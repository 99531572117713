import {
  GET_JOB,
  UPDATE_JOB,
  UPDATE_OBJECT_JOB,
  CREATE_JOB,
  DELETE_JOB,
} from '../actions/job';

export function jobReducer(job = {}, action) {
  switch (action.type) {
    case GET_JOB:
      return action.payload;
    case UPDATE_JOB:
      return action.payload;
    case CREATE_JOB:
      return action.payload;
    case DELETE_JOB:
      return action.payload;
    case UPDATE_OBJECT_JOB:
      return action.payload;

    default:
      return job;
  }
}
