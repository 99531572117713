import {
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_ERROR,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_ERROR,
  GET_PROFILE,
  UPDATE_PROFILE,
  updateObjectProfile,
  showSpinner,
  hideSpinner,
  hideModal,
} from '../actions/profile';
import Toastify from '../../utils/Toastify/index';
import { apiRequest } from '../actions/api';

const URL = 'profile';

export const updateProfileFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === UPDATE_PROFILE) {
    dispatch(
      apiRequest(
        'PUT',
        URL,
        action.payload,
        UPDATE_PROFILE_SUCCESS,
        UPDATE_PROFILE_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const getProfileFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_PROFILE) {
    dispatch(
      apiRequest('GET', URL, {}, FETCH_PROFILE_SUCCESS, FETCH_PROFILE_ERROR),
    );
    dispatch(showSpinner());
  }
};

export const processProfileCollection = ({ dispatch, getState }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_PROFILE_SUCCESS) {
    dispatch(updateObjectProfile(action.payload));
    dispatch(hideSpinner());
  } else if (action.type === UPDATE_PROFILE_SUCCESS) {
    console.log('After success', action.payload);
    dispatch(updateObjectProfile(action.payload));
    dispatch(hideModal());
    dispatch(hideSpinner());
    Toastify.addSuccess('Profile saved.');
  } else if (action.type === UPDATE_PROFILE_ERROR) {
    dispatch(hideSpinner());
    Toastify.addError(action.payload || 'Error to save the profile');
  }
};

export const profileMdl = [
  updateProfileFlow,
  getProfileFlow,
  processProfileCollection,
];
