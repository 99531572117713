import styled from 'styled-components';
import { Paper, AccordionDetails as MuiAccordionDetails } from '@material-ui/core';

export const Container = styled(Paper)`
  border-radius: 5px;
  margin-bottom: 20px;
  .MuiExpansionPanelSummary-root.Mui-expanded {
    height: 25px !important;
  }
  .MuiAccordionSummary-root.Mui-expanded {
    /* height: 25px !important; */
    border-bottom: 1px solid #ddd;
    min-height: 10px !important;
  }
  .MuiExpansionPanelDetails-root {
    padding: 0px;
  }
  .MuiList-padding {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .MuiExpansionPanelSummary-root:hover: {
    cursor: default !important;
  }
  .MuiButtonBase-root {
    cursor: ${(props) => (props.cursorType ? 'cursor' : 'default')};
  }
  .MuiListItem-root.Mui-disabled {
    opacity: 1;
  }  
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0px !important;
  }
`;

export const TitleContent = styled.div`
  margin: 0;
  width: 85%;
  min-height: 25px;
`;

export const CreateButton = styled.span`
  margin-left: 20px;
  background: #bbd2ca;
  border: none;
  font-size: 0.875rem;
  font-weight: 400;
  border-radius: 15px;
  padding: 5px 10px 5px 10px;
  cursor: pointer;
`;

export const GroupCreateButton = styled.span`
  margin-left: 20px;
  background: #eee;
  border: none;
  font-size: 0.875rem;
  font-weight: 400;
  border-radius: 15px;
  padding: 5px 10px 5px 10px;
  cursor: pointer;
`;

export const EditButton = styled.div`
  position: absolute;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  right: 30px;
  top: 0px;
  cursor: pointer;
`;

export const DeleteButton = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const InputContainer = styled.div`
  padding: 0px 10px 10px 10px;
`;

export const ButtonContainer = styled.div`
  padding: 5px 2px 5px 2px;
`;

export const AccordionDetails = styled(MuiAccordionDetails)`
padding: 0px;
flex-direction: column;
  .MuiTabs-flexContainer {
    background-color: white;
    /* display: flex; */
    /* align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%; */
  }
  .MuiTab-textColorInherit {
    color: #2c2c32;

  }
  .Mui-selected {
    color: #000;
  }
  .MuiTabs-indicator {
    background-color: #000;
  }

  .MuiTab-wrapper {
    font-size: 12px;
    font-weight: 700;
  }

  .MuiTab-root {
    cursor: pointer;
  }

`;
