import React, { useState } from 'react';
import { TableRow, TableCell, Collapse, IconButton } from '@material-ui/core';
import getRINADescription from '../../../../../utils/Equipment/getRINADescription';
import { CollapseCell, CollapseIcon } from './styles';
import ServiceTable from '../ServiceTable';
import MaintenanceTable from '../MaintenanceTable';

function InspectionRow({
  equipment,
  onOpenModalRejection,
  onApprove,
  services,
  maintenances,
  ServiceTableComponent = ServiceTable,
  MaintenanceTableComponent = MaintenanceTable,
  equipmentService = [],
  isSelected,
  onSelectChange,
}) {
  const [expanded, setExpanded] = useState(false);

  const handleToggleCollapse = () => {
    setExpanded((currentState) => !currentState);
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton onClick={handleToggleCollapse}>
            <CollapseIcon expanded={expanded} />
          </IconButton>
        </TableCell>
        <TableCell>{equipment?.individual_item_id}</TableCell>
        <TableCell>{getRINADescription(equipment?.customer_MM)}</TableCell>
        <TableCell>{equipment?.customer_MM?.mm_number}</TableCell>
        <TableCell>{equipment.qty}</TableCell>
        <TableCell>{equipment.footage}</TableCell>
        <TableCell>{equipment.condition_class}</TableCell>
        <TableCell>{equipment.manufacturer_no}</TableCell>
        <TableCell>{equipment.batch_no}</TableCell>
        <TableCell>{equipment.heat}</TableCell>
        <TableCell>{equipment.RBW}</TableCell>
        <TableCell>{equipment.facility_reference_id}</TableCell>
        <TableCell>{equipment.supplier}</TableCell>
      </TableRow>
      <TableRow>
        <CollapseCell colSpan={13}>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <ServiceTableComponent
              onOpenModalRejection={onOpenModalRejection}
              equipment={equipment}
              onApprove={onApprove}
              services={services}
              equipmentService={equipmentService}
              isSelected={isSelected}
              onSelectChange={onSelectChange}
            />
            <MaintenanceTableComponent
              equipment={equipment}
              onOpenModalRejection={onOpenModalRejection}
              onApprove={onApprove}
              maintenances={maintenances}
              equipmentService={equipmentService}
              isSelected={isSelected}
              onSelectChange={onSelectChange}
            />
          </Collapse>
        </CollapseCell>
      </TableRow>
    </>
  );
}

export default InspectionRow;
