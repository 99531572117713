export const GET_HISTORY = '[history] GET';
export const FETCH_HISTORY_SUCCESS = '[history] Fetch success';
export const FETCH_HISTORY_ERROR = '[history] Fetch Error';
export const UPDATE_HISTORY = '[history] Update';
export const SHOW_HISTORY_SPINNER = '[history - ui] show spinner';
export const HIDE_HISTORY_SPINNER = '[history - ui] hide spinner';

export const getHistory = (filter) => ({
  type: GET_HISTORY,
  payload: filter,
});

export const updateHistory = (data) => ({
  type: UPDATE_HISTORY,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_HISTORY_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_HISTORY_SPINNER,
});
