import React from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';

function ListView({ children }) {
  function renderChild(child) {
    return React.cloneElement(child, {
      ContainerComponent: TableRow,
      ValueComponent: TableCell,
    });
  }

  return (
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>Name</TableCell>
          <TableCell>Created By</TableCell>
          <TableCell>Modified At</TableCell>
          <TableCell>Size</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{React.Children.map(children, renderChild)}</TableBody>
    </Table>
  );
}

export default ListView;
