import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin: auto;
  margin-bottom: 20px;
  width: 800px;
  max-width: ${({ modal }) => (modal ? '90vw' : '100%')};
  p {
    color: red;
    text-align: center;
  }
`;

export const DropContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  width: 800px;
  max-width: 100%;
  height: 200px;
  border: 4px dashed #d3394c;
  transition: 500ms all;
  & > input {
    display: none;
  }

  ${({ disabled }) => (disabled
    ? css`
          opacity: 0.5;
        `
    : css`
          &:hover {
            cursor: pointer;
            border-color: #a12b39;
            transition: 500ms all;
            div {
              transition: 500ms all;
              color: #a12b39;
            }
            figure {
              transition: 500ms all;
              background-color: #a12b39;
            }
          }
        `)}
`;

export const DropMessage = styled.div`
  text-align: center;
  color: #d3394c;
  font-family: Arial;
  font-size: 20px;
  transition: 500ms all;
  figure {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #d3394c;
    display: block;
    padding: 20px;
    margin: 0 auto 10px;
    transition: 500ms all;
  }
  svg {
    width: 100%;
    height: 100%;
    fill: #f1e5e6;
  }
`;

export const FileDisplayContainer = styled.div`
  width: 805px;
  max-width: 100%;
  max-height: 20vh;
  overflow: auto;
  scrollbar-color: #555 #aaa;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: #aaaaaa;
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background: #555;
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #444;
  }
`;

export const FileStatusBar = styled.div`
  width: 100%;
  vertical-align: top;
  margin-top: 10px;
  margin-bottom: 20px;
  position: relative;
  line-height: 50px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  & > div {
    overflow: hidden;
  }
`;

export const FileType = styled.div`
  display: inline-block !important;
  position: absolute;
  font-size: 12px;
  font-weight: 700;
  line-height: 13px;
  margin-top: 25px;
  padding: 0 4px;
  border-radius: 2px;
  box-shadow: 1px 1px 2px #abc;
  color: #fff;
  background: #0080c8;
  text-transform: uppercase;
`;

export const FileName = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-left: 50px;
  color: #d3394c;
  transition: 500ms all;
  ${({ invalid }) => invalid
    && css`
      display: inline;
      vertical-align: top;
      margin-left: 60px;
      color: #9aa9bb;
    `}
`;

export const FileErrorMessage = styled.span`
  color: red;
`;

export const FileTypeLogo = styled.span`
  width: 50px;
  height: 50px;
  background: url(../images/generic.png) no-repeat center center;
  background-size: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  & > img {
    max-height: 100%;
    max-width: 100%;
  }
`;

export const FileSize = styled.span`
  display: inline-block;
  vertical-align: top;
  color: #30693d;
  margin-left: 10px;
  margin-right: 5px;
  margin-left: 10px;
  color: #444242;
  font-weight: 700;
  font-size: 14px;
`;

export const FileRemove = styled.div`
  position: absolute;
  top: 20px;
  right: 10px;
  line-height: 15px;
  cursor: pointer;
  color: red;
  margin-right: -10px;
`;
