import { UPDATE_OBJECT_OWNER } from '../actions/owner';

export function ownerReducer(
  owner = {
    name: '',
    contact_info: {
      name: '',
      email: '',
      phone: '',
      position: '',
    },
  },
  action,
) {
  switch (action.type) {
    case UPDATE_OBJECT_OWNER:
      return action.payload;

    default:
      return owner;
  }
}
