import {
  FETCH_INSPECTIONS_SUCCESS,
  FETCH_INSPECTIONS_ERROR,
  GET_INSPECTIONS,
  updateInspections,
  showSpinner,
  hideSpinner,
} from '../actions/inspections';
import { apiRequest } from '../actions/api';

const URL = 'inspections';

export const getInspectionsFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_INSPECTIONS) {
    dispatch(
      apiRequest(
        'GET',
        URL,
        action.payload,
        FETCH_INSPECTIONS_SUCCESS,
        FETCH_INSPECTIONS_ERROR,
        ['listAll', 'workOrder'],
      ),
    );
    dispatch(showSpinner());
  }
};

export const processInspectionsCollection = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_INSPECTIONS_SUCCESS) {
    dispatch(updateInspections(action.payload));
    dispatch(hideSpinner());
  }
};

export const inspectionsMdl = [
  getInspectionsFlow,
  processInspectionsCollection,
];
