import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {
  AddRounded,
  ArrowBack,
  CloudUploadOutlined,
  EditRounded,
  Search,
} from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import ModalContainer from '../../components/ModalContainer/index';
import Spinner from '../../components/Spinner/index';
import { CUSTOMER_KEY } from '../../config/consts';
import { hideModal, showModal } from '../../redux/actions/sloc';
import { getSLOCS } from '../../redux/actions/slocs';
import { tableStyles } from '../../styles/tableStyles';
import EditModal from '../Modals/SLOC';
import Row from './components/Row';
import WarehouseTable from './components/WarehouseTable';
import { Container } from './styles';

const schema = [
  { key: 'sloc_name' },
  { key: 'sloc_id', bold: true },
  { key: 'facility_type', bold: true },
];

function SLOC({
  dispatch, slocs, slocsLoading, slocModal,
}) {
  const customer = localStorage.getItem(CUSTOMER_KEY);
  const history = useHistory();
  const classes = tableStyles();
  const [SLOCSFormated, setSLOCSFormated] = useState([]);
  const [modalContentId, setModalContentId] = useState('new');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(50);
  const [total, setTotal] = useState(0);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (!localStorage.getItem(CUSTOMER_KEY)) return;
    dispatch(getSLOCS({ page: page + 1, limit, filter: { customer } }));
  }, [page, limit]);

  useEffect(() => {
    if (slocs.docs) {
      setSLOCSFormated(slocs.docs);
      setTotal(slocs.totalDocs);
    }
  }, [slocs]);

  const handleBack = () => {
    history.replace('/equipments');
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleOpenModal = () => {
    dispatch(showModal());
  };
  const handleClick = (id) => {
    setModalContentId(id);
    handleOpenModal();
  };

  const handleCloseModal = () => {
    dispatch(hideModal());
  };

  const handleGoToImport = () => {
    history.push('/sloc/import');
  };

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(getSLOCS({ page: 1, limit, searchText }));
  };

  return (
    <Container>
      <ModalContainer open={slocModal} handleClose={handleCloseModal}>
        <EditModal id={modalContentId} handleClose={handleCloseModal} />
      </ModalContainer>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Toolbar className={classes.toolBar}>
            <span>
              <IconButton onClick={handleBack}>
                <ArrowBack />
              </IconButton>
              <Typography
                className={classes.title}
                variant="h6"
                id="tableTitle"
                component="span"
              >
                SLOCs
              </Typography>
            </span>
            <span>
              <Button
                className={classes.buttons}
                onClick={() => handleClick('new')}
                startIcon={<AddRounded />}
              >
                New
              </Button>
              <Button
                color="secondary"
                onClick={handleGoToImport}
                startIcon={<CloudUploadOutlined />}
              >
                Import List
              </Button>
            </span>
          </Toolbar>
          <div
            style={{
              paddingTop: 10,
              width: 'fullWidth',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginLeft: '2rem',
              marginRight: '2rem',
            }}
          >
            <TextField
              label="Search"
              type="text"
              variant="standard"
              value={searchText}
              size="small"
              InputProps={{
                endAdornment: (
                  <IconButton onClick={handleSearch} size="small">
                    <Search />
                  </IconButton>
                ),
              }}
              name="role_name"
              onChange={(e) => setSearchText(e.target.value)}
              onKeyPress={(e) => (e.key === 'Enter' ? handleSearch(e) : null)}
              style={{ width: 300 }}
            />
          </div>
          <TableContainer className={classes.table}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>SLOC</TableCell>
                  <TableCell>SLOC ID</TableCell>
                  <TableCell>Facility Type</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {!localStorage.getItem(CUSTOMER_KEY) ? (
                  <TableRow className={classes.row}>
                    <TableCell
                        colSpan={5}
                        className={classes.cell}
                        align="center"
                      >
                        <Typography variant="h6">
                          Select an inventory
                        </Typography>
                      </TableCell>
                  </TableRow>
                ) : (
                  <>
                      {slocsLoading && (
                        <TableRow className={classes.row}>
                          <TableCell colSpan={5} className={classes.cell}>
                            <Spinner parent="Table" />
                          </TableCell>
                        </TableRow>
                      )}
                      {!slocsLoading
                        && SLOCSFormated.map((sloc) => (
                          <Row
                            key={sloc._id}
                            data={sloc}
                            onClick={handleClick}
                            schema={schema}
                            ChildrenTable={WarehouseTable}
                            childrenKey="warehouses"
                          />
                        ))}
                    </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[50, 100, 200, 500, 1000]}
            component="div"
            count={total}
            rowsPerPage={limit}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  slocsLoading: state.ui.slocsLoading,
  slocs: state.slocs,
  slocModal: state.ui.slocModal,
});

export default connect(mapStateToProps)(SLOC);
