export const GET_OWNERS = '[owners] GET';
export const FETCH_OWNERS_SUCCESS = '[owners] Fetch success';
export const FETCH_OWNERS_ERROR = '[owners] Fetch Error';
export const UPDATE_OWNERS = '[owners] Update';
export const SHOW_OWNERS_SPINNER = '[owners - ui] show spinner';
export const HIDE_OWNERS_SPINNER = '[owners - ui] hide spinner';

export const getOwners = (filter) => ({
  type: GET_OWNERS,
  payload: filter,
});

export const updateOwners = (data) => ({
  type: UPDATE_OWNERS,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_OWNERS_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_OWNERS_SPINNER,
});
