import React from 'react';
import { Button, TextField } from '@material-ui/core';
import ModalContainer from '../../../components/ModalContainer';
import TagsField from '../../../components/TagsField';
import { ModalButtons, InputContent, ModalTitle, ModalContent } from './styles';

function NoteModal({ open, onClose, onSubmit, data, onChange }) {
  const [noteError, setNoteError] = React.useState(false);
  function handleSubmit() {
    if (!data.eventNote) return setNoteError(true);
    onSubmit();
  }
  return (
    <ModalContainer open={open} handleClose={onClose}>
      <ModalContent>
        <ModalTitle>
          Please, inform details regarding this transaction and its motives.
        </ModalTitle>
        <TextField
          multiline
          size="small"
          label="Notes"
          required
          type="text"
          variant="outlined"
          value={data.eventNote}
          onChange={onChange}
          fullWidth
          error={noteError}
          helperText={noteError && 'This field is required'}
          name="eventNote"
        />

        <TagsField
          name="tags"
          value={data.tags}
          onChange={onChange}
          label="Tags"
        />
        <ModalButtons>
          <Button variant="contained" color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Confirm
          </Button>
        </ModalButtons>
      </ModalContent>
    </ModalContainer>
  );
}

export default NoteModal;
