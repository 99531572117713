import { UPDATE_RESULT_LAYOUT_LIST } from '../actions/resultLayouts';

export function resultLayoutsReducer(resultLayouts = [], action) {
  switch (action.type) {
    case UPDATE_RESULT_LAYOUT_LIST:
      return action.payload;
    default:
      return resultLayouts;
  }
}
