import {
  UPDATE_RECLASS_DAMAGE_SUCCESS,
  UPDATE_RECLASS_DAMAGE_ERROR,
  FETCH_RECLASS_DAMAGE_SUCCESS,
  FETCH_RECLASS_DAMAGE_ERROR,
  GET_RECLASS_DAMAGE,
  CREATE_RECLASS_DAMAGE,
  UPDATE_RECLASS_DAMAGE,
  updateObjectReclassDamage,
  showSpinner,
  hideSpinner,
  hideModal,
} from '../actions/reclassDamage';
import { getReclassDamages } from '../actions/reclassDamages';
import Toastify from '../../utils/Toastify/index';
import { apiRequest } from '../actions/api';

const URL = 'damage_descriptions';

export const createReclassDamageFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === CREATE_RECLASS_DAMAGE) {
    dispatch(
      apiRequest(
        'POST',
        URL,
        action.payload,
        UPDATE_RECLASS_DAMAGE_SUCCESS,
        UPDATE_RECLASS_DAMAGE_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const updateReclassDamageFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === UPDATE_RECLASS_DAMAGE) {
    dispatch(
      apiRequest(
        'PUT',
        URL,
        action.payload,
        UPDATE_RECLASS_DAMAGE_SUCCESS,
        UPDATE_RECLASS_DAMAGE_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const getReclassDamageFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_RECLASS_DAMAGE) {
    const { _id } = action.payload;
    dispatch(
      apiRequest(
        'GET-id',
        URL,
        { _id },
        FETCH_RECLASS_DAMAGE_SUCCESS,
        FETCH_RECLASS_DAMAGE_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const processReclassDamageCollection = ({ dispatch, getState }) => (next) => (
  action,
) => {
  next(action);
  if (action.type === FETCH_RECLASS_DAMAGE_SUCCESS) {
    dispatch(updateObjectReclassDamage(action.payload));
    dispatch(hideSpinner());
  } else if (action.type === UPDATE_RECLASS_DAMAGE_SUCCESS) {
    dispatch(updateObjectReclassDamage(action.payload));
    dispatch(hideModal());
    dispatch(hideSpinner());
    // To reload the page
    const { page, limit } = getState().reclassDamages;
    dispatch(getReclassDamages({ page, limit }));
    Toastify.addSuccess('Reclass Damage saved.');
  } else if (action.type === UPDATE_RECLASS_DAMAGE_ERROR) {
    dispatch(hideSpinner());
    Toastify.addError(action.payload || 'Error to save the reclass damage');
  }
};

export const reclassDamageMdl = [
  createReclassDamageFlow,
  updateReclassDamageFlow,
  getReclassDamageFlow,
  processReclassDamageCollection,
];
