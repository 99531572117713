import React from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import clsx from 'clsx';
import Tooltip from '@material-ui/core/Tooltip';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Popover from '@material-ui/core/Popover';
import CssBaseline from '@material-ui/core/CssBaseline';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import HomeIcon from '@material-ui/icons/Home';
import ErrorOutlineRounded from '@material-ui/icons/ErrorOutlineRounded';
import Badge from '@material-ui/core/Badge';
import {
  Menu,
  DashboardRounded as InventoryIcon,
  NotificationsNone,
  ExitToApp,
  AccountCircle,
  DeviceHub as HubIcon,
  LocalDrink as WellIcon,
  Ballot,
  BusinessCenter as BusinessUnitIcon,
  NotificationsActive,
} from '@material-ui/icons';
import { IoIosPeople } from 'react-icons/io';
import { ImOnedrive as VaultIcon } from 'react-icons/im';
import Footer from '../Footer';
import LogoImage from '../../assets/images/logo.png';
import { logout } from '../../redux/actions/loggedUser';
import { getCompleteCart } from '../../redux/actions/inventory_cart';
import { FaHandshake } from 'react-icons/fa';
import { RiFileDamageFill } from 'react-icons/ri';
import {
  Logo,
  Search,
  PanelContainer,
  UserName,
  ListIcon,
  MenuButton,
  SearchBox,
  Hideable,
  BlankSpace,
} from './styles';
import { CUSTOMER_KEY } from '../../config/consts';
import ResultBox from '../ResultBox';
import {
  searchMovimentations,
  updateMovimentationsSearch,
} from '../../redux/actions/movimentations';
import useDebounce from '../../hooks/useDebounce';
import { getMovimentations } from '../../redux/actions/movimentations';
import { getCustomers } from '../../redux/actions/customers';
import MenuOld from '../MenuOld';

import Nav, { useNav } from '../Nav'
import { Box } from '@material-ui/core';

const CompanyCodeIcon = () => <span style={{ fontWeight: 700 }}>CC</span>;
const MaterialOwnerIcon = () => <span style={{ fontWeight: 700 }}>MO</span>;

const StyledPopover = withStyles((theme) => ({
  paper: {
    marginTop: 10,
    overflow: 'visible',
    '&:before': {
      content: "''",
      border: '8px solid transparent',
      borderBottom: '8px solid white',
      position: 'absolute',
      top: -16,
      left: '50%',
      marginLeft: -8,
    },
  },
}))(Popover);

const styles = {
  iconStyle: {
    marginLeft: 25,
    cursor: 'pointer',
    color: '#717478',
  },
  dropdownIconStyle: {
    color: '#fff',
    position: 'absolute',
    top: 20,
    left: 20,
    cursor: 'pointer',
  },
  drawerIconStyle: {
    color: '#fff',
  },
  logoutIconStyle: {
    marginLeft: 10,
    height: 25,
    width: 25,
  },
  linkFont: {
    color: '#fff',
    textDecoration: 'none',
  },
};

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    backgroundColor: '#2c2c32',
    color: 'white',
    width: theme.spacing(9) + 1,
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      overflowX: 'auto',
    },
  },
  drawerClose: {
    backgroundColor: '#2c2c32',
    color: 'white',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  toolbarIcon: {
    color: '#fff',
  },
  contentOpen: {
    width: `calc(100% - ${theme.spacing(9) + 1}px)`,
    height: 'calc(100vh - 98px)',
    overflow: 'auto',
    marginTop: 65,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  contentClose: {
    width: '100%',
    height: 'calc(100vh - 98px)',
    overflow: 'auto',
    marginTop: 65,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${theme.spacing(9) + 1}px)`,
    },
  },
  tooltip: {
    fontSize: 12,
  },
  scrollableMenu: {
    scrollbarColor: '#555 #aaa',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: 6,
    },
    '&::-webkit-scrollbar-track': {
      background: '#aaa',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#555',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#444',
    },
  },
}));

const Navigation = ({
  loggedUser,
  component,
  dispatch,
  customers = [],
  cart_count,
  movimentationsFilter,
}) => {
  const nav = useNav();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [name] = React.useState(loggedUser.name);
  const [openSettings, setOpenSettings] = React.useState(false);
  const { pathname } = useLocation();
  const [query, setQuery] = React.useState('');
  const [enableSearchBox, setEnableSearchBox] = React.useState(false);
  const [changeInventoryPopoverAnchorEl, setChangeInventoryPopoverAnchorEl] =
    React.useState(false);
  const debouncedQuery = useDebounce(query, 800);
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const md = useMediaQuery(theme.breakpoints.down('md'));

  React.useEffect(() => {
    dispatch(getCustomers({ filter: { active: true, listAll: true } }));
  }, []);

  React.useEffect(() => {
    dispatch(getCompleteCart(loggedUser._id));
  }, []);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  function handleQueryChange(e) {
    const { value } = e.target;
    if (value === '')
      dispatch(updateMovimentationsSearch({ docs: [], totalDocs: 0 }));
    setQuery(value);
  }

  React.useEffect(() => {
    if (!debouncedQuery) return;
    dispatch(
      searchMovimentations({
        page: 1,
        limit: 5,
        filter: {
          query: debouncedQuery,
          in_inventory: true,
          consolidated: true,
        },
      })
    );
  }, [debouncedQuery]);

  function handleSubmitSearch(e) {
    e.preventDefault();
    if (location.pathname.includes('/equipments'))
      dispatch(
        getMovimentations({
          page: 1,
          limit: 50,
          filter: {
            ...movimentationsFilter,
            query,
            consolidated: true,
            in_inventory: true,
          },
        })
      );
    else
      dispatch(
        getMovimentations({
          page: 1,
          limit: 50,
          filter: { query, consolidated: true, in_inventory: true },
        })
      );
    history.push({
      pathname: '/equipments',
      state: { fromSearch: true },
    });
  }

  function handleChangeInventoryClick(e) {
    setChangeInventoryPopoverAnchorEl(e.target);
  }
  function handleChangeInventoryPopoverDismiss() {
    setChangeInventoryPopoverAnchorEl(null);
  }

  function handleSelectCustomer(customerId) {
    return function () {
      localStorage.setItem(CUSTOMER_KEY, customerId);
      handleChangeInventoryPopoverDismiss();
      history.go(0);
    };
  }

  const changeInventoryPopoverOpen = Boolean(changeInventoryPopoverAnchorEl);

  const selectedInventory = localStorage.getItem(CUSTOMER_KEY);

  const selectedInvObj = customers?.find(
    ({ _id }) => String(_id) === String(selectedInventory)
  );

  return (
    <div style={{ position: 'relative' }}>
      <div className={classes.root}>
        <CssBaseline />
        <form onSubmit={handleSubmitSearch}>
          <AppBar
            position="fixed"
            color="secondary"
            style={{
              height: 65,
              backgroundColor: '#16161e',
              width: 'calc(100%)',
            }}
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
          >
            <Toolbar>
              {pathname === '/' ? null : (
                <MenuButton onClick={() => nav.toggle()}>
                  <Menu style={styles.drawerIconStyle} />
                </MenuButton>
              )}
              {!xs && (
                <Link to="/equipments">
                  <Logo src={LogoImage} />
                </Link>
              )}
              <SearchBox>
                <ClickAwayListener
                  onClickAway={() => setEnableSearchBox(false)}
                >
                  <Search
                    onFocus={() => setEnableSearchBox(true)}
                    placeholder="Inventory Search"
                    value={query}
                    onChange={handleQueryChange}
                  />
                </ClickAwayListener>
                <ResultBox
                  query={debouncedQuery}
                  allowVisible={
                    enableSearchBox &&
                    debouncedQuery &&
                    !location.pathname.includes('/equipments')
                  }
                />
              </SearchBox>
              <PanelContainer>
                <Tooltip
                  arrow
                  title={
                    <>
                      Change Inventory
                      {selectedInventory &&
                        customers &&
                        customers.length > 0 && (
                          <p>Selected: {selectedInvObj?.name || 'none'}</p>
                        )}
                    </>
                  }
                  classes={{ tooltip: classes.tooltip }}
                >
                  <InventoryIcon
                    style={styles.iconStyle}
                    onClick={handleChangeInventoryClick}
                  />
                </Tooltip>
                <StyledPopover
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  anchorEl={changeInventoryPopoverAnchorEl}
                  open={changeInventoryPopoverOpen}
                  onClose={handleChangeInventoryPopoverDismiss}
                  elevation={5}
                >
                  {customers?.length > 0 ? (
                    <List disablePadding dense>
                      <ListSubheader>Select an inventory</ListSubheader>
                      {customers?.map((customer) => (
                        <ListItem
                          button
                          key={customer._id}
                          onClick={handleSelectCustomer(customer._id)}
                          selected={
                            String(selectedInventory) === String(customer._id)
                          }
                        >
                          <ListItemText>{customer.name}</ListItemText>
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    <span>No inventory found</span>
                  )}
                </StyledPopover>
                <Hideable hide={md}>
                  <UserName>Welcome, {name}.</UserName>
                  <Link to="/profile">
                    <Tooltip
                      title="My Profile"
                      arrow
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <AccountCircle style={styles.iconStyle} />
                    </Tooltip>
                  </Link>
                  <Link to="/inventory_cart">
                    <Tooltip
                      title="Inventory Cart"
                      arrow
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <Badge badgeContent={cart_count || 0} color="secondary">
                        <ShoppingCartIcon style={styles.iconStyle} />
                      </Badge>
                    </Tooltip>
                  </Link>
                </Hideable>
                <Link to="/notifications">
                  <Tooltip
                    title="Notifications"
                    arrow
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <NotificationsNone style={styles.iconStyle} />
                  </Tooltip>
                </Link>
                <Hideable hide={md}>
                  <Link to="/">
                    <Tooltip
                      arrow
                      title="Hub"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <HubIcon style={styles.iconStyle} />
                    </Tooltip>
                  </Link>
                  <Tooltip
                    title="Logout"
                    arrow
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <ExitToApp
                      onClick={handleLogout}
                      style={styles.iconStyle}
                    />
                  </Tooltip>
                </Hideable>
              </PanelContainer>
            </Toolbar>
          </AppBar>
        </form>
        {pathname !== '/' && (
          <Nav />
        )}
        <main
          className={clsx({
            [classes.contentOpen]: nav.state,
            [classes.contentClose]: !nav.state,
          })}
          style={pathname === '/' ? { width: '100vw' } : undefined}
        >
          {component}
        </main>
        <Box display="flex" flexDirection="column">
        </Box>
      </div>
      <Footer
        footerText={pathname === '/' ? 'RINA Hub v0.0.1' : 'RINA RAMP v0.0.1'}
      />
    </div>
  );
};

export default connect((state) => ({
  loggedUser: state.loggedUser,
  cart_count: state.inventory_cart?.all?.length,
  movimentationsFilter: state.movimentationsFilter,
  customers: state.customers?.docs || state.customers,
}))(Navigation);
