import { CUSTOMER_KEY } from '../../config/consts';

export const GET_ORIGINAL_CHARGE = '[original_charge] GET';
export const UPDATE_ORIGINAL_CHARGE = '[original_charge] UPDATE';
export const CREATE_ORIGINAL_CHARGE = '[original_charge] CREATE';
export const DELETE_ORIGINAL_CHARGE = '[original_charge] DELETE';
export const UPDATE_OBJECT_ORIGINAL_CHARGE = '[original_charge] UPDATE OBJECT ORIGINAL_CHARGE';
// UI
export const SHOW_ORIGINAL_CHARGE_MODAL = '[original_charge] SHOW MODAL';
export const HIDE_ORIGINAL_CHARGE_MODAL = '[original_charge] HIDE MODAL';
// Spinner
export const UPDATE_ORIGINAL_CHARGE_SUCCESS = '[original_charge] Update success';
export const UPDATE_ORIGINAL_CHARGE_ERROR = '[original_charge] Update Error';
export const FETCH_ORIGINAL_CHARGE_SUCCESS = '[original_charge] Fetch success';
export const FETCH_ORIGINAL_CHARGE_ERROR = '[original_charge] Fetch Error';
export const SHOW_ORIGINAL_CHARGE_SPINNER = '[original_charge - ui] show spinner';
export const HIDE_ORIGINAL_CHARGE_SPINNER = '[original_charge - ui] hide spinner';

export const getOriginalCharge = (id) => ({
  type: GET_ORIGINAL_CHARGE,
  payload: { _id: id },
});

export const createOriginalCharge = (data) => ({
  type: CREATE_ORIGINAL_CHARGE,
  payload: { ...data, customer: localStorage.getItem(CUSTOMER_KEY) },
});

export const updateOriginalCharge = (data) => ({
  type: UPDATE_ORIGINAL_CHARGE,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_ORIGINAL_CHARGE_MODAL,
});

export const hideModal = () => ({
  type: HIDE_ORIGINAL_CHARGE_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_ORIGINAL_CHARGE_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_ORIGINAL_CHARGE_SPINNER,
});

export const updateObjectOriginalCharge = (data) => ({
  type: UPDATE_OBJECT_ORIGINAL_CHARGE,
  payload: data,
});
