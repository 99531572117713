import React from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
} from '@material-ui/core';

function TableHeaderWithLayout({ layout, sortable, orderBy, order, onSort }) {
  function isActive(field) {
    return field === orderBy;
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell />
        {layout.map(({ field, label }) => (
          <TableCell
            align="center"
            sortDirection={isActive(field) ? order : false}
          >
            {sortable ? (
              <TableSortLabel
                active={isActive(field)}
                direction={isActive(field) ? order : 'desc'}
                onClick={onSort(field)}
              >
                {label}
              </TableSortLabel>
            ) : (
              label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default TableHeaderWithLayout;
