import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { TextField, Button } from '@material-ui/core';
import Alert from '../../../components/Alert/index';
import Spinner from '../../../components/Spinner/index';
import {
  Container, InputContent, ModalTitle, ButtonContainer,
} from './styles';

import { getSLOC, createSLOC, updateSLOC } from '../../../redux/actions/sloc';

const styles = {
  formButton: {
    margin: 2,
  },
};

function HistoryModal({
  id,
  dispatch,
  sloc,
  slocModal,
  handleClose,
  slocSpinner,
  equipmentName,
  equipmentParent,
  individualItemId,
  title,
}) {
  return (
    <>
      {slocSpinner && <Spinner overlay />}
      <Container style={{ opacity: slocSpinner ? 0.5 : 1 }}>
        <ModalTitle>
          Equipment
          {' '}
          {title === 'added' ? 'Added to' : 'Removed from'}
          {' '}
          Hierarchy
        </ModalTitle>
        <InputContent>
          <TextField
            label="RINA Description"
            disabled
            type="text"
            variant="outlined"
            value={equipmentName}
            name="equipmentName"
            fullWidth
          />
        </InputContent>
        <InputContent>
          <TextField
            label="RAMP Unique ID"
            disabled
            type="text"
            variant="outlined"
            value={individualItemId}
            name="individualItemId"
            fullWidth
          />
        </InputContent>
        <InputContent>
          <TextField
            label="Equipment Parent"
            disabled
            type="text"
            variant="outlined"
            value={equipmentParent}
            name="equipmentParent"
            fullWidth
          />
        </InputContent>

        <ButtonContainer>
          <Button
            color="primary"
            onClick={handleClose}
            variant="contained"
            style={styles.formButton}
          >
            Ok
          </Button>
        </ButtonContainer>
      </Container>
    </>
  );
}

export default connect((state) => ({
  sloc: state.sloc,
  slocModal: state.ui.slocModal,
  slocSpinner: state.ui.slocLoading,
}))(HistoryModal);
