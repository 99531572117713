export const GET_RACK = '[rack] GET';
export const UPDATE_RACK = '[rack] UPDATE';
export const CREATE_RACK = '[rack] CREATE';
export const DELETE_RACK = '[rack] DELETE';
export const UPDATE_OBJECT_RACK = '[rack] UPDATE OBJECT RACK';
// UI
export const SHOW_RACK_MODAL = '[rack] SHOW MODAL';
export const HIDE_RACK_MODAL = '[rack] HIDE MODAL';
// Spinner
export const UPDATE_RACK_SUCCESS = '[rack] Update success';
export const UPDATE_RACK_ERROR = '[rack] Update Error';
export const FETCH_RACK_SUCCESS = '[rack] Fetch success';
export const FETCH_RACK_ERROR = '[rack] Fetch Error';
export const SHOW_RACK_SPINNER = '[rack - ui] show spinner';
export const HIDE_RACK_SPINNER = '[rack - ui] hide spinner';

export const getRack = (id) => ({
  type: GET_RACK,
  payload: { _id: id },
});

export const createRack = (data) => ({
  type: CREATE_RACK,
  payload: data,
});

export const updateRack = (data) => ({
  type: UPDATE_RACK,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_RACK_MODAL,
});

export const hideModal = () => ({
  type: HIDE_RACK_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_RACK_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_RACK_SPINNER,
});

export const updateObjectRack = (data) => ({
  type: UPDATE_OBJECT_RACK,
  payload: data,
});
