import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

export const Entry = styled(Grid)`
  text-align: center;

  & .vaultEntry-name {
    text-align: center;
    width: 100%;
    margin: 2px;
  }
  & .vaultEntry-container {
    width: 70%;
    display: inline-block;
    cursor: default;
    padding: 10px;
    border-radius: 3px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    ${({ theme }) => theme.breakpoints.down('sm')} {
      width: 90%;
    }
  }
  & .vaultEntry-container:hover {
    background-color: #eee;
  }
  & .vaultEntry-icon {
    font-size: 2.5rem;
    display: block;
  }
  & .vaultEntry-creator,
  & .vaultEntry-modifiedAt,
  & .vaultEntry-size {
    display: none;
  }
`;
