import {
  UPDATE_CHECK_IN_SUCCESS,
  UPDATE_CHECK_IN_ERROR,
  FETCH_CHECK_IN_SUCCESS,
  FETCH_CHECK_IN_ERROR,
  GET_CHECK_IN,
  CREATE_CHECK_IN,
  UPDATE_CHECK_IN,
  updateObjectCheckIn,
  showSpinner,
  hideSpinner,
  hideModal,
} from '../actions/checkIn';
import Toastify from '../../utils/Toastify/index';
import history from '../../services/history';
import { apiRequest } from '../actions/api';
import { CUSTOMER_KEY } from '../../config/consts';

const URL = 'check-in';

export const createCheckInFlow = ({ dispatch, getState }) => (next) => (
  action,
) => {
  next(action);
  if (action.type === CREATE_CHECK_IN) {
    dispatch(
      apiRequest(
        'POST',
        URL,
        {
          ...action.payload,
          inventory: localStorage.getItem(CUSTOMER_KEY),
          user: getState().loggedUser._id,
        },
        UPDATE_CHECK_IN_SUCCESS,
        UPDATE_CHECK_IN_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const updateCheckInFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === UPDATE_CHECK_IN) {
    dispatch(
      apiRequest(
        'PUT',
        URL,
        action.payload,
        UPDATE_CHECK_IN_SUCCESS,
        UPDATE_CHECK_IN_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const getCheckInFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_CHECK_IN) {
    const { _id } = action.payload;
    dispatch(
      apiRequest(
        'GET-id',
        URL,
        { _id },
        FETCH_CHECK_IN_SUCCESS,
        FETCH_CHECK_IN_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const processCheckInCollection = ({ dispatch, getState }) => (next) => (
  action,
) => {
  next(action);
  if (action.type === FETCH_CHECK_IN_SUCCESS) {
    dispatch(updateObjectCheckIn(action.payload));
    dispatch(hideSpinner());
  } else if (action.type === UPDATE_CHECK_IN_SUCCESS) {
    dispatch(updateObjectCheckIn(action.payload));
    dispatch(hideModal());
    dispatch(hideSpinner());
    history.replace(`/check_in/${action.payload._id}`);
    Toastify.addSuccess('Check In saved.');
  } else if (action.type === UPDATE_CHECK_IN_ERROR) {
    dispatch(hideSpinner());
    Toastify.addError(action.payload || 'Error to save the check in');
  }
};

export const checkInMdl = [
  createCheckInFlow,
  updateCheckInFlow,
  getCheckInFlow,
  processCheckInCollection,
];
