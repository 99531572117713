/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  TextField,
  CircularProgress,
  FormHelperText,
  Box,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { AddRounded } from '@material-ui/icons';
import SweetAlert from 'sweetalert2';
import { InputContainer } from '../../components/InventoryFilter/styles';
import { getCustomers } from '../../redux/actions/customers';
import Toastify from '../../utils/Toastify/index';
import Alert from '../../components/Alert/index';
import {
  Container,
  PaperStyled,
  Title,
  TableTitle,
  ExcelTable,
  Column,
  ColumnHeader,
  ColumnContent,
  ButtonContainer,
  AddButton,
  AddMoreButtonContainer,
} from './styles';
import staticFields from '../../utils/StaticData/EquipmentInputs/import_data';
import excelColumns from '../../utils/StaticData/ExcelColumns/data';
import { apiUrl } from '../../config/consts';
import DropZone from '../../components/DropZone';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  title: {
    fontSize: 20,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  row: { padding: 0 },
  cell: {
    fontSize: 12,
    padding: '0px 10px 0px 15px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  buttons: {
    color: 'blue',
  },
  checkButton: {
    color: 'green',
  },
  closeButton: {
    color: 'red',
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

function ImportMenu ({ dispatch, customers = [], loggedUser }) {
  const history = useHistory();
  const classes = useStyles();
  const [firstLoad, setFirstLoad] = useState(true); // To detect the first page load
  const [customer, setCustomer] = useState('');
  const [customerError, setCustomerError] = useState(false);
  const [customerMMError, setCustomerMMError] = useState(false);
  const [file, setFile] = useState(null);
  const [fieldsLen, setFieldsLen] = useState(staticFields.length);
  const [fields, setFields] = useState([]);
  const [equipmentInputs, setEquipmentInputs] = useState([]);
  const [uploading, setUploading] = useState(false);

  const equipmentFieldsOrder = () => {
    setEquipmentInputs(
      staticFields.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      }),
    );
  };

  useEffect(() => {
    equipmentFieldsOrder();
    dispatch(getCustomers({ filter: { listAll: true, active: true } }));
  }, []);

  useEffect(() => {
    if (fieldsLen <= fields) return;
    setFields(getNewFieldsState(fieldsLen));

    function getNewFieldsState(newFieldsLength) {
      return function (currentFieldsState) {
        const emptyArray = createEmptyFieldsArray(
          currentFieldsState,
          newFieldsLength
        );
        return concatArrays(currentFieldsState, emptyArray);
      };
    }

    function createEmptyFieldsArray(currentList, newLength) {
      const length = getQtyToAdd(newLength, currentList.length);
      return [...Array(length).keys()].map((_, index) => {
        return {
          label: getExcelColumn(index, currentList.length),
          value: '',
        };
      });
    }

    function getQtyToAdd(newFieldsLength, currentFieldsLength) {
      return newFieldsLength - currentFieldsLength;
    }

    function getExcelColumn(index, length) {
      return excelColumns[length + index];
    }

    function concatArrays(head, tail) {
      return [...head, ...tail];
    }
  }, [fieldsLen]);

  const setValueOfField = (column, newValue) => {
    const newItems = JSON.parse(JSON.stringify(fields));
    newItems.forEach((item, count) => {
      if (item.label === column) newItems[count].value = newValue;
    });
    setFields(newItems);
  };

  useEffect(() => {
    // To remove the option is it was selected
    if (!firstLoad) {
      const docs = staticFields.filter(
        (el) => fields.findIndex((ff) => ff.value === el.value) === -1
      );
      setEquipmentInputs(docs);
    }
    setFirstLoad(false);
  }, [fields]);

  const handleImport = async () => {
    let err = false;

    if (!customer) {
      err = true;
      setCustomerError(true);
    }

    if (!file) {
      err = true;
      Toastify.addError('File not found. Please select a valid file.');
      return;
    }

    if (file.invalid) {
      err = true;
      Toastify.addError(
        'The file type is not supported. Please select an excel file.'
      );
    }

    let savedDocuments = 0;
    fields.forEach((field) => {
      if (field.value) {
        savedDocuments += 1;
      }
    });

    if (savedDocuments === 0) {
      err = true;
      Toastify.addError('You must select the excel columns.');
    }

    if (!err) {
      const form = new FormData();
      form.append('file', file);
      form.append('user', loggedUser?._id);

      if (customer && customer !== '') {
        form.append('customer', customer);
      }

      fields.forEach((field, index) => {
        if (field.value) {
          form.append(field.value, index + 1);
        }
      });

      const confirmResponse = await Alert();
      if (confirmResponse && confirmResponse.confirm) {
        setUploading(true);
        Toastify.addInfo('Starting upload, it may takes some minutes...');
        fetch(`${apiUrl}import_file`, {
          method: 'POST',
          body: form,
        })
          .then(async (response) => {
            if (response.status === 200 || response.status === 204) {
              Toastify.addSuccess('The file was imported successfully.');
              const result = await response.json();
              if (
                result.rowsWithNoMM?.length > 0 ||
                result.rowsWithNonExistentMM?.length > 0
              ) {
                const rowsWithNoMMString = result.rowsWithNoMM
                  .join(', ')
                  .replace(
                    /, ([^, ]*)$/,
                    ' and ' + '$1 don\'t have "customer MM"',
                  );
                const rowsWithNonExistentMMString = result.rowsWithNonExistentMM
                  .join(', ')
                  .replace(
                    /, ([^, ]*)$/,
                    ' and ' + '$1 had their Material Master created',
                  );
                const finalString = `The rows ${[
                  rowsWithNoMMString,
                  rowsWithNonExistentMMString,
                ].join(' and ')}.`;
                SweetAlert.fire(
                  'Inventory imported successfully!',
                  finalString,
                  'info'
                ).then(() => {
                  history.push('/equipments');
                });
              } else history.push('/equipments');
            } else {
              Toastify.addError('Error to import the file.');
            }
            setUploading(false);
          })
          .catch(() => {
            Toastify.addError('Error to import the file.');
            setUploading(false);
          });
      }
    }
  };

  return (
    <Container>
      <PaperStyled elevation={1}>
        <Grid container>
          <Grid item lg={6} md={6} xs={6} sm={6}>
            <Title>Import Form</Title>
          </Grid>
          <AddButton item lg={6} md={6} xs={6} sm={6}>
            <Button
              className={classes.buttons}
              onClick={() => history.push('/equipment/new')}
            >
              <AddRounded />
              Add Equipment
            </Button>
          </AddButton>
          <Grid item lg={12} md={12} sm={12} xs={12} align="center">
            <DropZone file={file} onChangeFile={setFile} />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <FormControl
              variant="outlined"
              size="small"
              error={customerError}
              helperText="Required field"
              fullWidth
            >
              <InputLabel id="select-label">Select the customer*</InputLabel>
              <Select
                size="small"
                labelId="select-label"
                variant="outlined"
                id="select-label-component"
                value={customer}
                onChange={(e) => {
                  setCustomer(e.target.value);
                  setCustomerError(false);
                }}
                fullWidth
              >
                {customers &&
                  customers.map((r) => (
                    <MenuItem value={r._id}>{r.name}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <TableTitle>Excel Columns</TableTitle>
        <ExcelTable>
          <AddMoreButtonContainer>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setFieldsLen(fieldsLen + 1)}
            >
              Add +1 Column
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: 5 }}
              onClick={() => setFieldsLen(fieldsLen + 5)}
            >
              Add +5 Column
            </Button>
          </AddMoreButtonContainer>
          {fields &&
            fields.map(
              (column, count) =>
                count < fieldsLen && (
                  <Column>
                    <ColumnHeader>{column.label}</ColumnHeader>
                    <ColumnContent>
                      <InputContainer>
                        <Autocomplete
                          freeSolo
                          id="size-small-standard"
                          size="small"
                          autoHighlight
                          options={equipmentInputs.filter(
                            (item) =>
                              item.label !== '' &&
                              fields.every(
                                (field) => field.value !== item.value
                              )
                          )}
                          getOptionLabel={(option) => option?.label}
                          onInputChange={(event, newValue) => {
                            setValueOfField(column.label, newValue);
                          }}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setValueOfField(column.label, newValue.value);
                            } else {
                              setValueOfField(column.label, null);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label={params.label}
                              placeholder={params.label}
                            />
                          )}
                        />
                      </InputContainer>
                    </ColumnContent>
                  </Column>
                )
            )}
        </ExcelTable>
        <FormControl fullWidth error={customerMMError}>
          {customerMMError && (
            <FormHelperText component={Box} textAlign="center">
              Customer MM field is required
            </FormHelperText>
          )}
          <ButtonContainer>
            <Button
              color="secondary"
              onClick={() => history.push('/equipments')}
              variant="contained"
              style={{ marginRight: 5 }}
            >
              Cancel
            </Button>

            <Button
              color="primary"
              onClick={handleImport}
              variant="contained"
              style={{ marginleft: 5 }}
              startIcon={
                uploading && (
                  <CircularProgress
                    size={24}
                    color="primary"
                    style={{ marginRight: 10 }}
                  />
                )
              }
              disabled={uploading}
            >
              Import
            </Button>
          </ButtonContainer>
        </FormControl>
      </PaperStyled>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  customers: state.customers?.docs || state.customers,
  loggedUser: state.loggedUser,
});

export default connect(mapStateToProps)(ImportMenu);
