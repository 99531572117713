export const GET_PROFILE = '[profile] GET';
export const UPDATE_PROFILE = '[profile] UPDATE';
export const UPDATE_OBJECT_PROFILE = '[profile] UPDATE OBJECT PROFILE';
// UI
export const SHOW_PROFILE_MODAL = '[profile] SHOW MODAL';
export const HIDE_PROFILE_MODAL = '[profile] HIDE MODAL';
// Spinner
export const UPDATE_PROFILE_SUCCESS = '[profile] Update success';
export const UPDATE_PROFILE_ERROR = '[profile] Update Error';
export const FETCH_PROFILE_SUCCESS = '[profile] Fetch success';
export const FETCH_PROFILE_ERROR = '[profile] Fetch Error';
export const SHOW_PROFILE_SPINNER = '[profile - ui] show spinner';
export const HIDE_PROFILE_SPINNER = '[profile - ui] hide spinner';

export const getProfile = () => ({
  type: GET_PROFILE,
  payload: {},
});

export const updateProfile = (data) => ({
  type: UPDATE_PROFILE,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_PROFILE_MODAL,
});

export const hideModal = () => ({
  type: HIDE_PROFILE_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_PROFILE_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_PROFILE_SPINNER,
});

export const updateObjectProfile = (data) => ({
  type: UPDATE_OBJECT_PROFILE,
  payload: data,
});
