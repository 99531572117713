export const GET_SERVICE_CATEGORY = '[serviceCategory] GET';
export const UPDATE_SERVICE_CATEGORY = '[serviceCategory] UPDATE';
export const CREATE_SERVICE_CATEGORY = '[serviceCategory] CREATE';
export const DELETE_SERVICE_CATEGORY = '[serviceCategory] DELETE';
export const UPDATE_OBJECT_SERVICE_CATEGORY = '[serviceCategory] UPDATE OBJECT CATEGORY';
// UI
export const SHOW_SERVICE_CATEGORY_MODAL = '[serviceCategory] SHOW MODAL';
export const HIDE_SERVICE_CATEGORY_MODAL = '[serviceCategory] HIDE MODAL';
// Spinner
export const UPDATE_SERVICE_CATEGORY_SUCCESS = '[serviceCategory] Update success';
export const UPDATE_SERVICE_CATEGORY_ERROR = '[serviceCategory] Update Error';
export const FETCH_SERVICE_CATEGORY_SUCCESS = '[serviceCategory] Fetch success';
export const FETCH_SERVICE_CATEGORY_ERROR = '[serviceCategory] Fetch Error';
export const SHOW_SERVICE_CATEGORY_SPINNER = '[serviceCategory - ui] show spinner';
export const HIDE_SERVICE_CATEGORY_SPINNER = '[serviceCategory - ui] hide spinner';

export const getServiceCategory = (id) => ({
  type: GET_SERVICE_CATEGORY,
  payload: { _id: id },
});

export const createServiceCategory = (data) => ({
  type: CREATE_SERVICE_CATEGORY,
  payload: data,
});

export const updateServiceCategory = (data) => ({
  type: UPDATE_SERVICE_CATEGORY,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_SERVICE_CATEGORY_MODAL,
});

export const hideModal = () => ({
  type: HIDE_SERVICE_CATEGORY_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_SERVICE_CATEGORY_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_SERVICE_CATEGORY_SPINNER,
});

export const updateObjectServiceCategory = (data) => ({
  type: UPDATE_OBJECT_SERVICE_CATEGORY,
  payload: data,
});
