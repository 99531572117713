import {
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_ERROR,
  GET_NOTIFICATIONS,
  updateNotifications,
  showSpinner,
  hideSpinner,
} from '../actions/notifications';
import { apiRequest } from '../actions/api';

const URL = 'mrp-notification-queue';

export const getNotificationsFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_NOTIFICATIONS) {
    dispatch(
      apiRequest(
        'GET',
        URL,
        action.payload,
        FETCH_NOTIFICATIONS_SUCCESS,
        FETCH_NOTIFICATIONS_ERROR,
        ['listAll'],
      ),
    );
    dispatch(showSpinner());
  }
};

export const processNotificationsCollection = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_NOTIFICATIONS_SUCCESS) {
    dispatch(updateNotifications(action.payload));
    dispatch(hideSpinner());
  }
};

export const notificationsMdl = [getNotificationsFlow, processNotificationsCollection];
