export const GET_OWNER = '[owner] GET';
export const UPDATE_OWNER = '[owner] UPDATE';
export const CREATE_OWNER = '[owner] CREATE';
export const DELETE_OWNER = '[owner] DELETE';
export const UPDATE_OBJECT_OWNER = '[owner] UPDATE OBJECT OWNER';
// UI
export const SHOW_OWNER_MODAL = '[owner] SHOW MODAL';
export const HIDE_OWNER_MODAL = '[owner] HIDE MODAL';
// Spinner
export const UPDATE_OWNER_SUCCESS = '[owner] Update success';
export const UPDATE_OWNER_ERROR = '[owner] Update Error';
export const FETCH_OWNER_SUCCESS = '[owner] Fetch success';
export const FETCH_OWNER_ERROR = '[owner] Fetch Error';
export const SHOW_OWNER_SPINNER = '[owner - ui] show spinner';
export const HIDE_OWNER_SPINNER = '[owner - ui] hide spinner';

export const getOwner = (id) => ({
  type: GET_OWNER,
  payload: { _id: id },
});

export const createOwner = (data) => ({
  type: CREATE_OWNER,
  payload: data,
});

export const updateOwner = (data) => ({
  type: UPDATE_OWNER,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_OWNER_MODAL,
});

export const hideModal = () => ({
  type: HIDE_OWNER_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_OWNER_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_OWNER_SPINNER,
});

export const updateObjectOwner = (data) => ({
  type: UPDATE_OBJECT_OWNER,
  payload: data,
});
