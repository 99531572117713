import {
  GET_CHECK_OUT,
  UPDATE_CHECK_OUT,
  UPDATE_OBJECT_CHECK_OUT,
  CREATE_CHECK_OUT,
  DELETE_CHECK_OUT,
} from '../actions/checkOut';

export function checkOutReducer(checkOut = {}, action) {
  switch (action.type) {
    case GET_CHECK_OUT:
      return action.payload;
    case UPDATE_CHECK_OUT:
      return action.payload;
    case CREATE_CHECK_OUT:
      return action.payload;
    case DELETE_CHECK_OUT:
      return action.payload;
    case UPDATE_OBJECT_CHECK_OUT:
      return action.payload;

    default:
      return checkOut;
  }
}
