import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import { ArrowBack, Cancel as DeleteIcon } from '@material-ui/icons';
import { getCustomerMMs } from '../../../redux/actions/customerMMs';
import { showModal } from '../../../redux/actions/customerMM';
import { CUSTOMER_KEY } from '../../../config/consts';
import { tableStyles } from '../../../styles/tableStyles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { InputContainer, Container, ButtonContainer } from './styles';
import EventDocumentsSection from '../../../components/EventDocumentsSection';
import TagsField from '../../../components/TagsField';
import getColorFromClass from '../../../utils/Equipment/getColorFromClass';
import { Button, MenuItem } from '@material-ui/core';
import {
  createReclass,
  updateReclass,
  getReclass,
} from '../../../redux/actions/reclass';
import { downloadReport } from '../../../services/Download';
import { getReclassDamages } from '../../../redux/actions/reclassDamages';
import handlePost from '../../../utils/Event/handlePost';

const chipStyles = makeStyles((theme) => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 8,
  },
}));

const Reclass = ({ dispatch, reclass, reclassDamages, loggedUser }) => {
  const { customer, id } = useParams();
  const history = useHistory();
  const { state } = useLocation();
  const classes = tableStyles();
  const chipsClasses = chipStyles();

  const [data, setData] = React.useState({
    individualData: [],
    note: '',
    files: [],
    tags: [],
    newFiles: [],
  });

  const damageDescriptionMap = React.useMemo(() => {
    const map = {};
    reclassDamages.forEach(({ _id, description }) => (map[_id] = description));
    return map;
  }, [reclassDamages]);

  const {
    individualData,
    note,
    files,
    facilityReferenceID,
    newFiles,
    fileNotes,
  } = data;

  React.useEffect(() => {
    if (state?.movimentations) {
      setData((currentData) => ({
        ...currentData,
        individualData: state.movimentations.map((mov) => ({
          movimentation: mov,
          class: mov.classification || 'A',
          qty: mov.qty,
          footage: mov.footage,
          tags: [],
        })),
      }));
    }
  }, [state]);

  React.useEffect(() => {
    if (!id || id === 'new') return;
    dispatch(getReclass(id));
  }, [id]);

  React.useEffect(() => {
    if (id === 'new' || !reclass) return;
    setData(reclass);
  }, [reclass]);

  useEffect(() => {
    if (!localStorage.getItem(CUSTOMER_KEY))
      history.replace('/equipments', { origin: 'mm_number' });
  }, []);

  useEffect(() => {
    dispatch(getCustomerMMs({ page: 1, limit: 99999, filter: { customer } }));
  }, []);

  useEffect(() => {
    dispatch(getReclassDamages({ page: 1, limit: 99999 }));
  }, []);

  const handleBack = () => {
    history.goBack();
  };

  function handleEdit(e) {
    const { name, value } = e.target;
    setData((currentData) => ({ ...currentData, [name]: value }));
  }
  function handleEditOnTable(index) {
    function handler(e) {
      const { name, value } = e.target;
      setData((currentData) => {
        const newReclassData = [...currentData.individualData];
        newReclassData[index] = { ...newReclassData[index], [name]: value };
        return { ...currentData, individualData: newReclassData };
      });
    }
    return handler;
  }

  function handleDownload() {
    downloadReport(id).then(() => dispatch(getReclass(id)));
  }

  function handleDeleteDamageDescription(individualItemIndex) {
    return function (_, index) {
      const damageDescriptionCopy = [
        ...individualData[individualItemIndex].damageDescription,
      ];
      damageDescriptionCopy.splice(index, 1);
      setData((currentData) => {
        const individualDataCopy = [...currentData.individualData];
        individualDataCopy[individualItemIndex].damageDescription =
          damageDescriptionCopy;
        return { ...currentData, individualData: individualDataCopy };
      });
    };
  }

  function handleFiles({ files, notes }) {
    setData((currentData) => ({
      ...currentData,
      newFiles: files,
      fileNotes: notes,
    }));
  }

  const disabled = id !== 'new';

  return (
    <>
      <Container>
        <div className={classes.root} style={{ overflow: 'visible' }}>
          <Paper className={classes.paper} style={{ minHeight: '85%' }}>
            <Toolbar className={classes.toolBar}>
              <span>
                <IconButton onClick={handleBack}>
                  <ArrowBack />
                </IconButton>
                <Typography
                  className={classes.title}
                  variant="h6"
                  id="tableTitle"
                  component="span"
                >
                  Reclass Tracking
                </Typography>
              </span>
            </Toolbar>
            <TableContainer className={classes.table}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Equipment Name</TableCell>
                    <TableCell>RAMP Unique ID</TableCell>
                    <TableCell>Customer MM No</TableCell>
                    <TableCell style={{ backgroundColor: '#bbdefb' }}>
                      Total Qty. EA
                    </TableCell>
                    <TableCell style={{ backgroundColor: '#bbdefb' }}>
                      Footage
                    </TableCell>
                    <TableCell style={{ backgroundColor: '#bbdefb' }}>
                      COPAS Class
                    </TableCell>
                    <TableCell style={{ backgroundColor: '#c8e6c9' }}>
                      Reclass Qty. EA
                    </TableCell>
                    <TableCell style={{ backgroundColor: '#c8e6c9' }}>
                      Reclass Footage
                    </TableCell>
                    <TableCell style={{ backgroundColor: '#c8e6c9' }}>
                      Reclass Condition
                    </TableCell>
                    <TableCell style={{ backgroundColor: '#c8e6c9' }}>
                      Damage Description
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: '#9370db66',
                        textAlign: 'center',
                      }}
                    >
                      Tags
                    </TableCell>
                    <TableCell>Supplier Batch No</TableCell>
                    <TableCell>Heat/Lot</TableCell>
                    <TableCell>RBW</TableCell>
                    <TableCell>Facility Reference ID</TableCell>
                    <TableCell>Assigned Project</TableCell>
                    <TableCell>Assigned Well</TableCell>
                    <TableCell>Assigned Charge Code</TableCell>
                    <TableCell>RAMP Tag No</TableCell>
                    <TableCell>RINA Work Order No</TableCell>
                    <TableCell>SLOC</TableCell>
                    <TableCell>Material Layout</TableCell>
                    <TableCell>Allocated</TableCell>
                    <TableCell>Supplier</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {individualData?.map((itemData, index) => (
                    <TableRow>
                      <TableCell>
                        {itemData.movimentation?.customer_MM?.short_description}
                      </TableCell>
                      <TableCell>
                        {itemData.movimentation?.individual_item_id}
                      </TableCell>
                      <TableCell>
                        {itemData.movimentation?.customer_MM?.mm_number}
                      </TableCell>
                      <TableCell style={{ backgroundColor: '#e3f2fd' }}>
                        {itemData.movimentation.qty}
                      </TableCell>
                      <TableCell style={{ backgroundColor: '#e3f2fd' }}>
                        {itemData.movimentation.footage}
                      </TableCell>
                      <TableCell style={{ backgroundColor: '#e3f2fd' }}>
                        {itemData.movimentation.classification}
                      </TableCell>
                      <TableCell
                        style={{
                          minWidth: '120px',
                          backgroundColor: '#e8f5e9',
                        }}
                      >
                        <InputContainer>
                          <TextField
                            onChange={handleEditOnTable(index)}
                            size="small"
                            name="qty"
                            type="text"
                            variant="outlined"
                            value={itemData.qty}
                            fullWidth
                            disabled={
                              itemData.movimentation.material_layout.toUpperCase() !==
                                'BATCH' || disabled
                            }
                          />
                        </InputContainer>
                      </TableCell>
                      <TableCell
                        style={{
                          minWidth: '120px',
                          backgroundColor: '#e8f5e9',
                        }}
                      >
                        <InputContainer>
                          <TextField
                            onChange={handleEditOnTable(index)}
                            size="small"
                            name="footage"
                            type="text"
                            variant="outlined"
                            value={itemData.footage}
                            fullWidth
                            disabled={
                              itemData.movimentation.material_layout.toUpperCase() !==
                                'BATCH' || disabled
                            }
                          />
                        </InputContainer>
                      </TableCell>
                      <TableCell
                        style={{
                          minWidth: '120px',
                          backgroundColor: '#e8f5e9',
                        }}
                      >
                        <InputContainer>
                          <TextField
                            onChange={handleEditOnTable(index)}
                            size="small"
                            name="class"
                            type="text"
                            variant="outlined"
                            value={itemData.class}
                            fullWidth
                            select
                            style={{
                              backgroundColor: getColorFromClass(
                                itemData.class
                              ),
                            }}
                            disabled={disabled}
                          >
                            <MenuItem value="A">A</MenuItem>
                            <MenuItem value="AR">AR</MenuItem>
                            <MenuItem value="C">C</MenuItem>
                            <MenuItem value="CR">CR</MenuItem>
                            <MenuItem value="B">B</MenuItem>
                            <MenuItem value="BR">BR</MenuItem>
                            <MenuItem value="E">E</MenuItem>
                          </TextField>
                        </InputContainer>
                      </TableCell>
                      <TableCell
                        style={{
                          minWidth: 350,
                          backgroundColor: '#e8f5e9',
                        }}
                      >
                        <InputContainer>
                          <TextField
                            onChange={handleEditOnTable(index)}
                            size="small"
                            name="damageDescription"
                            type="text"
                            variant="outlined"
                            value={itemData.damageDescription || []}
                            fullWidth
                            select
                            SelectProps={{
                              multiple: true,
                              renderValue: (selected) => (
                                <div className={chipsClasses.chips}>
                                  {selected.map((value) => (
                                    <Chip
                                      key={value}
                                      label={damageDescriptionMap[value]}
                                      className={chipsClasses.chip}
                                      onDelete={handleDeleteDamageDescription(
                                        index
                                      )}
                                      deleteIcon={
                                        <DeleteIcon
                                          onMouseDown={(e) =>
                                            e.stopPropagation()
                                          }
                                        />
                                      }
                                    />
                                  ))}
                                </div>
                              ),
                            }}
                            disabled={disabled}
                          >
                            {reclassDamages.map(({ description, _id }) => (
                              <MenuItem value={_id}>{description}</MenuItem>
                            ))}
                          </TextField>
                        </InputContainer>
                      </TableCell>
                      <TableCell
                        style={{ backgroundColor: '#9370db33', minWidth: 200 }}
                      >
                        <InputContainer>
                          <TagsField
                            value={itemData.tags}
                            name="tags"
                            onChange={handleEditOnTable(index)}
                            disabled={disabled}
                          />
                        </InputContainer>
                      </TableCell>
                      <TableCell>{itemData.movimentation?.batch_no}</TableCell>
                      <TableCell>{itemData.movimentation.heat}</TableCell>
                      <TableCell>{itemData.movimentation.RBW}</TableCell>
                      <TableCell>
                        {itemData.movimentation.facility_reference_id}
                      </TableCell>
                      <TableCell>
                        {
                          itemData.movimentation?.assigned_customer_charge_code
                            ?.well?.project?.name
                        }
                      </TableCell>
                      <TableCell>
                        {
                          itemData.movimentation?.assigned_customer_charge_code
                            ?.well?.name
                        }
                      </TableCell>
                      <TableCell>
                        {
                          itemData.movimentation?.assigned_customer_charge_code
                            ?.original_charge_code
                        }
                      </TableCell>
                      <TableCell>{itemData.movimentation.tag_number}</TableCell>
                      <TableCell>
                        {itemData.movimentation.rina_work_order_no}
                      </TableCell>
                      <TableCell>
                        {itemData.movimentation?.SLOC?.sloc_name}
                      </TableCell>
                      <TableCell>
                        {itemData.movimentation.material_layout}
                      </TableCell>
                      <TableCell>
                        {itemData.movimentation.allocated_release_number ||
                        itemData.movimentation.allocated_batch_number ||
                        itemData.movimentation.allocated_work_order_number ? (
                          <span>YES</span>
                        ) : (
                          <span>NO</span>
                        )}
                      </TableCell>
                      <TableCell>{itemData.movimentation.supplier}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid container style={{ padding: 10 }}>
              <Grid item lg={3} md={6} sm={12} xs={12}>
                <InputContainer>
                  <TextField
                    onChange={handleEdit}
                    size="small"
                    name="facilityReferenceID"
                    label="Facility Reference ID"
                    type="text"
                    variant="outlined"
                    multiline
                    value={facilityReferenceID}
                    fullWidth
                    disabled={disabled}
                  />
                </InputContainer>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <InputContainer>
                  <TextField
                    onChange={handleEdit}
                    size="small"
                    name="note"
                    label="Reclassification Note"
                    type="text"
                    variant="outlined"
                    multiline
                    value={note}
                    fullWidth
                    disabled={disabled}
                  />
                </InputContainer>
              </Grid>
            </Grid>
          </Paper>

          <Paper className={classes.paper}>
            <EventDocumentsSection
              files={files}
              id={id}
              newFiles={newFiles}
              fileNotes={fileNotes}
              onChangeFiles={handleFiles}
            />
            <Grid container style={{ padding: '0px 10px 10px 10px' }}>
              <Grid lg={12} md={12} sm={12} xs={12}>
                <ButtonContainer>
                  <Button
                    color="primary"
                    variant={id === 'new' ? 'contained' : 'outlined'}
                    style={{ marginRight: 5 }}
                    onClick={handlePost(
                      id,
                      data,
                      loggedUser,
                      createReclass,
                      updateReclass,
                      dispatch
                    )}
                  >
                    {id === 'new' ? 'Post' : 'Update'} Transaction
                  </Button>
                  <Button
                    disabled={id === 'new'}
                    color="primary"
                    variant="contained"
                    style={{ marginRight: 5 }}
                    onClick={handleDownload}
                  >
                    Create Reclass Tracking
                  </Button>
                </ButtonContainer>
              </Grid>
            </Grid>
          </Paper>
        </div>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  reconcilesLoading: state.ui.reconcilesLoading,
  reconciles: state.reconciles,
  reclass: state.reclass,
  reclassDamages: state.reclassDamages?.docs || [],
  loggedUser: state.loggedUser,
});

export default connect(mapStateToProps)(Reclass);
