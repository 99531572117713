import { GET_VPASS, UPDATE_VPASS } from '../actions/vpass';

const initState = {
  docs: [],
  page: 1,
  limit: 10,
  totalDocs: 0,
};

export function vpassReducer(vpass = initState, action) {
  switch (action.type) {
    case GET_VPASS:
      return action.payload;
    case UPDATE_VPASS:
      return action.payload;

    default:
      return vpass;
  }
}
