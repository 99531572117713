/* eslint-disable no-return-assign */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid, IconButton, Button } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { ArrowBack, Add } from '@material-ui/icons';
import axios from 'axios';
import { tableStyles } from '../../../styles/tableStyles';
import { getMovimentation } from '../../../redux/actions/movimentation';
import {
  updateMovimentations,
  getMovimentations,
} from '../../../redux/actions/movimentations';
import Spinner from '../../../components/Spinner/index';
import InventoryFilter from '../../../components/InventoryFilter/index';
import Alert from '../../../components/Alert/index';
import { CUSTOMER_KEY, apiUrl } from '../../../config/consts';
import toast from '../../../utils/Toastify';
import { Title, Container } from './styles';
import getRINADescription from '../../../utils/Equipment/getRINADescription';

const AddChildren = ({
  dispatch,
  movimentation,
  movimentationSpinner,
  handleBack,
  movimentations,
  movimentationsSpinner,
  id,
  handleNewAdded,
}) => {
  const classes = tableStyles();
  const [movimentationsFormatted, setMovimentationsFormatted] = useState([]);
  const [customer, setCustomer] = useState(null);
  const [selectedChildren, setSelectChildren] = useState([]);
  const [newChildren, setNewChildren] = useState([]);
  const [selectedChildrenName, setSelectChildrenName] = useState([]);

  useEffect(() => {
    dispatch(getMovimentations({ page: 1, limit: 50 }));
  }, []);

  useEffect(() => {
    if (!id) return;
    dispatch(getMovimentation(id));
  }, [id]);

  useEffect(() => {
    setCustomer(localStorage.getItem(CUSTOMER_KEY));
  }, []);

  useEffect(() => {
    if (movimentation.children) {
      setSelectChildren(movimentation.children.map((el) => el._id));
      setSelectChildrenName(
        movimentation.children.map((el) => el?.customer_MM?.short_description)
      );
    }
  }, [movimentation]);

  useEffect(() => {
    if (movimentations) {
      setMovimentationsFormatted(movimentations.docs);
    }
  }, [movimentations]);

  const selectAChild = async (_id, name, value) => {
    if (value) {
      setSelectChildren([...selectedChildren, _id]);
      const alreadyChildren =
        movimentation.children.findIndex((el) => el._id === _id) > -1;
      if (!alreadyChildren) setNewChildren([...newChildren, _id]);
      setSelectChildrenName([...selectedChildrenName, name]);
    } else {
      const array = JSON.parse(JSON.stringify(selectedChildren));
      if (array.indexOf(_id) !== -1) {
        array.splice(array.indexOf(_id), 1);
        await setSelectChildren(array);
      }
      const arrayName = JSON.parse(JSON.stringify(selectedChildrenName));
      if (arrayName.indexOf(name) !== -1) {
        arrayName.splice(arrayName.indexOf(_id), 1);
        await setSelectChildrenName(arrayName);
      }
    }
  };

  const valueToTheField = (_id) => {
    if (selectedChildren.indexOf(_id) === -1) {
      return false;
    }

    return true;
  };

  const handleSave = async () => {
    let htmlContent = '';
    selectedChildrenName.forEach((name) => (htmlContent += `- ${name}<br>`));
    const result = await Alert(
      'Do you want to add this following equipments? ',
      null,
      true,
      htmlContent
    );
    if (result && result.confirm) {
      axios
        .put(`${apiUrl}build-of-material/change-parent`, {
          movimentations: selectedChildren,
          parent: movimentation._id,
          inventory: localStorage.getItem(CUSTOMER_KEY),
        })
        .then(() => {
          if (handleNewAdded) handleNewAdded(newChildren);
          toast.addSuccess('Equipment updated successfully.');
          handleBack();
        })
        .catch((error) => {
          toast.addError(
            error?.response?.data?.error ||
              error?.response?.data?.message ||
              error?.message
          );
        });
    }
  };

  const styles = {
    headerContent: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      paddingBottom: 15,
      position: 'relative',
    },
    hierarchyMenu: {
      position: 'absolute',
      right: 30,
      top: 5,
    },
    addChildrenButton: {
      marginTop: 15,
    },
  };

  return (
    <Grid
      container
      className="headerContainer"
      style={{ opacity: movimentationSpinner ? 0.3 : 1 }}
    >
      <Grid item lg={12} md={12} sm={12} xs={12} style={styles.headerContent}>
        <IconButton onClick={handleBack}>
          <ArrowBack />
        </IconButton>
        <Title>
          {movimentation && movimentation?.customer_MM?.short_description}
        </Title>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} align="left">
        <InventoryFilter />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} align="center">
        <Container>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>RINA Unique ID</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Material Layout</TableCell>
                  <TableCell colSpan={3}>RINA Description</TableCell>
                  <TableCell>RINA MM no</TableCell>
                  <TableCell>Total Qty. (EA)</TableCell>
                  <TableCell>Footage</TableCell>
                  <TableCell>Supplier Batch No</TableCell>
                  <TableCell>SLOC</TableCell>
                  <TableCell>Assigned Project</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {movimentationsSpinner && (
                  <TableRow className={classes.row}>
                    <TableCell colSpan="18" className={classes.cell}>
                      <Spinner parent="Table" />
                    </TableCell>
                  </TableRow>
                )}
                {!movimentationsSpinner &&
                  movimentationsFormatted.map((rr) => (
                    <TableRow key={rr._id} className={classes.row}>
                      <TableCell className={classes.cell}>
                        <Checkbox
                          checked={valueToTheField(rr._id)}
                          onChange={(e) =>
                            selectAChild(
                              rr._id,
                              rr?.customer_MM?.short_description,
                              e.target.checked
                            )
                          }
                          color="primary"
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {rr.individual_item_id}
                      </TableCell>
                      <TableCell
                        className={classes.cell}
                        style={{
                          color: rr.status === 'Available' ? 'green' : 'black',
                        }}
                      >
                        <b>{rr.status}</b>
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {rr.material_layout}
                      </TableCell>
                      <TableCell colSpan={3} className={classes.cell}>
                        <b>{getRINADescription(rr?.customer_MM)}</b>
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {rr.customer_MM && rr.customer_MM.rina_mm_number}
                      </TableCell>
                      <TableCell className={classes.cell}>{rr.qty}</TableCell>
                      <TableCell className={classes.cell}>
                        {rr.footage}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {rr.batch_no}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {rr.SLOC && rr.SLOC.sloc_name}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {rr.original_charge &&
                          rr.original_charge.well &&
                          rr.original_charge.well.project &&
                          rr.original_charge.well.project.name}
                      </TableCell>
                    </TableRow>
                  ))}
                {!movimentationsSpinner && movimentations.length === 0 && (
                  <TableRow className={classes.row}>
                    <TableCell colSpan="12" style={{ fontSize: '1rem' }}>
                      <div style={{ width: '100%', textAlign: 'center' }}>
                        Please, filter the inventory and select the items
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} align="center">
        <Button
          onClick={handleSave}
          variant="contained"
          color="secondary"
          style={{ marginTop: 10 }}
        >
          Add this items <Add />
        </Button>
      </Grid>
    </Grid>
  );
};

export default connect((state) => ({
  movimentation: state.movimentation,
  movimentationSpinner: state.ui.movimentationLoading,
  movimentations: state.movimentations,
  movimentationsSpinner: state.ui.movimentationsLoading,
}))(AddChildren);
