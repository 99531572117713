import React from 'react';
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from '@material-ui/core';
import Thumbnail from '../../../../../components/Thumbnail';
import { apiUrl } from '../../../../../config/consts';

function ServiceTable({ services, hasNote }) {
  return (
    <Box marginBottom={4}>
      <Typography variant="h6">Services</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Service</TableCell>
            {hasNote && (
              <>
                <TableCell>Notes</TableCell>
                <TableCell>Photo Record</TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {!services.length && (
            <TableRow>
              <TableCell colSpan={hasNote ? 2 : 1} align="center">
                No services
              </TableCell>
            </TableRow>
          )}
          {services.map(({ service, note, photo }) => (
            <TableRow key={service}>
              <TableCell>{service}</TableCell>
              {hasNote && (
                <>
                  <TableCell>{note}</TableCell>
                  <TableCell>
                    {photo && (
                      <Thumbnail
                        src={`${apiUrl}vault/download/${photo?.file?.filename}`}
                        expandable
                      />
                    )}
                  </TableCell>
                </>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}

export default ServiceTable;
