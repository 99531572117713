import React, { useEffect, useState, Fragment } from 'react';
import {
  Button,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { ArrowBack, Delete, EditRounded } from '@material-ui/icons';
import { add, format } from 'date-fns';
import { connect } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import EventDocumentsSection from '../../../components/EventDocumentsSection';
import ModalContainer from '../../../components/ModalContainer/index';
import TagsField from '../../../components/TagsField';
import { CUSTOMER_KEY } from '../../../config/consts';
import { getCustomerMMs } from '../../../redux/actions/customerMMs';
import {
  createReconcile,
  getReconcile,
  hideModal,
  showModal,
  updateReconcile,
  createReconcileFromCounting,
} from '../../../redux/actions/reconcile';
import { getSLOCS } from '../../../redux/actions/slocs';
import { downloadReport } from '../../../services/Download';
import { tableStyles } from '../../../styles/tableStyles';
import Toastify from '../../../utils/Toastify';
import Maintenance from '../../Modals/Maintenance';
import {
  ConsumptionField,
  DefaultField,
  MaterialMasterField,
  SLOCField,
  StatusField,
} from './Components';
import {
  ButtonContainer,
  Container,
  InputContainer,
  ModalWrapper,
} from './styles';
import { effectedDateMap } from '../../../utils/Maintenance/maps';
import { getSpecLibrary } from '../../../redux/actions/vault';
import { getSpecName, getMaintenance } from '../../../utils/Maintenance';
import { getMaintenanceCategories } from '../../../redux/actions/maintenanceCategories';
import handlePost from '../../../utils/Event/handlePost';

const TransactionTypes = {
  'Status Change': {
    fields: [
      { Component: StatusField, label: 'Equipment Status', key: 'status' },
    ],
  },
  'Removed From Inventory': { fields: [] },
  'Material Master Change': {
    fields: [
      {
        Component: MaterialMasterField,
        label: 'Customer MM No.',
        key: 'materialMaster',
      },
    ],
  },
  'Maintenance Data Change': { fields: [], maintenanceData: true },
  'Physical Movement': {
    fields: [
      {
        Component: DefaultField,
        label: 'Bin Location',
        key: 'binLocation',
      },
      {
        Component: DefaultField,
        label: 'Rack Location',
        key: 'rackLocation',
      },
      {
        Component: DefaultField,
        label: 'Warehouse Location',
        key: 'warehouseLocation',
      },
      {
        Component: SLOCField,
        label: 'Storage Location',
        key: 'SLOC',
      },
    ],
  },
  'Data Adjustment': {
    fields: [
      {
        Component: DefaultField,
        label: 'Heat Number',
        key: 'heat',
      },
      {
        Component: DefaultField,
        label: 'Jt. / Run Number',
        key: 'jointNumber',
      },
    ],
    footageEdit: true,
    quantityEdit: true,
  },
  Consumption: {
    fields: [
      { Component: ConsumptionField, label: 'Consumed By', key: 'consumedBy' },
    ],
  },
};

const Reconcile = ({
  dispatch,
  maintenanceModal,
  reconcile,
  specLibrary,
  maintenanceCategories,
  loggedUser,
  isModal,
  equipment = [],
  workOrderNumber,
  onCloseModal,
}) => {
  const { id: idParam } = useParams();
  const id = isModal ? 'new' : idParam;
  const history = useHistory();
  const { state } = useLocation();
  const classes = tableStyles();
  const [modalContent, setModalContent] = useState(null);
  const [noteError, setNoteError] = useState(false);
  const [fieldError, setFieldError] = useState([]);
  const [data, setData] = useState({
    individualData: [],
    SLOC: '',
    facilityReferenceID: '',
    binLocation: '',
    rackLocation: '',
    warehouseLocation: '',
    status: '',
    divestmentNumber: '',
    batchNumber: '',
    tallyId: '',
    note: '',
    materialMaster: '',
    maintenances: [],
    files: [],
    newFiles: [],
  });

  const customer = localStorage.getItem(CUSTOMER_KEY);

  const {
    individualData,
    note,
    maintenances,
    files,
    newFiles,
    fileNotes,
    transactionType,
  } = data;

  useEffect(() => {
    if (!equipment.length) return;
    setData((currentData) => {
      const finalState = {
        ...currentData,
        individualData: equipment.map((movimentation) => ({
          movimentation,
          qty: movimentation.qty,
          footage: movimentation.footage,
          tags: [],
        })),
        work_order_no: workOrderNumber,
      };
      return finalState;
    });
  }, [equipment]);

  useEffect(() => {
    if (state?.movimentations) {
      setData((currentData) => ({
        ...currentData,
        individualData: state.movimentations.map((movimentation) => ({
          movimentation,
          qty: movimentation.qty,
          footage: movimentation.footage,
          tags: [],
        })),
      }));
    }
  }, [state]);
  useEffect(() => {
    dispatch(getSpecLibrary());
  }, []);
  useEffect(() => {
    dispatch(getMaintenanceCategories({ filter: { listAll: true } }));
  }, []);
  useEffect(() => {
    dispatch(getSLOCS({ filter: { customer, listAll: true } }));
  }, []);
  useEffect(() => {
    dispatch(getCustomerMMs({ filter: { customer, listAll: true } }));
  }, []);

  useEffect(() => {
    if (!id || id === 'new') return;
    dispatch(getReconcile(id));
  }, [id]);

  useEffect(() => {
    if (id === 'new' || !reconcile || isModal) return;
    setData(reconcile);
  }, [reconcile]);

  useEffect(() => {
    if (!customer) history.replace('/equipments', { origin: 'mm_number' });
  }, []);

  const handleBack = () => {
    history.goBack();
  };

  const handleOpenModal = () => {
    dispatch(showModal());
  };

  const handleEditMaintenance = (index) => {
    setModalContent(index);
    handleOpenModal();
  };

  const handleRemoveMaintenance = (index) =>
    setData((currentState) => {
      const currentMaintenance = currentState.maintenances;
      currentMaintenance.splice(index, 1);
      return {
        ...currentState,
        maintenances: currentMaintenance,
      };
    });

  const handleCloseModal = () => {
    setModalContent(null);
    dispatch(hideModal());
  };

  function handleEdit(e) {
    const { name, value } = e.target;
    setData((currentData) => ({ ...currentData, [name]: value }));
  }

  function handleSave() {
    if (!note) {
      Toastify.addError('Error - missing fields.');
      return setNoteError(true);
    }
    if (transactionType === 'Consumption' && !data.consumedBy) {
      Toastify.addError('Error - missing fields.');
      return setFieldError((currentState) => [...currentState, 'consumedBy']);
    }
    handlePost(
      id,
      data,
      loggedUser,
      isModal ? createReconcileFromCounting : createReconcile,
      updateReconcile,
      dispatch
    )();
  }

  function formatDate(date) {
    return format(new Date(date), 'MM-dd-yyyy');
  }

  function calculateNextInspectionDate(date, days) {
    return formatDate(add(new Date(date), { days }));
  }

  function handleDownload() {
    downloadReport(id).then(() => dispatch(getReconcile(id)));
  }

  function handleEditOnTable(index) {
    function handler(e) {
      const { name, value } = e.target;
      setData((currentData) => {
        const newAllocationData = [...currentData.individualData];
        newAllocationData[index] = {
          ...newAllocationData[index],
          [name]: value,
        };
        return { ...currentData, individualData: newAllocationData };
      });
    }
    return handler;
  }

  function handleFiles({ files, notes }) {
    setData((currentData) => ({
      ...currentData,
      newFiles: files,
      fileNotes: notes,
    }));
  }

  const Wrapper = isModal ? ModalWrapper : Fragment;

  return (
    <Wrapper>
      <Container>
        <div className={classes.root} style={{ overflow: 'visible' }}>
          <Paper
            className={classes.paper}
            style={{
              minHeight: isModal ? '21.87rem' : '75%',
              maxHeight: '50vh',
            }}
          >
            <Toolbar
              className={classes.toolBar}
              style={{ paddingLeft: isModal ? 25 : undefined }}
            >
              <span>
                {!isModal && (
                  <IconButton onClick={handleBack}>
                    <ArrowBack />
                  </IconButton>
                )}
                <Typography
                  className={classes.title}
                  variant="h6"
                  id="tableTitle"
                  component="span"
                >
                  Reconcile
                </Typography>
              </span>
            </Toolbar>
            <TableContainer className={classes.table}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Equipment Name</TableCell>
                    <TableCell>RAMP Unique ID</TableCell>
                    <TableCell>Customer MM No</TableCell>
                    <TableCell
                      style={{
                        backgroundColor: TransactionTypes[transactionType]
                          ?.quantityEdit
                          ? '#bbdefb'
                          : undefined,
                      }}
                    >
                      Total Qty. EA
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: TransactionTypes[transactionType]
                          ?.footageEdit
                          ? '#bbdefb'
                          : undefined,
                      }}
                    >
                      Footage
                    </TableCell>
                    {TransactionTypes[transactionType]?.quantityEdit && (
                      <TableCell style={{ backgroundColor: '#c8e6c9' }}>
                        New Total Qty (EA)
                      </TableCell>
                    )}
                    {TransactionTypes[transactionType]?.footageEdit && (
                      <TableCell style={{ backgroundColor: '#c8e6c9' }}>
                        New Footage
                      </TableCell>
                    )}
                    <TableCell
                      style={{
                        backgroundColor: '#9370db66',
                        textAlign: 'center',
                      }}
                    >
                      Tags
                    </TableCell>
                    <TableCell>Supplier Batch No</TableCell>
                    <TableCell>COPAS Class</TableCell>
                    <TableCell>Heat/Lot</TableCell>
                    <TableCell>RBW</TableCell>
                    <TableCell>Facility Reference ID</TableCell>
                    <TableCell>Assigned Project</TableCell>
                    <TableCell>Assigned Well</TableCell>
                    <TableCell>Assigned Charge Code</TableCell>
                    <TableCell>RAMP Tag No</TableCell>
                    <TableCell>RINA Work Order No</TableCell>
                    <TableCell>SLOC</TableCell>
                    <TableCell>Material Layout</TableCell>
                    <TableCell>Allocated</TableCell>
                    <TableCell>Supplier</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {individualData?.map(
                    ({ movimentation, tags, qty, footage }, index) => (
                      <TableRow>
                        <TableCell>
                          {movimentation?.customer_MM?.short_description}
                        </TableCell>
                        <TableCell>
                          {movimentation?.individual_item_id}
                        </TableCell>
                        <TableCell>
                          {movimentation?.customer_MM?.mm_number}
                        </TableCell>
                        <TableCell
                          style={{
                            backgroundColor: TransactionTypes[transactionType]
                              ?.quantityEdit
                              ? '#e3f2fd'
                              : undefined,
                          }}
                        >
                          {movimentation.qty}
                        </TableCell>
                        <TableCell
                          style={{
                            backgroundColor: TransactionTypes[transactionType]
                              ?.footageEdit
                              ? '#e3f2fd'
                              : undefined,
                          }}
                        >
                          {movimentation.footage}
                        </TableCell>
                        {TransactionTypes[transactionType]?.quantityEdit && (
                          <TableCell
                            style={{
                              minWidth: '120px',
                              backgroundColor: '#e8f5e9',
                            }}
                          >
                            <InputContainer>
                              <TextField
                                onChange={handleEditOnTable(index)}
                                size="small"
                                name="qty"
                                type="text"
                                variant="outlined"
                                value={qty}
                                disabled={
                                  movimentation.material_layout.toUpperCase() !==
                                    'BATCH' || id !== 'new'
                                }
                                fullWidth
                              />
                            </InputContainer>
                          </TableCell>
                        )}
                        {TransactionTypes[transactionType]?.footageEdit && (
                          <TableCell
                            style={{
                              minWidth: '120px',
                              backgroundColor: '#e8f5e9',
                            }}
                          >
                            <InputContainer>
                              <TextField
                                disabled={id !== 'new'}
                                onChange={handleEditOnTable(index)}
                                size="small"
                                name="footage"
                                type="text"
                                variant="outlined"
                                value={footage}
                                fullWidth
                              />
                            </InputContainer>
                          </TableCell>
                        )}
                        <TableCell
                          style={{
                            backgroundColor: '#9370db33',
                            minWidth: 200,
                          }}
                        >
                          <InputContainer>
                            <TagsField
                              disabled={id !== 'new'}
                              value={tags}
                              name="tags"
                              onChange={handleEditOnTable(index)}
                            />
                          </InputContainer>
                        </TableCell>
                        <TableCell>{movimentation?.batch_no}</TableCell>
                        <TableCell>{movimentation.classification}</TableCell>
                        <TableCell>{movimentation.heat}</TableCell>
                        <TableCell>{movimentation.RBW}</TableCell>
                        <TableCell>
                          {movimentation.facility_reference_id}
                        </TableCell>
                        <TableCell>
                          {
                            movimentation?.assigned_customer_charge_code?.well
                              ?.project?.name
                          }
                        </TableCell>
                        <TableCell>
                          {
                            movimentation?.assigned_customer_charge_code?.well
                              ?.name
                          }
                        </TableCell>
                        <TableCell>
                          {
                            movimentation?.assigned_customer_charge_code
                              ?.original_charge_code
                          }
                        </TableCell>
                        <TableCell>{movimentation.tag_number}</TableCell>
                        <TableCell>
                          {movimentation.rina_work_order_no}
                        </TableCell>
                        <TableCell>{movimentation?.SLOC?.sloc_name}</TableCell>
                        <TableCell>{movimentation.material_layout}</TableCell>
                        <TableCell>
                          {movimentation.allocated_release_number ||
                          movimentation.allocated_batch_number ||
                          movimentation.allocated_work_order_number ? (
                            <span>YES</span>
                          ) : (
                            <span>NO</span>
                          )}
                        </TableCell>
                        <TableCell>{movimentation.supplier}</TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Paper className={classes.paper}>
            <Toolbar className={classes.toolBar}>
              <span>
                <Typography
                  className={classes.title}
                  variant="h6"
                  id="tableTitle"
                  component="span"
                  style={{ marginLeft: 15 }}
                >
                  Reconcile Details
                </Typography>
              </span>
            </Toolbar>
            <ModalContainer
              open={maintenanceModal}
              handleClose={handleCloseModal}
            >
              <Maintenance
                index={modalContent}
                maintenances={maintenances}
                updateMaintenance={(maintenanceData) =>
                  setData((currentState) => ({
                    ...currentState,
                    maintenances: maintenanceData,
                  }))
                }
                removeMaintenance={(index) =>
                  setData((currentState) => {
                    const currentMaintenance = currentState.maintenances;
                    currentMaintenance.splice(index, 1);
                    return {
                      ...currentState,
                      maintenances: currentMaintenance,
                    };
                  })
                }
                handleClose={handleCloseModal}
              />
            </ModalContainer>
            <Grid container style={{ padding: '0px 10px 10px 10px' }}>
              <Grid item lg={3} md={6} sm={12} xs={12}>
                <DefaultField
                  value={transactionType}
                  onChange={handleEdit}
                  name="transactionType"
                  label="Transaction Type"
                  select
                  disabled={id !== 'new'}
                >
                  {Object.keys(TransactionTypes).map((key) => (
                    <MenuItem value={key}>{key}</MenuItem>
                  ))}
                </DefaultField>
              </Grid>
              <Grid item lg={3} md={6} sm={12} xs={12}>
                <DefaultField
                  value={data.facilityReferenceID}
                  onChange={handleEdit}
                  name="facilityReferenceID"
                  label="Facility Reference ID"
                  disabled={id !== 'new'}
                />
              </Grid>

              {TransactionTypes[transactionType]?.fields.map(
                ({ Component, key, label }) => (
                  <Grid item lg={3} md={6} sm={12} xs={12}>
                    <Component
                      name={key}
                      label={label}
                      onChange={handleEdit}
                      value={data[key]}
                      defaultValue={data[key]}
                      disabled={id !== 'new'}
                      error={fieldError.includes(key)}
                    />
                  </Grid>
                )
              )}

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <InputContainer>
                  <TextField
                    disabled={id !== 'new'}
                    onChange={handleEdit}
                    size="small"
                    label="Reconcile Summary Note"
                    multiline
                    type="text"
                    name="note"
                    variant="outlined"
                    value={note}
                    fullWidth
                    required
                    error={noteError}
                    helperText={
                      noteError &&
                      'This field is required, please specify the reasons behind this reconciliation'
                    }
                  />
                </InputContainer>
              </Grid>
            </Grid>
          </Paper>
          {TransactionTypes[transactionType]?.maintenanceData && (
            <Paper className={classes.paper}>
              <Toolbar className={classes.toolBar}>
                <span>
                  <Typography
                    className={classes.title}
                    variant="h6"
                    id="tableTitle"
                    component="span"
                    style={{ marginLeft: 15 }}
                  >
                    Maintenance Data
                  </Typography>
                </span>
                <span>
                  <ButtonContainer>
                    <Button
                      color="primary"
                      variant="contained"
                      style={{ marginRight: 5 }}
                      onClick={handleOpenModal}
                      disabled={id !== 'new'}
                    >
                      Add Maintenance
                    </Button>
                  </ButtonContainer>
                </span>
              </Toolbar>
              <TableContainer className={classes.table}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name of Maintenance</TableCell>
                      <TableCell>Inspection Criteria</TableCell>
                      <TableCell>Effected Date</TableCell>
                      <TableCell>Interval</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {maintenances.length === 0 && (
                      <TableCell
                        style={{
                          fontWeight: 'bold',
                          textAlign: 'center',
                          textTransform: 'uppercase',
                        }}
                        colSpan={6}
                      >
                        No maintenances added
                      </TableCell>
                    )}
                    {maintenances?.map(
                      (
                        {
                          maintenance,
                          inspectionCriteria,
                          effectedDate,
                          interval,
                        },
                        index
                      ) => (
                        <TableRow>
                          <TableCell>
                            {
                              getMaintenance(maintenance, maintenanceCategories)
                                ?.name
                            }
                          </TableCell>
                          <TableCell>
                            {getSpecName(inspectionCriteria, specLibrary)}
                          </TableCell>
                          <TableCell>{effectedDateMap[effectedDate]}</TableCell>
                          <TableCell>{interval} days</TableCell>
                          <TableCell>
                            <IconButton
                              onClick={() => handleEditMaintenance(index)}
                              disabled={id !== 'new'}
                            >
                              <EditRounded />
                            </IconButton>
                            <IconButton
                              onClick={() => handleRemoveMaintenance(index)}
                              disabled={id !== 'new'}
                            >
                              <Delete />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          )}
          <Paper
            className={classes.paper}
            style={{ minHeight: isModal ? '21.87rem' : undefined }}
          >
            <EventDocumentsSection
              files={files}
              id={id}
              newFiles={newFiles}
              fileNotes={fileNotes}
              onChangeFiles={handleFiles}
            />
            <Grid container style={{ padding: '0px 10px 10px 10px' }}>
              <Grid lg={12} md={12} sm={12} xs={12}>
                <ButtonContainer>
                  <Button
                    color="primary"
                    variant={id === 'new' || isModal ? 'contained' : 'outlined'}
                    style={{ marginRight: 5 }}
                    onClick={handleSave}
                  >
                    {id === 'new' || isModal ? 'Post' : 'Update'} Transaction
                  </Button>
                  <Button
                    disabled={id === 'new' || isModal}
                    color="primary"
                    variant="contained"
                    style={{ marginRight: 5 }}
                    onClick={handleDownload}
                  >
                    Create Recon Report
                  </Button>
                  {isModal && (
                    <Button
                      color="secondary"
                      variant="outlined"
                      onClick={onCloseModal}
                    >
                      Cancel
                    </Button>
                  )}
                </ButtonContainer>
              </Grid>
            </Grid>
          </Paper>
        </div>
      </Container>
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  reconcilesLoading: state.ui.reconcilesLoading,
  reconciles: state.reconciles,
  maintenanceModal: state.ui.maintenanceModal,
  reconcile: state.reconcile,
  specLibrary: state.specLibrary || [],
  maintenanceCategories:
    state.maintenanceCategories?.docs || state.maintenanceCategories || [],
  loggedUser: state.loggedUser,
});

export default connect(mapStateToProps)(Reconcile);
