import {
  UPDATE_MAINTENANCE_CATEGORY_SUCCESS,
  UPDATE_MAINTENANCE_CATEGORY_ERROR,
  FETCH_MAINTENANCE_CATEGORY_SUCCESS,
  FETCH_MAINTENANCE_CATEGORY_ERROR,
  GET_MAINTENANCE_CATEGORY,
  CREATE_MAINTENANCE_CATEGORY,
  UPDATE_MAINTENANCE_CATEGORY,
  updateObjectMaintenanceCategory,
  showSpinner,
  hideSpinner,
  hideModal,
} from '../actions/maintenanceCategory';
import { getMaintenanceCategories } from '../actions/maintenanceCategories';
import Toastify from '../../utils/Toastify/index';
import { apiRequest } from '../actions/api';

const URL = 'maintenance_categories';

export const createMaintenanceCategoryFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === CREATE_MAINTENANCE_CATEGORY) {
    dispatch(
      apiRequest(
        'POST',
        URL,
        action.payload,
        UPDATE_MAINTENANCE_CATEGORY_SUCCESS,
        UPDATE_MAINTENANCE_CATEGORY_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const updateMaintenanceCategoryFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === UPDATE_MAINTENANCE_CATEGORY) {
    dispatch(
      apiRequest(
        'PUT',
        URL,
        action.payload,
        UPDATE_MAINTENANCE_CATEGORY_SUCCESS,
        UPDATE_MAINTENANCE_CATEGORY_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const getMaintenanceCategoryFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_MAINTENANCE_CATEGORY) {
    const { _id } = action.payload;
    dispatch(
      apiRequest(
        'GET-id',
        URL,
        { _id },
        FETCH_MAINTENANCE_CATEGORY_SUCCESS,
        FETCH_MAINTENANCE_CATEGORY_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const processMaintenanceCategoryCollection = ({ dispatch, getState }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_MAINTENANCE_CATEGORY_SUCCESS) {
    dispatch(updateObjectMaintenanceCategory(action.payload));
    dispatch(hideSpinner());
  } else if (action.type === UPDATE_MAINTENANCE_CATEGORY_SUCCESS) {
    dispatch(updateObjectMaintenanceCategory(action.payload));
    dispatch(hideModal());
    dispatch(hideSpinner());
    const { page, limit } = getState().maintenanceCategories;
    dispatch(getMaintenanceCategories({ page, limit }));
    Toastify.addSuccess('Maintenance Category saved.');
  } else if (action.type === UPDATE_MAINTENANCE_CATEGORY_ERROR) {
    dispatch(hideSpinner());
    Toastify.addError(
      action.payload || 'Error to save the Maintenance Category',
    );
  }
};

export const maintenanceCategoryMdl = [
  createMaintenanceCategoryFlow,
  updateMaintenanceCategoryFlow,
  getMaintenanceCategoryFlow,
  processMaintenanceCategoryCollection,
];
