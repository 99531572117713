import {
  FETCH_SLOCS_SUCCESS,
  FETCH_SLOCS_ERROR,
  GET_SLOCS,
  updateSLOCS,
  showSpinner,
  hideSpinner,
} from '../actions/slocs';
import { apiRequest } from '../actions/api';

const URL = 'slocs';

export const getSLOCsFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_SLOCS) {
    dispatch(
      apiRequest(
        'GET',
        URL,
        action.payload,
        FETCH_SLOCS_SUCCESS,
        FETCH_SLOCS_ERROR,
        ['customer', 'listAll'],
      ),
    );
    dispatch(showSpinner());
  }
};

export const processSLOCsCollection = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_SLOCS_SUCCESS) {
    dispatch(updateSLOCS(action.payload));
    dispatch(hideSpinner());
  }
};

export const slocsMdl = [getSLOCsFlow, processSLOCsCollection];
