module.exports = [
  { value: 'vendor_name', label: 'Vendor Name', type: 'String' },
  { value: 'vendor_code', label: 'Vendor Code', type: 'String' },
  { value: 'vendor_sloc_code', label: 'SLOC Code', type: 'String' },
  { value: 'vendor_address.country', label: 'Country', type: 'String' },
  { value: 'vendor_address.state', label: 'State', type: 'String' },
  { value: 'vendor_address.city', label: 'City', type: 'String' },
  { value: 'vendor_address.address', label: 'Address', type: 'String' },
  { value: 'vendor_address.number', label: 'Number', type: 'String' },
  { value: 'vendor_address.postal_code', label: 'Postal Code', type: 'String' },
];
