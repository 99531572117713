import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { TextField, Button } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import IconButton from '@material-ui/core/IconButton';
import { Add } from '@material-ui/icons';
import { getWorkOrder } from '../../../redux/actions/workOrder';
import {
  Container,
  InputContent,
  ModalTitle,
  ButtonContainer,
  AddButton,
} from './styles';
import Spinner from '../../../components/Spinner/index';
import Alert from '../../../components/Alert/index';
import {
  showDocumentDataModal,
  hideDocumentDataModal,
} from '../../../redux/actions/workOrders';
import ModalContainer from '../../../components/ModalContainer';
import DocumentModal from '../DocumentData';

const styles = {
  formButton: {
    margin: 2,
  },
};

function JobsDocumentationModal({
  id,
  dispatch,
  job,
  jobsDocumentationModal,
  handleClose,
  woDocumentModal,
  workOrder,
}) {
  const [active, setActive] = useState(true);
  const [nameError, setNameError] = useState(false);
  const [idError, setIdError] = useState(false);
  const { documents = [] } = workOrder;
  const [editing, setEditing] = React.useState(null);

  function handleCloseDocumentModal() {
    dispatch(hideDocumentDataModal());
  }

  useEffect(() => {
    if (id !== 'new') {
      dispatch(getWorkOrder(id));
    }
  }, [id]);

  function handleDocumentModal(newData) {
    const newDocuments = [...documents];
    if (editing !== null) {
      newDocuments[editing] = newData;
    }
    // setData((currentData) => ({
    //   ...currentData,
    //   documents:
    //     editing !== null ? newDocuments : [...currentData.documents, newData],
    // }));
    dispatch(hideDocumentDataModal());
  }

  function handleDelete() {
    Alert(
      'Are you sure?',
      "You won't be able to revert this!",
      false,
      '',
      'Yes, delete it.',
    ).then(({ confirm }) => {
      if (!confirm) return;
      // setData((currentData) => {
      //   const currentDocuments = [...currentData.documents];
      //   currentDocuments.splice(editing, 1);
      //   return { ...currentData, documents: currentDocuments };
      // });
      dispatch(hideDocumentDataModal());
    });
  }

  return (
    <Container>
      <ModalTitle>Inspection Documentation</ModalTitle>
      <ModalContainer
        open={woDocumentModal}
        handleClose={handleCloseDocumentModal}
      >
        <DocumentModal
          handleClose={handleCloseDocumentModal}
          onSave={handleDocumentModal}
          onDelete={handleDelete}
          editData={editing !== null ? documents[editing] : null}
        />
      </ModalContainer>
      <AddButton
        variant="contained"
        color="primary"
        size="small"
        onClick={() => {
          setEditing(null);
          dispatch(showDocumentDataModal());
        }}
      >
        <Add />
        Add Inspection Documentation
      </AddButton>
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>Inspection Document</TableCell>
              <TableCell colSpan={2}>Document Name</TableCell>
              <TableCell>Attached Doc</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documents.length < 1 && (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No Document Added
                </TableCell>
              </TableRow>
            )}
            {documents.map((document, index) => (
              <TableRow
                hover
                onDoubleClick={() => {
                  setEditing(index);
                  dispatch(showDocumentDataModal());
                }}
              >
                <TableCell>{document.document}</TableCell>
                <TableCell colSpan={2} style={{ whiteSpace: 'pre-wrap' }}>
                  {document.name}
                </TableCell>
                <TableCell>
                  <IconButton>
                    <AttachFileIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ButtonContainer>
        <Button
          color="secondary"
          onClick={handleClose}
          variant="contained"
          style={styles.formButton}
        >
          Cancel
        </Button>
      </ButtonContainer>
    </Container>
  );
}

export default connect((state) => ({
  job: state.job,
  jobsDocumentationModal: state.ui.jobsDocumentationModal,
  woDocumentModal: state.ui.woDocumentModal,
  workOrder: state.workOrder,
}))(JobsDocumentationModal);
