import React, { useEffect, useState } from 'react';
import { Checkbox } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import {
  CloudDownload,
  FiberManualRecord as IndividualIcon,
  GroupWork as GroupedIcon,
  History as HistoryIcon,
} from '@material-ui/icons';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import InventoryCartFilter from '../../components/InventoryCartFilter';
import SimpleSpinner from '../../components/SimpleSpinner';
import Spinner from '../../components/Spinner/';
import MoveModal from '../Modals/Move';
import {
  getInventoryCart,
  removeFromInventoryCart,
} from '../../redux/actions/inventory_cart';
import api from '../../services/Api';
import { tableStyles } from '../../styles/tableStyles';
import getRINADescription from '../../utils/Equipment/getRINADescription';
import cartFunctions from './CartFunctions';
import {
  Container,
  MenuBar,
  Separator,
  StyledButton,
  TableContainer,
} from './styles';
import { CUSTOMER_KEY } from '../../config/consts';
import Toastify from '../../utils/Toastify';

const InventoryChart = ({
  dispatch,
  loggedUser,
  cartResponse,
  cartLoading,
}) => {
  const history = useHistory();
  const classes = tableStyles();
  const [cart, setCart] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [checked, setChecked] = useState([]);
  const [consolidatedView, setConsolidatedView] = useState(false);
  const [waiting, setWaiting] = useState([]);
  const [loadingCheckedData, setLoadingCheckedData] = useState(false);
  const [moveModalOpen, setMoveModalOpen] = useState(false);

  useEffect(() => {
    dispatch(
      getInventoryCart({
        page: page + 1,
        limit: limit,
        availability: 'available',
        userId: loggedUser._id,
        filter: { consolidated: consolidatedView },
      })
    );
  }, [page, limit, consolidatedView]);

  useEffect(() => {
    if (cartResponse?.docs) {
      setCart(cartResponse.docs);
      setTotal(cartResponse.totalDocs);
    }
  }, [cartResponse]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getGroupedItems = async ({
    _id,
    assigned_customer_charge_code,
    batch_no,
    customer_MM,
    status,
    allocated_charge_code,
    groupedItems,
  }) => {
    setWaiting((currentWaitingList) => [...currentWaitingList, _id]);
    return api
      .get(`cart/${loggedUser._id}/available`, {
        params: {
          page: 1,
          limit: groupedItems,
          assigned_customer_charge_code:
            typeof assigned_customer_charge_code === 'string'
              ? assigned_customer_charge_code
              : assigned_customer_charge_code?._id || 'null',
          batch_no: batch_no || 'null',
          customer_MM:
            typeof customer_MM === 'string'
              ? customer_MM
              : customer_MM?._id || 'null',
          status: status || 'null',
          allocated_charge_code:
            typeof allocated_charge_code === 'string'
              ? allocated_charge_code
              : allocated_charge_code?._id || 'null',
        },
      })
      .then(function (response) {
        setWaiting((currentWaitingList) => {
          const copy = [...currentWaitingList];
          const index = copy.indexOf(_id);
          if (index < 0) return currentWaitingList;
          copy.splice(index, 1);
          return copy;
        });
        return response.data.docs;
      });
  };

  const getAllCart = async () => {
    const items = [];
    const promises = cart.map(async (item) => {
      if (!item.groupedItems || item.groupedItems === 1) {
        items.push(item._id);
        return;
      }

      const groupedItems = await getGroupedItems(item);
      items.push(...groupedItems.map(({ _id }) => _id));
      return groupedItems;
    });

    return Promise.all(promises).then(() => items);
  };

  const handleChangeAllAvailable = async (event) => {
    const { checked } = event.target;
    setChecked(checked ? await getAllCart() : []);
  };
  function isChecked(id) {
    const index = checked.indexOf(id);
    return { checked: index > -1, index };
  }

  function changeCheckbox(value, items) {
    if (value) {
      setChecked([...checked, ...items]);
    } else {
      const currentChecked = [...checked];
      const remaining = currentChecked.filter(
        (checked) => !items.includes(checked)
      );
      setChecked(remaining);
    }
  }

  function getAllChecked() {
    setLoadingCheckedData(true);
    return api
      .get(`cart/${loggedUser._id}/available`, {
        params: {
          listAll: true,
        },
      })
      .then(({ data }) => {
        return data.filter(({ _id }) => checked.includes(_id)) || [];
      })
      .finally(() => {
        setLoadingCheckedData(false);
      });
  }

  function handleSingleCheckbox(id, grouped) {
    async function handler(event) {
      const val = event.target.checked;
      if (grouped) {
        const item = cart.find(({ _id }) => String(_id) === String(id));
        if (!item) return;
        const groupedItems = await getGroupedItems(item);
        changeCheckbox(
          val,
          groupedItems.map(({ _id }) => _id)
        );
      } else changeCheckbox(val, [id]);
    }
    return handler;
  }
  function handleRemoveFromCart() {
    dispatch(removeFromInventoryCart(loggedUser._id, checked));
    setChecked([]);
    setConsolidatedView(true);
  }

  function toggleView() {
    setConsolidatedView((currentView) => !currentView);
    setPage(0);
  }
  function handleFeatureClick(feature, suppressNew, inventoryRequired) {
    return async function () {
      if(inventoryRequired && !localStorage.getItem(CUSTOMER_KEY)) return Toastify.addInfo('Select an inventory first.');
      if (feature === 'remove') return handleRemoveFromCart();
      if (feature === 'move') return handleOpenMoveModal();
      const checkedItems = await getAllChecked();
      history.push(`/${feature}${!suppressNew ? '/new' : ''}`, {
        movimentations: checkedItems,
      });
    };
  }

  function renderRow(item) {
    return (
      <TableRow
        key={item._id}
        className={classes.row}
        style={{
          backgroundColor: item.status === 'Available' ? undefined : '#ddd',
        }}
      >
        <TableCell className={classes.cell}>
          {waiting.includes(item._id) ? (
            <CircularProgress size={30} />
          ) : (
            <Checkbox
              checked={isChecked(item._id).checked}
              onChange={handleSingleCheckbox(item._id, item.groupedItems > 1)}
              color="primary"
            />
          )}
        </TableCell>
        <TableCell>
          <Tooltip title="Go to history">
            <IconButton
              component={Link}
              to={`/history/${item._id}`}
              color="primary"
            >
              <HistoryIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
        {consolidatedView && (
          <TableCell className={classes.cell}>
            {item.groupedItems && item.groupedItems > 1 ? (
              <Tooltip title={`${item.groupedItems} Grouped items`}>
                <GroupedIcon />
              </Tooltip>
            ) : (
              <Tooltip title="Individual">
                <IndividualIcon />
              </Tooltip>
            )}
          </TableCell>
        )}
        <TableCell className={classes.cell}>{item.status}</TableCell>
        <TableCell colSpan={4}>
          {getRINADescription(item?.customer_MM)}
        </TableCell>
        <TableCell>
          {item.groupedItems && item.groupedItems > 1
            ? '--'
            : item.individual_item_id}
        </TableCell>
        <TableCell className={classes.cell}>
          {!item.parent || item.parent.material_layout !== 'B.O.M'
            ? '--'
            : item.parent.individual_item_id || '--'}
        </TableCell>
        <TableCell className={classes.cell}>
          {!item.parent || item.parent.material_layout === 'B.O.M'
            ? '--'
            : item.parent.individual_item_id || '--'}
        </TableCell>
        <TableCell className={classes.cell}>{item.children.length}</TableCell>
        <TableCell className={classes.cell}>
          {item?.customer_MM?.mm_number || ''}
        </TableCell>
        <TableCell className={classes.cell}>
          {!item.count ? item.qty || 1 : item.count}
        </TableCell>
        <TableCell className={classes.cell}>
          {item?.footage?.toFixed
            ? item?.footage?.toFixed(3)
            : item?.footage || '--'}
        </TableCell>
        <TableCell className={classes.cell}>
          {item.classification || ''}
        </TableCell>
        <TableCell className={classes.cell}>{item.batch_no || ''}</TableCell>
        <TableCell className={classes.cell}>{item.heat || ''}</TableCell>
        <TableCell className={classes.cell}>{item.RBW || ''}</TableCell>
        <TableCell className={classes.cell}>
          {item.rina_work_order_no || '--'}
        </TableCell>
        <TableCell className={classes.cell}>
          {item.SLOC?.sloc_name || ''}
        </TableCell>
        <TableCell className={classes.cell}>
          {item?.allocated_charge_code?.well?.project?.name || '--'}
        </TableCell>
        <TableCell className={classes.cell}>
          {item.allocated_batch_number || '--'}
        </TableCell>
        <TableCell className={classes.cell}>
          {item.allocated_work_order_number || '--'}
        </TableCell>
        <TableCell className={classes.cell}>
          {item.status === 'Consignment' ? (
            <RadioButtonCheckedIcon />
          ) : (
            <RadioButtonUncheckedIcon />
          )}
        </TableCell>
        <TableCell className={classes.cell}>{item.supplier || '--'}</TableCell>
        <TableCell className={classes.cell}>
          {item.facility_reference_id || '--'}
        </TableCell>
      </TableRow>
    );
  }

  const handleCloseMoveModal = () => {
    setMoveModalOpen(false);
  };

  const handleOpenMoveModal = () => {
    setMoveModalOpen(true);
  };

  return (
    <>
      <Container>
        <Modal open={loadingCheckedData} onClose={() => {}}>
          <SimpleSpinner color="#FFF" />
        </Modal>
        <InventoryCartFilter />
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <Toolbar className={classes.toolBar} style={{ marginLeft: '1rem' }}>
              <Typography
                className={classes.title}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                Inventory Cart
              </Typography>
              <FormControlLabel
                control={
                  <Switch
                    checked={consolidatedView}
                    color="primary"
                    onChange={toggleView}
                  />
                }
                label="Consolidated View"
              />
            </Toolbar>
            <MenuBar>
              {cartFunctions.map(({ title, label, featureKey, Icon, inventoryRequired }) => (
                <Tooltip
                  title={title}
                  arrow
                  classes={{ tooltip: classes.tooltip }}
                  key={featureKey}
                >
                  <StyledButton
                    disabled={!(checked.length > 0)}
                    onClick={handleFeatureClick(featureKey, undefined, inventoryRequired)}
                    injectFirst
                  >
                    {Icon}
                    {label}
                  </StyledButton>
                </Tooltip>
              ))}
              <Separator />
              <Tooltip
                title="Export Cart"
                arrow
                onClick={() => history.push('/export_cart')}
                classes={{ tooltip: classes.tooltip }}
              >
                <StyledButton injectFirst>
                  <CloudDownload />
                  Export
                </StyledButton>
              </Tooltip>
            </MenuBar>
            <TableContainer className={classes.table} injectFirst>
              <Table id="availableTable" aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.cell}>
                      {waiting.length > 0 ? (
                        <CircularProgress size={30} />
                      ) : (
                        <Checkbox
                          checked={cart.every(({ _id }) =>
                            checked.includes(_id)
                          )}
                          onChange={handleChangeAllAvailable}
                          color="primary"
                        />
                      )}
                    </TableCell>
                    <TableCell />
                    {consolidatedView && (
                      <TableCell className={classes.cell}>Grouped</TableCell>
                    )}
                    <TableCell className={classes.cell}>Status</TableCell>
                    <TableCell colSpan={4} style={{ minWidth: 250 }}>
                      RINA Description
                    </TableCell>
                    <TableCell>RAMP Unique ID</TableCell>
                    <TableCell className={classes.cell}>B.O.M ID</TableCell>
                    <TableCell className={classes.cell}>Container ID</TableCell>
                    <TableCell className={classes.cell}>Children No.</TableCell>
                    <TableCell className={classes.cell}>
                      Customer MM No.
                    </TableCell>
                    <TableCell className={classes.cell}>
                      Total Qty. (EA)
                    </TableCell>
                    <TableCell className={classes.cell}>Footage</TableCell>
                    <TableCell className={classes.cell}>COPAS Class</TableCell>
                    <TableCell className={classes.cell}>
                      Supplier Batch No.
                    </TableCell>
                    <TableCell className={classes.cell}>Heat/Lot</TableCell>
                    <TableCell className={classes.cell}>RBW</TableCell>
                    <TableCell className={classes.cell}>
                      RINA Work Order No.
                    </TableCell>
                    <TableCell className={classes.cell}>SLOC</TableCell>
                    <TableCell className={classes.cell}>
                      Allocated Project
                    </TableCell>
                    <TableCell className={classes.cell}>
                      Allocated Batch No
                    </TableCell>
                    <TableCell className={classes.cell}>
                      Allocated WO No
                    </TableCell>
                    <TableCell className={classes.cell}>Consignment</TableCell>
                    <TableCell className={classes.cell}>Supplier</TableCell>
                    <TableCell className={classes.cell}>
                      Facility Reference ID
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cartLoading && (
                    <TableRow className={classes.row}>
                      <TableCell colSpan="30" className={classes.cell}>
                        <Spinner parent="Table" />
                      </TableCell>
                    </TableRow>
                  )}
                  {!cartLoading && total === 0 && (
                    <TableRow className={classes.row}>
                      <TableCell
                        colSpan="30"
                        className={classes.cell}
                        style={{
                          textAlign: 'center',
                          fontWeight: 500,
                          fontSize: 20,
                          padding: 20,
                        }}
                      >
                        No items found
                      </TableCell>
                    </TableRow>
                  )}
                  {!cartLoading && total > 0 && cart.map(renderRow)}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[2, 5, 10, 25, total].sort(
                (a, b) => Number.parseInt(a) - Number.parseInt(b)
              )}
              component="div"
              count={total}
              rowsPerPage={limit}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </Container>
      <MoveModal
        open={moveModalOpen}
        onClose={handleCloseMoveModal}
        equipment={checked}
      />
    </>
  );
};
function mapStateToProps(state) {
  return {
    loggedUser: state.loggedUser,
    cartResponse: state.inventory_cart.cart,
    cartLoading: state.ui.cartLoading,
  };
}
export default connect(mapStateToProps)(InventoryChart);
