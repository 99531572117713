import {
  FETCH_CUSTOMER_MMS_SUCCESS,
  FETCH_CUSTOMER_MMS_ERROR,
  GET_CUSTOMER_MMS,
  updateCustomerMMs,
  showSpinner,
  hideSpinner,
} from '../actions/customerMMs';
import { apiRequest } from '../actions/api';

const URL = 'material_masters';

export const getCustomerMMsFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_CUSTOMER_MMS) {
    dispatch(
      apiRequest(
        'GET',
        URL,
        action.payload,
        FETCH_CUSTOMER_MMS_SUCCESS,
        FETCH_CUSTOMER_MMS_ERROR,
        [
          'customer',
          'mm_number',
          'short_description',
          'long_description',
          'equipment_type',
          'modifier',
          'size',
          'weight',
          'grade',
          'connection_1',
          'connection_1_size',
          'connection_1_weight',
          'connection_1_grade',
          'connection_2',
          'connection_2_size',
          'connection_2_weight',
          'connection_2_grade',
          'connection_configuration',
          'nominal_wall',
          'drift_diameter',
          'range',
          'pipe_type',
          'manufacturer',
          'manufacturer_pn',
          'inspection_criteria',
          'pressure_rating',
          'primary_uom',
          'secondary_uom',
          'action_type',
          'listAll',
        ],
      ),
    );
    dispatch(showSpinner());
  }
};

export const processCustomerMMsCollection = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_CUSTOMER_MMS_SUCCESS) {
    dispatch(updateCustomerMMs(action.payload));
    dispatch(hideSpinner());
  }
};

export const customerMMsMdl = [
  getCustomerMMsFlow,
  processCustomerMMsCollection,
];
