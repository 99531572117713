import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import axios from 'axios';
import Table from '@material-ui/core/Table';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import TableBody from '@material-ui/core/TableBody';
import Button from '@material-ui/core/Button';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import { Add } from '@material-ui/icons';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import {
  ArrowDropDown as DropdownIcon,
  EditOutlined as EditIcon,
  AddOutlined as AddIcon,
} from '@material-ui/icons';
import InventoryFilter from '../../../components/InventoryFilter/index';
import { getMovimentations } from '../../../redux/actions/movimentations';
import { listResultLayout } from '../../../redux/actions/resultLayouts';
import { showResultLayoutModal } from '../../../redux/actions/resultLayout';
import Spinner from '../../../components/Spinner/index';
import { Container } from './styles';
import { addToInventoryCart } from '../../../redux/actions/inventory_cart';
import {
  CUSTOMER_KEY,
  apiUrl,
  DEFAULT_RESULT_LAYOUT,
  RESULT_LAYOUT_KEY,
  RESULT_LAYOUT_VERSION_KEY,
  RESULT_LAYOUT_VERSION,
} from '../../../config/consts';
import { tableStyles } from '../../../styles/tableStyles';
import Row from '../../../components/Row';
import TableHeaderWithLayout from '../../../components/TableHeaderWithLayout';
import Prompt from '../../../components/Prompt';
import ResultLayoutModal from '../ResultLayout';
import { AddButton } from './styles';

const StyledPopover = withStyles((theme) => ({
  paper: {
    marginTop: 10,
    overflow: 'visible',
    '&:before': {
      content: "''",
      border: '8px solid transparent',
      borderBottom: '8px solid white',
      position: 'absolute',
      top: -16,
      right: 0,
      marginLeft: -8,
    },
  },
}))(Popover);

const EquipmentDetails = ({
  dispatch,
  movimentations,
  movimentationsLoading,
  movimentationsFilter,
  loggedUser,
  inventoryCart,
  customerSelected,
  resultLayouts,
  onSave,
  data,
  handleClose,
}) => {
  const classes = tableStyles();
  const [movimentationsFormated, setMovimentationsFormated] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [total, setTotal] = useState(0);
  const [orderBy, setOrderBy] = useState('customer_MM.short_description');
  const [order, setOrder] = useState('desc');
  const [searched, setSearched] = useState(false);
  const { state } = useLocation();
  const [consolidatedView, setConsolidatedView] = useState(true);
  const [layoutPopoverAnchorEl, setLayoutPopoverAnchorEl] = useState(false);
  const [layout, setLayout] = useState(
    JSON.parse(localStorage.getItem(RESULT_LAYOUT_KEY)) || DEFAULT_RESULT_LAYOUT
  );
  const [layoutId, setLayoutId] = useState('new');
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    const resultLayoutVersion = localStorage.getItem(RESULT_LAYOUT_VERSION_KEY);
    if (resultLayoutVersion !== RESULT_LAYOUT_VERSION) {
      setLayout(DEFAULT_RESULT_LAYOUT);
      localStorage.setItem(RESULT_LAYOUT_VERSION_KEY, RESULT_LAYOUT_VERSION);
    }
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      setSelectedItems(data);
    }
  }, data);

  useEffect(() => {
    if (state && state.fromSearch && !searched) {
      setConsolidatedView(true);
      return setSearched(true);
    }
    if (!localStorage.getItem(CUSTOMER_KEY)) return;
    dispatch(
      getMovimentations({
        page: page + 1,
        limit,
        filter: {
          ...movimentationsFilter,
          customer: localStorage.getItem(CUSTOMER_KEY),
          in_inventory: true,
          consolidated: consolidatedView,
        },
        orderBy,
        order,
      })
    );
  }, [page, limit, orderBy, order, consolidatedView]);

  useEffect(() => {
    if (movimentations.docs) {
      setMovimentationsFormated(movimentations.docs);
      setTotal(movimentations.totalDocs);
    }
  }, [movimentations]);

  useEffect(() => {
    dispatch(listResultLayout({ page: 1, limit: 50 }));
  }, []);

  useEffect(() => {
    if (!layout) return;
    const layoutString = JSON.stringify(layout);
    if (layoutString == localStorage.getItem(RESULT_LAYOUT_KEY)) return;
    localStorage.setItem(RESULT_LAYOUT_KEY, layoutString);
  }, [layout]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  function addToCart(idList) {
    dispatch(addToInventoryCart(loggedUser._id, idList));
  }

  function getItemsToAdd(item, quantity) {
    return axios
      .get(`${apiUrl}movimentations`, {
        params: {
          page: 1,
          limit: quantity,
          consolidated: false,
          assigned_customer_charge_code:
            item?.assigned_customer_charge_code?._id ||
            item?.assigned_customer_charge_code ||
            'null',
          batch_no: item?.batch_no || 'null',
          customer_MM: item?.customer_MM?._id || item?.customer_MM || 'null',
          status: item?.status || 'null',
          allocated_charge_code:
            item?.allocated_charge_code?._id ||
            item?.allocated_charge_code ||
            'null',
        },
      })
      .then(function (response) {
        return response.data.docs;
      });
  }

  function handleAddToCart(item) {
    function handler() {
      if (!item.count || item.count <= 1) return addToCart([item._id]);
      Prompt({
        title: 'Add to cart',
        text: 'Please, inform how many items do you want to add to the cart, to add all items, leave it empty',
        inputPlaceholder: 'Ex.: 1',
        icon: 'question',
        cancelButtonText: 'Cancel',
        input: 'number',
        confirmButtonText: 'Confirm',
        inputValidator: (value) => {
          if (value === 0 || (value && (value < 1 || value > item.count)))
            return `Please, inform a number between 1 and ${item.count}`;
        },
      }).then(function ({ isConfirmed, value }) {
        if (isConfirmed)
          getItemsToAdd(item, value || item.count).then((items) => {
            addToCart(items.map(({ _id }) => _id));
          });
      });
    }
    return handler;
  }

  const styles = {
    modalContent: {
      backgroundColor: 'transparent',
    },
    editButton: {
      marginRight: 10,
    },
  };

  function handleSort(field) {
    function handler(e) {
      if (orderBy === field)
        return setOrder((currentOrder) =>
          currentOrder === 'desc' ? 'asc' : 'desc'
        );
      setOrderBy(field);
      setOrder('desc');
    }
    return handler;
  }

  function handleChangeLayoutClick(e) {
    setLayoutPopoverAnchorEl(e.target);
  }

  function handleChangeLayoutPopoverDismiss() {
    setLayoutPopoverAnchorEl(null);
  }

  function handleSelectLayout(selectedLayout) {
    return function () {
      setLayout(selectedLayout);
      setLayoutPopoverAnchorEl(null);
    };
  }

  function handleOpenLayoutModal(id) {
    return function () {
      setLayoutId(id);
      dispatch(showResultLayoutModal());
    };
  }

  function selectEquipment(equipment, e) {
    if (selectedItems.find((item) => item._id == equipment._id)) {
      setSelectedItems(selectedItems.filter((item) => item._id != equipment._id));
    } else {
      setSelectedItems([...selectedItems, equipment]);
    }
  };

  function isEquipmentSelected(id) {
    return !!selectedItems.find(item => item._id === id);
  };

  function addEquipments() {
    onSave(selectedItems);
  }

  const layoutPopoverOpen = Boolean(layoutPopoverAnchorEl);

  return (
    <>
      <ResultLayoutModal id={layoutId} />
      <Container>
        <InventoryFilter
          orderBy={orderBy}
          order={order}
          consolidatedView={consolidatedView}
        />
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <Toolbar className={classes.toolBar} style={{ marginLeft: '1rem' }}>
              <Typography
                className={classes.title}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                Inventory{' '}
                {customerSelected && customerSelected.name
                  ? ` (${customerSelected.name})`
                  : ''}
              </Typography>
              <span>
                <FormControlLabel
                  control={
                    <Switch
                      checked={consolidatedView}
                      color="primary"
                      disabled={movimentationsLoading}
                      onChange={(event) => {
                        setConsolidatedView(event.target.checked);
                      }}
                    />
                  }
                  label="Consolidated View"
                />
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  endIcon={
                    <DropdownIcon style={{ borderLeft: '1px solid white' }} />
                  }
                  style={{ marginRight: 10 }}
                  onClick={handleChangeLayoutClick}
                >
                  Change layout
                </Button>
                <StyledPopover
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  anchorEl={layoutPopoverAnchorEl}
                  open={layoutPopoverOpen}
                  onClose={handleChangeLayoutPopoverDismiss}
                  elevation={5}
                >
                  <List>
                    {resultLayouts.map((layout) => (
                      <ListItem
                        button
                        onClick={handleSelectLayout(layout.fields)}
                      >
                        <ListItemText primary={layout.name} />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            color="secondary"
                            onClick={handleOpenLayoutModal(layout._id)}
                          >
                            <EditIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                    <ListItem>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={handleOpenLayoutModal('new')}
                      >
                        New Layout
                      </Button>
                    </ListItem>
                  </List>
                </StyledPopover>
              </span>
            </Toolbar>
            <TableContainer className={classes.table}>
              <Table stickyHeader aria-label="collapsible table">
                <TableHeaderWithLayout
                  sortable
                  layout={layout}
                  orderBy={orderBy}
                  order={order}
                  onSort={handleSort}
                />
                <TableBody>
                  {!localStorage.getItem(CUSTOMER_KEY) ? (
                    <TableRow className={classes.row}>
                      <TableCell
                        colSpan="24"
                        className={classes.cell}
                        style={{ paddingLeft: 48 }}
                      >
                        <Typography variant="h6">
                          Select an inventory
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <>
                      {movimentationsLoading && (
                        <TableRow className={classes.row}>
                          <TableCell colSpan="24" className={classes.cell}>
                            <Spinner parent="Table" />
                          </TableCell>
                        </TableRow>
                      )}
                      {!movimentationsLoading &&
                        movimentationsFormated.map((rr) => <>
                          <Row
                            rr={rr}
                            hideButtons
                            layout={layout}
                            inventoryCart={inventoryCart}
                            styles={styles}
                            handleAddToCart={handleAddToCart}
                            hasCheckbox
                            onChangeCheckbox={selectEquipment}
                            checkboxValue={isEquipmentSelected}
                          />
                        </>)}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[20, 50, 100, 200, 500, 1000]}
              component="div"
              count={total}
              rowsPerPage={limit}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
        <div>
          <AddButton variant="contained" color="primary" size="small" onClick={() => addEquipments()}>
            <Add />
            Add Equipments ({`${selectedItems.length} item${selectedItems.length !== 1 ? 's' : ''}`})
          </AddButton>
        </div>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  customers: state.customers,
  movimentationsLoading: state.ui.movimentationsLoading,
  movimentations: state.movimentations,
  movimentationsFilter: state.movimentationsFilter,
  loggedUser: state.loggedUser,
  inventoryCart: state.inventory_cart.all,
  customerSelected: state.customer,
  resultLayouts: state.resultLayouts,
});

export default withRouter(connect(mapStateToProps)(EquipmentDetails));
