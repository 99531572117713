import React from 'react';
import {
  FooterContainer,
  FooterText,
} from './styles';

function Footer({ footerText }) {
  return (
    <FooterContainer>
      <FooterText>
        {footerText}
      </FooterText>
    </FooterContainer>
  );
}

export default Footer;
