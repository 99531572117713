import React from 'react';
import { Redirect, Router, Switch } from 'react-router-dom';
import Route from './components/Route';
import BreakoutData from './pages/BreakoutData';
import SelectBuildOfMaterial from './pages/BuildOfMaterial';
import BusinessUnits from './pages/BusinessUnits';
import CartMaintenances from './pages/CartMaintenances';
import CompanyCodes from './pages/CompanyCodes';
import CustomerMMNo from './pages/CustomerMMNo';
import Customers from './pages/Customers';
import ExportReport from './pages/ExportReport';
import Allocate from './pages/Forms/Allocate';
import BuildOfMaterial from './pages/Forms/BuildOfMaterial';
import CheckIn from './pages/Forms/CheckIn';
import CheckOut from './pages/Forms/CheckOut';
import EQualityWorkOrderForm from './pages/Forms/EQualityWorkOrder';
import EquipmentForm from './pages/Forms/Equipment';
import Fabrication from './pages/Forms/Fabrication';
import HierarchyForm from './pages/Forms/Hierarchy';
import Reclass from './pages/Forms/Reclass';
import Reconcile from './pages/Forms/Reconcile';
import TransferOfOwnwership from './pages/Forms/TransferOfOwnwership';
import WorkOrderInspectionForm from './pages/Forms/WorkOrderInspection';
import History from './pages/History';
import HistoryDashboard from './pages/HistoryDashboard';
import Home from './pages/Home';
import Hub from './pages/Hub';
import ImportChargeCode from './pages/ImportChargeCode';
import ImportMaterialMaster from './pages/ImportMaterialMaster';
import ImportMenu from './pages/ImportMenu';
import ImportProjects from './pages/ImportProjects';
import ImportSLOC from './pages/ImportSLOC';
import ImportVendor from './pages/ImportVendor';
import ImportWells from './pages/ImportWells';
import InventoryCart from './pages/InventoryCart';
import Job from './pages/Jobs';
import Login from './pages/Login';
import MaintenanceCategories from './pages/MaintenanceCategories';
import Maintenances from './pages/Maintenances';
import NoPermissionPage from './pages/NoPermissionPage';
import NotFound from './pages/NotFound';
import OriginalChargeCode from './pages/OriginalChargeCode';
import Owners from './pages/Owners';
import Profile from './pages/Profile';
import MRPNotificationSetup from './pages/MRPNotificationSetup';
import Projects from './pages/Projects';
import Notifications from './pages/Notifications';
import ReclassDamage from './pages/ReclassDamage';
import ResetPassword from './pages/ResetPassword';
import Roles from './pages/Roles';
import ServiceCategories from './pages/ServiceCategories';
import SLOC from './pages/SLOC';
import Users from './pages/Users';
import VarianceReport from './pages/VarianceReport';
import Vault from './pages/Vault';
import Vendor from './pages/Vendor';
import VPAS from './pages/VPAS';
import Wells from './pages/Wells';
import history from './services/history';
import Inspections from './pages/Inspections';
import CycleCounting from './pages/CycleCounting';
import GenerateCycleCounting from './pages/Forms/GenerateCycleCounting';
import ReconcileCycleCounting from './pages/Forms/ReconcileCycleCounting';
import Activities from './pages/Activities';

const privateRoutes = [
  { path: '/', component: Hub },
  { path: '/allocation/:id', component: Allocate },
  { path: '/breakoutdata/:id', component: BreakoutData },
  { path: '/build_of_material/:id', component: BuildOfMaterial },
  { path: '/business_units', component: BusinessUnits },
  { path: '/cart_maintenances/:id?', component: CartMaintenances },
  { path: '/charge_code/import', component: ImportChargeCode },
  { path: '/check_in/:id', component: CheckIn },
  { path: '/check_out/:id', component: CheckOut },
  { path: '/company_codes', component: CompanyCodes },
  { path: '/customers', component: Customers },
  { path: '/damage_descriptions', component: ReclassDamage },
  { path: '/equalityworkorder/:id', component: EQualityWorkOrderForm },
  { path: '/equipment/:id', component: EquipmentForm },
  { path: '/equipment_import', component: ImportMenu },
  { path: '/equipments', component: Home },
  { path: '/export_cart', component: ExportReport },
  { path: '/export_inventory', component: ExportReport },
  { path: '/fabrication/:id', component: Fabrication },
  { path: '/hierarchy/:id', component: HierarchyForm },
  { path: '/history', component: HistoryDashboard },
  { path: '/history/:id', component: History },
  { path: '/inventory_cart', component: InventoryCart },
  { path: '/jobs', component: Job },
  { path: '/maintenance_categories', component: MaintenanceCategories },
  { path: '/maintenances', component: Maintenances },
  { path: '/material_master', component: CustomerMMNo },
  { path: '/material_master/import', component: ImportMaterialMaster },
  { path: '/original_charge_code', component: OriginalChargeCode },
  { path: '/owners', component: Owners },
  { path: '/profile', component: Profile },
  { path: '/mrp-notification-setup', component: MRPNotificationSetup },
  { path: '/project/import', component: ImportProjects },
  { path: '/projects', component: Projects },
  { path: '/notifications', component: Notifications },
  { path: '/reclass/:id', component: Reclass },
  { path: '/reconcile/:id', component: Reconcile },
  { path: '/roles', component: Roles },
  { path: '/select_build_of_material/:id', component: SelectBuildOfMaterial },
  { path: '/service_categories', component: ServiceCategories },
  { path: '/sloc', component: SLOC },
  { path: '/sloc/import', component: ImportSLOC },
  { path: '/transfer_of_ownership/:id', component: TransferOfOwnwership },
  { path: '/users', component: Users },
  { path: '/variance_report', component: VarianceReport },
  { path: '/vault', component: Vault },
  { path: '/vault/:directoryId', component: Vault },
  { path: '/vendor/import', component: ImportVendor },
  { path: '/vendors', component: Vendor },
  { path: '/vpas', component: VPAS },
  { path: '/well/import', component: ImportWells },
  { path: '/wells', component: Wells },
  {
    path: '/workorder_inspection/:workOrderId/:id',
    component: WorkOrderInspectionForm,
  },
  { path: '/inspections/:id', component: Inspections },
  { path: '/cycle_counting', component: CycleCounting },
  { path: '/cycle_counting/generate', component: GenerateCycleCounting },
  { path: '/cycle_counting/reconcile/:id', component: ReconcileCycleCounting },
  { path: '/activities', component: Activities },
];

const router = () => (
  <Router history={history}>
    <Switch>
      {privateRoutes.map(({ path, component }) => (
        <Route
          path={path}
          exact
          isPrivate
          wrapWithNavigation
          component={component}
        />
      ))}
      <Route exact path="/no_permission" component={NoPermissionPage} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/reset-password" component={ResetPassword} />
      <Route path="/not_found" wrapWithNavigation component={NotFound} />
      <Redirect to="/not_found" />
    </Switch>
  </Router>
);

// export default connect((state) => ({ loggedUser: state.loggedUser }))(router);
export default router;
