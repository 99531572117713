import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  makeStyles,
  TextField,
  FormGroup,
  Button,
  Grid,
  FormControl,
  CircularProgress,
} from '@material-ui/core';
import equipmentInputs from '../../../utils/StaticData/EquipmentInputs/filter_data';
import AddIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { setFilterLayout } from '../../../redux/actions/movimentationsFilter';
import {
  Container,
  ModalTitle,
  ButtonContainer,
  ExcelTable,
  Column,
  ColumnHeader,
  ColumnContent,
} from './styles';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 600,
    position: 'relative',
    width: '100%',
  },
}));

const styles = {
  formButton: {
    margin: 2,
  },
};

const alphabeticalOrder = (a, b) => {
  return a.label.localeCompare(b.label);
};

const FilterCustomizer = ({
  handleClose,
  filterLayout,
  reportFieldsLoading,
  dispatch,
}) => {
  const [fields, setFields] = useState(filterLayout);
  const [equipmentInputsSorted] = useState(
    equipmentInputs.sort(alphabeticalOrder)
  );
  const classes = useStyles();
  const [filteredData, setFilteredData] = useState('');

  function checkFieldToRemove(str) {
    return filterAvailableData(str);
  }

  const removeAll = async () => {
    const fieldsCopy = [];
    fields.forEach(function (item) {
      if (!filterAvailableData(item)) fieldsCopy.push(item);
    });
    setFields(fieldsCopy);
  };

  const exportAll = async () => {
    const allFields = equipmentInputsSorted.filter(filterAvailableData);
    setFields([...fields, ...allFields]);
  };

  const imported = (label) => {
    const result = fields.some((item) => {
      if (item.label === label) {
        return true;
      }
    });
    return result;
  };

  const notImported = (label) => {
    const result = fields.some((item) => {
      if (item.label === label) {
        return false;
      }
    });
    return result;
  };

  function handlefilteredData(e) {
    setFilteredData(e.target.value);
  }

  function filterAvailableData(data) {
    return data.label.toUpperCase().includes(filteredData.toUpperCase());
  }

  const saveFields = () => {
    dispatch(setFilterLayout(fields));
    handleClose();
  };

  const handleRemove = async (name) => {
    let newField = imported(name);
    if (newField) {
      equipmentInputsSorted.map((item) => {
        if (item.label === name) {
          const newFields = fields.filter((field) => {
            if (field.label !== name) return field;
          });
          setFields(newFields);
        }
      });
    }
  };

  const handleImport = async (name) => {
    let newField = imported(name);
    if (!newField) {
      equipmentInputsSorted.map((item) => {
        if (item.label === name) {
          setFields([...fields, item]);
        }
      });
    }
  };

  const areAllVisibleSelected =
    fields.filter(filterAvailableData).length ==
    equipmentInputsSorted.filter(filterAvailableData).length;

  return (
    <>
      <Container>
        <ModalTitle>Filter Customizer</ModalTitle>
        <Grid
          container
          style={{
            padding: '0px 10px 10px 10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ExcelTable>
            <Column>
              <ColumnHeader>
                Available Data
                <TextField
                  label="Filter"
                  variant="outlined"
                  value={filteredData}
                  onChange={handlefilteredData}
                  fullWidth
                  size="small"
                  style={{ margin: 10 }}
                />
              </ColumnHeader>
              <ColumnContent>
                <FormGroup>
                  {equipmentInputsSorted?.filter(filterAvailableData).map(
                    (obj, i) =>
                      !imported(obj.label) && (
                        <div
                          onClick={() => handleImport(obj.label)}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                          }}
                        >
                          <AddIcon
                            key={i}
                            style={{ margin: 9 }}
                            name={obj.label}
                            checked={imported(obj.label)}
                            color="primary"
                          />
                          {obj.label}
                        </div>
                      )
                  )}
                </FormGroup>
              </ColumnContent>
            </Column>
            <Column>
              <ColumnContent>
                <FormGroup>
                  <div
                    style={{
                      margin: 0,
                      marginTop: '15rem',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontWeight: 'bold',
                        marginLeft: '56px',
                      }}
                    >
                      <Button
                        onClick={() => exportAll()}
                        variant="outlined"
                        title="Add All"
                        size="small"
                        color="primary"
                        className={classes.margin}
                        disabled={areAllVisibleSelected}
                      >
                        {'>>'}
                      </Button>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontWeight: 'bold',
                        marginTop: '1rem',
                        marginLeft: '56px',
                      }}
                    >
                      <Button
                        onClick={() => removeAll()}
                        variant="outlined"
                        title="Remove All"
                        size="small"
                        color="secondary"
                        className={classes.margin}
                        disabled={
                          fields.filter(filterAvailableData).length === 0
                        }
                      >
                        {'<<'}
                      </Button>
                    </div>
                  </div>
                </FormGroup>
              </ColumnContent>
            </Column>
            <Column>
              <ColumnHeader style={{ marginBottom: '3.5rem' }}>
                Selected Data
              </ColumnHeader>
              <ColumnContent>
                <FormGroup colum>
                  {fields?.filter(filterAvailableData).map((obj, i) => (
                    <div
                      onClick={() => handleRemove(obj.label)}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                    >
                      <CancelIcon
                        key={i}
                        style={{ color: 'red', margin: 9 }}
                        name={obj.label}
                        checked={notImported(obj.label)}
                        color="primary"
                      />
                      {obj.label}
                    </div>
                  ))}
                </FormGroup>
                <FormControl
                  variant="outlined"
                  size="small"
                  fullWidth
                  style={{ marginTop: 10 }}
                >
                  <Button
                    onClick={saveFields}
                    variant="contained"
                    color="primary"
                    startIcon={
                      reportFieldsLoading && (
                        <CircularProgress size={24} style={{ color: '#fff' }} />
                      )
                    }
                  >
                    Save
                  </Button>
                </FormControl>
              </ColumnContent>
            </Column>
          </ExcelTable>
        </Grid>

        <ButtonContainer>
          <Button
            color="secondary"
            onClick={handleClose}
            variant="contained"
            style={styles.formButton}
          >
            Cancel
          </Button>
        </ButtonContainer>
      </Container>
    </>
  );
};

function mapStateToProps(state) {
  return { filterLayout: state.filterLayout };
}

export default connect(mapStateToProps)(FilterCustomizer);
