import {
  FETCH_PROJECTS_SUCCESS,
  FETCH_PROJECTS_ERROR,
  GET_PROJECTS,
  updateProjects,
  showSpinner,
  hideSpinner,
} from '../actions/projects';
import { apiRequest } from '../actions/api';

const URL = 'projects';

export const getProjectsFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_PROJECTS) {
    dispatch(
      apiRequest(
        'GET',
        URL,
        action.payload,
        FETCH_PROJECTS_SUCCESS,
        FETCH_PROJECTS_ERROR,
        ['listAll'],
      ),
    );
    dispatch(showSpinner());
  }
};

export const processProjectsCollection = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_PROJECTS_SUCCESS) {
    dispatch(updateProjects(action.payload));
    dispatch(hideSpinner());
  }
};

export const projectsMdl = [getProjectsFlow, processProjectsCollection];
