import React from 'react';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { connect } from 'react-redux';
import {
  Add,
  Close as CloseIcon,
  AttachFile as AttachFileIcon,
} from '@material-ui/icons';
import Alert from '../../../components/Alert';
import {
  showAdditionalNotesDataModal,
  hideAdditionalNotesDataModal,
} from '../../../redux/actions/workOrders';
import ModalContainer from '../../../components/ModalContainer';
import AdditionalNoteModal from '../../Modals/AdditionalNotesData';
import { AddButton } from './styles';
import { download } from '../../../services/Download';

function AdditionalNotes({ dispatch, data, setData, woAdditionalNoteModal }) {
  const {
    revision: { additional_notes = [] },
  } = data;
  const [editing, setEditing] = React.useState(null);

  function handleCloseAdditionalNoteModal() {
    dispatch(hideAdditionalNotesDataModal());
  }

  function handleAdditionalNoteModal(newData) {
    let newAdditionalNotes = [...additional_notes];
    if (editing !== null) {
      newAdditionalNotes[editing] = newData;
    }
    setData((currentData) => ({
      ...currentData,
      revision: {
        ...currentData.revision,
        additional_notes:
          editing !== null
            ? newAdditionalNotes
            : [...currentData.revision.additional_notes, newData],
      },
    }));
    dispatch(hideAdditionalNotesDataModal());
  }

  function handleDelete(index) {
    return function () {
      Alert(
        'Are you sure?',
        "You won't be able to revert this!",
        false,
        '',
        'Yes, delete it.'
      ).then(({ confirm }) => {
        if (!confirm) return;
        setData((currentData) => {
          const currentAdditionalNotes = [
            ...currentData.revision.additional_notes,
          ];
          currentAdditionalNotes.splice(index, 1);
          return {
            ...currentData,
            revision: {
              ...currentData.revision,
              additional_notes: currentAdditionalNotes,
            },
          };
        });
        dispatch(hideAdditionalNotesDataModal());
      });
    };
  }

  function handleDeleteModal() {
    handleDelete(editing)();
  }

  const handleDownload = (index) => {
    return function () {
      download(`vault/download/${additional_notes[index].attachment?.file?.filename}`);
    };
  };

  return (
    <>
      <ModalContainer
        open={woAdditionalNoteModal}
        handleClose={handleCloseAdditionalNoteModal}
      >
        <AdditionalNoteModal
          handleClose={handleCloseAdditionalNoteModal}
          onSave={handleAdditionalNoteModal}
          onDelete={handleDeleteModal}
          editData={editing !== null ? additional_notes[editing] : null}
        />
      </ModalContainer>
      <AddButton
        variant="contained"
        color="primary"
        size="small"
        onClick={() => {
          setEditing(null);
          dispatch(showAdditionalNotesDataModal());
        }}
      >
        <Add />
        Add new note
      </AddButton>
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={3}>Note</TableCell>
              <TableCell>Attached Doc</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {additional_notes.length < 1 && (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No Note Added
                </TableCell>
              </TableRow>
            )}
            {additional_notes.map((additional_note, index) => (
              <Tooltip
                title="Double click to edit"
                arrow
                placement="bottom-start"
              >
                <TableRow
                  hover
                  onDoubleClick={() => {
                    setEditing(index);
                    dispatch(showAdditionalNotesDataModal());
                  }}
                >
                  <TableCell colSpan={3} style={{ whiteSpace: 'pre-wrap' }}>
                    {additional_note.note}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={handleDownload(index)}>
                      <AttachFileIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Remove">
                      <IconButton
                        onClick={handleDelete(index)}
                        color="secondary"
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              </Tooltip>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default connect((state) => ({
  woAdditionalNoteModal: state.ui.woAdditionalNoteModal,
}))(AdditionalNotes);
