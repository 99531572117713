export const GET_BIN = '[bin] GET';
export const UPDATE_BIN = '[bin] UPDATE';
export const CREATE_BIN = '[bin] CREATE';
export const DELETE_BIN = '[bin] DELETE';
export const UPDATE_OBJECT_BIN = '[bin] UPDATE OBJECT BIN';
// UI
export const SHOW_BIN_MODAL = '[bin] SHOW MODAL';
export const HIDE_BIN_MODAL = '[bin] HIDE MODAL';
// Spinner
export const UPDATE_BIN_SUCCESS = '[bin] Update success';
export const UPDATE_BIN_ERROR = '[bin] Update Error';
export const FETCH_BIN_SUCCESS = '[bin] Fetch success';
export const FETCH_BIN_ERROR = '[bin] Fetch Error';
export const SHOW_BIN_SPINNER = '[bin - ui] show spinner';
export const HIDE_BIN_SPINNER = '[bin - ui] hide spinner';

export const getBin = (id) => ({
  type: GET_BIN,
  payload: { _id: id },
});

export const createBin = (data) => ({
  type: CREATE_BIN,
  payload: data,
});

export const updateBin = (data) => ({
  type: UPDATE_BIN,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_BIN_MODAL,
});

export const hideModal = () => ({
  type: HIDE_BIN_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_BIN_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_BIN_SPINNER,
});

export const updateObjectBin = (data) => ({
  type: UPDATE_OBJECT_BIN,
  payload: data,
});
