import React, { useState } from 'react';
import { Paper, Typography, Box, Button, TextField } from '@material-ui/core';
import ModalContainer from '../../../../../components/ModalContainer';

function SubmissionModal({ open, onClose, onConfirm }) {
  const [time, setTime] = useState('');

  const handleTimeChange = (e) => setTime(e.target.value);
  const handleCancel = () => {
    setTime('');
    onClose();
  };

  return (
    <ModalContainer open={open} handleClose={handleCancel} maxWidth="xs">
      <Paper component={Box} padding={2}>
        <Typography variant="h6">Submit this inspection?</Typography>
        <Typography variant="body">
          If this inspection is ready to be submitted, please, inform the time
          to job completion
        </Typography>
        <TextField
          value={time}
          type="number"
          onChange={handleTimeChange}
          variant="outlined"
          placeholder="Time spent"
          InputProps={{ endAdornment: 'h' }}
          fullWidth
        />
        <Box
          display="flex"
          justifyContent="center"
          marginTop={1}
          style={{ gap: 16 }}
        >
          <Button color="secondary" variant="outlined" onClick={handleCancel}>
            Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={onConfirm(time)}>
            Confirm
          </Button>
        </Box>
      </Paper>
    </ModalContainer>
  );
}

export default SubmissionModal;
