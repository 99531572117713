import {
  UPDATE_ALLOCATE_SUCCESS,
  UPDATE_ALLOCATE_ERROR,
  FETCH_ALLOCATE_SUCCESS,
  FETCH_ALLOCATE_ERROR,
  GET_ALLOCATE,
  CREATE_ALLOCATE,
  UPDATE_ALLOCATE,
  updateObjectAllocate,
  showSpinner,
  hideSpinner,
  hideModal,
} from '../actions/allocate';
import { removeFromInventoryCart } from '../actions/inventory_cart';
import Toastify from '../../utils/Toastify/index';
import history from '../../services/history';
import { apiRequest } from '../actions/api';
import { CUSTOMER_KEY } from '../../config/consts';

const URL = 'allocation';

export const createAllocateFlow =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === CREATE_ALLOCATE) {
      action.payload.append('inventory', localStorage.getItem(CUSTOMER_KEY));
      action.payload.append('user', getState().loggedUser._id);
      dispatch(
        apiRequest(
          'POST',
          URL,
          action.payload,
          UPDATE_ALLOCATE_SUCCESS,
          UPDATE_ALLOCATE_ERROR
        )
      );
      dispatch(showSpinner());
    }
  };

export const updateAllocateFlow =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === UPDATE_ALLOCATE) {
      dispatch(
        apiRequest(
          'PUT',
          URL,
          action.payload,
          UPDATE_ALLOCATE_SUCCESS,
          UPDATE_ALLOCATE_ERROR
        )
      );
      dispatch(showSpinner());
    }
  };

export const getAllocateFlow =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === GET_ALLOCATE) {
      const { _id } = action.payload;
      dispatch(
        apiRequest(
          'GET-id',
          URL,
          { _id },
          FETCH_ALLOCATE_SUCCESS,
          FETCH_ALLOCATE_ERROR
        )
      );
      dispatch(showSpinner());
    }
  };

export const processAllocateCollection =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === FETCH_ALLOCATE_SUCCESS) {
      dispatch(updateObjectAllocate(action.payload));
      dispatch(hideSpinner());
    } else if (action.type === UPDATE_ALLOCATE_SUCCESS) {
      dispatch(updateObjectAllocate(action.payload));
      dispatch(
        removeFromInventoryCart(
          getState().loggedUser._id,
          action.payload?.individualData?.map(
            (data) => data?.movimentation?._id || data?.movimentation
          )
        )
      );
      dispatch(hideModal());
      dispatch(hideSpinner());
      history.replace(`/allocation/${action.payload._id}`);
      Toastify.addSuccess('Allocate saved.');
    } else if (action.type === UPDATE_ALLOCATE_ERROR) {
      dispatch(hideSpinner());
      Toastify.addError(action.payload || 'Error to save the allocation');
    }
  };

export const allocateMdl = [
  createAllocateFlow,
  updateAllocateFlow,
  getAllocateFlow,
  processAllocateCollection,
];
