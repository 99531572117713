import {
  UPDATE_INSPECTION_SUCCESS,
  UPDATE_INSPECTION_ERROR,
  FETCH_INSPECTION_SUCCESS,
  FETCH_INSPECTION_ERROR,
  GET_INSPECTION,
  CREATE_INSPECTION,
  UPDATE_INSPECTION,
  updateObjectInspection,
  showSpinner,
  hideSpinner,
  hideModal,
} from '../actions/inspection';
import { getInspections } from '../actions/inspections';
import Toastify from '../../utils/Toastify/index';
import { apiRequest } from '../actions/api';
import history from '../../services/history';

const URL = 'inspections';

export const createInspectionFlow = ({ dispatch }) =>
  (next) =>
    (action) => {
      next(action);
      if (action.type === CREATE_INSPECTION) {
        dispatch(
          apiRequest(
            'POST',
            URL,
            action.payload,
            UPDATE_INSPECTION_SUCCESS,
            UPDATE_INSPECTION_ERROR
          )
        );
        dispatch(showSpinner());
      }
    };

export const updateInspectionFlow = ({ dispatch }) =>
  (next) =>
    (action) => {
      next(action);
      if (action.type === UPDATE_INSPECTION) {
        dispatch(
          apiRequest(
            'PUT',
            URL,
            action.payload,
            UPDATE_INSPECTION_SUCCESS,
            UPDATE_INSPECTION_ERROR
          )
        );
        dispatch(showSpinner());
      }
    };

export const getInspectionFlow = ({ dispatch }) =>
  (next) =>
    (action) => {
      next(action);
      if (action.type === GET_INSPECTION) {
        const { _id } = action.payload;
        dispatch(
          apiRequest(
            'GET-id',
            URL,
            { _id },
            FETCH_INSPECTION_SUCCESS,
            FETCH_INSPECTION_ERROR
          )
        );
        dispatch(showSpinner());
      }
    };

export const processInspectionCollection = ({ dispatch, getState }) =>
  (next) =>
    (action) => {
      next(action);
      if (action.type === FETCH_INSPECTION_SUCCESS) {
        dispatch(updateObjectInspection(action.payload));
        dispatch(hideSpinner());
      } else if (action.type === UPDATE_INSPECTION_SUCCESS) {
        dispatch(hideModal());
        dispatch(hideSpinner());
        // To reload the page
        const { inspection, workOrder } = getState();
        history.replace(
          `/workorder_inspection/${workOrder?._id}/${action.payload?._id || inspection?._id}`
        );
        dispatch(getInspections({ filter: { listAll: true } }));
        Toastify.addSuccess('Inspection saved.');
      } else if (action.type === UPDATE_INSPECTION_ERROR) {
        dispatch(hideSpinner());
        Toastify.addError(action.payload || 'Error to save the inspection');
      }
    };

export const inspectionMdl = [
  createInspectionFlow,
  updateInspectionFlow,
  getInspectionFlow,
  processInspectionCollection,
];
