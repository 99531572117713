export default function handlePost(
  id,
  data,
  loggedUser,
  createAction,
  updateAction,
  dispatch,
) {
  return function () {
    const jsonData = {
      ...data,
      ...(data?.individualData?.length
        ? {
          individualData: data?.individualData?.map((curData) => ({
            ...curData,
            movimentation: curData.movimentation._id || curData.movimentation,
          })),
        }
        : {}),
      ...(data?.movimentation?.length
        ? { movimentations: data?.movimentations?.map((mov) => mov._id || mov) }
        : {}),
    };
    delete jsonData.newFiles;
    delete jsonData._id;
    const formData = new FormData();
    Object.keys(jsonData).forEach((field) => formData.append(field, JSON.stringify(jsonData[field])));

    Array.from(data.newFiles).forEach((file) => formData.append('newFiles', file));

    if (id === 'new') {
      dispatch(createAction(formData));
    } else {
      formData.append('editor', loggedUser._id);
      formData.append('_id', id);
      dispatch(updateAction(formData));
    }
  };
}
