import {
  UPDATE_WORK_ORDER_SUCCESS,
  UPDATE_WORK_ORDER_ERROR,
  FETCH_WORK_ORDER_SUCCESS,
  FETCH_WORK_ORDER_ERROR,
  GET_WORK_ORDER,
  CREATE_WORK_ORDER,
  UPDATE_WORK_ORDER,
  UPLOAD_FILE,
  SUCCESS_UPLOAD_FILE,
  ERROR_UPLOAD_FILE,
  updateObjectWorkOrder,
  getWorkOrder,
  showSpinner,
  hideSpinner,
} from '../actions/workOrder';
import {
  getWorkOrders,
  hidePhotoDataModal,
  hideDocumentDataModal,
} from '../actions/workOrders';
import Toastify from '../../utils/Toastify/index';
import { apiRequest } from '../actions/api';

const URL = 'work_orders';

export const createWorkOrderFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === CREATE_WORK_ORDER) {
    dispatch(
      apiRequest(
        'POST',
        URL,
        action.payload,
        UPDATE_WORK_ORDER_SUCCESS,
        UPDATE_WORK_ORDER_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const updateWorkOrderFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === UPDATE_WORK_ORDER) {
    dispatch(
      apiRequest(
        'PUT',
        URL,
        action.payload,
        UPDATE_WORK_ORDER_SUCCESS,
        UPDATE_WORK_ORDER_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const getWorkOrderFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_WORK_ORDER) {
    const { _id } = action.payload;
    dispatch(
      apiRequest(
        'GET-id',
        URL,
        { _id },
        FETCH_WORK_ORDER_SUCCESS,
        FETCH_WORK_ORDER_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const uploadFileFlow = ({ dispatch, getState }) => (next) => (action) => {
  next(action);
  if (action.type === UPLOAD_FILE) {
    const { _id, data } = action.payload;
    const formData = new FormData();
    formData.append('file', data.file);
    formData.append('user', getState().loggedUser._id);
    formData.append('docType', data.docType);
    formData.append('description', data.description);
    formData.append('document', data.document);
    dispatch(
      apiRequest(
        'PUT-file',
        `${URL}/${_id}/upload`,
        formData,
        SUCCESS_UPLOAD_FILE,
        ERROR_UPLOAD_FILE,
      ),
    );
    dispatch(showSpinner());
  }
};

export const processWorkOrderCollection = ({ dispatch, getState }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_WORK_ORDER_SUCCESS) {
    dispatch(updateObjectWorkOrder(action.payload));
    dispatch(hideSpinner());
  } else if (action.type === UPDATE_WORK_ORDER_SUCCESS) {
    dispatch(hideSpinner());
    dispatch(hidePhotoDataModal());
    dispatch(hideDocumentDataModal());
    // To reload the page
    const { page, limit } = getState().workOrders;
    dispatch(getWorkOrders({ page, limit }));
    Toastify.addSuccess('Work Order saved.');
    window.location.replace(`/equalityworkorder/${action.payload._id}`);
  } else if (action.type === UPDATE_WORK_ORDER_ERROR) {
    dispatch(hideSpinner());
    Toastify.addError(action.payload || 'Error to save the Work Order');
  } else if (action.type === SUCCESS_UPLOAD_FILE) {
    dispatch(getWorkOrder(getState().workOrder._id));
    dispatch(hidePhotoDataModal());
    dispatch(hideDocumentDataModal());
    Toastify.addSuccess('File uploaded successfully');
  } else if (action.type === ERROR_UPLOAD_FILE) {
    Toastify.addError(action.payload.error || "Error - Couldn't upload file");
  }
};

export const workOrderMdl = [
  createWorkOrderFlow,
  updateWorkOrderFlow,
  getWorkOrderFlow,
  uploadFileFlow,
  processWorkOrderCollection,
];
