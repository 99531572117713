import { CUSTOMER_KEY } from '../../config/consts';

export const GET_CUSTOMER_MMS = '[customer_mms] GET';
export const FETCH_CUSTOMER_MMS_SUCCESS = '[customer_mms] Fetch success';
export const FETCH_CUSTOMER_MMS_ERROR = '[customer_mms] Fetch Error';
export const UPDATE_CUSTOMER_MMS = '[customer_mms] Update';
export const SHOW_CUSTOMER_MMS_SPINNER = '[customer_mms - ui] show spinner';
export const HIDE_CUSTOMER_MMS_SPINNER = '[customer_mms - ui] hide spinner';

export const getCustomerMMs = (filter) => ({
  type: GET_CUSTOMER_MMS,
  payload: filter,
});

export const updateCustomerMMs = (data) => ({
  type: UPDATE_CUSTOMER_MMS,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_CUSTOMER_MMS_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_CUSTOMER_MMS_SPINNER,
});
