import React from 'react';
import Icon from '@material-ui/core/Icon';
import {
  Add,
  AddCircle,
  Build as MaintenanceIcon,
  Cached,
  Delete,
  LocalOffer,
  Refresh,
  SwapHoriz,
} from '@material-ui/icons';

const cartFunctions = [
  {
    title: 'Create Work Order',
    label: 'Work Order',
    featureKey: 'equalityworkorder',
    Icon: <Add />,
  },
  {
    title: 'Allocation',
    label: 'Allocation',
    featureKey: 'allocation',
    Icon: <LocalOffer />,
  },
  {
    title: 'Transfer of Ownership',
    label: 'Transfer of Ownership',
    featureKey: 'transfer_of_ownership',
    Icon: <SwapHoriz />,
  },
  {
    title: 'Reconcile',
    label: 'Reconcile',
    featureKey: 'reconcile',
    Icon: <Cached />,
  },
  {
    title: 'Move',
    label: 'Move',
    featureKey: 'move',
    Icon: <Icon>move_up</Icon>,
  },
  {
    title: 'Reclass Tracking',
    label: 'Reclass',
    featureKey: 'reclass',
    Icon: <Refresh />,
  },
  {
    title: 'Create Bill of Material',
    label: 'Bill of Material',
    featureKey: 'select_build_of_material',
    Icon: <AddCircle />,
    inventoryRequired: true,
  },
  {
    title: 'Fabrication',
    label: 'Fabrication',
    featureKey: 'fabrication',
    Icon: <Icon>carpenter</Icon>,
  },
  {
    title: 'Maintenance',
    label: 'Maintenance',
    featureKey: 'cart_maintenances',
    Icon: <MaintenanceIcon />,
  },
  {
    title: 'Remove From Cart',
    label: 'Remove',
    featureKey: 'remove',
    Icon: <Delete />,
  },
];

export default cartFunctions;
