export const GET_COMPANY_CODES = '[company_codes] GET';
export const FETCH_COMPANY_CODES_SUCCESS = '[company_codes] Fetch success';
export const FETCH_COMPANY_CODES_ERROR = '[company_codes] Fetch Error';
export const UPDATE_COMPANY_CODES = '[company_codes] Update';
export const SHOW_COMPANY_CODES_SPINNER = '[company_codes - ui] show spinner';
export const HIDE_COMPANY_CODES_SPINNER = '[company_codes - ui] hide spinner';

export const getCompanyCodes = (filter) => ({
  type: GET_COMPANY_CODES,
  payload: filter,
});

export const updateCompanyCodes = (data) => ({
  type: UPDATE_COMPANY_CODES,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_COMPANY_CODES_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_COMPANY_CODES_SPINNER,
});
