import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
} from '@material-ui/core';
import Alert from '../../../components/Alert';
import Spinner from '../../../components/Spinner';
import WarehouseTable from './components/WarehouseTable';
import {
  Container,
  InputContent,
  ModalTitle,
  ButtonContainer,
  InputContainer,
} from './styles';

import { getSLOC, createSLOC, updateSLOC } from '../../../redux/actions/sloc';

const styles = {
  formButton: {
    margin: 2,
  },
};

const SLOCModal = ({
  id,
  dispatch,
  sloc,
  slocModal,
  handleClose,
  slocSpinner,
}) => {
  const [nameError, setNameError] = useState(false);
  const [idError, setIdError] = useState(false);
  const [data, setData] = useState({
    sloc_name: '',
    sloc_id: '',
    facility_type: '',
    warehouses: [],
  });
  const { sloc_name, sloc_id, facility_type, warehouses } = data;
  useEffect(() => {
    if (id !== 'new') {
      dispatch(getSLOC(id));
    }
  }, [id]);

  useEffect(() => {
    if (sloc && id !== 'new') {
      setData(sloc);
    }
  }, [sloc]);

  useEffect(() => {
    if (!slocModal) {
      handleClose();
    }
  }, [slocModal]);

  const handleSave = async () => {
    let err = false;
    setNameError(!sloc_name);
    setIdError(!sloc_id);
    err = !sloc_name || !sloc_id;

    if (!err) {
      const response = await Alert();
      if (response && response.confirm) {
        if (id === 'new') {
          dispatch(createSLOC(data));
        } else {
          dispatch(updateSLOC(data));
        }
      }
    }
  };

  const handleEdit = (e) => {
    const { name, value } = e.target;
    setData((currentData) => ({ ...currentData, [name]: value }));
  };

  const handleNewWarehouse = (warehouse) => {
    setData((currentData) => ({
      ...currentData,
      warehouses: [...currentData.warehouses, warehouse],
    }));
  };

  const handleNewRack = (warehouseIndex, rack) => {
    setData((currentData) => {
      const warehousesCopy = [...currentData.warehouses];
      warehousesCopy[warehouseIndex].racks = [
        ...(warehousesCopy[warehouseIndex].racks || []),
        rack,
      ];
      return {
        ...currentData,
        warehouses: warehousesCopy,
      };
    });
  };

  const handleNewBin = (warehouseIndex, rackIndex, bin) => {
    setData((currentData) => {
      const warehousesCopy = [...currentData.warehouses];
      warehousesCopy[warehouseIndex].racks[rackIndex].bins = [
        ...(warehousesCopy[warehouseIndex]?.racks[rackIndex]?.bins || []),
        bin,
      ];
      return {
        ...currentData,
        warehouses: warehousesCopy,
      };
    });
  };

  const handleRemoveWarehouse = (warehouseIndex) => {
    setData((currentData) => {
      const warehousesCopy = [...currentData.warehouses];
      warehousesCopy.splice(warehouseIndex, 1);
      return {
        ...currentData,
        warehouses: warehousesCopy,
      };
    });
  };
  const handleRemoveRack = (warehouseIndex, rackIndex) => {
    setData((currentData) => {
      const warehousesCopy = [...currentData.warehouses];
      warehousesCopy[warehouseIndex].racks.splice(rackIndex, 1);
      return {
        ...currentData,
        warehouses: warehousesCopy,
      };
    });
  };
  const handleRemoveBin = (warehouseIndex, rackIndex, binIndex) => {
    setData((currentData) => {
      const warehousesCopy = [...currentData.warehouses];
      warehousesCopy[warehouseIndex].racks[rackIndex].bins.splice(binIndex, 1);
      return {
        ...currentData,
        warehouses: warehousesCopy,
      };
    });
  };

  return (
    <>
      {slocSpinner && <Spinner overlay />}

      <Container style={{ opacity: slocSpinner ? 0.5 : 1 }}>
        <ModalTitle>{id === 'new' ? 'New' : 'Edit'} SLOC</ModalTitle>
        <InputContent>
          <TextField
            id="outlined-password-input"
            label="SLOC"
            required
            type="text"
            error={nameError}
            helperText={nameError && 'SLOC is required'}
            variant="outlined"
            value={sloc_name || ''}
            name="sloc_name"
            onChange={handleEdit}
            fullWidth
          />
        </InputContent>
        <InputContent>
          <TextField
            id="outlined-password-input"
            label="SLOC ID"
            required
            type="text"
            error={idError}
            helperText={idError && 'SLOC ID is required'}
            variant="outlined"
            value={sloc_id || ''}
            name="sloc_id"
            onChange={handleEdit}
            fullWidth
          />
        </InputContent>
        <InputContainer>
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel id="select-label">Facility Type</InputLabel>
            <Select
              size="small"
              labelId="select-label"
              id="select-label-component"
              value={facility_type || ''}
              variant="outlined"
              name="facility_type"
              fullWidth
              onChange={handleEdit}
            >
              <MenuItem value="">Select a Option</MenuItem>
              <MenuItem value="Storage">Storage</MenuItem>
              <MenuItem value="WIP">WIP</MenuItem>
              <MenuItem value="End User">End User</MenuItem>
            </Select>
          </FormControl>
        </InputContainer>
        {id === 'new' && (
          <>
            <Divider />
            <WarehouseTable
              data={warehouses}
              onNewWarehouse={handleNewWarehouse}
              onNewRack={handleNewRack}
              onNewBin={handleNewBin}
              onRemoveWarehouse={handleRemoveWarehouse}
              onRemoveRack={handleRemoveRack}
              onRemoveBin={handleRemoveBin}
            />
          </>
        )}

        <ButtonContainer>
          <Button
            color="secondary"
            onClick={handleClose}
            variant="contained"
            style={styles.formButton}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={handleSave}
            variant="contained"
            style={styles.formButton}
          >
            Save
          </Button>
        </ButtonContainer>
      </Container>
    </>
  );
};

export default connect((state) => ({
  sloc: state.sloc,
  slocModal: state.ui.slocModal,
  slocSpinner: state.ui.slocLoading,
}))(SLOCModal);
