import React from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepContent from '@material-ui/core/StepContent';
import { Button, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from './styles';
import Documents from './Documents';
import Photos from './Photos';
import Results from './Results';
import SubmissionModal from './components/SubmissionModal';
import { getVendors } from '../../../redux/actions/vendors';
import { getProjects } from '../../../redux/actions/projects';
import { getOriginalCharge } from '../../../redux/actions/originalCharges';
import {
  createInspection,
  getInspection,
  updateInspection,
} from '../../../redux/actions/inspection';
import { getWorkOrder } from '../../../redux/actions/workOrder';
import Alert from '../../../components/Alert';

const steps = [
  { label: 'Inspection Docs', Component: Documents },
  { label: 'Photo Report', Component: Photos },
  { label: 'Inspection Results', Component: Results },
];

function WorkOrderInspection() {
  const [submissionModalOpen, setSubmissionModalOpen] = React.useState(false);
  const { id, workOrderId } = useParams();
  const { replace } = useHistory();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const inspection = useSelector((state) => state.inspection);
  const loggedUser = useSelector((state) => state.loggedUser._id);

  const [currentStep, setCurrentStep] = React.useState(null);
  const [data, setData] = React.useState({
    docs: [],
    photos: [],
    equipment_service: [],
  });

  React.useEffect(() => {
    if (!workOrderId) return;

    dispatch(getWorkOrder(workOrderId));
  }, [workOrderId]);

  React.useEffect(() => {
    if (!id || id === 'new') return;

    dispatch(getInspection(id));
  }, [id]);

  React.useEffect(() => {
    if (!inspection || id === 'new') return;

    setData(inspection);
  }, [inspection]);

  React.useEffect(() => {
    dispatch(getVendors({ filter: { listAll: true } }));
  }, []);

  React.useEffect(() => {
    dispatch(getProjects({ filter: { listAll: true } }));
  }, []);

  React.useEffect(() => {
    dispatch(getOriginalCharge({ filter: { listAll: true } }));
  }, []);

  React.useEffect(() => {
    if (state?.movimentations) {
      setData((currentData) => ({
        ...currentData,
        movimentations: state.movimentations,
      }));
    }
  }, [state]);

  function handleEdit(e) {
    const { name, value } = e.target;
    setData((currentData) => ({ ...currentData, [name]: value }));
  }

  function handleDate(name) {
    function handler(value) {
      setData((currentData) => ({ ...currentData, [name]: value }));
    }
    return handler;
  }

  function handleNext() {
    setCurrentStep((currentValue) => currentValue + 1);
  }
  function handlePrevious() {
    setCurrentStep((currentValue) => currentValue - 1);
  }
  function handleStep(step) {
    function handler() {
      setCurrentStep((currentValue) => (currentValue === step ? null : step));
    }
    return handler;
  }

  const handleUpdate = (newData) => {
    dispatch(updateInspection({
      ...newData,
      equipment_service: newData.equipment_service?.map(
        ({ service, equipment, maintenance, status, note, photo }) => ({
          service,
          equipment: equipment?._id || equipment,
          maintenance,
          status,
          note,
          photo,
        })
      ),
    }));
  };

  const handleSubmit = (time) => async () => {
    handleUpdate({
      ...data,
      equipment_service: data.equipment_service?.map(
        ({ service, equipment, maintenance, status, note, photo }) => ({
          service,
          equipment: equipment?._id || equipment,
          maintenance,
          status,
          note,
          photo,
        })
      ),
      hours_spent: time,
      status: 'Submitted',
    });
    setSubmissionModalOpen(false);
  };

  const handlePost = async () => {
    const response = await Alert();
    if (response && response.confirm) {
      dispatch(
        createInspection({
          ...data,
          equipment_service: data.equipment_service?.map(
            ({ service, equipment, maintenance, status, note, photo }) => ({
              service,
              equipment: equipment?._id || equipment,
              maintenance,
              status,
              note,
              photo,
            })
          ),
          inspector: loggedUser,
          workOrder: workOrderId,
        })
      );
    }
  };

  const handleEquipmentService = (equipmentService) => {
    setData((currentData) => ({
      ...currentData,
      equipment_service: [...currentData.equipment_service, equipmentService],
    }));
  };

  const handleCloseSubmissionModal = () => {
    setSubmissionModalOpen(false);
  };
  const handleOpenSubmissionModal = () => {
    setSubmissionModalOpen(true);
  };

  const goBack = () => {
    replace(`/inspections/${workOrderId}`);
  };

  return (
    <>
      <Container>
        <Stepper activeStep={currentStep} nonLinear orientation="vertical">
          <Typography variant="h5">Work Order Inspection</Typography>
          {steps.map(({ label, Component }, index) => (
            <Step key={index}>
              <StepButton icon={index + 8} onClick={handleStep(index + 1)}>
                {label}
              </StepButton>
              <StepContent>
                <Component
                  handleDate={handleDate}
                  handleEdit={handleEdit}
                  data={data}
                  setData={setData}
                  onChangeEquipmentService={handleEquipmentService}
                />
                <Button
                  variant="text"
                  disabled={index === 0}
                  onClick={handlePrevious}
                  style={{ marginRight: 20, marginLeft: 30, marginTop: 20 }}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  color={index === steps.length - 1 ? 'primary' : 'secondary'}
                  onClick={
                    index === steps.length - 1
                      ? id === 'new'
                        ? handlePost
                        : handleOpenSubmissionModal
                      : handleNext
                  }
                  style={{ marginTop: 20 }}
                  disabled={
                    index === steps.length - 1 &&
                    data.status &&
                    data.status !== 'Pending'
                  }
                >
                  {index === steps.length - 1
                    ? id === 'new'
                      ? 'Save'
                      : 'Submit'
                    : 'Next'}
                </Button>
              </StepContent>
            </Step>
          ))}
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <Button
              variant="outlined"
              onClick={goBack}
              style={{ marginRight: 20, marginLeft: 30, marginTop: 20 }}
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              variant={id === 'new' ? 'contained' : 'outlined'}
              color="primary"
              onClick={id === 'new' ? handlePost : () => handleUpdate(data)}
              style={{ marginTop: 20 }}
              disabled={
                id !== 'new' && data.status && data.status !== 'Pending'
              }
            >
              Save
            </Button>

            {id !== 'new' && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenSubmissionModal}
                style={{ marginTop: 20 }}
                disabled={data.status && data.status !== 'Pending'}
              >
                Submit
              </Button>
            )}
          </div>
        </Stepper>
      </Container>
      <SubmissionModal
        open={submissionModalOpen}
        onClose={handleCloseSubmissionModal}
        onConfirm={handleSubmit}
      />
    </>
  );
}

export default WorkOrderInspection;
