import {
  GET_WAREHOUSE,
  UPDATE_WAREHOUSE,
  UPDATE_OBJECT_WAREHOUSE,
  CREATE_WAREHOUSE,
  DELETE_WAREHOUSE,
} from '../actions/warehouse';

export function warehouseReducer(warehouse = {}, action) {
  switch (action.type) {
    case UPDATE_OBJECT_WAREHOUSE:
      return action.payload;
    case GET_WAREHOUSE:
    case UPDATE_WAREHOUSE:
    case CREATE_WAREHOUSE:
    case DELETE_WAREHOUSE:
    default:
      return warehouse;
  }
}
