import {
  GET_REPORT_FIELDS,
  UPDATE_REPORT_FIELDS,
} from '../actions/reportFields';

const initState = [];

export function reportFieldsReducer(reportFields = initState, action) {
  switch (action.type) {
    case GET_REPORT_FIELDS:
      return reportFields;
    case UPDATE_REPORT_FIELDS:
      return action.payload;

    default:
      return reportFields;
  }
}
