import {
  FETCH_BINS_SUCCESS,
  FETCH_BINS_ERROR,
  GET_BINS,
  updateBins,
  showSpinner,
  hideSpinner,
} from '../actions/bins';
import { apiRequest } from '../actions/api';

export const getBinsFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_BINS) {
    dispatch(
      apiRequest(
        'GET',
        `slocs/${action.payload.sloc}/bins`,
        action.payload,
        FETCH_BINS_SUCCESS,
        FETCH_BINS_ERROR,
        ['listAll', 'active'],
      ),
    );
    dispatch(showSpinner());
  }
};

export const processBinsCollection = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_BINS_SUCCESS) {
    dispatch(updateBins(action.payload));
    dispatch(hideSpinner());
  }
};

export const binsMdl = [getBinsFlow, processBinsCollection];
