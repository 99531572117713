import styled from 'styled-components';

export const Container = styled.div`
  padding: 1rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
`;

export const RinaDescriptionTag = styled.a`
  cursor: pointer;
  font-size: 12px;
  color: blue;
  font-weight: 600;
  text-decoration: underline;
`;
