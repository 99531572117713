import {
  UPDATE_BIN_SUCCESS,
  UPDATE_BIN_ERROR,
  FETCH_BIN_SUCCESS,
  FETCH_BIN_ERROR,
  GET_BIN,
  CREATE_BIN,
  UPDATE_BIN,
  updateObjectBin,
  showSpinner,
  hideSpinner,
  hideModal,
} from '../actions/bin';
import { getSLOCS } from '../actions/slocs';
import Toastify from '../../utils/Toastify/index';
import { apiRequest } from '../actions/api';

const URL = 'slocs/bin';

export const createBinFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === CREATE_BIN) {
    dispatch(
      apiRequest(
        'POST',
        `${URL}s`,
        action.payload,
        UPDATE_BIN_SUCCESS,
        UPDATE_BIN_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const updateBinFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === UPDATE_BIN) {
    dispatch(
      apiRequest(
        'PUT',
        URL,
        action.payload,
        UPDATE_BIN_SUCCESS,
        UPDATE_BIN_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const getBinFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_BIN) {
    const { _id } = action.payload;
    dispatch(
      apiRequest('GET-id', URL, { _id }, FETCH_BIN_SUCCESS, FETCH_BIN_ERROR),
    );
    dispatch(showSpinner());
  }
};

export const processBinCollection = ({ dispatch, getState }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_BIN_SUCCESS) {
    dispatch(updateObjectBin(action.payload));
    dispatch(hideSpinner());
  } else if (action.type === UPDATE_BIN_SUCCESS) {
    dispatch(updateObjectBin(action.payload));
    dispatch(hideModal());
    dispatch(hideSpinner());
    // To reload the page
    const { page, limit } = getState().slocs;
    dispatch(getSLOCS({ page, limit }));
    Toastify.addSuccess('Bin saved.');
  } else if (action.type === UPDATE_BIN_ERROR) {
    dispatch(hideSpinner());
    Toastify.addError(action.payload || 'Error to save the bin');
  }
};

export const binMdl = [
  createBinFlow,
  updateBinFlow,
  getBinFlow,
  processBinCollection,
];
