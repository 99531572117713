import { GET_HISTORY, UPDATE_HISTORY } from '../actions/history';

const initState = {
  docs: [],
  page: 1,
  limit: 10,
  totalDocs: 0,
};

export function historyReducer(history = initState, action) {
  switch (action.type) {
    case GET_HISTORY:
      return action.payload;
    case UPDATE_HISTORY:
      return action.payload;

    default:
      return history;
  }
}
