import React from 'react';
import formatDate from 'date-fns/format';
import {
  Box,
  Toolbar,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TextField,
  TableCell,
  IconButton,
  Tooltip,
  Button,
} from '@material-ui/core';
import {
  DeleteForeverRounded as DeleteIcon,
  GetAppRounded as DownloadIcon,
  AssignmentRounded as CopyIcon,
  Add as AddIcon,
} from '@material-ui/icons';
import { download } from '../../services/Download';
import { apiUrl } from '../../config/consts';
import { tableStyles } from '../../styles/tableStyles';
import { Thumbnail } from './styles';
import UploadModal from './UploadModal';

function EventDocumentsSection({
  files = [],
  onChangeFiles,
  newFiles = [],
  fileNotes = [],
  id,
}) {
  const [copied, setCopied] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (!copied) return;
    setTimeout(() => setCopied(false), 2000);
  }, [copied]);

  const classes = tableStyles();

  function handleDownload(index) {
    return function () {
      download(`vault/download/${files[index].file?.file?.filename}`);
    };
  }

  function handleCopy(index) {
    return function () {
      navigator.clipboard.writeText(
        `${apiUrl}vault/download/${files[index].file?.file?.filename}`
      );
      setCopied(true);
    };
  }

  function handleImagePreview(index) {
    return files[index].file?.file?.mimetype.includes('image/') ? (
      <Thumbnail>
        <img
          src={`${apiUrl}vault/download/${files[index].file?.file?.filename}`}
          alt={files[index].file?.file?.originalname}
        />
      </Thumbnail>
    ) : (
      '---'
    );
  }

  function handleNewImagePreview(index) {
    return newFiles[index].type.toLowerCase().includes('image') ? (
      <Thumbnail>
        <img
          src={URL.createObjectURL(newFiles[index])}
          alt={newFiles[index].name}
        />
      </Thumbnail>
    ) : (
      '---'
    );
  }

  function openModal() {
    setOpen(true);
  }
  function closeModal() {
    setOpen(false);
  }

  function handleAddFile({ file, notes }) {
    onChangeFiles({ files: [...newFiles, file], notes: [...fileNotes, notes] });
  }

  function handleRemoveFile(index) {
    return function () {
      const filesCopy = [...newFiles];
      const notesCopy = [...fileNotes];
      filesCopy.splice(index, 1);
      notesCopy.splice(index, 1);
      onChangeFiles({ files: filesCopy, notes: notesCopy });
    };
  }

  return (
    <>
      <UploadModal open={open} onClose={closeModal} onAddFile={handleAddFile} />
      <Toolbar className={classes.toolBar} style={{ paddingLeft: 12 }}>
        <span>
          <Typography
            className={classes.title}
            variant="h6"
            id="tableTitle"
            component="span"
          >
            Job Documents
          </Typography>
        </span>
      </Toolbar>
      <TableContainer className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>File Name</TableCell>
              <TableCell>Doc Link</TableCell>
              <TableCell>Picture Preview</TableCell>
              <TableCell>Date Created</TableCell>
              <TableCell>Notes</TableCell>
              <TableCell>Uploaded by</TableCell>
              <TableCell colSpan="3">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!files || !files.length ? (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <span>There are no registers to show</span>
                </TableCell>
              </TableRow>
            ) : (
              files.map(({ notes, file }, index) => (
                <TableRow key={file?._id}>
                  <TableCell>{file?.name}</TableCell>
                  <TableCell>
                    <TextField
                      disabled
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <Tooltip
                            title={copied ? 'Copied to clipboard' : 'Copy'}
                          >
                            <IconButton onClick={handleCopy(index)}>
                              <CopyIcon />
                            </IconButton>
                          </Tooltip>
                        ),
                      }}
                      value={`${apiUrl}vault/download/${file?.file?.filename}`}
                    />
                  </TableCell>
                  <TableCell>{handleImagePreview(index)}</TableCell>
                  <TableCell>
                    {file?.createdAt &&
                      formatDate(new Date(file?.createdAt), 'MM-dd-yyyy')}
                  </TableCell>
                  <TableCell>{notes}</TableCell>
                  <TableCell>{file?.createdBy.name}</TableCell>
                  <TableCell>
                    <Tooltip title="Download">
                      <IconButton
                        color="primary"
                        onClick={handleDownload(index)}
                      >
                        <DownloadIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton disabled color="secondary">
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!!(newFiles && newFiles.length) && (
        <>
          <Typography
            variant="h6"
            style={{ marginTop: '2rem', marginLeft: '1rem' }}
          >
            New Files
          </Typography>
          <TableContainer className={classes.table}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>File Name</TableCell>
                  <TableCell>Image Preview</TableCell>
                  <TableCell>Notes</TableCell>
                  <TableCell colSpan="3">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {newFiles.map((file, index) => (
                  <TableRow key={index}>
                    <TableCell>{file?.name}</TableCell>
                    <TableCell>{handleNewImagePreview(index)}</TableCell>
                    <TableCell>{fileNotes[index]}</TableCell>
                    <TableCell>
                      <Tooltip title="Delete">
                        <IconButton
                          onClick={handleRemoveFile(index)}
                          color="secondary"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      <Box display="flex" justifyContent="flex-end" padding="1rem">
        <Button
          onClick={openModal}
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          size="small"
        >
          Add file
        </Button>
      </Box>
    </>
  );
}

export default EventDocumentsSection;
