import {
  GET_PROJECT,
  UPDATE_PROJECT,
  UPDATE_OBJECT_PROJECT,
  CREATE_PROJECT,
  DELETE_PROJECT,
} from '../actions/project';

export function projectReducer(project = {}, action) {
  switch (action.type) {
    case GET_PROJECT:
      return action.payload;
    case UPDATE_PROJECT:
      return action.payload;
    case CREATE_PROJECT:
      return action.payload;
    case DELETE_PROJECT:
      return action.payload;
    case UPDATE_OBJECT_PROJECT:
      return action.payload;

    default:
      return project;
  }
}
