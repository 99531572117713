import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import {
  ArrowBack,
  CheckCircle as EnabledIcon,
  Cancel as DisabledIcon,
  GetApp as ExportIcon,
} from '@material-ui/icons';
import { add, format, isBefore } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import MaintenanceFilter from '../../components/MaintenanceFilter';
import ModalContainer from '../../components/ModalContainer';
import {
  getMaintenances,
  enableNotifications,
  disableNotifications,
} from '../../redux/actions/maintenances';
import EditModal from '../Modals/Maintenance';
import { Container } from './styles';
import getRINADescription from '../../utils/Equipment/getRINADescription';
import Spinner from '../../components/Spinner';
import { setMaintenanceFilter } from '../../redux/actions/maintenanceFilter';
import { download } from '../../services/Download';
import { CUSTOMER_KEY } from '../../config/consts';
import { getMovimentations } from '../../redux/actions/movimentations';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  title: {
    fontSize: 20,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  row: {
    padding: 0,
  },
  cell: {
    fontSize: 12,
    padding: '0px 10px 0px 15px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  buttons: {
    color: 'blue',
  },
  checkButton: {
    color: 'green',
  },
  closeButton: {
    color: 'red',
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 0,
  },
}));

function formatDate(date) {
  if (!date) return 'Undefined';
  return format(new Date(date), 'MM-dd-yyyy');
}

function calculateNextInspectionDate(date, days) {
  if (!date) return false;
  return add(new Date(date), { days });
}

function nextDateStyle(maintenance, effectedDate) {
  if (!effectedDate) return { color: undefined };
  const today = new Date();
  const expectedDate = calculateNextInspectionDate(
    effectedDate,
    maintenance.interval
  );
  const isItOverdue = isBefore(expectedDate, today);
  return { color: isItOverdue ? 'red' : undefined };
}

const Maintenances = ({
  dispatch,
  maintenances,
  movimentations,
  maintenancesLoading,
  maintenanceFilter,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const [maintenanceModal, setMaintenanceModal] = useState(false);
  const [maintenancesFormatted, setMaintenancesFormatted] = useState([]);
  const [modalContent, setModalContent] = useState(null);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(50);
  const [total, setTotal] = useState(0);
  const [selected, setSelected] = useState([]);
  const { state } = useLocation();

  useEffect(() => {
    dispatch(
      getMovimentations({
        page: 1,
        limit: 100,
        consolidateView: true,
      }),
    );
  }, []);

  useEffect(() => {
    if (movimentations.docs.length > 0) {
      console.log('Entering here');
      dispatch(
        setMaintenanceFilter({
          ...maintenanceFilter,
          equipment: movimentations.docs.map((eqp) => eqp?._id || eqp),
        })
      );
      dispatch(
        getMaintenances({
          page: page + 1,
          limit,
          filter: {
            equipment: movimentations.docs.map((eqp) => eqp?._id || eqp),
          },
        })
      );
    }
  }, [page, limit, movimentations.docs]);

  useEffect(() => {
    if (maintenances.docs) {
      setMaintenancesFormatted(maintenances.docs);
      setTotal(maintenances.totalDocs);
    }
  }, [maintenances]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleBack = () => {
    history.goBack();
  };

  const handleCloseModal = () => {
    setMaintenanceModal(false);
  };

  const handleOpenModal = (maintenance) => {
    return function () {
      setModalContent(maintenance);
      setMaintenanceModal(true);
    };
  };

  function getEffectedDate(eqp, mnt) {
    let effectedDate = eqp[mnt.effectedDate];
    if (mnt.executions.length) {
      effectedDate = mnt.executions[mnt.executions.length - 1]?.date;
    }

    return effectedDate;
  }

  function getMaintenance(mnt) {
    return mnt.maintenanceCategory.maintenances.find(
      ({ _id }) => _id === mnt.maintenance
    );
  }

  function handleToggleSelection(eqp, mnt) {
    return function () {
      setSelected((currentSelected) => {
        const index = currentSelected.findIndex(
          ([curEqp, curMnt]) => eqp === curEqp && mnt === curMnt
        );
        if (index > -1) {
          currentSelected.splice(index, 1);
          return [...currentSelected];
        }
        return [...currentSelected, [eqp, mnt]];
      });
    };
  }

  function isSelected(eqp, mnt) {
    return (
      selected.findIndex(
        ([curEqp, curMnt]) => eqp === curEqp && mnt === curMnt
      ) > -1
    );
  }

  function areAllSelected() {
    return maintenancesFormatted.every(
      ({ _id: eqp, maintenances: { _id: mnt } }) =>
        selected.findIndex(
          ([curEqp, curMnt]) => eqp === curEqp && mnt === curMnt
        ) > -1
    );
  }

  function handleToggleAllSelection() {
    if (areAllSelected()) return setSelected([]);
    const allItems = maintenancesFormatted.map(
      ({ _id: eqp, maintenances: { _id: mnt } }) => [eqp, mnt]
    );
    setSelected(allItems);
  }

  function handleDisableNotifications() {
    dispatch(disableNotifications(selected));
  }
  function handleEnableNotifications() {
    dispatch(enableNotifications(selected));
  }
  const handleExport = () => {
    download('export/maintenance', {
      ...maintenanceFilter,
      equipment: maintenanceFilter.equipment.toString(),
      customer: localStorage.getItem(CUSTOMER_KEY),
    });
  };
  return (
    <>
      <Container>
        <MaintenanceFilter />
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <Toolbar className={classes.toolBar}>
              <span
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <IconButton onClick={handleBack}>
                  <ArrowBack />
                </IconButton>
                <Typography
                  className={classes.title}
                  variant="h6"
                  id="tableTitle"
                  component="span"
                >
                  Maintenances
                </Typography>
              </span>
              <span>
                {selected.length > 0 && (
                  <>
                    <Button
                      size="small"
                      variant="contained"
                      color="secondary"
                      style={{ marginRight: 8 }}
                      onClick={handleDisableNotifications}
                    >
                      Disable Notifications
                    </Button>
                    <Button
                      style={{ marginRight: 8 }}
                      size="small"
                      variant="contained"
                      color="primary"
                      onClick={handleEnableNotifications}
                    >
                      Enable Notifications
                    </Button>
                  </>
                )}
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={handleExport}
                  startIcon={<ExportIcon />}
                >
                  Export List
                </Button>
              </span>
            </Toolbar>
            <ModalContainer
              open={maintenanceModal}
              handleClose={handleCloseModal}
            >
              <EditModal
                index={0}
                maintenances={[modalContent]}
                handleClose={handleCloseModal}
                readOnly
              />
            </ModalContainer>
            <TableContainer className={classes.table}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        checked={areAllSelected()}
                        onChange={handleToggleAllSelection}
                      />
                    </TableCell>
                    <TableCell>Notifications</TableCell>
                    <TableCell>Equipment</TableCell>
                    <TableCell>Equipment Unique ID</TableCell>
                    <TableCell>Type of Maintenance</TableCell>
                    <TableCell>Inspection Criteria</TableCell>
                    <TableCell>Interval</TableCell>
                    <TableCell>Notification Trigger</TableCell>
                    <TableCell>Notification Frequency</TableCell>
                    <TableCell>
                      Date of Last Inspection / Effected Date
                    </TableCell>
                    <TableCell>Date of Next Inspection</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {maintenancesLoading ? (
                    <TableCell colSpan={11}>
                      <Spinner parent="Table" />
                    </TableCell>
                  ) : (
                    maintenancesFormatted.map(
                      ({ maintenances, ...equipment }) => (
                        <TableRow>
                          <TableCell>
                            <Checkbox
                              checked={isSelected(
                                equipment._id,
                                maintenances._id
                              )}
                              onChange={handleToggleSelection(
                                equipment._id,
                                maintenances._id
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            {maintenances.notificationsEnabled ? (
                              <Tooltip title="Enabled">
                                <EnabledIcon color="primary" />
                              </Tooltip>
                            ) : (
                              <Tooltip title="Disabled">
                                <DisabledIcon color="secondary" />
                              </Tooltip>
                            )}
                          </TableCell>
                          <TableCell>
                            {getRINADescription(equipment?.customer_MM)}
                          </TableCell>
                          <TableCell>{equipment?.individual_item_id}</TableCell>
                          <TableCell>
                            {getMaintenance(maintenances)?.name}
                          </TableCell>
                          <TableCell>
                            {maintenances.inspectionCriteria?.name}
                          </TableCell>
                          <TableCell>{maintenances.interval} days</TableCell>
                          <TableCell>
                            {maintenances.notificationTrigger} days
                          </TableCell>
                          <TableCell>
                            {maintenances.notificationFrequency} days
                          </TableCell>
                          <TableCell>
                            {formatDate(
                              getEffectedDate(equipment, maintenances)
                            )}
                          </TableCell>
                          <TableCell
                            style={nextDateStyle(
                              maintenances,
                              getEffectedDate(equipment, maintenances)
                            )}
                          >
                            {formatDate(
                              calculateNextInspectionDate(
                                getEffectedDate(equipment, maintenances),
                                maintenances.interval
                              )
                            )}
                          </TableCell>
                        </TableRow>
                      )
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[50, 100, 200, 500, 1000]}
              component="div"
              count={total}
              rowsPerPage={limit}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  maintenancesLoading: state.ui.maintenancesLoading,
  maintenances: state.maintenances,
  movimentations: state.movimentations,
  maintenanceModal: state.ui.maintenanceModal,
  maintenanceFilter: state.maintenanceFilter,
});

export default connect(mapStateToProps)(Maintenances);
