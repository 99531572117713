export const GET_WORK_ORDERS = '[workOrders] GET';
export const FETCH_WORK_ORDERS_SUCCESS = '[workOrders] Fetch success';
export const FETCH_WORK_ORDERS_ERROR = '[workOrders] Fetch Error';
export const UPDATE_WORK_ORDERS = '[workOrders] Update';
// UI
export const SHOW_CONTRACTOR_DATA_MODAL = '[workOrders] SHOW CONTRACTOR MODAL';
export const HIDE_CONTRACTOR_DATA_MODAL = '[workOrders] HIDE CONTRACTOR MODAL';
export const SHOW_EQUIPMENT_DETAILS_MODAL = '[workOrders] SHOW EQUIPMENT DETAILS MODAL';
export const HIDE_EQUIPMENT_DETAILS_MODAL = '[workOrders] HIDE EQUIPMENT DETAILS MODAL';
export const SHOW_JOB_AND_CUSTOMER_DATA_MODAL = '[workOrders] SHOW JOB AND CUSTOMER MODAL';
export const HIDE_JOB_AND_CUSTOMER_DATA_MODAL = '[workOrders] HIDE JOB AND CUSTOMER MODAL';
export const SHOW_DOCUMENT_DATA_MODAL = '[workOrders] SHOW DOCUMENT MODAL';
export const HIDE_DOCUMENT_DATA_MODAL = '[workOrders] HIDE DOCUMENT MODAL';
export const SHOW_INSPECTION_RESULTS_MODAL = '[workOrders] SHOW INSPECTION RESULTS MODAL';
export const HIDE_INSPECTION_RESULTS_MODAL = '[workOrders] HIDE INSPECTION RESULTS MODAL';
export const SHOW_PHOTO_DATA_MODAL = '[workOrders] SHOW PHOTO MODAL';
export const HIDE_PHOTO_DATA_MODAL = '[workOrders] HIDE PHOTO MODAL';
export const SHOW_WORK_ORDERS_SPINNER = '[workOrders] SHOW WORK ORDERS SPINNER';
export const HIDE_WORK_ORDERS_SPINNER = '[workOrders] HIDE WORK ORDERS SPINNER';
export const SHOW_ADDITIONAL_NOTE_DATA_MODAL = '[workOrders] SHOW ADDITIONAL NOTE MODAL';
export const HIDE_ADDITIONAL_NOTE_DATA_MODAL = '[workOrders] HIDE ADDITIONAL NOTE MODAL';

export const getWorkOrders = (filter) => ({
  type: GET_WORK_ORDERS,
  payload: {
    ...filter,
  },
});

export const updateWorkOrders = (data) => ({
  type: UPDATE_WORK_ORDERS,
  payload: data,
});

export const showContractorDataModal = () => ({
  type: SHOW_CONTRACTOR_DATA_MODAL,
});

export const hideContractorDataModal = () => ({
  type: HIDE_CONTRACTOR_DATA_MODAL,
});

export const showEquipmentDetailsModal = () => ({
  type: SHOW_EQUIPMENT_DETAILS_MODAL,
});

export const hideEquipmentDetailsModal = () => ({
  type: HIDE_EQUIPMENT_DETAILS_MODAL,
});

export const showJobAndCustomerDataModal = () => ({
  type: SHOW_JOB_AND_CUSTOMER_DATA_MODAL,
});

export const hideJobAndCustomerDataModal = () => ({
  type: HIDE_JOB_AND_CUSTOMER_DATA_MODAL,
});
export const showDocumentDataModal = () => ({
  type: SHOW_DOCUMENT_DATA_MODAL,
});

export const hideDocumentDataModal = () => ({
  type: HIDE_DOCUMENT_DATA_MODAL,
});
export const showInspectionResultsModal = () => ({
  type: SHOW_INSPECTION_RESULTS_MODAL,
});

export const hideInspectionResultsModal = () => ({
  type: HIDE_INSPECTION_RESULTS_MODAL,
});
export const showPhotoDataModal = () => ({
  type: SHOW_PHOTO_DATA_MODAL,
});

export const hidePhotoDataModal = () => ({
  type: HIDE_PHOTO_DATA_MODAL,
});
export const showAdditionalNotesDataModal = () => ({
  type: SHOW_ADDITIONAL_NOTE_DATA_MODAL,
});

export const hideAdditionalNotesDataModal = () => ({
  type: HIDE_ADDITIONAL_NOTE_DATA_MODAL,
});

export const showSpinner = () => ({ type: SHOW_WORK_ORDERS_SPINNER });
export const hideSpinner = () => ({ type: HIDE_WORK_ORDERS_SPINNER });
