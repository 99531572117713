import React, { useEffect } from 'react';
import { IconButton, Grid, TextField, Button } from '@material-ui/core';
import { ArrowBack as BackIcon, Add as AddIcon } from '@material-ui/icons';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getInspections } from '../../redux/actions/inspections';
import { getWorkOrder } from '../../redux/actions/workOrder';
import {
  Container,
  Panel,
  Title,
  Header,
  Content,
  Card,
  Filters,
} from './styles';
import formatDate from '../../utils/Date/formatDate';

function Inspections() {
  const dispatch = useDispatch();

  const inspections = useSelector(
    (state) => state.inspections?.docs || state.inspections || []
  );

  const history = useHistory();

  const { id } = useParams();

  useEffect(() => {
    if (!id) return;
    dispatch(getWorkOrder(id));
    dispatch(getInspections({ filter: { workOrder: id, listAll: true } }));
  }, [id]);

  const rina_work_order_no = useSelector(
    (state) => state.workOrder?.rina_work_order_no
  );

  function handleGoBackClick() {
    history.goBack();
  }

  return (
    <Container>
      <Panel>
        <Header>
          <IconButton onClick={handleGoBackClick}>
            <BackIcon />
          </IconButton>
          <Title>{rina_work_order_no}'s Inspections</Title>

          <Link
            startIcon={<AddIcon />}
            color="primary"
            component={Button}
            to={`/workorder_inspection/${id}/new`}
          >
            Start new Inspection
          </Link>
        </Header>
        <Filters>
          <Grid item xs={12}>
            Filters:
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              label="Inspector"
              select
              fullWidth
              size="small"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              label="Status"
              select
              fullWidth
              size="small"
              variant="outlined"
            />
          </Grid>
        </Filters>
        <Content>
          {inspections.map((inspection) => (
            <Card
              component={Link}
              to={`/workorder_inspection/${id}/${inspection._id}`}
            >
              <span>
                <h6>Inspector:</h6> {inspection.inspector?.name}
              </span>
              <span>
                <h6>Status:</h6> {inspection.status}
              </span>
              <span>
                <h6>Report </h6>No: {inspection.report_no}
              </span>
              <span>
                <h6>Date:</h6> {formatDate(inspection.date)}
              </span>
            </Card>
          ))}
        </Content>
      </Panel>
    </Container>
  );
}

export default Inspections;
