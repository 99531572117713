import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  Grid,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import {
  ArrowBack,
  RadioButtonChecked,
  RadioButtonUnchecked,
  History,
  AddShoppingCart,
  Edit,
} from '@material-ui/icons';
import { getMovimentation } from '../../redux/actions/movimentation';
import { addToInventoryCart } from '../../redux/actions/inventory_cart';
import Spinner from '../../components/Spinner/index';
import { tableStyles } from '../../styles/tableStyles';
import InventoryRefineFilter from '../../components/InventoryRefineFilter/index';
import {
  Container,
  Form,
  Title,
  InputContent,
  ButtonsContainer,
} from './styles';
import OwnershipInformation from '../../components/OwnershipInformation';
import EquipmentInformation from '../../components/EquipmentInformation';
import getRINADescription from '../../utils/Equipment/getRINADescription';

const BreakoutData = ({
  dispatch,
  movimentation,
  movimentationSpinner,
  refineFilter,
  loggedUser,
}) => {
  const history = useHistory();
  const { id } = useParams();
  const classes = tableStyles();
  const [childrenFormatted, setChildrenFormatted] = useState([]);
  const [status, setStatus] = useState('');
  const [qty, setQty] = useState(0);
  const [footage, setFootage] = useState('');

  useEffect(() => {
    dispatch(getMovimentation(id));
  }, [id]);

  useEffect(() => {
    if (movimentation && movimentation.children) {
      //  Make to logic to render the filtered children
      const aux = Object.assign(movimentation.children);

      const newChildren = aux.filter((el) => {
        let condCount = 0; // Show the quantity of conditions
        let condAccepted = 0;
        if (refineFilter.range) {
          condCount += 1;
          if (refineFilter.range === el.range) {
            condAccepted += 1;
          }
        }

        if (refineFilter.RBW) {
          condCount += 1;
          if (refineFilter.RBW === el.RBW) {
            condAccepted += 1;
          }
        }

        if (refineFilter.equality_work_order_number) {
          condCount += 1;
          if (
            refineFilter.equality_work_order_number ===
            el.equality_work_order_number
          ) {
            condAccepted += 1;
          }
        }

        if (refineFilter.facility_reference_id) {
          condCount += 1;
          if (refineFilter.facility_reference_id === el.facility_reference_id) {
            condAccepted += 1;
          }
        }

        if (refineFilter.manufacturer) {
          condCount += 1;
          if (refineFilter.manufacturer === el.manufacturer) {
            condAccepted += 1;
          }
        }

        if (condCount === 0 || condCount === condAccepted) {
          // Hardcore Validation: If the conds are equals, the filter is correct
          return el;
        }
      });
      setChildrenFormatted(newChildren);
    }
  }, [refineFilter]);

  useEffect(() => {
    if (movimentation && movimentation.children) {
      setChildrenFormatted(movimentation.children);

      if (movimentation) {
        // Equipment fields
        setStatus(movimentation.status);
        setQty(movimentation.qty);
        setFootage(movimentation.footage);
      }
    }
  }, [movimentation]);

  const handleBack = () => {
    history.goBack();
  };

  function handleAddToCart(id) {
    function handler() {
      dispatch(addToInventoryCart(loggedUser._id, [id]));
    }
    return handler;
  }

  const styles = {
    headerContent: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      paddingBottom: 15,
      position: 'relative',
    },
    hierarchyMenu: {
      position: 'absolute',
      right: 30,
      top: 5,
    },
    addChildrenButton: {
      marginTop: 15,
    },
    equipmentButton: {
      position: 'absolute',
      top: 10,
      right: 30,
    },
    editButton: {
      marginRight: 10,
    },
  };

  return (
    <>
      <Container>
        <Form>
          <Grid container>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={styles.headerContent}
            >
              <IconButton onClick={handleBack}>
                <ArrowBack />
              </IconButton>
              <Title>Break Out Data</Title>
              {movimentation.material_layout !== 'BATCH' ? (
                <Button
                  variant="contained"
                  color="default"
                  style={styles.equipmentButton}
                  onClick={() =>
                    movimentation &&
                    history.push(`/hierarchy/${movimentation._id}`)
                  }
                >
                  Edit Breakout Data
                </Button>
              ) : null}
            </Grid>
            <Grid lg={12} md={12} sm={12} xs={12}>
              <InputContent>
                <TextField
                  disabled
                  size="small"
                  label="RINA Description"
                  type="text"
                  multiline
                  variant="outlined"
                  value={getRINADescription(movimentation.customer_MM)}
                  fullWidth
                />
              </InputContent>
            </Grid>
            <Grid lg={6} md={6} sm={12} xs={12}>
              <InputContent>
                <TextField
                  disabled
                  size="small"
                  label="(Name) Short Description"
                  type="text"
                  variant="outlined"
                  value={movimentation.customer_MM?.short_description || ''}
                  fullWidth
                />
              </InputContent>
            </Grid>
            <Grid lg={6} md={6} sm={12} xs={12}>
              <InputContent>
                <FormControl variant="outlined" size="small" fullWidth>
                  <InputLabel id="select-label">Status</InputLabel>
                  <Select
                    disabled
                    size="small"
                    labelId="select-label"
                    id="select-label-component"
                    value={status}
                    variant="outlined"
                    fullWidth
                  >
                    <MenuItem value="">All Status</MenuItem>
                    <MenuItem value="Available">Available</MenuItem>
                    <MenuItem value="Allocated">Allocated</MenuItem>
                    <MenuItem value="Consumed">Consumed</MenuItem>
                    <MenuItem value="Consignment">Consignment</MenuItem>
                    <MenuItem value="Divestment">Divestment</MenuItem>
                    <MenuItem value="Flagged for Divestment">
                      Flagged for Divestment
                    </MenuItem>
                    <MenuItem value="On Order">On Order</MenuItem>
                  </Select>
                </FormControl>
              </InputContent>
            </Grid>
            <Grid lg={12} md={6} sm={12} xs={12}>
              <InputContent>
                <TextField
                  disabled
                  size="small"
                  label="(Name) Long Description"
                  type="text"
                  multiline
                  variant="outlined"
                  value={movimentation.customer_MM?.long_description || ''}
                  fullWidth
                />
              </InputContent>
            </Grid>
            <Grid lg={3} md={6} sm={12} xs={12}>
              <InputContent>
                <TextField
                  disabled
                  size="small"
                  label="Qty"
                  type="number"
                  variant="outlined"
                  value={qty}
                  fullWidth
                />
              </InputContent>
            </Grid>
            <Grid lg={3} md={6} sm={12} xs={12}>
              <InputContent>
                <TextField
                  disabled
                  size="small"
                  label="Footage"
                  type="text"
                  variant="outlined"
                  value={footage}
                  fullWidth
                />
              </InputContent>
            </Grid>
          </Grid>
        </Form>
      </Container>
      <Container
        style={{ padding: '0.5rem 2rem 0rem 2rem', marginBottom: -10 }}
      >
        <InventoryRefineFilter />
      </Container>
      <Container
        style={{ padding: '0.5rem 2rem 0rem 2rem', marginBottom: -10 }}
      >
        <EquipmentInformation movimentation={movimentation} />
      </Container>
      <Container
        style={{ padding: '0.5rem 2rem 0rem 2rem', marginBottom: -10 }}
      >
        <OwnershipInformation movimentation={movimentation} />
      </Container>
      <Container>
        <Form>
          <Grid
            container
            className="headerContainer"
            style={{ opacity: movimentationSpinner ? 0.3 : 1 }}
          >
            <Grid item lg={12} md={12} sm={12} xs={12} align="left">
              <Title>Mother Item</Title>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} align="center">
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell>Status</TableCell>
                      <TableCell colSpan={3}>Equipment Name</TableCell>
                      <TableCell>Customer MM No.</TableCell>
                      <TableCell>Total Qty. (EA)</TableCell>
                      <TableCell>Footage</TableCell>
                      <TableCell>COPAS Class</TableCell>
                      <TableCell>Heat / Lot</TableCell>
                      <TableCell>Supplier Batch No.</TableCell>
                      <TableCell>Supplier MM No.</TableCell>
                      <TableCell>RBW</TableCell>
                      <TableCell>RINA Work Order No.</TableCell>
                      <TableCell>Facility Reference ID</TableCell>
                      <TableCell>Manufacturer</TableCell>
                      <TableCell>Material Layout</TableCell>
                      <TableCell>Mother-Child Class</TableCell>
                      <TableCell>Range</TableCell>
                      <TableCell>Bin Location</TableCell>
                      <TableCell>Allocated RINA WO No.</TableCell>
                      <TableCell>Allocated</TableCell>
                      <TableCell>Allocated Batch No.</TableCell>
                      <TableCell>Allocated Project</TableCell>
                      <TableCell>Allocated Charge Code</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {movimentationSpinner && (
                      <TableRow className={classes.row}>
                        <TableCell colSpan="18" className={classes.cell}>
                          <Spinner parent="Table" />
                        </TableCell>
                      </TableRow>
                    )}
                    {movimentation && movimentation.parent && (
                      <TableRow className={classes.row}>
                        <TableCell className={classes.cell}>
                          <ButtonsContainer>
                            <Tooltip
                              title="History"
                              arrow
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <IconButton
                                size="small"
                                style={{
                                  ...styles.editButton,
                                  backgroundColor: '#878787',
                                  color: 'white',
                                }}
                              >
                                <History fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              title="Add to Cart"
                              arrow
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <IconButton
                                size="small"
                                onClick={handleAddToCart(
                                  movimentation.parent._id
                                )}
                                style={{
                                  ...styles.editButton,
                                  backgroundColor: 'cornflowerblue',
                                  color: 'white',
                                }}
                              >
                                <AddShoppingCart fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              title="Edit Data"
                              arrow
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <IconButton
                                size="small"
                                style={{
                                  ...styles.editButton,
                                  backgroundColor: 'mediumPurple',
                                  color: 'white',
                                }}
                                onClick={() =>
                                  history.push(
                                    `/equipment/${movimentation.parent._id}`
                                  )
                                }
                              >
                                <Edit fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </ButtonsContainer>
                        </TableCell>
                        <TableCell
                          className={classes.cell}
                          style={{
                            color:
                              movimentation.status === 'Available'
                                ? 'green'
                                : 'black',
                          }}
                          component="th"
                          scope="row"
                        >
                          <b>{movimentation.status}</b>
                        </TableCell>
                        <TableCell colSpan={3} className={classes.cell}>
                          <b>
                            {movimentation.parent &&
                              movimentation.parent?.customer_MM
                                ?.short_description}
                          </b>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {movimentation.parent &&
                            movimentation.parent.customer_MM &&
                            movimentation.parent.customer_MM.mm_number}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {movimentation.parent && movimentation.parent.qty}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {movimentation.parent && movimentation.parent.footage}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {movimentation.parent &&
                            movimentation.parent.classification}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {movimentation.parent && movimentation.parent.heat}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {movimentation.parent &&
                            movimentation.parent.batch_no}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {movimentation.parent &&
                            movimentation.parent.customer_MM &&
                            movimentation.parent.customer_MM.mm_number}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {movimentation.parent && movimentation.parent.RBW}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {movimentation.parent &&
                            movimentation.parent.rina_work_order_no}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {movimentation.parent &&
                            movimentation.parent.facility_reference_id}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {movimentation.parent &&
                            movimentation.parent.manufacturer}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {movimentation.parent &&
                            movimentation.parent.material_layout}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {'MOTHER-CHILD CLASS'}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {movimentation.parent &&
                            movimentation.parent.customer_MM &&
                            movimentation.parent.customer_MM.range}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {'BIN LOCATION'}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {movimentation.parent &&
                            movimentation.parent.rina_work_order_no}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {movimentation.parent &&
                          movimentation.parent.original_charge &&
                          movimentation.parent.original_charge.project ? (
                            <RadioButtonChecked />
                          ) : (
                            <RadioButtonUnchecked />
                          )}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {movimentation.parent &&
                            movimentation.parent.allocated_batch_number}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {movimentation.parent &&
                            movimentation.parent.original_charge &&
                            movimentation.parent.original_charge.well &&
                            movimentation.parent.original_charge.well.project &&
                            movimentation.parent.original_charge.well.project
                              .name}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {movimentation.parent &&
                            movimentation.parent.original_charge &&
                            movimentation.parent.original_charge
                              .original_charge_code}
                        </TableCell>
                      </TableRow>
                    )}
                    {!movimentationSpinner && !movimentation.parent && (
                      <TableRow className={classes.row}>
                        <TableCell colSpan="24" style={{ fontSize: '1rem' }}>
                          <div style={{ width: '100%', textAlign: 'center' }}>
                            This item doest not have parent
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Form>
      </Container>
      {childrenFormatted.length ? (
        <Container style={{ marginBottom: 80 }}>
          <Form>
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12} align="left">
                <Title>Children Data</Title>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell>Status</TableCell>
                        <TableCell colSpan={3}>Equipment Name</TableCell>
                        <TableCell>Customer MM No.</TableCell>
                        <TableCell>Total Qty. (EA)</TableCell>
                        <TableCell>Footage</TableCell>
                        <TableCell>COPAS Class</TableCell>
                        <TableCell>Heat / Lot</TableCell>
                        <TableCell>Supplier Batch No.</TableCell>
                        <TableCell>Supplier MM No.</TableCell>
                        <TableCell>RBW</TableCell>
                        <TableCell>RINA Work Order No.</TableCell>
                        <TableCell>Facility Reference ID</TableCell>
                        <TableCell>Manufacturer</TableCell>
                        <TableCell>Material Layout</TableCell>
                        <TableCell>Mother-Child Class</TableCell>
                        <TableCell>RAMP Tag No.</TableCell>
                        <TableCell>Bin Location</TableCell>
                        <TableCell>Allocated RINA WO No.</TableCell>
                        <TableCell>Allocated</TableCell>
                        <TableCell>Allocated Batch No.</TableCell>
                        <TableCell>Allocated Project</TableCell>
                        <TableCell>Allocated Charge Code</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {movimentationSpinner && (
                        <TableRow className={classes.row}>
                          <TableCell colSpan="18" className={classes.cell}>
                            <Spinner parent="Table" />
                          </TableCell>
                        </TableRow>
                      )}
                      {!movimentationSpinner &&
                        childrenFormatted.map((rr) => (
                          <TableRow className={classes.row}>
                            <TableCell className={classes.cell}>
                              <ButtonsContainer>
                                <Tooltip
                                  title="History"
                                  arrow
                                  classes={{ tooltip: classes.tooltip }}
                                >
                                  <IconButton
                                    size="small"
                                    style={{
                                      ...styles.editButton,
                                      backgroundColor: '#878787',
                                      color: 'white',
                                    }}
                                  >
                                    <History fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip
                                  title="Add to Cart"
                                  arrow
                                  classes={{ tooltip: classes.tooltip }}
                                >
                                  <IconButton
                                    size="small"
                                    onClick={handleAddToCart(rr._id)}
                                    style={{
                                      ...styles.editButton,
                                      backgroundColor: 'cornflowerblue',
                                      color: 'white',
                                    }}
                                  >
                                    <AddShoppingCart fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip
                                  title="Edit Data"
                                  arrow
                                  classes={{ tooltip: classes.tooltip }}
                                >
                                  <IconButton
                                    size="small"
                                    style={{
                                      ...styles.editButton,
                                      backgroundColor: 'mediumPurple',
                                      color: 'white',
                                    }}
                                    onClick={() =>
                                      history.push(`/equipment/${rr._id}`)
                                    }
                                  >
                                    <Edit fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </ButtonsContainer>
                            </TableCell>
                            <TableCell
                              className={classes.cell}
                              style={{
                                color:
                                  rr.status === 'Available' ? 'green' : 'black',
                              }}
                              component="th"
                              scope="row"
                            >
                              <b>{rr.status}</b>
                            </TableCell>
                            <TableCell colSpan={3} className={classes.cell}>
                              <b>{rr && rr?.customer_MM?.short_description}</b>
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {rr && rr.customer_MM && rr.customer_MM.mm_number}
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {rr && rr.qty}
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {rr && rr.footage}
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {rr && rr.classification}
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {rr && rr.heat}
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {rr && rr.batch_no}
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {rr && rr.customer_MM && rr.customer_MM.mm_number}
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {rr && rr.RBW}
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {rr && rr.rina_work_order_no}
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {rr && rr.facility_reference_id}
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {rr && rr.manufacturer}
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {rr && rr.material_layout}
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {'MOTHER-CHILD CLASS'}
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {rr && rr.customer_MM && rr.customer_MM.range}
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {'BIN LOCATION'}
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {rr && rr.rina_work_order_no}
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {rr.original_charge &&
                              rr.original_charge.well &&
                              rr.original_charge.well.project ? (
                                <RadioButtonChecked />
                              ) : (
                                <RadioButtonUnchecked />
                              )}
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {rr && rr.allocated_batch_number}
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {rr.allocated_charge_code &&
                                rr.allocated_charge_code.well &&
                                rr.allocated_charge_code.well.project &&
                                rr.allocated_charge_code.well.project.name}
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {rr.allocated_charge_code &&
                                rr.allocated_charge_code.original_charge_code}
                            </TableCell>
                          </TableRow>
                        ))}
                      {!movimentationSpinner && childrenFormatted.length === 0 && (
                        <TableRow className={classes.row}>
                          <TableCell colSpan="24" style={{ fontSize: '1rem' }}>
                            <div style={{ width: '100%', textAlign: 'center' }}>
                              This item does not have children
                            </div>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Form>
        </Container>
      ) : null}
    </>
  );
};
export default connect((state) => ({
  movimentation: state.movimentation,
  movimentationSpinner: state.ui.movimentationLoading,
  refineFilter: state.refineFilter,
  loggedUser: state.loggedUser,
}))(BreakoutData);
