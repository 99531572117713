import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  TextField,
  Button,
  Tab,
  Tabs,
  Typography,
  Grid,
  MenuItem,
  FormControl,
  FormHelperText,
  Select,
} from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import Alert from '../../../components/Alert/index';
import Spinner from '../../../components/Spinner/index';
import {
  Container,
  InputContent,
  ModalTitle,
  ButtonContainer,
  TabSheet,
} from './styles';
import SwipeableViews from 'react-swipeable-views';
import {
  getVendor,
  createVendor,
  updateVendor,
} from '../../../redux/actions/vendor';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import { Add, Close, EditRounded, Check } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import TabPanel from '../../../components/TabPanel';
import { AddButton } from './styles';
import USAState from '../../../structs/usa-state';

const styles = {
  formButton: {
    margin: 2,
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  title: {
    fontSize: 20,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  row: {
    padding: 0,
  },
  cell: {
    fontSize: 12,
    padding: '0px 10px 0px 15px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  buttons: {
    color: 'blue',
  },
  checkButton: {
    color: 'green',
  },
  closeButton: {
    color: 'red',
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const VendorModal = ({
  id,
  dispatch,
  vendor,
  vendorModal,
  handleClose,
  vendorSpinner,
}) => {
  const [active, setActive] = useState(true);
  const [value, setValue] = React.useState(0);
  const [nameError, setNameError] = useState(false);
  const [codeError, setCodeError] = useState(false);
  const [slocCodeError, setSlocCodeError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [numberError, setNumberError] = useState(false);
  const [postalCodeError, setPostalCodeError] = useState(false);
  const [typeError, setTypeError] = useState(false);
  const [contactNameError, setContactNameError] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [editing, setEditing] = React.useState(null);
  const [contactsError, setContactsError] = useState(false);
  const classes = useStyles();
  const [data, setData] = useState({
    vendor_name: '',
    vendor_code: '',
    vendor_sloc_code: '',
    vendor_address: {
      country: 'USA',
      state: '',
      city: '',
      address: '',
      number: '',
      postal_code: '',
    },
    contact_name: '',
    phone_number: '',
    vendor_type: '',
    vendor_contacts: [],
  });
  const {
    vendor_name,
    vendor_code,
    vendor_sloc_code,
    vendor_address,
    vendor_contacts,
    vendor_type,
    contact_name,
    phone_number,
  } = data;
  useEffect(() => {
    if (id !== 'new') {
      dispatch(getVendor(id));
    }
  }, [id]);

  useEffect(() => {
    if (vendor && id !== 'new') {
      setData(vendor);
    }
  }, [vendor]);

  useEffect(() => {
    if (!vendorModal) {
      handleClose();
    }
  }, [vendorModal]);

  function handleAdd() {
    setEditing(vendor_contacts.length);
    setData((currentData) => ({
      ...currentData,
      vendor_contacts: [
        ...currentData.vendor_contacts,
        {
          contact_position: '',
          contact_name: '',
          contact_email: '',
          contact_phone: '',
        },
      ],
    }));
  }

  function handleEditContact(e) {
    const { value, name } = e.target;
    setData((currentData) => {
      const currentContacts = [...currentData.vendor_contacts];
      currentContacts[editing] = { ...currentContacts[editing], [name]: value };
      return { ...currentData, vendor_contacts: currentContacts };
    });
  }

  function handleStartEditing(index) {
    function handler() {
      setEditing(index);
    }
    return handler;
  }

  function handleConfirm() {
    setEditing(null);
  }

  function handleDelete(index) {
    function handler() {
      Alert(
        'Are you sure?',
        "You won't be able to revert this!",
        false,
        '',
        'Yes, delete it.'
      ).then(({ confirm }) => {
        if (!confirm) return;
        setData((currentData) => {
          const currentComments = [...currentData.vendor_contacts];
          currentComments.splice(index, 1);
          return { ...currentData, vendor_contacts: currentComments };
        });
        setEditing(null);
      });
    }
    return handler;
  }

  const handleSave = async () => {
    let err = false;
    setNameError(!vendor_name);
    setCodeError(!vendor_code);
    setSlocCodeError(!vendor_sloc_code);
    setCountryError(!vendor_address.country);
    setStateError(!vendor_address.state);
    setCityError(!vendor_address.city);
    setAddressError(!vendor_address.address);
    setNumberError(!vendor_address.number);
    setPostalCodeError(!vendor_address.postal_code);
    setContactsError(vendor_contacts.length == 0);
    setTypeError(!vendor_type);
    setPhoneNumberError(!phone_number);
    setContactNameError(!contact_name);
    err =
      !vendor_name ||
      !vendor_type ||
      !contact_name ||
      !phone_number ||
      !vendor_code ||
      !vendor_sloc_code ||
      !vendor_address.country ||
      !vendor_address.state ||
      !vendor_address.city ||
      !vendor_address.address ||
      !vendor_address.number ||
      !vendor_address.postal_code ||
      vendor_contacts.length == 0;

    if (!err) {
      const response = await Alert();
      if (response && response.confirm) {
        if (id === 'new') {
          dispatch(createVendor(data));
        } else {
          dispatch(updateVendor(data));
        }
      }
    }
  };

  const handleEdit = (e) => {
    const { name, value } = e.target;
    setData((currentData) => ({ ...currentData, [name]: value }));
  };

  const handleEditAddress = (e) => {
    const { name, value } = e.target;

    if (name === 'country') {
    }

    setData((currentData) => ({
      ...currentData,
      vendor_address: {
        ...currentData.vendor_address,
        [name]: value,
        ...(name === 'country' ? { state: '' } : {}),
      },
    }));
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <>
      {vendorSpinner && <Spinner overlay />}

      <Container style={{ opacity: vendorSpinner ? 0.5 : 1 }}>
        <ModalTitle>{id === 'new' ? 'New' : 'Edit'} Vendor</ModalTitle>
        <TabSheet>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="Vendor Data" {...a11yProps(0)} />
            <Tab label="Vendor Contacts" {...a11yProps(1)} />
          </Tabs>

          <SwipeableViews
            axis="x"
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir="ltr">
              <Typography variant="h5">General Data</Typography>
              <Grid container style={{ opacity: vendorSpinner ? 0.3 : 1 }}>
                <Grid item xs={12}>
                  <InputContent>
                    <TextField
                      label="Vendor Name"
                      required
                      type="text"
                      error={nameError}
                      helperText={nameError && 'Vendor name is required'}
                      variant="outlined"
                      value={vendor_name}
                      name="vendor_name"
                      onChange={handleEdit}
                      fullWidth
                    />
                  </InputContent>
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={6}>
                  <InputContent>
                    <TextField
                      label="Vendor Code"
                      required
                      type="text"
                      error={codeError}
                      helperText={codeError && 'Vendor code is required'}
                      variant="outlined"
                      value={vendor_code}
                      name="vendor_code"
                      onChange={handleEdit}
                      fullWidth
                    />
                  </InputContent>
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={6}>
                  <InputContent>
                    <TextField
                      label="Vendor Type"
                      required
                      error={typeError}
                      helperText={typeError && 'Vendor type is required'}
                      variant="outlined"
                      value={vendor_type}
                      name="vendor_type"
                      onChange={handleEdit}
                      fullWidth
                      select
                    >
                      <MenuItem value="Storage">Storage</MenuItem>
                      <MenuItem value="Services">Services</MenuItem>
                      <MenuItem value="Materials">Materials</MenuItem>
                      <MenuItem value="Transportation">Transportation</MenuItem>
                    </TextField>
                  </InputContent>
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={6}>
                  <InputContent>
                    <TextField
                      label="SLOC Code"
                      required
                      type="text"
                      error={slocCodeError}
                      helperText={slocCodeError && 'SLOC code is required'}
                      variant="outlined"
                      value={vendor_sloc_code}
                      name="vendor_sloc_code"
                      onChange={handleEdit}
                      fullWidth
                    />
                  </InputContent>
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={6}>
                  <InputContent>
                    <TextField
                      label="Contact Name"
                      required
                      type="text"
                      error={contactNameError}
                      helperText={
                        contactNameError && 'Contact name is required'
                      }
                      variant="outlined"
                      value={contact_name}
                      name="contact_name"
                      onChange={handleEdit}
                      fullWidth
                    />
                  </InputContent>
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={6}>
                  <InputContent>
                    <FormControl error={phoneNumberError}>
                      <PhoneInput
                        country="us"
                        specialLabel="Phone Number *"
                        value={phone_number}
                        onChange={(_rawValue, _data, _event, value) =>
                          handleEdit({
                            target: { name: 'phone_number', value },
                          })
                        }
                      />
                      {phoneNumberError && (
                        <FormHelperText>
                          Phone number is required
                        </FormHelperText>
                      )}
                    </FormControl>
                  </InputContent>
                </Grid>
              </Grid>
              <Typography variant="h5">Vendor Address</Typography>
              <Grid container style={{ opacity: vendorSpinner ? 0.3 : 1 }}>

                <Grid item xs={12} md={6} lg={6} xl={6}>
                  <InputContent>
                    <TextField
                      label="Number"
                      required
                      type="text"
                      error={numberError}
                      helperText={numberError && 'Number is required'}
                      variant="outlined"
                      value={vendor_address?.number}
                      name="number"
                      onChange={handleEditAddress}
                      fullWidth
                    />
                  </InputContent>
                </Grid>

                <Grid item xs={12} md={6} lg={6} xl={6}>
                  <InputContent>
                    <TextField
                      label="Street Name"
                      required
                      type="text"
                      error={addressError}
                      helperText={addressError && 'Address is required'}
                      variant="outlined"
                      value={vendor_address?.address}
                      name="address"
                      onChange={handleEditAddress}
                      fullWidth
                    />
                  </InputContent>
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={6}>
                  <InputContent>
                    <TextField
                      label="City"
                      required
                      type="text"
                      error={cityError}
                      helperText={cityError && 'City is required'}
                      variant="outlined"
                      value={vendor_address?.city}
                      name="city"
                      onChange={handleEditAddress}
                      fullWidth
                    />
                  </InputContent>
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={6}>
                  <InputContent>
                    <TextField
                      label="Postal Code"
                      required
                      type="text"
                      error={postalCodeError}
                      helperText={postalCodeError && 'Postal code is required'}
                      variant="outlined"
                      value={vendor_address?.postal_code}
                      name="postal_code"
                      onChange={handleEditAddress}
                      fullWidth
                    />
                  </InputContent>
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={6}>
                  <InputContent>
                    <TextField
                      label="Country"
                      required
                      type="text"
                      error={countryError}
                      helperText={countryError && 'Country is required'}
                      variant="outlined"
                      value={vendor_address?.country}
                      name="country"
                      onChange={handleEditAddress}
                      fullWidth
                    />
                  </InputContent>
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={6}>
                  <InputContent>
                    {String(
                      vendor_address?.country ?? ''
                    ).toLocaleUpperCase() === 'USA' ? (


                    <TextField
                      label="State"
                      required
                      error={stateError}
                      helperText={stateError && 'State is required'}
                      variant="outlined"
                      value={vendor_address?.state}
                      name="state"
                      onChange={handleEditAddress}
                      fullWidth
                      select
                    >

                          {USAState.map((state) => (
                            <MenuItem value={state.value}>
                              {state.name}
                            </MenuItem>
                          ))}

                      </TextField>
                    ) : (
                      <TextField
                        label="State"
                        required
                        type="text"
                        error={stateError}
                        helperText={stateError && 'State is required'}
                        variant="outlined"
                        value={vendor_address?.state}
                        name="state"
                        onChange={handleEditAddress}
                        fullWidth
                      />
                    )}
                  </InputContent>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1} dir="ltr">
              <Typography variant="h5">Vendor Contacts</Typography>
              <AddButton
                variant="contained"
                color="primary"
                size="small"
                onClick={handleAdd}
              >
                <Add /> Add Contact
              </AddButton>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Contact Position</TableCell>
                      <TableCell>Contact Name</TableCell>
                      <TableCell>Contact E-mail</TableCell>
                      <TableCell>Contact Phone</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {vendor_contacts?.map((contact, index) => {
                      return index === editing ? (
                        <TableRow className={classes.row}>
                          <TableCell className={classes.cell}>
                            <InputContent>
                              <TextField
                                label="Position"
                                required
                                type="text"
                                variant="outlined"
                                value={contact.contact_position}
                                name="contact_position"
                                onChange={handleEditContact}
                                fullWidth
                              />
                            </InputContent>
                          </TableCell>
                          <TableCell className={classes.cell}>
                            <InputContent>
                              <TextField
                                label="Name"
                                required
                                type="text"
                                variant="outlined"
                                value={contact.contact_name}
                                name="contact_name"
                                onChange={handleEditContact}
                                fullWidth
                              />
                            </InputContent>
                          </TableCell>
                          <TableCell className={classes.cell}>
                            <InputContent>
                              <TextField
                                label="E-mail"
                                required
                                type="text"
                                variant="outlined"
                                value={contact.contact_email}
                                name="contact_email"
                                onChange={handleEditContact}
                                fullWidth
                              />
                            </InputContent>
                          </TableCell>
                          <TableCell className={classes.cell}>
                            <InputContent>
                              <PhoneInput
                                country="us"
                                specialLabel="Phone"
                                value={contact.contact_phone}
                                onChange={(_rawValue, _data, _event, value) =>
                                  handleEditContact({
                                    target: { name: 'contact_phone', value },
                                  })
                                }
                              />
                            </InputContent>
                          </TableCell>
                          <TableCell className={classes.cell}>
                            <IconButton onClick={handleConfirm} color="primary">
                              <Check />
                            </IconButton>
                            <IconButton
                              onClick={handleDelete(index)}
                              color="secondary"
                            >
                              <Close />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ) : (
                        <TableRow className={classes.row}>
                          <TableCell className={classes.cell}>
                            {contact.contact_position}
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {contact.contact_name}
                          </TableCell>
                          <TableCell className={classes.cell}>
                            <b>{contact.contact_email}</b>
                          </TableCell>
                          <TableCell className={classes.cell}>
                            <b>{contact.contact_phone}</b>
                          </TableCell>
                          <TableCell className={classes.cell}>
                            <IconButton
                              onClick={handleStartEditing(index)}
                              color="primary"
                            >
                              <EditRounded />
                            </IconButton>
                            <IconButton
                              onClick={handleDelete(index)}
                              color="secondary"
                            >
                              <Close />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>
          </SwipeableViews>
        </TabSheet>
        <ButtonContainer>
          <Button
            color="secondary"
            onClick={handleClose}
            variant="contained"
            style={styles.formButton}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={handleSave}
            variant="contained"
            style={styles.formButton}
          >
            Save
          </Button>
        </ButtonContainer>
      </Container>
    </>
  );
};

export default connect((state) => ({
  vendor: state.vendor,
  vendorModal: state.ui.vendorModal,
  vendorSpinner: state.ui.vendorLoading,
}))(VendorModal);
