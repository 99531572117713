import {
  HIDE_ALLOCATE_SPINNER,
  SHOW_ALLOCATE_SPINNER,
} from '../actions/allocate';
import {
  HIDE_BUSINESS_UNIT_MODAL,
  HIDE_BUSINESS_UNIT_SPINNER,
  SHOW_BUSINESS_UNIT_MODAL,
  SHOW_BUSINESS_UNIT_SPINNER,
} from '../actions/businessUnit';
import {
  HIDE_BUSINESS_UNITS_SPINNER,
  SHOW_BUSINESS_UNITS_SPINNER,
} from '../actions/businessUnits';
import {
  HIDE_ACTIVITY_MODAL,
  HIDE_ACTIVITY_SPINNER,
  SHOW_ACTIVITY_MODAL,
  SHOW_ACTIVITY_SPINNER,
} from '../actions/activity';
import {
  HIDE_ACTIVITIES_SPINNER,
  SHOW_ACTIVITIES_SPINNER,
} from '../actions/activities';
import {
  HIDE_CHECK_OUT_MODAL,
  HIDE_CHECK_OUT_SPINNER,
  SHOW_CHECK_OUT_MODAL,
  SHOW_CHECK_OUT_SPINNER,
} from '../actions/checkOut';
import {
  HIDE_COMPANY_CODE_MODAL,
  HIDE_COMPANY_CODE_SPINNER,
  SHOW_COMPANY_CODE_MODAL,
  SHOW_COMPANY_CODE_SPINNER,
} from '../actions/companyCode';
import {
  HIDE_COMPANY_CODES_SPINNER,
  SHOW_COMPANY_CODES_SPINNER,
} from '../actions/companyCodes';
import {
  HIDE_CUSTOMER_MODAL,
  HIDE_CUSTOMER_SPINNER,
  SHOW_CUSTOMER_MODAL,
  SHOW_CUSTOMER_SPINNER,
} from '../actions/customer';
import {
  HIDE_CUSTOMER_MM_MODAL,
  HIDE_CUSTOMER_MM_SPINNER,
  SHOW_CUSTOMER_MM_MODAL,
  SHOW_CUSTOMER_MM_SPINNER,
} from '../actions/customerMM';
import {
  HIDE_CUSTOMER_MMS_SPINNER,
  SHOW_CUSTOMER_MMS_SPINNER,
} from '../actions/customerMMs';
import {
  HIDE_CUSTOMERS_SPINNER,
  SHOW_CUSTOMERS_SPINNER,
} from '../actions/customers';
import { HIDE_HISTORY_SPINNER, SHOW_HISTORY_SPINNER } from '../actions/history';
import {
  HIDE_INVENTORY_CART_SPINNER,
  SHOW_INVENTORY_CART_SPINNER,
} from '../actions/inventory_cart';
import {
  HIDE_DOCUMENTATION_MODAL,
  HIDE_JOB_VPAS_MODAL,
  SHOW_DOCUMENTATION_MODAL,
  SHOW_JOB_VPAS_MODAL,
  SHOW_JOBS_SPINNER,
  HIDE_JOBS_SPINNER,
} from '../actions/jobs';
import { HIDE_LOGIN_SPINNER, SHOW_LOGIN_SPINNER } from '../actions/loggedUser';
import {
  HIDE_MAINTENANCE_CATEGORY_MODAL,
  SHOW_MAINTENANCE_CATEGORY_MODAL,
} from '../actions/maintenanceCategory';
import {
  HIDE_MAINTENANCES_SPINNER,
  SHOW_MAINTENANCES_SPINNER,
} from '../actions/maintenances';
import {
  SHOW_MAINTENANCE_MODAL,
  HIDE_MAINTENANCE_MODAL,
} from '../actions/maintenance';
import {
  HIDE_MOVIMENTATION_MODAL,
  HIDE_MOVIMENTATION_SPINNER,
  SHOW_MOVIMENTATION_MODAL,
  SHOW_MOVIMENTATION_SPINNER,
} from '../actions/movimentation';
import {
  HIDE_MOVIMENTATIONS_SEARCH_SPINNER,
  HIDE_MOVIMENTATIONS_SPINNER,
  SHOW_MOVIMENTATIONS_SEARCH_SPINNER,
  SHOW_MOVIMENTATIONS_SPINNER,
} from '../actions/movimentations';
import {
  HIDE_ORIGINAL_CHARGE_MODAL,
  HIDE_ORIGINAL_CHARGE_SPINNER,
  SHOW_ORIGINAL_CHARGE_MODAL,
  SHOW_ORIGINAL_CHARGE_SPINNER,
} from '../actions/originalCharge';
import {
  HIDE_ORIGINAL_CHARGES_SPINNER,
  SHOW_ORIGINAL_CHARGES_SPINNER,
} from '../actions/originalCharges';
import {
  HIDE_OWNER_MODAL,
  HIDE_OWNER_SPINNER,
  SHOW_OWNER_MODAL,
  SHOW_OWNER_SPINNER,
} from '../actions/owner';
import { HIDE_OWNERS_SPINNER, SHOW_OWNERS_SPINNER } from '../actions/owners';
import {
  HIDE_NOTIFICATIONS_SPINNER,
  SHOW_NOTIFICATIONS_SPINNER,
} from '../actions/notifications';
import {
  HIDE_PROJECT_MODAL,
  HIDE_PROJECT_SPINNER,
  SHOW_PROJECT_MODAL,
  SHOW_PROJECT_SPINNER,
} from '../actions/project';
import {
  HIDE_PROJECTS_SPINNER,
  SHOW_PROJECTS_SPINNER,
} from '../actions/projects';
import {
  HIDE_RECLASS_DAMAGE_MODAL,
  HIDE_RECLASS_DAMAGE_SPINNER,
  SHOW_RECLASS_DAMAGE_MODAL,
  SHOW_RECLASS_DAMAGE_SPINNER,
} from '../actions/reclassDamage';
import {
  HIDE_RECLASS_DAMAGES_SPINNER,
  SHOW_RECLASS_DAMAGES_SPINNER,
} from '../actions/reclassDamages';
import {
  HIDE_RECONCILE_MAINTENANCE_MODAL,
  HIDE_RECONCILE_SPINNER,
  SHOW_RECONCILE_MAINTENANCE_MODAL,
  SHOW_RECONCILE_SPINNER,
  SHOW_RECONCILE_MODAL,
  HIDE_RECONCILE_MODAL,
} from '../actions/reconcile';
import {
  HIDE_REPORT_FIELD_MODAL,
  HIDE_REPORT_FIELD_SPINNER,
  SHOW_REPORT_FIELD_MODAL,
  SHOW_REPORT_FIELD_SPINNER,
} from '../actions/reportField';
import {
  HIDE_REPORT_FIELDS_SPINNER,
  SHOW_REPORT_FIELDS_SPINNER,
} from '../actions/reportFields';
import {
  HIDE_RESULT_LAYOUT_MODAL,
  SHOW_RESULT_LAYOUT_MODAL,
} from '../actions/resultLayout';
import {
  HIDE_ROLE_MODAL,
  HIDE_ROLE_SPINNER,
  SHOW_ROLE_MODAL,
  SHOW_ROLE_SPINNER,
} from '../actions/role';
import { HIDE_ROLES_SPINNER, SHOW_ROLES_SPINNER } from '../actions/roles';
import {
  HIDE_SERVICE_CATEGORY_MODAL,
  SHOW_SERVICE_CATEGORY_MODAL,
} from '../actions/serviceCategory';
import {
  HIDE_SLOC_MODAL,
  HIDE_SLOC_SPINNER,
  SHOW_SLOC_MODAL,
  SHOW_SLOC_SPINNER,
} from '../actions/sloc';
import { HIDE_SLOCS_SPINNER, SHOW_SLOCS_SPINNER } from '../actions/slocs';
import {
  HIDE_USER_MODAL,
  HIDE_USER_SPINNER,
  SHOW_USER_MODAL,
  SHOW_USER_SPINNER,
} from '../actions/user';
import {
  HIDE_PROFILE_MODAL,
  HIDE_PROFILE_SPINNER,
  SHOW_PROFILE_MODAL,
  SHOW_PROFILE_SPINNER,
} from '../actions/profile';
import {
  HIDE_MRP_NOTIFICATION_SETUP_SPINNER,
  SHOW_MRP_NOTIFICATION_SETUP_SPINNER,
} from '../actions/MRPNotificationSetup';
import { HIDE_USERS_SPINNER, SHOW_USERS_SPINNER } from '../actions/users';
import {
  HIDE_STRUCTURE_SPINNER,
  SHOW_STRUCTURE_SPINNER,
} from '../actions/vault';
import {
  HIDE_VENDOR_MODAL,
  HIDE_VENDOR_SPINNER,
  SHOW_VENDOR_MODAL,
  SHOW_VENDOR_SPINNER,
} from '../actions/vendor';
import { HIDE_VENDORS_SPINNER, SHOW_VENDORS_SPINNER } from '../actions/vendors';
import {
  HIDE_VPAS_MODAL,
  HIDE_VPAS_SPINNER,
  SHOW_VPAS_MODAL,
  SHOW_VPAS_SPINNER,
} from '../actions/vpas';
import { HIDE_VPASS_SPINNER, SHOW_VPASS_SPINNER } from '../actions/vpass';
import {
  HIDE_WELL_MODAL,
  HIDE_WELL_SPINNER,
  SHOW_WELL_MODAL,
  SHOW_WELL_SPINNER,
} from '../actions/well';
import { HIDE_WELLS_SPINNER, SHOW_WELLS_SPINNER } from '../actions/wells';
import {
  HIDE_ADDITIONAL_NOTE_DATA_MODAL,
  HIDE_CONTRACTOR_DATA_MODAL,
  HIDE_DOCUMENT_DATA_MODAL,
  HIDE_INSPECTION_RESULTS_MODAL,
  HIDE_JOB_AND_CUSTOMER_DATA_MODAL,
  HIDE_PHOTO_DATA_MODAL,
  SHOW_ADDITIONAL_NOTE_DATA_MODAL,
  SHOW_CONTRACTOR_DATA_MODAL,
  SHOW_DOCUMENT_DATA_MODAL,
  SHOW_INSPECTION_RESULTS_MODAL,
  SHOW_JOB_AND_CUSTOMER_DATA_MODAL,
  SHOW_PHOTO_DATA_MODAL,
  SHOW_WORK_ORDERS_SPINNER,
  HIDE_WORK_ORDERS_SPINNER,
  SHOW_EQUIPMENT_DETAILS_MODAL,
  HIDE_EQUIPMENT_DETAILS_MODAL,
} from '../actions/workOrders';
import {
  HIDE_WAREHOUSE_MODAL,
  HIDE_WAREHOUSE_SPINNER,
  SHOW_WAREHOUSE_MODAL,
  SHOW_WAREHOUSE_SPINNER,
} from '../actions/warehouse';
import {
  HIDE_WAREHOUSES_SPINNER,
  SHOW_WAREHOUSES_SPINNER,
} from '../actions/warehouses';
import {
  HIDE_RACK_MODAL,
  HIDE_RACK_SPINNER,
  SHOW_RACK_MODAL,
  SHOW_RACK_SPINNER,
} from '../actions/rack';
import { HIDE_RACKS_SPINNER, SHOW_RACKS_SPINNER } from '../actions/racks';
import {
  HIDE_BIN_MODAL,
  HIDE_BIN_SPINNER,
  SHOW_BIN_MODAL,
  SHOW_BIN_SPINNER,
} from '../actions/bin';
import { HIDE_BINS_SPINNER, SHOW_BINS_SPINNER } from '../actions/bins';
import {
  HIDE_CYCLE_COUNT_MODAL,
  HIDE_CYCLE_COUNT_SPINNER,
  SHOW_CYCLE_COUNT_MODAL,
  SHOW_CYCLE_COUNT_SPINNER,
} from '../actions/cycleCount';
import {
  HIDE_CYCLE_COUNTS_SPINNER,
  SHOW_CYCLE_COUNTS_SPINNER,
} from '../actions/cycleCounts';
import {
  HIDE_INSPECTION_MODAL,
  HIDE_INSPECTION_SPINNER,
  SHOW_INSPECTION_MODAL,
  SHOW_INSPECTION_SPINNER,
} from '../actions/inspection';
import {
  HIDE_INSPECTIONS_SPINNER,
  SHOW_INSPECTIONS_SPINNER,
} from '../actions/inspections';

const initUi = {
  allocationLoading: false,
  businessUnitLoading: false,
  businessUnitModal: false,
  businessUnitsLoading: false,
  activityLoading: false,
  activityModal: false,
  activitiesLoading: false,
  cartLoading: false,
  checkOutLoading: false,
  checkOutModal: false,
  companyCodeLoading: false,
  companyCodeModal: false,
  companyCodesLoading: false,
  customerLoading: false,
  customerModal: false,
  customersLoading: false,
  directoryStructureSpinner: [],
  historyLoading: false,
  jobVpasModal: false,
  loginLoading: false,
  maintenanceModal: false,
  maintenancesLoading: false,
  movimentationLoading: false,
  movimentationModal: false,
  movimentationsLoading: false,
  movimentationsSearchLoading: false,
  ownerLoading: false,
  ownerModal: false,
  ownersLoading: false,
  notificationsLoading: false,
  projectLoading: false,
  projectModal: false,
  projectsLoading: false,
  reclassDamageLoading: false,
  reclassDamageModal: false,
  reclassDamagesLoading: false,
  reconcileLoading: false,
  reconcileModal: false,
  reportFieldLoading: false,
  reportFieldModal: false,
  reportFieldsLoading: false,
  resultLayoutModal: false,
  roleLoading: false,
  roleModal: false,
  rolesLoading: false,
  profileLoading: false,
  profileModal: false,
  mrpNotificationLoading: false,
  userLoading: false,
  userModal: false,
  usersLoading: false,
  vpasLoading: false,
  jobsLoading: false,
  vpasModal: false,
  vpassLoading: false,
  wellLoading: false,
  wellModal: false,
  wellsLoading: false,
  woAdditionalNoteModal: false,
  woContractorDataModal: false,
  woDocumentModal: false,
  woInspectionResultsModal: false,
  woJobAndCustomerModal: false,
  woEquipmentDetailsModal: false,
  woPhotoModal: false,
  workOrdersLoading: false,
  warehouseLoading: false,
  rackLoading: false,
  binLoading: false,
  warehouseModal: false,
  rackModal: false,
  binModal: false,
  warehousesLoading: false,
  racksLoading: false,
  binsLoading: false,
  cycleCountsLoading: false,
  cycleCountLoading: false,
  cycleCountModal: false,
  inspectionsLoading: false,
  inspectionLoading: false,
  inspectionModal: false,
};

export function uiReducer(state = initUi, action) {
  switch (action.type) {
    // Users
    case SHOW_USERS_SPINNER:
      return { ...state, usersLoading: true };

    case HIDE_USERS_SPINNER:
      return { ...state, usersLoading: false };

    case SHOW_USER_SPINNER:
      return { ...state, userLoading: true };

    case HIDE_USER_SPINNER:
      return { ...state, userLoading: false };

    case SHOW_USER_MODAL:
      return { ...state, userModal: true };

    case HIDE_USER_MODAL:
      return { ...state, userModal: false };

    // Profile
    case SHOW_PROFILE_SPINNER:
      return { ...state, profileLoading: true };

    case HIDE_PROFILE_SPINNER:
      return { ...state, profileLoading: false };

    case SHOW_PROFILE_MODAL:
      return { ...state, profileModal: true };

    case HIDE_PROFILE_MODAL:
      return { ...state, profileModal: false };

    // MRP Notification Setup
    case SHOW_MRP_NOTIFICATION_SETUP_SPINNER:
      return { ...state, mrpNotificationLoading: true };

    case HIDE_MRP_NOTIFICATION_SETUP_SPINNER:
      return { ...state, mrpNotificationLoading: false };

    // Customers
    case SHOW_CUSTOMERS_SPINNER:
      return { ...state, customersLoading: true };

    case HIDE_CUSTOMERS_SPINNER:
      return { ...state, customersLoading: false };

    case SHOW_CUSTOMER_SPINNER:
      return { ...state, customerLoading: true };

    case HIDE_CUSTOMER_SPINNER:
      return { ...state, customerLoading: false };

    case SHOW_CUSTOMER_MODAL:
      return { ...state, customerModal: true };

    case HIDE_CUSTOMER_MODAL:
      return { ...state, customerModal: false };

    // Business Units
    case SHOW_BUSINESS_UNITS_SPINNER:
      return { ...state, businessUnitsLoading: true };

    case HIDE_BUSINESS_UNITS_SPINNER:
      return { ...state, businessUnitsLoading: false };

    case SHOW_BUSINESS_UNIT_SPINNER:
      return { ...state, businessUnitLoading: true };

    case HIDE_BUSINESS_UNIT_SPINNER:
      return { ...state, businessUnitLoading: false };

    case SHOW_BUSINESS_UNIT_MODAL:
      return { ...state, businessUnitModal: true };

    case HIDE_BUSINESS_UNIT_MODAL:
      return { ...state, businessUnitModal: false };

    // Activities
    case SHOW_ACTIVITIES_SPINNER:
      return { ...state, activitiesLoading: true };

    case HIDE_ACTIVITIES_SPINNER:
      return { ...state, activitiesLoading: false };

    case SHOW_ACTIVITY_SPINNER:
      return { ...state, activityLoading: true };

    case HIDE_ACTIVITY_SPINNER:
      return { ...state, activityLoading: false };

    case SHOW_ACTIVITY_MODAL:
      return { ...state, activityModal: true };

    case HIDE_ACTIVITY_MODAL:
      return { ...state, activityModal: false };

    // Company Codes
    case SHOW_COMPANY_CODES_SPINNER:
      return { ...state, companyCodesLoading: true };

    case HIDE_COMPANY_CODES_SPINNER:
      return { ...state, companyCodesLoading: false };

    case SHOW_COMPANY_CODE_SPINNER:
      return { ...state, companyCodeLoading: true };

    case HIDE_COMPANY_CODE_SPINNER:
      return { ...state, companyCodeLoading: false };

    case SHOW_COMPANY_CODE_MODAL:
      return { ...state, companyCodeModal: true };

    case HIDE_COMPANY_CODE_MODAL:
      return { ...state, companyCodeModal: false };

    // Owners
    case SHOW_OWNERS_SPINNER:
      return { ...state, ownersLoading: true };

    case HIDE_OWNERS_SPINNER:
      return { ...state, ownersLoading: false };

    case SHOW_OWNER_SPINNER:
      return { ...state, ownerLoading: true };

    case HIDE_OWNER_SPINNER:
      return { ...state, ownerLoading: false };

    case SHOW_OWNER_MODAL:
      return { ...state, ownerModal: true };

    case HIDE_OWNER_MODAL:
      return { ...state, ownerModal: false };

    // History
    case SHOW_HISTORY_SPINNER:
      return { ...state, historyLoading: true };

    case HIDE_HISTORY_SPINNER:
      return { ...state, historyLoading: false };
    // CHECK OUT
    case SHOW_CHECK_OUT_SPINNER:
      return { ...state, checkOutLoading: true };

    case HIDE_CHECK_OUT_SPINNER:
      return { ...state, checkOutLoading: false };

    case SHOW_CHECK_OUT_MODAL:
      return { ...state, checkOutModal: true };

    case HIDE_CHECK_OUT_MODAL:
      return { ...state, checkOutModal: false };

    // Work Orders
    case SHOW_CONTRACTOR_DATA_MODAL:
      return { ...state, woContractorDataModal: true };

    case HIDE_CONTRACTOR_DATA_MODAL:
      return { ...state, woContractorDataModal: false };

    case SHOW_JOB_AND_CUSTOMER_DATA_MODAL:
      return { ...state, woJobAndCustomerModal: true };
    case HIDE_JOB_AND_CUSTOMER_DATA_MODAL:
      return { ...state, woJobAndCustomerModal: false };
    case SHOW_EQUIPMENT_DETAILS_MODAL:
      return { ...state, woEquipmentDetailsModal: true };
    case HIDE_EQUIPMENT_DETAILS_MODAL:
      return { ...state, woEquipmentDetailsModal: false };
    case SHOW_DOCUMENT_DATA_MODAL:
      return { ...state, woDocumentModal: true };
    case SHOW_INSPECTION_RESULTS_MODAL:
      return { ...state, woInspectionResultsModal: true };

    case HIDE_DOCUMENT_DATA_MODAL:
      return { ...state, woDocumentModal: false };
    case HIDE_INSPECTION_RESULTS_MODAL:
      return { ...state, woInspectionResultsModal: false };
    case SHOW_PHOTO_DATA_MODAL:
      return { ...state, woPhotoModal: true };

    case HIDE_PHOTO_DATA_MODAL:
      return { ...state, woPhotoModal: false };
    case SHOW_ADDITIONAL_NOTE_DATA_MODAL:
      return { ...state, woAdditionalNoteModal: true };

    case HIDE_ADDITIONAL_NOTE_DATA_MODAL:
      return { ...state, woAdditionalNoteModal: false };

    case SHOW_WORK_ORDERS_SPINNER:
      return { ...state, workOrdersLoading: true };

    case HIDE_WORK_ORDERS_SPINNER:
      return { ...state, workOrdersLoading: false };

    // Notifications
    case SHOW_NOTIFICATIONS_SPINNER:
      return { ...state, notificationsLoading: true };

    case HIDE_NOTIFICATIONS_SPINNER:
      return { ...state, notificationsLoading: false };

    // Projects
    case SHOW_PROJECTS_SPINNER:
      return { ...state, projectsLoading: true };

    case HIDE_PROJECTS_SPINNER:
      return { ...state, projectsLoading: false };

    case SHOW_PROJECT_SPINNER:
      return { ...state, projectLoading: true };

    case HIDE_PROJECT_SPINNER:
      return { ...state, projectLoading: false };

    case SHOW_PROJECT_MODAL:
      return { ...state, projectModal: true };

    case HIDE_PROJECT_MODAL:
      return { ...state, projectModal: false };

    // Wells
    case SHOW_WELLS_SPINNER:
      return { ...state, wellsLoading: true };

    case HIDE_WELLS_SPINNER:
      return { ...state, wellsLoading: false };

    case SHOW_WELL_SPINNER:
      return { ...state, wellLoading: true };

    case HIDE_WELL_SPINNER:
      return { ...state, wellLoading: false };

    case SHOW_WELL_MODAL:
      return { ...state, wellModal: true };

    case HIDE_WELL_MODAL:
      return { ...state, wellModal: false };

    // Reclass Damage
    case SHOW_RECLASS_DAMAGES_SPINNER:
      return { ...state, reclassDamagesLoading: true };

    case HIDE_RECLASS_DAMAGES_SPINNER:
      return { ...state, reclassDamagesLoading: false };

    case SHOW_RECLASS_DAMAGE_SPINNER:
      return { ...state, reclassDamageLoading: true };

    case HIDE_RECLASS_DAMAGE_SPINNER:
      return { ...state, reclassDamageLoading: false };

    case SHOW_RECLASS_DAMAGE_MODAL:
      return { ...state, reclassDamageModal: true };

    case HIDE_RECLASS_DAMAGE_MODAL:
      return { ...state, reclassDamageModal: false };

    // Allocation
    case SHOW_ALLOCATE_SPINNER:
      return { ...state, allocationLoading: true };
    case HIDE_ALLOCATE_SPINNER:
      return { ...state, allocationLoading: false };

    // Report Fields
    case SHOW_REPORT_FIELDS_SPINNER:
      return { ...state, reportFieldsLoading: true };

    case HIDE_REPORT_FIELDS_SPINNER:
      return { ...state, reportFieldsLoading: false };

    case SHOW_REPORT_FIELD_SPINNER:
      return { ...state, reportFieldLoading: true };

    case HIDE_REPORT_FIELD_SPINNER:
      return { ...state, reportFieldLoading: false };

    case SHOW_REPORT_FIELD_MODAL:
      return { ...state, reportFieldModal: true };

    case HIDE_REPORT_FIELD_MODAL:
      return { ...state, reportFieldModal: false };

    // Jobs
    case SHOW_DOCUMENTATION_MODAL:
      return { ...state, documentsModal: true };

    case HIDE_DOCUMENTATION_MODAL:
      return { ...state, documentsModal: false };

    case SHOW_JOB_VPAS_MODAL:
      return { ...state, jobVpasModal: true };

    case HIDE_JOB_VPAS_MODAL:
      return { ...state, jobVpasModal: false };

    case SHOW_JOBS_SPINNER:
      return { ...state, jobsLoading: true };
    case HIDE_JOBS_SPINNER:
      return { ...state, jobsLoading: false };

    // Login
    case SHOW_LOGIN_SPINNER:
      return { ...state, loginLoading: true };

    case HIDE_LOGIN_SPINNER:
      return { ...state, loginLoading: false };

    // Roles
    case SHOW_ROLES_SPINNER:
      return { ...state, rolesLoading: true };

    case HIDE_ROLES_SPINNER:
      return { ...state, rolesLoading: false };

    case SHOW_ROLE_SPINNER:
      return { ...state, roleLoading: true };

    case HIDE_ROLE_SPINNER:
      return { ...state, roleLoading: false };

    case SHOW_ROLE_MODAL:
      return { ...state, roleModal: true };

    case HIDE_ROLE_MODAL:
      return { ...state, roleModal: false };

    // Movimentation
    case SHOW_MOVIMENTATION_SPINNER:
      return { ...state, movimentationLoading: true };

    case HIDE_MOVIMENTATION_SPINNER:
      return { ...state, movimentationLoading: false };

    case SHOW_MOVIMENTATIONS_SPINNER:
      return { ...state, movimentationsLoading: true };

    case HIDE_MOVIMENTATIONS_SPINNER:
      return { ...state, movimentationsLoading: false };

    case SHOW_MOVIMENTATIONS_SEARCH_SPINNER:
      return { ...state, movimentationsSearchLoading: true };

    case HIDE_MOVIMENTATIONS_SEARCH_SPINNER:
      return { ...state, movimentationsSearchLoading: false };

    case SHOW_MOVIMENTATION_MODAL:
      return { ...state, movimentationModal: true };

    case HIDE_MOVIMENTATION_MODAL:
      return { ...state, movimentationModal: false };

    // Inventory Cart
    case SHOW_INVENTORY_CART_SPINNER:
      return { ...state, cartLoading: true };
    case HIDE_INVENTORY_CART_SPINNER:
      return { ...state, cartLoading: false };
    // SLOC
    case SHOW_SLOCS_SPINNER:
      return { ...state, slocsLoading: true };

    case HIDE_SLOCS_SPINNER:
      return { ...state, slocsLoading: false };

    case SHOW_SLOC_SPINNER:
      return { ...state, slocLoading: true };

    case HIDE_SLOC_SPINNER:
      return { ...state, slocLoading: false };

    case SHOW_SLOC_MODAL:
      return { ...state, slocModal: true };

    case HIDE_SLOC_MODAL:
      return { ...state, slocModal: false };

      // Reconcile

    case SHOW_RECONCILE_SPINNER:
      return { ...state, reconcileLoading: true };

    case HIDE_RECONCILE_SPINNER:
      return { ...state, reconcileLoading: false };
    case SHOW_RECONCILE_MODAL:
      return { ...state, reconcileModal: true };

    case HIDE_RECONCILE_MODAL:
      return { ...state, reconcileModal: false };

    case SHOW_RECONCILE_MAINTENANCE_MODAL:
    case SHOW_MAINTENANCE_MODAL:
      return { ...state, maintenanceModal: true };

    case HIDE_RECONCILE_MAINTENANCE_MODAL:
    case HIDE_MAINTENANCE_MODAL:
      return { ...state, maintenanceModal: false };

    // VENDOR
    case SHOW_VENDORS_SPINNER:
      return { ...state, vendorsLoading: true };

    case HIDE_VENDORS_SPINNER:
      return { ...state, vendorsLoading: false };

    case SHOW_VENDOR_SPINNER:
      return { ...state, vendorLoading: true };

    case HIDE_VENDOR_SPINNER:
      return { ...state, vendorLoading: false };

    case SHOW_VENDOR_MODAL:
      return { ...state, vendorModal: true };

    case HIDE_VENDOR_MODAL:
      return { ...state, vendorModal: false };

    // Original Charge
    case SHOW_ORIGINAL_CHARGES_SPINNER:
      return { ...state, originalChargesLoading: true };

    case HIDE_ORIGINAL_CHARGES_SPINNER:
      return { ...state, originalChargesLoading: false };

    case SHOW_ORIGINAL_CHARGE_SPINNER:
      return { ...state, originalChargeLoading: true };

    case HIDE_ORIGINAL_CHARGE_SPINNER:
      return { ...state, originalChargeLoading: false };

    case SHOW_ORIGINAL_CHARGE_MODAL:
      return { ...state, originalChargeModal: true };

    case HIDE_ORIGINAL_CHARGE_MODAL:
      return { ...state, originalChargeModal: false };

    // V-PAS
    case SHOW_VPASS_SPINNER:
      return { ...state, vpassLoading: true };

    case HIDE_VPASS_SPINNER:
      return { ...state, vpassLoading: false };

    case SHOW_VPAS_SPINNER:
      return { ...state, vpasLoading: true };

    case HIDE_VPAS_SPINNER:
      return { ...state, vpasLoading: false };

    case SHOW_VPAS_MODAL:
      return { ...state, vpasModal: true };

    case HIDE_VPAS_MODAL:
      return { ...state, vpasModal: false };

    // Customer MM
    case SHOW_CUSTOMER_MMS_SPINNER:
      return { ...state, customerMMsLoading: true };

    case HIDE_CUSTOMER_MMS_SPINNER:
      return { ...state, customerMMsLoading: false };

    case SHOW_CUSTOMER_MM_SPINNER:
      return { ...state, customerMMLoading: true };

    case HIDE_CUSTOMER_MM_SPINNER:
      return { ...state, customerMMLoading: false };

    case SHOW_CUSTOMER_MM_MODAL:
      return { ...state, customerMMModal: true };

    case HIDE_CUSTOMER_MM_MODAL:
      return { ...state, customerMMModal: false };

    // SERVICE CATEGORIES
    case SHOW_SERVICE_CATEGORY_MODAL:
      return { ...state, serviceCategoryModal: true };

    case HIDE_SERVICE_CATEGORY_MODAL:
      return { ...state, serviceCategoryModal: false };

    // MAINTENANCE CATEGORIES
    case SHOW_MAINTENANCE_CATEGORY_MODAL:
      return { ...state, maintenanceCategoryModal: true };

    case HIDE_MAINTENANCE_CATEGORY_MODAL:
      return { ...state, maintenanceCategoryModal: false };

    // MAINTENANCES
    case SHOW_MAINTENANCES_SPINNER:
      return { ...state, maintenancesLoading: true };

    case HIDE_MAINTENANCES_SPINNER:
      return { ...state, maintenancesLoading: false };

    // RESULT LAYOUT
    case SHOW_RESULT_LAYOUT_MODAL:
      return { ...state, resultLayoutModal: true };
    case HIDE_RESULT_LAYOUT_MODAL:
      return { ...state, resultLayoutModal: false };

    // VAULT
    case SHOW_STRUCTURE_SPINNER:
    {
      const directoryStructureSpinnerToShow = [
        ...state.directoryStructureSpinner,
      ];
      const indexToShow = directoryStructureSpinnerToShow.indexOf(
        action.payload,
      );
      if (indexToShow < 0) {
        return {
          ...state,
          directoryStructureSpinner: [
            ...directoryStructureSpinnerToShow,
            action.payload,
          ],
        };
      }
      return state;
    }
    case HIDE_STRUCTURE_SPINNER:
    {
      const directoryStructureSpinnerToHide = [
        ...state.directoryStructureSpinner,
      ];
      const indexToHide = directoryStructureSpinnerToHide.indexOf(
        action.payload,
      );
      if (indexToHide < 0) return state;
      directoryStructureSpinnerToHide.splice(indexToHide, 1);
      return {
        ...state,
        directoryStructureSpinner: directoryStructureSpinnerToHide,
      };
    }
    // Warehouse
    case SHOW_WAREHOUSES_SPINNER:
      return { ...state, warehousesLoading: true };

    case HIDE_WAREHOUSES_SPINNER:
      return { ...state, warehousesLoading: false };

    case SHOW_WAREHOUSE_SPINNER:
      return { ...state, warehouseLoading: true };

    case HIDE_WAREHOUSE_SPINNER:
      return { ...state, warehouseLoading: false };

    case SHOW_WAREHOUSE_MODAL:
      return { ...state, warehouseModal: true };

    case HIDE_WAREHOUSE_MODAL:
      return { ...state, warehouseModal: false };

    // Rack
    case SHOW_RACKS_SPINNER:
      return { ...state, racksLoading: true };

    case HIDE_RACKS_SPINNER:
      return { ...state, racksLoading: false };

    case SHOW_RACK_SPINNER:
      return { ...state, rackLoading: true };

    case HIDE_RACK_SPINNER:
      return { ...state, rackLoading: false };

    case SHOW_RACK_MODAL:
      return { ...state, rackModal: true };

    case HIDE_RACK_MODAL:
      return { ...state, rackModal: false };

    // Bin
    case SHOW_BINS_SPINNER:
      return { ...state, binsLoading: true };

    case HIDE_BINS_SPINNER:
      return { ...state, binsLoading: false };

    case SHOW_BIN_SPINNER:
      return { ...state, binLoading: true };

    case HIDE_BIN_SPINNER:
      return { ...state, binLoading: false };

    case SHOW_BIN_MODAL:
      return { ...state, binModal: true };

    case HIDE_BIN_MODAL:
      return { ...state, binModal: false };

    // Cycle Count
    case SHOW_CYCLE_COUNTS_SPINNER:
      return { ...state, cycleCountsLoading: true };

    case HIDE_CYCLE_COUNTS_SPINNER:
      return { ...state, cycleCountsLoading: false };

    case SHOW_CYCLE_COUNT_SPINNER:
      return { ...state, cycleCountLoading: true };

    case HIDE_CYCLE_COUNT_SPINNER:
      return { ...state, cycleCountLoading: false };

    case SHOW_CYCLE_COUNT_MODAL:
      return { ...state, cycleCountModal: true };

    case HIDE_CYCLE_COUNT_MODAL:
      return { ...state, cycleCountModal: false };

    // Inspection
    case SHOW_INSPECTIONS_SPINNER:
      return { ...state, inspectionsLoading: true };

    case HIDE_INSPECTIONS_SPINNER:
      return { ...state, inspectionsLoading: false };

    case SHOW_INSPECTION_SPINNER:
      return { ...state, inspectionLoading: true };

    case HIDE_INSPECTION_SPINNER:
      return { ...state, inspectionLoading: false };

    case SHOW_INSPECTION_MODAL:
      return { ...state, inspectionModal: true };

    case HIDE_INSPECTION_MODAL:
      return { ...state, inspectionModal: false };

    default:
      return state;
  }
}
