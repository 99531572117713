import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import {
  Container,
  TitleContent,
  InputContainer,
  ButtonContainer,
} from './styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 600,
    position: 'relative',
    width: '100%',
  },
  button: {
    fontSize: 16,
    color: '#737373',
  },
  listItem: {
    width: '100%',
  },
  listAccordion: {
    padding: '0px 0px 0px 25px',
    height: 39,
    marginTop: -25,
  },
  questionLabel: {
    paddingTop: 2,
    paddingBottom: 2,
    fontSize: '0.8rem',
  },
  listItemQuestion: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderTop: '1px solid #ccc',
  },
  listItemPadding: {
    paddingTop: 4,
    paddingBottom: 4,
  },
  expansionHeader: {},
  questionLabelEmpty: {
    color: '#808080',
    fontSize: '0.8rem',
  },
}));

const OwnershipInformation = ({ movimentation }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);

  return (
    <Container>
      <Accordion expanded={expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          classes={{ content: classes.expansionHeader }}
          onClick={() => setExpanded(!expanded)}
        >
          <div className={classes.heading}>
            <TitleContent>Ownership Information</TitleContent>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container style={{ padding: '0px 10px 10px 10px' }}>
            <Grid item lg={3} md={6} sm={12} xs={12}>
              <InputContainer>
                <TextField
                  disabled
                  size="small"
                  type="text"
                  label="Supplier"
                  variant="outlined"
                  value={movimentation?.supplier || ''}
                  fullWidth
                />
              </InputContainer>
            </Grid>
            <Grid item lg={3} md={6} sm={12} xs={12}>
              <InputContainer>
                <TextField
                  disabled
                  size="small"
                  label="Allocated Batch No."
                  type="number"
                  variant="outlined"
                  value={movimentation?.allocated_batch_number || ''}
                  fullWidth
                />
              </InputContainer>
            </Grid>
            <Grid item lg={3} md={6} sm={12} xs={12}>
              <InputContainer>
                <TextField
                  disabled
                  size="small"
                  label="Allocated Project"
                  type="text"
                  variant="outlined"
                  value={
                    movimentation?.allocated_charge_code?.well?.project?.name ||
                    ''
                  }
                  fullWidth
                />
              </InputContainer>
            </Grid>
            <Grid item lg={3} md={6} sm={12} xs={12}>
              <InputContainer>
                <TextField
                  disabled
                  size="small"
                  label="Allocated Charge Code"
                  variant="outlined"
                  value={
                    movimentation?.allocated_charge_code
                      ?.original_charge_code || ''
                  }
                  fullWidth
                />
              </InputContainer>
            </Grid>
            <Grid item lg={3} md={6} sm={12} xs={12}>
              <InputContainer>
                <TextField
                  disabled
                  size="small"
                  label="Divestment No."
                  type="number"
                  variant="outlined"
                  value={movimentation?.divestment_number || ''}
                  fullWidth
                />
              </InputContainer>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

export default OwnershipInformation;
