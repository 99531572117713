import {
  SET_HISTORY_FILTER,
  CLEAR_HISTORY_FILTER,
} from '../actions/historyFilter';

const initialState = {
  event: '',
  period_start: null,
  period_end: null,
  facilityReferenceID: '',
  SLOC: '',
  batch: '',
  project: '',
  chargeCode: '',
  query: '',
  transactionId: '',
  author: '',
};

export function historyFilterReducer(historyFilter = initialState, action) {
  switch (action.type) {
    case SET_HISTORY_FILTER:
      return { ...historyFilter, ...action.payload };
    case CLEAR_HISTORY_FILTER:
      return initialState;
    default:
      return historyFilter;
  }
}
