import {
  FETCH_CYCLE_COUNTS_SUCCESS,
  FETCH_CYCLE_COUNTS_ERROR,
  GET_CYCLE_COUNTS,
  updateCycleCounts,
  showSpinner,
  hideSpinner,
} from '../actions/cycleCounts';
import { apiRequest } from '../actions/api';

const URL = 'cycle_counts';

export const getCycleCountsFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_CYCLE_COUNTS) {
    dispatch(
      apiRequest(
        'GET',
        URL,
        action.payload,
        FETCH_CYCLE_COUNTS_SUCCESS,
        FETCH_CYCLE_COUNTS_ERROR,
        ['listAll', 'active'],
      ),
    );
    dispatch(showSpinner());
  }
};

export const processCycleCountsCollection = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_CYCLE_COUNTS_SUCCESS) {
    dispatch(updateCycleCounts(action.payload));
    dispatch(hideSpinner());
  }
};

export const cycleCountsMdl = [
  getCycleCountsFlow,
  processCycleCountsCollection,
];
