import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import { ArrowBack } from '@material-ui/icons';
import { CUSTOMER_KEY } from '../../../config/consts';
import { tableStyles } from '../../../styles/tableStyles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { getProjects } from '../../../redux/actions/projects';
import { getOriginalCharge } from '../../../redux/actions/originalCharges';
import { InputContainer, Container, ButtonContainer } from './styles';
import EventDocumentsSection from '../../../components/EventDocumentsSection';
import { Button, MenuItem } from '@material-ui/core';
import {
  createTransferOfOwnership,
  updateTransferOfOwnership,
  getTransferOfOwnership,
} from '../../../redux/actions/transferOfOwnership';
import { downloadReport } from '../../../services/Download';
import TagsField from '../../../components/TagsField';
import handlePost from '../../../utils/Event/handlePost';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  title: {
    fontSize: 20,
  },
  paper: {
    width: '100%',
    marginBottom: 16,
  },
  row: {
    padding: 0,
  },
  cell: {
    fontSize: 12,
    padding: '0px 10px 0px 15px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  buttons: {
    color: 'blue',
  },
  checkButton: {
    color: 'green',
  },
  closeButton: {
    color: 'red',
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  styledSpan: {
    alignSelf: 'center',
    fontWeight: '700',
  },
}));

const TransferOfOwnership = ({
  dispatch,
  projects,
  originalCharges,
  transferOfOwnership,
  loggedUser,
}) => {
  const { customer, id } = useParams();
  const history = useHistory();
  const { state } = useLocation();
  const classes = tableStyles();
  const localClasses = useStyles();
  const [data, setData] = React.useState({
    individualData: [],
    supplierName: '',
    supplierPhone: '',
    supplierEmail: '',
    binLocation: '',
    rackLocation: '',
    warehouseLocation: '',
    note: '',
    destinationProject: '',
    facilityReferenceID: '',
    chargeCode: '',
    supplierTo: '',
    workOrderNo: '',
    batchNumberTo: '',
    rqasServices: '',
    formNumber: '',
    plantCode: '',
    files: [],
    tags: [],
    newFiles: [],
  });

  const {
    individualData,
    supplierName,
    supplierPhone,
    supplierEmail,
    note,
    facilityReferenceID,
    chargeCode,
    supplierTo,
    workOrderNo,
    batchNumberTo,
    rqasServices,
    formNumber,
    files,
    plantCode,
    newFiles,
    fileNotes,
  } = data;

  React.useEffect(() => {
    if (!id || id === 'new') return;
    dispatch(getTransferOfOwnership(id));
  }, [id]);

  React.useEffect(() => {
    if (id === 'new' || !transferOfOwnership) return;
    setData(transferOfOwnership);
  }, [transferOfOwnership]);

  React.useEffect(() => {
    if (state?.movimentations) {
      setData((currentData) => ({
        ...currentData,
        chargeCode:
          state.movimentations[0]?.allocated_charge_code?._id ||
          state.movimentations[0]?.allocated_charge_code,
        batchNumberTo: state.movimentations[0]?.allocated_batch_number,
        plantCode: state.movimentations[0]?.allocated_plant_code,
        individualData: state.movimentations.map((mov) => ({
          movimentation: mov,
          qty: mov.qty,
          footage: mov.footage,
          tags: [],
        })),
      }));
    }
  }, [state]);

  useEffect(() => {
    if (!localStorage.getItem(CUSTOMER_KEY))
      history.replace('/equipments', { origin: 'mm_number' });
  }, []);

  useEffect(() => {
    dispatch(getProjects({ page: 1, limit: 999, filter: { customer } }));
  }, []);

  useEffect(() => {
    dispatch(getOriginalCharge({ page: 1, limit: 999, filter: { customer } }));
  }, []);

  const handleBack = () => {
    history.goBack();
  };

  function handleEdit(e) {
    const { name, value } = e.target;
    setData((currentData) => ({ ...currentData, [name]: value }));
  }

  function handleEditOnTable(index) {
    function handler(e) {
      const { name, value } = e.target;
      setData((currentData) => {
        const newAllocationData = [...currentData.individualData];
        newAllocationData[index] = {
          ...newAllocationData[index],
          [name]: value,
        };
        return { ...currentData, individualData: newAllocationData };
      });
    }
    return handler;
  }

  function handleDownload() {
    downloadReport(id).then(() => dispatch(getTransferOfOwnership(id)));
  }

  function handleFiles({ files, notes }) {
    setData((currentData) => ({
      ...currentData,
      newFiles: files,
      fileNotes: notes,
    }));
  }

  const disabled = id !== 'new';

  return (
    <>
      <Container>
        <div className={classes.root} style={{ overflow: 'visible' }}>
          <Paper className={classes.paper} style={{ minHeight: '75%' }}>
            <Toolbar className={classes.toolBar}>
              <span>
                <IconButton onClick={handleBack}>
                  <ArrowBack />
                </IconButton>
                <Typography
                  className={classes.title}
                  variant="h6"
                  id="tableTitle"
                  component="span"
                >
                  Transfer of Ownership
                </Typography>
              </span>
            </Toolbar>
            <TableContainer className={classes.table}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Equipment Name</TableCell>
                    <TableCell>RAMP Unique ID</TableCell>
                    <TableCell>Customer MM No</TableCell>
                    <TableCell style={{ backgroundColor: '#bbdefb' }}>
                      Total Qty. EA
                    </TableCell>
                    <TableCell style={{ backgroundColor: '#bbdefb' }}>
                      Footage
                    </TableCell>
                    <TableCell style={{ backgroundColor: '#c8e6c9' }}>
                      Total Qty (EA) Transfering
                    </TableCell>
                    <TableCell style={{ backgroundColor: '#c8e6c9' }}>
                      Footage Transfering
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: '#9370db66',
                        textAlign: 'center',
                      }}
                    >
                      Tags
                    </TableCell>
                    <TableCell>Supplier Batch No</TableCell>
                    <TableCell>COPAS Class</TableCell>
                    <TableCell>Heat/Lot</TableCell>
                    <TableCell>RBW</TableCell>
                    <TableCell>Facility Reference ID</TableCell>
                    <TableCell>Assigned Project</TableCell>
                    <TableCell>Assigned Well</TableCell>
                    <TableCell>Assigned Charge Code</TableCell>
                    <TableCell>Assigned Plant Code</TableCell>
                    <TableCell>RAMP Tag No</TableCell>
                    <TableCell>RINA Work Order No</TableCell>
                    <TableCell>SLOC</TableCell>
                    <TableCell>Material Layout</TableCell>
                    <TableCell>Allocated</TableCell>
                    <TableCell>Supplier</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {individualData?.map((itemData, index) => (
                    <TableRow>
                      <TableCell>
                        {itemData.movimentation?.customer_MM?.short_description}
                      </TableCell>
                      <TableCell>
                        {itemData.movimentation?.individual_item_id}
                      </TableCell>
                      <TableCell>
                        {itemData.movimentation?.customer_MM?.mm_number}
                      </TableCell>
                      <TableCell style={{ backgroundColor: '#e3f2fd' }}>
                        {itemData.movimentation.qty}
                      </TableCell>
                      <TableCell style={{ backgroundColor: '#e3f2fd' }}>
                        {itemData.movimentation.footage}
                      </TableCell>
                      <TableCell
                        style={{
                          minWidth: '120px',
                          backgroundColor: '#e8f5e9',
                        }}
                      >
                        <InputContainer>
                          <TextField
                            onChange={handleEditOnTable(index)}
                            size="small"
                            name="qty"
                            type="text"
                            variant="outlined"
                            value={itemData.qty}
                            disabled={
                              itemData.movimentation.material_layout.toUpperCase() !==
                                'BATCH' || disabled
                            }
                            fullWidth
                          />
                        </InputContainer>
                      </TableCell>
                      <TableCell
                        style={{
                          minWidth: '120px',
                          backgroundColor: '#e8f5e9',
                        }}
                      >
                        <InputContainer>
                          <TextField
                            onChange={handleEditOnTable(index)}
                            size="small"
                            name="footage"
                            type="text"
                            variant="outlined"
                            disabled={
                              itemData.movimentation.material_layout.toUpperCase() !==
                                'BATCH' || disabled
                            }
                            value={itemData.footage}
                            fullWidth
                          />
                        </InputContainer>
                      </TableCell>
                      <TableCell
                        style={{ backgroundColor: '#9370db33', minWidth: 200 }}
                      >
                        <InputContainer>
                          <TagsField
                            value={itemData.tags}
                            name="tags"
                            onChange={handleEditOnTable(index)}
                            disabled={disabled}
                          />
                        </InputContainer>
                      </TableCell>
                      <TableCell>{itemData.movimentation?.batch_no}</TableCell>
                      <TableCell>
                        {itemData.movimentation.classification}
                      </TableCell>
                      <TableCell>{itemData.movimentation.heat}</TableCell>
                      <TableCell>{itemData.movimentation.RBW}</TableCell>
                      <TableCell>
                        {itemData.movimentation.facility_reference_id}
                      </TableCell>
                      <TableCell>
                        {
                          itemData.movimentation?.assigned_customer_charge_code
                            ?.well?.project?.name
                        }
                      </TableCell>
                      <TableCell>
                        {
                          itemData.movimentation?.assigned_customer_charge_code
                            ?.well?.name
                        }
                      </TableCell>
                      <TableCell>
                        {
                          itemData.movimentation?.assigned_customer_charge_code
                            ?.original_charge_code
                        }
                      </TableCell>
                      <TableCell>{itemData.movimentation.plant_code}</TableCell>
                      <TableCell>{itemData.movimentation.tag_number}</TableCell>
                      <TableCell>
                        {itemData.movimentation.rina_work_order_no}
                      </TableCell>
                      <TableCell>
                        {itemData.movimentation?.SLOC?.sloc_name}
                      </TableCell>
                      <TableCell>
                        {itemData.movimentation.material_layout}
                      </TableCell>
                      <TableCell>
                        {itemData.movimentation.allocated_release_number ||
                        itemData.movimentation.allocated_batch_number ||
                        itemData.movimentation.allocated_work_order_number ? (
                          <span>YES</span>
                        ) : (
                          <span>NO</span>
                        )}
                      </TableCell>
                      <TableCell>{itemData.movimentation.supplier}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Paper className={localClasses.paper}>
            <Toolbar className={classes.toolBar}>
              <span>
                <Typography
                  className={classes.title}
                  variant="h6"
                  id="tableTitle"
                  component="span"
                  style={{ marginLeft: 15 }}
                >
                  Requesting Supplier Details
                </Typography>
              </span>
            </Toolbar>
            <Grid container style={{ padding: '0px 10px 10px 10px' }}>
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <InputContainer>
                  <TextField
                    onChange={handleEdit}
                    size="small"
                    name="supplierName"
                    label="Supplier Name"
                    type="text"
                    variant="outlined"
                    value={supplierName}
                    fullWidth
                    disabled={disabled}
                  />
                </InputContainer>
              </Grid>
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <InputContainer>
                  <TextField
                    onChange={handleEdit}
                    size="small"
                    name="supplierPhone"
                    label="Supplier Phone"
                    type="text"
                    variant="outlined"
                    value={supplierPhone}
                    fullWidth
                    disabled={disabled}
                  />
                </InputContainer>
              </Grid>
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <InputContainer>
                  <TextField
                    onChange={handleEdit}
                    size="small"
                    name="supplierEmail"
                    label="Supplier E-mail"
                    type="text"
                    variant="outlined"
                    value={supplierEmail}
                    fullWidth
                    disabled={disabled}
                  />
                </InputContainer>
              </Grid>
            </Grid>
          </Paper>
          <Paper className={localClasses.paper}>
            <Toolbar className={classes.toolBar}>
              <span>
                <Typography
                  className={classes.title}
                  variant="h6"
                  id="tableTitle"
                  component="span"
                  style={{ marginLeft: 15 }}
                >
                  Transfering Details
                </Typography>
              </span>
            </Toolbar>
            <Grid container style={{ padding: '0px 10px 10px 10px' }}>
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <InputContainer>
                  <TextField
                    select
                    onChange={handleEdit}
                    size="small"
                    name="destinationProject"
                    label="Destination Project"
                    type="text"
                    variant="outlined"
                    value={
                      originalCharges?.docs?.find(
                        ({ _id }) => _id === chargeCode
                      )?.well?.project?._id || ''
                    }
                    disabled
                    fullWidth
                  >
                    {projects?.docs?.map((project) => (
                      <MenuItem value={project._id}>{project.name}</MenuItem>
                    ))}
                  </TextField>
                </InputContainer>
              </Grid>
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <InputContainer>
                  <TextField
                    select
                    onChange={handleEdit}
                    size="small"
                    name="chargeCode"
                    label="Destination Charge Code"
                    type="text"
                    variant="outlined"
                    value={chargeCode?._id || chargeCode}
                    fullWidth
                    disabled={disabled}
                  >
                    {originalCharges?.docs?.map((originalCharge) => (
                      <MenuItem value={originalCharge._id}>
                        {originalCharge.original_charge_code}
                      </MenuItem>
                    ))}
                  </TextField>
                </InputContainer>
              </Grid>
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <InputContainer>
                  <TextField
                    onChange={handleEdit}
                    size="small"
                    name="plantCode"
                    label="Destination Plant Code"
                    type="text"
                    variant="outlined"
                    value={plantCode}
                    fullWidth
                    disabled={disabled}
                  />
                </InputContainer>
              </Grid>
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <InputContainer>
                  <TextField
                    onChange={handleEdit}
                    size="small"
                    name="facilityReferenceID"
                    label="Facility Reference ID"
                    type="text"
                    variant="outlined"
                    value={facilityReferenceID}
                    fullWidth
                    disabled={disabled}
                  />
                </InputContainer>
              </Grid>
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <InputContainer>
                  <TextField
                    onChange={handleEdit}
                    size="small"
                    name="supplierTo"
                    label="Owner"
                    type="text"
                    variant="outlined"
                    value={supplierTo}
                    fullWidth
                    disabled={disabled}
                  />
                </InputContainer>
              </Grid>
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <InputContainer>
                  <TextField
                    onChange={handleEdit}
                    size="small"
                    name="workOrderNo"
                    label="RINA Work Order Number"
                    type="text"
                    variant="outlined"
                    value={workOrderNo}
                    fullWidth
                    disabled={disabled}
                  />
                </InputContainer>
              </Grid>
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <InputContainer>
                  <TextField
                    onChange={handleEdit}
                    size="small"
                    name="batchNumberTo"
                    label="Transfering Batch Number To"
                    type="text"
                    variant="outlined"
                    value={batchNumberTo}
                    fullWidth
                    disabled={disabled}
                  />
                </InputContainer>
              </Grid>
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <InputContainer>
                  <TextField
                    onChange={handleEdit}
                    size="small"
                    name="rqasServices"
                    label="RQAS Services"
                    type="text"
                    variant="outlined"
                    value={rqasServices}
                    fullWidth
                    disabled={disabled}
                  />
                </InputContainer>
              </Grid>
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <InputContainer>
                  <TextField
                    onChange={handleEdit}
                    size="small"
                    name="formNumber"
                    label="Form Number"
                    type="text"
                    variant="outlined"
                    value={formNumber}
                    fullWidth
                    disabled={disabled}
                  />
                </InputContainer>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <InputContainer>
                  <TextField
                    onChange={handleEdit}
                    size="small"
                    name="note"
                    label="Transfer Summary Note"
                    type="text"
                    variant="outlined"
                    value={note}
                    fullWidth
                    disabled={disabled}
                  />
                </InputContainer>
              </Grid>
            </Grid>
          </Paper>
          <Paper className={classes.paper}>
            <EventDocumentsSection
              files={files}
              id={id}
              newFiles={newFiles}
              fileNotes={fileNotes}
              onChangeFiles={handleFiles}
            />
            <Grid container style={{ padding: '0px 10px 10px 10px' }}>
              <Grid lg={12} md={12} sm={12} xs={12}>
                <ButtonContainer>
                  <Button
                    color="primary"
                    variant={id === 'new' ? 'contained' : 'outlined'}
                    style={{ marginRight: 5 }}
                    onClick={handlePost(
                      id,
                      data,
                      loggedUser,
                      createTransferOfOwnership,
                      updateTransferOfOwnership,
                      dispatch
                    )}
                  >
                    {id === 'new' ? 'Post' : 'Update'} Transaction
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    style={{ marginRight: 5 }}
                    disabled={id === 'new'}
                    onClick={handleDownload}
                  >
                    Create Transfer Report
                  </Button>
                </ButtonContainer>
              </Grid>
            </Grid>
          </Paper>
        </div>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  reconcilesLoading: state.ui.reconcilesLoading,
  reconciles: state.reconciles,
  projects: state.projects,
  originalCharges: state.originalCharges,
  transferOfOwnership: state.transferOfOwnership,
  loggedUser: state.loggedUser,
});

export default connect(mapStateToProps)(TransferOfOwnership);
