module.exports = [
  {
    value: 'customer_MM',
    label: 'RINA Material Master No',
    type: 'ObjectId',
    ref: 'materialMasters',
    field: 'rina_mm_number',
  },
  { value: 'qty', label: 'Quantity', type: 'Number' }, // Number
  { value: 'footage', label: 'Footage', type: 'String' }, // String
  { value: 'batch_no', label: 'Supplier Batch No', type: 'String' }, // String
  { value: 'heat', label: 'HEAT No', type: 'String' }, // String
  { value: 'joint_no', label: 'Joint/Run No', type: 'String' }, // String
  { value: 'supplier', label: 'Supplier', type: 'String' }, // String
  { value: 'supplier_part_no', label: 'Supplier Part No', type: 'String' }, // String
  { value: 'supplier_serial_no', label: 'Supplier Serial No', type: 'String' }, // String
  { value: 'supplier_so_no', label: 'Supplier SO No', type: 'String' }, // String
  { value: 'customer_po_no', label: 'Customer PO Number', type: 'String' }, // String
  { value: 'PO_line_item_no', label: 'PO Line Item Number', type: 'String' }, // String
  { value: 'classification', label: 'COPAS Class', type: 'String' }, // String
  {
    value: 'damage',
    label: 'Damage Description',
    type: 'ObjectId',
    ref: 'reclassDamages',
    field: 'description',
  }, // String
  {
    value: 'original_charge',
    label: 'Original Charge Code',
    type: 'ObjectId',
    ref: ' originalCharge',
    field: 'original_charge_code',
  },
  {
    value: 'assigned_customer_charge_code',
    type: 'ObjectId',
    label: 'Assigned Charge Code',
    field: 'original_charge_code',
    ref: 'originalCharge',
  },
  { value: 'plant_code', label: 'Assigned Plant Code', type: 'String' }, // String
  {
    value: 'parent',
    type: 'ObjectId',
    label: 'Parent ID',
    field: 'individual_items_id',
    ref: 'movimentations',
  },
  { value: 'tally_id', label: 'Tally ID', type: 'String' }, // String
  { value: 'RBW', label: 'RBW', type: 'String' }, // String
  { value: 'status', label: 'Status', type: 'String' }, // String
  { value: 'material_layout', label: 'Material Layout', type: 'String' }, // String
  { value: 'divestment_number', label: 'Divestment Number', type: 'String' }, // String
  { value: 'legacy_tag_id', label: 'Legacy Tag ID', type: 'String' }, // String
  { value: 'note', label: 'Note', type: 'String' }, // String
  {
    value: 'SLOC',
    label: 'SLOC ID',
    type: 'ObjectId',
    ref: 'sloc',
    field: 'sloc_id',
  },
  {
    value: 'facility_reference_id',
    label: 'Facility Reference ID',
    type: 'String',
  }, // String
  {
    value: 'rina_work_order_no',
    label: 'Equality Work Order Number',
    type: 'String',
  }, // String

  { value: 'manufacturer', label: 'Manufacturer', type: 'String' }, // String
  { value: 'assembly_id', label: 'Assembly ID', type: 'String' }, // String
  { value: 'department', label: 'Department', type: 'String' }, // String
  { value: 'manufacturer_no', label: 'Manufacturer Number', type: 'String' }, // String
  { value: 'qty_prime', label: 'Quantity Prime', type: 'Number' }, // Number
  { value: 'qty_damage', label: 'Quantity Damage', type: 'Number' }, // Number
  {
    value: 'bin_location',
    label: 'Bin Location',
    type: 'ObjectId',
    ref: 'bins',
    field: 'name',
  }, // String
  {
    value: 'warehouse_location',
    label: 'Warehouse Location',
    type: 'ObjectId',
    ref: 'warehouses',
    field: 'name',
  }, // String
  {
    value: 'rack_location',
    label: 'Rack Location',
    type: 'ObjectId',
    ref: 'racks',
    field: 'name',
  }, // String
  {
    value: 'price_per_foot_cost',
    label: 'Price Per Foot Cost',
    type: 'String',
  }, // String
  { value: 'unit_cost', label: 'Unit (EA) Cost', type: 'String' }, // String
  { value: 'container_id', label: 'Container ID', type: 'String' }, // String
  { value: 'date_last_returned', label: 'Date Last Returned', type: 'Date' }, // Date
  { value: 'date_last_shipped', label: 'Date Last Shipped', type: 'Date' }, // Date
  {
    value: 'rina_return_work_order_no',
    label: 'Equality Return Work Order Number',
    type: 'String',
  }, // String

  {
    value: 'date_placed_in_inventory',
    label: 'Date Placed in Inventory',
    type: 'Date',
  }, // Date
  { value: 'individual_item_id', label: 'RAMP Unique ID', type: 'String' }, // String
  {
    value: 'allocated_plant_code',
    label: 'Allocated Plant Code',
    type: 'String',
  }, // String
  { value: 'last_reconcile_date', label: 'Last Reconcile Date', type: 'Date' }, // Date
  { value: 'last_date_offshore', label: 'Last Date Offshore', type: 'Date' }, // Date
  {
    value: 'maintenance_routine_interval_days',
    label: 'Maintenance Routine Interval Days',
    type: 'String',
  }, // String
  {
    value: 'maintenance_routine_target_date',
    label: 'Maintenance Routine Target Date',
    type: 'Date',
  }, // Date
  {
    value: 'rotation_routine_interval',
    label: 'Rotation Routine Interval',
    type: 'String',
  }, // String
  {
    value: 'rotation_routine_date',
    label: 'Rotation Routine Date',
    type: 'Date',
  }, // Date
  {
    value: 'testing_routine_interval',
    label: 'Testing Routine Interval',
    type: 'String',
  }, // String
  {
    value: 'testing_routine_date',
    label: 'Testing Routine Date',
    type: 'Date',
  }, // Date
  {
    value: 'allocated_release_number',
    label: 'Allocated Release Number',
    type: 'String',
  }, // String
  {
    value: 'allocated_batch_number',
    label: 'Allocated Batch Number',
    type: 'String',
  }, // String
  {
    value: 'allocated_work_order_number',
    label: 'Allocated Work Order Number',
    type: 'String',
  }, // String

  {
    value: 'inspection_criteria',
    label: 'Inspection Criteria',
    type: 'String',
  }, // String
  // { value: 'unique_id', label: 'Unique ID', type: 'String' }, // String
  { value: 'tag_number', label: 'Tag Number', type: 'String' }, // String

  {
    value: 'previous_customer_charge_code',
    label: 'Previous Charge Code',
    type: 'previous_customer_charge_code',
    ref: 'originalCharge',
  },
  // To implement
];
