import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  AppBar,
  Toolbar as MaterialToolbar,
  IconButton,
  Tooltip,
  Breadcrumbs,
  Link,
  Typography,
  Box,
  Badge,
  withStyles,
  useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import {
  ViewListOutlined as ListIcon,
  ViewComfyOutlined as GridIcon,
  CreateNewFolderOutlined as NewFolderIcon,
  CloudUploadOutlined as UploadIcon,
  DeleteOutline as DeleteIcon,
} from '@material-ui/icons';
import { FiSidebar as BarIcon } from 'react-icons/fi';
import Alert from '../../components/Alert';
import { deleteEntries } from '../../redux/actions/vault';

const StyledBadge = withStyles((theme) => ({
  badge: {
    width: 15,
    height: 15,
    minWidth: 15,
    fontSize: '.65rem',
  },
}))(Badge);

function Toolbar({
  showSidebar,
  listView,
  toggleListView,
  toggleSidebar,
  path = [],
  directoryName,
  onCreateFolder,
  onUploadClick,
  selectedEntries = [],
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  function handleDelete() {
    Alert(
      'Are you sure?',
      'Do you really want to delete the selected files? This action cannot be undone.',
      undefined,
      undefined,
      'Yes, delete it.'
    ).then((confirm) => {
      if (!confirm) return;

      dispatch(deleteEntries(selectedEntries));
    });
  }

  return (
    <AppBar
      style={{ gridColumnStart: 1, gridColumnEnd: 3 }}
      position="sticky"
      color="default"
    >
      <MaterialToolbar>
        {!smallScreen && (
          <Tooltip title={`${showSidebar ? 'Hide' : 'Show'} sidebar`}>
            <IconButton onClick={toggleSidebar}>
              <StyledBadge
                overlap="circle"
                color="secondary"
                badgeContent={showSidebar ? 'X' : 0}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
              >
                <BarIcon />
              </StyledBadge>
            </IconButton>
          </Tooltip>
        )}
        <Breadcrumbs component={Box} display="flex" flex="1">
          {path?.map(
            (dir, index) =>
              index && (
                <Link
                  key={dir._id}
                  style={{ cursor: 'pointer' }}
                  onClick={() => history.push(`/vault/${dir._id}`)}
                >
                  {dir.name}
                </Link>
              )
          )}
          <Typography color="textPrimary">{directoryName}</Typography>
        </Breadcrumbs>
        {selectedEntries.length > 0 && (
          <Tooltip title="Delete Items ">
            <IconButton color="secondary" onClick={handleDelete}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title="Upload files ">
          <IconButton onClick={onUploadClick}>
            <UploadIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="New folder ">
          <IconButton onClick={onCreateFolder}>
            <NewFolderIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={`${listView ? 'Grid' : 'List'} view`}>
          <IconButton onClick={toggleListView}>
            {listView ? <GridIcon /> : <ListIcon />}
          </IconButton>
        </Tooltip>
      </MaterialToolbar>
    </AppBar>
  );
}

export default Toolbar;
