import {
  FETCH_WORK_ORDERS_SUCCESS,
  FETCH_WORK_ORDERS_ERROR,
  GET_WORK_ORDERS,
  updateWorkOrders,
  showSpinner,
  hideSpinner,
} from '../actions/workOrders';
import { apiRequest } from '../actions/api';

const URL = 'work_orders';

const params = [
  'project',
  'charge_code',
  'rina_work_order_no',
  'rina_job_number',
  'well',
  'activity',
  'supplier_release',
  'job_status',
  'from_date',
  'to_date',
  'customer',
];

export const getWorkOrdersFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_WORK_ORDERS) {
    dispatch(
      apiRequest(
        'GET',
        URL,
        action.payload,
        FETCH_WORK_ORDERS_SUCCESS,
        FETCH_WORK_ORDERS_ERROR,
        params,
      ),
    );
    dispatch(showSpinner());
  }
};

export const processWorkOrdersCollection = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_WORK_ORDERS_SUCCESS) {
    dispatch(updateWorkOrders(action.payload));
    dispatch(hideSpinner());
  }
};

export const workOrdersMdl = [getWorkOrdersFlow, processWorkOrdersCollection];
