import {
  UPDATE_WELL_SUCCESS,
  UPDATE_WELL_ERROR,
  FETCH_WELL_SUCCESS,
  FETCH_WELL_ERROR,
  GET_WELL,
  CREATE_WELL,
  UPDATE_WELL,
  updateObjectWell,
  showSpinner,
  hideSpinner,
  hideModal,
} from '../actions/well';
import { getWells } from '../actions/wells';
import Toastify from '../../utils/Toastify/index';
import { apiRequest } from '../actions/api';
import { CUSTOMER_KEY } from '../../config/consts';

const URL = 'wells';

export const createWellFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === CREATE_WELL) {
    dispatch(
      apiRequest(
        'POST',
        URL,
        action.payload,
        UPDATE_WELL_SUCCESS,
        UPDATE_WELL_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const updateWellFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === UPDATE_WELL) {
    dispatch(
      apiRequest(
        'PUT',
        URL,
        action.payload,
        UPDATE_WELL_SUCCESS,
        UPDATE_WELL_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const getWellFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_WELL) {
    const { _id } = action.payload;
    dispatch(
      apiRequest('GET-id', URL, { _id }, FETCH_WELL_SUCCESS, FETCH_WELL_ERROR),
    );
    dispatch(showSpinner());
  }
};

export const processWellCollection = ({ dispatch, getState }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_WELL_SUCCESS) {
    dispatch(updateObjectWell(action.payload));
    dispatch(hideSpinner());
  } else if (action.type === UPDATE_WELL_SUCCESS) {
    dispatch(updateObjectWell(action.payload));
    dispatch(hideModal());
    dispatch(hideSpinner());
    // To reload the page
    const { page, limit } = getState().wells;
    dispatch(
      getWells({
        page,
        limit,
        filter: { customer: localStorage.getItem(CUSTOMER_KEY) },
      }),
    );
    Toastify.addSuccess('Well saved.');
  } else if (action.type === UPDATE_WELL_ERROR) {
    dispatch(hideSpinner());
    Toastify.addError(action.payload || 'Error to save the well');
  }
};

export const wellMdl = [
  createWellFlow,
  updateWellFlow,
  getWellFlow,
  processWellCollection,
];
