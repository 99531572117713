import React from 'react';
import {
  Grid,
  Typography,
  Tooltip,
  TextField,
  IconButton,
  Button,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import PrevIcon from '@material-ui/icons/ArrowBack';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { InputContent, Divider } from '../styles';

function GeneralInfo({ data, onEdit, onPrevStep }) {
  const { date, counting_number, note } = data;

  const handleDate = (name) => (value) => {
    onEdit({ target: { name, value } });
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h6">General Information</Typography>
        <Divider />
      </Grid>
      <Grid lg={6} md={6} sm={12} xs={12}>
        <InputContent>
          <KeyboardDatePicker
            label="Date"
            size="small"
            variant="outlined"
            inputVariant="outlined"
            name="date"
            value={date}
            onChange={handleDate('date')}
            format="MM-dd-yyyy"
            fullWidth
          />
        </InputContent>
      </Grid>
      <Grid lg={6} md={6} sm={12} xs={12}>
        <InputContent>
          <TextField
            size="small"
            label="Counting Number"
            type="text"
            variant="outlined"
            name="counting_number"
            value={counting_number}
            onChange={onEdit}
            fullWidth
            disabled
            InputProps={{
              endAdornment: (
                <Tooltip title="This field is automatically filled when Work Order is posted.">
                  <IconButton size="small" color="primary">
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              ),
            }}
          />
        </InputContent>
      </Grid>
      <Grid item xs={12}>
        <InputContent>
          <TextField
            label="Notes"
            type="text"
            variant="outlined"
            name="note"
            value={note}
            onChange={onEdit}
            fullWidth
            multiline
          />
        </InputContent>
      </Grid>
      <Grid item xs={6}>
        <Button
          startIcon={<PrevIcon />}
          color="secondary"
          style={{ marginTop: '.5rem' }}
          onClick={onPrevStep}
        >
          Change Bin
        </Button>
      </Grid>
    </Grid>
  );
}

export default GeneralInfo;
