export const GET_FABRICATION = '[fabrication] GET';
export const UPDATE_FABRICATION = '[fabrication] UPDATE';
export const CREATE_FABRICATION = '[fabrication] CREATE';
export const DELETE_FABRICATION = '[fabrication] DELETE';
export const UPDATE_OBJECT_FABRICATION = '[fabrication] UPDATE OBJECT FABRICATION';
// UI
export const SHOW_FABRICATION_MODAL = '[fabrication] SHOW MODAL';
export const HIDE_FABRICATION_MODAL = '[fabrication] HIDE MODAL';
// Spinner
export const UPDATE_FABRICATION_SUCCESS = '[fabrication] Update success';
export const UPDATE_FABRICATION_ERROR = '[fabrication] Update Error';
export const FETCH_FABRICATION_SUCCESS = '[fabrication] Fetch success';
export const FETCH_FABRICATION_ERROR = '[fabrication] Fetch Error';
export const SHOW_FABRICATION_SPINNER = '[fabrication - ui] show spinner';
export const HIDE_FABRICATION_SPINNER = '[fabrication - ui] hide spinner';

export const getFabrication = (id) => ({
  type: GET_FABRICATION,
  payload: { _id: id },
});

export const createFabrication = (data) => ({
  type: CREATE_FABRICATION,
  payload: data,
});

export const updateFabrication = (data) => ({
  type: UPDATE_FABRICATION,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_FABRICATION_MODAL,
});

export const hideModal = () => ({
  type: HIDE_FABRICATION_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_FABRICATION_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_FABRICATION_SPINNER,
});

export const updateObjectFabrication = (data) => ({
  type: UPDATE_OBJECT_FABRICATION,
  payload: data,
});
