import { UPDATE_OBJECT_INSPECTION } from '../actions/inspection';

export function inspectionReducer(inspection = {}, action) {
  switch (action.type) {
    case UPDATE_OBJECT_INSPECTION:
      return action.payload;

    default:
      return inspection;
  }
}
