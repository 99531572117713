import { CUSTOMER_KEY } from '../../config/consts';

export const GET_WORK_ORDER = '[workOrder] GET';
export const UPDATE_WORK_ORDER = '[workOrder] UPDATE';
export const CREATE_WORK_ORDER = '[workOrder] CREATE';
export const DELETE_WORK_ORDER = '[workOrder] DELETE';
export const UPDATE_OBJECT_WORK_ORDER = '[workOrder] UPDATE OBJECT WORK_ORDER';
export const UPLOAD_FILE = '[workOrder] UPLOAD FILE';
export const SUCCESS_UPLOAD_FILE = '[workOrder] SUCCESS UPLOAD FILE';
export const ERROR_UPLOAD_FILE = '[workOrder] ERROR UPLOAD FILE';
// UI
// export const SHOW_WORK_ORDER_MODAL = '[workOrder] SHOW MODAL';
// export const HIDE_WORK_ORDER_MODAL = '[workOrder] HIDE MODAL';
// Spinner
export const UPDATE_WORK_ORDER_SUCCESS = '[workOrder] Update success';
export const UPDATE_WORK_ORDER_ERROR = '[workOrder] Update Error';
export const FETCH_WORK_ORDER_SUCCESS = '[workOrder] Fetch success';
export const FETCH_WORK_ORDER_ERROR = '[workOrder] Fetch Error';
export const SHOW_WORK_ORDER_SPINNER = '[workOrder - ui] show spinner';
export const HIDE_WORK_ORDER_SPINNER = '[workOrder - ui] hide spinner';

export const getWorkOrder = (id) => ({
  type: GET_WORK_ORDER,
  payload: { _id: id },
});

export const createWorkOrder = (data) => ({
  type: CREATE_WORK_ORDER,
  payload: { ...data, customer: localStorage.getItem(CUSTOMER_KEY) },
});

export const updateWorkOrder = (data) => ({
  type: UPDATE_WORK_ORDER,
  payload: data,
});

export const uploadFile = (data) => ({
  type: UPLOAD_FILE,
  payload: data,
});

// export const showModal = () => ({
//   type: SHOW_WORK_ORDER_MODAL,
// });

// export const hideModal = () => ({
//   type: HIDE_WORK_ORDER_MODAL,
// });

export const showSpinner = () => ({
  type: SHOW_WORK_ORDER_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_WORK_ORDER_SPINNER,
});

export const updateObjectWorkOrder = (data) => ({
  type: UPDATE_OBJECT_WORK_ORDER,
  payload: data,
});
