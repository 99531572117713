import {
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_ERROR,
  FETCH_PROJECT_SUCCESS,
  FETCH_PROJECT_ERROR,
  GET_PROJECT,
  CREATE_PROJECT,
  UPDATE_PROJECT,
  updateObjectProject,
  showSpinner,
  hideSpinner,
  hideModal,
} from '../actions/project';
import { getProjects } from '../actions/projects';
import Toastify from '../../utils/Toastify/index';
import { apiRequest } from '../actions/api';

const URL = 'projects';

export const createProjectFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === CREATE_PROJECT) {
    dispatch(
      apiRequest(
        'POST',
        URL,
        action.payload,
        UPDATE_PROJECT_SUCCESS,
        UPDATE_PROJECT_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const updateProjectFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === UPDATE_PROJECT) {
    dispatch(
      apiRequest(
        'PUT',
        URL,
        action.payload,
        UPDATE_PROJECT_SUCCESS,
        UPDATE_PROJECT_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const getProjectFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_PROJECT) {
    const { _id } = action.payload;
    dispatch(
      apiRequest(
        'GET-id',
        URL,
        { _id },
        FETCH_PROJECT_SUCCESS,
        FETCH_PROJECT_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const processProjectCollection = ({ dispatch, getState }) => (next) => (
  action,
) => {
  next(action);
  if (action.type === FETCH_PROJECT_SUCCESS) {
    dispatch(updateObjectProject(action.payload));
    dispatch(hideSpinner());
  } else if (action.type === UPDATE_PROJECT_SUCCESS) {
    dispatch(updateObjectProject(action.payload));
    dispatch(hideModal());
    dispatch(hideSpinner());
    // To reload the page
    const { page, limit } = getState().projects;
    dispatch(getProjects({ page, limit }));
    Toastify.addSuccess('Project saved.');
  } else if (action.type === UPDATE_PROJECT_ERROR) {
    dispatch(hideSpinner());
    Toastify.addError(action.payload || 'Error to save the project');
  }
};

export const projectMdl = [
  createProjectFlow,
  updateProjectFlow,
  getProjectFlow,
  processProjectCollection,
];
