/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Button, Grid, IconButton } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { ArrowBack } from '@material-ui/icons';
import axios from 'axios';
import money from 'currency.js';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import Alert from '../../../components/Alert/index';
import Spinner from '../../../components/SimpleSpinner/index';
import { apiUrl, CUSTOMER_KEY } from '../../../config/consts';
import { addToInventoryCart } from '../../../redux/actions/inventory_cart';
import {
  createMovimentation,
  getMovimentation,
  updateMovimentation,
} from '../../../redux/actions/movimentation';
import toast from '../../../utils/Toastify';
import {
  CustomerSpecificData,
  FinancialData,
  MaterialMasterData,
  OwnershipAndAllocationData,
  POAndHistoricalData,
  RINAAndQAQCData,
  StorageAndAssemblyData,
  TechnicalData,
  Summary,
} from './components';
import NoteModal from './NoteModal';
import {
  ButtonContainer,
  Container,
  Form,
  InputContent,
  TabSheet,
  Title,
} from './styles';
import { getSLOCS } from '../../../redux/actions/slocs';

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const modifier = {
  A: 1,
  B: 1,
  BR: 1,
  AR: 0.5,
  C: 0.5,
  CR: 0.5,
  E: 0,
};

const TabList = [
  'Material Master Data',
  'Equipment Technical Data',
  'PO and Historical Data',
  'Ownership and Allocation Data',
  'Storage and Assembly Data',
  'RINA and QA/QC Data',
  'Customer Specific Data',
  'Financial Data',
];

const badgeMap = {
  'Material Master Data': [],
  'Equipment Technical Data': ['classification', 'material_layout'],
  'PO and Historical Data': [],
  'Ownership and Allocation Data': ['customer'],
  'Storage and Assembly Data': ['SLOC'],
  'RINA and QA/QC Data': [],
  'Customer Specific Data': ['customer_MM'],
  'Financial Data': [],
};

const TabComponents = [
  MaterialMasterData,
  TechnicalData,
  POAndHistoricalData,
  OwnershipAndAllocationData,
  StorageAndAssemblyData,
  RINAAndQAQCData,
  CustomerSpecificData,
  FinancialData,
];

const EquipmentForm = ({
  dispatch,
  movimentation,
  movimentationSpinner,
  SLOCs,
  chargeCodes,
  customerMMs,
  loggedUser,
  fabrication,
  onSave,
  onClose,
  motherTube,
}) => {
  const history = useHistory();
  const { id } = useParams();
  const { state: navigationState } = useLocation();
  // To control the fields
  const [blockedField, setBlockedField] = useState(false);

  const [value, setValue] = useState(0);
  const [data, setData] = useState({
    qty: 1,
    footage: '',
    rina_work_order_no: '',
    customer_MM: '',
    customer: localStorage.getItem(CUSTOMER_KEY),
    batch_no: '',
    original_batch_no: '',
    RBW: '',
    heat: '',
    joint_no: '',
    SLOC: '',
    facility_reference_id: '',
    bin_location: '',
    rack_location: '',
    warehouse_location: '',
    customer_po_no: '',
    manufacturer: '',
    individual_item_id: '',
    legacy_tag_id: '',
    parent_assembly: '',
    assembly_id: '',
    tally_id: '',
    parent_container: '',
    container_id: '',
    previous_customer_charge_code: null,
    material_layout: 'II',
    associated_equipment_id: '',
    contact: '',
    department: '',
    original_charge: null,
    PO_line_item_no: '',
    manufacturer_no: '',
    divestment_number: '',
    date_last_returned: null,
    date_last_shipped: null,
    rina_return_work_order_no: '',
    reclass_damage_description: '',
    date_placed_in_inventory: new Date(),
    manufacture_date: null,
    expiration_date: null,
    in_service_date: null,
    assigned_customer_charge_code: null,
    plant_code: '',
    last_date_offshore: null,
    last_reconcile_date: null,
    maintenance_routine_interval_days: '',
    maintenance_routine_target_date: null,
    rotation_routine_interval: '',
    rotation_routine_date: null,
    testing_routine_interval: '',
    testing_routine_date: null,
    qty_prime: '',
    qty_damage: '',
    allocated_release_number: '',
    note: '',
    supplier: '',
    supplier_serial_no: '',
    supplier_so_no: '',
    supplier_part_no: '',
    status: 'Available',
    inspection_criteria: '',
    unique_id: '',
    classification: '',
    allocated_batch_number: '',
    allocated_work_order_number: '',
    user: loggedUser?._id,
    country: 'US',
    estimated_delivery_date: null,
    actual_delivery_date: null,
    weight: '',
    customer_batch_no: '',
    purchase_price: null,
    fabrication_costs: null,
    service_value: null,
    service_cost: null,
    transport_value: null,
    transportation_cost: null,
    handling_value: null,
    handling_cost: null,
    inspection_value: null,
    inspection_cost: null,
    material_value_plus_services: null,
    price_per_foot_cost: null,
    unit_cost: null,
    current_material_value: null,
  });

  const [errors, setErrors] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [noteModalOpen, setNoteModalOpen] = useState(false);

  // useEffect(() => {
  //   dispatch(getSLOCS({listAll: true}))
  // }, [])

  useEffect(() => {
    if (id !== 'new') {
      dispatch(getMovimentation(id));
    } else {
      setBlockedField(false);
    }
  }, [id]);

  useEffect(() => {
    if (!dataLoaded && Object.keys(data).length) setDataLoaded(true);
  }, [data]);

  useEffect(() => {
    if (isNaN(data.service_value) || isNaN(data.purchase_price) || !dataLoaded)
      return;
    const service_cost = money(data.purchase_price).multiply(
      data.service_value
    ).value;
    setData((currentData) => ({ ...currentData, service_cost }));
  }, [data.service_value, data.purchase_price]);

  useEffect(() => {
    if (
      isNaN(data.transport_value) ||
      isNaN(data.footage) ||
      !customerMMs ||
      !dataLoaded
    )
      return;
    const transportation_cost = money(data.transport_value)
      .multiply(data.footage)
      .multiply(
        customerMMs?.find(({ _id }) => _id === data.customer_MM)?.weight || 0
      ).value;
    setData((currentData) => ({ ...currentData, transportation_cost }));
  }, [data.transport_value, data.footage, data.customer_MM]);

  useEffect(() => {
    if (isNaN(data.handling_value) || isNaN(data.footage) || !dataLoaded)
      return;
    const handling_cost = money(data.handling_value).multiply(
      data.footage
    ).value;
    setData((currentData) => ({ ...currentData, handling_cost }));
  }, [data.handling_value, data.footage]);

  useEffect(() => {
    if (isNaN(data.inspection_value) || isNaN(data.footage) || !dataLoaded)
      return;
    const inspection_cost = money(data.inspection_value).multiply(
      data.footage
    ).value;
    setData((currentData) => ({ ...currentData, inspection_cost }));
  }, [data.inspection_value, data.footage]);

  useEffect(() => {
    if (!dataLoaded) return;
    const material_value_plus_services = money(
      isNaN(data.purchase_price) ? 0 : data.purchase_price
    )
      .add(money(isNaN(data.fabrication_costs) ? 0 : data.fabrication_costs))
      .add(isNaN(data.service_cost) ? 0 : money(data.service_cost))
      .add(
        isNaN(data.transportation_cost) ? 0 : money(data.transportation_cost)
      )
      .add(isNaN(data.handling_cost) ? 0 : money(data.handling_cost))
      .add(isNaN(data.inspection_cost) ? 0 : money(data.inspection_cost)).value;
    setData((currentData) => ({
      ...currentData,
      material_value_plus_services,
    }));
  }, [
    data.purchase_price,
    data.fabrication_costs,
    data.service_cost,
    data.transportation_cost,
    data.handling_cost,
    data.inspection_cost,
  ]);

  useEffect(() => {
    if (
      isNaN(data.material_value_plus_services) ||
      !data.footage ||
      !dataLoaded
    )
      return;
    const price_per_foot_cost = money(data.material_value_plus_services).divide(
      data.footage
    ).value;
    setData((currentData) => ({ ...currentData, price_per_foot_cost }));
  }, [data.material_value_plus_services, data.footage]);

  useEffect(() => {
    if (isNaN(data.material_value_plus_services) || !data.qty || !dataLoaded)
      return;
    const unit_cost = money(data.material_value_plus_services).divide(
      data.qty
    ).value;
    setData((currentData) => ({ ...currentData, unit_cost }));
  }, [data.material_value_plus_services, data.qty]);

  useEffect(() => {
    if (
      isNaN(data.material_value_plus_services) ||
      !data.classification ||
      !dataLoaded
    )
      return;
    const current_material_value = money(
      data.material_value_plus_services
    ).multiply(modifier[data.classification]).value;
    setData((currentData) => ({ ...currentData, current_material_value }));
  }, [data.material_value_plus_services, data.classification]);

  function handleAddToCart(idList) {
    function handler() {
      dispatch(addToInventoryCart(loggedUser._id, idList));
    }
    return handler;
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleGoToBOM = async () => {
    const response = await Alert(
      undefined,
      'Do you want to create and set as parent to the selected equipment?'
    );
    if (response && response.confirm) {
      axios
        .post(`${apiUrl}movimentations`, data)
        .then((result) => {
          toast.addSuccess('Equipment created successfully.');
          axios
            .put(`${apiUrl}build-of-material/change-parent`, {
              movimentations: navigationState.movimentations,
              parent: result.data[0]?._id,
              inventory: localStorage.getItem(CUSTOMER_KEY),
              user: loggedUser._id,
            })
            .then(() => {
              toast.addSuccess('Equipment added to hierarchy successfully.');
              history.replace('/build_of_material/new', {
                parents: [result.data[0]?._id],
                movimentations: navigationState.movimentations?.map((mov) =>
                  mov._id ? mov._id : mov
                ),
              });
            })
            .catch((error) => {
              toast.addError(
                error?.response?.data?.error ||
                  error?.response?.data?.message ||
                  error?.message
              );
            });
        })
        .catch((error) => {
          toast.addError(
            error?.response?.data?.error ||
              error?.response?.data?.message ||
              error?.message
          );
        });
    }
  };

  useEffect(() => {
    if (movimentation && id !== 'new') {
      // Equipment fields
      setData({
        ...movimentation,
        SLOC: movimentation.SLOC?._id || movimentation.SLOC,
        warehouse_location:
          movimentation.warehouse_location?._id ||
          movimentation.warehouse_location,
        rack_location:
          movimentation.rack_location?._id || movimentation.rack_location,
        bin_location:
          movimentation.bin_location?._id || movimentation.bin_location,
        customer_MM:
          movimentation.customer_MM?._id || movimentation.customer_MM,
        previous_customer_charge_code:
          movimentation.previous_customer_charge_code?._id ||
          movimentation.previous_customer_charge_code,
        original_charge:
          movimentation.original_charge?._id ||
          movimentation.previous_customer_charge_code,
        assigned_customer_charge_code:
          movimentation.assigned_customer_charge_code?._id ||
          movimentation.previous_customer_charge_code,
        allocated_charge_code:
          movimentation.allocated_charge_code?._id ||
          movimentation.previous_customer_charge_code,
      });
    }
  }, [movimentation]);

  const handleData = (fieldsToUpdate) =>
    setData((currentData) => ({ ...currentData, ...fieldsToUpdate }));

  const handleSave = async (e) => {
    e.preventDefault();
    const currentErrors = [];

    if (!data.material_layout) currentErrors.push('material_layout');
    if (!data.classification) currentErrors.push('classification');
    if (!data.customer) currentErrors.push('customer');
    if (!data.customer_MM) currentErrors.push('customer_MM');
    if (!data.SLOC) currentErrors.push('SLOC');
    if (!data.status) currentErrors.push('status');

    setErrors(currentErrors);

    if (currentErrors.length) return;

    if (navigationState && navigationState.creatingBOM) {
      return handleGoToBOM();
    }
    if (!fabrication && id !== 'new') return setNoteModalOpen(true);
    const response = await Alert();
    if (response && response.confirm) {
      if (fabrication) {
        return onSave({
          ...data,
          customer_MM: customerMMs.find(({ _id }) => _id === data.customer_MM),
          SLOC: SLOCs.find(({ _id }) => _id === data.SLOC),
          previous_customer_charge_code: chargeCodes.find(
            ({ _id }) => _id === data.previous_customer_charge_code
          ),
          original_charge: chargeCodes.find(
            ({ _id }) => _id === data.original_charge
          ),
          assigned_customer_charge_code: chargeCodes.find(
            ({ _id }) => _id === data.assigned_customer_charge_code
          ),
        });
      }
      if (id === 'new') {
        dispatch(createMovimentation(data));
      }
    }
  };

  const handleBack = () => {
    history.goBack();
  };

  const styles = {
    headerContent: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      paddingBottom: 15,
      position: 'relative',
    },
    hierarchyMenu: {
      position: 'absolute',
      right: 30,
      top: 5,
    },
  };

  const handleChangeField = (e) => {
    const { name } = e.target;
    let { value } = e.target;
    if (name === 'service_value') {
      value /= 100;
    }
    handleData({ [name]: value });
  };

  function handleDateChange(name) {
    return function (value) {
      handleData({ [name]: value });
    };
  }

  function handleCloseNoteModal() {
    setNoteModalOpen(false);
  }

  async function handleSubmitNote() {
    const response = await Alert();
    if (response && response.confirm) {
      dispatch(updateMovimentation(data));
    }
    setNoteModalOpen(false);
  }

  const handleLocation = (e) => {
    const { value, name } = e.target;
    const setMap = {
      SLOC: 'SLOC',
      bin: 'bin_location',
      rack: 'rack_location',
      warehouse: 'warehouse_location',
    };
    const toUpdate = { [setMap[name]]: value };

    if (name === 'bin') {
      handleData(toUpdate);
      return;
    }
    toUpdate.bin_location = '';
    if (name === 'rack') {
      handleData(toUpdate);
      return;
    }
    toUpdate.rack_location = '';
    if (name === 'warehouse') {
      handleData(toUpdate);
      return;
    }
    toUpdate.warehouse_location = '';
    handleData(toUpdate);
  };

  return (
    <Container fabrication={fabrication}>
      <NoteModal
        open={noteModalOpen}
        onClose={handleCloseNoteModal}
        data={data}
        onChange={handleChangeField}
        onSubmit={handleSubmitNote}
      />
      <Form onSubmit={handleSave}>
        {movimentationSpinner && <Spinner />}
        <Grid
          container
          className="headerContainer"
          style={{ opacity: movimentationSpinner ? 0.3 : 1 }}
        >
          <Grid lg={12} md={12} sm={12} xs={12} style={styles.headerContent}>
            <IconButton onClick={fabrication ? onClose : handleBack}>
              <ArrowBack />
            </IconButton>
            <Title>
              {id === 'new' ? 'New' : 'Edit'} Equipment{' '}
              {fabrication && `from ${motherTube}`}
            </Title>
            {id !== 'new' && !fabrication && (
              <Button
                onClick={() => history.push(`/breakoutdata/${id}`)}
                variant="contained"
                color="default"
                style={styles.hierarchyMenu}
              >
                Breakout Data
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid container style={{ opacity: movimentationSpinner ? 0.3 : 1 }}>
          <Summary data={data} onChange={handleChangeField} errors={errors} />
        </Grid>
        <TabSheet>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
            >
              {TabList.map((label, index) => (
                <Tab
                  key={label}
                  label={
                    <Badge
                      color="secondary"
                      badgeContent={
                        badgeMap[label].filter((field) =>
                          errors.includes(field)
                        ).length
                      }
                    >
                      {label}
                    </Badge>
                  }
                  {...a11yProps(index)}
                />
              ))}
            </Tabs>
          </AppBar>

          <SwipeableViews
            axis="x"
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            {TabComponents.map((Component, index) => (
              <Component
                data={data}
                selectedTab={value}
                tabIndex={index}
                onChange={handleChangeField}
                onChangeDate={handleDateChange}
                setData={handleData}
                errors={errors}
              />
            ))}
          </SwipeableViews>
        </TabSheet>
        <Grid container style={{ opacity: movimentationSpinner ? 0.3 : 1 }}>
          <Grid lg={12} md={12} sm={12} xs={12}>
            <ButtonContainer>
              {fabrication ? (
                <>
                  <Button
                    color="secondary"
                    onClick={onClose}
                    variant="contained"
                    style={{ marginRight: 5 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    type="submit"
                    variant="contained"
                    style={{ marginRight: 5 }}
                  >
                    Confirm
                  </Button>
                </>
              ) : (
                <>
                  {!blockedField && (
                    <Button
                      color="secondary"
                      onClick={handleBack}
                      variant="contained"
                      style={{ marginRight: 5 }}
                    >
                      Cancel
                    </Button>
                  )}
                  {!blockedField && (
                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                      style={{ marginRight: 5 }}
                    >
                      Save
                    </Button>
                  )}
                  {!blockedField && (
                    <Button
                      color="primary"
                      variant="contained"
                      style={{ marginleft: 5 }}
                      disabled={id === 'new'}
                      onClick={handleAddToCart([id])}
                    >
                      Add to Cart
                    </Button>
                  )}
                  {blockedField && (
                    <Button
                      color="secondary"
                      onClick={() => history.goBack()}
                      variant="contained"
                      style={{ marginRight: 5 }}
                    >
                      Back
                    </Button>
                  )}
                </>
              )}
            </ButtonContainer>
          </Grid>
        </Grid>
      </Form>
    </Container>
  );
};

export default connect((state) => ({
  movimentation: state.movimentation,
  movimentationSpinner: state.ui.movimentationLoading,
  customers: state.customers?.docs || state.customers,
  loggedUser: state.loggedUser,
  owners: state.owners?.docs || state.owners,
  customerMMs: state.customerMMs?.docs || state.customerMMs || [],
  SLOCs: state.slocs?.docs || state.slocs || [],
  chargeCodes: state.originalCharges?.docs || state.originalCharges || []
}))(EquipmentForm);
