import { UPDATE_OBJECT_BUILD_OF_MATERIAL } from '../actions/movimentation';

export function BOMParentsReducer(BOMParents = [], action) {
  switch (action.type) {
    case UPDATE_OBJECT_BUILD_OF_MATERIAL:
      return action.payload;

    default:
      return BOMParents;
  }
}
