import { UPDATE_OWNERS } from '../actions/owners';

const initState = {
  docs: [],
  page: 1,
  limit: 10,
  totalDocs: 0,
};

export function ownersReducer(owners = initState, action) {
  switch (action.type) {
    case UPDATE_OWNERS:
      return action.payload;

    default:
      return owners;
  }
}
