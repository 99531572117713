import React, { useEffect } from 'react';
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Icon,
  IconButton,
  Tooltip,
  Checkbox,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';

function ServiceTable({
  allSelected: AllSelectedTrigger,
  onOpenModalRejection,
  equipment,
  onApprove,
  services,
  equipmentService = [],
  isSelected,
  onSelectChange,
}) {
  const { id } = useParams();
  const servicesToRender = services.filter(
    (service) =>
      !equipmentService.some(
        ({ service: svc, equipment: eqp }) =>
          svc === service && equipment._id === eqp._id
      )
  );

  const allSelected = () =>
    servicesToRender.length &&
    servicesToRender.every((service) => isSelected({ service, equipment }));
  const selected = () =>
    servicesToRender.filter((service) => isSelected({ service, equipment }));

  const handleSelectAll = () => {
    const selectedItems = selected();
    if (!selectedItems.length)
      servicesToRender.forEach((service) =>
        onSelectChange({ service, equipment })()
      );
    selectedItems.forEach((service) =>
      onSelectChange({ service, equipment })()
    );
  };

  useEffect(() => {
    if(AllSelectedTrigger !== undefined) {
      handleSelectAll();
    }
  }, [AllSelectedTrigger])

  return (
    <Box marginBottom={4}>
      <Typography variant="h6">Services</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Checkbox checked={allSelected()} onChange={handleSelectAll} />
            </TableCell>
            <TableCell>Service</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!servicesToRender.length && (
            <TableRow>
              <TableCell colSpan={3} align="center">
                No pending services
              </TableCell>
            </TableRow>
          )}
          {servicesToRender.map((service) => (
            <TableRow key={service}>
              <TableCell>
                <Checkbox
                  checked={isSelected({ service, equipment })}
                  onChange={onSelectChange({ service, equipment })}
                />
              </TableCell>
              <TableCell>{service}</TableCell>
              <TableCell>
                <Tooltip title="Approve">
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={onApprove(equipment, service)}
                  >
                    <Icon>check</Icon>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Reject">
                  <IconButton
                    size="small"
                    color="secondary"
                    onClick={onOpenModalRejection(equipment, service)}
                  >
                    <Icon>close</Icon>
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}

export default ServiceTable;
