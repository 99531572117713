import { UPDATE_OBJECT_ALLOCATE } from '../actions/allocate';

export function allocateReducer(allocate = {}, action) {
  switch (action.type) {
    case UPDATE_OBJECT_ALLOCATE:
      return action.payload;

    default:
      return allocate;
  }
}
