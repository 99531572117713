export const GET_NOTIFICATIONS = '[notifications] GET';
export const FETCH_NOTIFICATIONS_SUCCESS = '[notifications] Fetch success';
export const FETCH_NOTIFICATIONS_ERROR = '[notifications] Fetch Error';
export const UPDATE_NOTIFICATIONS = '[notifications] Update';
export const SHOW_NOTIFICATIONS_SPINNER = '[notifications - ui] show spinner';
export const HIDE_NOTIFICATIONS_SPINNER = '[notifications - ui] hide spinner';

export const getNotifications = (filter) => ({
  type: GET_NOTIFICATIONS,
  payload: filter,
});

export const updateNotifications = (data) => ({
  type: UPDATE_NOTIFICATIONS,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_NOTIFICATIONS_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_NOTIFICATIONS_SPINNER,
});
