export const CREATE_RESULT_LAYOUT = '[RESULT LAYOUT] CREATE';
export const SUCCESS_CREATE_RESULT_LAYOUT = '[RESULT LAYOUT] SUCCESS_CREATE';
export const ERROR_CREATE_RESULT_LAYOUT = '[RESULT LAYOUT] ERROR_CREATE';
export const UPDATE_RESULT_LAYOUT = '[RESULT LAYOUT] UPDATE';
export const GET_RESULT_LAYOUT = '[RESULT LAYOUT] GET';
export const SUCCESS_GET_RESULT_LAYOUT = '[RESULT LAYOUT] SUCCESS_GET';
export const ERROR_GET_RESULT_LAYOUT = '[RESULT LAYOUT] ERROR_GET';
export const SHOW_RESULT_LAYOUT_MODAL = '[RESULT LAYOUT] SHOW MODAL';
export const HIDE_RESULT_LAYOUT_MODAL = '[RESULT LAYOUT] HIDE MODAL';
export const UPDATE_RESULT_LAYOUT_OBJECT = '[RESULT LAYOUT] UPDATE OBJECT';

export function createResultLayout(payload) {
  return { type: CREATE_RESULT_LAYOUT, payload };
}
export function updateResultLayout(payload) {
  return { type: UPDATE_RESULT_LAYOUT, payload };
}
export function getResultLayout(payload) {
  return { type: GET_RESULT_LAYOUT, payload: { _id: payload } };
}
export function showResultLayoutModal() {
  return { type: SHOW_RESULT_LAYOUT_MODAL };
}
export function hideResultLayoutModal() {
  return { type: HIDE_RESULT_LAYOUT_MODAL };
}
export function updateResultLayoutObject(payload) {
  return { type: UPDATE_RESULT_LAYOUT_OBJECT, payload };
}
