import { UPDATE_OBJECT_BUSINESS_UNIT } from '../actions/businessUnit';

export function businessUnitReducer(businessUnit = {}, action) {
  switch (action.type) {
    case UPDATE_OBJECT_BUSINESS_UNIT:
      return action.payload;

    default:
      return businessUnit;
  }
}
