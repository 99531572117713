import React from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Tooltip,
  IconButton,
  withStyles,
  CircularProgress,
} from '@material-ui/core';
import {
  FaFolder as ClosedIcon,
  FaFolderOpen as OpenIcon,
} from 'react-icons/fa';

import { getDirectoryStructure } from '../../redux/actions/vault';

const Text = withStyles(() => ({
  primary: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))(ListItemText);

function TreeItem({ dispatch, directoryStructureSpinner = [], entry, path }) {
  const { directoryId } = useParams();
  const [open, setOpen] = React.useState(false);
  const history = useHistory();

  React.useEffect(() => {
    if (!path || !directoryId || open) return;
    setOpen(openState(entry?._id, path, directoryId));
  }, [path, directoryId, entry?._id]);

  React.useEffect(() => {
    if ((entry?.content && entry?.content?.length > 0) || !open) return;
    dispatch(getDirectoryStructure(entry?._id));
  }, [open]);

  function toggle() {
    setOpen((currentState) => !currentState);
  }

  function handleOpen() {
    history.push(`/vault/${entry._id}`);
  }

  function renderIconOrSpinner() {
    const loading =
      !entry?._id || directoryStructureSpinner?.includes(String(entry?._id));
    if (loading)
      return (
        <CircularProgress size="1.125rem" style={{ color: '#0000008a' }} />
      );
    return open ? <OpenIcon /> : <ClosedIcon />;
  }

  return (
    <List>
      <Tooltip title={entry?.name}>
        <ListItem button>
          <ListItemIcon>
            <IconButton size="small" onClick={toggle}>
              {renderIconOrSpinner()}
            </IconButton>
          </ListItemIcon>
          <Text onClick={handleOpen}>{entry?.name}</Text>
        </ListItem>
      </Tooltip>
      <Collapse style={{ paddingLeft: 10 }} in={open}>
        {entry?.content?.map((child) => (
          <ConnectedTreeItem key={child._id} entry={child} path={path} />
        ))}
      </Collapse>
    </List>
  );
}

function openState(entryId, path, directoryId) {
  if (entryId === directoryId) return true;

  const entryIndexInPath = path?.findIndex(
    ({ _id }) => String(entryId) === String(_id)
  );
  return entryIndexInPath > -1;
}

function mapStateToProps(state) {
  return { directoryStructureSpinner: state?.ui?.directoryStructureSpinner };
}

const ConnectedTreeItem = connect(mapStateToProps)(TreeItem);

export default ConnectedTreeItem;
