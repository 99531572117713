import { ListItemIcon } from '@material-ui/core';
import styled from 'styled-components';

export const ListIcon = styled(ListItemIcon)`
  padding-left: 8px;
  .react-icons {
    font-size: 1.6rem;
  }
`;

export const BlankSpace = styled.div`
  display: flex;
  flex: 1;
`;
