import React, { useState } from 'react';
import { TableRow, TableCell, IconButton, Collapse } from '@material-ui/core';
import { EditRounded as EditIcon } from '@material-ui/icons';
import { tableStyles } from '../../../../styles/tableStyles';
import { CollapseIcon, CollapseCell } from './styles';

function Row({ data, onClick, ChildrenTable, schema = [], childrenKey }) {
  const classes = tableStyles();

  const [expanded, setExpanded] = useState(false);

  const handleClick = () => {
    onClick(data._id);
  };

  const handleToggleCollapse = () => {
    setExpanded((currentState) => !currentState);
  };

  return (
    <>
      <TableRow className={classes.row}>
        <TableCell>
          <IconButton onClick={handleToggleCollapse}>
            <CollapseIcon expanded={expanded} />
          </IconButton>
        </TableCell>
        {schema.map(({ key, bold, postfix = '' }) => {
          const text = data[key] ? data[key] + postfix : '--';
          return (
            <TableCell className={classes.cell}>
              {bold ? <b>{text}</b> : text}
            </TableCell>
          );
        })}
        <TableCell className={classes.cell}>
          <IconButton onClick={handleClick}>
            <EditIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <CollapseCell colSpan={schema.length + 2}>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <ChildrenTable data={data[childrenKey]} parentId={data._id} />
          </Collapse>
        </CollapseCell>
      </TableRow>
    </>
  );
}

export default Row;
