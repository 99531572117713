import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { Container, TitleContent, InputContainer } from './styles';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { getInventoryCart } from '../../redux/actions/inventory_cart';
import { apiUrl, CUSTOMER_KEY } from '../../config/consts';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 600,
    position: 'relative',
    width: '100%',
  },
  button: {
    fontSize: 16,
    color: '#737373',
  },
  listItem: {
    width: '100%',
  },
  listAccordion: {
    padding: '0px 0px 0px 25px',
    height: 39,
    marginTop: -25,
  },
  questionLabel: {
    paddingTop: 2,
    paddingBottom: 2,
    fontSize: '0.8rem',
  },
  listItemQuestion: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderTop: '1px solid #ccc',
  },
  listItemPadding: {
    paddingTop: 4,
    paddingBottom: 4,
  },
  expansionHeader: {},
  questionLabelEmpty: {
    color: '#808080',
    fontSize: '0.8rem',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    fontSize: 10,
    height: 20,
    '& > span': { paddingLeft: 5, paddingRight: 5 },
  },
  formControl: {
    width: '100%',
    padding: 10,
    paddingTop: 0,
    '& > label': { paddingLeft: 10 },
  },
}));

const initialState = {
  equipmentName: null,
  workOrder: '',
  allocatedProject: '',
  chargeCode: '',
  availability: '',
  allocated: null,
};

const InventoryCartFilter = ({ dispatch, loggedUser }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [equipmentData, setEquipmentData] = useState([]);
  const [data, setData] = React.useState(initialState);

  useEffect(() => {
    axios.get(`${apiUrl}cart/${loggedUser._id}/available`).then((response) => {
      const data = response.data.docs;
      const theData = data.map((item) => item);
      setEquipmentData(theData);
    });
  }, []);

  const {
    equipmentName,
    workOrder,
    allocatedProject,
    chargeCode,
    availability,
    allocated,
  } = data;

  function handleEdit(e) {
    const { name, value } = e.target;
    setData((currentData) => ({ ...currentData, [name]: value }));
  }

  function handleCheckbox(e) {
    const { name, checked } = e.target;
    setData((currentData) => ({ ...currentData, [name]: checked }));
  }

  function handleSearch() {
    dispatch(
      getInventoryCart({
        page: 1,
        limit: 50,
        availability: 'available',
        userId: loggedUser._id,
        filter: data,
      })
    );
  }

  function clearFilter() {
    setData(initialState);
    dispatch(
      getInventoryCart({
        page: 1,
        limit: 50,
        availability: 'available',
        userId: loggedUser._id,
        filter: initialState,
      })
    );
  }

  return (
    <Container>
      <Accordion expanded={expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          classes={{ content: classes.expansionHeader }}
          onClick={() => setExpanded(!expanded)}
        >
          <div className={classes.heading}>
            <TitleContent>Inventory Cart Filter</TitleContent>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container style={{ padding: '0px 10px 10px 10px' }}>
            <Grid item lg={3} md={3} sm={12} xs={12}>
              <InputContainer>
                <Autocomplete
                  freeSolo
                  id="size-small-standard"
                  size="small"
                  options={equipmentData.filter(
                    (item) =>
                      item.customer_MM &&
                      item.customer_MM?.short_description !== ''
                  )}
                  getOptionLabel={(option) =>
                    option.customer_MM?.short_description || ''
                  }
                  defaultValue={equipmentName}
                  onInputChange={(event, newValue) => {
                    setData((currentData) => ({
                      ...currentData,
                      equipmentName: newValue,
                    }));
                  }}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setData((currentData) => ({
                        ...currentData,
                        equipmentName:
                          newValue.customer_MM?.short_description || newValue,
                      }));
                    } else {
                      setData((currentData) => ({
                        ...currentData,
                        equipmentName: null,
                      }));
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Equipment Name"
                    />
                  )}
                />
              </InputContainer>
            </Grid>
            <Grid item lg={3} md={3} sm={12} xs={12}>
              <InputContainer>
                <TextField
                  onChange={handleEdit}
                  size="small"
                  name="workOrder"
                  label="Work Order"
                  type="text"
                  variant="outlined"
                  value={workOrder}
                  fullWidth
                />
              </InputContainer>
            </Grid>
            <Grid item lg={3} md={3} sm={12} xs={12}>
              <InputContainer>
                <TextField
                  onChange={handleEdit}
                  size="small"
                  name="allocatedProject"
                  label="Allocated Project"
                  type="text"
                  variant="outlined"
                  value={allocatedProject}
                  fullWidth
                />
              </InputContainer>
            </Grid>
            <Grid item lg={3} md={3} sm={12} xs={12}>
              <InputContainer>
                <TextField
                  onChange={handleEdit}
                  size="small"
                  name="chargeCode"
                  label="Charge Code"
                  type="text"
                  variant="outlined"
                  value={chargeCode}
                  fullWidth
                />
              </InputContainer>
            </Grid>
            <Grid item lg={3} md={3} sm={12} xs={12}>
              <InputContainer>
                <FormControl variant="outlined" size="small" fullWidth>
                  <InputLabel id="select-label">Availability</InputLabel>
                  <Select
                    size="small"
                    labelId="select-label"
                    id="select-label-component"
                    value={availability}
                    variant="outlined"
                    name="availability"
                    fullWidth
                    onChange={handleEdit}
                  >
                    <MenuItem value="Available">Available</MenuItem>
                    <MenuItem value="NotAvailable">Not Available</MenuItem>
                  </Select>
                </FormControl>
              </InputContainer>
            </Grid>
            <Grid item lg={3} md={3} sm={12} xs={12}>
              <InputContainer>
                <FormControlLabel
                  label="Allocated"
                  control={
                    <Checkbox
                      color="primary"
                      onChange={handleCheckbox}
                      checked={allocated}
                      name="allocated"
                    />
                  }
                />
              </InputContainer>
            </Grid>
            <Grid item xs style={{ textAlign: 'end' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSearch}
                style={{ marginRight: 10, cursor: 'pointer' }}
              >
                Filter
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={clearFilter}
                style={{ cursor: 'pointer' }}
              >
                Clear
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  projects: state.projects,
  slocs: state.slocs,
  originalCharges: state.originalCharges,
  movimentations: state.movimentations,
  movimentationsFilter: state.movimentationsFilter,
  loggedUser: state.loggedUser,
});

export default connect(mapStateToProps)(InventoryCartFilter);
