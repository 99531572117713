import {
  GET_BIN,
  UPDATE_BIN,
  UPDATE_OBJECT_BIN,
  CREATE_BIN,
  DELETE_BIN,
} from '../actions/bin';

export function binReducer(bin = {}, action) {
  switch (action.type) {
    case UPDATE_OBJECT_BIN:
      return action.payload;

    default:
      return bin;
  }
}
