import React from 'react';
import { Animated } from 'react-animated-css';
import HubCard from '../../components/HubCard';

import {
  Container,
  Item,
} from './styles';

import logoImage from '../../assets/images/logo.png';
import equalityImage from '../../assets/images/equality.png';

function Hub() {
  return (
    <Animated animationIn="fadeInUp" animationOut="zoomOutDown" animationInDuration={1000} animationOutDuration={1000} isVisible>
      <Container>
        <Item xl={6} lg={6} md={6} sm={12} xs={12}>
          <HubCard company="Ramp" link="/equipments" image={logoImage} color="#00A6CA" />
        </Item>
        <Item xl={6} lg={6} md={6} sm={12} xs={12}>
          <HubCard company="e-Quality" link="https://qicequality.quickbase.com/db/main?a=signin&_c=tyrsjs" image={equalityImage} color="#00A6CA" />
        </Item>
      </Container>
    </Animated>
  );
}

export default Hub;
