import React, { useEffect, useState } from 'react';
import format from 'date-fns/format';
import { useParams, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import {
  CloudDownload as DownloadIcon,
  SearchRounded,
  ExpandMore,
  AddRounded,
} from '@material-ui/icons';
import { getHistory } from '../../redux/actions/history';
import { getMovimentation } from '../../redux/actions/movimentation';
import Spinner from '../../components/Spinner/index';
import { Container, ButtonContainer } from './styles';
import { tableStyles } from '../../styles/tableStyles';
import HistoryFilter from '../../components/HistoryFilter';
import ModalContainer from '../../components/ModalContainer';
import HistoryModal from '../Modals/HistoryModal';
import AddHistoryManually from '../Modals/AddHistoryManually';
import { downloadReport, exportHistory } from '../../services/Download';
import getRINADescription from '../../utils/Equipment/getRINADescription';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  title: {
    fontSize: 20,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  row: {
    padding: 0,
  },
  cell: {
    fontSize: 12,
    padding: '0px 10px 0px 15px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  addButton: {
    position: 'absolute',
    color: 'blue',
    right: 20,
  },
  checkButton: {
    color: 'green',
  },
  closeButton: {
    color: 'red',
  },
}));

const History = ({ dispatch, history, historyLoading, movimentation }) => {
  const classes = tableStyles();
  const browserHistory = useHistory();
  const { id } = useParams();
  const [historyFormated, setHistoryFormated] = useState([]);
  const [modalContentId, setModalContentId] = useState('new');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [servicePerformed, setServicePerformed] = useState('');
  const [equipmentName, setEquipmentName] = useState('');
  const [equipmentParent, setEquipmentParent] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [openAddHistoryModal, setOpenAddHistoryModal] = useState(false);
  const [eventId, setEventId] = useState('new');

  useEffect(() => {
    if (!id) return;
    dispatch(getMovimentation(id));
  }, [id]);

  useEffect(() => {
    dispatch(
      getHistory({ page: page + 1, limit, filter: { movimentation: id } })
    );
  }, [page, limit]);

  useEffect(() => {
    if (history.docs) {
      setHistoryFormated(history.docs);
      setTotal(history.totalDocs);
    }
  }, [history]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEdit = (event) => {
    function handler() {
      switch (event.event) {
        case 'Check-Out':
          browserHistory.push(`/check_out/${event._id}`);
          break;
        case 'Reconcile':
          browserHistory.push(`/reconcile/${event._id}`);
          break;
        case 'Check-In':
          browserHistory.push(`/check_in/${event._id}`);
          break;
        case 'Allocation':
          browserHistory.push(`/allocation/${event._id}`);
          break;
        case 'Transfer of Ownership':
          browserHistory.push(`/transfer_of_ownership/${event._id}`);
          break;
        case 'Reclass':
          browserHistory.push(`/reclass/${event._id}`);
          break;
        case 'Bill of Material':
          browserHistory.push(`/build_of_material/${event._id}`);
          break;
        case 'Fabrication':
          browserHistory.push(`/fabrication/${event._id}`);
          break;
        case 'Added to hierarchy':
          handleModalInformation(
            getRINADescription(
              event.individualData?.movimentation?.customer_MM
            ),

            getRINADescription(event.parent?.customer_MM),
            'added'
          );
          handleOpenModal();
          break;
        case 'Removed from hierarchy':
          handleModalInformation(
            getRINADescription(
              event.individualData?.movimentation?.customer_MM
            ),

            getRINADescription(event.parent?.customer_MM),
            'removed'
          );
          handleOpenModal();
          break;
        case 'Created by user':
          handleOpenAddHistoryModal(event._id);
          break;
        default:
          return;
      }
    }
    return handler;
  };

  function handleOpenModal() {
    setOpenModal(true);
  }

  function handleCloseModal() {
    setOpenModal(false);
  }

  function handleOpenAddHistoryModal(eventIdArg) {
    if (eventIdArg) setEventId(eventIdArg);
    else setEventId('new');
    setOpenAddHistoryModal(true);
  }

  function handleCloseAddHistoryModal() {
    setOpenAddHistoryModal(false);
  }

  function handleModalInformation(equipment, parent, service) {
    setEquipmentName(equipment);
    setEquipmentParent(parent);
    setServicePerformed(service);
  }

  function handleDownload(event) {
    function handler() {
      downloadReport(event._id);
    }
    return handler;
  }

  function handleExport() {
    exportHistory(id);
  }
  return (
    <>
      <ModalContainer open={openModal} handleClose={handleCloseModal}>
        <HistoryModal
          equipmentName={getRINADescription(movimentation?.customer_MM)}
          individualItemId={movimentation?.individual_item_id}
          equipmentParent={equipmentParent}
          title={servicePerformed}
          handleClose={() => {
            setOpenModal(false);
          }}
        />
      </ModalContainer>
      <ModalContainer
        open={openAddHistoryModal}
        handleClose={handleCloseAddHistoryModal}
      >
        <AddHistoryManually
          eventId={eventId}
          handleClose={() => handleCloseAddHistoryModal()}
        />
      </ModalContainer>
      <Container>
        <div style={{ marginBottom: 10 }}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography
                className={classes.title}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                {movimentation?.individual_item_id}'s History
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                spacing={2}
                style={{ padding: '0px 10px 10px 10px' }}
              >
                <Grid item lg={3} md={3} sm={12} xs={12}>
                  <TextField
                    size="small"
                    color="primary"
                    fullWidth
                    variant="outlined"
                    disabled
                    label="Customer MM No."
                    value={movimentation?.customer_MM?.mm_number || ''}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={12} xs={12}>
                  <TextField
                    size="small"
                    color="primary"
                    fullWidth
                    variant="outlined"
                    disabled
                    label="Footage"
                    value={movimentation?.footage || ''}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={12} xs={12}>
                  <TextField
                    size="small"
                    color="primary"
                    fullWidth
                    variant="outlined"
                    disabled
                    label="COPAS Class"
                    value={movimentation?.classification || ''}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={12} xs={12}>
                  <TextField
                    size="small"
                    color="primary"
                    fullWidth
                    variant="outlined"
                    disabled
                    label="Allocated Batch"
                    value={movimentation?.allocated_batch_number || ''}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={12} xs={12}>
                  <TextField
                    size="small"
                    color="primary"
                    fullWidth
                    variant="outlined"
                    disabled
                    label="Supplier Batch No."
                    value={movimentation?.batch_no || ''}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={12} xs={12}>
                  <TextField
                    size="small"
                    color="primary"
                    fullWidth
                    variant="outlined"
                    disabled
                    label="Allocated Project"
                    value={
                      movimentation?.allocated_charge_code?.well?.project
                        ?.name || ''
                    }
                  />
                </Grid>

                <Grid item lg={3} md={3} sm={12} xs={12}>
                  <TextField
                    size="small"
                    color="primary"
                    fullWidth
                    variant="outlined"
                    disabled
                    label="Supplier Part No."
                    value={movimentation?.supplier_part_no || ''}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={12} xs={12}>
                  <TextField
                    size="small"
                    color="primary"
                    fullWidth
                    variant="outlined"
                    disabled
                    label="RAMP Tag No"
                    value={movimentation?.tag_number || ''}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={12} xs={12}>
                  <TextField
                    size="small"
                    color="primary"
                    fullWidth
                    variant="outlined"
                    disabled
                    label="Equipment Type"
                    value={movimentation?.customer_MM?.equipment_type || ''}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={12} xs={12}>
                  <TextField
                    size="small"
                    color="primary"
                    fullWidth
                    variant="outlined"
                    disabled
                    label="Modifier"
                    value={movimentation?.customer_MM?.modifier || ''}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={12} xs={12}>
                  <TextField
                    size="small"
                    color="primary"
                    fullWidth
                    variant="outlined"
                    disabled
                    label="Max OD Size"
                    value={movimentation?.customer_MM?.size || ''}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={12} xs={12}>
                  <TextField
                    size="small"
                    color="primary"
                    fullWidth
                    variant="outlined"
                    disabled
                    label="Tube Weight"
                    value={movimentation?.customer_MM?.weight || ''}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={12} xs={12}>
                  <TextField
                    size="small"
                    color="primary"
                    fullWidth
                    variant="outlined"
                    disabled
                    label="Material Grade"
                    value={movimentation?.customer_MM?.grade || ''}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={12} xs={12}>
                  <TextField
                    size="small"
                    color="primary"
                    fullWidth
                    variant="outlined"
                    disabled
                    label="Connection 1"
                    value={movimentation?.customer_MM?.connection_1 || ''}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={12} xs={12}>
                  <TextField
                    size="small"
                    color="primary"
                    fullWidth
                    variant="outlined"
                    disabled
                    label="Connection 1 (SIZE)"
                    value={movimentation?.customer_MM?.connection_1_size || ''}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={12} xs={12}>
                  <TextField
                    size="small"
                    color="primary"
                    fullWidth
                    variant="outlined"
                    disabled
                    label="Connection 1 (WEIGHT)"
                    value={
                      movimentation?.customer_MM?.connection_1_weight || ''
                    }
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={12} xs={12}>
                  <TextField
                    size="small"
                    color="primary"
                    fullWidth
                    variant="outlined"
                    disabled
                    label="Connection 1 (GRADE)"
                    value={movimentation?.customer_MM?.connection_1_grade || ''}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={12} xs={12}>
                  <TextField
                    size="small"
                    color="primary"
                    fullWidth
                    variant="outlined"
                    disabled
                    label="Connection 2"
                    value={movimentation?.customer_MM?.connection_2 || ''}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={12} xs={12}>
                  <TextField
                    size="small"
                    color="primary"
                    fullWidth
                    variant="outlined"
                    disabled
                    label="Connection 2 (SIZE)"
                    value={movimentation?.customer_MM?.connection_2_size || ''}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={12} xs={12}>
                  <TextField
                    size="small"
                    color="primary"
                    fullWidth
                    variant="outlined"
                    disabled
                    label="Connection 2 (WEIGHT)"
                    value={
                      movimentation?.customer_MM?.connection_2_weight || ''
                    }
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={12} xs={12}>
                  <TextField
                    size="small"
                    color="primary"
                    fullWidth
                    variant="outlined"
                    disabled
                    label="Connection 2 (GRADE)"
                    value={movimentation?.customer_MM?.connection_2_grade || ''}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={12} xs={12}>
                  <TextField
                    size="small"
                    color="primary"
                    fullWidth
                    variant="outlined"
                    disabled
                    label="Connection Configuration"
                    value={
                      movimentation?.customer_MM?.connection_configuration || ''
                    }
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={12} xs={12}>
                  <TextField
                    size="small"
                    color="primary"
                    fullWidth
                    variant="outlined"
                    disabled
                    label="Range"
                    value={movimentation?.customer_MM?.range || ''}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={12} xs={12}>
                  <TextField
                    size="small"
                    color="primary"
                    fullWidth
                    variant="outlined"
                    disabled
                    label="Drift Diameter"
                    value={movimentation?.customer_MM?.drift_diameter || ''}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={12} xs={12}>
                  <TextField
                    size="small"
                    color="primary"
                    fullWidth
                    variant="outlined"
                    disabled
                    label="Nominal Wall"
                    value={movimentation?.customer_MM?.nominal_wall || ''}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={12} xs={12}>
                  <TextField
                    size="small"
                    color="primary"
                    fullWidth
                    variant="outlined"
                    disabled
                    label="Pipe Type"
                    value={movimentation?.customer_MM?.pipe_type || ''}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={12} xs={12}>
                  <TextField
                    size="small"
                    color="primary"
                    fullWidth
                    variant="outlined"
                    disabled
                    label="Assigned Project"
                    value={
                      movimentation?.assigned_customer_charge_code?.project
                        ?.name || ''
                    }
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={12} xs={12}>
                  <TextField
                    size="small"
                    color="primary"
                    fullWidth
                    variant="outlined"
                    disabled
                    label="Assigned Charge Code"
                    value={
                      movimentation?.assigned_customer_charge_code
                        ?.original_charge_code || ''
                    }
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={12} xs={12}>
                  <TextField
                    size="small"
                    color="primary"
                    fullWidth
                    variant="outlined"
                    disabled
                    label="Manufacture"
                    value={movimentation?.manufacturer || ''}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={12} xs={12}>
                  <TextField
                    size="small"
                    color="primary"
                    fullWidth
                    variant="outlined"
                    disabled
                    label="RINA Work Order No."
                    value={movimentation?.rina_work_order_no || ''}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={12} xs={12}>
                  <TextField
                    size="small"
                    color="primary"
                    fullWidth
                    variant="outlined"
                    disabled
                    label="Facility Refrence ID"
                    value={movimentation?.facility_reference_id || ''}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </div>
        <HistoryFilter movimentation={id} />
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <ButtonContainer>
              <Button
                color="secondary"
                startIcon={<AddRounded />}
                style={{ margin: 10 }}
                onClick={() => handleOpenAddHistoryModal()}
              >
                Add History Manually
              </Button>
              <Button
                disabled={historyLoading}
                color="primary"
                onClick={handleExport}
                startIcon={<DownloadIcon />}
              >
                Export History
              </Button>
            </ButtonContainer>
            <TableContainer className={classes.table}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Transaction ID</TableCell>
                    <TableCell>Author</TableCell>
                    <TableCell>Service Performed</TableCell>
                    <TableCell>Total Qty (EA)</TableCell>
                    <TableCell style={{ minWidth: 100 }}>Footage</TableCell>
                    <TableCell>COPAS Class</TableCell>
                    <TableCell>Facility Reference ID</TableCell>
                    <TableCell>Charge Code From</TableCell>
                    <TableCell>Allocated Charge Code</TableCell>
                    <TableCell>Charge Code To</TableCell>
                    <TableCell>SLOC</TableCell>
                    <TableCell>Allocated Batch</TableCell>
                    <TableCell>Batch To</TableCell>
                    <TableCell>Allocated Project</TableCell>
                    <TableCell>Project To</TableCell>
                    <TableCell style={{ minWidth: 200 }}>Notes</TableCell>
                    <TableCell colSpan={2} style={{ textAlign: 'center' }}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {historyLoading && (
                    <TableRow className={classes.row}>
                      <TableCell colSpan="13" className={classes.cell}>
                        <Spinner parent="Table" />
                      </TableCell>
                    </TableRow>
                  )}
                  {!historyLoading &&
                    historyFormated.map((event) => (
                      <TableRow key={event._id} className={classes.row}>
                        <TableCell className={classes.cell}>
                          {format(
                            new Date(event.createdAt),
                            'MM-dd-yyyy HH:mm'
                          )}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {event.transactionId}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {event.user?.name ? event.user.name : '---'}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {event.event}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {event?.individualData
                            ? event?.individualData?.qty || '---'
                            : '---'}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {event?.individualData
                            ? event?.individualData?.footage || '---'
                            : '---'}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {event?.individualData
                            ? event?.individualData?.class || '---'
                            : '---'}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {event.facilityReferenceID || '---'}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {event.individualData?.originalData
                            ?.assigned_customer_charge_code
                            ?.original_charge_code || '---'}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {event.event === 'Allocation'
                            ? event.chargeCode?.original_charge_code || '---'
                            : '---'}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {event.event === 'Transfer of Ownership'
                            ? event.chargeCode?.original_charge_code || '---'
                            : '---'}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {event.SLOC?.sloc_id || '---'}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {event.batchChange || '---'}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {event.batchNumberTo || '---'}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {event.event === 'Allocation'
                            ? event.chargeCode?.well?.project?.name || '---'
                            : '---'}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {event.event === 'Transfer of Ownership'
                            ? event.chargeCode?.well?.project?.name || '---'
                            : '---'}
                        </TableCell>
                        <TableCell className={`${classes.cell} ellipsis`}>
                          <Tooltip title={event.note || '---'}>
                            <span>{event.note || '---'}</span>
                          </Tooltip>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <Tooltip title="Download Report">
                            <IconButton
                              disabled={
                                event.event === 'Added to hierarchy' ||
                                event.event === 'Removed from hierarchy'
                              }
                              color="secondary"
                              onClick={handleDownload(event)}
                            >
                              <DownloadIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <Tooltip title="Details">
                            <IconButton
                              color="primary"
                              onClick={handleEdit(event)}
                            >
                              <SearchRounded />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[2, 5, 10, 25]}
              component="div"
              count={total}
              rowsPerPage={limit}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  historyLoading: state.ui.historyLoading,
  history: state.history,
  movimentation: state.movimentation,
});

export default connect(mapStateToProps)(History);
