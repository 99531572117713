import styled from 'styled-components';
import {
  TableHead as MuiTableHead,
  TableBody as MuiTableBody,
} from '@material-ui/core';

export const TableHead = styled(MuiTableHead)`
  background-color: #ffcdd2;
`;

export const TableBody = styled(MuiTableBody)`
  background-color: #ffebee;
`;
