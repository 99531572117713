import {
  GET_RECLASS,
  UPDATE_RECLASS,
  UPDATE_OBJECT_RECLASS,
  CREATE_RECLASS,
  DELETE_RECLASS,
} from '../actions/reclass';

export function reclassReducer(reclass = {}, action) {
  switch (action.type) {
    case GET_RECLASS:
      return action.payload;
    case UPDATE_RECLASS:
      return action.payload;
    case CREATE_RECLASS:
      return action.payload;
    case DELETE_RECLASS:
      return action.payload;
    case UPDATE_OBJECT_RECLASS:
      return action.payload;

    default:
      return reclass;
  }
}
