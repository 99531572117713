import {
  GET_SERVICE_CATEGORY,
  UPDATE_SERVICE_CATEGORY,
  UPDATE_OBJECT_SERVICE_CATEGORY,
  CREATE_SERVICE_CATEGORY,
  DELETE_SERVICE_CATEGORY,
} from '../actions/serviceCategory';

export function serviceCategoryReducer(serviceCategory = {}, action) {
  switch (action.type) {
    case UPDATE_OBJECT_SERVICE_CATEGORY:
      return action.payload;
    case GET_SERVICE_CATEGORY:
    case UPDATE_SERVICE_CATEGORY:
    case CREATE_SERVICE_CATEGORY:
    case DELETE_SERVICE_CATEGORY:
    default:
      return serviceCategory;
  }
}
