const SEARCH_STATE_STORAGE = '@ramp/searchState';
const MAXIMUM_HISTORY_SIZE = 5;

export const searchFields = [
  'customer',
  'equipment_name',
  'equipment_type',
  'modifier',
  'size',
  'weight',
  'grade',
  'connection_1',
  'connection_1_size',
  'connection_1_weight',
  'connection_1_grade',
  'connection_2',
  'connection_2_size',
  'connection_2_weight',
  'connection_2_grade',
  'connection_configuration',
  'material_layout',
  'SLOC',
  'project',
  'charge_code',
  'supplier',
  'manufacturer',
  'bin_location',
  'rack_location',
  'warehouse_location',
  'department',
  'includeConsumed',
  'status',
  'query',
];

export const SearchFields = {
  customer: 'Customer',
  equipment_name: 'Equipment Name',
  equipment_type: 'Equipment Type',
  modifier: 'Modifier',
  size: 'Max OD Size',
  weight: 'Tube Weight',
  grade: 'Material Grade',
  connection_1: 'Connection 1',
  connection_1_size: 'Connection 1 (SIZE)',
  connection_1_weight: 'Connection 1 (WEIGHT)',
  connection_1_grade: 'Connection 1 (GRADE)',
  connection_2: 'Connection 2',
  connection_2_size: 'Connection 2 (SIZE)',
  connection_2_weight: 'Connection 2 (WEIGHT)',
  connection_2_grade: 'Connection 2 (GRADE)',
  connection_configuration: 'Connection Configuration',
  material_layout: 'Material Layout',
  SLOC: 'SLOC',
  project: 'Assigned Project',
  charge_code: 'Assigned Charge Code',
  supplier: 'Supplier',
  manufacturer: 'Manufacturer',
  bin_location: 'Bin Location',
  rack_location: 'Rack Location',
  warehouse_location: 'Warehouse Location',
  department: 'Item Department',
  includeConsumed: 'Include Consumed',
  status: 'Status',
  query: 'Global Search Query',
};

function generateId(fields) {
  let id = '';
  searchFields.forEach((key) => {
    if (fields[key]) id += `${key}_${fields[key]}/`;
  });
  return id;
}

export function getSearchHistory() {
  const parsedHistory = JSON.parse(localStorage.getItem(SEARCH_STATE_STORAGE));
  return Array.isArray(parsedHistory) ? parsedHistory : [];
}

function doesTheSearchExists(data = [], id) {
  const index = data.findIndex((entry) => entry.id === id);
  return { exists: index > -1, index };
}

function buildFieldData(fields) {
  const data = [];
  searchFields.forEach((key) => {
    if (fields[key]) {
      data.push({
        key,
        value: fields[key],
        label: SearchFields[key],
      });
    }
  });
  return data;
}

function popHistory(data = []) {
  return data.length > 1 ? data.splice(0, data.length - 1) : [];
}

function addAtBeginning(data = [], newData) {
  return [newData, ...data];
}

function buildData(fields, totalResults) {
  const id = generateId(fields);
  const currentData = getSearchHistory();
  const { exists, index } = doesTheSearchExists(currentData, id);

  if (exists) {
    currentData[index] = {
      ...currentData[index],
      date: new Date(),
      totalResults,
    };
    return JSON.stringify(currentData);
  }
  const data = {
    id,
    totalResults,
    fields: buildFieldData(fields),
    date: new Date(),
  };
  if (currentData.length >= MAXIMUM_HISTORY_SIZE) return JSON.stringify(addAtBeginning(popHistory(currentData), data));
  return JSON.stringify(addAtBeginning(currentData, data));
}

export function storeSearchState(fields, totalResults) {
  const newData = buildData(fields, totalResults);
  localStorage.setItem(SEARCH_STATE_STORAGE, newData);
}
