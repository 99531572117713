import {
  UPDATE_MOVIMENTATION_SUCCESS,
  UPDATE_MOVIMENTATION_ERROR,
  FETCH_MOVIMENTATION_SUCCESS,
  FETCH_MOVIMENTATION_ERROR,
  GET_MOVIMENTATION,
  GET_BUILD_OF_MATERIAL,
  CREATE_MOVIMENTATION,
  UPDATE_MOVIMENTATION,
  FETCH_BUILD_OF_MATERIAL_SUCCESS,
  updateObjectMovimentation,
  updateObjectBuildOfMaterial,
  showSpinner,
  hideSpinner,
} from '../actions/movimentation';
import Toastify from '../../utils/Toastify/index';
import history from '../../services/history';
import { apiRequest } from '../actions/api';

const URL = 'movimentations';

export const createMovimentationFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === CREATE_MOVIMENTATION) {
    dispatch(
      apiRequest(
        'POST',
        URL,
        action.payload,
        UPDATE_MOVIMENTATION_SUCCESS,
        UPDATE_MOVIMENTATION_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const updateMovimentationFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === UPDATE_MOVIMENTATION) {
    dispatch(
      apiRequest(
        'PUT',
        URL,
        action.payload,
        UPDATE_MOVIMENTATION_SUCCESS,
        UPDATE_MOVIMENTATION_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const getMovimentationFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_MOVIMENTATION) {
    const { _id } = action.payload;
    dispatch(
      apiRequest(
        'GET-id',
        URL,
        { _id },
        FETCH_MOVIMENTATION_SUCCESS,
        FETCH_MOVIMENTATION_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const getBuildOfMaterialFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_BUILD_OF_MATERIAL) {
    dispatch(
      apiRequest(
        'POST',
        'equipment/build-of-material',
        action.payload,
        FETCH_BUILD_OF_MATERIAL_SUCCESS,
        FETCH_MOVIMENTATION_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const processMovimentationCollection = ({ dispatch, getState }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_MOVIMENTATION_SUCCESS) {
    dispatch(updateObjectMovimentation(action.payload));
    dispatch(hideSpinner());
  } else if (action.type === FETCH_BUILD_OF_MATERIAL_SUCCESS) {
    dispatch(updateObjectBuildOfMaterial(action.payload));
    dispatch(hideSpinner());
  } else if (action.type === UPDATE_MOVIMENTATION_SUCCESS) {
    dispatch(updateObjectMovimentation(action.payload));
    dispatch(hideSpinner());
    history.go(0);
    Toastify.addSuccess('Material uploaded');
  } else if (action.type === UPDATE_MOVIMENTATION_ERROR) {
    dispatch(hideSpinner());
    Toastify.addError(action.payload || 'Error to save the movimentation');
  }
};

export const movimentationMdl = [
  createMovimentationFlow,
  updateMovimentationFlow,
  getMovimentationFlow,
  processMovimentationCollection,
  getBuildOfMaterialFlow,
];
