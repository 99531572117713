import {
  GET_USER, UPDATE_USER, UPDATE_OBJECT_USER, CREATE_USER, DELETE_USER,
} from '../actions/user';

export function userReducer(user = {}, action) {
  switch (action.type) {
    case GET_USER:
      return action.payload;
    case UPDATE_USER:
      return action.payload;
    case CREATE_USER:
      return action.payload;
    case DELETE_USER:
      return action.payload;
    case UPDATE_OBJECT_USER:
      return action.payload;

    default:
      return user;
  }
}
