export const GET_BUSINESS_UNITS = '[business_units] GET';
export const FETCH_BUSINESS_UNITS_SUCCESS = '[business_units] Fetch success';
export const FETCH_BUSINESS_UNITS_ERROR = '[business_units] Fetch Error';
export const UPDATE_BUSINESS_UNITS = '[business_units] Update';
export const SHOW_BUSINESS_UNITS_SPINNER = '[business_units - ui] show spinner';
export const HIDE_BUSINESS_UNITS_SPINNER = '[business_units - ui] hide spinner';

export const getBusinessUnits = (filter) => ({
  type: GET_BUSINESS_UNITS,
  payload: filter,
});

export const updateBusinessUnits = (data) => ({
  type: UPDATE_BUSINESS_UNITS,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_BUSINESS_UNITS_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_BUSINESS_UNITS_SPINNER,
});
