import React, { Fragment } from 'react';
import { Typography, ClickAwayListener, Button } from '@material-ui/core';

import getRINADescription from '../../utils/Equipment/getRINADescription';
import { apiUrl } from '../../config/consts';
import { StyledTooltip } from './styles';

function MaterialMasterTooltip({ children, materialMaster }) {
  const [openTooltip, setOpenTooltip] = React.useState(false);

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleTooltipOpen = () => {
    setOpenTooltip(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <StyledTooltip
          PopperProps={{
            disablePortal: true,
          }}
          interactive
          onClose={handleTooltipClose}
          open={openTooltip}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={
            <Fragment>
              <Typography color="inherit">Material Master Summary</Typography>
              <p>{getRINADescription(materialMaster)}</p>
              <p>
                {materialMaster?.photos?.map((photo) => (
                  <img
                    src={`${apiUrl}file/image/${photo}`}
                    style={{
                      maxHeight: '45%',
                      maxWidth: '45%',
                      margin: 5,
                    }}
                  />
                ))}
              </p>
            </Fragment>
          }
          arrow
        >
          <Button onClick={handleTooltipOpen} color="default">
            {children}
          </Button>
        </StyledTooltip>
      </div>
    </ClickAwayListener>
  );
}

export default MaterialMasterTooltip;
