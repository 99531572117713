import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Spinner from '../../../components/Spinner/index';
import SwipeableViews from 'react-swipeable-views';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Alert from '../../../components/Alert/index';
import { makeStyles } from '@material-ui/core/styles';
import { AddButton } from './styles';
import { Add, Close, EditRounded, Check } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import TabPanel from '../../../components/TabPanel';
import {
  TextField,
  FormGroup,
  Button,
  FormControlLabel,
  Switch,
  Tab,
  Tabs,
  Grid,
} from '@material-ui/core';
import {
  Container,
  ModalTitle,
  ButtonContainer,
  CheckboxContent,
  TabSheet,
  InputContent,
} from './styles';
import {
  getServiceCategory,
  createServiceCategory,
  updateServiceCategory,
} from '../../../redux/actions/serviceCategory';

const styles = {
  formButton: {
    margin: 2,
  },
};

const useClasses = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  title: {
    fontSize: 20,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  row: {
    padding: 0,
  },
  cell: {
    fontSize: 12,
    padding: '0px 10px 0px 15px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  buttons: {
    color: 'blue',
  },
  checkButton: {
    color: 'green',
  },
  closeButton: {
    color: 'red',
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const ServiceCategoryModal = ({
  id,
  dispatch,
  serviceCategory,
  serviceCategoryModal,
  handleClose,
  serviceCategorySpinner,
}) => {
  const [active, setActive] = useState(true);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [value, setValue] = React.useState(0);
  const [services, setServices] = useState([]);
  const classes = useClasses();
  const [editing, setEditing] = React.useState(null);

  useEffect(() => {
    if (id !== 'new') {
      dispatch(getServiceCategory(id));
    }
  }, [id]);

  useEffect(() => {
    if (serviceCategory && id !== 'new') {
      setActive(serviceCategory.active);
      setName(serviceCategory.name);
      setServices(serviceCategory.services);
    }
  }, [serviceCategory]);

  useEffect(() => {
    if (!serviceCategoryModal) {
      handleClose();
    }
  }, [serviceCategoryModal]);

  const handleChange = (_e, newValue) => {
    console.log(newValue);
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleAdd = () => {
    setEditing(services.length);
    setServices((currentServices) => [...currentServices, { name: '' }]);
  };

  const handleEditService = (e) => {
    const { value, name } = e.target;
    setServices((currentServices) => {
      const updatedServices = [...currentServices];
      updatedServices[editing] = { ...updatedServices[editing], [name]: value };
      return updatedServices;
    });
  };

  const handleStartEditing = (index) => {
    function handler() {
      setEditing(index);
    }
    return handler;
  };

  const handleConfirm = () => {
    setEditing(null);
  };

  function handleDelete(index) {
    function handler() {
      Alert(
        'Are you sure?',
        "You won't be able to revert this!",
        false,
        '',
        'Yes, delete it.'
      ).then(({ confirm }) => {
        if (!confirm) return;
        setServices((currentServices) => {
          const updatedServices = [...currentServices];
          updatedServices.splice(index, 1);
          return updatedServices;
        });
        setEditing(null);
      });
    }
    return handler;
  }

  const handleSave = async () => {
    let err = false;
    setNameError(!name);
    err = !name;

    if (!err) {
      const response = await Alert();
      if (response && response.confirm) {
        if (id === 'new') {
          dispatch(
            createServiceCategory({
              active,
              name,
              services,
            })
          );
        } else {
          dispatch(
            updateServiceCategory({
              _id: id,
              active,
              name,
              services,
            })
          );
        }
      }
    }
  };

  return (
    <>
      {serviceCategorySpinner && <Spinner overlay />}
      <Container style={{ opacity: serviceCategorySpinner ? 0.5 : 1 }}>
        <ModalTitle>
          {id === 'new' ? 'New' : 'Edit'} Service Category
        </ModalTitle>
        <TabSheet>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="Category Data" {...a11yProps(0)} />
            <Tab label="Service List" {...a11yProps(1)} />
          </Tabs>

          <SwipeableViews
            axis="x"
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir="ltr">
              <Grid container style={{ opacity: 1 }}>
                <FormGroup>
                  <Grid item xs={12}>
                    <CheckboxContent>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={active}
                            onChange={(e) => setActive(e.target.checked)}
                          />
                        }
                        label="Active"
                        labelPlacement="start"
                      />
                    </CheckboxContent>
                  </Grid>
                  <Grid item xs={12}>
                    <InputContent>
                      <TextField
                        id="outlined-password-input"
                        label="Name"
                        type="text"
                        error={nameError}
                        helperText={nameError && 'Name is required'}
                        variant="outlined"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        fullWidth
                      />
                    </InputContent>
                  </Grid>
                </FormGroup>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1} dir="ltr">
              <AddButton
                variant="contained"
                color="primary"
                size="small"
                onClick={handleAdd}
              >
                <Add /> Add Service
              </AddButton>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Service Name</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {services?.map((service, index) => {
                      return index === editing ? (
                        <TableRow className={classes.row}>
                          <TableCell className={classes.cell}>
                            <InputContent>
                              <TextField
                                label="Name"
                                required
                                type="text"
                                variant="outlined"
                                value={service.name}
                                name="name"
                                onChange={handleEditService}
                                fullWidth
                              />
                            </InputContent>
                          </TableCell>
                          <TableCell className={classes.cell}>
                            <IconButton onClick={handleConfirm} color="primary">
                              <Check />
                            </IconButton>
                            <IconButton
                              onClick={handleDelete(index)}
                              color="secondary"
                            >
                              <Close />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ) : (
                        <TableRow className={classes.row}>
                          <TableCell className={classes.cell}>
                            {service.name}
                          </TableCell>
                          <TableCell className={classes.cell}>
                            <IconButton
                              onClick={handleStartEditing(index)}
                              color="primary"
                            >
                              <EditRounded />
                            </IconButton>
                            <IconButton
                              onClick={handleDelete(index)}
                              color="secondary"
                            >
                              <Close />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>
          </SwipeableViews>
        </TabSheet>

        <ButtonContainer>
          <Button
            color="secondary"
            onClick={handleClose}
            variant="contained"
            style={styles.formButton}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={handleSave}
            variant="contained"
            style={styles.formButton}
          >
            Save
          </Button>
        </ButtonContainer>
      </Container>
    </>
  );
};

export default connect((state) => ({
  serviceCategory: state.serviceCategory,
  serviceCategoryModal: state.ui.serviceCategoryModal,
  serviceCategorySpinner: state.ui.serviceCategoryLoading,
}))(ServiceCategoryModal);
