import {
  FETCH_MAINTENANCES_SUCCESS,
  FETCH_MAINTENANCES_ERROR,
  SEARCH_MAINTENANCES_SUCCESS,
  SEARCH_MAINTENANCES_ERROR,
  GET_MAINTENANCES,
  SEARCH_MAINTENANCES,
  FULL_SEARCH_MAINTENANCES,
  ENABLE_MAINTENANCES_NOTIFICATION,
  DISABLE_MAINTENANCES_NOTIFICATION,
  SUCCESS_DISABLE_MAINTENANCES_NOTIFICATION,
  SUCCESS_ENABLE_MAINTENANCES_NOTIFICATION,
  ERROR_DISABLE_MAINTENANCES_NOTIFICATION,
  ERROR_ENABLE_MAINTENANCES_NOTIFICATION,
  updateMaintenances,
  updateMaintenancesSearch,
  showSpinner,
  hideSpinner,
  showSearchSpinner,
  hideSearchSpinner,
  getMaintenances,
} from '../actions/maintenances';
import Toastify from '../../utils/Toastify';
import { setMaintenanceFilter } from '../actions/maintenanceFilter';
import { apiRequest } from '../actions/api';
import { CUSTOMER_KEY } from '../../config/consts';

const URL = 'maintenance';

const params = [
  'equipment',
  'maintenance',
  'individualItemId',
  'customer_MM',
  'inspectionCriteria',
  'effectedDate',
  'interval',
  'listAll',
  'group',
];

export const getMaintenancesFlow = ({ dispatch, getState }) =>
  (next) =>
    (action) => {
      next(action);
      if (action.type === GET_MAINTENANCES) {
        dispatch(
          apiRequest(
            'GET',
            URL,
            {
              ...action.payload,
              filter: {
                ...getState().maintenanceFilter,
                ...action.payload?.filter,
                customer: localStorage.getItem(CUSTOMER_KEY),
              },
            },
            FETCH_MAINTENANCES_SUCCESS,
            FETCH_MAINTENANCES_ERROR,
            params
          )
        );
        dispatch(showSpinner());
      }
    };
export const enableNotificationsFlow = ({ dispatch }) =>
  (next) =>
    (action) => {
      next(action);
      if (action.type === ENABLE_MAINTENANCES_NOTIFICATION) {
        dispatch(
          apiRequest(
            'PUT',
            `${URL}/enable_notifications`,
            { data: action.payload, _id: '' },
            SUCCESS_ENABLE_MAINTENANCES_NOTIFICATION,
            ERROR_ENABLE_MAINTENANCES_NOTIFICATION
          )
        );
        dispatch(showSpinner());
      }
    };

export const disableNotificationsFlow = ({ dispatch }) =>
  (next) =>
    (action) => {
      next(action);
      if (action.type === DISABLE_MAINTENANCES_NOTIFICATION) {
        dispatch(
          apiRequest(
            'PUT',
            `${URL}/disable_notifications`,
            { data: action.payload, _id: '' },
            SUCCESS_DISABLE_MAINTENANCES_NOTIFICATION,
            ERROR_DISABLE_MAINTENANCES_NOTIFICATION
          )
        );
        dispatch(showSpinner());
      }
    };

export const searchMaintenancesFlow = ({ dispatch }) =>
  (next) =>
    (action) => {
      next(action);
      if (action.type === SEARCH_MAINTENANCES) {
        dispatch(
          apiRequest(
            'GET',
            URL,
            {
              ...action.payload,
              filter: {
                ...action.payload.filter,
                customer: localStorage.getItem(CUSTOMER_KEY),
              },
            },
            SEARCH_MAINTENANCES_SUCCESS,
            SEARCH_MAINTENANCES_ERROR,
            ['query', 'customer']
          )
        );
        dispatch(updateMaintenancesSearch({ docs: [], totalDocs: 0 }));
        dispatch(showSearchSpinner());
      }
    };
export const fullSearchMaintenancesFlow = ({ dispatch }) =>
  (next) =>
    (action) => {
      next(action);
      if (action.type === FULL_SEARCH_MAINTENANCES) {
        dispatch(setMaintenanceFilter({}));
        dispatch(
          apiRequest(
            'GET',
            'search',
            {
              ...action.payload,
              filter: {
                ...action.payload.filter,
                customer: localStorage.getItem(CUSTOMER_KEY),
              },
            },
            FETCH_MAINTENANCES_SUCCESS,
            FETCH_MAINTENANCES_ERROR,
            ['query', 'customer']
          )
        );
        // dispatch(updateMaintenancesSearch({ docs: [], totalDocs: 0 }));
        dispatch(showSearchSpinner());
      }
    };

export const processMaintenancesCollection = ({ dispatch }) =>
  (next) =>
    (action) => {
      next(action);
      if (action.type === FETCH_MAINTENANCES_SUCCESS) {
        dispatch(updateMaintenances(action.payload));
        dispatch(hideSpinner());
        dispatch(hideSearchSpinner());
      }
      if (action.type === SEARCH_MAINTENANCES_SUCCESS) {
        dispatch(updateMaintenancesSearch(action.payload));
        dispatch(hideSearchSpinner());
      }
      if (
        action.type === SUCCESS_ENABLE_MAINTENANCES_NOTIFICATION ||
        action.type === SUCCESS_DISABLE_MAINTENANCES_NOTIFICATION
      ) {
        Toastify.addSuccess('Success - notification status updated.');
        dispatch(getMaintenances());
      }
      if (
        action.type === ERROR_ENABLE_MAINTENANCES_NOTIFICATION ||
        action.type === ERROR_DISABLE_MAINTENANCES_NOTIFICATION
      ) {
        Toastify.addError(
          action.payload || "Error - couldn't process this operation."
        );
      }
    };

export const maintenancesMdl = [
  getMaintenancesFlow,
  searchMaintenancesFlow,
  fullSearchMaintenancesFlow,
  processMaintenancesCollection,
  disableNotificationsFlow,
  enableNotificationsFlow,
];
