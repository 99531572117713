import {
  Button,
  FormGroup,
  FormHelperText,
  Grid,
  makeStyles,
  TextField,
} from '@material-ui/core';
import { AddCircle as AddIcon, Cancel as CancelIcon } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Alert, ModalContainer, Spinner } from '../../../components';
import {
  createResultLayout,
  getResultLayout,
  hideResultLayoutModal,
  updateResultLayout,
} from '../../../redux/actions/resultLayout';
import equipmentInputs from '../../../utils/StaticData/EquipmentInputs/result_data';
import {
  ButtonContainer,
  Column,
  ColumnContent,
  ColumnHeader,
  Container,
  ExcelTable,
  InputContent,
  ModalTitle,
} from './styles';

const styles = {
  formButton: {
    margin: 2,
  },
};
const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 600,
    position: 'relative',
    width: '100%',
  },
}));

const alphabeticalOrder = (a, b) =>
  String(a.label).localeCompare(String(b.label));

const initState = { name: '', fields: [] };

const ResultLayoutModal = ({
  id,
  dispatch,
  resultLayout,
  resultLayoutModal,
  resultLayoutLoading,
}) => {
  const classes = useStyles();
  const [filteredData, setFilteredData] = useState('');
  const [nameError, setNameError] = useState(false);
  const [fieldsError, setFieldsError] = useState(false);
  const [data, setData] = useState(initState);
  const nameRef = React.useRef(null);
  const { fields, name } = data;

  useEffect(() => {
    if (id !== 'new') {
      dispatch(getResultLayout(id));
    } else setData(initState);
  }, [id]);

  useEffect(() => {
    if (resultLayout && id !== 'new') {
      setData(resultLayout);
    }
  }, [resultLayout]);

  useEffect(() => {
    if (!resultLayoutModal) {
      handleClose();
    }
  }, [resultLayoutModal]);

  const equipmentInputsSorted = equipmentInputs.sort(alphabeticalOrder);

  function handleClose() {
    dispatch(hideResultLayoutModal());
  }

  const handleSave = async () => {
    let err = false;
    err = !fields || !fields.length || !name;
    setNameError(!name);
    setFieldsError(!fields || !fields.length);
    if (!name) nameRef.current.scrollIntoView({ behavior: 'smooth' });

    if (!err) {
      const response = await Alert();
      if (response && response.confirm) {
        if (id === 'new') {
          dispatch(createResultLayout(data));
        } else {
          dispatch(updateResultLayout(data));
        }
      }
    }
  };

  const areAllVisibleSelected =
    fields.filter(filterAvailableData).length ===
    equipmentInputsSorted.filter(filterAvailableData).length;

  const removeAll = async () => {
    const fieldsCopy = [];
    fields.forEach(function (item) {
      if (!filterAvailableData(item)) fieldsCopy.push(item);
    });
    setData((currentData) => ({ ...currentData, fields: fieldsCopy }));
  };

  const selectAll = async () => {
    const allFields = equipmentInputsSorted.filter(filterAvailableData);
    setData((currentData) => ({
      ...currentData,
      fields: [...currentData.fields, ...allFields],
    }));
  };

  const handleRemove = async (name) => {
    setData((currentData) => {
      const newFields = currentData.fields.filter(
        (field) => field.label !== name
      );
      return { ...currentData, fields: newFields };
    });
  };

  const handleSelect = async (name) => {
    let newField = selected(name);
    if (!newField) {
      const selectedField = equipmentInputsSorted.find(
        (item) => item.label === name
      );
      if (!selectedField) return;
      setData((currentData) => ({
        ...currentData,
        fields: [...currentData.fields, selectedField],
      }));
    }
  };

  const selected = (label) => {
    const result = fields.some((item) => item.label === label);
    return result;
  };

  const notSelected = (label) => {
    const result = fields.some((item) => !(item.label === label));
    return result;
  };

  function handleFilteredData(e) {
    setFilteredData(e.target.value);
  }

  function filterAvailableData(data) {
    return data.label.toUpperCase().includes(filteredData.toUpperCase());
  }

  function handleName(e) {
    const { value } = e.target;
    setData((currentData) => ({ ...currentData, name: value }));
  }

  return (
    <ModalContainer open={resultLayoutModal} handleClose={handleClose}>
      <>
        {resultLayoutLoading && <Spinner overlay />}
        <Container style={{ opacity: resultLayoutLoading ? 0.5 : 1 }}>
          <ModalTitle>{id === 'new' ? 'New' : 'Edit'} Result Layout</ModalTitle>
          <InputContent>
            <TextField
              label="Layout Name"
              onChange={handleName}
              value={name}
              variant="outlined"
              fullWidth
              error={nameError && !name}
              helperText={nameError && !name && 'This field is required'}
              ref={nameRef}
            />
          </InputContent>
          <Grid
            container
            style={{
              padding: '0px 10px 10px 10px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ExcelTable>
              <Column>
                <ColumnHeader>
                  Available Data
                  <TextField
                    label="Filter"
                    variant="outlined"
                    value={filteredData}
                    onChange={handleFilteredData}
                    fullWidth
                    size="small"
                    style={{ margin: 10 }}
                  />
                </ColumnHeader>
                <ColumnContent>
                  <FormGroup>
                    {equipmentInputsSorted?.filter(filterAvailableData).map(
                      (obj, i) =>
                        !selected(obj.label) && (
                          <div
                            onClick={() => handleSelect(obj.label)}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              cursor: 'pointer',
                            }}
                          >
                            <AddIcon
                              key={i}
                              style={{ margin: 9 }}
                              name={obj.label}
                              checked={selected(obj.label)}
                              color="primary"
                            />
                            {obj.label}
                          </div>
                        )
                    )}
                  </FormGroup>
                </ColumnContent>
              </Column>
              <Column>
                <ColumnContent>
                  <FormGroup>
                    <div
                      style={{
                        margin: 0,
                        marginTop: '15rem',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          fontWeight: 'bold',
                          marginLeft: '56px',
                        }}
                      >
                        <Button
                          onClick={() => selectAll()}
                          variant="outlined"
                          title="Add All"
                          size="small"
                          color="primary"
                          className={classes.margin}
                          disabled={areAllVisibleSelected}
                        >
                          {'>>'}
                        </Button>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          fontWeight: 'bold',
                          marginTop: '1rem',
                          marginLeft: '56px',
                        }}
                      >
                        <Button
                          onClick={() => removeAll()}
                          variant="outlined"
                          title="Remove All"
                          size="small"
                          color="secondary"
                          className={classes.margin}
                          disabled={
                            fields.filter(filterAvailableData).length === 0
                          }
                        >
                          {'<<'}
                        </Button>
                      </div>
                    </div>
                  </FormGroup>
                </ColumnContent>
              </Column>
              <Column>
                <ColumnHeader style={{ marginBottom: '3.5rem' }}>
                  Selected Data
                </ColumnHeader>
                <ColumnContent>
                  <FormGroup colum>
                    {fields?.filter(filterAvailableData).map((obj, i) => (
                      <div
                        onClick={() => handleRemove(obj.label)}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                      >
                        <CancelIcon
                          key={i}
                          style={{ color: 'red', margin: 9 }}
                          name={obj.label}
                          checked={notSelected(obj.label)}
                          color="primary"
                        />
                        {obj.label}
                      </div>
                    ))}
                  </FormGroup>
                </ColumnContent>
              </Column>
            </ExcelTable>
          </Grid>
          {fieldsError && (!fields || !fields.length) && (
            <FormHelperText
              style={{ textAlign: 'center', marginBottom: -25 }}
              error={fieldsError && (!fields || !fields.length)}
            >
              Select at least one field to show
            </FormHelperText>
          )}
          <ButtonContainer>
            <Button
              color="secondary"
              onClick={handleClose}
              variant="contained"
              style={styles.formButton}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={handleSave}
              variant="contained"
              style={styles.formButton}
            >
              Save
            </Button>
          </ButtonContainer>
        </Container>
      </>
    </ModalContainer>
  );
};

export default connect((state) => ({
  resultLayout: state.resultLayout,
  resultLayoutModal: state.ui.resultLayoutModal,
  resultLayoutLoading: state.ui.resultLayoutLoading,
}))(ResultLayoutModal);
