import {
  FETCH_INVENTORY_CART_SUCCESS,
  FETCH_INVENTORY_CART_ERROR,
  CART_CHANGE_SUCCESS,
  REMOVE_FROM_CART_SUCCESS,
  GET_INVENTORY_CART,
  ADD_TO_INVENTORY_CART,
  REMOVE_FROM_INVENTORY_CART,
  ADD_TO_CART_SUCCESS,
  updateInventoryCart,
  showSpinner,
  hideSpinner,
  updateCartCount,
  GET_COMPLETE_INVENTORY_CART,
  ADD_TO_CART_ERROR,
  REMOVE_FROM_CART_ERROR,
  getCompleteCart,
  getInventoryCart,
} from '../actions/inventory_cart';
import Toastify from '../../utils/Toastify/index';
import { apiRequest } from '../actions/api';

const URL = 'cart';

export const getInventoryCartFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_INVENTORY_CART) {
    dispatch(
      apiRequest(
        'GET-cart',
        URL,
        action.payload,
        FETCH_INVENTORY_CART_SUCCESS,
        FETCH_INVENTORY_CART_ERROR,
        [
          'equipmentName',
          'workOrder',
          'allocatedProject',
          'chargeCode',
          'availability',
          'allocated',
          'consolidated',
        ],
      ),
    );
    dispatch(showSpinner(action.payload.availability));
  }
};

export const getCount = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_COMPLETE_INVENTORY_CART) {
    dispatch(
      apiRequest(
        'GET-id',
        URL,
        action.payload,
        CART_CHANGE_SUCCESS,
        FETCH_INVENTORY_CART_ERROR,
      ),
    );
    dispatch(showSpinner(action.payload.availability));
  }
};

export const addToInventoryCartFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === ADD_TO_INVENTORY_CART) {
    dispatch(
      apiRequest(
        'POST-cart',
        URL,
        action.payload,
        ADD_TO_CART_SUCCESS,
        ADD_TO_CART_ERROR,
      ),
    );
  }
};

export const removeFromInventoryCartFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === REMOVE_FROM_INVENTORY_CART) {
    dispatch(
      apiRequest(
        'DELETE-cart',
        URL,
        action.payload,
        REMOVE_FROM_CART_SUCCESS,
        REMOVE_FROM_CART_ERROR,
      ),
    );
  }
};

export const processInventoryCartCollection = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_INVENTORY_CART_SUCCESS) {
    dispatch(updateInventoryCart(action.payload));
    dispatch(hideSpinner(action.payload.availability));
  }
  if (action.type === CART_CHANGE_SUCCESS) {
    dispatch(updateCartCount(action.payload));
  }
  if (action.type === ADD_TO_CART_SUCCESS) {
    dispatch(getCompleteCart(action.payload));
  }
  if (action.type === REMOVE_FROM_CART_SUCCESS) {
    dispatch(getCompleteCart(action.payload));
    dispatch(
      getInventoryCart({
        page: 1,
        limit: 10,
        availability: 'available',
        userId: action.payload,
        filter: { consolidated: true },
      }),
    );
  }
  if (action.type === ADD_TO_CART_ERROR) {
    Toastify.addError(action.payload);
  }
  if (action.type === REMOVE_FROM_CART_ERROR) {
    Toastify.addError(action.payload);
  }
  if (action.type === REMOVE_FROM_CART_SUCCESS) {
    Toastify.addSuccess('Item(s) removed successfully!');
  }
};

export const inventoryCartMdl = [
  getInventoryCartFlow,
  processInventoryCartCollection,
  addToInventoryCartFlow,
  removeFromInventoryCartFlow,
  getCount,
];
