import { UPDATE_INSPECTIONS } from '../actions/inspections';

const initState = {
  docs: [],
  page: 1,
  limit: 10,
  totalDocs: 0,
};

export function inspectionsReducer(inspections = initState, action) {
  switch (action.type) {
    case UPDATE_INSPECTIONS:
      return action.payload;

    default:
      return inspections;
  }
}
