const colorMap = {
  A: undefined,
  AR: '#fcf299',
  C: '#fcf299',
  CR: '#fcf299',
  B: undefined,
  BR: '#fcf299',
  E: '#f19699',
};

export default function getColorFromClass(classification) {
  return colorMap[classification];
}
