import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import {
  CloudDownload as DownloadIcon,
  SearchRounded,
  ShoppingCart,
  Visibility,
} from '@material-ui/icons';
import format from 'date-fns/format';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import HistoryFilter from '../../components/HistoryFilter';
import ModalContainer from '../../components/ModalContainer';
import Spinner from '../../components/Spinner/index';
import { CUSTOMER_KEY } from '../../config/consts';
import { getHistory } from '../../redux/actions/history';
import { setHistoryFilter } from '../../redux/actions/historyFilter';
import { addToInventoryCart } from '../../redux/actions/inventory_cart';
import { getMovimentation } from '../../redux/actions/movimentation';
import { downloadReport, exportHistory } from '../../services/Download';
import { tableStyles } from '../../styles/tableStyles';
import HistoryModal from '../Modals/HistoryModal';
import { ButtonContainer, Container } from './styles';
import getRINADescription from '../../utils/Equipment/getRINADescription';

const HistoryDashboard = ({
  dispatch,
  history,
  historyLoading,
  historyFilter,
  loggedUser,
}) => {
  const classes = tableStyles();
  const browserHistory = useHistory();
  const { id } = useParams();
  const [historyFormated, setHistoryFormated] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [servicePerformed, setServicePerformed] = useState('');
  const [equipmentName, setEquipmentName] = useState('');
  const [equipmentUniqueId, setEquipmentUniqueId] = useState('');
  const [equipmentParent, setEquipmentParent] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const historyEquipment = useHistory();

  useEffect(() => {
    if (!id) return;
    dispatch(getMovimentation(id));
  }, [id]);

  useEffect(() => {
    dispatch(
      getHistory({
        page: page + 1,
        limit,
        filter: {
          ...historyFilter,
          inventory: localStorage.getItem(CUSTOMER_KEY),
        },
      })
    );
  }, [page, limit]);

  useEffect(() => {
    if (history.docs) {
      setHistoryFormated(history.docs);
      setTotal(history.totalDocs);
    }
  }, [history]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEdit = (event) => {
    function handler() {
      switch (event.event) {
        case 'Check-Out':
          browserHistory.push(`/check_out/${event._id}`);
          break;
        case 'Reconcile':
          browserHistory.push(`/reconcile/${event._id}`);
          break;
        case 'Check-In':
          browserHistory.push(`/check_in/${event._id}`);
          break;
        case 'Allocation':
          browserHistory.push(`/allocation/${event._id}`);
          break;
        case 'Transfer of Ownership':
          browserHistory.push(`/transfer_of_ownership/${event._id}`);
          break;
        case 'Reclass':
          browserHistory.push(`/reclass/${event._id}`);
          break;
        case 'Bill of Material':
          browserHistory.push(`/build_of_material/${event._id}`);
          break;
        case 'Fabrication':
          browserHistory.push(`/fabrication/${event._id}`);
          break;
        case 'Added to hierarchy':
          handleModalInformation(
            getRINADescription(
              event.individualData?.movimentation?.customer_MM
            ),
            event.individualData?.movimentation?.individual_item_id,

            getRINADescription(event.parent?.customer_MM),
            'added'
          );
          handleOpenModal();
          break;
        case 'Removed from hierarchy':
          handleModalInformation(
            getRINADescription(
              event.individualData?.movimentation?.customer_MM
            ),
            event.individualData?.movimentation?.individual_item_id,
            getRINADescription(event.parent?.customer_MM),
            'removed'
          );
          handleOpenModal();
          break;

        default:
          return;
      }
    }
    return handler;
  };

  const handleGoToEquipmentDetails = (_id) => {
    return () => historyEquipment.push(`/equipment/${_id}`);
  };
  function addToCart(id) {
    return () => dispatch(addToInventoryCart(loggedUser._id, [id]));
  }

  function handleSearch(e) {
    e.preventDefault();
    setPage(0);
    dispatch(
      getHistory({
        page: 1,
        limit,
        filter: {
          ...historyFilter,
          inventory: localStorage.getItem(CUSTOMER_KEY),
        },
      })
    );
  }

  function handleOpenModal() {
    setOpenModal(true);
  }

  function handleCloseModal() {
    setOpenModal(false);
  }

  function handleModalInformation(equipment, id, parent, service) {
    setEquipmentName(equipment);
    setEquipmentUniqueId(id);
    setEquipmentParent(parent);
    setServicePerformed(service);
  }

  function handleDownload(event) {
    function handler() {
      downloadReport(event._id);
    }
    return handler;
  }

  return (
    <>
      <ModalContainer open={openModal} handleClose={handleCloseModal}>
        <HistoryModal
          equipmentName={equipmentName}
          equipmentParent={equipmentParent}
          individualItemId={equipmentUniqueId}
          title={servicePerformed}
          handleClose={() => {
            setOpenModal(false);
          }}
        />
      </ModalContainer>
      <Container>
        <div style={{ marginBottom: 20 }}>
          <form onSubmit={handleSearch}>
            <Paper
              style={{
                padding: '5px 20px',
                justifyContent: 'center',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <InputBase
                style={{
                  height: 36,
                  flex: 1,
                  backgroundColor: '#eee',
                  fontSize: 15,
                  borderRadius: 20,
                  paddingLeft: 20,
                }}
                placeholder="Search in history"
                onChange={(e) =>
                  dispatch(setHistoryFilter({ query: e.target.value }))
                }
                value={historyFilter.query}
              />
              <IconButton
                type="submit"
                className={classes.iconButton}
                aria-label="search"
              >
                <SearchRounded />
              </IconButton>
            </Paper>
          </form>
        </div>
        <HistoryFilter movimentation={id} complete />
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <ButtonContainer>
              <Button
                disabled={historyLoading}
                color="primary"
                onClick={() => exportHistory(undefined, true)}
                startIcon={<DownloadIcon />}
              >
                Export History
              </Button>
            </ButtonContainer>
            <TableContainer className={classes.table}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Transaction ID</TableCell>
                    <TableCell>Author</TableCell>
                    <TableCell>Service Performed</TableCell>
                    <TableCell style={{ minWidth: 150 }}>
                      Equality Work Order No
                    </TableCell>
                    <TableCell>RINA Description</TableCell>
                    <TableCell>RAMP Unique ID</TableCell>
                    <TableCell style={{ minWidth: 100 }}>
                      Total Qty (EA)
                    </TableCell>
                    <TableCell style={{ minWidth: 100 }}>Footage</TableCell>
                    <TableCell>Current COPAS Class</TableCell>
                    <TableCell>COPAS Class</TableCell>
                    <TableCell>Customer MM No</TableCell>
                    <TableCell>Heat/Lot</TableCell>
                    <TableCell>RBW</TableCell>
                    <TableCell style={{ minWidth: 150 }}>
                      Facility Reference ID
                    </TableCell>
                    <TableCell>Charge Code From</TableCell>
                    <TableCell>Allocated Charge Code</TableCell>
                    <TableCell>Charge Code To</TableCell>
                    <TableCell>SLOC</TableCell>
                    <TableCell>Allocated Batch</TableCell>
                    <TableCell>Batch To</TableCell>
                    <TableCell>Allocated Project</TableCell>
                    <TableCell>Project To</TableCell>
                    <TableCell style={{ minWidth: 200 }}>Notes</TableCell>
                    <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {historyLoading && (
                    <TableRow className={classes.row}>
                      <TableCell colSpan="23" className={classes.cell}>
                        <Spinner parent="Table" />
                      </TableCell>
                    </TableRow>
                  )}
                  {!historyLoading &&
                    historyFormated.map((event) => (
                      <TableRow key={event._id} className={classes.row}>
                        <TableCell className={classes.cell}>
                          {format(
                            new Date(event.createdAt),
                            'MM-dd-yyyy HH:mm'
                          )}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {event.transactionId}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {event.user?.name ? event.user.name : '---'}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {event.event}
                        </TableCell>
                        <TableCell>
                          {event?.individualData?.movimentation
                            ?.rina_work_order_no || '---'}
                        </TableCell>
                        <TableCell style={{ minWidth: 250, maxWidth: 300 }}>
                          {getRINADescription(
                            event?.individualData?.movimentation?.customer_MM
                          )}
                        </TableCell>
                        <TableCell>
                          {event?.individualData?.movimentation
                            ?.individual_item_id || '---'}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {event?.individualData?.qty || '---'}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {event?.individualData?.footage || '---'}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {event?.individualData?.movimentation
                            ?.classification || '---'}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {event?.individualData?.class || '---'}
                        </TableCell>
                        <TableCell>
                          {event?.individualData?.movimentation?.customer_MM
                            ?.mm_number || '---'}
                        </TableCell>
                        <TableCell>
                          {event?.individualData?.movimentation?.heat || '---'}
                        </TableCell>
                        <TableCell>
                          {event?.individualData?.movimentation?.RBW || '---'}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {event.facilityReferenceID || '---'}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {event.individualData?.originalData
                            ?.assigned_customer_charge_code
                            ?.original_charge_code || '---'}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {event.event === 'Allocation'
                            ? event.chargeCode?.original_charge_code || '---'
                            : '---'}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {event.event === 'Transfer of Ownership'
                            ? event.chargeCode?.original_charge_code || '---'
                            : '---'}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {event.SLOC?.sloc_id || '---'}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {event.batchChange || '---'}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {event.batchNumberTo || '---'}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {event.event === 'Allocation'
                            ? event.chargeCode?.well?.project?.name || '---'
                            : '---'}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {event.event === 'Transfer of Ownership'
                            ? event.chargeCode?.well?.project?.name || '---'
                            : '---'}
                        </TableCell>
                        <TableCell className={`${classes.cell} ellipsis`}>
                          <Tooltip title={event.note || '---'}>
                            <span>{event.note || '---'}</span>
                          </Tooltip>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <Tooltip title="Download Report">
                            <IconButton
                              disabled={
                                event.event === 'Added to hierarchy' ||
                                event.event === 'Removed from hierarchy'
                              }
                              color="secondary"
                              onClick={handleDownload(event)}
                            >
                              <DownloadIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <Tooltip title="Details">
                            <IconButton
                              color="primary"
                              onClick={handleEdit(event)}
                            >
                              <SearchRounded />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <Tooltip title="See Equipment Details">
                            <IconButton
                              color="primary"
                              onClick={handleGoToEquipmentDetails(
                                event?.individualData?.movimentation?._id
                              )}
                            >
                              <Visibility style={{ color: 'mediumpurple' }} />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <Tooltip title="Add to Cart">
                            <IconButton
                              onClick={addToCart(
                                event?.individualData?.movimentation?._id
                              )}
                            >
                              <ShoppingCart
                                style={{ color: 'cornflowerblue' }}
                              />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[2, 5, 10, 25]}
              component="div"
              count={total}
              rowsPerPage={limit}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  historyLoading: state.ui.historyLoading,
  history: state.history,
  movimentation: state.movimentation,
  historyFilter: state.historyFilter,
  loggedUser: state.loggedUser,
});

export default connect(mapStateToProps)(HistoryDashboard);
