import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import format from 'date-fns/format';
import { Add, ArrowBack, EditRounded } from '@material-ui/icons';
import { getVPASs } from '../../redux/actions/vpass';
import { showModal, hideModal } from '../../redux/actions/vpas';
import Spinner from '../../components/Spinner/index';
import { Container } from './styles';
import ModalContainer from '../../components/ModalContainer/index';
import EditModal from '../Modals/VPAS';
import { CUSTOMER_KEY } from '../../config/consts';
import { tableStyles } from '../../styles/tableStyles';

const VPASs = ({ dispatch, vpass, vpassLoading, vpasModal }) => {
  const { customer } = useParams();
  const history = useHistory();
  const classes = tableStyles();
  const [VPASsFormated, setVPASsFormated] = useState([]);
  const [modalContentId, setModalContentId] = useState('new');
  const [modalContentWOId, setModalContentWOId] = useState('new');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(50);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    dispatch(getVPASs({ page: page + 1, limit, filter: { customer } }));
  }, [page, limit]);

  useEffect(() => {
    if (vpass.docs) {
      setVPASsFormated(vpass.docs);
      setTotal(vpass.totalDocs);
    }
  }, [vpass]);

  const handleBack = () => {
    history.goBack();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleOpenModal = () => {
    dispatch(showModal());
  };
  const handleClick = (id, work_order_id) => {
    setModalContentId(id);
    setModalContentWOId(work_order_id);
    handleOpenModal();
  };

  const handleCloseModal = () => {
    dispatch(hideModal());
  };

  return (
    <>
      <Container>
        <ModalContainer open={vpasModal} handleClose={handleCloseModal}>
          <EditModal
            id={modalContentId}
            workOrder={modalContentWOId}
            handleClose={handleCloseModal}
          />
        </ModalContainer>
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <Toolbar className={classes.toolBar}>
              <span
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <IconButton onClick={handleBack}>
                  <ArrowBack />
                </IconButton>
                <Typography
                  className={classes.title}
                  variant="h6"
                  id="tableTitle"
                  component="span"
                >
                  VPAS
                </Typography>
              </span>
              <span>
                <Button
                  color="primary"
                  onClick={() => handleClick('new')}
                  startIcon={<Add />}
                >
                  New
                </Button>
              </span>
            </Toolbar>
            <TableContainer className={classes.table}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Employee</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>VPAS Vendor</TableCell>
                    <TableCell>Job Number</TableCell>
                    <TableCell>Coordinator</TableCell>
                    <TableCell>Activity</TableCell>
                    <TableCell>Down Time</TableCell>
                    <TableCell>Down Time (HR)</TableCell>
                    <TableCell>Stop Work</TableCell>
                    <TableCell>Near Miss</TableCell>
                    <TableCell>NCR</TableCell>
                    <TableCell>Category of Concern</TableCell>
                    <TableCell>Descrepancy</TableCell>
                    <TableCell>Action</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {vpassLoading && (
                    <TableRow className={classes.row}>
                      <TableCell colSpan="15" className={classes.cell}>
                        <Spinner parent="Table" />
                      </TableCell>
                    </TableRow>
                  )}
                  {!vpassLoading &&
                    VPASsFormated.map((vpas) => (
                      <TableRow className={classes.row}>
                        <TableCell className={classes.cell}>
                          {vpas.employee}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {vpas.date
                            ? format(new Date(vpas.date), 'MM-dd-yyyy')
                            : '--'}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {vpas.vendor?.vendor_name}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {vpas.work_order?.rina_job_number}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {vpas.coordinator}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {vpas.activity}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {vpas.down_time}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {vpas.down_time_hr}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {vpas.stop_work}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {vpas.near_miss}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {vpas.ncr}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {vpas.category_of_concern}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {vpas.descrepancy}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {vpas.action}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <IconButton
                            onClick={() =>
                              handleClick(vpas._id, vpas?.work_order?._id)
                            }
                          >
                            <EditRounded />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[50, 100, 200, 500, 1000]}
              component="div"
              count={total}
              rowsPerPage={limit}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  vpassLoading: state.ui.vpassLoading,
  vpass: state.vpass,
  vpasModal: state.ui.vpasModal,
});

export default connect(mapStateToProps)(VPASs);
