import {
  UPDATE_SLOC_SUCCESS,
  UPDATE_SLOC_ERROR,
  FETCH_SLOC_SUCCESS,
  FETCH_SLOC_ERROR,
  GET_SLOC,
  CREATE_SLOC,
  UPDATE_SLOC,
  updateObjectSLOC,
  showSpinner,
  hideSpinner,
  hideModal,
} from '../actions/sloc';
import { getSLOCS } from '../actions/slocs';
import Toastify from '../../utils/Toastify/index';
import { apiRequest } from '../actions/api';

const URL = 'slocs';

export const createSLOCFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === CREATE_SLOC) {
    dispatch(
      apiRequest(
        'POST',
        URL,
        action.payload,
        UPDATE_SLOC_SUCCESS,
        UPDATE_SLOC_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const updateSLOCFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === UPDATE_SLOC) {
    dispatch(
      apiRequest(
        'PUT',
        URL,
        action.payload,
        UPDATE_SLOC_SUCCESS,
        UPDATE_SLOC_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const getSLOCFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_SLOC) {
    const { _id } = action.payload;
    dispatch(
      apiRequest('GET-id', URL, { _id }, FETCH_SLOC_SUCCESS, FETCH_SLOC_ERROR),
    );
    dispatch(showSpinner());
  }
};

export const processSLOCCollection = ({ dispatch, getState }) => (next) => (
  action,
) => {
  next(action);
  if (action.type === FETCH_SLOC_SUCCESS) {
    dispatch(updateObjectSLOC(action.payload));
    dispatch(hideSpinner());
  } else if (action.type === UPDATE_SLOC_SUCCESS) {
    dispatch(updateObjectSLOC(action.payload));
    dispatch(hideModal());
    dispatch(hideSpinner());
    // To reload the page
    const { page, limit } = getState().slocs;
    dispatch(getSLOCS({ page, limit }));
    Toastify.addSuccess('SLOC saved.');
  } else if (action.type === UPDATE_SLOC_ERROR) {
    dispatch(hideSpinner());
    Toastify.addError(action.payload || 'Error to save the sloc');
  }
};

export const slocMdl = [
  createSLOCFlow,
  updateSLOCFlow,
  getSLOCFlow,
  processSLOCCollection,
];
