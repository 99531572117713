import {
  GET_MAINTENANCE,
  UPDATE_MAINTENANCE,
  UPDATE_OBJECT_MAINTENANCE,
  CREATE_MAINTENANCE,
  DELETE_MAINTENANCE,
} from '../actions/maintenance';

export function maintenanceReducer(maintenance = {}, action) {
  switch (action.type) {
    case UPDATE_OBJECT_MAINTENANCE:
      return action.payload;
    case CREATE_MAINTENANCE:
      return action.payload;

    default:
      return maintenance;
  }
}
