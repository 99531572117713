import styled from 'styled-components';
import TableContainerMaterial from '@material-ui/core/TableContainer';
import Button from '@material-ui/core/Button';

export const Container = styled.div`
  padding: 1rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  
`;

export const TableContainer = styled(TableContainerMaterial)`
.MuiCheckbox-root {
    /* color: #1976d2; */
    color: #2c2c32;
  }`;

export const StyledButton = styled(Button)`
  
  color: white;
  font-size: 0.75rem;
  margin: 0.75rem;
  .MuiButton-label {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &.Mui-disabled {
    color: #fff5;
  }
`;

export const MenuBar = styled.div`
  padding: 2rem;
  background-color: #2c2c32;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .MuiIconButton-root {
    color: white;
    margin: 8px;
  }
  .MuiIconButton-root.Mui-disabled {
    color: #fff5;
  }
`;

export const Separator = styled.div`
  width: 0.1px;
  height: 3rem;
  background-color: #fff;
`;

export const Title = styled.div`
  font-size: 1.25rem;
  padding-left: 24px;
  padding-top: 12px;
  font-weight: 500;
`;
