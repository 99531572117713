import styled from 'styled-components';

import ListItemIcon from '@material-ui/core/ListItemIcon';

export const Container = styled.div`
  padding: 1rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
`;

export const TitleContent = styled.div`
  margin: 0;
  width: 85%;
  min-height: 25px;
`;

export const ListIcon = styled(ListItemIcon)`
  padding-left: 8px;
  .react-icons {
    font-size: 1.6rem;
  }
`;
export const ButtonsContainer = styled.span`
  display: flex;
`;

export const Title = styled.div`
  font-size: 1.3rem;
  padding-left: 10px;
`;

export const ButtonContainer = styled.div`
  padding: 15px 2px 5px 2px;
`;
