import React from 'react';

import { Container } from './styles';

function Spinner({ parent, overlay, color = '#878787' }) {
  return (
    <Container color={color}>
      <div className="lds-ellipsis">
        <div />
        <div />
        <div />
        <div />
      </div>
    </Container>
  );
}

export default Spinner;
