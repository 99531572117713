import styled from 'styled-components';
import { Grid } from '@material-ui/core';

import ListItemIcon from '@material-ui/core/ListItemIcon';

export const Container = styled.div`
  padding: 1rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
`;

export const ListIcon = styled(ListItemIcon)`
  padding-left: 8px;
  .react-icons {
    font-size: 1.6rem;
  }
`;
export const ButtonsContainer = styled.span`
  display: flex;
`;

export const Title = styled.div`
  font-size: 1.3rem;
  padding-left: 10px;
`;

export const AddButton = styled(Grid)`
  display: flex;
  justify-content: flex-end;
`;
