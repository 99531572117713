import styled from 'styled-components';
import { KeyboardArrowRight as MuiCollapseIcon } from '@material-ui/icons';
import { TableCell } from '@material-ui/core';

export const CollapseIcon = styled(MuiCollapseIcon)`
  transition: transform ease 500ms;
  transform: rotate(${({ expanded }) => (expanded ? 90 : 0)}deg);
`;

export const CollapseCell = styled(TableCell)`
  background-color: #ccc3;
  padding-top: 0;
  padding-bottom: 0;
`;
