import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { TextField, FormGroup, Button, MenuItem } from '@material-ui/core';
import Alert from '../../../components/Alert/index';
import Spinner from '../../../components/Spinner/index';
import { Container, InputContent, ModalTitle, ButtonContainer } from './styles';

import { getWell, createWell, updateWell } from '../../../redux/actions/well';
import { getProjects } from '../../../redux/actions/projects';
import { CUSTOMER_KEY } from '../../../config/consts';

const styles = {
  formButton: {
    margin: 2,
  },
};

const WellModal = ({
  id,
  dispatch,
  well,
  projects,
  wellModal,
  handleClose,
  wellSpinner,
}) => {
  const [data, setData] = useState({
    name: '',
    description: '',
    project: '',
  });

  const [nameError, setNameError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [projectError, setProjectError] = useState(false);

  useEffect(() => {
    dispatch(getProjects({ filter: { listAll: true } }));
  }, []);

  useEffect(() => {
    if (id !== 'new') {
      dispatch(getWell(id));
    }
  }, [id]);

  useEffect(() => {
    if (well && id !== 'new') {
      setData(well);
    }
  }, [well]);

  useEffect(() => {
    if (!wellModal) {
      handleClose();
    }
  }, [wellModal]);

  const handleSave = async () => {
    let err = false;
    setNameError(!data.name);
    setDescriptionError(!data.description);
    setProjectError(!data.project);
    err = !data.name || !data.description || !data.project;

    if (!err) {
      const response = await Alert();
      if (response && response.confirm) {
        if (id === 'new') {
          dispatch(
            createWell({
              ...data,
              customer: localStorage.getItem(CUSTOMER_KEY),
            })
          );
        } else {
          dispatch(
            updateWell({
              ...data,
              _id: id,
            })
          );
        }
      }
    }
  };

  function handleEdit(e) {
    const { name, value } = e.target;
    setData((currentData) => ({ ...currentData, [name]: value }));
  }

  return (
    <>
      {wellSpinner && <Spinner overlay />}
      <Container style={{ opacity: wellSpinner ? 0.5 : 1 }}>
        <ModalTitle>{id === 'new' ? 'New' : 'Edit'} Well</ModalTitle>
        <FormGroup>
          <InputContent>
            <TextField
              id="outlined-password-input"
              label="Name*"
              type="text"
              error={nameError}
              helperText={nameError && 'Name is required'}
              variant="outlined"
              value={data.name}
              onChange={handleEdit}
              name="name"
              fullWidth
            />
          </InputContent>
          <InputContent>
            <TextField
              id="outlined-password-input"
              label="Description*"
              type="text"
              multiline
              error={descriptionError}
              helperText={descriptionError && 'Description is required'}
              variant="outlined"
              value={data.description}
              onChange={handleEdit}
              name="description"
              fullWidth
            />
          </InputContent>
          <InputContent>
            <TextField
              label="Project*"
              error={projectError}
              helperText={projectError && 'Project is required'}
              variant="outlined"
              value={data.project}
              onChange={handleEdit}
              name="project"
              fullWidth
              select
            >
              {projects &&
                projects.map((project) => (
                  <MenuItem value={project._id}>{project.name}</MenuItem>
                ))}
            </TextField>
          </InputContent>
          <ButtonContainer>
            <Button
              color="secondary"
              onClick={handleClose}
              variant="contained"
              style={styles.formButton}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={handleSave}
              variant="contained"
              style={styles.formButton}
            >
              Save
            </Button>
          </ButtonContainer>
        </FormGroup>
      </Container>
    </>
  );
};

export default connect((state) => ({
  well: state.well,
  wellModal: state.ui.wellModal,
  wellSpinner: state.ui.wellLoading,
  projects: state.projects?.docs || state.projects,
}))(WellModal);
