import { CUSTOMER_KEY } from '../../config/consts';

export const GET_MAINTENANCE = '[maintenance] GET';
export const UPDATE_MAINTENANCE = '[maintenance] UPDATE';
export const CREATE_MAINTENANCE = '[maintenance] CREATE';
export const DELETE_MAINTENANCE = '[maintenance] DELETE';
export const UPDATE_OBJECT_MAINTENANCE = '[maintenance] UPDATE OBJECT MAINTENANCE';
// UI
export const SHOW_MAINTENANCE_MODAL = '[maintenance] SHOW MODAL';
export const HIDE_MAINTENANCE_MODAL = '[maintenance] HIDE MODAL';
// Spinner
export const UPDATE_MAINTENANCE_SUCCESS = '[maintenance] Update success';
export const UPDATE_MAINTENANCE_ERROR = '[maintenance] Update Error';
export const FETCH_MAINTENANCE_SUCCESS = '[maintenance] Fetch success';
export const FETCH_MAINTENANCE_ERROR = '[maintenance] Fetch Error';
export const SHOW_MAINTENANCE_SPINNER = '[maintenance - ui] show spinner';
export const HIDE_MAINTENANCE_SPINNER = '[maintenance - ui] hide spinner';

export const getMaintenance = (id) => ({
  type: GET_MAINTENANCE,
  payload: { _id: id },
});

export const createMaintenance = (data) => ({
  type: CREATE_MAINTENANCE,
  payload: { ...data, customer: localStorage.getItem(CUSTOMER_KEY) },
});

export const updateMaintenance = (data) => ({
  type: UPDATE_MAINTENANCE,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_MAINTENANCE_MODAL,
});

export const hideModal = () => ({
  type: HIDE_MAINTENANCE_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_MAINTENANCE_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_MAINTENANCE_SPINNER,
});

export const updateObjectMaintenance = (data) => ({
  type: UPDATE_OBJECT_MAINTENANCE,
  payload: data,
});
