import { UPDATE_OBJECT_ACTIVITY } from '../actions/activity';

export function activityReducer(activity = {}, action) {
  switch (action.type) {
    case UPDATE_OBJECT_ACTIVITY:
      return action.payload;

    default:
      return activity;
  }
}
