import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

export const Container = styled.div`
  min-width: 60vw;
  padding: 20px;
`;

export const ModalTitle = styled.div`
  padding-left: 15px;
  padding-bottom: 20px;
  font-size: 1.3rem;
`;

export const InputContent = styled.form`
  width: 100%;
  padding: 20px 10px 10px 10px;
`;

export const CheckboxContent = styled.form`
  width: 100%;
  padding: 12px 10px 15px 0px;
`;

export const ButtonContainer = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

export const PicturesContainer = styled(Grid)`
  margin: 10px 0;
  max-width: 100%;
`;
export const Picture = styled.div`
  width: 100%;
  background-color: #e3e3e3;
  border: 1px solid #a0a0a0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  position: relative;
  overflow: auto;
  & > img {
    max-width: 100%;
    max-height: 100%;
  }
  & > .delete-button {
    position: absolute;
    top: 0;
    right: 0;
  }
  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`;
