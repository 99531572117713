import {
  FETCH_MOVIMENTATIONS_SUCCESS,
  FETCH_MOVIMENTATIONS_ERROR,
  SEARCH_MOVIMENTATIONS_SUCCESS,
  SEARCH_MOVIMENTATIONS_ERROR,
  GET_MOVIMENTATIONS,
  SEARCH_MOVIMENTATIONS,
  FULL_SEARCH_MOVIMENTATIONS,
  updateMovimentations,
  updateMovimentationsSearch,
  showSpinner,
  hideSpinner,
  showSearchSpinner,
  hideSearchSpinner,
} from '../actions/movimentations';
import { setMovimentationsFilter } from '../actions/movimentationsFilter';
import { apiRequest } from '../actions/api';
import { CUSTOMER_KEY } from '../../config/consts';
import { searchFields, storeSearchState } from '../../services/SearchStorage';

const URL = 'movimentations';

const params = [
  'customer',
  'equipment_name',
  'equipment_type',
  'modifier',
  'size',
  'weight',
  'grade',
  'connection_1',
  'connection_1_size',
  'connection_1_weight',
  'connection_1_grade',
  'connection_2',
  'connection_2_size',
  'connection_2_weight',
  'connection_2_grade',
  'connection_configuration',
  'material_layout',
  'SLOC',
  'project',
  'charge_code',
  'supplier',
  'manufacturer',
  'bin_location',
  'rack_location',
  'warehouse_location',
  'department',
  'status',
  'includeConsumed',
  'query',
  'in_inventory',
  'consolidated',
  'individual_item_id',
  'long_description',
];

export const getMovimentationsFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_MOVIMENTATIONS) {
    if (action.payload && action.payload.filter) {
      dispatch(setMovimentationsFilter(action.payload.filter));
    }
    dispatch(showSpinner());
    dispatch(
      apiRequest(
        'GET',
        URL,
        {
          ...action.payload,
          filter: {
            ...action.payload.filter,
            customer: localStorage.getItem(CUSTOMER_KEY),
          },
        },
        FETCH_MOVIMENTATIONS_SUCCESS,
        FETCH_MOVIMENTATIONS_ERROR,
        params,
      ),
    );
  }
};
export const searchMovimentationsFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === SEARCH_MOVIMENTATIONS) {
    dispatch(
      apiRequest(
        'GET',
        URL,
        {
          ...action.payload,
          filter: {
            ...action.payload.filter,
            customer: localStorage.getItem(CUSTOMER_KEY),
          },
        },
        SEARCH_MOVIMENTATIONS_SUCCESS,
        SEARCH_MOVIMENTATIONS_ERROR,
        ['query', 'customer', 'in_inventory', 'consolidated'],
      ),
    );
    dispatch(updateMovimentationsSearch({ docs: [], totalDocs: 0 }));
    dispatch(showSearchSpinner());
  }
};
export const fullSearchMovimentationsFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === FULL_SEARCH_MOVIMENTATIONS) {
    dispatch(setMovimentationsFilter({}));
    dispatch(
      apiRequest(
        'GET',
        URL,
        {
          ...action.payload,
          filter: {
            ...action.payload.filter,
            customer: localStorage.getItem(CUSTOMER_KEY),
          },
        },
        FETCH_MOVIMENTATIONS_SUCCESS,
        FETCH_MOVIMENTATIONS_ERROR,
        ['query', 'customer'],
      ),
    );
    // dispatch(updateMovimentationsSearch({ docs: [], totalDocs: 0 }));
    dispatch(showSearchSpinner());
  }
};

export const processMovimentationsCollection = ({ dispatch, getState }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_MOVIMENTATIONS_SUCCESS) {
    const { movimentationsFilter } = getState();
    const needToUpdateSearchHistory = searchFields.some(
      (field) => field !== 'customer' && movimentationsFilter[field],
    );
    dispatch(updateMovimentations(action.payload));
    dispatch(hideSpinner());
    dispatch(hideSearchSpinner());
    if (needToUpdateSearchHistory) {
      storeSearchState(movimentationsFilter, action.payload.totalDocs);
    }
  }
  if (action.type === SEARCH_MOVIMENTATIONS_SUCCESS) {
    dispatch(updateMovimentationsSearch(action.payload));
    dispatch(hideSearchSpinner());
  }
};

export const movimentationsMdl = [
  getMovimentationsFlow,
  searchMovimentationsFlow,
  fullSearchMovimentationsFlow,
  processMovimentationsCollection,
];
