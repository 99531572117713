export const GET_CYCLE_COUNT = '[cycleCount] GET';
export const UPDATE_CYCLE_COUNT = '[cycleCount] UPDATE';
export const CREATE_CYCLE_COUNT = '[cycleCount] CREATE';
export const DELETE_CYCLE_COUNT = '[cycleCount] DELETE';
export const UPDATE_OBJECT_CYCLE_COUNT = '[cycleCount] UPDATE OBJECT CYCLE_COUNT';
// UI
export const SHOW_CYCLE_COUNT_MODAL = '[cycleCount] SHOW MODAL';
export const HIDE_CYCLE_COUNT_MODAL = '[cycleCount] HIDE MODAL';
// Spinner
export const UPDATE_CYCLE_COUNT_SUCCESS = '[cycleCount] Update success';
export const CREATE_CYCLE_COUNT_SUCCESS = '[cycleCount] Create success';
export const UPDATE_CYCLE_COUNT_ERROR = '[cycleCount] Update Error';
export const FETCH_CYCLE_COUNT_SUCCESS = '[cycleCount] Fetch success';
export const FETCH_CYCLE_COUNT_ERROR = '[cycleCount] Fetch Error';
export const SHOW_CYCLE_COUNT_SPINNER = '[cycleCount - ui] show spinner';
export const HIDE_CYCLE_COUNT_SPINNER = '[cycleCount - ui] hide spinner';

export const getCycleCount = (id) => ({
  type: GET_CYCLE_COUNT,
  payload: { _id: id },
});

export const createCycleCount = (data) => ({
  type: CREATE_CYCLE_COUNT,
  payload: data,
});

export const updateCycleCount = (data) => ({
  type: UPDATE_CYCLE_COUNT,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_CYCLE_COUNT_MODAL,
});

export const hideModal = () => ({
  type: HIDE_CYCLE_COUNT_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_CYCLE_COUNT_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_CYCLE_COUNT_SPINNER,
});

export const updateObjectCycleCount = (data) => ({
  type: UPDATE_OBJECT_CYCLE_COUNT,
  payload: data,
});
