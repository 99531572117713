export const GET_CYCLE_COUNTS = '[cycleCounts] GET';
export const FETCH_CYCLE_COUNTS_SUCCESS = '[cycleCounts] Fetch success';
export const FETCH_CYCLE_COUNTS_ERROR = '[cycleCounts] Fetch Error';
export const UPDATE_CYCLE_COUNTS = '[cycleCounts] Update';
export const SHOW_CYCLE_COUNTS_SPINNER = '[cycleCounts - ui] show spinner';
export const HIDE_CYCLE_COUNTS_SPINNER = '[cycleCounts - ui] hide spinner';

export const getCycleCounts = (filter) => ({
  type: GET_CYCLE_COUNTS,
  payload: filter,
});

export const updateCycleCounts = (data) => ({
  type: UPDATE_CYCLE_COUNTS,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_CYCLE_COUNTS_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_CYCLE_COUNTS_SPINNER,
});
