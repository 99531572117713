export const GET_SERVICE_CATEGORIES = '[serviceCategories] GET';
export const FETCH_SERVICE_CATEGORIES_SUCCESS = '[serviceCategories] Fetch success';
export const FETCH_SERVICE_CATEGORIES_ERROR = '[serviceCategories] Fetch Error';
export const UPDATE_SERVICE_CATEGORIES = '[serviceCategories] Update';
export const SHOW_SERVICE_CATEGORIES_SPINNER = '[serviceCategories - ui] show spinner';
export const HIDE_SERVICE_CATEGORIES_SPINNER = '[serviceCategories - ui] hide spinner';

export const getServiceCategories = (filter) => ({
  type: GET_SERVICE_CATEGORIES,
  payload: filter,
});

export const updateServiceCategories = (data) => ({
  type: UPDATE_SERVICE_CATEGORIES,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_SERVICE_CATEGORIES_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_SERVICE_CATEGORIES_SPINNER,
});
