import React, { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import {
  Add as AddIcon,
  KeyboardArrowRight as CollapsedIcon,
  KeyboardArrowDown as ExpandedIcon,
} from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import ModalContainer from '../../../components/ModalContainer';
import { ModalTitle, Container, ButtonContainer } from './styles';
import CreateMaintenanceToWorkOrder from '../CreateMaintenanceToWorkOrder';
import { getMaintenances } from '../../../redux/actions/maintenances';
import {
  showModal as openMaintenanceModal,
  hideModal as closeMaintenanceModal,
} from '../../../redux/actions/maintenance';
import Spinner from '../../../components/Spinner';

const styles = {
  formButton: {
    margin: 2,
  },
};

const effectedDateMap = {
  manufacture_date: 'Date of Manufacture',
  expiration_date: 'Expiration Date',
  in_service_date: 'In Service Date',
  last_reconcile_date: 'Date Last Reconciled',
  date_last_tested: 'Date Last Tested',
  date_last_rotated: 'Date Last Rotated',
};

const headers = [
  'Maintenance Name',
  'Inspection Criteria',
  'Effected Date',
  'Interval',
];

function Row({
  maintenance,
  onEquipmentClick,
  onMaintenanceClick,
  getEquipmentSelection,
  getMaintenanceSelection,
}) {
  const [collapsed, setCollapsed] = useState(true);

  function toggleCollapsed() {
    setCollapsed((currentStatus) => !currentStatus);
  }

  return (
    <>
      <TableRow>
        <TableCell>
          <Checkbox
            color="primary"
            onClick={onMaintenanceClick(maintenance)}
            checked={getMaintenanceSelection(maintenance) === 1}
            indeterminate={!getMaintenanceSelection(maintenance)}
          />
        </TableCell>
        <TableCell>
          <IconButton
            size="small"
            color={collapsed ? 'default' : 'primary'}
            onClick={toggleCollapsed}
          >
            {collapsed ? <CollapsedIcon /> : <ExpandedIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{maintenance?.maintenance?.name}</TableCell>
        <TableCell>{maintenance?.inspectionCriteria?.name}</TableCell>
        <TableCell>{effectedDateMap[maintenance?.effectedDate]}</TableCell>
        <TableCell>{maintenance?.interval}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={!collapsed} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Equipment Selected
              </Typography>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Unique ID</TableCell>
                    <TableCell>Customer MM No</TableCell>
                    <TableCell>Total Qty. (EA)</TableCell>
                    <TableCell>Footage</TableCell>
                    <TableCell>COPAS Class</TableCell>
                    <TableCell>Supplier</TableCell>
                    <TableCell>Batch No</TableCell>
                    <TableCell>Heat/Lot</TableCell>
                    <TableCell>RBW</TableCell>
                    <TableCell>Facility Reference ID</TableCell>
                    <TableCell>Manufacture</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {maintenance.equipment?.map((eqp) => (
                    <TableRow key={eqp._id}>
                      <TableCell>
                        <Checkbox
                          color="primary"
                          checked={getEquipmentSelection(maintenance, eqp._id)}
                          onClick={onEquipmentClick(maintenance, eqp._id)}
                        />
                      </TableCell>
                      <TableCell>{eqp.individual_item_id}</TableCell>
                      <TableCell>{eqp.customer_MM?.mm_number}</TableCell>
                      <TableCell>{eqp.qty}</TableCell>
                      <TableCell>{eqp.footage}</TableCell>
                      <TableCell>{eqp.classification}</TableCell>
                      <TableCell>{eqp.supplier}</TableCell>
                      <TableCell>{eqp.batch_no}</TableCell>
                      <TableCell>{eqp.heat}</TableCell>
                      <TableCell>{eqp.RBW}</TableCell>
                      <TableCell>{eqp.facility_reference_id}</TableCell>
                      <TableCell>{eqp.manufacturer}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function AddMaintenanceToWorkOrder({
  open,
  onClose,
  equipment = [],
  handleEditRevision,
  maintenances: existentMaintenances = [],
}) {
  const [selected, setSelected] = useState([]);
  const dispatch = useDispatch();
  const maintenances = useSelector((state) => state.maintenances?.docs || []);
  const loading = useSelector((state) => state.ui.maintenancesLoading);
  const maintenanceModal = useSelector((state) => state.ui.maintenanceModal);

  useEffect(() => {
    if (!equipment || !equipment.length) return;
    dispatch(
      getMaintenances({
        filter: {
          listAll: true,
          equipment: equipment.map(({ _id }) => _id),
          group: true,
        },
      })
    );
  }, [equipment]);

  function getMaintenanceIndex(list, maintenanceData, listHasSubProp) {
    const {
      maintenance,
      inspectionCriteria,
      effectedDate,
      interval,
      notificationTrigger,
      notificationFrequency,
    } = maintenanceData;

    const maintenanceToCompare = maintenance?._id;
    const inspectionCriteriaToCompare = inspectionCriteria?._id;

    return list.findIndex((mnt) => {
      const item = listHasSubProp ? mnt.maintenance : mnt;
      const maintenanceFromList = item.maintenance?._id;
      const inspectionCriteriaFromList = item.inspectionCriteria?._id;
      return (
        maintenanceFromList?.toString() === maintenanceToCompare?.toString() &&
        inspectionCriteriaFromList?.toString() ===
          inspectionCriteriaToCompare?.toString() &&
        item.effectedDate === effectedDate &&
        item.interval === interval &&
        item.notificationTrigger === notificationTrigger &&
        item.notificationFrequency === notificationFrequency
      );
    });
  }

  function getMaintenanceData(list, maintenanceData, listHasSubProp) {
    const {
      maintenance,
      inspectionCriteria,
      effectedDate,
      interval,
      notificationTrigger,
      notificationFrequency,
    } = maintenanceData;

    const maintenanceToCompare = maintenance?._id;
    const inspectionCriteriaToCompare = inspectionCriteria?._id;

    return list.find((mnt) => {
      const item = listHasSubProp ? mnt.maintenance : mnt;
      const maintenanceFromList = item.maintenance?._id;
      const inspectionCriteriaFromList = item.inspectionCriteria?._id;
      return (
        maintenanceFromList?.toString() === maintenanceToCompare?.toString() &&
        inspectionCriteriaFromList?.toString() ===
          inspectionCriteriaToCompare?.toString() &&
        item.effectedDate === effectedDate &&
        item.interval === interval &&
        item.notificationTrigger === notificationTrigger &&
        item.notificationFrequency === notificationFrequency
      );
    });
  }

  function getCompleteSelection() {
    let thereIsAPartiallySelectedMnt = false;
    const notCompletelySelectedMnt = maintenances.filter((item) => {
      const itemSelection = getMaintenanceSelection(item, item);
      if (itemSelection === 0) thereIsAPartiallySelectedMnt = true;
      return itemSelection < 1;
    });
    if (!notCompletelySelectedMnt.length) return 1;
    return notCompletelySelectedMnt.length < maintenances.length ||
      thereIsAPartiallySelectedMnt
      ? 0
      : -1;
  }

  function getMaintenanceSelection(maintenance, fullItemParam) {
    const item = getMaintenanceData(selected, maintenance, true);
    if (!item) return -1;
    const fullItem =
      fullItemParam || getMaintenanceData(maintenances, maintenance, false);
    if (!fullItem) return -1;
    return fullItem.equipment.every(({ _id }) => item.equipment.includes(_id))
      ? 1
      : 0;
  }

  function getEquipmentSelection(maintenance, equipmentId) {
    const item = getMaintenanceData(selected, maintenance, true);
    if (!item) return false;
    return item.equipment.some((eqp) => eqp === equipmentId);
  }

  const areAllSelected = getCompleteSelection();

  function toggleSelectAll() {
    if (selected.length) return setSelected([]);
    setSelected(
      maintenances.map((maintenance) => ({
        maintenance: {
          maintenance: maintenance.maintenance,
          inspectionCriteria: maintenance.inspectionCriteria,
          effectedDate: maintenance.effectedDate,
          notificationFrequency: maintenance.notificationFrequency,
          notificationTrigger: maintenance.notificationTrigger,
          interval: maintenance.interval,
        },
        equipment: maintenance.equipment.map(({ _id }) => _id),
      }))
    );
  }

  function toggleSelectMaintenance(maintenance) {
    return function () {
      const itemIndex = getMaintenanceIndex(selected, maintenance, true);
      if (itemIndex > -1)
        return setSelected((currentSelected) => {
          const copy = [...currentSelected];
          copy.splice(itemIndex, 1);
          return copy;
        });

      const fullItem = getMaintenanceData(maintenances, maintenance, false);
      if (!fullItem) return;
      setSelected((currentSelected) => [
        ...currentSelected,
        {
          maintenance: {
            maintenance: fullItem.maintenance,
            inspectionCriteria: fullItem.inspectionCriteria,
            effectedDate: fullItem.effectedDate,
            notificationFrequency: fullItem.notificationFrequency,
            notificationTrigger: fullItem.notificationTrigger,
            interval: fullItem.interval,
          },
          equipment: fullItem.equipment.map(({ _id }) => _id),
        },
      ]);
    };
  }

  function toggleSelectEquipment(maintenanceData, id) {
    return function () {
      const mntIndex = getMaintenanceIndex(selected, maintenanceData, true);
      if (mntIndex === -1)
        return setSelected((currentSelected) => [
          ...currentSelected,
          {
            maintenance: {
              maintenance: maintenanceData.maintenance,
              inspectionCriteria: maintenanceData.inspectionCriteria,
              effectedDate: maintenanceData.effectedDate,
              notificationFrequency: maintenanceData.notificationFrequency,
              notificationTrigger: maintenanceData.notificationTrigger,
              interval: maintenanceData.interval,
            },
            equipment: [id],
          },
        ]);

      const eqpIndex = selected[mntIndex].equipment.findIndex(
        (eqp) => eqp === id
      );
      if (eqpIndex > -1)
        return setSelected((currentSelected) => {
          const copy = [...currentSelected];
          copy[mntIndex].equipment.splice(eqpIndex, 1);
          if (!copy[mntIndex].equipment.length) {
            copy.splice(mntIndex, 1);
          }
          return copy;
        });

      return setSelected((currentSelected) => {
        const copy = [...currentSelected];
        copy[mntIndex].equipment = [...copy[mntIndex].equipment, id];
        return copy;
      });
    };
  }

  function openModal() {
    dispatch(openMaintenanceModal());
  }

  function closeModal() {
    dispatch(closeMaintenanceModal());
  }

  function onAdd() {
    handleEditRevision({
      target: {
        name: 'maintenances',
        value: [...existentMaintenances, ...selected],
      },
    });
    onClose();
  }

  return (
    <>
      <CreateMaintenanceToWorkOrder
        open={maintenanceModal}
        onClose={closeModal}
        equipment={equipment}
      />
      <ModalContainer open={open} handleClose={onClose}>
        <Container>
          <ModalTitle>
            Tracked Maintenance{' '}
            <Button
              startIcon={<AddIcon />}
              variant="outlined"
              color="primary"
              size="small"
              onClick={openModal}
            >
              New Maintenance
            </Button>
          </ModalTitle>
          <Table style={{ width: '100%' }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    color="primary"
                    onClick={toggleSelectAll}
                    checked={areAllSelected === 1}
                    indeterminate={areAllSelected === 0}
                  />
                </TableCell>
                <TableCell />
                {headers.map((header) => (
                  <TableCell key={header}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!maintenances.length && !loading && (
                <TableRow>
                  <TableCell colSpan={6}>
                    <Typography variant="subtitle1" component="center">
                      No maintenance tracked
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
              {loading && (
                <TableRow>
                  <TableCell colSpan={6}>
                    <Spinner parent="Table" />
                  </TableCell>
                </TableRow>
              )}
              {!loading &&
                maintenances.map((maintenance) => (
                  <Row
                    key={maintenance._id}
                    maintenance={maintenance}
                    onMaintenanceClick={toggleSelectMaintenance}
                    onEquipmentClick={toggleSelectEquipment}
                    getMaintenanceSelection={getMaintenanceSelection}
                    getEquipmentSelection={getEquipmentSelection}
                  />
                ))}
            </TableBody>
          </Table>
          <ButtonContainer>
            <Button
              color="secondary"
              onClick={onClose}
              variant="contained"
              style={styles.formButton}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={onAdd}
              variant="contained"
              style={styles.formButton}
            >
              Save
            </Button>
          </ButtonContainer>
        </Container>
      </ModalContainer>
    </>
  );
}

export default AddMaintenanceToWorkOrder;
