export const GET_RECLASS_DAMAGES = '[reclassDamages] GET';
export const FETCH_RECLASS_DAMAGES_SUCCESS = '[reclassDamages] Fetch success';
export const FETCH_RECLASS_DAMAGES_ERROR = '[reclassDamages] Fetch Error';
export const UPDATE_RECLASS_DAMAGES = '[reclassDamages] Update';
export const SHOW_RECLASS_DAMAGES_SPINNER = '[reclassDamages - ui] show spinner';
export const HIDE_RECLASS_DAMAGES_SPINNER = '[reclassDamages - ui] hide spinner';

export const getReclassDamages = (filter) => ({
  type: GET_RECLASS_DAMAGES,
  payload: filter,
});

export const updateReclassDamages = (data) => ({
  type: UPDATE_RECLASS_DAMAGES,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_RECLASS_DAMAGES_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_RECLASS_DAMAGES_SPINNER,
});
