import {
  FETCH_OWNERS_SUCCESS,
  FETCH_OWNERS_ERROR,
  GET_OWNERS,
  updateOwners,
  showSpinner,
  hideSpinner,
} from '../actions/owners';
import { apiRequest } from '../actions/api';

const URL = 'owners';

export const getOwnersFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_OWNERS) {
    dispatch(
      apiRequest(
        'GET',
        URL,
        action.payload,
        FETCH_OWNERS_SUCCESS,
        FETCH_OWNERS_ERROR,
        ['listAll', 'active'],
      ),
    );
    dispatch(showSpinner());
  }
};

export const processOwnersCollection = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_OWNERS_SUCCESS) {
    dispatch(updateOwners(action.payload));
    dispatch(hideSpinner());
  }
};

export const ownersMdl = [getOwnersFlow, processOwnersCollection];
