import {
  FETCH_HISTORY_SUCCESS,
  FETCH_HISTORY_ERROR,
  GET_HISTORY,
  updateHistory,
  showSpinner,
  hideSpinner,
} from '../actions/history';
import { apiRequest } from '../actions/api';

const URL = 'events';

export const getHistoryFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_HISTORY) {
    dispatch(
      apiRequest(
        'GET',
        URL,
        action.payload,
        FETCH_HISTORY_SUCCESS,
        FETCH_HISTORY_ERROR,
        [
          'event',
          'movimentation',
          'period_start',
          'period_end',
          'query',
          'facilityReferenceID',
          'SLOC',
          'batch',
          'project',
          'chargeCode',
          'transactionId',
          'inventory',
          'author',
        ],
      ),
    );
    dispatch(showSpinner());
  }
};

export const processHistoryCollection = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_HISTORY_SUCCESS) {
    dispatch(updateHistory(action.payload));
    dispatch(hideSpinner());
  }
};

export const historyMdl = [getHistoryFlow, processHistoryCollection];
