import {
  GET_ORIGINAL_CHARGES,
  UPDATE_ORIGINAL_CHARGES,
} from '../actions/originalCharges';

const initState = {
  docs: [],
  page: 1,
  limit: 10,
  totalDocs: 0,
};

export function originalChargesReducer(originalCharges = initState, action) {
  switch (action.type) {
    case UPDATE_ORIGINAL_CHARGES:
      return action.payload;
    case GET_ORIGINAL_CHARGES:
    default:
      return originalCharges;
  }
}
