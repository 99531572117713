import React from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import DefaultInput from './DefaultInput';
import TabPanel from '../../../../components/TabPanel';
import InputInfo from '../../../../components/InputInfo';

const getInputProps = (title) => ({
  endAdornment: <InputInfo size="small" title={title} />,
});

function FinancialData({ data, selectedTab, tabIndex, onChange, setData }) {
  const equipmentLoading = useSelector(
    (state) => state.ui.movimentationLoading
  );

  const handleChangeCurrencyField = (e, value) => {
    const { name } = e.target;
    setData({ [name]: value });
  };

  const fields = [
    { label: 'Purchase Price', key: 'purchase_price' },
    { label: 'Fabrication Costs', key: 'fabrication_costs' },
    {
      label: 'Service Value',
      key: 'service_value',
      value: data.service_value * 100,
      InputProps: { endAdornment: '%' },
      type: 'number',
      currency: false,
      onChange: onChange,
    },
    {
      label: 'Service Cost',
      key: 'service_cost',
      InputProps: getInputProps(
        'Default value is Service Value percent of Purchase Price.'
      ),
    },
    { label: 'Transport Value', key: 'transport_value' },
    {
      label: 'Transport Cost',
      key: 'transportation_cost',
      InputProps: getInputProps(
        'Default value is the Transport Value multiplied by the total footage and the material weight.'
      ),
    },
    { label: 'Handling Value', key: 'handling_value' },
    {
      label: 'Handling Cost',
      key: 'handling_cost',
      InputProps: getInputProps(
        'Default value is the Handling Value multiplied by the total footage.'
      ),
    },
    { label: 'Inspection Value', key: 'inspection_value' },
    {
      label: 'Inspection Cost',
      key: 'inspection_cost',
      InputProps: getInputProps(
        'Default value is the Inspection Value multiplied by the total footage.'
      ),
    },
    {
      label: 'Material Value Including Services',
      key: 'material_value_plus_services',
      InputProps: getInputProps(
        'Default value is the sum of Fabrication Costs, Service Cost, Transportation Cost, Handling Cost and Inspection Cost.'
      ),
    },
    {
      label: 'Price per FT',
      key: 'price_per_foot_cost',
      InputProps: getInputProps(
        'Material Value Including Services divided by total footage.'
      ),
      disabled: true,
    },
    {
      label: 'Price per EA',
      key: 'unit_cost',
      InputProps: getInputProps(
        'Material Value Including Services divided by quantity.'
      ),
      disabled: true,
    },
    {
      label: 'Current Material Value',
      key: 'current_material_value',
      InputProps: getInputProps(
        'Material Value Including Services multiplied by COPAS Class modifier.'
      ),
      disabled: true,
    },
  ];

  return (
    <TabPanel value={selectedTab} index={tabIndex} dir="ltr">
      <Grid container style={{ opacity: equipmentLoading ? 0.3 : 1 }}>
        {fields.map(({ key, ...props }) => (
          <DefaultInput
            key={key}
            name={key}
            value={data[key]}
            inputProps={{
              style: { textAlign: 'right', paddingRight: 14 },
            }}
            InputLabelProps={{ shrink: true }}
            currency
            onChange={handleChangeCurrencyField}
            {...props}
          />
        ))}
      </Grid>
    </TabPanel>
  );
}

export default FinancialData;
