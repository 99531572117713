import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { TextField, Button, CircularProgress } from '@material-ui/core';
import DropZone from '../../../components/DropZone';
import Spinner from '../../../components/Spinner';
import { Container, InputContent, ModalTitle, ButtonContainer } from './styles';
import { useParams } from 'react-router-dom';
import uploadFile from '../../../services/Inspection/uploadFile';
import Toastify from '../../../utils/Toastify';

const styles = {
  formButton: {
    margin: 2,
  },
};

const AdditionalNotes = ({
  dispatch,
  handleClose,
  additionalNotesSpinner,
  onSave,
  editData,
  onDelete,
}) => {
  const [noteError, setNoteError] = useState(false);
  const [data, setData] = useState({
    note: '',
  });
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const loggedUser = useSelector((state) => state.loggedUser?._id);

  const { id } = useParams();
  useEffect(() => {
    if (editData) setData(editData);
    else
      setData({
        note: '',
      });
  }, [editData]);
  const { note } = data;

  const handleSave = async () => {
    setLoading(true);
    let err = false;
    setNoteError(!note);
    err = !note;

    if (!err) {
      if (file) {
        uploadFile(file, { workOrderId: id, user: loggedUser })
          .then(({ ok, data: responseData }) => {
            setLoading(false)
            if (!ok)
              Toastify.addError(
                "Error - couldn't upload file, please contact support."
              );
            else onSave({ ...data, attachment: responseData });
          })
          .catch((e) => {
            setLoading(false);
            Toastify.addError(
              "Error - couldn't upload file, please contact support."
            );
          });
      } else {
        setLoading(false);
        onSave(data);}
    }
  };

  const handleEdit = (e) => {
    const { name, value } = e.target;
    setData((currentData) => ({ ...currentData, [name]: value }));
  };

  return (
    <>
      {additionalNotesSpinner && <Spinner overlay />}
      <Container style={{ opacity: additionalNotesSpinner ? 0.5 : 1 }}>
        <ModalTitle>New Note</ModalTitle>
        <InputContent>
          <TextField
            label="Note"
            required
            multiline
            type="text"
            error={noteError}
            helperText={noteError && 'Note is required'}
            variant="outlined"
            value={note || ''}
            name="note"
            onChange={handleEdit}
            fullWidth
          />
        </InputContent>

        <DropZone
          file={file}
          allowedExtensions="any"
          onChangeFile={setFile}
          disabled={id === 'new'}
        />

        <ButtonContainer>
          {editData && (
            <Button
              color="secondary"
              onClick={onDelete}
              variant="outlined"
              style={styles.formButton}
            >
              Delete
            </Button>
          )}
          <Button
            color="secondary"
            onClick={handleClose}
            variant="contained"
            style={styles.formButton}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={handleSave}
            variant="contained"
            style={styles.formButton}
            disabled={loading}
            startIcon={loading && <CircularProgress size={14} />}
          >
            Save
          </Button>
        </ButtonContainer>
      </Container>
    </>
  );
};

export default connect((state) => ({
  additionalNotesSpinner: state.ui.additionalNotesLoading,
}))(AdditionalNotes);
