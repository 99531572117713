import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {
  AddRounded,
  ArrowBack,
  EditRounded,
  CloudUploadOutlined,
  Search,
} from '@material-ui/icons';
import { getOriginalCharge } from '../../redux/actions/originalCharges';
import {
  showModal,
  hideModal,
  updateOriginalCharge,
} from '../../redux/actions/originalCharge';
import Spinner from '../../components/Spinner/index';
import { Container } from './styles';
import ModalContainer from '../../components/ModalContainer/index';
import EditModal from '../Modals/OriginalCharge';
import { CUSTOMER_KEY } from '../../config/consts';
import { tableStyles } from '../../styles/tableStyles';

const Home = ({
  dispatch,
  originalCharges,
  originalChargesLoading,
  originalChargeModal,
}) => {
  const customer = localStorage.getItem(CUSTOMER_KEY);
  const history = useHistory();
  const classes = tableStyles();
  const [originalChargesFormated, setOriginalChargesFormated] = useState([]);
  const [modalContentId, setModalContentId] = useState('new');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(50);
  const [total, setTotal] = useState(0);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (!localStorage.getItem(CUSTOMER_KEY)) return;
    dispatch(
      getOriginalCharge({ page: page + 1, limit, filter: { customer } })
    );
  }, [page, limit]);

  useEffect(() => {
    if (originalCharges.docs) {
      setOriginalChargesFormated(originalCharges.docs);
      setTotal(originalCharges.totalDocs);
    }
  }, [originalCharges]);

  const handleBack = () => {
    history.replace('/equipments');
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleOpenModal = () => {
    dispatch(showModal());
  };
  const handleClick = (id) => {
    setModalContentId(id);
    handleOpenModal();
  };

  const handleCloseModal = () => {
    dispatch(hideModal());
  };

  function handleChangeActiveStatus(chargeCode) {
    function handler(e) {
      dispatch(
        updateOriginalCharge({ ...chargeCode, active: e.target.checked })
      );
    }
    return handler;
  }

  const handleGoToImport = () => {
    history.push('/charge_code/import');
  };

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(getOriginalCharge({ page: 1, limit, searchText }));
  };

  return (
    <>
      <Container>
        <ModalContainer
          open={originalChargeModal}
          handleClose={handleCloseModal}
        >
          <EditModal id={modalContentId} handleClose={handleCloseModal} />
        </ModalContainer>
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <Toolbar className={classes.toolBar}>
              <span>
                <IconButton onClick={handleBack}>
                  <ArrowBack />
                </IconButton>
                <Typography
                  className={classes.title}
                  variant="h6"
                  id="tableTitle"
                  component="span"
                >
                  Charge Codes
                </Typography>
              </span>
              <span>
                <Button
                  className={classes.buttons}
                  onClick={() => handleClick('new')}
                  startIcon={<AddRounded />}
                >
                  New
                </Button>
                <Button
                  color="secondary"
                  onClick={handleGoToImport}
                  startIcon={<CloudUploadOutlined />}
                >
                  Import List
                </Button>
              </span>
            </Toolbar>
            <div
              style={{
                paddingTop: 10,
                width: 'fullWidth',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                marginLeft: '2rem',
                marginRight: '2rem',
              }}
            >
              <TextField
                label="Search"
                type="text"
                variant="standard"
                value={searchText}
                size="small"
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={handleSearch} size="small">
                      <Search />
                    </IconButton>
                  ),
                }}
                name="role_name"
                onChange={(e) => setSearchText(e.target.value)}
                onKeyPress={(e) => (e.key === 'Enter' ? handleSearch(e) : null)}
                style={{ width: 300 }}
              />
            </div>
            <TableContainer className={classes.table}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Code</TableCell>
                    <TableCell>Project</TableCell>
                    <TableCell>Assigned Well</TableCell>
                    <TableCell>Active</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!localStorage.getItem(CUSTOMER_KEY) ? (
                    <TableRow className={classes.row}>
                      <TableCell
                        colSpan="5"
                        className={classes.cell}
                        align="center"
                      >
                        <Typography variant="h6">
                          Select an inventory
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <>
                      {originalChargesLoading && (
                        <TableRow className={classes.row}>
                          <TableCell colSpan="5" className={classes.cell}>
                            <Spinner parent="Table" />
                          </TableCell>
                        </TableRow>
                      )}
                      {!originalChargesLoading &&
                        originalChargesFormated.map((originalCharge) => (
                          <TableRow className={classes.row}>
                            <TableCell className={classes.cell}>
                              <b>
                                {originalCharge.original_charge_code || '--'}
                              </b>
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {originalCharge.well?.project?.name || '--'}
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {originalCharge.well?.name || '--'}
                            </TableCell>
                            <TableCell className={classes.cell}>
                              <Switch
                                color="primary"
                                checked={originalCharge.active}
                                onChange={handleChangeActiveStatus(
                                  originalCharge
                                )}
                              />
                            </TableCell>
                            <TableCell className={classes.cell}>
                              <IconButton
                                onClick={() => handleClick(originalCharge._id)}
                              >
                                <EditRounded />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[50, 100, 200, 500, 1000]}
              component="div"
              count={total}
              rowsPerPage={limit}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  originalChargesLoading: state.ui.originalChargesLoading,
  originalCharges: state.originalCharges,
  originalChargeModal: state.ui.originalChargeModal,
});

export default connect(mapStateToProps)(Home);
