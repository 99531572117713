import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Animated } from 'react-animated-css';
import {
  Grid, Button, TextField, FormHelperText, FormControl, Box,
} from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';
import { Container, Form, Picture } from './styles';
import Toastr from '../../utils/Toastify/index';
import { getProfile, updateProfile } from '../../redux/actions/profile';

const NoImage = 'https://nemsmbr.org/wp-content/uploads/2019/03/no-image-icon-md-2.png';

function Profile({ profile, dispatch }) {
  const [nameForm, setNameForm] = useState('');
  const [phoneForm, setPhoneForm] = useState('');
  const [nameError, setNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);

  useEffect(() => {
    dispatch(getProfile());
  }, []);

  useEffect(() => {
    setNameForm(profile.name);
    setPhoneForm(profile.phone_number);
  }, [profile]);

  const inputStyle = {
    // width: "80%",
    // marginTop: 20,
  };

  const handleSave = async () => {
    if (nameForm !== '' && phoneForm !== '') {
      dispatch(
        updateProfile({
          name: nameForm,
          phone_number: phoneForm,
        }),
      );
      setNameError(false);
      setPhoneError(false);
    } else {
      setNameError(nameForm === '');
      setPhoneError(phoneForm === '');
      Toastr.addError('Please fill all fields');
    }
  };

  return (
    <Container>
      <Animated
        animationIn="fadeInLeft"
        animationOut="fadeOut"
        animationInDuration={500}
        animationOutDuration={1000}
      >
        <Form>
          <Box m={5}>
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                <Picture src={profile.picture ? profile.picture : NoImage} />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                <TextField
                  onChange={(e) => setNameForm(e.target.value)}
                  value={nameForm}
                  variant="outlined"
                  label="Name"
                  style={inputStyle}
                  error={nameError}
                  fullWidth
                  helperText={nameError ? 'Please fill this field' : ''}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                <FormControl fullWidth error={phoneError} style={{ marginTop: 20 }}>
                  <PhoneInput
                    fullWidth
                    country="us"
                    specialLabel="Phone Number"
                    containerStyle={{ width: '100%' }}
                    inputStyle={{ width: '100%' }}
                    value={phoneForm}
                    onChange={(_rawValue, _data, _event, value) => setPhoneForm(value)}
                  />
                  {phoneError && (
                    <FormHelperText>
                      Please fill this field
                    </FormHelperText>
                  )}
                </FormControl>
                {/* <TextField
                  id="standard-basic"
                  onChange={(e) => setPhoneForm(e.target.value)}
                  value={phoneForm}
                  label="Phone Number"
                  style={inputStyle}
                  error={phoneError}
                  helperText={phoneError ? "Please fill this field" : ""}
                /> */}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleSave}
                  style={{ marginTop: 20 }}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Form>
      </Animated>
    </Container>
  );
}

export default connect((state) => ({ profile: state.profile }))(Profile);
