import { CUSTOMER_KEY } from '../../config/consts';

export const GET_CHECK_IN = '[check-in] GET';
export const UPDATE_CHECK_IN = '[check-in] UPDATE';
export const CREATE_CHECK_IN = '[check-in] CREATE';
export const DELETE_CHECK_IN = '[check-in] DELETE';
export const UPDATE_OBJECT_CHECK_IN = '[check-in] UPDATE OBJECT CHECK IN';
// UI
export const SHOW_CHECK_IN_MODAL = '[check-in] SHOW MODAL';
export const HIDE_CHECK_IN_MODAL = '[check-in] HIDE MODAL';
// Spinner
export const UPDATE_CHECK_IN_SUCCESS = '[check-in] Update success';
export const UPDATE_CHECK_IN_ERROR = '[check-in] Update Error';
export const FETCH_CHECK_IN_SUCCESS = '[check-in] Fetch success';
export const FETCH_CHECK_IN_ERROR = '[check-in] Fetch Error';
export const SHOW_CHECK_IN_SPINNER = '[check-in - ui] show spinner';
export const HIDE_CHECK_IN_SPINNER = '[check-in - ui] hide spinner';

export const getCheckIn = (id) => ({
  type: GET_CHECK_IN,
  payload: { _id: id },
});

export const createCheckIn = (data) => ({
  type: CREATE_CHECK_IN,
  payload: { ...data, customer: localStorage.getItem(CUSTOMER_KEY) },
});

export const updateCheckIn = (data) => ({
  type: UPDATE_CHECK_IN,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_CHECK_IN_MODAL,
});

export const hideModal = () => ({
  type: HIDE_CHECK_IN_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_CHECK_IN_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_CHECK_IN_SPINNER,
});

export const updateObjectCheckIn = (data) => ({
  type: UPDATE_OBJECT_CHECK_IN,
  payload: data,
});
