import SweetAlert from 'sweetalert2';

const swalWithBootstrapButtons = SweetAlert.mixin({
  customClass: {
    confirmButton: 'btn btn-success',
    cancelButton: 'btn btn-danger',
  },
  buttonsStyling: true,
});

export default function ({
  title = 'Are you sure?',
  text = "You won't be able to revert this!",
  icon = 'warning',
  showCancelButton = true,
  confirmButtonText = 'Yes, save it.',
  cancelButtonText = 'No, cancel.',
  reverseButtons = true,
  input = 'text',
  inputLabel,
  inputPlaceholder,
  inputAttributes = {
    maxlength: 30,
    autocapitalize: 'off',
    autocorrect: 'off',
  },
  inputValidator,
  inputValue,
}) {
  const options = {
    title,
    text,
    icon,
    showCancelButton,
    confirmButtonText,
    cancelButtonText,
    reverseButtons,
    input,
    inputLabel,
    inputPlaceholder,
    inputAttributes,
    inputValidator,
    inputValue,
  };
  return swalWithBootstrapButtons.fire(options);
}
