import React from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import DefaultInput from './DefaultInput';
import getColorFromClass from '../../../../utils/Equipment/getColorFromClass';
import TabPanel from '../../../../components/TabPanel';
import { MaterialLayoutMap } from '../../../../utils/StaticData/MaterialLayouts/data';

const classes = ['A', 'AR', 'C', 'CR', 'B', 'BR', 'E'];

function TechnicalData({ data, selectedTab, tabIndex, onChange, errors = [] }) {
  const equipmentLoading = useSelector(
    (state) => state.ui.movimentationLoading
  );

  const fields = [
    { label: 'Manufacturer Serial Number', key: 'manufacturer_serial_no' },
    { label: 'Supplier', key: 'supplier' },
    { label: 'Supplier Part Number', key: 'supplier_part_no' },
    { label: 'Supplier Serial Number', key: 'supplier_serial_no' },
    { label: 'Heat Number', key: 'heat' },
    { label: 'Joint/Run Number', key: 'joint_no' },
    { label: 'RBW', key: 'RBW' },
    {
      label: 'COPAS Class',
      key: 'classification',
      options: classes.map((item) => ({ value: item, label: item })),
      extraProps: {
        required: true,
        error: errors.includes('classification'),
        style: {
          backgroundColor: getColorFromClass(data.classification),
        },
        helperText:
          errors.includes('classification') && 'COPAS Class is required',
      },
    },
    {
      label: 'Length',
      key: 'length',
      extraProps: { InputProps: { endAdornment: 'in' }, type: 'number' },
    },
    {
      label: 'Width',
      key: 'width',
      extraProps: { InputProps: { endAdornment: 'in' }, type: 'number' },
    },
    {
      label: 'Height',
      key: 'height',
      extraProps: { InputProps: { endAdornment: 'in' }, type: 'number' },
    },
    {
      label: 'Material Layout',
      key: 'material_layout',
      options: Object.keys(MaterialLayoutMap).map((item) => ({
        value: item,
        label: MaterialLayoutMap[item],
      })),
      extraProps: {
        required: true,
        error: errors.includes('material_layout'),
        helperText:
          errors.includes('material_layout') && 'Material Layout is required',
      },
    },
  ];

  return (
    <TabPanel value={selectedTab} index={tabIndex} dir="ltr">
      <Grid container style={{ opacity: equipmentLoading ? 0.3 : 1 }}>
        {fields.map(({ label, key, options, extraProps }) => (
          <DefaultInput
            label={label}
            value={data[key]}
            onChange={onChange}
            name={key}
            key={key}
            options={options}
            error={errors.includes(key)}
            helperText={errors.includes(key) && `${label} is required`}
            {...extraProps}
          />
        ))}
      </Grid>
    </TabPanel>
  );
}

export default TechnicalData;
