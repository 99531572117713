import {
  UPDATE_ACTIVITY_SUCCESS,
  UPDATE_ACTIVITY_ERROR,
  FETCH_ACTIVITY_SUCCESS,
  FETCH_ACTIVITY_ERROR,
  GET_ACTIVITY,
  CREATE_ACTIVITY,
  UPDATE_ACTIVITY,
  updateObjectActivity,
  showSpinner,
  hideSpinner,
  hideModal,
} from '../actions/activity';
import { getActivities } from '../actions/activities';
import Toastify from '../../utils/Toastify';
import { apiRequest } from '../actions/api';

const URL = 'activities';

export const createActivityFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === CREATE_ACTIVITY) {
    dispatch(
      apiRequest(
        'POST',
        URL,
        action.payload,
        UPDATE_ACTIVITY_SUCCESS,
        UPDATE_ACTIVITY_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const updateActivityFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === UPDATE_ACTIVITY) {
    dispatch(
      apiRequest(
        'PUT',
        URL,
        action.payload,
        UPDATE_ACTIVITY_SUCCESS,
        UPDATE_ACTIVITY_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const getActivityFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_ACTIVITY) {
    const { _id } = action.payload;
    dispatch(
      apiRequest(
        'GET-id',
        URL,
        { _id },
        FETCH_ACTIVITY_SUCCESS,
        FETCH_ACTIVITY_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const processActivityCollection = ({ dispatch, getState }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_ACTIVITY_SUCCESS) {
    dispatch(updateObjectActivity(action.payload));
    dispatch(hideSpinner());
  } else if (action.type === UPDATE_ACTIVITY_SUCCESS) {
    dispatch(updateObjectActivity(action.payload));
    dispatch(hideModal());
    dispatch(hideSpinner());
    // To reload the page
    const { page, limit } = getState().activities;
    dispatch(getActivities({ page, limit }));
    Toastify.addSuccess('Activity saved.');
  } else if (action.type === UPDATE_ACTIVITY_ERROR) {
    dispatch(hideSpinner());
    Toastify.addError(action.payload || 'Error to save the activity');
  }
};

export const activityMdl = [
  createActivityFlow,
  updateActivityFlow,
  getActivityFlow,
  processActivityCollection,
];
