/* eslint-disable object-curly-newline */
import MaterialLayouts from '../MaterialLayouts/data';

export default [
  // { value: 'bin_location', label: 'Bin Location', type: 'String' },
  // { value: 'rack_location', label: 'Rack Location', type: 'String' },
  // { value: 'warehouse_location', label: 'Warehouse Location', type: 'String' },
  {
    value: 'original_charge',
    label: 'Assigned Charge Code',
    type: 'ObjectId',
    labelRef: 'original_charge_code',
    route: 'original_charges',
    ref: 'chargeCodes',
  },
  {
    value: 'connection_1',
    label: 'Connection 1',
    type: 'Autocomplete',
    ref: 'connection_1',
    route: 'material_masters',
  },
  {
    value: 'connection_1_size',
    label: 'Connection 1 (SIZE)',
    type: 'Autocomplete',
    ref: 'connection_1_size',
    route: 'material_masters',
  },
  {
    value: 'connection_1_weight',
    label: 'Connection 1 (WEIGHT)',
    type: 'Autocomplete',
    ref: 'connection_1_weight',
    route: 'material_masters',
  },
  {
    value: 'connection_1_grade',
    label: 'Connection 1 (GRADE)',
    type: 'Autocomplete',
    ref: 'connection_1_grade',
    route: 'material_masters',
  },
  {
    value: 'connection_2',
    label: 'Connection 2',
    type: 'Autocomplete',
    ref: 'connection_2',
    route: 'material_masters',
  },
  {
    value: 'connection_2_size',
    label: 'Connection 2 (SIZE)',
    type: 'Autocomplete',
    ref: 'connection_2_size',
    route: 'material_masters',
  },
  {
    value: 'connection_2_weight',
    label: 'Connection 2 (WEIGHT)',
    type: 'Autocomplete',
    ref: 'connection_2_weight',
    route: 'material_masters',
  },
  {
    value: 'connection_2_grade',
    label: 'Connection 2 (GRADE)',
    type: 'Autocomplete',
    ref: 'connection_2_grade',
    route: 'material_masters',
  },
  {
    value: 'connection_configuration',
    label: 'Connection Configuration',
    type: 'Autocomplete',
    ref: 'connection_configuration',
    route: 'material_masters',
  },
  {
    value: 'equipment_name',
    label: 'Equipment Name',
    type: 'Autocomplete',
    ref: 'short_description',
    route: 'material_masters',
  },
  {
    value: 'equipment_type',
    label: 'Equipment Type',
    type: 'Autocomplete',
    ref: 'equipment_type',
    route: 'material_masters',
  },
  {
    value: 'modifier',
    label: 'Modifier',
    type: 'Autocomplete',
    ref: 'modifier',
    route: 'material_masters',
  },
  {
    value: 'grade',
    label: 'Material Grade',
    type: 'Autocomplete',
    ref: 'grade',
    route: 'material_masters',
  },
  {
    value: 'individual_item_id',
    label: 'RAMP Unique ID',
    type: 'String',
  },
  {
    value: 'legacy_tag_id',
    label: 'Legacy Tag ID',
    type: 'String',
  },
  {
    value: 'long_description',
    label: 'Long Description',
    type: 'String',
  },
  {
    value: 'plant_code',
    label: 'Assigned Plant Code',
    type: 'String',
  },
  {
    value: 'allocated_plant_code',
    label: 'Allocated Plant Code',
    type: 'String',
  },
  { value: 'manufacturer', label: 'Manufacturer', type: 'String' },
  {
    value: 'material_layout',
    label: 'Material Layout',
    type: 'Select',
    values: [{ value: '', label: 'All Layouts' }, ...MaterialLayouts],
  },
  {
    value: 'project',
    label: 'Assigned Project',
    type: 'ObjectId',
    labelRef: 'name',
    ref: 'projects',
    route: 'projects',
  },
  { value: 'size', type: 'String', label: 'Max OD Size' },
  {
    value: 'SLOC',
    label: 'SLOC',
    type: 'ObjectId',
    labelRef: 'sloc_name',
    ref: 'slocs',
    route: 'slocs',
  },
  {
    value: 'status',
    label: 'Status',
    type: 'Multiple',
    values: [
      'Available',
      'Allocated',
      'Consignment',
      'Divestment',
      'On Order',
      'Flagged for Divestment',
    ],
  },
  { value: 'supplier', label: 'Supplier', type: 'String' },
  { value: 'weight', type: 'String', label: 'Tube Weight' },

  // { value: 'parent_assembly', label: 'Parent Assembly', type: 'String' },
  // { value: 'assembly_id', label: 'Assembly ID', type: 'String' },

  // {
  //   value: 'PO_line_item_no',
  //   label: 'PO Line Item Number',
  //   type: 'String',
  // },

  // {
  //   value: 'manufacturer_no',
  //   label: 'Manufacturer Number',
  //   type: 'String',
  // },
  // {
  //   value: 'divestment_number',
  //   label: 'Divestment Number',
  //   type: 'String',
  // },
  // { value: 'classification', label: 'Classification', type: 'String' },
  // { value: 'footage', label: 'Footage', type: 'String' },
  // { value: 'qty', label: 'Quantity', type: 'Number' }, // Number
  // { value: 'tally', label: 'Tally', type: 'String' },
  // { value: 'tally_id', label: 'Tally ID', type: 'String' },
  // { value: 'qty_prime', label: 'Quantity Prime', type: 'Number' }, // Number
  // { value: 'qty_damage', label: 'Quantity Damage', type: 'Number' }, // Number
  // { value: 'batch_no', label: 'Batch Number', type: 'String' },
  // {
  //   value: 'customer_po_no',
  //   label: 'Customer PO Number',
  //   type: 'String',
  // },
  // { value: 'RBW', label: 'RBW', type: 'String' },
  // { value: 'heat', label: 'HEAT', type: 'String' },
  // {
  //   value: 'facility_reference_id',
  //   label: 'Facility Reference ID',
  //   type: 'String',
  // },
  // { value: 'price_per_foot_cost', label: 'Price Per Foot Cost', type: 'String' },
  // { value: 'unit_cost', label: 'Unit (EA) Cost', type: 'String' },
  // { value: 'container_id', label: 'Container ID', type: 'String' },
  // {
  //   value: 'date_last_returned',
  //   label: 'Date Last Returned',
  //   type: 'Date',
  // }, // Date
  // {
  //   value: 'date_last_shipped',
  //   label: 'Date Last Shipped',
  //   type: 'Date',
  // }, // Date
  // {
  //   value: 'rina_work_order_no',
  //   label: 'Equality Work Order Number',
  //   type: 'String',
  // },
  // {
  //   value: 'rina_return_work_order_no',
  //   label: 'Equality Return Work Order Number',
  //   type: 'String',
  // },
  // {
  //   value: 'reclass_damage_description',
  //   label: 'Reclass Damage Description',
  //   type: 'String',
  // },
  // {
  //   value: 'date_placed_in_inventory',
  //   label: 'Date Placed in Inventory',
  //   type: 'Date',
  // }, // Date

  // {
  //   value: 'last_reconcile_date',
  //   label: 'Last Reconcile Date',
  //   type: 'Date',
  // }, // Date
  // {
  //   value: 'last_date_offshore',
  //   label: 'Last Date Offshore',
  //   type: 'Date',
  // }, // Date
  // {
  //   value: 'parent_container',
  //   label: 'Parent Container',
  //   type: 'String',
  // },
  // {
  //   value: 'maintenance_routine_interval_days',
  //   label: 'Maintenance Routine Interval Days',
  //   type: 'String',
  // },
  // {
  //   value: 'maintenance_routine_target_date',
  //   label: 'Maintenance Routine Target Date',
  //   type: 'Date',
  // }, // Date
  // {
  //   value: 'rotation_routine_interval',
  //   label: 'Rotation Routine Interval',
  //   type: 'String',
  // },
  // {
  //   value: 'rotation_routine_date',
  //   label: 'Rotation Routine Date',
  //   type: 'Date',
  // }, // Date
  // {
  //   value: 'testing_routine_interval',
  //   label: 'Testing Routine Interval',
  //   type: 'String',
  // },
  // {
  //   value: 'testing_routine_date',
  //   label: 'Testing Routine Date',
  //   type: 'Date',
  // }, // Date
  // // { value: 'allocated_release_number', label: 'Allocated Release Number', type: 'String' },
  // {
  //   value: 'allocated_batch_number',
  //   label: 'Allocated Batch Number',
  //   type: 'String',
  // },
  // {
  //   value: 'allocated_work_order_number',
  //   label: 'Allocated Work Order Number',
  //   type: 'String',
  // },
  // { value: 'note', label: 'Note', type: 'String' },
  // { value: 'supplier_so_no', label: 'Supplier SO', type: 'String' },
  // { value: 'supplier_part_no', label: 'Supplier PN', type: 'String' },

  // {
  //   value: 'inspection_criteria',
  //   label: 'Inspection Criteria',
  //   type: 'String',
  // },
  // // { value: 'unique_id', label: 'Unique ID', type: 'String' },
  // { value: 'tag_number', label: 'Tag Number', type: 'String' },
  // { value: 'department', label: 'Item Department', type: 'String' },
  // Original Charges Objects
  // {
  //   value: 'assigned_customer_charge_code',
  //   label: 'Assigned Customer Charge Code',
  //   type: 'ObjectId', labelRef: '', route: '',
  //   ref: 'original_charges',
  // },

  // {
  //   value: 'previous_customer_charge_code',
  //   label: 'Previous Customer Charge Code',
  //   type: 'ObjectId', labelRef: '', route: '',
  //   ref: 'original_charges',
  // },
  // Customer MM Objects
  // { value: 'customer_MM.short_description', type: 'ObjectId', labelRef: '', route: '', label: 'Customer MM', ref: 'materialMasters' },

  // {
  //   value: 'customer_MM.nominal_wall',
  //   type: 'String',
  //   label: 'Nominal Wall',
  // },
  // { value: 'customer_MM.range', type: 'String', label: 'Range' },
  // { value: 'customer_MM.pipe_type', type: 'String', label: 'Pipe Type' },
  // {
  //   value: 'equipment_type',
  //   label: 'Equipment Type',
  //   type: 'Autocomplete',
  //   ref: 'equipment_type',
  //   route: 'material_masters',
  // },
];
