import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Form = styled.div`
  min-width: 60vw;
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  @media only screen and (max-width: 900px) {
    width: 300px;
  }
`;

export const CheckboxContent = styled.form`
  width: 100%;
  padding: 12px 10px 15px 0px;
  .MuiFormControlLabel-labelPlacementStart {
    margin-left: 0px;
  }
`;

export const InputForm = styled.div`
  margin: 15px;
`;
