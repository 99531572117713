import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  TextField,
  FormGroup,
  Button,
  FormControlLabel,
  Switch,
  Typography,
  FormControl,
} from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';
import Alert from '../../../components/Alert';
import Spinner from '../../../components/Spinner';
import {
  Container,
  InputContent,
  ModalTitle,
  ButtonContainer,
  CheckboxContent,
} from './styles';

import {
  getOwner,
  createOwner,
  updateOwner,
} from '../../../redux/actions/owner';

const styles = {
  formButton: {
    margin: 2,
  },
};

function OwnerModal({
  id,
  dispatch,
  owner,
  ownerModal,
  handleClose,
  ownerSpinner,
}) {
  const [active, setActive] = useState(true);
  const [name, setName] = useState('');
  const [contactInfo, setContactInfo] = useState({
    name: '',
    email: '',
    phone: '',
    position: '',
  });

  const [nameError, setNameError] = useState(false);

  useEffect(() => {
    if (id !== 'new') {
      dispatch(getOwner(id));
    }
  }, [id]);

  useEffect(() => {
    if (owner && id !== 'new') {
      setActive(owner.active);
      setName(owner.name);
      setContactInfo(owner.contact_info);
    }
  }, [owner]);

  useEffect(() => {
    if (!ownerModal) {
      handleClose();
    }
  }, [ownerModal]);

  const handleSave = async () => {
    let err = false;
    setNameError(!name);
    err = !name;

    if (!err) {
      const response = await Alert();
      if (response && response.confirm) {
        if (id === 'new') {
          dispatch(
            createOwner({
              active,
              name,
              contact_info: contactInfo,
            }),
          );
        } else {
          dispatch(
            updateOwner({
              _id: id,
              active,
              name,
              contact_info: contactInfo,
            }),
          );
        }
      }
    }
  };

  function handleContactInfo(e) {
    const { name, value } = e.target;
    setContactInfo((currentData) => ({ ...currentData, [name]: value }));
  }

  return (
    <>
      {ownerSpinner && <Spinner overlay />}

      <Container style={{ opacity: ownerSpinner ? 0.5 : 1 }}>
        <ModalTitle>
          {id === 'new' ? 'New' : 'Edit'}
          {' '}
          Material Owner
        </ModalTitle>
        <FormGroup>
          <CheckboxContent>
            <FormControlLabel
              control={(
                <Switch
                  checked={active}
                  onChange={(e) => setActive(e.target.checked)}
                />
              )}
              label="Active"
              labelPlacement="start"
            />
          </CheckboxContent>
          <InputContent>
            <TextField
              label="Name*"
              error={nameError}
              helperText={nameError && 'Name is required'}
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
            />
          </InputContent>
          <Typography variant="h6">Contact Info</Typography>
          <InputContent>
            <TextField
              label="Name"
              name="name"
              variant="outlined"
              value={contactInfo.name}
              onChange={handleContactInfo}
              fullWidth
            />
          </InputContent>
          <InputContent>
            <TextField
              label="E-mail"
              name="email"
              variant="outlined"
              value={contactInfo.email}
              onChange={handleContactInfo}
              fullWidth
            />
          </InputContent>
          <InputContent>
            <FormControl fullWidth>
              <PhoneInput
                fullWidth
                country="us"
                specialLabel="Phone Number"
                containerStyle={{ width: '100%' }}
                inputStyle={{ width: '100%' }}
                value={contactInfo.phone}
                onChange={(_rawValue, _data, _event, value) => handleContactInfo({ target: { name: 'phone', value } })}
              />
              {/* {phoneNumberError && (
                <FormHelperText>
                  Phone number is required
                </FormHelperText>
              )} */}
            </FormControl>
          </InputContent>
          <InputContent>
            <TextField
              label="Position"
              name="position"
              variant="outlined"
              value={contactInfo.position}
              onChange={handleContactInfo}
              fullWidth
            />
          </InputContent>
          <ButtonContainer>
            <Button
              color="secondary"
              onClick={handleClose}
              variant="contained"
              style={styles.formButton}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={handleSave}
              variant="contained"
              style={styles.formButton}
            >
              Save
            </Button>
          </ButtonContainer>
        </FormGroup>
      </Container>
    </>
  );
}

export default connect((state) => ({
  owner: state.owner,
  ownerModal: state.ui.ownerModal,
  ownerSpinner: state.ui.ownerLoading,
}))(OwnerModal);
