import { GET_CUSTOMER_MMS, UPDATE_CUSTOMER_MMS } from '../actions/customerMMs';

const initState = {
  docs: [],
  page: 1,
  limit: 10,
  totalDocs: 0,
};

export function customerMMsReducer(customerMMs = initState, action) {
  switch (action.type) {
    case UPDATE_CUSTOMER_MMS:
      return action.payload;

    default:
      return customerMMs;
  }
}
