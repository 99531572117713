import React from 'react';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Close from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import uploadFile from '../../../services/Inspection/uploadFile';
import { connect, useSelector } from 'react-redux';
import { Add } from '@material-ui/icons';
import Alert from '../../../components/Alert';
import Toastr from '../../../utils/Toastify/index';
import { useHistory } from 'react-router-dom';
import {
  showJobAndCustomerDataModal,
  hideJobAndCustomerDataModal,
} from '../../../redux/actions/workOrders';
import ModalContainer from '../../../components/ModalContainer';
import JobAndCustomerModal from '../../Modals/JobAndCustomerData';
import { download } from '../../../services/Download';
import { getSpecLibrary } from '../../../redux/actions/vault';
import { AddButton, AttachmentContainer, FileNotReadyWarning } from './styles';

function Specifications({
  dispatch,
  data,
  setData,
  woJobAndCustomerModal,
  workOrder,
  specLibrary,
}) {
  const {
    revision: { specifications = [] },
  } = data;
  const [editing, setEditing] = React.useState(null);
  const loggedUser = useSelector((state) => state.loggedUser?._id);
  const history = useHistory();

  React.useEffect(() => {
    dispatch(getSpecLibrary());
  }, []);

  const specFiles = Array.isArray(specLibrary)
    ? specLibrary.filter(({ isDirectory, hidden }) => !isDirectory && !hidden)
    : [];

  function handleCloseJobAndCustomerModal() {
    dispatch(hideJobAndCustomerDataModal());
  }

  function handleJobAndCustomerModal(newData) {
    let newSpecifications = [...specifications];
    if (editing !== null) {
      newSpecifications[editing] = newData;
    }

    setData((currentData) => ({
      ...currentData,
      revision: {
        ...currentData.revision,
        specifications:
          editing !== null
            ? newSpecifications
            : [...currentData.revision.specifications, newData],
      },
    }));

    dispatch(hideJobAndCustomerDataModal());
  }

  function handleDelete() {
    Alert(
      'Are you sure?',
      "You won't be able to revert this!",
      false,
      '',
      'Yes, delete it.'
    ).then(({ confirm }) => {
      if (!confirm) return;
      setData((currentData) => {
        const currentSpecifications = [...currentData.revision.specifications];
        currentSpecifications.splice(editing, 1);
        return {
          ...currentData,
          revision: {
            ...currentData.revision,
            specifications: currentSpecifications,
          },
        };
      });
      dispatch(hideJobAndCustomerDataModal());
    });
  }

  function handleDeleteRow(index) {
    function handler() {
      Alert(
        'Are you sure?',
        "You won't be able to revert this!",
        false,
        '',
        'Yes, delete it.'
      ).then(({ confirm }) => {
        if (!confirm) return;
        setData((currentData) => {
          const currentSpecifications = [
            ...currentData.revision.specifications,
          ];
          currentSpecifications.splice(index, 1);
          return {
            ...currentData,
            revision: {
              ...currentData.revision,
              specifications: currentSpecifications,
            },
          };
        });
        dispatch(hideJobAndCustomerDataModal());
      });
    }
    return handler;
  }

  function openJobAndCustomerDataModal() {
    if (!data?.revision?.charge_code || !data?.revision?.charge_code?.well) {
      Toastr.addWarning(
        'We must complete the Charge Code, Well and Project inside the General Information before creating the specifications'
      );
    } else {
      setEditing(null);
      dispatch(showJobAndCustomerDataModal());
    }
  }

  function handleDownload(index) {
    const toDownload = specFiles.find(
      ({ _id }) =>
        _id ===
        (data.revision.specifications[index].attachment?._id ||
          data.revision.specifications[index].attachment)
    );
    return function () {
      download(`vault/download/${toDownload?.file?.filename}`);
    };
  }

  return (
    <>
      <ModalContainer
        open={woJobAndCustomerModal}
        handleClose={handleCloseJobAndCustomerModal}
      >
        <JobAndCustomerModal
          handleClose={handleCloseJobAndCustomerModal}
          onSave={handleJobAndCustomerModal}
          onDelete={handleDelete}
          editData={editing !== null ? specifications[editing] : null}
        />
      </ModalContainer>
      <AddButton
        variant="contained"
        color="primary"
        size="small"
        onClick={() => openJobAndCustomerDataModal()}
      >
        <Add />
        Add Job Specification
      </AddButton>
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>Specification Doc</TableCell>
              <TableCell colSpan={2}>Specification Description</TableCell>
              <TableCell>Revision Number</TableCell>
              <TableCell>Attached Doc</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {specifications.length < 1 && (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No Specification Added
                </TableCell>
              </TableRow>
            )}
            {specifications.map((specification, index) => (
              <TableRow
                hover
                onDoubleClick={() => {
                  setEditing(index);
                  dispatch(showJobAndCustomerDataModal());
                }}
              >
                <TableCell>{specification.doc}</TableCell>
                <TableCell colSpan={2} style={{ whiteSpace: 'pre-wrap' }}>
                  {specification.description}
                </TableCell>
                <TableCell>{specification.revision}</TableCell>
                <TableCell>
                  <AttachmentContainer>
                    {specification?.file && (
                      <FileNotReadyWarning>
                        The file will be ready after the document be saved
                      </FileNotReadyWarning>
                    )}
                    <IconButton
                      disabled={specification?.file}
                      onClick={handleDownload(index)}
                    >
                      {specification?.attachment && <AttachFileIcon />}
                    </IconButton>
                  </AttachmentContainer>
                </TableCell>
                <TableCell>
                  <Tooltip title="Remove">
                    <IconButton
                      color="secondary"
                      onClick={handleDeleteRow(index)}
                    >
                      <Close />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default connect((state) => ({
  woJobAndCustomerModal: state.ui.woJobAndCustomerModal,
  specLibrary: state.specLibrary,
}))(Specifications);
