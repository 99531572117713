import { UPDATE_OBJECT_MAINTENANCE_CATEGORY } from '../actions/maintenanceCategory';

export function maintenanceCategoryReducer(maintenanceCategory = {}, action) {
  switch (action.type) {
    case UPDATE_OBJECT_MAINTENANCE_CATEGORY:
      return action.payload;

    default:
      return maintenanceCategory;
  }
}
