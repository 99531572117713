import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Container, TitleContent, InputContainer } from './styles';
import { getCustomerMMs } from '../../redux/actions/customerMMs';
import {
  setCustomerMMFilter,
  clearCustomerMMFilter,
} from '../../redux/actions/customerMMFilter';
import { CUSTOMER_KEY } from '../../config/consts';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 600,
    position: 'relative',
    width: '100%',
  },
  button: {
    fontSize: 16,
    color: '#737373',
  },
  listItem: {
    width: '100%',
  },
  listAccordion: {
    padding: '0px 0px 0px 25px',
    height: 39,
    marginTop: -25,
  },
  questionLabel: {
    paddingTop: 2,
    paddingBottom: 2,
    fontSize: '0.8rem',
  },
  listItemQuestion: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderTop: '1px solid #ccc',
  },
  listItemPadding: {
    paddingTop: 4,
    paddingBottom: 4,
  },
  expansionHeader: {},
  questionLabelEmpty: {
    color: '#808080',
    fontSize: '0.8rem',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    fontSize: 10,
    height: 20,
    '& > span': { paddingLeft: 5, paddingRight: 5 },
  },
  formControl: {
    width: '100%',
    padding: 10,
    paddingTop: 0,
    '& > label': { paddingLeft: 10 },
  },
}));

function CustomerMMFilter({ dispatch, customerMMFilter }) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const fields = [
    {
      value: customerMMFilter.mm_number,
      key: 'mm_number',
      label: 'Customer MM No',
    },
    {
      value: customerMMFilter.short_description,
      key: 'short_description',
      label: 'Short Description',
    },
    {
      value: customerMMFilter.long_description,
      key: 'long_description',
      label: 'Long Description',
    },
    {
      value: customerMMFilter.equipment_type,
      key: 'equipment_type',
      label: 'Equipment Type',
    },
    {
      value: customerMMFilter.modifier,
      key: 'modifier',
      label: 'Modifier',
    },
    { value: customerMMFilter.size, key: 'size', label: 'Max OD Size' },
    { value: customerMMFilter.weight, key: 'weight', label: 'Tube Weight' },
    { value: customerMMFilter.grade, key: 'grade', label: 'Material Grade' },
    {
      value: customerMMFilter.connection_1,
      key: 'connection_1',
      label: 'Connection 1',
    },
    {
      value: customerMMFilter.connection_1_size,
      key: 'connection_1_size',
      label: 'Connection 1 (SIZE)',
    },
    {
      value: customerMMFilter.connection_1_weight,
      key: 'connection_1_weight',
      label: 'Connection 1 (WEIGHT)',
    },
    {
      value: customerMMFilter.connection_1_grade,
      key: 'connection_1_grade',
      label: 'Connection 1 (GRADE)',
    },
    {
      value: customerMMFilter.connection_2,
      key: 'connection_2',
      label: 'Connection 2',
    },
    {
      value: customerMMFilter.connection_2_size,
      key: 'connection_2_size',
      label: 'Connection 2 (SIZE)',
    },
    {
      value: customerMMFilter.connection_2_weight,
      key: 'connection_2_weight',
      label: 'Connection 2 (WEIGHT)',
    },
    {
      value: customerMMFilter.connection_2_grade,
      key: 'connection_2_grade',
      label: 'Connection 2 (GRADE)',
    },
    {
      value: customerMMFilter.connection_configuration,
      key: 'connection_configuration',
      label: 'Connection Configuration',
    },
    {
      value: customerMMFilter.nominal_wall,
      key: 'nominal_wall',
      label: 'Nominal Wall',
    },
    {
      value: customerMMFilter.drift_diameter,
      key: 'drift_diameter',
      label: 'Drift Diameter',
    },
    { value: customerMMFilter.range, key: 'range', label: 'Range' },
    { value: customerMMFilter.pipe_type, key: 'pipe_type', label: 'Pipe Type' },
    {
      value: customerMMFilter.manufacturer,
      key: 'manufacturer',
      label: 'Manufacturer',
    },
    {
      value: customerMMFilter.manufacturer_pn,
      key: 'manufacturer_pn',
      label: 'Manufacturer Part Number',
    },
    {
      value: customerMMFilter.inspection_criteria,
      key: 'inspection_criteria',
      label: 'Inspection Criteria',
    },
    {
      value: customerMMFilter.pressure_rating,
      key: 'pressure_rating',
      label: 'Pressure Rating (PSI)',
    },
    {
      value: customerMMFilter.primary_uom,
      key: 'primary_uom',
      label: 'Primary UoM',
    },
    {
      value: customerMMFilter.secondary_uom,
      key: 'secondary_uom',
      label: 'Secondary UoM',
    },
    {
      value: customerMMFilter.action_type,
      key: 'action_type',
      label: 'Action Type',
    },
  ];

  function handleEdit(e) {
    const { name, value } = e.target;
    dispatch(setCustomerMMFilter({ [name]: value }));
  }

  function handleSearch(e) {
    e.preventDefault();
    dispatch(
      getCustomerMMs({
        page: 1,
        limit: 50,
        filter: {
          ...customerMMFilter,
          customer: localStorage.getItem(CUSTOMER_KEY),
        },
      }),
    );
  }

  function clearFilter() {
    dispatch(clearCustomerMMFilter());
    dispatch(
      getCustomerMMs({
        page: 1,
        limit: 50,
        filter: { customer: localStorage.getItem(CUSTOMER_KEY) },
      }),
    );
  }

  return (
    <Container>
      <form onSubmit={handleSearch}>
        <Accordion expanded={expanded}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            classes={{ content: classes.expansionHeader }}
            onClick={() => setExpanded(!expanded)}
          >
            <div className={classes.heading}>
              <TitleContent>Material Master Filter</TitleContent>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container style={{ padding: '0px 10px 10px 10px' }}>
              {fields.map(({ value, key, label }) => (
                <Grid item lg={3} md={3} sm={12} xs={12}>
                  <InputContainer>
                    <TextField
                      onChange={handleEdit}
                      size="small"
                      name={key}
                      label={label}
                      variant="outlined"
                      value={value}
                      fullWidth
                    />
                  </InputContainer>
                </Grid>
              ))}

              <Grid item xs style={{ textAlign: 'end' }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{ marginRight: 10, cursor: 'pointer' }}
                >
                  Filter
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={clearFilter}
                  style={{ cursor: 'pointer' }}
                >
                  Clear
                </Button>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </form>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  customerMMFilter: state.customerMMFilter,
});

export default connect(mapStateToProps)(CustomerMMFilter);
