import { combineReducers } from 'redux';
import { allocateReducer } from './allocate';
import { BOMParentsReducer } from './BOMParents';
import { buildOfMaterialReducer } from './buildOfMaterial';
import { businessUnitReducer } from './businessUnit';
import { businessUnitsReducer } from './businessUnits';
import { checkInReducer } from './checkIn';
import { checkOutReducer } from './checkOut';
import { companyCodeReducer } from './companyCode';
import { companyCodesReducer } from './companyCodes';
import { customerReducer } from './customer';
import { customersReducer } from './customers';
import { customerMMReducer } from './customerMM';
import { customerMMFilterReducer } from './customerMMFilter';
import { customerMMsReducer } from './customerMMs';
import { fabricationReducer } from './fabrication';
import { filterLayoutReducer } from './filterLayout';
import { historyReducer } from './history';
import { historyFilterReducer } from './historyFilter';
import { inventoryCartReducer } from './inventory_cart';
import { jobReducer } from './job';
import { jobsReducer } from './jobs';
import { loggedUserReducer } from './logged-user';
import { maintenanceCategoriesReducer } from './maintenanceCategories';
import { maintenanceCategoryReducer } from './maintenanceCategory';
import { maintenanceFilterReducer } from './maintenanceFilter';
import { maintenancesReducer } from './maintenances';
import { maintenanceReducer } from './maintenance';
import { movimentationReducer } from './movimentation';
import { movimentationsReducer } from './movimentations';
import { movimentationsFilterReducer } from './movimentationsFilter';
import { originalChargeReducer } from './originalCharge';
import { originalChargesReducer } from './originalCharges';
import { ownerReducer } from './owner';
import { ownersReducer } from './owners';
import { projectReducer } from './project';
import { projectsReducer } from './projects';
import { notificationsReducer } from './notifications';
import { reclassReducer } from './reclass';
import { reclassDamageReducer } from './reclassDamage';
import { reclassDamagesReducer } from './reclassDamages';
import { reconcileReducer } from './reconcile';
import { refineFilterReducer } from './refineFilter';
import { reportFieldReducer } from './reportField';
import { reportFieldsReducer } from './reportFields';
import { resultLayoutReducer } from './resultLayout';
import { resultLayoutsReducer } from './resultLayouts';
import { roleReducer } from './role';
import { rolesReducer } from './roles';
import { serviceCategoriesReducer } from './serviceCategories';
import { serviceCategoryReducer } from './serviceCategory';
import { slocReducer } from './sloc';
import { slocsReducer } from './slocs';
import { specLibraryReducer } from './specLibrary';
import { transferOfOwnershipReducer } from './transferOfOwnership';
import { uiReducer } from './ui';
import { profileReducer } from './profile';
import { mrpNotificationSetupReducer } from './MRPNotificationSetup';
import { userReducer } from './user';
import { usersReducer } from './users';
import { vaultReducer } from './vault';
import { vendorReducer } from './vendor';
import { vendorsReducer } from './vendors';
import { vpasReducer } from './vpas';
import { vpassReducer } from './vpass';
import { wellReducer } from './well';
import { wellsReducer } from './wells';
import { workOrderReducer } from './workOrder';
import { workOrdersReducer } from './workOrders';
import { warehouseReducer } from './warehouse';
import { warehousesReducer } from './warehouses';
import { rackReducer } from './rack';
import { racksReducer } from './racks';
import { binReducer } from './bin';
import { binsReducer } from './bins';
import { cycleCountReducer } from './cycleCount';
import { cycleCountsReducer } from './cycleCounts';
import { inspectionReducer } from './inspection';
import { inspectionsReducer } from './inspections';
import { activityReducer } from './activity';
import { activitiesReducer } from './activities';

export const reducers = combineReducers({
  BOMParents: BOMParentsReducer,
  allocate: allocateReducer,
  buildOfMaterial: buildOfMaterialReducer,
  businessUnit: businessUnitReducer,
  businessUnits: businessUnitsReducer,
  checkIn: checkInReducer,
  checkOut: checkOutReducer,
  companyCode: companyCodeReducer,
  companyCodes: companyCodesReducer,
  customerMM: customerMMReducer,
  customerMMFilter: customerMMFilterReducer,
  customerMMs: customerMMsReducer,
  customer: customerReducer,
  customers: customersReducer,
  fabrication: fabricationReducer,
  filterLayout: filterLayoutReducer,
  history: historyReducer,
  historyFilter: historyFilterReducer,
  inventory_cart: inventoryCartReducer,
  job: jobReducer,
  jobs: jobsReducer,
  loggedUser: loggedUserReducer,
  maintenanceCategories: maintenanceCategoriesReducer,
  maintenanceCategory: maintenanceCategoryReducer,
  maintenanceFilter: maintenanceFilterReducer,
  maintenances: maintenancesReducer,
  maintenance: maintenanceReducer,
  movimentation: movimentationReducer,
  movimentations: movimentationsReducer,
  movimentationsFilter: movimentationsFilterReducer,
  originalCharge: originalChargeReducer,
  originalCharges: originalChargesReducer,
  owner: ownerReducer,
  owners: ownersReducer,
  project: projectReducer,
  projects: projectsReducer,
  notifications: notificationsReducer,
  reclass: reclassReducer,
  reclassDamage: reclassDamageReducer,
  reclassDamages: reclassDamagesReducer,
  reconcile: reconcileReducer,
  refineFilter: refineFilterReducer,
  reportField: reportFieldReducer,
  reportFields: reportFieldsReducer,
  resultLayout: resultLayoutReducer,
  resultLayouts: resultLayoutsReducer,
  role: roleReducer,
  roles: rolesReducer,
  serviceCategories: serviceCategoriesReducer,
  serviceCategory: serviceCategoryReducer,
  sloc: slocReducer,
  slocs: slocsReducer,
  specLibrary: specLibraryReducer,
  transferOfOwnership: transferOfOwnershipReducer,
  ui: uiReducer,
  profile: profileReducer,
  mrpNotificationSetup: mrpNotificationSetupReducer,
  user: userReducer,
  users: usersReducer,
  vault: vaultReducer,
  vendor: vendorReducer,
  vendors: vendorsReducer,
  vpas: vpasReducer,
  vpass: vpassReducer,
  well: wellReducer,
  wells: wellsReducer,
  workOrder: workOrderReducer,
  workOrders: workOrdersReducer,
  warehouse: warehouseReducer,
  warehouses: warehousesReducer,
  rack: rackReducer,
  racks: racksReducer,
  bin: binReducer,
  bins: binsReducer,
  cycleCount: cycleCountReducer,
  cycleCounts: cycleCountsReducer,
  inspection: inspectionReducer,
  inspections: inspectionsReducer,
  activity: activityReducer,
  activities: activitiesReducer,
});
