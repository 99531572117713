import styled from 'styled-components';
import { Paper, Typography, Grid } from '@material-ui/core';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem;
`;

export const Panel = styled(Paper).attrs({ elevation: 2 })`
  padding: 1rem;
  margin-bottom: 2rem;
  max-height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Title = styled(Typography).attrs({ variant: 'h6' })``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  & .MuiButtonBase-root:last-child {
    margin-left: auto;
  }
`;

export const Content = styled.div`
  background-color: #eee;
  border-radius: 1rem;
  width: 100%;
  flex: 1;
  display: flex;
  overflow: auto;
  gap: 1rem;
  padding: 0.5rem;
  flex-direction: column;
  scrollbar-color: #555 #aaa;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: #aaaaaa;
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background: #555;
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #444;
  }
`;

export const Card = styled(Paper).attrs({ elevation: 2 })`
  cursor: pointer;
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border-radius: 1rem;
  grid-gap: 0.5rem;
  text-decoration: none;
  & .note {
    grid-column-start: 1;
    grid-column-end: 4;
  }
`;

export const Filters = styled(Grid).attrs({ container: true, spacing: 1 })`
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  & fieldset {
    border-radius: 1rem;
  }
`;
