import { UPDATE_OBJECT_TRANSFER_OF_OWNERSHIP } from '../actions/transferOfOwnership';

export function transferOfOwnershipReducer(transferOfOwnership = {}, action) {
  switch (action.type) {
    case UPDATE_OBJECT_TRANSFER_OF_OWNERSHIP:
      return action.payload;

    default:
      return transferOfOwnership;
  }
}
