import React from 'react';
import { Lottie } from '../../utils/Lottie/Lottie';
import {
  Container,
} from './styles';
import animationLottie from '../../assets/animations/underConstructionAnimation.json';

function UnderConstruction() {
  const styles = {
    animation: {
      height: '75vh',
    },
  };

  return (
    <Container>
      <Lottie animationData={animationLottie} height={styles.animation.height} />
    </Container>
  );
}
export default UnderConstruction;
