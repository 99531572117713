import { GET_JOBS, UPDATE_JOBS } from '../actions/jobs';

const initState = {
  docs: [],
  page: 1,
  limit: 10,
  totalDocs: 0,
};

export function jobsReducer(jobs = initState, action) {
  switch (action.type) {
    case GET_JOBS:
      return action.payload;
    case UPDATE_JOBS:
      return action.payload;

    default:
      return jobs;
  }
}
