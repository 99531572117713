import { UPDATE_NOTIFICATIONS } from '../actions/notifications';

const initState = {
  docs: [],
  page: 1,
  limit: 10,
  totalDocs: 0,
};

export function notificationsReducer(notifications = initState, action) {
  switch (action.type) {
    case UPDATE_NOTIFICATIONS:
      return action.payload;

    default:
      return notifications;
  }
}
