import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Box } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Spinner from '../../../components/Spinner';
import { Container, InputContent, ModalTitle, ButtonContainer } from './styles';
import { Add } from '@material-ui/icons';
import UploadSpecification from '../UploadSpecification';
import { getSpecLibrary } from '../../../redux/actions/vault';

const styles = {
  formButton: {
    margin: 2,
  },
};

const JobAndCustomerModal = ({
  handleClose,
  jobAndCustomerSpinner,
  onSave,
  editData,
  onDelete,
  specLibrary,
  dispatch,
}) => {
  const [docError, setDocError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [revisionError, setRevisionError] = useState(false);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({
    doc: '',
    description: '',
    revision: '',
    attachment: '',
  });
  useEffect(() => {
    if (editData) setData(editData);
    else
      setData({
        doc: '',
        description: '',
        revision: '',
        attachment: '',
      });
  }, [editData]);

  const { doc, revision, description, attachment } = data;

  const handleSave = async () => {
    let err = false;
    setDocError(!doc);
    setDescriptionError(!description);
    setRevisionError(!revision);
    err = !doc || !description || !revision;

    if (!err) {
      onSave(data);
    }
  };

  const handleEdit = (e) => {
    const { name, value } = e.target;
    setData((currentData) => ({ ...currentData, [name]: value }));
  };

  const getSpec = () =>
    specFiles.find(({ _id }) => _id === (attachment?._id || attachment)) ||
    null;

  const specFiles = Array.isArray(specLibrary)
    ? specLibrary.filter(({ isDirectory, hidden }) => !isDirectory && !hidden)
    : [];

  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  const uploadCallback = (fileId) => {
    dispatch(getSpecLibrary());
    setData((currentData) => ({ ...currentData, attachment: fileId }));
  };

  return (
    <>
      {jobAndCustomerSpinner && <Spinner overlay />}
      <Container style={{ opacity: jobAndCustomerSpinner ? 0.5 : 1 }}>
        <ModalTitle>New Job Specification</ModalTitle>
        <InputContent>
          <TextField
            label="Specification Doc"
            required
            type="text"
            error={docError}
            helperText={docError && 'Specification Doc is required'}
            variant="outlined"
            value={doc || ''}
            name="doc"
            onChange={handleEdit}
            fullWidth
          />
        </InputContent>
        <InputContent>
          <TextField
            label="Specification Description"
            required
            multiline
            type="text"
            error={descriptionError}
            helperText={
              descriptionError && 'Specification description is required'
            }
            variant="outlined"
            value={description || ''}
            name="description"
            onChange={handleEdit}
            fullWidth
          />
        </InputContent>
        <InputContent>
          <TextField
            label="Revision Number"
            required
            type="text"
            error={revisionError}
            helperText={revisionError && 'Revision number is required'}
            variant="outlined"
            value={revision || ''}
            name="revision"
            onChange={handleEdit}
            fullWidth
          />
        </InputContent>
        <InputContent>
          <Autocomplete
            getOptionSelected={({ _id }, value) => {
              return _id === value?._id;
            }}
            value={getSpec()}
            onChange={(_, value) =>
              handleEdit({ target: { name: 'attachment', value } })
            }
            fullWidth
            options={specFiles}
            autoHighlight
            getOptionLabel={({ name }) => name}
            renderInput={(props) => (
              <TextField
                {...props}
                label="Attachment"
                required
                variant="outlined"
              />
            )}
          />
          <Box display="flex" flex={1} justifyContent="flex-end">
            <Button
              color="primary"
              startIcon={<Add />}
              onClick={handleOpenModal}
            >
              New File
            </Button>
          </Box>
        </InputContent>

        <ButtonContainer>
          {editData && (
            <Button
              color="secondary"
              onClick={onDelete}
              variant="outlined"
              style={styles.formButton}
            >
              Delete
            </Button>
          )}
          <Button
            color="secondary"
            onClick={handleClose}
            variant="contained"
            style={styles.formButton}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={handleSave}
            variant="contained"
            style={styles.formButton}
          >
            Save
          </Button>
        </ButtonContainer>
      </Container>
      <UploadSpecification
        open={open}
        onClose={handleCloseModal}
        callback={uploadCallback}
      />
    </>
  );
};

export default connect((state) => ({
  jobAndCustomerSpinner: state.ui.jobAndCustomerLoading,
  specLibrary: state.specLibrary,
}))(JobAndCustomerModal);
