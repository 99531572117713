import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  TextField,
  FormGroup,
  Button,
  FormControlLabel,
  Switch,
  MenuItem,
  Tooltip,
  Icon,
  IconButton,
} from '@material-ui/core';
import Alert from '../../../components/Alert/index';
import Spinner from '../../../components/Spinner/index';
import {
  Container,
  InputContent,
  ModalTitle,
  ButtonContainer,
  CheckboxContent,
} from './styles';

import {
  getActivity,
  createActivity,
  updateActivity,
} from '../../../redux/actions/activity';

const styles = {
  formButton: {
    margin: 2,
  },
};

const fields = [
  { label: 'Return Work Order Number', field: 'rina_return_work_order_no' },
  {
    label: 'Allocated Work Order Number',
    field: 'allocated_work_order_number',
  },
];

function ActivityModal({
  id,
  dispatch,
  activity,
  activityModal,
  handleClose,
  activitySpinner,
}) {
  const [active, setActive] = useState(true);
  const [title, setTitle] = useState('');
  const [equipmentField, setEquipmentField] = useState('');

  const [titleError, setTitleError] = useState(false);

  useEffect(() => {
    if (id !== 'new') {
      dispatch(getActivity(id));
    }
  }, [id]);

  useEffect(() => {
    if (activity && id !== 'new') {
      setActive(activity.active);
      setTitle(activity.title);
      setEquipmentField(activity.equipment_field);
    }
  }, [activity]);

  useEffect(() => {
    if (!activityModal) {
      handleClose();
    }
  }, [activityModal]);

  const handleSave = async () => {
    let err = false;
    setTitleError(!title);
    err = !title;

    if (!err) {
      const response = await Alert();
      if (response && response.confirm) {
        if (id === 'new') {
          dispatch(
            createActivity({
              active,
              title,
              equipment_field: equipmentField,
            }),
          );
        } else {
          dispatch(
            updateActivity({
              _id: id,
              active,
              title,
              equipment_field: equipmentField,
            }),
          );
        }
      }
    }
  };

  return (
    <>
      {activitySpinner && <Spinner overlay />}
      <Container style={{ opacity: activitySpinner ? 0.5 : 1 }}>
        <ModalTitle>
          {id === 'new' ? 'New' : 'Edit'}
          {' '}
          Activity
        </ModalTitle>
        <FormGroup>
          <CheckboxContent>
            <FormControlLabel
              control={(
                <Switch
                  checked={active}
                  onChange={(e) => setActive(e.target.checked)}
                />
              )}
              label="Active"
              labelPlacement="start"
            />
          </CheckboxContent>
          <InputContent>
            <TextField
              label="Activity*"
              error={titleError}
              helperText={titleError && 'Activity is required'}
              variant="outlined"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              fullWidth
            />
          </InputContent>
          <InputContent>
            <TextField
              label="Equipment Field"
              variant="outlined"
              value={equipmentField}
              onChange={(e) => setEquipmentField(e.target.value)}
              fullWidth
              select
              InputProps={{
                endAdornment: (
                  <Tooltip title="Field to be filled with Work Order No when activity completed.">
                    <IconButton
                      color="primary"
                      size="small"
                      style={{ marginRight: 10 }}
                    >
                      <Icon>info_outlined</Icon>
                    </IconButton>
                  </Tooltip>
                ),
              }}
            >
              {fields.map(({ field, label }) => (
                <MenuItem key={field} value={field}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
          </InputContent>

          <ButtonContainer>
            <Button
              color="secondary"
              onClick={handleClose}
              variant="contained"
              style={styles.formButton}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={handleSave}
              variant="contained"
              style={styles.formButton}
            >
              Save
            </Button>
          </ButtonContainer>
        </FormGroup>
      </Container>
    </>
  );
}

export default connect((state) => ({
  activity: state.activity,
  activityModal: state.ui.activityModal,
  activitySpinner: state.ui.activityLoading,
}))(ActivityModal);
