import styled from 'styled-components';

export const Container = styled.div`
  min-width: 60vw;
  max-height: 95vh;
  max-width: 100vw;
  padding: 20px;
  overflow: auto;
  height: 100%;
  scrollbar-color: #555 #aaa;
  scrollbar-width: thin;
  @media (min-width: 768px) {
    max-width: 60vw;
  }
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: #aaa;
    border-radius: 0px 4px 4px 0px;
  }
  &::-webkit-scrollbar-thumb {
    background: #555;
    border-radius: 0px 4px 4px 0px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #444;
  }
`;

export const ModalTitle = styled.div`
  padding-left: 15px;
  padding-bottom: 20px;
  font-size: 1.3rem;
`;

export const InputContent = styled.form`
  width: 100%;
  padding: 20px 10px 10px 10px;
`;

export const CheckboxContent = styled.form`
  width: 100%;
  padding: 12px 10px 15px 0px;
`;

export const ButtonContainer = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

export const FilesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin: 10px;
`;

export const DropZoneContainer = styled.div`
  margin: 10px;
`;
