import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  background-color: #fff;
  border-radius: 0 0 8px 8px;
  -webkit-box-shadow: 0px 8px 11px 3px rgba(0, 0, 0, 0.31);
  -moz-box-shadow: 0px 8px 11px 3px rgba(0, 0, 0, 0.31);
  box-shadow: 0px 8px 11px 3px rgba(0, 0, 0, 0.31);
  position: absolute;
  top: 34px;
  width: 90%;
  color: #000000de;
  transition: all 300ms;
  max-height: ${({ open }) => (open ? 'calc(50vh + 15px)' : '0')};
  min-height: ${({ open }) => (open ? '50px' : '0')};
  justify-content: stretch;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  overflow: hidden;
`;

export const Results = styled.div`
  padding: 10px;
  transition: all 300ms;
  max-height: ${({ open }) => (open ? '50vh' : '0')};
  overflow: auto;
  scrollbar-color: #555 #aaa;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: #aaa;
  }
  &::-webkit-scrollbar-thumb {
    background: #555;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #444;
  }
`;

export const SearchItem = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  border-bottom: 0.5px solid #a0a0a0de;
  margin-bottom: 10px;
`;

export const Title = styled(Link)`
  font-size: 1.05rem;
  text-decoration: none;
`;

export const Label = styled.span`
  font-weight: 600;
  display: block;
`;

export const Value = styled.span`
  color: #000000bc;
`;

export const SeeAll = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  transition: all 300ms;
  max-height: ${({ open }) => (open ? '50vh' : '0')};
  overflow: hidden;
`;

export const NoResults = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
  font-size: 1.1rem;
  transition: all 300ms;
  max-height: ${({ open }) => (open ? '50vh' : '0')};
  overflow: hidden;
`;

export const SpinnerContainer = styled.div`
  transition: all 300ms;
  max-height: ${({ open }) => (open ? '50vh' : '0')};
  margin-top: 10px;
  text-align: center;
  overflow: hidden;
`;
