export const GET_CUSTOMERS = '[customers] GET';
export const FETCH_CUSTOMERS_SUCCESS = '[customers] Fetch success';
export const FETCH_CUSTOMERS_ERROR = '[customers] Fetch Error';
export const UPDATE_CUSTOMERS = '[customers] Update';
export const SHOW_CUSTOMERS_SPINNER = '[customers - ui] show spinner';
export const HIDE_CUSTOMERS_SPINNER = '[customers - ui] hide spinner';

export const getCustomers = (filter) => ({
  type: GET_CUSTOMERS,
  payload: filter,
});

export const updateCustomers = (data) => ({
  type: UPDATE_CUSTOMERS,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_CUSTOMERS_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_CUSTOMERS_SPINNER,
});
