import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { NavProvider } from '../Nav';
import Navigation from '../Navigation';

function RouteWrapper({
  loggedUser: { authenticated },
  isPrivate,
  component: Component,
  wrapWithNavigation,
  ...rest
}) {
  if (isPrivate && !authenticated) {
    return <Redirect to="/login" />;
  }
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <NavProvider>
      <Route {...rest}>
        {wrapWithNavigation
          ? <Navigation component={<Component />} />
          : <Component />}
      </Route>
    </NavProvider>
  );
}

function mapStateToProps(state) {
  return { loggedUser: state.loggedUser };
}

export default connect(mapStateToProps)(RouteWrapper);
