import {
  UPDATE_CUSTOMER_MM_SUCCESS,
  UPDATE_CUSTOMER_MM_ERROR,
  FETCH_CUSTOMER_MM_SUCCESS,
  FETCH_CUSTOMER_MM_ERROR,
  GET_CUSTOMER_MM,
  CREATE_CUSTOMER_MM,
  UPDATE_CUSTOMER_MM,
  updateObjectCustomerMM,
  showSpinner,
  hideSpinner,
  hideModal,
} from '../actions/customerMM';
import { getCustomerMMs } from '../actions/customerMMs';
import Toastify from '../../utils/Toastify/index';
import { apiRequest } from '../actions/api';
import { CUSTOMER_KEY } from '../../config/consts';

const URL = 'material_masters';

export const createCustomerMMFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === CREATE_CUSTOMER_MM) {
    dispatch(
      apiRequest(
        'POST',
        URL,
        action.payload,
        UPDATE_CUSTOMER_MM_SUCCESS,
        UPDATE_CUSTOMER_MM_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const updateCustomerMMFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === UPDATE_CUSTOMER_MM) {
    dispatch(
      apiRequest(
        'PUT',
        URL,
        action.payload,
        UPDATE_CUSTOMER_MM_SUCCESS,
        UPDATE_CUSTOMER_MM_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const getCustomerMMFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_CUSTOMER_MM) {
    const { _id } = action.payload;
    dispatch(
      apiRequest(
        'GET-id',
        URL,
        { _id },
        FETCH_CUSTOMER_MM_SUCCESS,
        FETCH_CUSTOMER_MM_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const processCustomerMMCollection = ({ dispatch, getState }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_CUSTOMER_MM_SUCCESS) {
    dispatch(updateObjectCustomerMM(action.payload));
    dispatch(hideSpinner());
  } else if (action.type === UPDATE_CUSTOMER_MM_SUCCESS) {
    dispatch(updateObjectCustomerMM(action.payload));
    dispatch(hideModal());
    dispatch(hideSpinner());
    // To reload the page
    const { page, limit } = getState().customerMMs;
    dispatch(
      getCustomerMMs({
        page,
        limit,
        filter: { customer: localStorage.getItem(CUSTOMER_KEY) },
      }),
    );
    Toastify.addSuccess('Customer material master saved.');
  } else if (action.type === UPDATE_CUSTOMER_MM_ERROR) {
    dispatch(hideSpinner());
    Toastify.addError(
      action.payload || 'Error to save the customer material master',
    );
  }
};

export const customerMMMdl = [
  createCustomerMMFlow,
  updateCustomerMMFlow,
  getCustomerMMFlow,
  processCustomerMMCollection,
];
