import { UPDATE_BUSINESS_UNITS } from '../actions/businessUnits';

const initState = {
  docs: [],
  page: 1,
  limit: 10,
  totalDocs: 0,
};

export function businessUnitsReducer(businessUnits = initState, action) {
  switch (action.type) {
    case UPDATE_BUSINESS_UNITS:
      return action.payload;

    default:
      return businessUnits;
  }
}
