import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  TextField,
  FormGroup,
  Button,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import Alert from '../../../components/Alert/index';
import Spinner from '../../../components/Spinner/index';
import {
  Container,
  InputContent,
  ModalTitle,
  ButtonContainer,
  CheckboxContent,
} from './styles';

import {
  getBusinessUnit,
  createBusinessUnit,
  updateBusinessUnit,
} from '../../../redux/actions/businessUnit';

const styles = {
  formButton: {
    margin: 2,
  },
};

function BusinessUnitModal({
  id,
  dispatch,
  businessUnit,
  businessUnitModal,
  handleClose,
  businessUnitSpinner,
}) {
  const [active, setActive] = useState(true);
  const [unit, setUnit] = useState('');

  const [unitError, setUnitError] = useState(false);

  useEffect(() => {
    if (id !== 'new') {
      dispatch(getBusinessUnit(id));
    }
  }, [id]);

  useEffect(() => {
    if (businessUnit && id !== 'new') {
      setActive(businessUnit.active);
      setUnit(businessUnit.unit);
    }
  }, [businessUnit]);

  useEffect(() => {
    if (!businessUnitModal) {
      handleClose();
    }
  }, [businessUnitModal]);

  const handleSave = async () => {
    let err = false;
    setUnitError(!unit);
    err = !unit;

    if (!err) {
      const response = await Alert();
      if (response && response.confirm) {
        if (id === 'new') {
          dispatch(
            createBusinessUnit({
              active,
              unit,
            }),
          );
        } else {
          dispatch(
            updateBusinessUnit({
              _id: id,
              active,
              unit,
            }),
          );
        }
      }
    }
  };

  return (
    <>
      {businessUnitSpinner && <Spinner overlay />}
      <Container style={{ opacity: businessUnitSpinner ? 0.5 : 1 }}>
        <ModalTitle>
          {id === 'new' ? 'New' : 'Edit'}
          {' '}
          Business Unit
        </ModalTitle>
        <FormGroup>
          <CheckboxContent>
            <FormControlLabel
              control={(
                <Switch
                  checked={active}
                  onChange={(e) => setActive(e.target.checked)}
                />
              )}
              label="Active"
              labelPlacement="start"
            />
          </CheckboxContent>
          <InputContent>
            <TextField
              label="Unit*"
              error={unitError}
              helperText={unitError && 'Unit is required'}
              variant="outlined"
              value={unit}
              onChange={(e) => setUnit(e.target.value)}
              fullWidth
            />
          </InputContent>

          <ButtonContainer>
            <Button
              color="secondary"
              onClick={handleClose}
              variant="contained"
              style={styles.formButton}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={handleSave}
              variant="contained"
              style={styles.formButton}
            >
              Save
            </Button>
          </ButtonContainer>
        </FormGroup>
      </Container>
    </>
  );
}

export default connect((state) => ({
  businessUnit: state.businessUnit,
  businessUnitModal: state.ui.businessUnitModal,
  businessUnitSpinner: state.ui.businessUnitLoading,
}))(BusinessUnitModal);
