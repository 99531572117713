import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;
  padding: 1rem;
  & .MuiFormLabel-root:not(.Mui-disabled) {
    color: var(--primary-bg-color);
  }
  & .MuiFormControl-root:hover .MuiFormLabel-root:not(.Mui-disabled) {
    font-weight: 500;
  }
  & .MuiInputBase-root:not(.Mui-disabled) > fieldset {
    border-color: var(--primary-bg-color);
  }
  ${({ fabrication }) => fabrication
    && css`
      max-height: 95vh;
      overflow: auto;
    `}
`;

export const Form = styled.form`
  position: relative;
  padding: 1rem 0;
  min-height: 60vh;
  border-radius: 5px;
  background-color: white;

  .headerContainer {
    margin-bottom: 16px;
  }
`;

export const Title = styled.div`
  font-size: 1.3rem;
  padding-left: 10px;
`;

export const InputContent = styled.form`
  width: 100%;
  padding: 7px 2rem 10px 2rem;
`;

export const ButtonContainer = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

export const TabSheet = styled.div`
  .MuiTabs-root {
    background-color: white;
  }
  .MuiTab-textColorInherit {
    color: #2c2c32;
  }
  .Mui-selected {
    color: #000;
  }
  .MuiTabs-indicator {
    background-color: #000;
  }
`;
export const ModalTitle = styled.div`
  padding-left: 16px;
  padding-bottom: 16px;
  font-size: 1.3rem;
`;

export const ModalContent = styled.div`
  min-width: 60vw;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ModalButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;
