import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { TextField, FormGroup, Button } from '@material-ui/core';
import Alert from '../../../components/Alert/index';
import Spinner from '../../../components/Spinner/index';
import {
  Container, InputContent, ModalTitle, ButtonContainer,
} from './styles';

import {
  getReclassDamage,
  createReclassDamage,
  updateReclassDamage,
} from '../../../redux/actions/reclassDamage';

const styles = {
  formButton: {
    margin: 2,
  },
};

function ReclassDamageModal({
  id,
  dispatch,
  reclassDamage,
  reclassDamageModal,
  handleClose,
  reclassDamageSpinner,
}) {
  const [description, setDescription] = useState('');
  const [details, setDetails] = useState('');

  const [descriptionError, setDescriptionError] = useState(false);

  useEffect(() => {
    if (id !== 'new') {
      dispatch(getReclassDamage(id));
    }
  }, [id]);

  useEffect(() => {
    if (reclassDamage && id !== 'new') {
      setDescription(reclassDamage.description);
      setDetails(reclassDamage.details);
    }
  }, [reclassDamage]);

  useEffect(() => {
    if (!reclassDamageModal) {
      handleClose();
    }
  }, [reclassDamageModal]);

  const handleSave = async () => {
    let err = false;
    setDescriptionError(!description);
    err = !description;

    if (!err) {
      const response = await Alert();
      if (response && response.confirm) {
        if (id === 'new') {
          dispatch(
            createReclassDamage({
              description,
              details,
            }),
          );
        } else {
          dispatch(
            updateReclassDamage({
              _id: id,
              description,
              details,
            }),
          );
        }
      }
    }
  };

  return (
    <>
      {reclassDamageSpinner && <Spinner overlay />}
      <Container style={{ opacity: reclassDamageSpinner ? 0.5 : 1 }}>
        <ModalTitle>
          {id === 'new' ? 'New' : 'Edit'}
          {' '}
          ReclassDamage
        </ModalTitle>
        <FormGroup>
          <InputContent>
            <TextField
              id="outlined-password-input"
              label="Description*"
              type="text"
              error={descriptionError}
              helperText={descriptionError && 'Description is required'}
              variant="outlined"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              fullWidth
            />
          </InputContent>
          <InputContent>
            <TextField
              id="outlined-password-input"
              label="Details"
              type="text"
              multiline
              variant="outlined"
              value={details}
              onChange={(e) => setDetails(e.target.value)}
              fullWidth
            />
          </InputContent>
          <ButtonContainer>
            <Button
              color="secondary"
              onClick={handleClose}
              variant="contained"
              style={styles.formButton}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={handleSave}
              variant="contained"
              style={styles.formButton}
            >
              Save
            </Button>
          </ButtonContainer>
        </FormGroup>
      </Container>
    </>
  );
}

export default connect((state) => ({
  reclassDamage: state.reclassDamage,
  reclassDamageModal: state.ui.reclassDamageModal,
  reclassDamageSpinner: state.ui.reclassDamageLoading,
}))(ReclassDamageModal);
