import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  useHistory,
  useParams,
  useLocation,
  withRouter,
} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Button from '@material-ui/core/Button';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import { ArrowBack, SkipNext, AddRounded } from '@material-ui/icons';
import InventoryFilter from '../../components/InventoryFilter';
import { getMovimentations } from '../../redux/actions/movimentations';
import Spinner from '../../components/Spinner';
import Alert from '../../components/Alert';
import { Container, AddButton } from './styles';
import { addToInventoryCart } from '../../redux/actions/inventory_cart';
import { tableStyles } from '../../styles/tableStyles';
import BuildOfMaterialRow from '../../components/BuildOfMaterialRow';

function BuildOfMaterial({
  dispatch,
  movimentations,
  movimentationsLoading,
  movimentationsFilter,
  loggedUser,
  inventoryCart,
}) {
  const { customer } = useParams();
  const history = useHistory();
  const classes = tableStyles();
  const [movimentationsFormated, setMovimentationsFormated] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(50);
  const [total, setTotal] = useState(0);
  const [orderBy, setOrderBy] = useState('individual_item_id');
  const [order, setOrder] = useState('desc');
  const [searched, setSearched] = useState(false);
  const { state } = useLocation();
  const consolidatedView = false;

  useEffect(() => {
    if (firstLoad) {
      const newFilter = JSON.parse(JSON.stringify(movimentationsFilter));
      newFilter.customer = customer;
      // eslint-disable-next-line radix
      setPage(Number.parseInt(movimentations.page) - 1);
      setFirstLoad(false);
    }
  }, []);

  useEffect(() => {
    if (firstLoad) return;
    if (state && state.fromSearch && !searched) {
      return setSearched(true);
    }
    dispatch(
      getMovimentations({
        page: page + 1,
        limit,
        filter: {
          customer,
          in_inventory: true,
          consolidated: consolidatedView,
          material_layout: ['B.O.M', 'Cont'],
          ...movimentationsFilter,
        },
        orderBy,
        order,
      }),
    );
  }, [page, limit, firstLoad, orderBy, order]);

  useEffect(() => {
    if (movimentations.docs) {
      setMovimentationsFormated(movimentations.docs);
      setTotal(movimentations.totalDocs);
    }
  }, [movimentations]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  function handleAddToCart(idList) {
    function handler() {
      dispatch(addToInventoryCart(loggedUser._id, idList));
    }
    return handler;
  }

  const styles = {
    modalContent: {
      backgroundColor: 'transparent',
    },
    editButton: {
      marginRight: 10,
    },
  };

  function handleSort(field) {
    function handler(e) {
      if (orderBy === field) { return setOrder((currentOrder) => (currentOrder === 'desc' ? 'asc' : 'desc')); }
      setOrderBy(field);
      setOrder('desc');
    }
    return handler;
  }

  function isActive(field) {
    return field === orderBy;
  }

  const handleBack = () => {
    history.goBack();
  };

  function handleSkip() {
    Alert(
      'Are you sure?',
      'This will handle the previously selected equipment as parents to this build of material.',
      undefined,
      undefined,
      'Yes, continue.',
    ).then(({ confirm }) => {
      if (confirm) {
        history.push('/build_of_material/new', {
          parents: state.movimentations.map((mov) => mov._id || mov),
          movimentations: state.movimentations.map((mov) => mov._id || mov),
        });
      }
    });
  }

  return (
    <Container>
      <InventoryFilter />
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Toolbar className={classes.toolBar}>
            <span>
              <IconButton onClick={handleBack}>
                <ArrowBack />
              </IconButton>
              <Typography
                className={classes.title}
                variant="h6"
                id="tableTitle"
                component="span"
              >
                Select Parent
              </Typography>
            </span>
            <span>
              <AddButton>
                <Button color="secondary" onClick={handleSkip}>
                  <SkipNext />
                  Skip
                </Button>
                <Button
                  className={classes.buttons}
                  onClick={() => history.push('/equipment/new', {
                    creatingBOM: true,
                    ...state,
                  })}
                >
                  <AddRounded />
                  Add Equipment
                </Button>
              </AddButton>
            </span>
          </Toolbar>
          <TableContainer className={classes.table}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell
                    align="center"
                    sortDirection={
                      isActive('individual_item_id') ? order : false
                    }
                  >
                    <TableSortLabel
                      active={isActive('individual_item_id')}
                      direction={
                        isActive('individual_item_id') ? order : 'desc'
                      }
                      onClick={handleSort('individual_item_id')}
                    >
                      RAMP Unique ID
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    align="center"
                    sortDirection={isActive('status') ? order : false}
                  >
                    <TableSortLabel
                      active={isActive('status')}
                      direction={isActive('status') ? order : 'desc'}
                      onClick={handleSort('status')}
                    >
                      Status
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    align="center"
                    sortDirection={
                      isActive('material_layout') ? order : false
                    }
                  >
                    <TableSortLabel
                      active={isActive('material_layout')}
                      direction={isActive('material_layout') ? order : 'desc'}
                      onClick={handleSort('material_layout')}
                    >
                      Material Layout
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    align="center"
                    colSpan={3}
                    sortDirection={
                      isActive('customer_MM.short_description')
                        ? order
                        : false
                    }
                  >
                    <TableSortLabel
                      active={isActive('customer_MM.short_description')}
                      direction={
                        isActive('customer_MM.short_description')
                          ? order
                          : 'desc'
                      }
                      onClick={handleSort('customer_MM.short_description')}
                    >
                      RINA Description
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">
                    <TableSortLabel>Resides in</TableSortLabel>
                  </TableCell>
                  <TableCell
                    align="center"
                    sortDirection={isActive('status') ? order : false}
                  >
                    <TableSortLabel
                      active={isActive('status')}
                      direction={isActive('status') ? order : 'desc'}
                      onClick={handleSort('status')}
                    >
                      Childre No.
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    align="center"
                    sortDirection={
                      isActive('customer_MM.rina_mm_number') ? order : false
                    }
                  >
                    <TableSortLabel
                      active={isActive('customer_MM.rina_mm_number')}
                      direction={
                        isActive('customer_MM.rina_mm_number') ? order : 'desc'
                      }
                      onClick={handleSort('customer_MM.rina_mm_number')}
                    >
                      RINA MM No
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    align="center"
                    sortDirection={isActive('qty') ? order : false}
                  >
                    <TableSortLabel
                      active={isActive('qty')}
                      direction={isActive('qty') ? order : 'desc'}
                      onClick={handleSort('qty')}
                    >
                      Total Qty. (EA)
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    align="center"
                    sortDirection={isActive('footage') ? order : false}
                  >
                    <TableSortLabel
                      active={isActive('footage')}
                      direction={isActive('footage') ? order : 'desc'}
                      onClick={handleSort('footage')}
                    >
                      Footage
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    align="center"
                    sortDirection={isActive('batch_no') ? order : false}
                  >
                    <TableSortLabel
                      active={isActive('batch_no')}
                      direction={isActive('batch_no') ? order : 'desc'}
                      onClick={handleSort('batch_no')}
                    >
                      Supplier Batch No
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    align="center"
                    sortDirection={isActive('SLOC.sloc_name') ? order : false}
                  >
                    <TableSortLabel
                      active={isActive('SLOC.sloc_name')}
                      direction={isActive('SLOC.sloc_name') ? order : 'desc'}
                      onClick={handleSort('SLOC.sloc_name')}
                    >
                      SLOC
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    align="center"
                    sortDirection={
                      isActive('original_charge.well.project.name')
                        ? order
                        : false
                    }
                  >
                    <TableSortLabel
                      active={isActive('original_charge.well.project.name')}
                      direction={
                        isActive('original_charge.well.project.name')
                          ? order
                          : 'desc'
                      }
                      onClick={handleSort(
                        'original_charge.well.project.name',
                      )}
                    >
                      Assigned Project
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    align="center"
                    sortDirection={
                      isActive('original_charge.original_charge_code')
                        ? order
                        : false
                    }
                  >
                    <TableSortLabel
                      active={isActive(
                        'original_charge.original_charge_code',
                      )}
                      direction={
                        isActive('original_charge.original_charge_code')
                          ? order
                          : 'desc'
                      }
                      onClick={handleSort(
                        'original_charge.original_charge_code',
                      )}
                    >
                      Assigned Charge Code
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">Allocated</TableCell>
                  <TableCell
                    align="center"
                    sortDirection={
                      isActive('allocated_batch_number') ? order : false
                    }
                  >
                    <TableSortLabel
                      active={isActive('allocated_batch_number')}
                      direction={
                        isActive('allocated_batch_number') ? order : 'desc'
                      }
                      onClick={handleSort('allocated_batch_number')}
                    >
                      Allocated Batch No
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    align="center"
                    sortDirection={
                      isActive('allocated_work_order_number') ? order : false
                    }
                  >
                    <TableSortLabel
                      active={isActive('allocated_work_order_number')}
                      direction={
                        isActive('allocated_work_order_number')
                          ? order
                          : 'desc'
                      }
                      onClick={handleSort('allocated_work_order_number')}
                    >
                      Allocated WO No
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {movimentationsLoading && (
                  <TableRow className={classes.row}>
                    <TableCell colSpan="24" className={classes.cell}>
                      <Spinner parent="Table" />
                    </TableCell>
                  </TableRow>
                )}
                {!movimentationsLoading
                  && movimentationsFormated.map((rr) => (
                    <BuildOfMaterialRow
                      rr={rr}
                      inventoryCart={inventoryCart}
                      styles={styles}
                      handleAddToCart={handleAddToCart}
                    />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[50, 100, 200, 500, 1000]}
            component="div"
            count={total}
            rowsPerPage={limit}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  customers: state.customers,
  movimentationsLoading: state.ui.movimentationsLoading,
  movimentations: state.movimentations,
  movimentationsFilter: state.movimentationsFilter,
  loggedUser: state.loggedUser,
  inventoryCart: state.inventory_cart.all,
});

export default withRouter(connect(mapStateToProps)(BuildOfMaterial));
