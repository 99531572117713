import React from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import TabPanel from '../../../../components/TabPanel';
import DefaultInput from './DefaultInput';

function MaterialMasterData({ data: { customer_MM }, selectedTab, tabIndex }) {
  const customerMMs = useSelector((state) =>
    (state.customerMMs?.docs || state.customerMMs).sort((a, b) =>
      a?.mm_number?.localeCompare(b?.mm_number)
    )
  );

  const equipmentSpinner = useSelector(
    (state) => state.ui.movimentationLoading
  );

  const selectedMaterialMaster = customerMMs?.find(
    (current) => current._id === customer_MM
  );

  const fields = [
    { label: 'Equipment Type', key: 'equipment_type' },
    { label: 'Modifier', key: 'modifier' },
    { label: 'Max OD Size', key: 'size' },
    { label: 'Tube Weight', key: 'weight' },
    { label: 'Material Grade', key: 'grade' },
    { label: 'Connection 1', key: 'connection_1' },
    { label: 'Connection 1 (SIZE)', key: 'connection_1_size' },
    { label: 'Connection 1 (WEIGHT)', key: 'connection_1_weight' },
    { label: 'Connection 1 (GRADE)', key: 'connection_1_grade' },
    { label: 'Connection 2', key: 'connection_2' },
    { label: 'Connection 2 (SIZE)', key: 'connection_2_size' },
    { label: 'Connection 2 (WEIGHT)', key: 'connection_2_weight' },
    { label: 'Connection 2 (GRADE)', key: 'connection_2_grade' },
    { label: 'Connection Configuration', key: 'connection_configuration' },
    { label: 'Range', key: 'range' },
    { label: 'Pipe Type', key: 'pipe_type' },
    { label: 'Manufacturer', key: 'manufacturer' },
    { label: 'Manufacturer Part Number', key: 'manufacturer_pn' },
    { label: 'Nominal Wall', key: 'nominal_wall' },
    { label: 'Drift Diameter', key: 'drift_diameter' },
  ];

  return (
    <TabPanel value={selectedTab} index={tabIndex} dir="ltr">
      <Grid container style={{ opacity: equipmentSpinner ? 0.3 : 1 }}>
        {fields.map(({ key, label }) => (
          <DefaultInput
            disabled
            label={label}
            value={selectedMaterialMaster?.[key] || ''}
          />
        ))}
      </Grid>
    </TabPanel>
  );
}

export default MaterialMasterData;
