import styled from 'styled-components';

export const FooterContainer = styled.footer`
  width: calc(100%);
  background-color: var(--primary-bg-color);
  color: var(--primary-white-color);
  box-shadow: 0 2px 20px rgba(0,0,0, 0.6);
  /* position: fixed; */
  bottom: 0;
  left: 0;
  z-index: 1000;
`;

export const FooterText = styled.p`
  display: block;
  text-align: center;
  padding: 8px 0;
  font-size: 0.8rem;
`;
