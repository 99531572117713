import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { TextField, FormGroup, Button } from '@material-ui/core';
import Alert from '../../../components/Alert/index';
import Spinner from '../../../components/Spinner/index';
import {
  Container, InputContent, ModalTitle, ButtonContainer,
} from './styles';

import {
  getProject,
  createProject,
  updateProject,
} from '../../../redux/actions/project';

const styles = {
  formButton: {
    margin: 2,
  },
};

function ProjectModal({
  id,
  dispatch,
  project,
  projectModal,
  handleClose,
  projectSpinner,
}) {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const [nameError, setNameError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);

  useEffect(() => {
    if (id !== 'new') {
      dispatch(getProject(id));
    }
  }, [id]);

  useEffect(() => {
    if (project && id !== 'new') {
      setName(project.name);
      setDescription(project.description);
    }
  }, [project]);

  useEffect(() => {
    if (!projectModal) {
      handleClose();
    }
  }, [projectModal]);

  const handleSave = async () => {
    let err = false;
    setNameError(!name);
    setDescriptionError(!description);
    err = !name || !description;

    if (!err) {
      const response = await Alert();
      if (response && response.confirm) {
        if (id === 'new') {
          dispatch(
            createProject({
              name,
              description,
            }),
          );
        } else {
          dispatch(
            updateProject({
              _id: id,
              name,
              description,
            }),
          );
        }
      }
    }
  };

  return (
    <>
      {projectSpinner && <Spinner overlay />}
      <Container style={{ opacity: projectSpinner ? 0.5 : 1 }}>
        <ModalTitle>
          {id === 'new' ? 'New' : 'Edit'}
          {' '}
          Project
        </ModalTitle>
        <FormGroup>
          <InputContent>
            <TextField
              id="outlined-password-input"
              label="Name*"
              type="text"
              error={nameError}
              helperText={nameError && 'Name is required'}
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
            />
          </InputContent>
          <InputContent>
            <TextField
              id="outlined-password-input"
              label="Description*"
              type="text"
              multiline
              error={descriptionError}
              helperText={descriptionError && 'Description is required'}
              variant="outlined"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              fullWidth
            />
          </InputContent>
          <ButtonContainer>
            <Button
              color="secondary"
              onClick={handleClose}
              variant="contained"
              style={styles.formButton}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={handleSave}
              variant="contained"
              style={styles.formButton}
            >
              Save
            </Button>
          </ButtonContainer>
        </FormGroup>
      </Container>
    </>
  );
}

export default connect((state) => ({
  project: state.project,
  projectModal: state.ui.projectModal,
  projectSpinner: state.ui.projectLoading,
}))(ProjectModal);
