import React from 'react';
import {
  Table,
  TableRow,
  TableCell,
  TableContainer,
  Box,
} from '@material-ui/core';
import InspectionRow from '../InspectionRow';
import ServiceTable from '../ResultServiceTable';
import MaintenanceTable from '../ResultMaintenanceTable';
import { TableHead, TableBody } from './styles';

function RejectedTable({ data: { equipment_service: equipmentService = [] } }) {
  const rejectedItems = equipmentService.filter(
    ({ status }) => status === 'Rejected'
  );

  const equipmentMap = rejectedItems.reduce((map, item) => {
    if (!map[item.equipment._id]) {
      map[item.equipment._id] = {
        equipment: item.equipment,
        maintenances: [],
        services: [],
      };
    }
    if (item.service)
      map[item.equipment._id].services.push({
        service: item.service,
        note: item.note,
        photo: item.photo,
      });
    if (item.maintenance)
      map[item.equipment._id].maintenances.push({
        maintenance: item.maintenance,
        note: item.note,
        photo: item.photo,
      });
    return map;
  }, {});

  return (
    <Box marginTop="2rem">
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={13} align="center">
                Rejected Items
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell />
              <TableCell>Unique ID</TableCell>
              <TableCell>RINA Description</TableCell>
              <TableCell>Customer MM No</TableCell>
              <TableCell>Total Qty. (EA)</TableCell>
              <TableCell>Footage</TableCell>
              <TableCell>COPAS Class</TableCell>
              <TableCell>Supplier MM No</TableCell>
              <TableCell>Supplier Batch No</TableCell>
              <TableCell>Heat/Lot</TableCell>
              <TableCell>RBW</TableCell>
              <TableCell>Facility Reference ID</TableCell>
              <TableCell>Manufacture</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!rejectedItems.length && (
              <TableRow>
                <TableCell colSpan={13} align="center">
                  No Rejected Items
                </TableCell>
              </TableRow>
            )}
            {Object.keys(equipmentMap).map((item) => (
              <InspectionRow
                equipment={equipmentMap[item].equipment}
                maintenances={equipmentMap[item].maintenances}
                services={equipmentMap[item].services}
                key={item}
                MaintenanceTableComponent={(props) => (
                  <MaintenanceTable {...props} hasNote />
                )}
                ServiceTableComponent={(props) => (
                  <ServiceTable {...props} hasNote />
                )}
                equipmentService={equipmentService}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default RejectedTable;
