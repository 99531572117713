import {
  UPDATE_WAREHOUSE_SUCCESS,
  UPDATE_WAREHOUSE_ERROR,
  FETCH_WAREHOUSE_SUCCESS,
  FETCH_WAREHOUSE_ERROR,
  GET_WAREHOUSE,
  CREATE_WAREHOUSE,
  UPDATE_WAREHOUSE,
  updateObjectWarehouse,
  showSpinner,
  hideSpinner,
  hideModal,
} from '../actions/warehouse';
import { getSLOCS } from '../actions/slocs';
import Toastify from '../../utils/Toastify/index';
import { apiRequest } from '../actions/api';

const URL = 'slocs/warehouse';

export const createWarehouseFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === CREATE_WAREHOUSE) {
    dispatch(
      apiRequest(
        'POST',
        `${URL}s`,
        action.payload,
        UPDATE_WAREHOUSE_SUCCESS,
        UPDATE_WAREHOUSE_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const updateWarehouseFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === UPDATE_WAREHOUSE) {
    dispatch(
      apiRequest(
        'PUT',
        URL,
        action.payload,
        UPDATE_WAREHOUSE_SUCCESS,
        UPDATE_WAREHOUSE_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const getWarehouseFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_WAREHOUSE) {
    const { _id } = action.payload;
    dispatch(
      apiRequest(
        'GET-id',
        URL,
        { _id },
        FETCH_WAREHOUSE_SUCCESS,
        FETCH_WAREHOUSE_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const processWarehouseCollection = ({ dispatch, getState }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_WAREHOUSE_SUCCESS) {
    dispatch(updateObjectWarehouse(action.payload));
    dispatch(hideSpinner());
  } else if (action.type === UPDATE_WAREHOUSE_SUCCESS) {
    dispatch(updateObjectWarehouse(action.payload));
    dispatch(hideModal());
    dispatch(hideSpinner());
    // To reload the page
    const { page, limit } = getState().slocs;
    dispatch(getSLOCS({ page, limit }));
    Toastify.addSuccess('Warehouse saved.');
  } else if (action.type === UPDATE_WAREHOUSE_ERROR) {
    dispatch(hideSpinner());
    Toastify.addError(action.payload || 'Error to save the warehouse');
  }
};

export const warehouseMdl = [
  createWarehouseFlow,
  updateWarehouseFlow,
  getWarehouseFlow,
  processWarehouseCollection,
];
