import {
  DELETE_ENTRY,
  DELETE_ENTRIES,
  GET_DIRECTORY_CONTENT,
  GET_DIRECTORY_INFO,
  GET_DIRECTORY_STRUCTURE,
  GET_COMPLETE_DIRECTORY_STRUCTURE,
  GET_ENTRY_INFO,
  GET_ROOT,
  SUCCESS_GET_ROOT,
  SUCCESS_GET_DIRECTORY_CONTENT,
  SUCCESS_GET_DIRECTORY_INFO,
  SUCCESS_GET_DIRECTORY_STRUCTURE,
  SUCCESS_GET_ENTRY_INFO,
  ERROR_GET_ROOT,
  ERROR_GET_DIRECTORY_CONTENT,
  ERROR_GET_DIRECTORY_INFO,
  ERROR_GET_DIRECTORY_STRUCTURE,
  ERROR_GET_ENTRY_INFO,
  updateDirectoryContent,
  updateDirectoryInfo,
  updateDirectoryStructure,
  updateEntryInfo,
  showStructureSpinner,
  hideStructureSpinner,
  SUCCESS_CREATE_FOLDER,
  SUCCESS_UPLOAD_FILES,
  ERROR_CREATE_FOLDER,
  ERROR_UPLOAD_FILES,
  SUCCESS_DELETE_ENTRY,
  SUCCESS_DELETE_ENTRIES,
  ERROR_DELETE_ENTRY,
  CREATE_FOLDER,
  UPLOAD_FILES,
  GET_SPEC_LIBRARY,
  ERROR_GET_SPEC_LIBRARY,
  SUCCESS_GET_SPEC_LIBRARY,
  getDirectoryContent,
  updateSpecLibraryObject,
} from '../actions/vault';
import { apiRequest } from '../actions/api';
import Toastify from '../../utils/Toastify';

const URL = 'vault';

function getDirectoryContentFlow({ dispatch }) {
  return function (next) {
    return function (action) {
      next(action);
      if (action.type === GET_DIRECTORY_CONTENT) {
        dispatch(
          apiRequest(
            'GET-id',
            URL,
            { _id: `${action.payload}/content` },
            SUCCESS_GET_DIRECTORY_CONTENT,
            ERROR_GET_DIRECTORY_CONTENT,
          ),
        );
        // dispatch(showSpinner());
      }
    };
  };
}

function getRootFlow({ dispatch }) {
  return function (next) {
    return function (action) {
      next(action);
      if (action.type === GET_ROOT) {
        dispatch(apiRequest('GET', URL, {}, SUCCESS_GET_ROOT, ERROR_GET_ROOT));
        // dispatch(showSpinner());
      }
    };
  };
}

function getSpecLibraryFlow({ dispatch }) {
  return function (next) {
    return function (action) {
      next(action);
      if (action.type === GET_SPEC_LIBRARY) {
        dispatch(
          apiRequest(
            'GET',
            'vault/spec',
            {},
            SUCCESS_GET_SPEC_LIBRARY,
            ERROR_GET_SPEC_LIBRARY,
          ),
        );
        // dispatch(showSpinner());
      }
    };
  };
}

function getDirectoryInfoFlow({ dispatch }) {
  return function (next) {
    return function (action) {
      next(action);
      if (action.type === GET_DIRECTORY_INFO) {
        dispatch(
          apiRequest(
            'GET-id',
            URL,
            { _id: action.payload },
            SUCCESS_GET_DIRECTORY_INFO,
            ERROR_GET_DIRECTORY_INFO,
          ),
        );
        // dispatch(showSpinner());
      }
    };
  };
}
function getDirectoryStructureFlow({ dispatch }) {
  return function (next) {
    return function (action) {
      next(action);
      if (action.type === GET_DIRECTORY_STRUCTURE) {
        dispatch(
          apiRequest(
            'GET-id',
            URL,
            { _id: `${action.payload}/structure`, forwardId: true },
            SUCCESS_GET_DIRECTORY_STRUCTURE,
            ERROR_GET_DIRECTORY_STRUCTURE,
          ),
        );
        dispatch(showStructureSpinner(action.payload));
      }
    };
  };
}
function getCompleteDirectoryStructureFlow({ dispatch }) {
  return function (next) {
    return function (action) {
      next(action);
      if (action.type === GET_COMPLETE_DIRECTORY_STRUCTURE) {
        dispatch(
          apiRequest(
            'GET-id',
            URL,
            { _id: `${action.payload}/structure/complete`, forwardId: true },
            SUCCESS_GET_DIRECTORY_STRUCTURE,
            ERROR_GET_DIRECTORY_STRUCTURE,
          ),
        );
        dispatch(showStructureSpinner(action.payload));
      }
    };
  };
}
function getEntryInfoFlow({ dispatch }) {
  return function (next) {
    return function (action) {
      next(action);
      if (action.type === GET_ENTRY_INFO) {
        dispatch(
          apiRequest(
            'GET-id',
            URL,
            { _id: action.payload },
            SUCCESS_GET_ENTRY_INFO,
            ERROR_GET_ENTRY_INFO,
          ),
        );
        // dispatch(showSpinner());
      }
    };
  };
}
function createFolderFlow({ dispatch, getState }) {
  return function (next) {
    return function (action) {
      next(action);
      if (action.type === CREATE_FOLDER) {
        dispatch(
          apiRequest(
            'POST',
            `${URL}/${action.payload.parent}/create`,
            { name: action.payload.name, creator: getState().loggedUser._id },
            SUCCESS_CREATE_FOLDER,
            ERROR_CREATE_FOLDER,
          ),
        );
        // dispatch(showSpinner());
      }
    };
  };
}
function uploadFilesFlow({ dispatch, getState }) {
  return function (next) {
    return function (action) {
      next(action);
      if (action.type === UPLOAD_FILES) {
        const form = new FormData();
        Array.from(action.payload.files).forEach((file) => {
          form.append('files', file);
        });
        form.append('creator', getState().loggedUser._id);
        dispatch(
          apiRequest(
            'POST',
            `${URL}/${action.payload.parent}/upload`,
            form,
            SUCCESS_UPLOAD_FILES,
            ERROR_UPLOAD_FILES,
          ),
        );
        // dispatch(showSpinner());
      }
    };
  };
}
function deleteEntryFlow({ dispatch/*, getState*/ }) {
  return function (next) {
    return function (action) {
      next(action);
      if (action.type === DELETE_ENTRY) {
        dispatch(
          apiRequest(
            'DELETE',
            URL,
            action.payload,
            SUCCESS_DELETE_ENTRY,
            ERROR_DELETE_ENTRY,
          ),
        );
        // dispatch(showSpinner());
      }
    };
  };
}
function deleteEntriesFlow({ dispatch/*, getState*/ }) {
  return function (next) {
    return function (action) {
      next(action);
      if (action.type === DELETE_ENTRIES) {
        dispatch(
          apiRequest(
            'POST',
            `${URL}/delete`,
            action.payload,
            SUCCESS_DELETE_ENTRIES,
            ERROR_DELETE_ENTRY,
          ),
        );
        // dispatch(showSpinner());
      }
    };
  };
}

function processVaultCollection({ dispatch }) {
  return function (next) {
    return function (action) {
      next(action);
      switch (action.type) {
        case SUCCESS_DELETE_ENTRIES:
          window.location.reload();
          break;
        case SUCCESS_GET_DIRECTORY_CONTENT:
          dispatch(updateDirectoryContent(action.payload));
          break;
        case SUCCESS_GET_DIRECTORY_INFO:
          dispatch(updateDirectoryInfo(action.payload));
          break;
        case SUCCESS_GET_DIRECTORY_STRUCTURE:
          dispatch(updateDirectoryStructure(action.payload.data));
          const forwardedId = String(action.payload?._id);
          dispatch(
            hideStructureSpinner(
              forwardedId.substring(0, forwardedId.indexOf('/'))
            )
          );
          break;
        case SUCCESS_GET_ENTRY_INFO:
          dispatch(updateEntryInfo(action.payload));
        case SUCCESS_GET_ROOT:
          dispatch(updateDirectoryInfo(action.payload.info));
          dispatch(updateDirectoryContent(action.payload.content));
          break;
        case SUCCESS_GET_SPEC_LIBRARY:
          dispatch(updateSpecLibraryObject(action.payload.content));
          break;
        case SUCCESS_CREATE_FOLDER:
          dispatch(
            getDirectoryContent(
              action.payload.path[action.payload.files[0].path.length - 1]
            )
          );
          break;
        case SUCCESS_UPLOAD_FILES:
          if (!action.payload.ok) {
            if (action.payload.files.length > 0) {
              Toastify.addWarning('Error uploading some of the files');
              dispatch(
                getDirectoryContent(
                  action.payload.files[0].path[
                  action.payload.files[0].path.length - 1
                  ]
                )
              );
            } else Toastify.addError("Error - the files couldn't be uploaded");
          }
          dispatch(
            getDirectoryContent(
              action.payload.files[0].path[
              action.payload.files[0].path.length - 1
              ]
            )
          );
          break;
        case ERROR_UPLOAD_FILES:
          Toastify.addWarning("Error - the files couldn't be uploaded");
          break;
        default:
          break;
      }
    };
  };
}

export const vaultMdl = [
  getDirectoryContentFlow,
  getDirectoryInfoFlow,
  getDirectoryStructureFlow,
  getCompleteDirectoryStructureFlow,
  getEntryInfoFlow,
  getRootFlow,
  processVaultCollection,
  createFolderFlow,
  uploadFilesFlow,
  deleteEntryFlow,
  deleteEntriesFlow,
  getSpecLibraryFlow,
];
