import {
  FETCH_COMPANY_CODES_SUCCESS,
  FETCH_COMPANY_CODES_ERROR,
  GET_COMPANY_CODES,
  updateCompanyCodes,
  showSpinner,
  hideSpinner,
} from '../actions/companyCodes';
import { apiRequest } from '../actions/api';

const URL = 'company_codes';

export const getCompanyCodesFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_COMPANY_CODES) {
    dispatch(
      apiRequest(
        'GET',
        URL,
        action.payload,
        FETCH_COMPANY_CODES_SUCCESS,
        FETCH_COMPANY_CODES_ERROR,
        ['listAll', 'active'],
      ),
    );
    dispatch(showSpinner());
  }
};

export const processCompanyCodesCollection = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_COMPANY_CODES_SUCCESS) {
    dispatch(updateCompanyCodes(action.payload));
    dispatch(hideSpinner());
  }
};

export const companyCodesMdl = [
  getCompanyCodesFlow,
  processCompanyCodesCollection,
];
