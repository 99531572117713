function getUnity(field = '') {
  if (field.toLowerCase().includes('weight')) return 'LB';
  if (field.toLowerCase().includes('size')) return 'IN';
  return false;
}

export function numberValues(customer_MM, field) {
  if (!customer_MM) return '';
  const unity = getUnity(field);
  const value = customer_MM[field];
  const number = Number.parseFloat(value);
  if (Number.isNaN(number)) return value;
  const formattedValue = number.toFixed(3);
  if (unity) return `${formattedValue} ${unity}`;
  return formattedValue;
}

function getRINADescription(customerMM) {
  if (!customerMM) return '';
  const {
    equipment_type,
    modifier,
    grade,
    connection_1,
    connection_1_grade,
    connection_2,
    connection_2_grade,
    connection_configuration,
    range,
  } = customerMM;

  const [
    size,
    weight,
    connection_1_size,
    connection_1_weight,
    connection_2_size,
    connection_2_weight,
  ] = [
    numberValues(customerMM, 'size'),
    numberValues(customerMM, 'weight'),
    numberValues(customerMM, 'connection_1_size'),
    numberValues(customerMM, 'connection_1_weight'),
    numberValues(customerMM, 'connection_2_size'),
    numberValues(customerMM, 'connection_2_weight'),
  ];

  let description = equipment_type || '';
  if (
    modifier
    || size
    || weight
    || grade
    || connection_1
    || connection_1_size
    || connection_1_weight
    || connection_1_grade
    || connection_2
    || connection_2_size
    || connection_2_weight
    || connection_2_grade
    || connection_configuration
    || range
  ) description += ':';

  const sequences = [
    [modifier || '', size, weight, grade],
    [connection_1, connection_1_size, connection_1_weight, connection_1_grade],
    [
      connection_2,
      connection_2_size,
      connection_2_weight,
      connection_2_grade,
      connection_configuration,
      range ? `RANGE ${range}` : range,
    ],
  ];

  sequences[0].forEach((field) => {
    if (!field) return;
    if (description.length > 0) {
      description += ' ';
    }
    description += field;
  });

  if (
    connection_1
    || connection_1_size
    || connection_1_size
    || connection_1_weight
    || connection_1_weight
    || connection_1_grade
    || connection_1_grade
    || connection_2
    || connection_2_size
    || connection_2_size
    || connection_2_weight
    || connection_2_weight
    || connection_2_grade
    || connection_2_grade
    || connection_configuration
    || range
  ) description += ' (';

  sequences[1].forEach((field) => {
    if (!field) return;
    if (description.length > 0 && description[description.length - 1] !== '(') {
      description += ' ';
    }
    description += field;
  });
  if (
    (connection_1
      || connection_1_size
      || connection_1_weight
      || connection_1_weight
      || connection_1_grade
      || connection_1_grade
      || connection_2)
    && (connection_2_size
      || connection_2_weight
      || connection_2_weight
      || connection_2_grade
      || connection_2_grade
      || connection_configuration
      || range)
  ) description += ' /';
  sequences[2].forEach((field) => {
    if (!field) return;
    if (
      (description.length > 0 && description[description.length - 1] !== '(')
      || description[description.length - 1] !== '/'
    ) {
      description += ' ';
    }
    description += field;
  });

  if (
    connection_1
    || connection_1_size
    || connection_1_weight
    || connection_1_weight
    || connection_1_grade
    || connection_1_grade
    || connection_2
    || connection_2_size
    || connection_2_size
    || connection_2_weight
    || connection_2_weight
    || connection_2_grade
    || connection_2_grade
    || connection_configuration
    || range
  ) description += ')';

  return description;
}

export default getRINADescription;
