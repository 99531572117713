import {
  FETCH_CUSTOMERS_SUCCESS,
  FETCH_CUSTOMERS_ERROR,
  GET_CUSTOMERS,
  updateCustomers,
  showSpinner,
  hideSpinner,
} from '../actions/customers';
import { apiRequest } from '../actions/api';

const URL = 'customers';

export const getCustomersFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_CUSTOMERS) {
    dispatch(
      apiRequest(
        'GET',
        URL,
        action.payload,
        FETCH_CUSTOMERS_SUCCESS,
        FETCH_CUSTOMERS_ERROR,
        ['listAll', 'active'],
      ),
    );
    dispatch(showSpinner());
  }
};

export const processCustomersCollection = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_CUSTOMERS_SUCCESS) {
    dispatch(updateCustomers(action.payload));
    dispatch(hideSpinner());
  }
};

export const customersMdl = [getCustomersFlow, processCustomersCollection];
