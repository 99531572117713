import { SET_REFINE_FILTER } from '../actions/refineFilter';

export function refineFilterReducer(refineFilter = {}, action) {
  switch (action.type) {
    case SET_REFINE_FILTER:
      return action.payload;

    default:
      return refineFilter;
  }
}
