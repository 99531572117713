import React, { useState, Fragment } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Collapse from '@material-ui/core/Collapse';
import { Grid, TablePagination } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import {
  AddShoppingCart,
  BarChart,
  History,
  Edit,
  SubdirectoryArrowRight,
} from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';
import axios from 'axios';
import Spinner from '../../components/Spinner/index';
import RowWithLayout from '../RowWithLayout';
import TableHeaderWithLayout from '../TableHeaderWithLayout';
import { ButtonsContainer, Title } from './styles';
import { tableStyles } from '../../styles/tableStyles';
import { apiUrl } from '../../config/consts';
import getColorFromClass from '../../utils/Equipment/getColorFromClass';

const Row = (props) => {
  const {
    rr,
    styles,
    handleAddToCart,
    inventoryCart,
    customerSelected,
    layout,
    hideButtons,
    hasCheckbox,
    onChangeCheckbox,
    checkboxValue,
  } = props;
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [formattedChildren, setFormattedChildren] = useState([]);
  const [formattedParent, setFormattedParent] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [childrenHasMore, setChildrenHasMore] = useState('');
  const [consolidatedEquipment, setConsolidatedEquipment] = useState([]);
  const [loadingConsolidated, setLoadingConsolidated] = useState(true);
  const [consolidatedPage, setConsolidatedPage] = useState(0);
  const [consolidatedTotal, setConsolidatedTotal] = useState(0);
  const classes = tableStyles();
  const history = useHistory();

  async function getMovimentation(id) {
    setLoading(true);

    const response = await axios.get(`${apiUrl}movimentations/${id}`);

    if (response.status === 200) {
      setFormattedChildren(
        filterChildrenByFirstTenElements(response.data.children)
      );
      setFormattedParent(response.data.parent);
      setLoading(false);
      setLoaded(true);
    }
  }

  function filterChildrenByFirstTenElements(array) {
    if (array && array.length > 5) {
      const filteredArray = array.filter((element, index) => index < 5);
      const remainingItems = array.length - 5;
      setChildrenHasMore(
        `There ${remainingItems > 1 ? 'are' : 'is'} ${remainingItems} more ${
          remainingItems > 1 ? 'items' : 'item'
        }...`
      );
      return filteredArray;
    }
    return array;
  }

  function handleUniqueIdClick(_id) {
    history.push(`/breakoutdata/${_id}`);
  }

  React.useEffect(() => {
    if (!open) return;
    getConsolidated(rr);
  }, [consolidatedPage]);

  function getConsolidated(equipment) {
    setLoadingConsolidated(true);
    axios
      .get(`${apiUrl}movimentations`, {
        params: {
          limit: 10,
          page: consolidatedPage + 1,
          assigned_customer_charge_code:
            equipment?.assigned_customer_charge_code?._id ||
            equipment?.assigned_customer_charge_code ||
            'null',
          batch_no: equipment?.batch_no || 'null',
          customer_MM:
            equipment?.customer_MM?._id || equipment?.customer_MM || 'null',
          status: equipment?.status || 'null',
          allocated_charge_code:
            equipment?.allocated_charge_code?._id ||
            equipment?.allocated_charge_code ||
            'null',
          customer: customerSelected._id,
        },
      })
      .then((response) => {
        setConsolidatedEquipment(response.data.docs);
        setConsolidatedTotal(response.data.totalDocs);
        setLoadingConsolidated(false);
      });
  }

  function handleClick() {
    if (!open && !loaded) {
      getMovimentation(rr._id);
      if (rr.groupedItems > 1) {
        getConsolidated(rr);
      }
    }
    setOpen(!open);
  }

  let backgroundColor = undefined;
  if (inventoryCart.findIndex((item) => item?._id === rr._id) > -1)
    backgroundColor = '#eee';
  else if (open) backgroundColor = '#ddd';
  function getChildColor(child) {
    let color = undefined;
    const classificationColor = getColorFromClass(child.classification);
    if (!classificationColor) {
      if (inventoryCart.findIndex((item) => item._id === child._id) > -1)
        color = '#eee';
    } else color = classificationColor;
    return color;
  }

  function handleChangeConsolidatedPage(_, newPage) {
    setConsolidatedPage(newPage);
  }

  return (
    <>
      <TableRow
        className={classes.row}
        style={{
          backgroundColor,
        }}
      >
        {hideButtons && <TableCell />}
        {!hideButtons && <TableCell className={classes.cell}>
          <ButtonsContainer>
            {(rr.children && rr.children.length > 0) ||
            (rr.groupedItems && rr.groupedItems > 1) ||
            rr.parent ? (
              <Tooltip
                title="Breakout Data"
                arrow
                classes={{ tooltip: classes.tooltip }}
              >
                <IconButton
                  size="small"
                  onClick={handleClick}
                  style={{
                    ...styles.editButton,
                    backgroundColor:
                      rr.children && rr.children.length > 0
                        ? 'darkred'
                        : 'white',
                    border:
                      rr.children && rr.children.length > 0
                        ? undefined
                        : '1px solid black',
                    color:
                      rr.children && rr.children.length > 0 ? 'white' : 'black',
                  }}
                >
                  <BarChart fontSize="small" />
                </IconButton>
              </Tooltip>
            ) : (
              <div style={{ width: 36 }} />
            )}

            <Tooltip
              title="History"
              arrow
              classes={{ tooltip: classes.tooltip }}
            >
              <IconButton
                size="small"
                style={{
                  ...styles.editButton,
                  backgroundColor: !(rr.groupedItems && rr.groupedItems > 1)
                    ? '#878787'
                    : '#b3b3b3',
                  color: 'white',
                }}
                onClick={() => history.push(`/history/${rr._id}`)}
                disabled={rr.groupedItems && rr.groupedItems > 1}
              >
                <History fontSize="small" />
              </IconButton>
            </Tooltip>

            <Tooltip
              title="Add to Cart"
              arrow
              classes={{ tooltip: classes.tooltip }}
            >
              <IconButton
                size="small"
                onClick={handleAddToCart(rr)}
                style={{
                  ...styles.editButton,
                  backgroundColor: 'cornflowerblue',
                  color: 'white',
                }}
              >
                <AddShoppingCart fontSize="small" />
              </IconButton>
            </Tooltip>
            {!(rr.groupedItems && rr.groupedItems > 1) && (
              <Tooltip
                title="Edit Data"
                arrow
                classes={{ tooltip: classes.tooltip }}
              >
                <IconButton
                  size="small"
                  style={{
                    ...styles.editButton,
                    backgroundColor: 'mediumPurple',
                    color: 'white',
                  }}
                  onClick={() => history.push(`/equipment/${rr._id}`)}
                >
                  <Edit fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </ButtonsContainer>
        </TableCell>}
        <RowWithLayout equipment={rr} layout={layout} hasCheckbox={hasCheckbox} onChangeCheckbox={onChangeCheckbox} checkboxValue={checkboxValue} />
      </TableRow>
      <TableRow style={{ backgroundColor: '#fefefe' }}>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            border: 'none',
            paddingLeft: '27px',
            backgroundColor: '#FCFEFE',
          }}
          colSpan="20"
        >
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            style={{
              borderLeft: '2px solid rgba(224, 224, 224, 1)',
              borderBottom: '2px solid rgba(224, 224, 224, 1)',
              paddingLeft: '8px',
              position: 'relative',
            }}
          >
            <SubdirectoryArrowRight
              style={{
                marginTop: '17px',
                marginLeft: '-14px',
                position: 'absolute',
                color: 'rgba(224, 224, 224, 0.8)',
              }}
            />
            <Grid item lg={12} md={12} sm={12} xs={12} align="left">
              <Title style={{ marginTop: '1rem' }}>Mother Data</Title>
            </Grid>
            <TableContainer>
              <Table>
                <TableHeaderWithLayout layout={layout} />
                <TableBody>
                  {loading && (
                    <TableRow className={classes.row}>
                      <TableCell colSpan="20" className={classes.cell}>
                        <Spinner parent="Table" />
                      </TableCell>
                    </TableRow>
                  )}
                  {!loading && rr && formattedParent && (
                    <TableRow
                      className={classes.row}
                      style={{
                        backgroundColor:
                          inventoryCart.findIndex(
                            (item) => item._id === formattedParent._id
                          ) > -1
                            ? '#eee'
                            : undefined,
                      }}
                    >
                      <TableCell className={classes.cell}>
                        <ButtonsContainer>
                          <Tooltip
                            title="History"
                            arrow
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <IconButton
                              size="small"
                              style={{
                                ...styles.editButton,
                                backgroundColor: '#878787',
                                color: 'white',
                              }}
                              onClick={() =>
                                history.push(`/history/${formattedParent._id}`)
                              }
                            >
                              <History fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            title="Add to Cart"
                            arrow
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <IconButton
                              size="small"
                              onClick={handleAddToCart(formattedParent)}
                              style={{
                                ...styles.editButton,
                                backgroundColor: 'cornflowerblue',
                                color: 'white',
                              }}
                            >
                              <AddShoppingCart fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            title="Edit Data"
                            arrow
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <IconButton
                              size="small"
                              style={{
                                ...styles.editButton,
                                backgroundColor: 'mediumPurple',
                                color: 'white',
                              }}
                              onClick={() =>
                                history.push(
                                  `/equipment/${formattedParent._id}`
                                )
                              }
                            >
                              <Edit fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </ButtonsContainer>
                      </TableCell>
                      <RowWithLayout
                        equipment={formattedParent}
                        layout={layout}
                      />
                    </TableRow>
                  )}
                  {!loading && !formattedParent && (
                    <TableRow className={classes.row}>
                      <TableCell colSpan="20" style={{ fontSize: '1rem' }}>
                        <div style={{ width: '100%', textAlign: 'center' }}>
                          This item does not have parent
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <SubdirectoryArrowRight
              style={{
                marginTop: '17px',
                marginLeft: '-14px',
                position: 'absolute',
                color: 'rgba(224, 224, 224, 0.8)',
              }}
            />
            <Grid item lg={12} md={12} sm={12} xs={12} align="left">
              <Title style={{ marginTop: '1rem' }}>Children Data</Title>
            </Grid>
            <TableContainer style={{ marginBottom: '2rem' }}>
              <Table>
                <TableHeaderWithLayout layout={layout} />
                <TableBody>
                  {loading && (
                    <TableRow className={classes.row}>
                      <TableCell colSpan="20" className={classes.cell}>
                        <Spinner parent="Table" />
                      </TableCell>
                    </TableRow>
                  )}
                  {!loading &&
                    formattedChildren.map((children) => (
                      <TableRow
                        className={classes.row}
                        style={{
                          backgroundColor: getChildColor(children),
                        }}
                      >
                        <TableCell className={classes.cell}>
                          <ButtonsContainer>
                            <Tooltip
                              title="History"
                              arrow
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <IconButton
                                size="small"
                                style={{
                                  ...styles.editButton,
                                  backgroundColor: '#878787',
                                  color: 'white',
                                }}
                                onClick={() =>
                                  history.push(`/history/${children._id}`)
                                }
                              >
                                <History fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              title="Add to Cart"
                              arrow
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <IconButton
                                size="small"
                                onClick={handleAddToCart(children)}
                                style={{
                                  ...styles.editButton,
                                  backgroundColor: 'cornflowerblue',
                                  color: 'white',
                                }}
                              >
                                <AddShoppingCart fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              title="Edit Data"
                              arrow
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <IconButton
                                size="small"
                                style={{
                                  ...styles.editButton,
                                  backgroundColor: 'mediumPurple',
                                  color: 'white',
                                }}
                                onClick={() =>
                                  history.push(`/equipment/${children._id}`)
                                }
                              >
                                <Edit fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </ButtonsContainer>
                        </TableCell>
                        <RowWithLayout equipment={children} layout={layout} />
                      </TableRow>
                    ))}
                  {!loading && formattedChildren.length === 0 && (
                    <TableRow className={classes.row}>
                      <TableCell colSpan="24" style={{ fontSize: '1rem' }}>
                        <div style={{ width: '100%', textAlign: 'center' }}>
                          This item does not have children
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                  {!loading && childrenHasMore !== '' && (
                    <TableRow className={classes.row}>
                      <TableCell
                        colSpan="20"
                        style={{
                          fontSize: '1rem',
                          borderBottom: 'none',
                          paddingBottom: 0,
                        }}
                      >
                        <div style={{ width: '100%', textAlign: 'center' }}>
                          {childrenHasMore}
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {rr.groupedItems > 1 && rr.groupedItems !== rr.qty ? (
              <>
                <SubdirectoryArrowRight
                  style={{
                    marginTop: '17px',
                    marginLeft: '-14px',
                    position: 'absolute',
                    color: 'rgba(224, 224, 224, 0.8)',
                  }}
                />
                <Grid item lg={12} md={12} sm={12} xs={12} align="left">
                  <Title style={{ marginTop: '3rem' }}>
                    {rr.material_layout !== 'BATCH'
                      ? 'Individual Items'
                      : 'Batches'}
                  </Title>
                </Grid>
                <TableContainer style={{ marginBottom: '2rem' }}>
                  <Table>
                    <TableHeaderWithLayout layout={layout} />
                    <TableBody>
                      {loadingConsolidated && (
                        <TableRow className={classes.row}>
                          <TableCell colSpan="20" className={classes.cell}>
                            <Spinner parent="Table" />
                          </TableCell>
                        </TableRow>
                      )}
                      {!loadingConsolidated &&
                        consolidatedEquipment?.map((item) => (
                          <TableRow
                            className={classes.row}
                            style={{
                              backgroundColor: getChildColor(item),
                            }}
                          >
                            <TableCell className={classes.cell}>
                              <ButtonsContainer>
                                <Tooltip
                                  title="History"
                                  arrow
                                  classes={{ tooltip: classes.tooltip }}
                                >
                                  <IconButton
                                    size="small"
                                    style={{
                                      ...styles.editButton,
                                      backgroundColor: '#878787',
                                      color: 'white',
                                    }}
                                    onClick={() =>
                                      history.push(`/history/${item._id}`)
                                    }
                                  >
                                    <History fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip
                                  title="Add to Cart"
                                  arrow
                                  classes={{ tooltip: classes.tooltip }}
                                >
                                  <IconButton
                                    size="small"
                                    onClick={handleAddToCart(item)}
                                    style={{
                                      ...styles.editButton,
                                      backgroundColor: 'cornflowerblue',
                                      color: 'white',
                                    }}
                                  >
                                    <AddShoppingCart fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip
                                  title="Edit Data"
                                  arrow
                                  classes={{ tooltip: classes.tooltip }}
                                >
                                  <IconButton
                                    size="small"
                                    style={{
                                      ...styles.editButton,
                                      backgroundColor: 'mediumPurple',
                                      color: 'white',
                                    }}
                                    onClick={() =>
                                      history.push(`/equipment/${item._id}`)
                                    }
                                  >
                                    <Edit fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </ButtonsContainer>
                            </TableCell>
                            <RowWithLayout equipment={item} layout={layout} />
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    component="div"
                    count={consolidatedTotal}
                    page={consolidatedPage}
                    rowsPerPage={10}
                    rowsPerPageOptions={[]}
                    onChangePage={handleChangeConsolidatedPage}
                    rowSpan={2}
                    style={{ display: 'flex' }}
                  />
                </TableContainer>
              </>
            ) : (
              <div style={{ marginBottom: '2rem' }}></div>
            )}
            {!loading && (
              <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                <Button
                  onClick={() => history.push(`/breakoutdata/${rr._id}`)}
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ marginBottom: '2rem' }}
                >
                  Full Breakout Data
                </Button>
              </Grid>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

function mapStateToProps(state) {
  return {
    customerSelected: state.customer,
  };
}

export default connect(mapStateToProps)(Row);
