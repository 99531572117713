import {
  UPDATE_RACK_SUCCESS,
  UPDATE_RACK_ERROR,
  FETCH_RACK_SUCCESS,
  FETCH_RACK_ERROR,
  GET_RACK,
  CREATE_RACK,
  UPDATE_RACK,
  updateObjectRack,
  showSpinner,
  hideSpinner,
  hideModal,
} from '../actions/rack';
import { getSLOCS } from '../actions/slocs';
import Toastify from '../../utils/Toastify/index';
import { apiRequest } from '../actions/api';

const URL = 'slocs/rack';

export const createRackFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === CREATE_RACK) {
    dispatch(
      apiRequest(
        'POST',
        `${URL}s`,
        action.payload,
        UPDATE_RACK_SUCCESS,
        UPDATE_RACK_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const updateRackFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === UPDATE_RACK) {
    dispatch(
      apiRequest(
        'PUT',
        URL,
        action.payload,
        UPDATE_RACK_SUCCESS,
        UPDATE_RACK_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const getRackFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_RACK) {
    const { _id } = action.payload;
    dispatch(
      apiRequest('GET-id', URL, { _id }, FETCH_RACK_SUCCESS, FETCH_RACK_ERROR),
    );
    dispatch(showSpinner());
  }
};

export const processRackCollection = ({ dispatch, getState }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_RACK_SUCCESS) {
    dispatch(updateObjectRack(action.payload));
    dispatch(hideSpinner());
  } else if (action.type === UPDATE_RACK_SUCCESS) {
    dispatch(updateObjectRack(action.payload));
    dispatch(hideModal());
    dispatch(hideSpinner());
    // To reload the page
    const { page, limit } = getState().slocs;
    dispatch(getSLOCS({ page, limit }));
    Toastify.addSuccess('Rack saved.');
  } else if (action.type === UPDATE_RACK_ERROR) {
    dispatch(hideSpinner());
    Toastify.addError(action.payload || 'Error to save the rack');
  }
};

export const rackMdl = [
  createRackFlow,
  updateRackFlow,
  getRackFlow,
  processRackCollection,
];
