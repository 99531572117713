import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Typography,
  Button,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Row from '../Row';
import BinTable from '../BinTable';
import { showModal, hideModal } from '../../../../redux/actions/rack';
import EditModal from '../../../Modals/Rack';
import ModalContainer from '../../../../components/ModalContainer';

const schema = [
  { key: 'name', bold: true },
  { key: 'max_weight', postfix: ' lb' },
];

function RackTable({ data = [], parentId }) {
  const dispatch = useDispatch();
  const [modalContentId, setModalContentId] = useState();

  const modalOpen = useSelector((state) => state.ui.rackModal);

  const handleOpenModal = () => {
    dispatch(showModal());
  };
  const handleCloseModal = () => {
    dispatch(hideModal());
  };
  const handleClick = (id) => {
    setModalContentId(id);
    handleOpenModal();
  };

  return (
    <>
      <Box>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h6" gutterBottom component="span">
            Racks
          </Typography>
          <Button
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => handleClick('new')}
          >
            New
          </Button>
        </Box>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Name</TableCell>
              <TableCell>Maximum Weight</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length ? (
              data.map((rack) => (
                <Row
                  key={rack._id}
                  data={rack}
                  schema={schema}
                  childrenKey="bins"
                  ChildrenTable={BinTable}
                  onClick={handleClick}
                />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  There is no racks at this warehouse
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>
      <ModalContainer open={modalOpen} handleClose={handleCloseModal}>
        <EditModal
          id={modalContentId}
          warehouseId={parentId}
          handleClose={handleCloseModal}
        />
      </ModalContainer>
    </>
  );
}

export default RackTable;
