import styled from 'styled-components';

export const Container = styled.div`
  padding: 1rem;
  height: 100%;
`;
export const ModalTitle = styled.div`
  padding-left: 15px;
  padding-bottom: 20px;
  font-size: 1.3rem;
`;
export const ButtonContainer = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
`;
