import Service from '../Service';

export function getVendorsByType(vendor_type) {
  const api = new Service({ route: '/vendors' });

  return api
    .get({ filter: { vendor_type }, listAll: true }, ['vendor_type'])
    .then((response) => response)
    .catch((e) => e);
}
