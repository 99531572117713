export const GET_PROJECT = '[project] GET';
export const UPDATE_PROJECT = '[project] UPDATE';
export const CREATE_PROJECT = '[project] CREATE';
export const DELETE_PROJECT = '[project] DELETE';
export const UPDATE_OBJECT_PROJECT = '[project] UPDATE OBJECT PROJECT';
// UI
export const SHOW_PROJECT_MODAL = '[project] SHOW MODAL';
export const HIDE_PROJECT_MODAL = '[project] HIDE MODAL';
// Spinner
export const UPDATE_PROJECT_SUCCESS = '[project] Update success';
export const UPDATE_PROJECT_ERROR = '[project] Update Error';
export const FETCH_PROJECT_SUCCESS = '[project] Fetch success';
export const FETCH_PROJECT_ERROR = '[project] Fetch Error';
export const SHOW_PROJECT_SPINNER = '[project - ui] show spinner';
export const HIDE_PROJECT_SPINNER = '[project - ui] hide spinner';

export const getProject = (id) => ({
  type: GET_PROJECT,
  payload: { _id: id },
});

export const createProject = (data) => ({
  type: CREATE_PROJECT,
  payload: data,
});

export const updateProject = (data) => ({
  type: UPDATE_PROJECT,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_PROJECT_MODAL,
});

export const hideModal = () => ({
  type: HIDE_PROJECT_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_PROJECT_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_PROJECT_SPINNER,
});

export const updateObjectProject = (data) => ({
  type: UPDATE_OBJECT_PROJECT,
  payload: data,
});
