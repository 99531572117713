import React from 'react';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { connect } from 'react-redux';
import { Add, Delete } from '@material-ui/icons';
import {
  showContractorDataModal,
  hideContractorDataModal,
} from '../../../redux/actions/workOrders';
import Alert from '../../../components/Alert';
import ModalContainer from '../../../components/ModalContainer';
import ContactModal from '../../Modals/ContactData';
import { AddButton } from './styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

function ContactInfo({
  dispatch,
  data,
  setData,
  woContractorDataModal,
  vendors,
}) {
  const {
    revision: { contacts = [] },
  } = data;
  const [editing, setEditing] = React.useState(null);

  function handleCloseContractorModal() {
    dispatch(hideContractorDataModal());
  }

  function handleContractorModal(newData) {
    let newContacts = [...contacts];
    if (editing !== null) {
      newContacts[editing] = newData;
    }
    setData((currentData) => ({
      ...currentData,
      revision: {
        ...currentData.revision,
        contacts:
          editing !== null
            ? newContacts
            : [...currentData.revision.contacts, newData],
      },
    }));
    dispatch(hideContractorDataModal());
  }
  function handleDelete(index) {
    Alert(
      'Are you sure?',
      "You won't be able to revert this!",
      false,
      '',
      'Yes, delete it.'
    ).then(({ confirm }) => {
      if (!confirm) return;
      setData((currentData) => {
        const currentContacts = [...currentData.revision.contacts];
        currentContacts.splice(index, 1);
        return {
          ...currentData,
          revision: { ...currentData.revision, contacts: currentContacts },
        };
      });
      dispatch(hideContractorDataModal());
    });
  }

  return (
    <>
      <ModalContainer
        open={woContractorDataModal}
        handleClose={handleCloseContractorModal}
      >
        <ContactModal
          handleClose={handleCloseContractorModal}
          onSave={handleContractorModal}
          onDelete={() => handleDelete(editing)}
          editData={editing !== null ? contacts[editing] : null}
        />
      </ModalContainer>
      <AddButton
        variant="contained"
        color="primary"
        size="small"
        onClick={() => {
          setEditing(null);
          dispatch(showContractorDataModal());
        }}
      >
        <Add />
        Add Contact Info.
      </AddButton>
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>Vendor</TableCell>
              <TableCell>Point of Contact</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Position</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {contacts.length < 1 && (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No Vendor Added
                </TableCell>
              </TableRow>
            )}
            {contacts.map((contact, index) => (
              <TableRow
                hover
                onDoubleClick={() => {
                  setEditing(index);
                  dispatch(showContractorDataModal());
                }}
              >
                <TableCell>
                  {contact.vendor?.vendor_name ||
                    vendors?.find(({ _id }) => _id === contact.vendor)
                      ?.vendor_name}
                </TableCell>
                <TableCell>{contact.point_of_contact}</TableCell>
                <TableCell>{contact.phone}</TableCell>
                <TableCell>{contact.email}</TableCell>
                <TableCell>{contact.position}</TableCell>
                <TableCell>
                  <IconButton>
                    <Tooltip title="Delete">
                      <IconButton
                        color="secondary"
                        onClick={() => handleDelete(index)}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default connect((state) => ({
  woContractorDataModal: state.ui.woContractorDataModal,
  vendors: state.vendors?.docs || [],
}))(ContactInfo);
