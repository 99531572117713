import styled, { css } from 'styled-components';
import IconButton from '@material-ui/core/IconButton';

export const Container = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
  background-color: #e3e3e3;
  border: 1px solid #a0a0a0;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  ${({ expandable }) => expandable
    && css`
      cursor: pointer;
      opacity: 0.7;
      transition: opacity 500ms;
      &:hover {
        opacity: 1;
      }
    `}
  & > img {
    max-width: 100%;
    max-height: 100%;
  }

  @media (max-width: 800px) {
    width: calc((100% - 20px) / 3);
  }
  @media (max-width: 500px) {
    width: calc((100% - 20px) / 2);
  }
`;

export const RemoveButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`;

export const ExpandedImg = styled.img`
  max-width: 90vw;
  max-height: 90vh;
`;
