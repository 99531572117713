import { UPDATE_OBJECT_COMPANY_CODE } from '../actions/companyCode';

export function companyCodeReducer(companyCode = {}, action) {
  switch (action.type) {
    case UPDATE_OBJECT_COMPANY_CODE:
      return action.payload;

    default:
      return companyCode;
  }
}
