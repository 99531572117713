import api from './Api';

class Service {
  constructor({ route }) {
    this.route = route;
  }

  store(data) {
    return api
      .post(this.route, data)
      .then((response) => (
        {
          data: response.data,
          ok: true,
          status: response.data.status,
        }
      ))
      .catch((error) => ({
        ok: false,
        message:
          error.response?.data?.message
          || error.response?.data?.error
          || error.message
          || 'Route not found',
      }));
  }

  async post(data) {
    try {
      const response = await api
        .post(this.route, data);
      return ({
        data: response.data,
        ok: true,
        status: response.data.status,
      });
    } catch (error) {
      return (
        {
          ok: false,
          message: error.response?.data?.message
            || error.response?.data?.error
            || error.message
            || 'Route not found',
        });
    }
  }

  get(query, params = []) {
    const {
      page,
      limit,
      active,
      listAll,
      orderBy,
      order,
      searchText,
      customer,
    } = query;
    let queryParams = '?';
    queryParams += page ? `page=${page}` : '';
    queryParams += limit ? `&limit=${limit}` : '';
    queryParams += orderBy ? `&orderBy=${orderBy}` : '';
    queryParams += order ? `&order=${order}` : '';
    queryParams += listAll ? `listAll=${listAll}` : '';
    queryParams += searchText ? `&searchText=${searchText}` : '';
    queryParams += customer ? `&customer=${customer}` : '';
    queryParams += active ? `&active=${active}` : '';

    if (query.filter) {
      params.forEach((param) => {
        if (query.filter[param] && query.filter[param] !== '') {
          queryParams += `&${param}=${query.filter[param]}`;
        }
      });
    }

    return api
      .get(`${this.route}${queryParams}`)
      .then((response) => ({
        data: response.data,
        ok: true,
        status: response.data.status,
      }))
      .catch((error) => (
        {
          ok: false,
          message:
            error.response?.data?.message
            || error.response?.data?.error
            || error.message
            || 'Route not found',
        }));
  }

  getById() {
    return api
      .get(this.route)
      .then((response) => ({
        data: response.data,
        ok: true,
        status: response.data.status,
      }))
      .catch((error) => (
        {
          ok: false,
          message:
            error.response?.data?.message
            || error.response?.data?.error
            || error.message
            || 'Route not found',
        }));
  }

  put(id, data) {
    return api
      .put(`${this.route}/${id || ''}`, data)
      .then((response) => (
        {
          data: response.data.docs || response.data,
          ok: true,
          status: response.data.status,
        }))
      .catch((error) => ({
        ok: false,
        message:
          error.response?.data?.message
          || error.response?.data?.error
          || error.message
          || 'Route not found',
      }));
  }

  patch(id, data) {
    return api
      .patch(`${this.route}/${id}`, data)
      .then((response) => ({
        data: response.data.docs || response.data,
        ok: true,
        status: response.data.status,
      }))
      .catch((error) => ({
        ok: false,
        message:
          error.response?.data?.message
          || error.response?.data?.error
          || error.message
          || 'Route not found',
      }));
  }

  delete(id, options) {
    return api
      .delete(`${this.route}/${id}`, options)
      .then((response) => ({
        data: response.data.docs || response.data,
        ok: true,
        status: response.data.status,
      }))
      .catch((error) => ({
        ok: false,
        message:
          error.response?.data?.message
          || error.response?.data?.error
          || error.message
          || 'Route not found',
      }));
  }
}

export default Service;
