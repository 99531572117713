import React from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import Checkbox from '@material-ui/core/Checkbox';

import MaterialMasterTooltip from '../MaterialMasterTooltip';
import { TableCell } from './styles';
import { tableStyles } from '../../styles/tableStyles';
import { dataExtractors } from '../../utils/StaticData/EquipmentInputs/result_data';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

function RowWithLayout({ equipment, layout, hasCheckbox, onChangeCheckbox, checkboxValue }) {
  const classes = tableStyles();

  function extractData({ field, type, function_id }) {
    if (type === 'Function') return dataExtractors[function_id](equipment);
    if (field === 'qty')
      return !equipment.count ? equipment.qty || 1 : equipment.count;
    if (field === 'footage')
      return equipment?.footage?.toFixed
        ? equipment?.footage?.toFixed(3)
        : equipment?.footage || '--';
    if (field === 'individual_item_id')
      return equipment.groupedItems && equipment.groupedItems > 1
        ? '--'
        : equipment.individual_item_id;
    const path = field.split('.');
    let currentLevel = { ...equipment };
    path.forEach((fieldName) => {
      if (currentLevel === '--') return;
      if (currentLevel && currentLevel[fieldName])
        currentLevel = currentLevel[fieldName];
      else {
        currentLevel = '--';
      }
    });

    if (type === 'Currency' && currentLevel !== '--')
      return formatter.format(currentLevel);
    if (type === 'Date' && currentLevel !== '--')
      return format(new Date(currentLevel), 'mm-dd-yyyy');
    return currentLevel;
  }

  return layout.map((field, index) => {
    const data = extractData(field);
    const isMaterialMaster = String(field.field).includes('customer_MM');
    let Container = ({ children }) => <div>{children}</div>;

    if (isMaterialMaster)
      Container = ({ children }) => (
        <>
          <MaterialMasterTooltip materialMaster={equipment.customer_MM}>
            {children}
          </MaterialMasterTooltip>
        </>
      );
    else if (field.route)
      Container = ({ children }) => {
        const param = extractData(field.param);
        return param !== '--' ? (
          <Link to={`${field.route}/${param}`}>{children}</Link>
        ) : (
          {children}
        );
      };
    return (
      <TableCell
        isStatus={field.field === 'status'}
        data={data}
        className={classes.cell}
        style={{
          position: 'relative',
        }}
      >
        {hasCheckbox && index === 0 && <Checkbox
          color="primary"
          onChange={(event) => onChangeCheckbox(equipment, event)}
          checked={checkboxValue(equipment._id)}
          style={{
            position: 'absolute',
            left: -30,
            top: 5,
          }}
        />}
        <Container>
          {data}
        </Container>
      </TableCell>
    );
  });
}

export default RowWithLayout;
