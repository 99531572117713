import React from 'react';
import TextField from '@material-ui/core/TextField';
import { InputContainer } from '../../styles';

function DefaultField({
  name,
  label,
  onChange,
  value,
  children,
  select,
  disabled,
}) {
  return (
    <InputContainer>
      <TextField
        onChange={onChange}
        size="small"
        name={name}
        label={label}
        type="text"
        variant="outlined"
        value={value}
        select={select}
        fullWidth
        disabled={disabled}
      >
        {children}
      </TextField>
    </InputContainer>
  );
}

export default DefaultField;
