import {
  GET_PROFILE,
  UPDATE_PROFILE,
  UPDATE_OBJECT_PROFILE,
} from '../actions/profile';

export function profileReducer(profile = {}, action) {
  switch (action.type) {
    case GET_PROFILE:
      return action.payload;
    case UPDATE_PROFILE:
      return action.payload;
    case UPDATE_OBJECT_PROFILE:
      return action.payload;

    default:
      return profile;
  }
}
