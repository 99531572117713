import React, { useCallback } from "react";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Add,
  Close as CloseIcon,
  AttachFile as AttachFileIcon,
} from "@material-ui/icons";
import Alert from "../../../components/Alert";
import {
  showPhotoDataModal,
  hidePhotoDataModal,
} from "../../../redux/actions/workOrders";
import uploadFile from "../../../services/Inspection/uploadFile";
import { download } from "../../../services/Download";
import { deleteEntry } from "../../../redux/actions/vault";
import ModalContainer from "../../../components/ModalContainer";
import PhotoModal from "../../Modals/PhotoData";
import { AddButton, Thumbnail } from "./styles";
import { apiUrl } from "../../../config/consts";

function Photos({ data, setData }) {
  const dispatch = useDispatch();
  const woPhotoModal = useSelector((state) => state.ui.woPhotoModal);
  const loggedUser = useSelector((state) => state.loggedUser?._id);
  const { workOrderId } = useParams();
  const { photos = [], equipment_service = [] } = data;
  const [editing, setEditing] = React.useState(null);

  function handleClosePhotoModal() {
    dispatch(hidePhotoDataModal());
  }

  const rejectedPhotos = useCallback(() => {
    const equipmentWithPhoto = equipment_service.filter(({ photo }) => !!photo);
    return equipmentWithPhoto.map(({ photo, note }) => ({
      description: note,
      file: photo,
    }));
  }, [equipment_service])();

  function handlePhotoModal(newData, callback) {
    if (!newData.file) return;
    if (newData.file.invalid) return;
    Alert("Are you sure?", "Do you really want to upload this photo?").then(
      ({ confirm }) => {
        if (confirm) {
          const { file, ...data } = {
            ...newData,
            docType: "photos",
            workOrderId,
            user: loggedUser,
          };
          uploadFile(file, data)
            .then(({ data: responseData, ok }) => {
              if (!ok) return;
              setData((currentData) => ({
                ...currentData,
                photos: [
                  ...currentData.photos,
                  { description: newData.description, file: responseData },
                ],
              }));
              handleClosePhotoModal();
            })
            .finally(() => {
              callback();
            });
        } else {
          callback();
        }
      }
    );
  }
  function handleDelete(index) {
    return function () {
      Alert(
        "Are you sure?",
        "You won't be able to revert this!",
        false,
        "",
        "Yes, delete it."
      ).then(({ confirm }) => {
        if (!confirm) return;
        dispatch(deleteEntry(photos[index].file._id));

        setData((currentData) => {
          const currentDocuments = [...currentData.photos];
          currentDocuments.splice(index, 1);
          return { ...currentData, photos: currentDocuments };
        });
      });
    };
  }

  function handleDeleteModal() {
    handleDelete(editing)();
  }

  const handleDownload = (index) => {
    return function () {
      download(`vault/download/${photos[index].file?.file?.filename}`);
    };
  };

  return (
    <>
      <ModalContainer open={woPhotoModal} handleClose={handleClosePhotoModal}>
        <PhotoModal
          handleClose={handleClosePhotoModal}
          onSave={handlePhotoModal}
          onDelete={handleDeleteModal}
          editData={editing !== null ? photos[editing] : null}
        />
      </ModalContainer>
      <AddButton
        variant="contained"
        color="primary"
        size="small"
        onClick={() => {
          setEditing(null);
          dispatch(showPhotoDataModal());
        }}
      >
        <Add />
        Add Picture
      </AddButton>
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>Picture Preview</TableCell>
              <TableCell>Picture Name</TableCell>
              <TableCell colSpan={2}>Picture Description</TableCell>
              <TableCell>Attached Picture</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {[...photos, ...rejectedPhotos].length < 1 && (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No Picture Added
                </TableCell>
              </TableRow>
            )}
            {[...photos, ...rejectedPhotos].map((photo, index) => (
              <TableRow hover>
                <TableCell>
                  <Thumbnail>
                    <img
                      alt={photo.description}
                      src={`${apiUrl}vault/download/${photo.file?.file?.filename}`}
                    />
                  </Thumbnail>
                </TableCell>
                <TableCell>{photo.file.name}</TableCell>
                <TableCell colSpan={2} style={{ whiteSpace: "pre-wrap" }}>
                  {photo.description}
                </TableCell>
                <TableCell>
                  <IconButton onClick={handleDownload(index)}>
                    <AttachFileIcon />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <Tooltip title="Remove">
                    <IconButton color="secondary" onClick={handleDelete(index)}>
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default Photos;
