import {
  UPDATE_REPORT_FIELD_SUCCESS,
  UPDATE_REPORT_FIELD_ERROR,
  FETCH_REPORT_FIELD_SUCCESS,
  FETCH_REPORT_FIELD_ERROR,
  GET_REPORT_FIELD,
  CREATE_REPORT_FIELD,
  UPDATE_REPORT_FIELD,
  updateObjectReportField,
  showSpinner,
  hideSpinner,
  hideModal,
} from '../actions/reportField';
import { getReportFields } from '../actions/reportFields';
import Toastify from '../../utils/Toastify/index';
import { apiRequest } from '../actions/api';

const URL = 'report-fields';

export const createReportFieldFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === CREATE_REPORT_FIELD) {
    dispatch(
      apiRequest(
        'POST',
        URL,
        action.payload,
        UPDATE_REPORT_FIELD_SUCCESS,
        UPDATE_REPORT_FIELD_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const updateReportFieldFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === UPDATE_REPORT_FIELD) {
    dispatch(
      apiRequest(
        'PUT',
        URL,
        action.payload,
        UPDATE_REPORT_FIELD_SUCCESS,
        UPDATE_REPORT_FIELD_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const getReportFieldFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_REPORT_FIELD) {
    const { _id } = action.payload;
    dispatch(
      apiRequest(
        'GET-id',
        URL,
        { _id },
        FETCH_REPORT_FIELD_SUCCESS,
        FETCH_REPORT_FIELD_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const processReportFieldCollection = ({ dispatch, getState }) => (
  next,
) => (action) => {
  next(action);
  if (action.type === FETCH_REPORT_FIELD_SUCCESS) {
    dispatch(updateObjectReportField(action.payload));
    dispatch(hideSpinner());
  } else if (action.type === UPDATE_REPORT_FIELD_SUCCESS) {
    dispatch(updateObjectReportField(action.payload));
    dispatch(hideModal());
    dispatch(hideSpinner());
    // To reload the page
    const { page, limit } = getState().reportFields;
    dispatch(
      getReportFields({
        page,
        limit,
        filter: { user: getState().loggedUser._id },
      }),
    );
    Toastify.addSuccess('Report template  saved.');
  } else if (action.type === UPDATE_REPORT_FIELD_ERROR) {
    dispatch(hideSpinner());
    Toastify.addError(action.payload || 'Error to save the report template');
  }
};

export const reportFieldMdl = [
  createReportFieldFlow,
  updateReportFieldFlow,
  getReportFieldFlow,
  processReportFieldCollection,
];
