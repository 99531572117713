export const GET_MOVIMENTATION = '[movimentation] GET';
export const GET_BUILD_OF_MATERIAL = '[movimentation] GET BOM';
export const UPDATE_MOVIMENTATION = '[movimentation] UPDATE';
export const CREATE_MOVIMENTATION = '[movimentation] CREATE';
export const DELETE_MOVIMENTATION = '[movimentation] DELETE';
export const UPDATE_OBJECT_MOVIMENTATION = '[movimentation] UPDATE OBJECT MOVIMENTATION';
export const UPDATE_OBJECT_BUILD_OF_MATERIAL = '[movimentation] UPDATE OBJECT BOM';
// Spinner
export const UPDATE_MOVIMENTATION_SUCCESS = '[movimentation] Update success';
export const UPDATE_MOVIMENTATION_ERROR = '[movimentation] Update Error';
export const FETCH_MOVIMENTATION_SUCCESS = '[movimentation] Fetch success';
export const FETCH_BUILD_OF_MATERIAL_SUCCESS = '[movimentation] Fetch BOM success';
export const FETCH_MOVIMENTATION_ERROR = '[movimentation] Fetch Error';
export const SHOW_MOVIMENTATION_SPINNER = '[movimentation - ui] show spinner';
export const HIDE_MOVIMENTATION_SPINNER = '[movimentation - ui] hide spinner';
// UI
export const SHOW_MOVIMENTATION_MODAL = '[movimentation] SHOW MODAL';
export const HIDE_MOVIMENTATION_MODAL = '[movimentation] HIDE MODAL';

export const getMovimentation = (id) => ({
  type: GET_MOVIMENTATION,
  payload: { _id: id },
});
export const getBuildOfMaterial = (parents) => ({
  type: GET_BUILD_OF_MATERIAL,
  payload: { parents },
});

export const createMovimentation = (data) => ({
  type: CREATE_MOVIMENTATION,
  payload: data,
});

export const updateMovimentation = (data) => ({
  type: UPDATE_MOVIMENTATION,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_MOVIMENTATION_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_MOVIMENTATION_SPINNER,
});

export const updateObjectMovimentation = (data) => ({
  type: UPDATE_OBJECT_MOVIMENTATION,
  payload: data,
});
export const updateObjectBuildOfMaterial = (data) => ({
  type: UPDATE_OBJECT_BUILD_OF_MATERIAL,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_MOVIMENTATION_MODAL,
});

export const hideModal = () => ({
  type: HIDE_MOVIMENTATION_MODAL,
});
