import React from 'react';
import formatDate from 'date-fns/format';
import { useHistory } from 'react-router-dom';
import { Tooltip, useMediaQuery, useTheme } from '@material-ui/core';
import {
  FaRegFile as GenericIcon,
  FaRegFilePdf as PDFIcon,
  FaRegFileImage as ImageIcon,
  FaRegFileExcel as ExcelIcon,
  FaRegFilePowerpoint as PowerPointIcon,
  FaRegFileVideo as VideoIcon,
  FaRegFileWord as WordIcon,
  FaRegFileAudio as AudioIcon,
  FaRegFileArchive as ZipIcon,
  FaRegFolder as DirIcon,
} from 'react-icons/fa';

import { download } from '../../services/Download';

function Icon({ entry: { isDirectory, file } }) {
  if (isDirectory) return <DirIcon />;

  if (file?.mimetype?.includes('image')) return <ImageIcon />;
  if (file?.mimetype?.includes('pdf')) return <PDFIcon />;
  if (file?.mimetype?.includes('video')) return <VideoIcon />;
  if (file?.mimetype?.includes('audio')) return <AudioIcon />;
  if (
    file?.mimetype?.includes('spreadsheet') ||
    file?.mimetype?.includes('excel')
  )
    return <ExcelIcon />;
  if (
    file?.mimetype?.includes('document.text') ||
    file?.mimetype?.includes('msword')
  )
    return <WordIcon />;
  if (
    file?.mimetype?.includes('presentation') ||
    file?.mimetype?.includes('powerpoint')
  )
    return <PowerPointIcon />;
  if (file?.mimetype?.includes('compressed') || file?.mimetype?.includes('zip'))
    return <ZipIcon />;

  return <GenericIcon />;
}

function VaultEntry({
  entry,
  ContainerComponent = 'div',
  ValueComponent = 'span',
  selected,
  onToggleSelection,
}) {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();
  function handleAccessEntry() {
    if (entry.isDirectory) return history.push(`/vault/${entry._id}`);
    download(`vault/download/${entry?.file?.filename}`);
  }

  function bytesToSize(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${Math.round(bytes / 1024 ** i)} ${sizes[i]}`;
  }

  function handleSelection() {
    if (entry.isDirectory) return;
    onToggleSelection(entry._id);
  }

  return (
    <Tooltip title={entry?.name}>
      <ContainerComponent
        style={{
          ...preventSelectStyle,
          backgroundColor: selected ? '#cfd8dc' : undefined,
        }}
        className="vaultEntry-container"
        onDoubleClick={handleAccessEntry}
        onClick={smallScreen ? handleAccessEntry : handleSelection}
      >
        <ValueComponent className="vaultEntry-icon">
          <Icon entry={entry} />
        </ValueComponent>
        <ValueComponent className="vaultEntry-name">
          {entry.name}
        </ValueComponent>
        <ValueComponent className="vaultEntry-creator">
          {entry.createdBy?.name ? entry.createdBy?.name : '---'}
        </ValueComponent>
        <ValueComponent className="vaultEntry-modifiedAt">
          {entry.updatedAt
            ? formatDate(new Date(entry.updatedAt), 'MM-dd-yyyy HH:mm')
            : '---'}
        </ValueComponent>
        <ValueComponent className="vaultEntry-size">
          {entry.file?.size ? bytesToSize(entry.file?.size) : '--'}
        </ValueComponent>
      </ContainerComponent>
    </Tooltip>
  );
}

const preventSelectStyle = {
  WebkitTouchCallout: 'none',
  WebkitUserSelect: 'none',
  KhtmlUserSelect: 'none',
  MozUserSelect: 'none',
  MsUserSelect: 'none',
  userSelect: 'none',
};

export default VaultEntry;
