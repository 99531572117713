import {
  UPDATE_SERVICE_CATEGORY_SUCCESS,
  UPDATE_SERVICE_CATEGORY_ERROR,
  FETCH_SERVICE_CATEGORY_SUCCESS,
  FETCH_SERVICE_CATEGORY_ERROR,
  GET_SERVICE_CATEGORY,
  CREATE_SERVICE_CATEGORY,
  UPDATE_SERVICE_CATEGORY,
  updateObjectServiceCategory,
  showSpinner,
  hideSpinner,
  hideModal,
} from '../actions/serviceCategory';
import { getServiceCategories } from '../actions/serviceCategories';
import Toastify from '../../utils/Toastify/index';
import { apiRequest } from '../actions/api';

const URL = 'service_categories';

export const createServiceCategoryFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === CREATE_SERVICE_CATEGORY) {
    dispatch(apiRequest('POST', URL, action.payload, UPDATE_SERVICE_CATEGORY_SUCCESS, UPDATE_SERVICE_CATEGORY_ERROR));
    dispatch(showSpinner());
  }
};

export const updateServiceCategoryFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === UPDATE_SERVICE_CATEGORY) {
    dispatch(apiRequest('PUT', URL, action.payload, UPDATE_SERVICE_CATEGORY_SUCCESS, UPDATE_SERVICE_CATEGORY_ERROR));
    dispatch(showSpinner());
  }
};

export const getServiceCategoryFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_SERVICE_CATEGORY) {
    const { _id } = action.payload;
    dispatch(apiRequest('GET-id', URL, { _id }, FETCH_SERVICE_CATEGORY_SUCCESS, FETCH_SERVICE_CATEGORY_ERROR));
    dispatch(showSpinner());
  }
};

export const processServiceCategoryCollection = ({ dispatch, getState }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_SERVICE_CATEGORY_SUCCESS) {
    dispatch(updateObjectServiceCategory(action.payload));
    dispatch(hideSpinner());
  } else if (action.type === UPDATE_SERVICE_CATEGORY_SUCCESS) {
    dispatch(updateObjectServiceCategory(action.payload));
    dispatch(hideModal());
    dispatch(hideSpinner());
    const { page, limit } = getState().serviceCategories;
    dispatch(getServiceCategories({ page, limit }));
    Toastify.addSuccess('Service Category saved.');
  } else if (action.type === UPDATE_SERVICE_CATEGORY_ERROR) {
    dispatch(hideSpinner());
    Toastify.addError(action.payload || 'Error to save the Service Category');
  }
};

export const serviceCategoryMdl = [
  createServiceCategoryFlow,
  updateServiceCategoryFlow,
  getServiceCategoryFlow,
  processServiceCategoryCollection,
];
