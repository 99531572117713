export const GET_ACTIVITIES = '[activities] GET';
export const FETCH_ACTIVITIES_SUCCESS = '[activities] Fetch success';
export const FETCH_ACTIVITIES_ERROR = '[activities] Fetch Error';
export const UPDATE_ACTIVITIES = '[activities] Update';
export const SHOW_ACTIVITIES_SPINNER = '[activities - ui] show spinner';
export const HIDE_ACTIVITIES_SPINNER = '[activities - ui] hide spinner';

export const getActivities = (filter) => ({
  type: GET_ACTIVITIES,
  payload: filter,
});

export const updateActivities = (data) => ({
  type: UPDATE_ACTIVITIES,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_ACTIVITIES_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_ACTIVITIES_SPINNER,
});
