import React from 'react';
import { Divider } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { ImOnedrive as VaultIcon } from 'react-icons/im';
import { FaHandshake } from 'react-icons/fa';
import { RiFileDamageFill } from 'react-icons/ri';
import { IoIosPeople } from 'react-icons/io';
import {
  Assessment,
  Assignment,
  Build,
  Business,
  CloudUpload,
  ErrorOutlineRounded,
  GroupWorkOutlined,
  GroupRounded,
  History,
  Home,
  RecentActors,
  VerifiedUser,
  AccountBalanceWallet,
  BusinessCenter as BusinessUnitIcon,
  LocalDrink as WellIcon,
  AllInbox,
  MenuBook,
  LocationOn,
  Place,
  Work,
  Ballot,
  Settings,
  NotificationsActive,
  SyncProblem as CycleCountingIcon,
} from '@material-ui/icons';

function CompanyCodeIcon() {
  return <span style={{ fontWeight: 700 }}>CC</span>;
}
function MaterialOwnerIcon() {
  return <span style={{ fontWeight: 700 }}>MO</span>;
}

export const navigation = [
  {
    path: '/equipments',
    label: 'Inventory',
    icon: <Home />,
  },
  {
    path: '/jobs',
    label: 'Jobs',
    icon: <Assignment />,
  },
  {
    path: '/maintenances',
    label: 'Maintenance',
    icon: <Build />,
  },
  {
    path: '/history',
    label: 'History',
    icon: <History />,
  },
  {
    path: '/cycle_counting',
    label: 'Cycle Counting',
    icon: <CycleCountingIcon />,
  },
  {
    path: '/vpas',
    label: 'V-PAS',
    icon: <ErrorOutlineRounded />,
  },
  {
    path: '/vault',
    label: 'Vault',
    icon: <VaultIcon className="react-icons" />,
  },
  {
    path: '/variance_report',
    label: 'Variance Report',
    icon: <Assessment />,
  },
  {
    path: '/equipment_import',
    label: 'Upload Inventory',
    icon: <CloudUpload />,
  },
  {
    component: <Divider />,
  },
  {
    label: 'Settings',
    icon: <Settings />,
    children: [
      {
        label: 'System Access',
        icon: <RecentActors />,
        children: [
          {
            path: '/users',
            label: 'Users',
            icon: <GroupRounded />,
          },
          {
            path: '/roles',
            label: 'Roles',
            icon: <VerifiedUser />,
          },
          {
            path: '/mrp-notification-setup',
            label: 'MRP Notifications Setup',
            icon: <NotificationsActive />,
          },
        ],
      },
      {
        label: 'Companies Settings',
        icon: <Business />,
        children: [
          {
            path: '/business_units',
            label: 'Business Units',
            icon: <BusinessUnitIcon />,
          },
          {
            path: '/original_charge_code',
            label: 'Charge Codes',
            icon: <AccountBalanceWallet />,
          },
          {
            path: '/company_codes',
            label: 'Company Codes',
            icon: <CompanyCodeIcon />,
          },
          {
            path: '/customers',
            label: 'Customers',
            icon: <IoIosPeople className="react-icons" />,
          },
          {
            path: '/owners',
            label: 'Material Owners',
            icon: <MaterialOwnerIcon />,
          },
          {
            path: '/projects',
            label: 'Projects',
            icon: <GroupWorkOutlined />,
          },
          {
            path: '/vendors',
            label: 'Vendors',
            icon: <FaHandshake className="react-icons" />,
          },
        ],
      },
      {
        label: 'Inventory Control',
        icon: <AllInbox />,
        children: [
          {
            path: '/material_master',
            label: 'Material Master',
            icon: <MenuBook />,
          },
        ],
      },
      {
        label: 'Places',
        icon: <Place />,
        children: [
          {
            path: '/wells',
            label: 'Wells',
            icon: <WellIcon />,
          },
          {
            path: '/sloc',
            label: 'SLOCS',
            icon: <LocationOn />,
          },
        ],
      },
      {
        label: 'Jobs Settings',
        icon: <Work />,
        children: [
          {
            path: '/damage_descriptions',
            label: 'Damage Description',
            icon: <RiFileDamageFill className="react-icons" />,
          },
          {
            path: '/service_categories',
            label: 'Service Categories',
            icon: <Ballot />,
          },
          {
            path: '/maintenance_categories',
            label: 'Maintenance Categories',
            icon: <Build />,
          },
          {
            path: '/activities',
            label: 'Activities',
            icon: <Icon>task_alt</Icon>,
          },
        ],
      },
    ],
  },
];
