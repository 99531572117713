import React, { useEffect, useState } from 'react';
import { Button, Grid, MenuItem, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { connect } from 'react-redux';
import Spinner from '../../../components/Spinner/index';
import { ButtonContainer, Container, InputContent, ModalTitle } from './styles';
import { getMaintenance } from '../../../utils/Maintenance';

const styles = {
  formButton: {
    margin: 2,
  },
};

const effectedDateList = [
  { label: 'Date of Manufacture', value: 'manufacture_date' },
  { label: 'Expiration Date', value: 'expiration_date' },
  { label: 'In Service Date', value: 'in_service_date' },
  { label: 'Date Last Reconciled', value: 'last_reconcile_date' },
  { label: 'Date Last Tested', value: 'date_last_tested' },
  { label: 'Date Last Rotated', value: 'date_last_rotated' },
];

const Maintenance = ({
  index,
  handleClose,
  maintenanceSpinner,
  maintenances = [],
  updateMaintenance,
  readOnly,
  specLibrary,
  maintenanceCategories,
}) => {
  const [data, setData] = useState({
    maintenance: null,
    inspectionCriteria: '',
    effectedDate: '',
    interval: '',
    notificationTrigger: '',
    notificationFrequency: '',
  });
  const {
    maintenance,
    inspectionCriteria,
    effectedDate,
    interval,
    notificationTrigger,
    notificationFrequency,
  } = data;
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    if (index === null) return;
    setData(maintenances[index]);
  }, [index]);

  const setDataField = (field, value) => {
    setData((currentData) => ({ ...currentData, [field]: value }));
  };

  const handleEdit = (e) => {
    const { name, value } = e.target;
    setDataField(name, value);
  };

  const handleSave = () => {
    const dataToSave = [...maintenances];
    if (index !== null) dataToSave[index] = data;
    else dataToSave.push(data);
    updateMaintenance(dataToSave);
    handleClose();
  };

  const specFiles = Array.isArray(specLibrary)
    ? specLibrary.filter(({ isDirectory, hidden }) => !isDirectory && !hidden)
    : [];

  function getAllMaintenances() {
    return (
      maintenanceCategories.reduce(
        (mnts, category) => [...mnts, ...category.maintenances],
        []
      ) || []
    );
  }

  function handleEditMaintenance(_, value) {
    if (!value?._id) return setDataField('maintenance', null);
    const {
      _id,
      defaultInspectionCriteria,
      defaultEffectedDate,
      defaultInterval,
      defaultNotificationTrigger,
      defaultNotificationFrequency,
    } = value;
    setData((currentData) => ({
      ...currentData,
      maintenance: _id,
      inspectionCriteria: defaultInspectionCriteria,
      effectedDate: defaultEffectedDate,
      interval: defaultInterval,
      notificationTrigger: defaultNotificationTrigger,
      notificationFrequency: defaultNotificationFrequency,
    }));
  }

  const getSpec = () =>
    specFiles.find(({ _id }) => _id === inspectionCriteria) || null;

  return (
    <>
      {maintenanceSpinner && <Spinner overlay />}
      <Container style={{ opacity: maintenanceSpinner ? 0.5 : 1 }}>
        <ModalTitle>Maintenance</ModalTitle>
        <Grid container style={{ padding: '0px 10px 10px 10px' }}>
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <InputContent>
              <Autocomplete
                options={maintenanceCategories}
                getOptionLabel={({ name }) => name}
                onChange={(_, value) => {
                  setSelectedCategory(value);
                  setDataField('maintenance', '');
                }}
                value={selectedCategory}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    label="Maintenance Category"
                    type="text"
                    variant="outlined"
                    fullWidth
                    disabled={readOnly}
                  />
                )}
              />
            </InputContent>
          </Grid>
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <InputContent>
              <Autocomplete
                options={selectedCategory?.maintenances || getAllMaintenances()}
                getOptionLabel={({ name }) => name}
                onChange={handleEditMaintenance}
                value={getMaintenance(maintenance, maintenanceCategories)}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    label="Maintenance"
                    type="text"
                    variant="outlined"
                    fullWidth
                    disabled={readOnly}
                  />
                )}
              />
            </InputContent>
          </Grid>
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <InputContent>
              <Autocomplete
                getOptionSelected={({ _id }, value) => {
                  return _id === value?._id;
                }}
                value={getSpec()}
                onChange={(_, value) =>
                  setDataField('inspectionCriteria', value?._id)
                }
                fullWidth
                options={specFiles}
                autoHighlight
                getOptionLabel={({ name }) => name}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    label="Inspection Criteria"
                    required
                    variant="outlined"
                  />
                )}
              />
            </InputContent>
          </Grid>
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <InputContent>
              <TextField
                label="Effected Date"
                required
                type="text"
                variant="outlined"
                value={effectedDate}
                name="effectedDate"
                onChange={handleEdit}
                fullWidth
                disabled={readOnly}
                select
              >
                {effectedDateList.map(({ label, value }) => (
                  <MenuItem value={value}>{label}</MenuItem>
                ))}
              </TextField>
            </InputContent>
          </Grid>
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <InputContent>
              <TextField
                label="Maintenance Interval (by days)"
                type="number"
                variant="outlined"
                value={interval}
                name="interval"
                onChange={handleEdit}
                fullWidth
                disabled={readOnly}
              />
            </InputContent>
          </Grid>
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <InputContent>
              <TextField
                label="Notification Trigger (days left)"
                type="number"
                variant="outlined"
                value={notificationTrigger}
                name="notificationTrigger"
                onChange={handleEdit}
                fullWidth
                disabled={readOnly}
              />
            </InputContent>
          </Grid>
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <InputContent>
              <TextField
                label="Maintenance Frequency (each X days)"
                type="number"
                variant="outlined"
                value={notificationFrequency}
                name="notificationFrequency"
                onChange={handleEdit}
                fullWidth
                disabled={readOnly}
              />
            </InputContent>
          </Grid>
        </Grid>
        <ButtonContainer>
          {!readOnly && (
            <Button
              color="primary"
              onClick={handleSave}
              variant="contained"
              style={styles.formButton}
            >
              {index !== null ? 'Save' : 'Add'}
            </Button>
          )}
          <Button
            color="secondary"
            onClick={handleClose}
            variant="contained"
            style={styles.formButton}
          >
            {!readOnly ? 'Cancel' : 'Close'}
          </Button>
        </ButtonContainer>
      </Container>
    </>
  );
};

export default connect((state) => ({
  maintenanceSpinner: state.ui.maintenanceLoading,
  specLibrary: state.specLibrary,
  maintenanceCategories:
    state.maintenanceCategories?.docs || state.maintenanceCategories || [],
}))(Maintenance);
