import { apiRequest } from '../actions/api';
import {
  FETCH_MAINTENANCE_CATEGORIES_SUCCESS,
  FETCH_MAINTENANCE_CATEGORIES_ERROR,
  GET_MAINTENANCE_CATEGORIES,
  updateMaintenanceCategories,
  showSpinner,
  hideSpinner,
} from '../actions/maintenanceCategories';

const URL = 'maintenance_categories';

export const getMaintenanceCategoriesFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_MAINTENANCE_CATEGORIES) {
    dispatch(
      apiRequest(
        'GET',
        URL,
        action.payload,
        FETCH_MAINTENANCE_CATEGORIES_SUCCESS,
        FETCH_MAINTENANCE_CATEGORIES_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const processMaintenanceCategoriesCollection = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_MAINTENANCE_CATEGORIES_SUCCESS) {
    dispatch(updateMaintenanceCategories(action.payload));
    dispatch(hideSpinner());
  }
};

export const maintenanceCategoriesMdl = [
  getMaintenanceCategoriesFlow,
  processMaintenanceCategoriesCollection,
];
