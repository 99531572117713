import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionActions';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { KeyboardDatePicker } from '@material-ui/pickers';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CUSTOMER_KEY } from '../../config/consts';
import { getHistory } from '../../redux/actions/history';
import {
  clearHistoryFilter,
  setHistoryFilter,
} from '../../redux/actions/historyFilter';
import { getOriginalCharge } from '../../redux/actions/originalCharges';
import { getProjects } from '../../redux/actions/projects';
import { getSLOCS } from '../../redux/actions/slocs';
import { Container, InputContainer, TitleContent } from './styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 600,
    position: 'relative',
    width: '100%',
  },
  button: {
    fontSize: 16,
    color: '#737373',
  },
  listItem: {
    width: '100%',
  },
  listAccordion: {
    padding: '0px 0px 0px 25px',
    height: 39,
    marginTop: -25,
  },
  questionLabel: {
    paddingTop: 2,
    paddingBottom: 2,
    fontSize: '0.8rem',
  },
  listItemQuestion: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderTop: '1px solid #ccc',
  },
  listItemPadding: {
    paddingTop: 4,
    paddingBottom: 4,
  },
  expansionHeader: {},
  questionLabelEmpty: {
    color: '#808080',
    fontSize: '0.8rem',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    fontSize: 10,
    height: 20,
    '& > span': { paddingLeft: 5, paddingRight: 5 },
  },
  formControl: {
    width: '100%',
    padding: 10,
    paddingTop: 0,
    '& > label': { paddingLeft: 10 },
  },
}));

const HistoryFilter = ({
  dispatch,
  loggedUser,
  movimentation,
  complete,
  SLOCs,
  projects,
  chargeCodes,
  historyFilter,
}) => {
  const { customer } = useParams();
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    dispatch(getSLOCS({ filter: { customer, listAll: true } }));
  }, []);
  useEffect(() => {
    dispatch(getProjects({ filter: { customer, listAll: true } }));
  }, []);
  useEffect(() => {
    dispatch(getOriginalCharge({ filter: { customer, listAll: true } }));
  }, []);

  const {
    event,
    period_end,
    period_start,
    facilityReferenceID,
    SLOC,
    batch,
    project,
    chargeCode,
    transactionId,
    author,
  } = historyFilter;

  function handleEdit(e) {
    const { name, value } = e.target;
    dispatch(setHistoryFilter({ [name]: value }));
  }

  function handleSearch(e) {
    e.preventDefault();
    dispatch(
      getHistory({
        page: 1,
        limit: 50,
        filter: {
          ...historyFilter,
          movimentation,
          ...(complete
            ? { inventory: localStorage.getItem(CUSTOMER_KEY) }
            : {}),
        },
      })
    );
  }

  function clearFilter() {
    dispatch(clearHistoryFilter());
    dispatch(
      getHistory({
        page: 1,
        limit: 50,
        filter: { movimentation },
      })
    );
  }
  function handleDate(name) {
    function handler(value) {
      dispatch(setHistoryFilter({ [name]: value }));
    }
    return handler;
  }

  return (
    <Container>
      <Accordion expanded={expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          classes={{ content: classes.expansionHeader }}
          onClick={() => setExpanded(!expanded)}
        >
          <div className={classes.heading}>
            <TitleContent>History Filter</TitleContent>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <form style={{ width: '100%' }} onSubmit={handleSearch}>
            <Grid container style={{ padding: '0px 10px 10px 10px' }}>
              <Grid item lg={3} md={3} sm={12} xs={12}>
                <InputContainer>
                  <TextField
                    onChange={handleEdit}
                    size="small"
                    name="event"
                    label="Service Performed"
                    variant="outlined"
                    value={event}
                    fullWidth
                    select
                  >
                    <MenuItem value="Added to hierarchy">
                      Added to hierarchy
                    </MenuItem>
                    <MenuItem value="Removed from hierarchy">
                      Removed from hierarchy
                    </MenuItem>
                    <MenuItem value="Check-Out">Check-Out</MenuItem>
                    <MenuItem value="Check-In">Check-In</MenuItem>
                    <MenuItem value="Allocation">Allocation</MenuItem>
                    <MenuItem value="Transfer of Ownership">
                      Transfer of Ownership
                    </MenuItem>
                    <MenuItem value="Reconcile">Reconcile</MenuItem>
                    <MenuItem value="Reclass">Reclass</MenuItem>
                    <MenuItem value="Bill of Material">
                      Bill of Material
                    </MenuItem>
                    <MenuItem value="Fabrication">Fabrication</MenuItem>
                    <MenuItem value="Equipment Created">
                      Equipment Created
                    </MenuItem>
                    <MenuItem value="Equipment Imported">
                      Equipment Imported
                    </MenuItem>
                    <MenuItem value="Fabricated">Fabricated</MenuItem>
                    <MenuItem value="Created by user">Created by user</MenuItem>
                    <MenuItem value="Equipment Edited">
                      Equipment Edited
                    </MenuItem>
                    <MenuItem value="Consumed Other Equipments">
                      Consumed Other Equipments
                    </MenuItem>
                  </TextField>
                </InputContainer>
              </Grid>
              <Grid item lg={3} md={3} sm={12} xs={12}>
                <InputContainer>
                  <TextField
                    onChange={handleEdit}
                    size="small"
                    name="transactionId"
                    label="Transaction ID"
                    variant="outlined"
                    value={transactionId}
                    fullWidth
                  />
                </InputContainer>
              </Grid>
              {complete && (
                <>
                  <Grid item lg={3} md={3} sm={12} xs={12}>
                    <InputContainer>
                      <TextField
                        onChange={handleEdit}
                        size="small"
                        name="facilityReferenceID"
                        label="Facility Reference ID"
                        variant="outlined"
                        value={facilityReferenceID}
                        fullWidth
                      />
                    </InputContainer>
                  </Grid>
                  <Grid item lg={3} md={3} sm={12} xs={12}>
                    <InputContainer>
                      <TextField
                        onChange={handleEdit}
                        size="small"
                        name="SLOC"
                        label="SLOC"
                        variant="outlined"
                        value={SLOC}
                        fullWidth
                        select
                      >
                        {SLOCs?.map((item) => (
                          <MenuItem value={item._id}>{item.sloc_id}</MenuItem>
                        ))}
                      </TextField>
                    </InputContainer>
                  </Grid>
                  <Grid item lg={3} md={3} sm={12} xs={12}>
                    <InputContainer>
                      <TextField
                        onChange={handleEdit}
                        size="small"
                        name="batch"
                        label="Batch"
                        variant="outlined"
                        value={batch}
                        fullWidth
                      />
                    </InputContainer>
                  </Grid>
                  <Grid item lg={3} md={3} sm={12} xs={12}>
                    <InputContainer>
                      <TextField
                        onChange={handleEdit}
                        size="small"
                        name="project"
                        label="Project To"
                        variant="outlined"
                        value={project}
                        fullWidth
                        select
                      >
                        {projects?.map((item) => (
                          <MenuItem value={item._id}>{item.name}</MenuItem>
                        ))}
                      </TextField>
                    </InputContainer>
                  </Grid>
                  <Grid item lg={3} md={3} sm={12} xs={12}>
                    <InputContainer>
                      <TextField
                        onChange={handleEdit}
                        size="small"
                        name="chargeCode"
                        label="Charge Code"
                        variant="outlined"
                        value={chargeCode}
                        fullWidth
                        select
                      >
                        {chargeCodes?.map((item) => (
                          <MenuItem value={item._id}>
                            {item.original_charge_code}
                          </MenuItem>
                        ))}
                      </TextField>
                    </InputContainer>
                  </Grid>
                </>
              )}
              <Grid item lg={3} md={3} sm={12} xs={12}>
                <InputContainer>
                  <KeyboardDatePicker
                    label="Period Start"
                    size="small"
                    inputVariant="outlined"
                    name="period_start"
                    value={period_start}
                    onChange={handleDate('period_start')}
                    format="MM-dd-yyyy"
                    fullWidth
                  />
                </InputContainer>
              </Grid>
              <Grid item lg={3} md={3} sm={12} xs={12}>
                <InputContainer>
                  <KeyboardDatePicker
                    label="Period End"
                    size="small"
                    inputVariant="outlined"
                    name="period_end"
                    value={period_end}
                    onChange={handleDate('period_end')}
                    format="MM-dd-yyyy"
                    fullWidth
                  />
                </InputContainer>
              </Grid>
              <Grid item lg={3} md={3} sm={12} xs={12}>
                <InputContainer>
                  <TextField
                    onChange={handleEdit}
                    size="small"
                    name="author"
                    label="Author"
                    variant="outlined"
                    value={author}
                    fullWidth
                  />
                </InputContainer>
              </Grid>
              <Grid item xs style={{ textAlign: 'end' }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{ marginRight: 10, cursor: 'pointer' }}
                >
                  Filter
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={clearFilter}
                  style={{ cursor: 'pointer' }}
                >
                  Clear
                </Button>
              </Grid>
            </Grid>
          </form>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  loggedUser: state.loggedUser,
  historyFilter: state.historyFilter,
  SLOCs: state.slocs?.docs || state.slocs,
  projects: state.projects?.docs || state.projects,
  chargeCodes: state.originalCharges?.docs || state.originalCharges,
});

export default connect(mapStateToProps)(HistoryFilter);
