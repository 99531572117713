import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Spinner from '../../../components/Spinner/index';
import SwipeableViews from 'react-swipeable-views';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Alert from '../../../components/Alert/index';
import { makeStyles } from '@material-ui/core/styles';
import { AddButton } from './styles';
import { Add, Close, EditRounded, Check } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import TabPanel from '../../../components/TabPanel';
import {
  TextField,
  FormGroup,
  Button,
  FormControlLabel,
  Switch,
  Tab,
  Tabs,
  Grid,
  MenuItem,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import {
  Container,
  ModalTitle,
  ButtonContainer,
  CheckboxContent,
  TabSheet,
  InputContent,
} from './styles';
import {
  getMaintenanceCategory,
  createMaintenanceCategory,
  updateMaintenanceCategory,
} from '../../../redux/actions/maintenanceCategory';
import { effectedDateMap } from '../../../utils/Maintenance/maps';
import { getSpecLibrary } from '../../../redux/actions/vault';
import { getSpecName } from '../../../utils/Maintenance';

const styles = {
  formButton: {
    margin: 2,
  },
};

const useClasses = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  title: {
    fontSize: 20,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  row: {
    padding: 0,
  },
  cell: {
    fontSize: 12,
    padding: '0px 10px 0px 15px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  buttons: {
    color: 'blue',
  },
  checkButton: {
    color: 'green',
  },
  closeButton: {
    color: 'red',
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const MaintenanceCategoryModal = ({
  id,
  dispatch,
  maintenanceCategory,
  maintenanceCategoryModal,
  handleClose,
  maintenanceCategorySpinner,
  specLibrary,
}) => {
  const [active, setActive] = useState(true);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [value, setValue] = React.useState(0);
  const [maintenances, setMaintenances] = useState([]);
  const classes = useClasses();
  const [editing, setEditing] = React.useState(null);

  useEffect(() => {
    if (id !== 'new') {
      dispatch(getMaintenanceCategory(id));
    }
  }, [id]);
  useEffect(() => {
    dispatch(getSpecLibrary());
  }, []);

  useEffect(() => {
    if (maintenanceCategory && id !== 'new') {
      setActive(maintenanceCategory.active);
      setName(maintenanceCategory.name);
      setMaintenances(maintenanceCategory.maintenances);
    }
  }, [maintenanceCategory]);

  useEffect(() => {
    if (!maintenanceCategoryModal) {
      handleClose();
    }
  }, [maintenanceCategoryModal]);

  const handleChange = (_e, newValue) => {
    console.log(newValue);
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleAdd = () => {
    setEditing(maintenances.length);
    setMaintenances((currentMaintenances) => [
      ...currentMaintenances,
      { name: '' },
    ]);
  };

  const handleEditMaintenance = (e) => {
    const { value, name } = e.target;
    setMaintenances((currentMaintenances) => {
      const updatedMaintenances = [...currentMaintenances];
      updatedMaintenances[editing] = {
        ...updatedMaintenances[editing],
        [name]: value,
      };
      return updatedMaintenances;
    });
  };

  const handleEditInspectionCriteria = (_, value) => {
    setMaintenances((currentMaintenances) => {
      const updatedMaintenances = [...currentMaintenances];
      updatedMaintenances[editing] = {
        ...updatedMaintenances[editing],
        defaultInspectionCriteria: value?._id,
      };
      return updatedMaintenances;
    });
  };

  const handleStartEditing = (index) => {
    function handler() {
      setEditing(index);
    }
    return handler;
  };

  const handleConfirm = () => {
    setEditing(null);
  };

  function handleDelete(index) {
    function handler() {
      Alert(
        'Are you sure?',
        "You won't be able to revert this!",
        false,
        '',
        'Yes, delete it.'
      ).then(({ confirm }) => {
        if (!confirm) return;
        setMaintenances((currentMaintenances) => {
          const updatedMaintenances = [...currentMaintenances];
          updatedMaintenances.splice(index, 1);
          return updatedMaintenances;
        });
        setEditing(null);
      });
    }
    return handler;
  }

  const handleSave = async () => {
    let err = false;
    setNameError(!name);
    err = !name;

    if (!err) {
      const response = await Alert();
      if (response && response.confirm) {
        if (id === 'new') {
          dispatch(
            createMaintenanceCategory({
              active,
              name,
              maintenances,
            })
          );
        } else {
          dispatch(
            updateMaintenanceCategory({
              _id: id,
              active,
              name,
              maintenances,
            })
          );
        }
      }
    }
  };

  const specFiles = specLibrary.filter(
    ({ isDirectory, hidden }) => !isDirectory && !hidden
  );

  return (
    <>
      {maintenanceCategorySpinner && <Spinner overlay />}
      <Container style={{ opacity: maintenanceCategorySpinner ? 0.5 : 1 }}>
        <ModalTitle>
          {id === 'new' ? 'New' : 'Edit'} Maintenance Category
        </ModalTitle>
        <TabSheet>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="Category Data" {...a11yProps(0)} />
            <Tab label="Maintenance List" {...a11yProps(1)} />
          </Tabs>

          <SwipeableViews
            axis="x"
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir="ltr">
              <Grid container style={{ opacity: 1 }}>
                <FormGroup>
                  <Grid item xs={12}>
                    <CheckboxContent>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={active}
                            onChange={(e) => setActive(e.target.checked)}
                          />
                        }
                        label="Active"
                        labelPlacement="start"
                      />
                    </CheckboxContent>
                  </Grid>
                  <Grid item xs={12}>
                    <InputContent>
                      <TextField
                        id="outlined-password-input"
                        label="Name"
                        type="text"
                        error={nameError}
                        helperText={nameError && 'Name is required'}
                        variant="outlined"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        fullWidth
                      />
                    </InputContent>
                  </Grid>
                </FormGroup>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1} dir="ltr">
              <AddButton
                variant="contained"
                color="primary"
                size="small"
                onClick={handleAdd}
              >
                <Add /> Add Maintenance
              </AddButton>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Maintenance Name</TableCell>
                      <TableCell>Default Inspection Criteria</TableCell>
                      <TableCell style={{ minWidth: 244 }}>
                        Default Effected Date
                      </TableCell>
                      <TableCell>Default Interval</TableCell>
                      <TableCell>Default Notification Trigger</TableCell>
                      <TableCell>Default Notification Frequency</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {maintenances?.map((maintenance, index) => {
                      return index === editing ? (
                        <TableRow className={classes.row}>
                          <TableCell className={classes.cell}>
                            <InputContent>
                              <TextField
                                label="Name"
                                required
                                type="text"
                                variant="outlined"
                                value={maintenance.name}
                                name="name"
                                onChange={handleEditMaintenance}
                                fullWidth
                              />
                            </InputContent>
                          </TableCell>
                          <TableCell
                            className={classes.cell}
                            style={{ minWidth: 290 }}
                          >
                            <InputContent>
                              <Autocomplete
                                getOptionSelected={({ _id }, value) => {
                                  return _id === value?._id;
                                }}
                                value={specFiles.find(
                                  ({ _id }) =>
                                    _id ===
                                    maintenance.defaultInspectionCriteria
                                )}
                                name="defaultInspectionCriteria"
                                onChange={handleEditInspectionCriteria}
                                fullWidth
                                options={specFiles}
                                autoHighlight
                                getOptionLabel={({ name }) => name}
                                renderInput={(props) => (
                                  <TextField
                                    {...props}
                                    label="Default Inspection Criteria"
                                    required
                                    variant="outlined"
                                  />
                                )}
                              />
                            </InputContent>
                          </TableCell>
                          <TableCell className={classes.cell}>
                            <InputContent>
                              <TextField
                                label="Default Effected Date"
                                required
                                variant="outlined"
                                value={maintenance.defaultEffectedDate}
                                name="defaultEffectedDate"
                                onChange={handleEditMaintenance}
                                fullWidth
                                select
                              >
                                {Object.keys(effectedDateMap).map((key) => (
                                  <MenuItem value={key}>
                                    {effectedDateMap[key]}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </InputContent>
                          </TableCell>
                          <TableCell className={classes.cell}>
                            <InputContent>
                              <TextField
                                label="Default Interval"
                                required
                                inputMode="numeric"
                                variant="outlined"
                                value={maintenance.defaultInterval}
                                name="defaultInterval"
                                onChange={handleEditMaintenance}
                                fullWidth
                                InputProps={{ endAdornment: 'days' }}
                              />
                            </InputContent>
                          </TableCell>
                          <TableCell className={classes.cell}>
                            <InputContent>
                              <TextField
                                label="Default Notification Trigger"
                                required
                                inputMode="numeric"
                                variant="outlined"
                                value={maintenance.defaultNotificationTrigger}
                                name="defaultNotificationTrigger"
                                onChange={handleEditMaintenance}
                                fullWidth
                                InputProps={{ endAdornment: 'days' }}
                              />
                            </InputContent>
                          </TableCell>
                          <TableCell className={classes.cell}>
                            <InputContent>
                              <TextField
                                label="Default Notification Frequency"
                                required
                                inputMode="numeric"
                                variant="outlined"
                                value={maintenance.defaultNotificationFrequency}
                                name="defaultNotificationFrequency"
                                onChange={handleEditMaintenance}
                                fullWidth
                                InputProps={{ endAdornment: 'days' }}
                              />
                            </InputContent>
                          </TableCell>
                          <TableCell className={classes.cell}>
                            <IconButton onClick={handleConfirm} color="primary">
                              <Check />
                            </IconButton>
                            <IconButton
                              onClick={handleDelete(index)}
                              color="secondary"
                            >
                              <Close />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ) : (
                        <TableRow className={classes.row}>
                          <TableCell className={classes.cell}>
                            {maintenance.name}
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {getSpecName(
                              maintenance.defaultInspectionCriteria,
                              specLibrary
                            )}
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {effectedDateMap[maintenance.defaultEffectedDate]}
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {maintenance.defaultInterval} days
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {maintenance.defaultNotificationTrigger} days
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {maintenance.defaultNotificationFrequency} days
                          </TableCell>
                          <TableCell className={classes.cell}>
                            <IconButton
                              onClick={handleStartEditing(index)}
                              color="primary"
                            >
                              <EditRounded />
                            </IconButton>
                            <IconButton
                              onClick={handleDelete(index)}
                              color="secondary"
                            >
                              <Close />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>
          </SwipeableViews>
        </TabSheet>

        <ButtonContainer>
          <Button
            color="secondary"
            onClick={handleClose}
            variant="contained"
            style={styles.formButton}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={handleSave}
            variant="contained"
            style={styles.formButton}
          >
            Save
          </Button>
        </ButtonContainer>
      </Container>
    </>
  );
};

export default connect((state) => ({
  maintenanceCategory: state.maintenanceCategory,
  maintenanceCategoryModal: state.ui.maintenanceCategoryModal,
  maintenanceCategorySpinner: state.ui.maintenanceCategoryLoading,
  specLibrary: state.specLibrary,
}))(MaintenanceCategoryModal);
