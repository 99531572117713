import React from 'react';
import styled from 'styled-components';
import { Button } from '@material-ui/core';

export const Container = styled.div`
  min-width: 60vw;
  padding: 20px;
`;

export const ModalTitle = styled.div`
  padding-left: 15px;
  padding-bottom: 20px;
  font-size: 1.3rem;
`;

export const InputContent = styled.form`
  width: 100%;
  padding: 20px 10px 10px 10px;
`;

export const CheckboxContent = styled.form`
  width: 100%;
  padding: 12px 10px 15px 0px;
`;

export const ButtonContainer = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

export const TabSheet = styled.div`
  .MuiTabs-flexContainer {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  
  }
  .MuiTab-textColorInherit {
    color: #2c2c32;
  }
  .Mui-selected {
    color: #000;
  }
  .MuiTabs-indicator {
    background-color: #000;
  }

`;

export const AddButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 32px;
`;

export function AddButton(props) {
  return (
    <AddButtonContainer>
      <Button {...props} />
    </AddButtonContainer>
  );
}
