export const GET_MAINTENANCE_CATEGORIES = '[maintenanceCategories] GET';
export const FETCH_MAINTENANCE_CATEGORIES_SUCCESS = '[maintenanceCategories] Fetch success';
export const FETCH_MAINTENANCE_CATEGORIES_ERROR = '[maintenanceCategories] Fetch Error';
export const UPDATE_MAINTENANCE_CATEGORIES = '[maintenanceCategories] Update';
export const SHOW_MAINTENANCE_CATEGORIES_SPINNER = '[maintenanceCategories - ui] show spinner';
export const HIDE_MAINTENANCE_CATEGORIES_SPINNER = '[maintenanceCategories - ui] hide spinner';

export const getMaintenanceCategories = (filter) => ({
  type: GET_MAINTENANCE_CATEGORIES,
  payload: filter,
});

export const updateMaintenanceCategories = (data) => ({
  type: UPDATE_MAINTENANCE_CATEGORIES,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_MAINTENANCE_CATEGORIES_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_MAINTENANCE_CATEGORIES_SPINNER,
});
