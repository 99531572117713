import React from 'react';
import { Link } from 'react-router-dom';

import {
  CardContainer,
  CardImage,
  CardButton,
  ExternalLink,
} from './styles';

function HubCard({
  company, link, image, color,
}) {
  return (
    <CardContainer>
      <CardImage src={image} />
      {company === 'Ramp' ? (
        <Link to={link}>
          <CardButton color={color}>
            {company}
          </CardButton>
        </Link>
      ) : (
        <ExternalLink href={link} target="_blank">
          <CardButton color={color}>
            {company}
          </CardButton>
        </ExternalLink>
      )}
    </CardContainer>
  );
}

export default HubCard;
