import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Animated } from 'react-animated-css';
import {
  Grid,
  Button,
  TextField,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Form, CheckboxContent } from './styles';
import Spinner from '../../components/Spinner/index';
import { getMRPNotificationSetup, updateMRPNotificationSetup } from '../../redux/actions/MRPNotificationSetup';
import { getUsers } from '../../redux/actions/users';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  title: {
    fontSize: 20,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflow: 'hidden',
  },
  row: {
    padding: 0,
  },
  cell: {
    fontSize: 14,
    padding: '5px 0px 5px 15px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 0,
  },
  usersTitle: {
    fontSize: 16,
    fontWeight: 'bold',
  },
}));

function MRPNotificationSetup({
  MRPNotificationSetup, users, usersLoading, dispatch,
}) {
  const classes = useStyles();
  const [active, setActive] = useState(true);
  const [defaultPeriod, setDefaultPeriod] = useState(7); // 7 days
  const [rampUsers, setRampUsers] = useState([]);
  const [usersRegisteredInNotification, setUsersRegisteredInNotification] = useState([]);
  const [usersFormatted, setUsersFormatted] = useState([]);

  useEffect(() => {
    dispatch(getUsers({ page: 1, limit: 999 })); // TODO: Make this pagination
    dispatch(getMRPNotificationSetup());
  }, []);

  const convertDaysToSeconds = (time) => time * 60 * 60 * 24;

  const convertSecondsToDays = (time) => time / (60 * 60 * 24);

  useEffect(() => {
    setRampUsers(users?.docs || []);
  }, [users]);

  useEffect(() => {
    setActive(MRPNotificationSetup.active);
    setDefaultPeriod(MRPNotificationSetup.default_period_in_seconds ? convertSecondsToDays(Number(MRPNotificationSetup.default_period_in_seconds)) : 7);
    if (MRPNotificationSetup.users?.length > 0) {
      setUsersRegisteredInNotification(MRPNotificationSetup.users[0]?._id ? MRPNotificationSetup.users.map((user) => user._id) : MRPNotificationSetup.users);
    }
  }, [MRPNotificationSetup]);

  useEffect(() => {
    if (rampUsers.length > 0) {
      const usersSelected = rampUsers.map((user) => ({
        ...user,
        selected: usersRegisteredInNotification.includes(user._id),
      }));
      setUsersFormatted(usersSelected);
    }
  }, [rampUsers, usersRegisteredInNotification]);

  const inputStyle = {
    width: '100%',
    marginTop: 20,
  };

  const handleSave = async () => {
    dispatch(
      updateMRPNotificationSetup({
        active,
        default_period_in_seconds: convertDaysToSeconds(defaultPeriod),
        users: usersFormatted.filter((user) => user.selected).map((user) => user._id),
      }),
    );
  };

  const onSelect = (userId, value) => {
    let copiedUsers = [...usersFormatted];
    copiedUsers = copiedUsers.map((user) => {
      if (user._id === userId) {
        user.selected = value;
      }
      return user;
    });
    setUsersFormatted(copiedUsers);
  };

  return (
    <Container>
      <Animated
        animationIn="fadeInLeft"
        animationOut="fadeOut"
        animationInDuration={500}
        animationOutDuration={1000}
      >
        <Form>
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12} align="left">
              <h2>MRP Notifications Setup</h2>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} align="left">
              <CheckboxContent>
                <FormControlLabel
                  control={<Switch checked={active} onChange={(e) => setActive(e.target.checked)} />}
                  label="Active"
                  labelPlacement="start"
                />
              </CheckboxContent>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} align="left">
              <TextField
                id="standard-basic"
                onChange={(e) => setDefaultPeriod(e.target.value)}
                value={defaultPeriod}
                type="number"
                label="Default Period in Days"
                style={inputStyle}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} align="center" style={{ paddingTop: 20 }}>
              <span className={classes.usersTitle}>
                Select the users that will receive the notifications
              </span>
              <TableContainer className={classes.table}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>E-mail</TableCell>
                      <TableCell>Receive Notification</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {usersLoading && (
                      <TableRow className={classes.row}>
                        <TableCell colSpan="3" className={classes.cell}>
                          <Spinner parent="Table" />
                        </TableCell>
                      </TableRow>
                    )}
                    {!usersLoading
                      && usersFormatted.map((user) => (
                        <TableRow className={classes.row}>
                          <TableCell className={classes.cell}>
                            {user.name}
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {user.email}
                          </TableCell>
                          <TableCell className={classes.cell} style={{ width: 200 }}>
                            <CheckboxContent>
                              <FormControlLabel
                                control={<Switch checked={user.selected} onChange={(e) => onSelect(user._id, e.target.checked)} />}
                              />
                            </CheckboxContent>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} align="center">
              <Button
                color="primary"
                variant="contained"
                onClick={handleSave}
                style={{ marginTop: 20 }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Animated>
    </Container>
  );
}

export default connect((state) => ({
  MRPNotificationSetup: state.mrpNotificationSetup,
  users: state.users,
  usersLoading: state.ui.usersLoading,
}))(MRPNotificationSetup);
