import {
  FETCH_WELLS_SUCCESS,
  FETCH_WELLS_ERROR,
  GET_WELLS,
  updateWells,
  showSpinner,
  hideSpinner,
} from '../actions/wells';
import { apiRequest } from '../actions/api';

const URL = 'wells';

export const getWellsFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_WELLS) {
    dispatch(
      apiRequest(
        'GET',
        URL,
        action.payload,
        FETCH_WELLS_SUCCESS,
        FETCH_WELLS_ERROR,
        ['customer', 'project', 'listAll'],
      ),
    );
    dispatch(showSpinner());
  }
};

export const processWellsCollection = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_WELLS_SUCCESS) {
    dispatch(updateWells(action.payload));
    dispatch(hideSpinner());
  }
};

export const wellsMdl = [getWellsFlow, processWellsCollection];
