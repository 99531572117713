import styled, { css } from 'styled-components';
import {
  Paper as MuiPaper,
  Divider as MaterialDivider,
} from '@material-ui/core';

export const Container = styled.div`
  padding: 1rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
`;

export const Paper = styled(MuiPaper)`
  width: 100%;
  margin-bottom: 2rem;
  padding: 1rem;
`;

export const Title = styled.span`
  font-size: 20px;
  display: flex;
  align-items: center;
`;

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Body = styled.div``;

export const SectionTitle = styled.span`
  font-size: 18px;
`;

export const Section = styled.div`
  margin-top: 1rem;
  margin-left: 0.5rem;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-left-width: 5px;
  border-left-style: solid;
  ${({ pending }) => pending
    && css`
      border-left-color: #fff59d;
    `}
  ${({ done }) => done
    && css`
      border-left-color: #a5d6a7;
    `}
  padding-left: 1rem;
`;

export const ButtonArea = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: center;
`;
