import {
  GET_MOVIMENTATION,
  UPDATE_MOVIMENTATION,
  UPDATE_OBJECT_MOVIMENTATION,
  CREATE_MOVIMENTATION,
  DELETE_MOVIMENTATION,
} from '../actions/movimentation';

export function movimentationReducer(movimentation = {}, action) {
  switch (action.type) {
    case UPDATE_OBJECT_MOVIMENTATION:
      return action.payload;

    default:
      return movimentation;
  }
}
