import {
  UPDATE_MAINTENANCE_SUCCESS,
  UPDATE_MAINTENANCE_ERROR,
  FETCH_MAINTENANCE_SUCCESS,
  FETCH_MAINTENANCE_ERROR,
  GET_MAINTENANCE,
  CREATE_MAINTENANCE,
  UPDATE_MAINTENANCE,
  updateObjectMaintenance,
  showSpinner,
  hideSpinner,
  hideModal,
} from '../actions/maintenance';
import { getMaintenances } from '../actions/maintenances';
import Toastify from '../../utils/Toastify/index';
import { apiRequest } from '../actions/api';

const URL = 'maintenance';

export const createMaintenanceFlow = ({ dispatch }) =>
  (next) =>
    (action) => {
      next(action);
      if (action.type === CREATE_MAINTENANCE) {
        dispatch(
          apiRequest(
            'POST',
            URL,
            action.payload,
            UPDATE_MAINTENANCE_SUCCESS,
            UPDATE_MAINTENANCE_ERROR
          )
        );
        dispatch(showSpinner());
      }
    };

export const updateMaintenanceFlow = ({ dispatch }) =>
  (next) =>
    (action) => {
      next(action);
      if (action.type === UPDATE_MAINTENANCE) {
        dispatch(
          apiRequest(
            'PUT',
            URL,
            action.payload,
            UPDATE_MAINTENANCE_SUCCESS,
            UPDATE_MAINTENANCE_ERROR
          )
        );
        dispatch(showSpinner());
      }
    };

export const getMaintenanceFlow = ({ dispatch }) =>
  (next) =>
    (action) => {
      next(action);
      if (action.type === GET_MAINTENANCE) {
        const { _id } = action.payload;
        dispatch(
          apiRequest(
            'GET-id',
            URL,
            { _id },
            FETCH_MAINTENANCE_SUCCESS,
            FETCH_MAINTENANCE_ERROR
          )
        );
        dispatch(showSpinner());
      }
    };

export const processMaintenanceCollection = ({ dispatch, getState }) =>
  (next) =>
    (action) => {
      next(action);
      if (action.type === FETCH_MAINTENANCE_SUCCESS) {
        dispatch(action.payload);
        dispatch(hideSpinner());
      } else if (action.type === UPDATE_MAINTENANCE_SUCCESS) {
        const requestData = getState().maintenance?.request;
        dispatch(updateObjectMaintenance(action.payload));
        dispatch(hideModal());
        dispatch(hideSpinner());
        // To reload the page
        dispatch(getMaintenances(requestData));
        Toastify.addSuccess('Maintenance saved.');
      } else if (action.type === UPDATE_MAINTENANCE_ERROR) {
        dispatch(hideSpinner());
        Toastify.addError(action.payload || 'Error to save the maintenance');
      }
    };

export const maintenanceMdl = [
  createMaintenanceFlow,
  updateMaintenanceFlow,
  getMaintenanceFlow,
  processMaintenanceCollection,
];
