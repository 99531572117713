import React, { useState, useEffect } from 'react';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Close from '@material-ui/icons/Close';
import { Add } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import { connect } from 'react-redux';
import {
  showEquipmentDetailsModal,
  hideEquipmentDetailsModal,
} from '../../../redux/actions/workOrders';
import Alert from '../../../components/Alert';
import ModalContainer from '../../../components/ModalContainer';
import EquipmentDetailsModal from '../../Modals/EquipmentDetails';
import { AddButton } from './styles';

function EquipmentDetails({
  dispatch,
  data: {
    revision: { equipment = [] },
  },
  woEquipmentDetailsModal,
  data,
  setData,
}) {

  const [editing] = React.useState(null);
  function handleCloseEquipmentDetailsModal() {
    dispatch(hideEquipmentDetailsModal());
  }

  function handleEquipmentDetailsModal(newData) {
    setData((currentData) => ({
      ...currentData,
      revision: {
        ...currentData.revision,
        equipment: newData,
      },
    }));
    dispatch(hideEquipmentDetailsModal());
  }

  function handleDeleteRow(index) {
    function handler() {
      Alert(
        'Are you sure?',
        "You won't be able to revert this!",
        false,
        '',
        'Yes, delete it.'
      ).then((response) => {
        if (!response?.confirm) return;
        setData((currentData) => {
          const currentEquipment = [
            ...currentData.revision.equipment,
          ];
          currentEquipment.splice(index, 1);
          return {
            ...currentData,
            revision: {
              ...currentData.revision,
              equipment: currentEquipment,
            },
          };
        });
      });
    }
    return handler;
  }

  function addNewEquipment() {
    dispatch(showEquipmentDetailsModal());
  }

  return (
    <>
      <ModalContainer
        open={woEquipmentDetailsModal}
        handleClose={handleCloseEquipmentDetailsModal}
      >
        <EquipmentDetailsModal
          handleClose={handleCloseEquipmentDetailsModal}
          onSave={handleEquipmentDetailsModal}
          editData={editing !== null ? equipment[editing] : null}
          data={equipment}
        />
      </ModalContainer>
      <AddButton variant="contained" color="primary" size="small" onClick={() => addNewEquipment()}>
        <Add />
        Add Equipment
      </AddButton>
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>RINA RAMP ID</TableCell>
              <TableCell>Customer MM No</TableCell>
              <TableCell>Total Qty. (EA)</TableCell>
              <TableCell>Footage</TableCell>
              <TableCell>COPAS Class</TableCell>
              <TableCell>Supplier Batch No</TableCell>
              <TableCell>RAMP Tag No</TableCell>
              <TableCell>Heat/Lot</TableCell>
              <TableCell>RBW</TableCell>
              <TableCell>Facility Reference ID</TableCell>
              <TableCell>Manufacture</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {equipment.map((movimentation, index) => (
              <TableRow>
                <TableCell>
                  {movimentation?.individual_item_id}
                </TableCell>
                <TableCell>{movimentation?.customer_MM?.mm_number}</TableCell>
                <TableCell>{movimentation.qty}</TableCell>
                <TableCell>{movimentation.footage}</TableCell>
                <TableCell>{movimentation.classification}</TableCell>
                <TableCell>{movimentation.batch_no}</TableCell>
                <TableCell>{movimentation.tag_number}</TableCell>
                <TableCell>{movimentation.heat}</TableCell>
                <TableCell>{movimentation.RBW}</TableCell>
                <TableCell>{movimentation.facility_reference_id}</TableCell>
                <TableCell>{movimentation.manufacturer}</TableCell>
                <TableCell>
                  <Tooltip title="Remove">
                    <IconButton
                      color="secondary"
                      onClick={handleDeleteRow(index)}
                    >
                      <Close />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default connect((state) => ({
  woEquipmentDetailsModal: state.ui.woEquipmentDetailsModal,
}))(EquipmentDetails);

