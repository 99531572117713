import { CUSTOMER_KEY } from '../../config/consts';

export const GET_VENDOR = '[vendor] GET';
export const UPDATE_VENDOR = '[vendor] UPDATE';
export const CREATE_VENDOR = '[vendor] CREATE';
export const DELETE_VENDOR = '[vendor] DELETE';
export const UPDATE_OBJECT_VENDOR = '[vendor] UPDATE OBJECT VENDOR';
// UI
export const SHOW_VENDOR_MODAL = '[vendor] SHOW MODAL';
export const HIDE_VENDOR_MODAL = '[vendor] HIDE MODAL';
// Spinner
export const UPDATE_VENDOR_SUCCESS = '[vendor] Update success';
export const UPDATE_VENDOR_ERROR = '[vendor] Update Error';
export const FETCH_VENDOR_SUCCESS = '[vendor] Fetch success';
export const FETCH_VENDOR_ERROR = '[vendor] Fetch Error';
export const SHOW_VENDOR_SPINNER = '[vendor - ui] show spinner';
export const HIDE_VENDOR_SPINNER = '[vendor - ui] hide spinner';

export const getVendor = (id) => ({
  type: GET_VENDOR,
  payload: { _id: id },
});

export const createVendor = (data) => ({
  type: CREATE_VENDOR,
  payload: { ...data, customer: localStorage.getItem(CUSTOMER_KEY) },
});

export const updateVendor = (data) => ({
  type: UPDATE_VENDOR,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_VENDOR_MODAL,
});

export const hideModal = () => ({
  type: HIDE_VENDOR_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_VENDOR_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_VENDOR_SPINNER,
});

export const updateObjectVendor = (data) => ({
  type: UPDATE_OBJECT_VENDOR,
  payload: data,
});
