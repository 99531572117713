import {
  FETCH_RECLASS_DAMAGES_SUCCESS,
  FETCH_RECLASS_DAMAGES_ERROR,
  GET_RECLASS_DAMAGES,
  updateReclassDamages,
  showSpinner,
  hideSpinner,
} from '../actions/reclassDamages';
import { apiRequest } from '../actions/api';

const URL = 'damage_descriptions';

export const getReclassDamagesFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_RECLASS_DAMAGES) {
    dispatch(
      apiRequest(
        'GET',
        URL,
        action.payload,
        FETCH_RECLASS_DAMAGES_SUCCESS,
        FETCH_RECLASS_DAMAGES_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const processReclassDamagesCollection = ({ dispatch }) => (next) => (
  action,
) => {
  next(action);
  if (action.type === FETCH_RECLASS_DAMAGES_SUCCESS) {
    dispatch(updateReclassDamages(action.payload));
    dispatch(hideSpinner());
  }
};

export const reclassDamagesMdl = [getReclassDamagesFlow, processReclassDamagesCollection];
