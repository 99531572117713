module.exports = [
  {
    value: 'name',
    label: 'Name',
    type: 'String',
  },
  {
    value: 'description',
    label: 'Description',
    type: 'String',
  },
  {
    value: 'project',
    label: 'Project',
    type: 'ObjectId',
  },
];
