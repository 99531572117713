import { applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import { reducers } from './reducers';
import { usersMdl } from './middleware/users';
import { userMdl } from './middleware/user';
import { profileMdl } from './middleware/profile';
import { MRPNotificationSetupMdl } from './middleware/MRPNotificationSetup';
import { rolesMdl } from './middleware/roles';
import { roleMdl } from './middleware/role';
import { customersMdl } from './middleware/customers';
import { customerMdl } from './middleware/customer';
import { businessUnitsMdl } from './middleware/businessUnits';
import { businessUnitMdl } from './middleware/businessUnit';
import { companyCodesMdl } from './middleware/companyCodes';
import { companyCodeMdl } from './middleware/companyCode';
import { projectsMdl } from './middleware/projects';
import { notificationsMdl } from './middleware/notifications';
import { projectMdl } from './middleware/project';
import { reclassDamagesMdl } from './middleware/reclassDamages';
import { reclassDamageMdl } from './middleware/reclassDamage';
import { reportFieldsMdl } from './middleware/reportFields';
import { reportFieldMdl } from './middleware/reportField';
import { loggedUserMdl } from './middleware/logged-user';
import { inventoryCartMdl } from './middleware/inventory_cart';
import { movimentationMdl } from './middleware/movimentation';
import { movimentationsMdl } from './middleware/movimentations';
import { slocMdl } from './middleware/sloc';
import { slocsMdl } from './middleware/slocs';
import { vendorMdl } from './middleware/vendor';
import { vendorsMdl } from './middleware/vendors';
import { workOrderMdl } from './middleware/workOrder';
import { workOrdersMdl } from './middleware/workOrders';
import { jobMdl } from './middleware/job';
import { jobsMdl } from './middleware/jobs';
import { original_chargeMdl } from './middleware/originalCharge';
import { original_chargesMdl } from './middleware/originalCharges';
import { ownerMdl } from './middleware/owner';
import { ownersMdl } from './middleware/owners';
import { customerMMMdl } from './middleware/customerMM';
import { customerMMsMdl } from './middleware/customerMMs';
import { checkInMdl } from './middleware/checkIn';
import { vpasMdl } from './middleware/vpas';
import { vpassMdl } from './middleware/vpass';
import { historyMdl } from './middleware/history';
import { reconcileMdl } from './middleware/reconcile';
import { checkOutMdl } from './middleware/checkOut';
import { allocateMdl } from './middleware/allocate';
import { reclassMdl } from './middleware/reclass';
import { transferOfOwnershipMdl } from './middleware/transferOfOwnership';
import { buildOfMaterialMdl } from './middleware/buildOfMaterial';
import { fabricationMdl } from './middleware/fabrication';
import { maintenancesMdl } from './middleware/maintenances';
import { maintenanceMdl } from './middleware/maintenance';
import { vaultMdl } from './middleware/vault';
import { resultLayoutMdl } from './middleware/resultLayout';
import { resultLayoutsMdl } from './middleware/resultLayouts';
import { wellsMdl } from './middleware/wells';
import { wellMdl } from './middleware/well';
import { serviceCategoryMdl } from './middleware/serviceCategory';
import { serviceCategoriesMdl } from './middleware/serviceCategories';
import { maintenanceCategoryMdl } from './middleware/maintenanceCategory';
import { maintenanceCategoriesMdl } from './middleware/maintenanceCategories';
import { api } from './middleware/api';
import { warehousesMdl } from './middleware/warehouses';
import { warehouseMdl } from './middleware/warehouse';
import { racksMdl } from './middleware/racks';
import { rackMdl } from './middleware/rack';
import { binsMdl } from './middleware/bins';
import { binMdl } from './middleware/bin';
import { cycleCountsMdl } from './middleware/cycleCounts';
import { cycleCountMdl } from './middleware/cycleCount';
import { inspectionsMdl } from './middleware/inspections';
import { inspectionMdl } from './middleware/inspection';
import { activitiesMdl } from './middleware/activities';
import { activityMdl } from './middleware/activity';

// dev tool
const composeEnhancers = (process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;

export const store = createStore(
  reducers,
  composeEnhancers(
    applyMiddleware(
      ...allocateMdl,
      ...buildOfMaterialMdl,
      ...businessUnitMdl,
      ...businessUnitsMdl,
      ...checkInMdl,
      ...checkOutMdl,
      ...companyCodeMdl,
      ...companyCodesMdl,
      ...customerMMMdl,
      ...customerMMsMdl,
      ...customerMdl,
      ...customersMdl,
      ...fabricationMdl,
      ...historyMdl,
      ...inventoryCartMdl,
      ...jobMdl,
      ...jobsMdl,
      ...loggedUserMdl,
      ...maintenanceCategoriesMdl,
      ...maintenanceCategoryMdl,
      ...maintenancesMdl,
      ...maintenanceMdl,
      ...movimentationMdl,
      ...movimentationsMdl,
      ...original_chargeMdl,
      ...original_chargesMdl,
      ...ownerMdl,
      ...ownersMdl,
      ...projectMdl,
      ...projectsMdl,
      ...notificationsMdl,
      ...reclassDamageMdl,
      ...reclassDamagesMdl,
      ...reclassMdl,
      ...reconcileMdl,
      ...reportFieldMdl,
      ...reportFieldsMdl,
      ...resultLayoutMdl,
      ...resultLayoutsMdl,
      ...roleMdl,
      ...rolesMdl,
      ...serviceCategoriesMdl,
      ...serviceCategoryMdl,
      ...slocMdl,
      ...slocsMdl,
      ...transferOfOwnershipMdl,
      ...profileMdl,
      ...MRPNotificationSetupMdl,
      ...userMdl,
      ...usersMdl,
      ...vaultMdl,
      ...vendorMdl,
      ...vendorsMdl,
      ...vpasMdl,
      ...vpassMdl,
      ...wellMdl,
      ...wellsMdl,
      ...workOrderMdl,
      ...workOrdersMdl,
      ...warehouseMdl,
      ...warehousesMdl,
      ...rackMdl,
      ...racksMdl,
      ...binMdl,
      ...binsMdl,
      ...cycleCountMdl,
      ...cycleCountsMdl,
      ...inspectionMdl,
      ...inspectionsMdl,
      ...activityMdl,
      ...activitiesMdl,
      api,
      thunk,
    ),
  ),
);
