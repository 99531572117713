import React from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Typography,
} from '@material-ui/core';
import Spinner from '../../../../components/Spinner';
import { useSelector } from 'react-redux';

function SelectSLOC({ onEdit, data, onNextStep }) {
  const slocs = useSelector((state) => state.slocs?.docs || state.slocs || []);
  const loading = useSelector((state) => state.ui.slocsLoading);

  const handleClick = (id) => () => {
    if (id === data.sloc) return onNextStep();
    onEdit({ target: { name: 'sloc', value: id } });
  };

  return (
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>SLOC ID</TableCell>
          <TableCell>SLOC Name</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {loading && (
          <TableRow>
            <TableCell colSpan={3}>
              <Spinner parent="Table" />
            </TableCell>
          </TableRow>
        )}
        {!loading && !slocs.length ? (
          <TableRow>
            <TableCell colSpan={3}>
              <Typography align="center" variant="body" component="div">
                No SLOC found
              </Typography>
            </TableCell>
          </TableRow>
        ) : (
          slocs.map((sloc) => (
            <TableRow key={sloc._id}>
              <TableCell>
                <Button
                  onClick={handleClick(sloc._id)}
                  color="primary"
                  variant={sloc._id === data.sloc ? 'contained' : 'outlined'}
                >
                  {sloc._id === data.sloc ? 'Selected' : 'Select'}
                </Button>
              </TableCell>
              <TableCell>{sloc.sloc_id}</TableCell>
              <TableCell>{sloc.sloc_name}</TableCell>
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
  );
}

export default SelectSLOC;
