import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, CircularProgress } from '@material-ui/core';
import DropZone from '../../../components/DropZone';
import Spinner from '../../../components/Spinner';
import {
  Container, InputContent, ModalTitle, ButtonContainer,
} from './styles';

const styles = {
  formButton: {
    margin: 2,
  },
};

function DocumentModal({
  dispatch,
  handleClose,
  documentSpinner,
  onSave,
  editData,
  onDelete,
}) {
  const [documentError, setDocumentError] = useState(false);
  const [data, setData] = useState({
    document: '',
    file: null,
  });
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (editData) setData(editData);
    else {
      setData({
        document: '',
      });
    }
  }, [editData]);
  const { document, file } = data;

  const handleSave = async () => {
    let err = false;
    setDocumentError(!document);
    err = !document;

    if (!err) {
      setLoading(true);
      onSave(data, () => setLoading(false));
    }
  };

  const handleEdit = (e) => {
    const { name, value } = e.target;
    setData((currentData) => ({ ...currentData, [name]: value }));
  };
  function handleFile(file) {
    setData((currentData) => ({ ...currentData, file }));
  }
  return (
    <>
      {documentSpinner && <Spinner overlay />}

      <Container style={{ opacity: documentSpinner ? 0.5 : 1 }}>
        <ModalTitle>
          {editData ? 'Edit' : 'New'}
          {' '}
          Inspection Document
        </ModalTitle>
        <InputContent>
          <TextField
            label="Document"
            required
            type="text"
            error={documentError}
            helperText={documentError && 'Document is required'}
            variant="outlined"
            value={document || ''}
            name="document"
            onChange={handleEdit}
            fullWidth
          />
        </InputContent>
        {!editData && (
          <DropZone
            allowedExtensions="any"
            file={file}
            onChangeFile={handleFile}
          />
        )}

        <ButtonContainer>
          {editData && (
            <Button
              color="secondary"
              onClick={onDelete}
              variant="outlined"
              style={styles.formButton}
            >
              Delete
            </Button>
          )}
          <Button
            color="secondary"
            onClick={handleClose}
            variant="contained"
            style={styles.formButton}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={handleSave}
            variant="contained"
            style={styles.formButton}
            disabled={loading}
            startIcon={loading && <CircularProgress size={12} />}
          >
            Save
          </Button>
        </ButtonContainer>
      </Container>
    </>
  );
}

export default connect((state) => ({
  documentSpinner: state.ui.documentLoading,
}))(DocumentModal);
