import {
  GET_SERVICE_CATEGORIES,
  UPDATE_SERVICE_CATEGORIES,
} from '../actions/serviceCategories';

const initState = {
  docs: [],
  page: 1,
  limit: 10,
  totalDocs: 0,
};

export function serviceCategoriesReducer(service_categories = initState, action) {
  switch (action.type) {
    case UPDATE_SERVICE_CATEGORIES:
      return action.payload;
    case GET_SERVICE_CATEGORIES:
    default:
      return service_categories;
  }
}
