export const GET_WELL = '[well] GET';
export const UPDATE_WELL = '[well] UPDATE';
export const CREATE_WELL = '[well] CREATE';
export const DELETE_WELL = '[well] DELETE';
export const UPDATE_OBJECT_WELL = '[well] UPDATE OBJECT WELL';
// UI
export const SHOW_WELL_MODAL = '[well] SHOW MODAL';
export const HIDE_WELL_MODAL = '[well] HIDE MODAL';
// Spinner
export const UPDATE_WELL_SUCCESS = '[well] Update success';
export const UPDATE_WELL_ERROR = '[well] Update Error';
export const FETCH_WELL_SUCCESS = '[well] Fetch success';
export const FETCH_WELL_ERROR = '[well] Fetch Error';
export const SHOW_WELL_SPINNER = '[well - ui] show spinner';
export const HIDE_WELL_SPINNER = '[well - ui] hide spinner';

export const getWell = (id) => ({
  type: GET_WELL,
  payload: { _id: id },
});

export const createWell = (data) => ({
  type: CREATE_WELL,
  payload: data,
});

export const updateWell = (data) => ({
  type: UPDATE_WELL,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_WELL_MODAL,
});

export const hideModal = () => ({
  type: HIDE_WELL_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_WELL_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_WELL_SPINNER,
});

export const updateObjectWell = (data) => ({
  type: UPDATE_OBJECT_WELL,
  payload: data,
});
