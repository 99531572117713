import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import WorkInstructionBox from './WorkInstructionBox';
import { getServiceCategories } from '../../../redux/actions/serviceCategories';

const WorkInstructions = ({
  data: {
    revision: { contacts = [] },
  },
  setData,
  dispatch,
  serviceCategories,
}) => {
  useEffect(() => {
    dispatch(getServiceCategories({ listAll: true, active: true }));
  }, []);

  return (
    <>
      <Grid container spacing={5}>
        {contacts.length === 0 && (
          <Grid item xs={12}>
            No contact found, please add one to add instructions.
          </Grid>
        )}
        {contacts.map((contact, index) => (
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <WorkInstructionBox
              contact={contact}
              contactIndex={index}
              setData={setData}
              serviceCategories={serviceCategories}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

function mapStateToProps(state) {
  return {
    serviceCategories:
      state.serviceCategories?.docs || state.serviceCategories || [],
  };
}

export default connect(mapStateToProps)(WorkInstructions);
