import {
  CREATE_RESULT_LAYOUT,
  UPDATE_RESULT_LAYOUT,
  GET_RESULT_LAYOUT,
  SUCCESS_CREATE_RESULT_LAYOUT,
  SUCCESS_GET_RESULT_LAYOUT,
  ERROR_CREATE_RESULT_LAYOUT,
  ERROR_GET_RESULT_LAYOUT,
  hideResultLayoutModal,
  updateResultLayoutObject,
} from '../actions/resultLayout';
import { listResultLayout } from '../actions/resultLayouts';
import { apiRequest } from '../actions/api';
import Toastify from '../../utils/Toastify/index';

const URL = 'result_layouts';

function createResultLayoutFlow({ dispatch, getState }) {
  return function (next) {
    return function (action) {
      next(action);
      if (action.type === CREATE_RESULT_LAYOUT) {
        dispatch(
          apiRequest(
            'POST',
            URL,
            { user: getState().loggedUser._id, ...action.payload },
            SUCCESS_CREATE_RESULT_LAYOUT,
            ERROR_CREATE_RESULT_LAYOUT
          )
        );
      }
    };
  };
}
function updateResultLayoutFlow({ dispatch }) {
  return function (next) {
    return function (action) {
      next(action);
      if (action.type === UPDATE_RESULT_LAYOUT) {
        dispatch(
          apiRequest(
            'PUT',
            URL,
            action.payload,
            SUCCESS_CREATE_RESULT_LAYOUT,
            ERROR_CREATE_RESULT_LAYOUT
          )
        );
      }
    };
  };
}
function getResultLayoutFlow({ dispatch }) {
  return function (next) {
    return function (action) {
      next(action);
      if (action.type === GET_RESULT_LAYOUT) {
        dispatch(
          apiRequest(
            'GET-id',
            URL,
            action.payload,
            SUCCESS_GET_RESULT_LAYOUT,
            ERROR_GET_RESULT_LAYOUT
          )
        );
      }
    };
  };
}
function processResultLayoutCollection({ dispatch }) {
  return function (next) {
    return function (action) {
      next(action);
      if (action.type === SUCCESS_CREATE_RESULT_LAYOUT) {
        dispatch(updateResultLayoutObject(action.payload));
        Toastify.addSuccess('Success - template saved.');
        dispatch(listResultLayout());
        dispatch(hideResultLayoutModal());
      } else if (action.type === ERROR_CREATE_RESULT_LAYOUT) {
        Toastify.addError(
          action.payload?.error || "Error - couldn't save template."
        );
      } else if (action.type === SUCCESS_GET_RESULT_LAYOUT) {
        dispatch(updateResultLayoutObject(action.payload));
      } else if (action.type === ERROR_GET_RESULT_LAYOUT) {
        dispatch(hideResultLayoutModal());
        Toastify.addError(
          action.payload?.error || "Error - couldn't get template."
        );
      }
    };
  };
}

export const resultLayoutMdl = [
  createResultLayoutFlow,
  updateResultLayoutFlow,
  getResultLayoutFlow,
  processResultLayoutCollection,
];
