import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
import DefaultInput from './DefaultInput';
import { getCustomerMMs } from '../../../../redux/actions/customerMMs';
import getRINADescription from '../../../../utils/Equipment/getRINADescription';

const StatusList = ['Available', 'Consignment', 'On Order'];

const buildOption =
  (labelField = 'name', valueField = '_id') =>
  (item) => ({ label: item[labelField], value: item[valueField] });

function Summary({ data, onChange, errors = [] }) {
  const dispatch = useDispatch();

  const equipmentLoading = useSelector(
    (state) => state.ui.movimentationLoading
  );
  const customerMMs = useSelector(
    (state) => state.customerMMs?.docs || state.customerMMs || []
  );

  useEffect(() => {
    dispatch(getCustomerMMs({ customer: data.customer, listAll: true }));
  }, [data.customer]);

  const fields = [
    {
      label: 'RINA MM Number',
      key: 'customer_MM',
      options: customerMMs.map(buildOption('rina_mm_number')),
      required: true,
    },
    {
      label: 'RAMP Unique ID',
      key: 'individual_item_id',
      disabled: true,
    },
    {
      label: 'Status',
      key: 'status',
      options: StatusList.map((status) => ({ label: status, value: status })),
    },
    {
      label: 'RINA Description',
      key: 'rina_description',
      value: getRINADescription(
        customerMMs?.find((current) => current._id === data.customer_MM)
      ),
      disabled: true,
      multiline: true,
      lg: 12,
      md: 12,
      sm: 12,
      xs: 12,
    },

    {
      label: 'Qty',
      key: 'qty',
      type: 'number',
    },
    {
      label: 'Footage',
      key: 'footage',
      type: 'number',
    },
    {
      label: 'Weight',
      key: 'weight',
      type: 'number',
    },
    {
      label: 'Note',
      key: 'note',
      multiline: true,
      lg: 12,
      md: 12,
      sm: 12,
      xs: 12,
    },
  ];

  return (
    <Grid container style={{ opacity: equipmentLoading ? 0.3 : 1 }}>
      {fields.map(({ key, label, ...props }) => (
        <DefaultInput
          key={key}
          name={key}
          value={data[key]}
          onChange={onChange}
          label={label}
          error={errors.includes(key)}
          helperText={errors.includes(key) && `${label} is required`}
          {...props}
        />
      ))}
    </Grid>
  );
}

export default Summary;
