import {
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_ERROR,
  FETCH_CUSTOMER_SUCCESS,
  FETCH_CUSTOMER_ERROR,
  GET_CUSTOMER,
  CREATE_CUSTOMER,
  UPDATE_CUSTOMER,
  updateObjectCustomer,
  showSpinner,
  hideSpinner,
  hideModal,
} from '../actions/customer';
import { getCustomers } from '../actions/customers';
import Toastify from '../../utils/Toastify/index';
import { apiRequest } from '../actions/api';

const URL = 'customers';

export const createCustomerFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === CREATE_CUSTOMER) {
    dispatch(
      apiRequest(
        'POST',
        URL,
        action.payload,
        UPDATE_CUSTOMER_SUCCESS,
        UPDATE_CUSTOMER_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const updateCustomerFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === UPDATE_CUSTOMER) {
    dispatch(
      apiRequest(
        'PUT',
        URL,
        action.payload,
        UPDATE_CUSTOMER_SUCCESS,
        UPDATE_CUSTOMER_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const getCustomerFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_CUSTOMER) {
    const { _id } = action.payload;
    dispatch(
      apiRequest(
        'GET-id',
        URL,
        { _id },
        FETCH_CUSTOMER_SUCCESS,
        FETCH_CUSTOMER_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const processCustomerCollection = ({ dispatch, getState }) => (next) => (
  action,
) => {
  next(action);
  if (action.type === FETCH_CUSTOMER_SUCCESS) {
    dispatch(updateObjectCustomer(action.payload));
    dispatch(hideSpinner());
  } else if (action.type === UPDATE_CUSTOMER_SUCCESS) {
    dispatch(updateObjectCustomer(action.payload));
    dispatch(hideModal());
    dispatch(hideSpinner());
    // To reload the page
    const { page, limit } = getState().customers;
    dispatch(getCustomers({ page, limit }));
    Toastify.addSuccess('Customer saved.');
  } else if (action.type === UPDATE_CUSTOMER_ERROR) {
    dispatch(hideSpinner());
    Toastify.addError(action.payload || 'Error to save the customer');
  }
};

export const customerMdl = [
  createCustomerFlow,
  updateCustomerFlow,
  getCustomerFlow,
  processCustomerCollection,
];
