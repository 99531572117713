import { Button, TextField } from '@material-ui/core';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import TagsField from '../../../components/TagsField';
import { apiUrl } from '../../../config/consts';
import { getHistory } from '../../../redux/actions/history';
import Toastify from '../../../utils/Toastify';
import { ButtonContainer, Container, InputContent, ModalTitle } from './styles';

const styles = {
  formButton: {
    margin: 2,
  },
};

const AddHistoryManually = ({ handleClose, dispatch, eventId }) => {
  const { id } = useParams();
  const [active, setActive] = useState(true);
  const [noteError, setNoteError] = useState(false);
  const [data, setData] = useState({
    note: '',
    individualData: [{ movimentation: id, tags: [] }],
    event: 'Created by user',
    files: [],
  });

  useEffect(() => {
    if (eventId === 'new')
      return setData({
        note: '',
        individualData: [{ movimentation: id, tags: [] }],
        event: 'Created by user',
        files: [],
      });

    axios
      .get(`${apiUrl}events/${eventId}`)
      .then((response) => {
        setData(response.data);
      })
      .catch(() => {
        Toastify.addError("Error - couldn't get event details");
        handleClose();
      });
  }, [eventId]);
  const { note, individualData, files } = data;

  const handleSave = async () => {
    setNoteError(!note);
    if (!note) return;

    axios
      .post(`${apiUrl}events`, data)
      .then((response) => {
        Toastify.addSuccess('Event posted successfully.');
        dispatch(
          getHistory({ page: 1, limit: 10, filter: { movimentation: id } })
        );
        handleClose();
      })
      .catch((error) => {
        Toastify.addError(
          error?.response?.data?.error || "Error - couldn't post event"
        );
      });
  };

  const handleEdit = (e) => {
    const { name, value } = e.target;
    setData((currentData) => ({ ...currentData, [name]: value }));
  };

  return (
    <>
      <Container>
        <ModalTitle>New Event</ModalTitle>
        <InputContent>
          <TextField
            label="Notes"
            required
            multiline
            error={noteError}
            helperText={noteError && 'This field is required'}
            variant="outlined"
            value={note || ''}
            name="note"
            onChange={handleEdit}
            fullWidth
          />
        </InputContent>
        <InputContent>
          <TagsField
            name="tags"
            value={individualData[0].tags}
            onChange={({ target: { value } }) =>
              setData((currentData) => ({
                ...currentData,
                individualData: [{ ...individualData[0], tags: value }],
              }))
            }
            label="Tags"
          />
        </InputContent>
        {/* <EventDocumentsSection files={files} /> */}
        <ButtonContainer>
          <Button
            color="secondary"
            onClick={handleClose}
            variant="contained"
            style={styles.formButton}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={handleSave}
            variant="contained"
            style={styles.formButton}
            disabled={eventId !== 'new'}
          >
            Save
          </Button>
        </ButtonContainer>
      </Container>
    </>
  );
};

export default connect((state) => ({}))(AddHistoryManually);
