export const GET_RACKS = '[racks] GET';
export const FETCH_RACKS_SUCCESS = '[racks] Fetch success';
export const FETCH_RACKS_ERROR = '[racks] Fetch Error';
export const UPDATE_RACKS = '[racks] Update';
export const SHOW_RACKS_SPINNER = '[racks - ui] show spinner';
export const HIDE_RACKS_SPINNER = '[racks - ui] hide spinner';

export const getRacks = (filter) => ({
  type: GET_RACKS,
  payload: filter,
});

export const updateRacks = (data) => ({
  type: UPDATE_RACKS,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_RACKS_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_RACKS_SPINNER,
});
