import {
  GET_RECLASS_DAMAGE,
  UPDATE_RECLASS_DAMAGE,
  UPDATE_OBJECT_RECLASS_DAMAGE,
  CREATE_RECLASS_DAMAGE,
  DELETE_RECLASS_DAMAGE,
} from '../actions/reclassDamage';

export function reclassDamageReducer(reclassDamage = {}, action) {
  switch (action.type) {
    case GET_RECLASS_DAMAGE:
      return action.payload;
    case UPDATE_RECLASS_DAMAGE:
      return action.payload;
    case CREATE_RECLASS_DAMAGE:
      return action.payload;
    case DELETE_RECLASS_DAMAGE:
      return action.payload;
    case UPDATE_OBJECT_RECLASS_DAMAGE:
      return action.payload;

    default:
      return reclassDamage;
  }
}
