import {
  UPDATE_FABRICATION_SUCCESS,
  UPDATE_FABRICATION_ERROR,
  FETCH_FABRICATION_SUCCESS,
  FETCH_FABRICATION_ERROR,
  GET_FABRICATION,
  CREATE_FABRICATION,
  UPDATE_FABRICATION,
  updateObjectFabrication,
  showSpinner,
  hideSpinner,
  hideModal,
} from '../actions/fabrication';
import { removeFromInventoryCart } from '../actions/inventory_cart';
import Toastify from '../../utils/Toastify/index';
import history from '../../services/history';
import { apiRequest } from '../actions/api';
import { CUSTOMER_KEY } from '../../config/consts';

const URL = 'fabrication';

export const createFabricationFlow =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === CREATE_FABRICATION) {
      action.payload.append('inventory', localStorage.getItem(CUSTOMER_KEY));
      action.payload.append('user', getState().loggedUser._id);
      dispatch(
        apiRequest(
          'POST',
          URL,
          action.payload,
          UPDATE_FABRICATION_SUCCESS,
          UPDATE_FABRICATION_ERROR
        )
      );
      dispatch(showSpinner());
    }
  };

export const updateFabricationFlow =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === UPDATE_FABRICATION) {
      dispatch(
        apiRequest(
          'PUT',
          URL,
          action.payload,
          UPDATE_FABRICATION_SUCCESS,
          UPDATE_FABRICATION_ERROR
        )
      );
      dispatch(showSpinner());
    }
  };

export const getFabricationFlow =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === GET_FABRICATION) {
      const { _id } = action.payload;
      dispatch(
        apiRequest(
          'GET-id',
          URL,
          { _id },
          FETCH_FABRICATION_SUCCESS,
          FETCH_FABRICATION_ERROR
        )
      );
      dispatch(showSpinner());
    }
  };

export const processFabricationCollection =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === FETCH_FABRICATION_SUCCESS) {
      dispatch(updateObjectFabrication(action.payload));
      dispatch(hideSpinner());
    } else if (action.type === UPDATE_FABRICATION_SUCCESS) {
      Toastify.addSuccess('Fabrication posted successfully!');
      dispatch(updateObjectFabrication(action.payload));
      dispatch(
        removeFromInventoryCart(
          getState().loggedUser._id,
          action.payload?.individualData?.map(
            (data) => data?.movimentation?._id || data?.movimentation
          )
        )
      );
      dispatch(hideModal());
      dispatch(hideSpinner());
      history.replace(`/fabrication/${action.payload._id}`);
    } else if (action.type === UPDATE_FABRICATION_ERROR) {
      dispatch(hideSpinner());
      Toastify.addError(action.payload || 'Error to save Fabrication');
    }
  };

export const fabricationMdl = [
  createFabricationFlow,
  updateFabricationFlow,
  getFabricationFlow,
  processFabricationCollection,
];
