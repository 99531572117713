import {
  FETCH_REPORT_FIELDS_SUCCESS,
  FETCH_REPORT_FIELDS_ERROR,
  GET_REPORT_FIELDS,
  updateReportFields,
  showSpinner,
  hideSpinner,
} from '../actions/reportFields';
import { apiRequest } from '../actions/api';

const URL = 'report-fields';

export const getReportFieldsFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_REPORT_FIELDS) {
    dispatch(
      apiRequest(
        'GET',
        URL,
        action.payload,
        FETCH_REPORT_FIELDS_SUCCESS,
        FETCH_REPORT_FIELDS_ERROR,
        ['user'],
      ),
    );
    dispatch(showSpinner());
  }
};

export const processReportFieldsCollection = ({ dispatch }) => (next) => (
  action,
) => {
  next(action);
  if (action.type === FETCH_REPORT_FIELDS_SUCCESS) {
    dispatch(updateReportFields(action.payload));
    dispatch(hideSpinner());
  }
};

export const reportFieldsMdl = [
  getReportFieldsFlow,
  processReportFieldsCollection,
];
