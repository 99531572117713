import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  TextField,
  FormGroup,
  Button,
  FormControlLabel,
  Switch,
  FormControl,
} from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';
import Alert from '../../../components/Alert/index';
import Spinner from '../../../components/Spinner/index';
import {
  Container,
  InputContent,
  ModalTitle,
  ButtonContainer,
  CheckboxContent,
} from './styles';

import {
  getCustomer,
  createCustomer,
  updateCustomer,
} from '../../../redux/actions/customer';

const styles = {
  formButton: {
    margin: 2,
  },
};

function CustomerModal({
  id,
  dispatch,
  customer,
  customerModal,
  handleClose,
  customerSpinner,
}) {
  const [active, setActive] = useState(true);
  const [name, setName] = useState('');
  const [number, setNumber] = useState('');
  const [email, setEmail] = useState('');
  const [document, setDocument] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');

  const [nameError, setNameError] = useState(false);
  const [numberError, setNumberError] = useState(false);

  useEffect(() => {
    if (id !== 'new') {
      dispatch(getCustomer(id));
    }
  }, [id]);

  useEffect(() => {
    if (customer && id !== 'new') {
      setActive(customer.active);
      setName(customer.name);
      setNumber(customer.number);
      setEmail(customer.email);
      setDocument(customer.document);
      setPhone(customer.phone);
      setAddress(customer.address);
    }
  }, [customer]);

  useEffect(() => {
    if (!customerModal) {
      handleClose();
    }
  }, [customerModal]);

  const handleSave = async () => {
    let err = false;
    setNameError(!name);
    setNumberError(!number);
    err = !name || !number;

    if (!err) {
      const response = await Alert();
      if (response && response.confirm) {
        if (id === 'new') {
          dispatch(
            createCustomer({
              active,
              name,
              number,
              email,
              document,
              phone,
              address,
            }),
          );
        } else {
          dispatch(
            updateCustomer({
              _id: id,
              active,
              name,
              number,
              email,
              document,
              phone,
              address,
            }),
          );
        }
      }
    }
  };

  return (
    <>
      {customerSpinner && <Spinner overlay />}
      <Container style={{ opacity: customerSpinner ? 0.5 : 1 }}>
        <ModalTitle>
          {id === 'new' ? 'New' : 'Edit'}
          {' '}
          Customer
        </ModalTitle>
        <FormGroup>
          <CheckboxContent>
            <FormControlLabel
              control={(
                <Switch
                  checked={active}
                  onChange={(e) => setActive(e.target.checked)}
                />
              )}
              label="Active"
              labelPlacement="start"
            />
          </CheckboxContent>
          <InputContent>
            <TextField
              id="outlined-password-input"
              label="Name*"
              type="text"
              error={nameError}
              helperText={nameError && 'Name is required'}
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
            />
          </InputContent>
          <InputContent>
            <TextField
              id="outlined-password-input"
              label="Project Number*"
              type="text"
              error={numberError}
              helperText={numberError && 'Project Number is required'}
              variant="outlined"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              fullWidth
            />
          </InputContent>
          <InputContent>
            <TextField
              id="outlined-password-input"
              label="Document"
              type="text"
              variant="outlined"
              value={document}
              onChange={(e) => setDocument(e.target.value)}
              fullWidth
            />
          </InputContent>
          <InputContent>
            <TextField
              id="outlined-password-input"
              label="E-mail"
              type="text"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
            />
          </InputContent>
          <InputContent>
            <FormControl fullWidth>
              <PhoneInput
                fullWidth
                country="us"
                specialLabel="Phone Number"
                containerStyle={{ width: '100%' }}
                inputStyle={{ width: '100%' }}
                value={phone}
                onChange={(_rawValue, _data, _event, value) => setPhone(value)}
              />
              {/* {phoneNumberError && (
                <FormHelperText>
                  Phone number is required
                </FormHelperText>
              )} */}
            </FormControl>
          </InputContent>
          <InputContent>
            <TextField
              id="outlined-password-input"
              label="Address"
              type="text"
              variant="outlined"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              fullWidth
            />
          </InputContent>
          <ButtonContainer>
            <Button
              color="secondary"
              onClick={handleClose}
              variant="contained"
              style={styles.formButton}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={handleSave}
              variant="contained"
              style={styles.formButton}
            >
              Save
            </Button>
          </ButtonContainer>
        </FormGroup>
      </Container>
    </>
  );
}

export default connect((state) => ({
  customer: state.customer,
  customerModal: state.ui.customerModal,
  customerSpinner: state.ui.customerLoading,
}))(CustomerModal);
