import styled from 'styled-components';
import { Paper as MuiPaper } from '@material-ui/core';

export const Container = styled.div`
  padding: 1rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
`;

export const Paper = styled(MuiPaper)`
  width: 100%;
  margin-bottom: 2rem;
  padding: 1rem;
`;

export const Title = styled.span`
  font-size: 20px;
`;

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Body = styled.div``;
