import {
  FETCH_USERS_SUCCESS, FETCH_USERS_ERROR, GET_USERS, updateUsers, showSpinner, hideSpinner,
} from '../actions/users';
import { apiRequest } from '../actions/api';

const URL = 'users';

export const getUsersFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_USERS) {
    dispatch(apiRequest('GET', URL, action.payload, FETCH_USERS_SUCCESS, FETCH_USERS_ERROR));
    dispatch(showSpinner());
  }
};

export const processUsersCollection = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_USERS_SUCCESS) {
    dispatch(updateUsers(action.payload));
    dispatch(hideSpinner());
  }
};

export const usersMdl = [getUsersFlow, processUsersCollection];
