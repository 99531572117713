export const GET_INSPECTION = '[inspection] GET';
export const UPDATE_INSPECTION = '[inspection] UPDATE';
export const CREATE_INSPECTION = '[inspection] CREATE';
export const DELETE_INSPECTION = '[inspection] DELETE';
export const UPDATE_OBJECT_INSPECTION = '[inspection] UPDATE OBJECT INSPECTION';
// UI
export const SHOW_INSPECTION_MODAL = '[inspection] SHOW MODAL';
export const HIDE_INSPECTION_MODAL = '[inspection] HIDE MODAL';
// Spinner
export const UPDATE_INSPECTION_SUCCESS = '[inspection] Update success';
export const UPDATE_INSPECTION_ERROR = '[inspection] Update Error';
export const FETCH_INSPECTION_SUCCESS = '[inspection] Fetch success';
export const FETCH_INSPECTION_ERROR = '[inspection] Fetch Error';
export const SHOW_INSPECTION_SPINNER = '[inspection - ui] show spinner';
export const HIDE_INSPECTION_SPINNER = '[inspection - ui] hide spinner';

export const getInspection = (id) => ({
  type: GET_INSPECTION,
  payload: { _id: id },
});

export const createInspection = (data) => ({
  type: CREATE_INSPECTION,
  payload: data,
});

export const updateInspection = (data) => ({
  type: UPDATE_INSPECTION,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_INSPECTION_MODAL,
});

export const hideModal = () => ({
  type: HIDE_INSPECTION_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_INSPECTION_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_INSPECTION_SPINNER,
});

export const updateObjectInspection = (data) => ({
  type: UPDATE_OBJECT_INSPECTION,
  payload: data,
});
