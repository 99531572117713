export const GET_REPORT_FIELDS = '[report fields] GET';
export const FETCH_REPORT_FIELDS_SUCCESS = '[report fields] Fetch success';
export const FETCH_REPORT_FIELDS_ERROR = '[report fields] Fetch Error';
export const UPDATE_REPORT_FIELDS = '[report fields] Update';
export const SHOW_REPORT_FIELDS_SPINNER = '[report fields - ui] show spinner';
export const HIDE_REPORT_FIELDS_SPINNER = '[report fields - ui] hide spinner';

export const getReportFields = (filter) => ({
  type: GET_REPORT_FIELDS,
  payload: filter,
});

export const updateReportFields = (data) => ({
  type: UPDATE_REPORT_FIELDS,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_REPORT_FIELDS_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_REPORT_FIELDS_SPINNER,
});
