import {
  SET_CUSTOMER_MM_FILTER,
  CLEAR_CUSTOMER_MM_FILTER,
} from '../actions/customerMMFilter';

const initialState = {
  mm_number: '',
  short_description: '',
  long_description: '',
  equipment_type: '',
  modifier: '',
  size: '',
  weight: '',
  grade: '',
  connection_1: '',
  connection_1_size: '',
  connection_1_weight: '',
  connection_1_grade: '',
  connection_2: '',
  connection_2_size: '',
  connection_2_weight: '',
  connection_2_grade: '',
  connection_configuration: '',
  nominal_wall: '',
  drift_diameter: '',
  range: '',
  pipe_type: '',
  manufacturer: '',
  manufacturer_pn: '',
  inspection_criteria: '',
  pressure_rating: '',
  primary_uom: '',
  secondary_uom: '',
  action_type: '',
};

export function customerMMFilterReducer(
  customerMMFilter = initialState,
  action,
) {
  switch (action.type) {
    case SET_CUSTOMER_MM_FILTER:
      return { ...customerMMFilter, ...action.payload };
    case CLEAR_CUSTOMER_MM_FILTER:
      return initialState;
    default:
      return customerMMFilter;
  }
}
