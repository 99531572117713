export const GET_MRP_NOTIFICATION_SETUP = '[MRP Notification Setup] GET';
export const UPDATE_MRP_NOTIFICATION_SETUP = '[MRP Notification Setup] UPDATE';
export const UPDATE_OBJECT_MRP_NOTIFICATION_SETUP = '[MRP Notification Setup] UPDATE OBJECT MRP NOTIFICATION SETUP';
// Spinner
export const UPDATE_MRP_NOTIFICATION_SETUP_SUCCESS = '[MRP Notification Setup] Update success';
export const UPDATE_MRP_NOTIFICATION_SETUP_ERROR = '[MRP Notification Setup] Update Error';
export const FETCH_MRP_NOTIFICATION_SETUP_SUCCESS = '[MRP Notification Setup] Fetch success';
export const FETCH_MRP_NOTIFICATION_SETUP_ERROR = '[MRP Notification Setup] Fetch Error';
export const SHOW_MRP_NOTIFICATION_SETUP_SPINNER = '[MRP Notification Setup - ui] show spinner';
export const HIDE_MRP_NOTIFICATION_SETUP_SPINNER = '[MRP Notification Setup - ui] hide spinner';

export const getMRPNotificationSetup = () => ({
  type: GET_MRP_NOTIFICATION_SETUP,
  payload: {},
});

export const updateMRPNotificationSetup = (data) => ({
  type: UPDATE_MRP_NOTIFICATION_SETUP,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_MRP_NOTIFICATION_SETUP_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_MRP_NOTIFICATION_SETUP_SPINNER,
});

export const updateObjectMRPNotificationSetup = (data) => ({
  type: UPDATE_OBJECT_MRP_NOTIFICATION_SETUP,
  payload: data,
});
