import {
  GET_BUILD_OF_MATERIAL,
  UPDATE_BUILD_OF_MATERIAL,
  UPDATE_OBJECT_BUILD_OF_MATERIAL,
  CREATE_BUILD_OF_MATERIAL,
  DELETE_BUILD_OF_MATERIAL,
} from '../actions/buildOfMaterial';

export function buildOfMaterialReducer(buildOfMaterial = {}, action) {
  switch (action.type) {
    case GET_BUILD_OF_MATERIAL:
      return action.payload;
    case UPDATE_BUILD_OF_MATERIAL:
      return action.payload;
    case CREATE_BUILD_OF_MATERIAL:
      return action.payload;
    case DELETE_BUILD_OF_MATERIAL:
      return action.payload;
    case UPDATE_OBJECT_BUILD_OF_MATERIAL:
      return action.payload;

    default:
      return buildOfMaterial;
  }
}
