import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';

export const Container = styled(Paper)`
  border-radius: 5px;
  margin-bottom: 20px;
  .MuiExpansionPanelSummary-root.Mui-expanded {
    min-height: 35px !important;
  }

  .MuiExpansionPanelDetails-root {
    padding: 0px;
  }
  .MuiList-padding {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .MuiExpansionPanelSummary-root:hover: {
    cursor: default !important;
  }

  .MuiButtonBase-root {
    cursor: ${(props) => (props.cursorType ? 'cursor' : 'default')};
  }

  .MuiListItem-root.Mui-disabled {
    opacity: 1;
  }  
`;

export const TitleContent = styled.div`
  margin: 0;
  width: 85%;
  min-height: 25px;
`;

export const CreateButton = styled.span`
  margin-left: 20px;
  background: var(--secondary-gray-color);
  border: none;
  font-size: 0.875rem;
  font-weight: 400;
  border-radius: 15px;
  padding: 5px 10px 5px 10px;
  cursor: pointer;
`;

export const GroupCreateButton = styled.span`
  margin-left: 20px;
  background: var(--primary-gray-color);
  border: none;
  font-size: 0.875rem;
  font-weight: 400;
  border-radius: 15px;
  padding: 5px 10px 5px 10px;
  cursor: pointer;
`;

export const EditButton = styled.div`
  position: absolute;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  right: 30px;
  top: 0px;
  cursor: pointer;
`;

export const DeleteButton = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const InputContainer = styled.div`
  padding: 5px 10px 10px 10px;
`;

export const ButtonContainer = styled.div`
  padding: 15px 2px 5px 2px;
`;
