import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Button, Grid, MenuItem } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Alert from '../../../components/Alert';
import Spinner from '../../../components/ButtonSpinner';
import TagsField from '../../../components/TagsField';
import ModalContainer from '../../../components/ModalContainer';
import { Container, InputContent, ModalTitle, ButtonContainer } from './styles';
import { moveEquipment } from '../../../services/Movement';
import Toastify from '../../../utils/Toastify';

import { getSLOCS } from '../../../redux/actions/slocs';

const styles = {
  formButton: {
    margin: 2,
  },
};

const MoveModal = ({ open, onClose, equipment }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    tags: [],
    note: '',
    sloc: undefined,
    warehouse: undefined,
    rack: undefined,
    bin: undefined,
  });
  const [autocompleteInputs, setAutocompleteInputs] = useState({
    sloc: '',
    warehouse: '',
    rack: '',
    bin: '',
  });
  const { tags, note, sloc, warehouse, rack, bin } = data;

  const SLOCs = useSelector((state) => state.slocs?.docs || state.slocs || []);
  const user = useSelector((state) => state.loggedUser?._id);

  useEffect(() => {
    dispatch(getSLOCS({ listAll: true }));
  }, []);

  const handleEdit = (e) => {
    const { name, value } = e.target;
    setData((currentData) => ({ ...currentData, [name]: value }));
  };

  const handleAutoCompleteChange = (name) => (_, value) => {
    const newFields = { [name]: value };
    const inputs = {};
    if (name !== 'bin') {
      newFields.bin = undefined;
      inputs.bin = '';
    }
    if (name !== 'bin' && name !== 'rack') {
      newFields.rack = undefined;
      inputs.rack = '';
    }
    if (name === 'sloc') {
      newFields.warehouse = undefined;
      inputs.warehouse = '';
    }
    setData((currentData) => ({ ...currentData, ...newFields }));
    setAutocompleteInputs((currentData) => ({ ...currentData, ...inputs }));
  };

  const handleAutoCompleteInputChange = (name) => (_, value) => {
    setAutocompleteInputs((currentData) => ({ ...currentData, [name]: value }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const response = await Alert();
    if (!response?.confirm) return;
    setLoading(true);
    moveEquipment({
      equipment,
      sloc: sloc?._id,
      bin: bin?._id,
      tags,
      note,
      user,
    }).then((response) => {
      setLoading(false);
      if (response.ok) {
        Toastify.addSuccess('Equipment moved successfully');
        onClose();
      } else {
        Toastify.addError(response.message);
      }
    });
  };

  const getSLOCLabel = (item) => `${item.sloc_id} - ${item.sloc_name}`;
  const getLocationLabel = (item) => item.name;

  return (
    <ModalContainer open={open} handleClose={onClose}>
      <Container>
        <form onSubmit={handleSave}>
          <ModalTitle>Move Materials</ModalTitle>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <InputContent>
                <Autocomplete
                  options={SLOCs}
                  autoHighlight
                  getOptionLabel={getSLOCLabel}
                  value={sloc}
                  onChange={handleAutoCompleteChange('sloc')}
                  inputValue={autocompleteInputs.sloc}
                  onInputChange={handleAutoCompleteInputChange('sloc')}
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      label="SLOC"
                      required
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </InputContent>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputContent>
                <Autocomplete
                  disabled={!sloc}
                  options={sloc?.warehouses || []}
                  autoHighlight
                  getOptionLabel={getLocationLabel}
                  value={warehouse}
                  onChange={handleAutoCompleteChange('warehouse')}
                  inputValue={autocompleteInputs.warehouse}
                  onInputChange={handleAutoCompleteInputChange('warehouse')}
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      label="Warehouse"
                      required
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </InputContent>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputContent>
                <Autocomplete
                  disabled={!warehouse}
                  options={warehouse?.racks || []}
                  autoHighlight
                  getOptionLabel={getLocationLabel}
                  value={rack}
                  onChange={handleAutoCompleteChange('rack')}
                  inputValue={autocompleteInputs.rack}
                  onInputChange={handleAutoCompleteInputChange('rack')}
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      label="Rack"
                      required
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </InputContent>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputContent>
                <Autocomplete
                  disabled={!rack}
                  options={rack?.bins || []}
                  autoHighlight
                  getOptionLabel={getLocationLabel}
                  value={bin}
                  onChange={handleAutoCompleteChange('bin')}
                  inputValue={autocompleteInputs.bin}
                  onInputChange={handleAutoCompleteInputChange('bin')}
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      label="Bin"
                      required
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </InputContent>
            </Grid>

            <Grid item xs={12}>
              <InputContent>
                <TagsField
                  value={tags}
                  onChange={handleEdit}
                  name="tags"
                  label="Tags"
                />
              </InputContent>
            </Grid>
            <Grid item xs={12}>
              <InputContent>
                <TextField
                  value={note}
                  onChange={handleEdit}
                  name="note"
                  label="Note"
                  multiline
                  fullWidth
                  variant="outlined"
                />
              </InputContent>
            </Grid>
          </Grid>
          <ButtonContainer>
            <Button
              color="secondary"
              onClick={onClose}
              variant="contained"
              style={styles.formButton}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              style={styles.formButton}
              type="submit"
              disabled={loading}
            >
              {loading ? <Spinner /> : 'Save'}
            </Button>
          </ButtonContainer>
        </form>
      </Container>
    </ModalContainer>
  );
};

export default MoveModal;
