import React from 'react';

import { connect } from 'react-redux';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import AddRounded from '@material-ui/icons/AddRounded';
import EditRounded from '@material-ui/icons/EditRounded';
import TableContainer from '@material-ui/core/TableContainer';
import format from 'date-fns/format';

import { showModal, hideModal } from '../../../redux/actions/vpas';
import { getVPASs } from '../../../redux/actions/vpass';
import ModalContainer from '../../../components/ModalContainer';
import Spinner from '../../../components/Spinner';
import VPASModal from '../VPAS';
import { tableStyles } from '../../../styles/tableStyles';

function JobVPAS({ vpassLoading, vpass, vpasModal, dispatch, id }) {
  const [VPASsFormated, setVPASsFormated] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [limit, setLimit] = React.useState(50);
  const [page, setPage] = React.useState(0);
  const [modalContentId, setModalContentId] = React.useState('new');
  const classes = tableStyles();

  React.useEffect(() => {
    dispatch(getVPASs({ page: page + 1, limit, filter: { work_order: id } }));
  }, [page, limit, id]);

  React.useEffect(() => {
    if (vpass.docs) {
      setVPASsFormated(vpass.docs);
      setTotal(vpass.totalDocs);
    }
  }, [vpass]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  function handleOpenVPASModal(id) {
    function handler() {
      setModalContentId(id);
      dispatch(showModal());
    }
    return handler;
  }

  const handleCloseVPASModal = () => {
    dispatch(hideModal());
  };

  return (
    <>
      <ModalContainer open={vpasModal} handleClose={handleCloseVPASModal}>
        <VPASModal
          id={modalContentId}
          workOrderId={id}
          handleClose={handleCloseVPASModal}
        />
      </ModalContainer>
      <Toolbar className={classes.toolBar}>
        <span>
          <Typography
            className={classes.title}
            variant="h6"
            id="tableTitle"
            component="span"
          >
            V-PAS
          </Typography>
        </span>
        <span>
          <IconButton
            className={classes.buttons}
            onClick={handleOpenVPASModal('new')}
          >
            <AddRounded />
          </IconButton>
        </span>
      </Toolbar>
      <TableContainer className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Employee</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>VPAS Vendor</TableCell>
              <TableCell>Coordinator</TableCell>
              <TableCell>Activity</TableCell>
              <TableCell>Down Time</TableCell>
              <TableCell>Down Time (HR)</TableCell>
              <TableCell>Stop Work</TableCell>
              <TableCell>Near Miss</TableCell>
              <TableCell>NCR</TableCell>
              <TableCell>Category of Concern</TableCell>
              <TableCell>Descrepancy</TableCell>
              <TableCell>Action</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {vpassLoading && (
              <TableRow className={classes.row}>
                <TableCell colSpan="14" className={classes.cell}>
                  <Spinner parent="Table" />
                </TableCell>
              </TableRow>
            )}
            {!vpassLoading &&
              VPASsFormated.map((vpas) => (
                <TableRow className={classes.row}>
                  <TableCell className={classes.cell}>
                    {vpas.employee}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {format(new Date(vpas.date), 'MM-dd-yyyy')}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {vpas.vendor?.vendor_name}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {vpas.coordinator}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {vpas.activity}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {vpas.down_time}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {vpas.down_time_hr}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {vpas.stop_work}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {vpas.near_miss}
                  </TableCell>
                  <TableCell className={classes.cell}>{vpas.ncr}</TableCell>
                  <TableCell className={classes.cell}>
                    {vpas.category_of_concern}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {vpas.descrepancy}
                  </TableCell>
                  <TableCell className={classes.cell}>{vpas.action}</TableCell>
                  <TableCell className={classes.cell}>
                    <IconButton onClick={handleOpenVPASModal(vpas._id)}>
                      <EditRounded />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[50, 100, 200, 500, 1000]}
        component="div"
        count={total}
        rowsPerPage={limit}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
}

function mapStateToProps(state) {
  return {
    vpassLoading: state.ui.vpassLoading,
    vpass: state.vpass,
    vpasModal: state.ui.vpasModal,
  };
}

export default connect(mapStateToProps)(JobVPAS);
