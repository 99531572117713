import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { StylesProvider } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import Router from './Routes';
import { store } from './redux/store';

ReactDOM.render(
  <React.StrictMode>
    <StylesProvider injectFirst>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Provider store={store}>
          <Router />
        </Provider>
      </MuiPickersUtilsProvider>
    </StylesProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
