import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionActions';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setRefineFilter } from '../../redux/actions/refineFilter';
import {
  ButtonContainer,
  Container,
  InputContainer,
  TitleContent,
} from './styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 600,
    position: 'relative',
    width: '100%',
  },
  button: {
    fontSize: 16,
    color: '#737373',
  },
  listItem: {
    width: '100%',
  },
  listAccordion: {
    padding: '0px 0px 0px 25px',
    height: 39,
    marginTop: -25,
  },
  questionLabel: {
    paddingTop: 2,
    paddingBottom: 2,
    fontSize: '0.8rem',
  },
  listItemQuestion: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderTop: '1px solid #ccc',
  },
  listItemPadding: {
    paddingTop: 4,
    paddingBottom: 4,
  },
  expansionHeader: {},
  questionLabelEmpty: {
    color: '#808080',
    fontSize: '0.8rem',
  },
}));

function InventoryRefineFilter({ dispatch, refineFilter }) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  //  Filters
  const [range, setRange] = useState('');
  const [RBW, setRBW] = useState('');
  const [manufacturer, setManufacturer] = useState('');
  const [facilityReferenceID, setFacilityReferenceID] = useState('');
  const [equalityWorkOrderNumber, setEqualityWorkOrderNumber] = useState('');
  // const [tagNo, setTagNo] = useState('');

  const handleSearch = () => {
    const filter = {
      range,
      RBW,
      manufacturer,
      facility_reference_id: facilityReferenceID,
      equality_work_order_number: equalityWorkOrderNumber,
    };

    dispatch(setRefineFilter(filter));
  };

  const clearFilter = () => {
    setRange('');
    setRBW('');
    setManufacturer('');
    setFacilityReferenceID('');
    setEqualityWorkOrderNumber('');
    dispatch(setRefineFilter({}));
  };

  useEffect(() => {
    if (refineFilter) {
      setRange(refineFilter.range);
      setRBW(refineFilter.RBW);
      setManufacturer(refineFilter.manufacturer);
      setFacilityReferenceID(refineFilter.facility_reference_id);
      setEqualityWorkOrderNumber(refineFilter.equality_work_order_number);
    }
    handleSearch();
  }, []);

  return (
    <Container>
      <Accordion expanded={expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          classes={{ content: classes.expansionHeader }}
          onClick={() => setExpanded(!expanded)}
        >
          <div className={classes.heading}>
            <TitleContent>Refine Filter</TitleContent>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container style={{ padding: '0px 10px 10px 10px' }}>
            <Grid item lg={2} md={3} sm={12} xs={12}>
              <InputContainer>
                <TextField
                  id="standard-basic"
                  value={range}
                  onChange={(e) => setRange(e.target.value)}
                  size="small"
                  label="Range"
                  fullWidth
                />
              </InputContainer>
            </Grid>
            <Grid item lg={2} md={3} sm={12} xs={12}>
              <InputContainer>
                <TextField
                  id="standard-basic"
                  value={RBW}
                  onChange={(e) => setRBW(e.target.value)}
                  size="small"
                  label="RBW Band"
                  fullWidth
                />
              </InputContainer>
            </Grid>
            <Grid item lg={2} md={3} sm={12} xs={12}>
              <InputContainer>
                <TextField
                  id="standard-basic"
                  value={manufacturer}
                  onChange={(e) => setManufacturer(e.target.value)}
                  size="small"
                  label="Manufacturer"
                  fullWidth
                />
              </InputContainer>
            </Grid>
            <Grid item lg={2} md={3} sm={12} xs={12}>
              <InputContainer>
                <TextField
                  id="standard-basic"
                  value={facilityReferenceID}
                  onChange={(e) => setFacilityReferenceID(e.target.value)}
                  size="small"
                  label="Facility Reference ID"
                  fullWidth
                />
              </InputContainer>
            </Grid>
            <Grid item lg={2} md={3} sm={12} xs={12}>
              <InputContainer>
                <TextField
                  id="standard-basic"
                  value={equalityWorkOrderNumber}
                  onChange={(e) => setEqualityWorkOrderNumber(e.target.value)}
                  size="small"
                  label="Rina Work Order No"
                  fullWidth
                />
              </InputContainer>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} align="right">
              <ButtonContainer>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSearch}
                  style={{ marginRight: 10 }}
                >
                  Filter
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={clearFilter}
                >
                  Clear
                </Button>
              </ButtonContainer>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  movimentation: state.movimentation,
  refineFilter: state.refineFilter,
});

export default connect(mapStateToProps)(InventoryRefineFilter);
