import React, {
  useCallback, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Box, List, ListItem, ListItemText, makeStyles, Tooltip, useTheme, Paper, ListSubheader, Fade, Collapse,
} from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Link, useHistory } from 'react-router-dom';
import { ListIcon } from './styles';
import { navigation } from '../../structs/navigation';

const drawerWidth = 240;

const styles = {
  iconStyle: {
    marginLeft: 25,
    cursor: 'pointer',
    color: '#717478',
  },
  dropdownIconStyle: {
    color: '#fff',
    position: 'absolute',
    top: 20,
    left: 20,
    cursor: 'pointer',
  },
  drawerIconStyle: {
    color: '#fff',
  },
  logoutIconStyle: {
    marginLeft: 10,
    height: 25,
    width: 25,
  },
  linkFont: {
    color: '#fff',
    textDecoration: 'none',
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  subMenu: {
    backgroundColor: '#2c2c32',
    color: 'white',
    paddingTop: 64,
    width: ({ isSubMenu }) => (isSubMenu ? drawerWidth + 50 : 0),
    overflowX: ({ isSubMenu }) => (isSubMenu ? undefined : 'hidden'),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    backgroundColor: '#2c2c32',
    color: 'white',
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    backgroundColor: ({ isSubMenu }) => (isSubMenu ? '#27272c' : '#2c2c32'),
    color: 'white',
    width: theme.spacing(9) + 1,
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      overflowX: 'auto',
    },
  },
  drawerClose: {
    backgroundColor: ({ isSubMenu }) => (isSubMenu ? '#27272c' : '#2c2c32'),
    color: 'white',
    transition: theme.transitions.create(['width', 'backgroundColor'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  toolbarIcon: {
    color: '#fff',
  },
  contentOpen: {
    width: `calc(100% - ${theme.spacing(9) + 1}px)`,
    height: 'calc(100vh - 98px)',
    overflow: 'auto',
    marginTop: 65,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  contentClose: {
    width: '100%',
    height: 'calc(100vh - 98px)',
    overflow: 'auto',
    marginTop: 65,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${theme.spacing(9) + 1}px)`,
    },
  },
  tooltip: {
    fontSize: 12,
  },
  scrollableMenu: {
    scrollbarColor: '#555 #aaa',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: 6,
    },
    '&::-webkit-scrollbar-track': {
      background: '#aaa',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#555',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#444',
    },
  },
}));

const menuOptions = navigation;

const NavContext = React.createContext();

export const useNav = () => {
  const context = useContext(NavContext);
  if (!context) throw new Error('Nav provider required');
  return context;
};

export function NavProvider({ children }) {
  const [open, setOpen] = React.useState(false);

  const props = useMemo(() => ({
    state: open,
    open: () => {
      setOpen(true);
    },
    close: () => {
      setOpen(false);
    },
    toggle: () => {
      setOpen((old) => !old);
    },
  }), [open, setOpen]);

  return (
    <NavContext.Provider value={props}>
      {children}
    </NavContext.Provider>
  );
}

function Nav() {
  const navRef = useRef();
  const nav = useNav();
  const theme = useTheme();
  const history = useHistory();
  const [subMenu, setSubMenu] = useState(null);
  const [currentIndexMenu, setCurrentIndexMenu] = useState();
  const [currentIndexSubMenu, setCurrentIndexSubMenu] = useState();
  const classes = useStyles({ isSubMenu: !!subMenu });

  const handleDrawerClose = useCallback(() => {
    nav.close();
    setCurrentIndexMenu(undefined);
    setCurrentIndexSubMenu(undefined);
  }, []);

  useEffect(() => {
    const evt = (event) => {
      if (!navRef.current.contains(event.relatedTarget)) {
        setSubMenu(null);
        setCurrentIndexMenu(undefined);
        setCurrentIndexSubMenu(undefined);
      }
    };
    document.addEventListener('mouseout', evt);
    return () => {
      document.removeEventListener('mouseout', evt);
    };
  }, []);

  return (
    <Box display="flex" ref={navRef}>
      <Drawer
        open={nav.state}
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawer]: true,
          [classes.drawerOpen]: nav.state,
          [classes.drawerClose]: !nav.state,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: nav.state,
            [classes.drawerClose]: !nav.state,
          }),
          paperAnchorLeft: classes.scrollableMenu,
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon className={classes.toolbarIcon} />
            ) : (
              <ChevronLeftIcon className={classes.toolbarIcon} />
            )}
          </IconButton>
        </div>
        <List>
          {menuOptions.map((opt, key) => (
            <>
              {
                opt.component ? opt.component : (
                  <Tooltip
                    key={opt.label}
                    title={opt.label}
                    placement="right"
                    arrow
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <ListItem
                      component={opt.path ? Link : undefined}
                      to={opt.path}
                      selected={currentIndexMenu === key}
                      button
                      style={{ width: '100%' }}
                      onClick={(event) => {
                        event.preventDefault();
                        if (opt.children) {
                          if (currentIndexMenu === key) {
                            setSubMenu(null);
                            setCurrentIndexMenu(undefined);
                            setCurrentIndexSubMenu(undefined);
                            nav.open();
                          } else {
                            setCurrentIndexMenu(key);
                            setSubMenu(opt);
                            nav.close();
                          }
                        } else {
                          setSubMenu(null);
                          history.push(opt.path);
                        }
                      }}
                    >
                      <ListIcon style={styles.drawerIconStyle}>
                        {opt.icon}
                      </ListIcon>
                      <ListItemText primary={opt.label} />
                    </ListItem>
                  </Tooltip>
                )
              }
            </>
          ))}
        </List>
      </Drawer>
      <Paper square className={[classes.subMenu]}>
        <Fade in={subMenu} mountOnEnter unmountOnExit>
          <div>
            {
              !!subMenu && (
                <List>
                  <ListSubheader style={{ color: 'white' }}>
                    {subMenu.label}
                  </ListSubheader>
                  {!!subMenu && (subMenu.children || []).map((opt, subIndex) => (
                    <>
                      <Tooltip
                        key={opt.label}
                        title={opt.label}
                        placement="right"
                        arrow
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <ListItem
                          component={Link}
                          to={opt.path}
                          button
                          style={{ width: '100%' }}
                          onClick={(event) => {
                            event.preventDefault();
                            if (opt.children) {
                              if (currentIndexSubMenu === subIndex) {
                                setCurrentIndexSubMenu(undefined);
                              } else {
                                setCurrentIndexSubMenu(subIndex);
                              }
                            } else {
                              setSubMenu(null);
                              setCurrentIndexMenu(undefined);
                              setCurrentIndexSubMenu(undefined);
                              history.push(opt.path);
                            }
                          }}
                        >
                          <ListIcon style={styles.drawerIconStyle}>
                            {opt.icon}
                          </ListIcon>
                          <ListItemText primary={opt.label} />
                          {currentIndexSubMenu === subIndex ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                      </Tooltip>
                      <Collapse in={currentIndexSubMenu === subIndex}>
                        {
                          (opt.children || []).map((optsub) => (
                            <ListItem
                              component={Link}
                              to={optsub.path}
                              button
                              style={{ width: '100%' }}
                              onClick={(event) => {
                                event.preventDefault();
                                setSubMenu(null);
                                setCurrentIndexMenu(undefined);
                                setCurrentIndexSubMenu(undefined);
                                history.push(optsub.path);
                              }}
                            >
                              <ListIcon style={{ ...styles.drawerIconStyle, marginLeft: 15 }}>
                                {optsub.icon}
                              </ListIcon>
                              <ListItemText primary={optsub.label} />
                            </ListItem>
                          ))
                        }
                      </Collapse>
                    </>
                  ))}
                </List>
              )
            }
          </div>
        </Fade>
      </Paper>
    </Box>
  );
}

export default Nav;
