import React, { useState, useEffect } from 'react';
import formatDate from 'date-fns/format';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import axios from 'axios';
import {
  Accordion,
  AccordionSummary,
  Grid,
  TextField,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  Select,
  Input,
  Chip,
  Switch,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Tooltip,
  AppBar,
  Tab,
} from '@material-ui/core';
import {
  Autocomplete, TabContext, TabList, TabPanel,
} from '@material-ui/lab';
import {
  Search as SearchIcon,
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';
import {
  Container,
  TitleContent,
  InputContainer,
  ButtonContainer,
  AccordionDetails,
} from './styles';
import { useDebounce } from '../../hooks';
import { apiUrl, CUSTOMER_KEY } from '../../config/consts';
import { getMovimentations } from '../../redux/actions/movimentations';
import { setMovimentationsFilter } from '../../redux/actions/movimentationsFilter';
import { getSearchHistory, SearchFields } from '../../services/SearchStorage';
import FilterCustomizer from '../../pages/Modals/FilterCustomizer';
import ModalContainer from '../ModalContainer/index';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 600,
    position: 'relative',
    width: '100%',
  },
  button: {
    fontSize: 16,
    color: '#737373',
  },
  listItem: {
    width: '100%',
  },
  listAccordion: {
    padding: '0px 0px 0px 25px',
    height: 39,
    marginTop: -25,
  },
  questionLabel: {
    paddingTop: 2,
    paddingBottom: 2,
    fontSize: '0.8rem',
  },
  listItemQuestion: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderTop: '1px solid #ccc',
  },
  listItemPadding: {
    paddingTop: 4,
    paddingBottom: 4,
  },
  expansionHeader: {},
  questionLabelEmpty: {
    color: '#808080',
    fontSize: '0.8rem',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    fontSize: 10,
    height: 20,
    '& > span': { paddingLeft: 5, paddingRight: 5 },
  },
  formControl: {
    width: '100%',
    padding: 10,
    paddingTop: 0,
    '& > label': { paddingLeft: 10 },
  },
}));

const initialFilterState = {
  equipment_name: '',
  equipment_type: '',
  modifier: '',
  size: '',
  weight: '',
  grade: '',
  connection_1: '',
  connection_2: '',
  material_layout: '',
  SLOC: '',
  project: '',
  charge_code: '',
  supplier: '',
  manufacturer: '',
  bin_location: '',
  rack_location: '',
  warehouse_location: '',
  department: '',
  includeConsumed: false,
  status: [],
  in_inventory: true,
  individual_item_id: '',
};

function InventoryFilter({
  dispatch,
  projects,
  slocs,
  originalCharges,
  movimentations,
  movimentationsFilter,
  customers,
  consolidatedView,
  orderBy,
  order,
  filterLayout,
}) {
  const customer = localStorage.getItem(CUSTOMER_KEY);
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  //  Filters
  const [filterData, setFilterData] = useState(initialFilterState);
  const [selectedTab, setSelectedTab] = React.useState('1');
  const [searchHistory, setSearchHistory] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectData, setSelectData] = useState({});

  const {
    equipment_name,
    equipment_type,
    modifier,
    grade,
    connection_1,
    connection_1_size,
    connection_1_weight,
    connection_1_grade,
    connection_2,
    connection_2_size,
    connection_2_weight,
    connection_2_grade,
    connection_configuration,
    includeConsumed,
  } = filterData;

  const debouncedEquipmentName = useDebounce(equipment_name, 800);
  const debouncedEquipmentType = useDebounce(equipment_type, 800);
  const debouncedModifier = useDebounce(modifier, 800);
  const debouncedGrade = useDebounce(grade, 800);
  const debouncedConnection1 = useDebounce(connection_1, 800);
  const debouncedConnection1Size = useDebounce(connection_1_size, 800);
  const debouncedConnection1Weight = useDebounce(connection_1_weight, 800);
  const debouncedConnection1Grade = useDebounce(connection_1_grade, 800);
  const debouncedConnection2 = useDebounce(connection_2, 800);
  const debouncedConnection2Size = useDebounce(connection_2_size, 800);
  const debouncedConnection2Weight = useDebounce(connection_2_weight, 800);
  const debouncedConnection2Grade = useDebounce(connection_2_grade, 800);
  const debouncedConnectionConfiguration = useDebounce(
    connection_configuration,
    800,
  );

  useEffect(() => {
    if (!filterLayout.length) return;
    function sameRoute(field) {
      return function (currentField) {
        return currentField.route === field.route;
      };
    }
    const objectIdFields = filterLayout.reduce((currentFields, field) => (field.type === 'ObjectId' && !currentFields.some(sameRoute(field))
      ? [...currentFields, field]
      : currentFields), []);

    const requestPromises = objectIdFields.map(async (field) => ({
      ...field,
      result: await axios
        .get(`${apiUrl}${field.route}`, {
          params: {
            customer,
            listAll: true,
          },
        })
        .then((result) => result.data)
        .catch((e) => []),
    }));
    Promise.all(requestPromises).then((response) => {
      const tempSelectData = {};
      response.forEach((field) => (tempSelectData[field.ref] = field.result));
      setSelectData((currentData) => ({ ...currentData, ...tempSelectData }));
    });
  }, [filterLayout]);

  useEffect(() => {
    axios
      .get(`${apiUrl}material_masters`, {
        params: {
          customer,
          listAll: true,
          short_description: debouncedEquipmentName,
          equipment_type: debouncedEquipmentType,
          modifier: debouncedModifier,
          grade: debouncedGrade,
          connection_1: debouncedConnection1,
          connection_1_size: debouncedConnection1Size,
          connection_1_weight: debouncedConnection1Weight,
          connection_1_grade: debouncedConnection1Grade,
          connection_2: debouncedConnection2,
          connection_2_size: debouncedConnection2Size,
          connection_2_weight: debouncedConnection2Weight,
          connection_2_grade: debouncedConnection2Grade,
          connection_configuration: debouncedConnectionConfiguration,
        },
      })
      .then((response) => {
        setSelectData((currentData) => ({
          ...currentData,
          material_masters: response.data,
        }));
      });
  }, [
    debouncedEquipmentName,
    debouncedEquipmentType,
    debouncedModifier,
    debouncedGrade,
    debouncedConnection1,
    debouncedConnection1Size,
    debouncedConnection1Weight,
    debouncedConnection1Grade,
    debouncedConnection2,
    debouncedConnection2Size,
    debouncedConnection2Weight,
    debouncedConnection2Grade,
    debouncedConnectionConfiguration,
  ]);

  useEffect(() => {
    const history = getSearchHistory();
    setSearchHistory(Array.isArray(history) ? history : []);
  }, [movimentations.docs]);

  const handleChangeTab = (_, newValue) => {
    setSelectedTab(newValue);
  };

  const handleSearch = () => {
    const filter = {
      consolidated: consolidatedView,
      ...movimentationsFilter,
      customer,
      ...filterData,
      status: filterData.status.length > 0 ? filterData.status : null,
      labels: {
        customer: customers?.docs?.find(({ _id }) => _id === customer)?.name,
        project: projects?.docs?.find(({ _id }) => _id === filterData.project)
          ?.name,
        SLOC: slocs?.docs?.find(({ _id }) => _id === filterData.SLOC)
          ?.sloc_name,
      },
    };

    // eslint-disable-next-line radix
    dispatch(
      getMovimentations({
        page: Number.parseInt(movimentations.page) || 1,
        limit: Number.parseInt(movimentations.limit) || 50,
        filter,
        orderBy,
        order,
      }),
    );
    setExpanded(false);
  };

  const clearFilter = () => {
    setFilterData(initialFilterState);
    dispatch(setMovimentationsFilter({}));
    dispatch(
      getMovimentations({
        page: 1,
        limit: 50,
        filter: { consolidated: consolidatedView },
        orderBy,
        order,
      }),
    );
    setExpanded(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    if (movimentationsFilter) {
      setFilterData({
        ...movimentationsFilter,
        status: movimentationsFilter.status || [],
      });
    }
  }, []);

  useEffect(() => {
    if (!filterData.searchOnUseEffect) return;
    handleSearch();
    setFilterData((currentState) => ({
      ...currentState,
      searchOnUseEffect: false,
    }));
  }, [filterData]);

  function handleHistorySearch(entry = []) {
    return function () {
      setSelectedTab('1');
      const newFilterData = {};
      const fieldsToAddToLayout = [];
      entry.fields.forEach(({ key, value }) => {
        switch (key) {
          case 'project':
            newFilterData[key] = projects?.docs?.find(
              ({ _id }) => _id === value,
            );
            break;
          case 'SLOC':
            newFilterData[key] = slocs?.docs?.find(({ _id }) => _id === value);
            break;
          case 'charge_code':
            newFilterData[key] = originalCharges?.docs?.find(
              ({ original_charge_code }) => original_charge_code === value,
            );
            break;
          case 'status':
            newFilterData[key] = value || [];
            break;
          default:
            newFilterData[key] = value;
            break;
        }
      });
      setFilterData({
        ...initialFilterState,
        ...newFilterData,
        searchOnUseEffect: true,
      });
    };
  }

  function buildHistoryEntryTooltipTitle(entry) {
    const tooltipTitleComponents = entry.fields.map(({ key, value, label }) => {
      if (key === 'status') {
        return (
          <>
            {SearchFields[key]}
            :
            {value.join(', ')}
            <br />
          </>
        );
      }
      if (key === 'includeConsumed') {
        return (
          <>
            {SearchFields[key]}
            :
            {value ? 'Yes' : 'No'}
            <br />
          </>
        );
      }
      return (
        <>
          {SearchFields[key]}
          :
          {label}
          <br />
        </>
      );
    });
    return tooltipTitleComponents;
  }

  function handleTextField(event) {
    const { name, value } = event.target;
    setFilterData((currentState) => ({
      ...currentState,
      [name]: value,
    }));
  }
  function handleSwitch(event) {
    const { name, checked } = event.target;
    setFilterData((currentState) => ({
      ...currentState,
      [name]: checked,
    }));
  }
  function handleAutocompleteInput(name) {
    return function (_, value) {
      setFilterData((currentState) => ({ ...currentState, [name]: value }));
    };
  }
  function handleAutocompleteChange(name, path) {
    return function (_, value) {
      if (value) {
        const nodes = path.split('.');
        let newValue = value;
        nodes.forEach((node) => {
          if (!newValue[node]) return (newValue = value);
          newValue = newValue[node];
        });
        setFilterData((currentState) => ({
          ...currentState,
          [name]: newValue,
        }));
      } else setFilterData((currentState) => ({ ...currentState, [name]: null }));
    };
  }

  function getSelectedDataById(data, selectedValue) {
    if (!Array.isArray(data) || !selectedValue) return '';
    return data.find((item) => item?._id === selectedValue) || '';
  }

  function extractSelectValue(value, key) {
    if (typeof value === 'object' && value !== null) {
      return value[key];
    } return value;
  }

  const inputRenderer = {
    Select: (field) => (
      <TextField
        select
        label={field.label}
        value={filterData[field.value]}
        onChange={handleTextField}
        name={field.value}
        fullWidth
      >
        {field.values.map((value) => (
          <MenuItem
            key={extractSelectValue(value, 'value')}
            value={extractSelectValue(value, 'value')}
          >
            {extractSelectValue(value, 'label')}
          </MenuItem>
        ))}
      </TextField>
    ),
    Multiple: (field) => (
      <FormControl className={classes.formControl}>
        <InputLabel id={`${field.value}_label`}>{field.label}</InputLabel>
        <Select
          size="small"
          labelId={`${field.value}_label`}
          multiple
          fullWidth
          value={filterData[field.value]}
          onChange={handleTextField}
          name={field.value}
          input={<Input />}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => (
                <Chip className={classes.chip} key={value} label={value} />
              ))}
            </div>
          )}
        >
          {field.values.map((value) => (
            <MenuItem
              key={extractSelectValue(value, 'value')}
              value={extractSelectValue(value, 'value')}
            >
              {extractSelectValue(value, 'label')}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    ),
    String: (field) => (
      <TextField
        value={filterData[field.value] || ''}
        onChange={handleTextField}
        name={field.value}
        variant="standard"
        label={field.label}
        placeholder={field.label}
        fullWidth
      />
    ),
    Autocomplete: (field) => (
      <Autocomplete
        freeSolo
        size="small"
        options={
          selectData[field.route]?.filter((item) => item[field.ref]) || []
        }
        getOptionLabel={(option) => option[field.ref] || option}
        value={filterData[field.value]}
        onInputChange={handleAutocompleteInput(field.value)}
        onChange={handleAutocompleteChange(field.value, field.ref)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label={field.label}
            placeholder={field.label}
          />
        )}
        fullWidth
      />
    ),
    ObjectId: (field) => (
      <Autocomplete
        size="small"
        options={selectData[field.ref] || []}
        getOptionLabel={(option) => option[field.labelRef]}
        value={getSelectedDataById(
          selectData[field.ref],
          filterData[field.value],
        )}
        onChange={handleAutocompleteChange(field.value, '_id')}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label={field.label}
            placeholder={field.label}
          />
        )}
      />
    ),
  };

  function renderInput(field) {
    return (
      <Grid item lg={field.type === 'Multiple' ? 4 : 2} md={3} sm={12} xs={12}>
        <InputContainer>{inputRenderer[field.type](field)}</InputContainer>
      </Grid>
    );
  }

  return (
    <Container>
      {openModal && (
        <ModalContainer open={openModal} handleClose={handleCloseModal}>
          <FilterCustomizer handleClose={handleCloseModal} reportFields="10" />
        </ModalContainer>
      )}
      <Accordion expanded={expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          classes={{ content: classes.expansionHeader }}
          onClick={() => setExpanded(!expanded)}
        >
          <div className={classes.heading}>
            <TitleContent>Inventory Filter</TitleContent>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <TabContext value={selectedTab}>
            <AppBar elevation={0} position="static">
              <TabList
                onChange={handleChangeTab}
                aria-label="simple tabs example"
              >
                <Tab label="Current Filter" value="1" />
                <Tab label="Search History" value="2" />
              </TabList>
            </AppBar>
            <TabPanel value="1">
              <Grid container style={{ padding: '0px 10px 10px 10px' }}>
                {filterLayout.map((field) => renderInput(field))}

                <Grid item lg={4} md={3} sm={12} xs={12}>
                  <FormControlLabel
                    control={(
                      <Switch
                        checked={includeConsumed}
                        color="primary"
                        inputProps={{ name: 'includeConsumed' }}
                        onChange={handleSwitch}
                      />
                    )}
                    label="Include Consumed"
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} align="right">
                  <ButtonContainer>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSearch}
                      style={{ marginRight: 10, cursor: 'pointer' }}
                    >
                      Filter
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={clearFilter}
                      style={{ cursor: 'pointer' }}
                    >
                      Clear
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleOpenModal}
                      style={{ marginLeft: 10, cursor: 'pointer' }}
                    >
                      Customize Filter
                    </Button>
                  </ButtonContainer>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="2">
              <List component="nav" aria-label="main mailbox folders">
                {searchHistory.length === 0
                  ? 'No searches found'
                  : searchHistory.map((entry) => (
                    <Tooltip
                      placement="bottom-start"
                      title={buildHistoryEntryTooltipTitle(entry)}
                      key={entry.id}
                    >
                      <ListItem button onClick={handleHistorySearch(entry)}>
                        <ListItemIcon>
                          <SearchIcon />
                        </ListItemIcon>
                        <ListItemText>
                          {entry.fields.length}
                          {' '}
                          Fields -
                          {entry.totalResults}
                          {' '}
                          results -
                          {' '}
                          {formatDate(
                            new Date(entry.date),
                            'MM-dd-yyyy HH:mm',
                          )}
                        </ListItemText>
                      </ListItem>
                    </Tooltip>
                  ))}
              </List>
            </TabPanel>
          </TabContext>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  projects: state.projects,
  slocs: state.slocs,
  originalCharges: state.originalCharges,
  movimentations: state.movimentations,
  movimentationsFilter: state.movimentationsFilter,
  customers: state.customers,
  filterLayout: state.filterLayout || [],
});

export default connect(mapStateToProps)(InventoryFilter);
