export const GET_INVENTORY_CART = '[inventory_cart] GET ';
export const GET_COMPLETE_INVENTORY_CART = '[inventory_cart] GET Complete';
export const FETCH_INVENTORY_CART_SUCCESS = '[inventory_cart] Fetch success';
export const FETCH_INVENTORY_CART_ERROR = '[inventory_cart] Fetch Error';
export const CART_CHANGE_SUCCESS = '[inventory_cart] Change Success';
export const ADD_TO_CART_SUCCESS = '[inventory_cart] ADD Success';
export const ADD_TO_CART_ERROR = '[inventory_cart] ADD Error';
export const REMOVE_FROM_CART_ERROR = '[inventory_cart] Remove Error';
export const REMOVE_FROM_CART_SUCCESS = '[inventory_cart] Remove Success';
export const UPDATE_INVENTORY_CART = '[inventory_cart] Update';
export const ADD_TO_INVENTORY_CART = '[inventory_cart] Add To';
export const REMOVE_FROM_INVENTORY_CART = '[inventory_cart] Remove From';
export const SHOW_INVENTORY_CART_SPINNER = '[inventory_cart - ui] show available spinner';
export const HIDE_INVENTORY_CART_SPINNER = '[inventory_cart - ui] hide available spinner';
export const UPDATE_CART_COUNT = '[inventory_cart] update count';

export const getInventoryCart = (filter) => ({
  type: GET_INVENTORY_CART,
  payload: filter,
});

export const getCompleteCart = (userId) => ({
  type: GET_COMPLETE_INVENTORY_CART,
  payload: { _id: userId },
});

export const addToInventoryCart = (userId, idList) => ({
  type: ADD_TO_INVENTORY_CART,
  payload: { userId, idList },
});

export const removeFromInventoryCart = (userId, equipmentIds) => ({
  type: REMOVE_FROM_INVENTORY_CART,
  payload: { userId, equipmentIds },
});

export const updateInventoryCart = (data) => ({
  type: UPDATE_INVENTORY_CART,
  payload: data,
});

export const updateCartCount = (data) => ({
  type: UPDATE_CART_COUNT,
  payload: data,
});

export const showSpinner = (availability) => ({
  type: SHOW_INVENTORY_CART_SPINNER,
});

export const hideSpinner = (availability) => ({
  type: HIDE_INVENTORY_CART_SPINNER,
});
