import { GET_CUSTOMERS, UPDATE_CUSTOMERS } from '../actions/customers';

const initState = {
  docs: [],
  page: 1,
  limit: 10,
  totalDocs: 0,
};

export function customersReducer(customers = initState, action) {
  switch (action.type) {
    case UPDATE_CUSTOMERS:
      return action.payload;

    default:
      return customers;
  }
}
