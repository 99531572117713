export const GET_BINS = '[bins] GET';
export const FETCH_BINS_SUCCESS = '[bins] Fetch success';
export const FETCH_BINS_ERROR = '[bins] Fetch Error';
export const UPDATE_BINS = '[bins] Update';
export const SHOW_BINS_SPINNER = '[bins - ui] show spinner';
export const HIDE_BINS_SPINNER = '[bins - ui] hide spinner';

export const getBins = (filter) => ({
  type: GET_BINS,
  payload: filter,
});

export const updateBins = (data) => ({
  type: UPDATE_BINS,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_BINS_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_BINS_SPINNER,
});
