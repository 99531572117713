import { CUSTOMER_KEY } from '../../config/consts';

export const GET_SLOCS = '[slocs] GET';
export const FETCH_SLOCS_SUCCESS = '[slocs] Fetch success';
export const FETCH_SLOCS_ERROR = '[slocs] Fetch Error';
export const UPDATE_SLOCS = '[slocs] Update';
export const SHOW_SLOCS_SPINNER = '[slocs - ui] show spinner';
export const HIDE_SLOCS_SPINNER = '[slocs - ui] hide spinner';

export const getSLOCS = (filter) => ({
  type: GET_SLOCS,
  payload: {
    ...filter,
    filter: { customer: localStorage.getItem(CUSTOMER_KEY), ...filter.filter },
  },
});

export const updateSLOCS = (data) => ({
  type: UPDATE_SLOCS,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_SLOCS_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_SLOCS_SPINNER,
});
