import React from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Typography,
} from '@material-ui/core';
import PrevIcon from '@material-ui/icons/ArrowBack';
import { useSelector } from 'react-redux';
import format from 'date-fns/format';
import Spinner from '../../../../components/Spinner';

function formatDate(date) {
  if (!date) return '--';
  return format(new Date(date), 'MM-dd-yyyy HH:mm');
}

function SelectBin({ onEdit, onPrevStep, data, onNextStep }) {
  const bins = useSelector((state) => state.bins?.docs || state.bins || []);
  const loading = useSelector((state) => state.ui.binsLoading);

  const handleClick = (id) => () => {
    if (id === data.bin) return onNextStep();
    onEdit({ target: { name: 'bin', value: id } });
  };

  return (
    <>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Bin</TableCell>
            <TableCell>Maximum Weight</TableCell>
            <TableCell>Last Reconcile Date</TableCell>
            <TableCell>Last Count Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading && (
            <TableRow>
              <TableCell colSpan={5}>
                <Spinner parent="Table" />
              </TableCell>
            </TableRow>
          )}
          {!loading && !bins.length ? (
            <TableRow>
              <TableCell colSpan={5}>
                <Typography align="center" variant="body" component="div">
                  No Bin found
                </Typography>
              </TableCell>
            </TableRow>
          ) : (
            bins.map((bin) => (
              <TableRow key={bin._id}>
                <TableCell>
                  <Button
                    onClick={handleClick(bin._id)}
                    color="primary"
                    variant={bin._id === data.bin ? 'contained' : 'outlined'}
                  >
                    {bin._id === data.bin ? 'Selected' : 'Select'}
                  </Button>
                </TableCell>
                <TableCell>{bin.name}</TableCell>
                <TableCell>
                  {bin.max_weight ? `${bin.max_weight} lb` : '--'}
                </TableCell>
                <TableCell>{formatDate(bin.last_reconcile_date)}</TableCell>
                <TableCell>{formatDate(bin.last_count_date)}</TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
      <Button
        startIcon={<PrevIcon />}
        color="secondary"
        style={{ marginTop: '.5rem' }}
        onClick={onPrevStep}
      >
        Change SLOC
      </Button>
    </>
  );
}

export default SelectBin;
