import React from 'react';
import ChipInput from 'material-ui-chip-input';

function TagsField({
  value, name, onChange, disabled, label,
}) {
  function handleChange(chip) {
    if (!value.includes(chip)) onChange({ target: { name, value: [...value, chip] } });
  }
  function handleDelete(_, index) {
    const tagsCopy = [...value];
    tagsCopy.splice(index, 1);
    onChange({ target: { name, value: tagsCopy } });
  }
  return (
    <ChipInput
      fullWidth
      onDelete={handleDelete}
      onAdd={handleChange}
      value={value}
      variant="outlined"
      label={label}
      readOnly={disabled}
    />
  );
}
export default TagsField;
