import { CUSTOMER_KEY } from '../../config/consts';

export const GET_SLOC = '[sloc] GET';
export const UPDATE_SLOC = '[sloc] UPDATE';
export const CREATE_SLOC = '[sloc] CREATE';
export const DELETE_SLOC = '[sloc] DELETE';
export const UPDATE_OBJECT_SLOC = '[sloc] UPDATE OBJECT SLOC';
// UI
export const SHOW_SLOC_MODAL = '[sloc] SHOW MODAL';
export const HIDE_SLOC_MODAL = '[sloc] HIDE MODAL';
// Spinner
export const UPDATE_SLOC_SUCCESS = '[sloc] Update success';
export const UPDATE_SLOC_ERROR = '[sloc] Update Error';
export const FETCH_SLOC_SUCCESS = '[sloc] Fetch success';
export const FETCH_SLOC_ERROR = '[sloc] Fetch Error';
export const SHOW_SLOC_SPINNER = '[sloc - ui] show spinner';
export const HIDE_SLOC_SPINNER = '[sloc - ui] hide spinner';

export const getSLOC = (id) => ({
  type: GET_SLOC,
  payload: { _id: id },
});

export const createSLOC = (data) => ({
  type: CREATE_SLOC,
  payload: { ...data, customer: localStorage.getItem(CUSTOMER_KEY) },
});

export const updateSLOC = (data) => ({
  type: UPDATE_SLOC,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_SLOC_MODAL,
});

export const hideModal = () => ({
  type: HIDE_SLOC_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_SLOC_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_SLOC_SPINNER,
});

export const updateObjectSLOC = (data) => ({
  type: UPDATE_OBJECT_SLOC,
  payload: data,
});
