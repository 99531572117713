import React from 'react';
import { useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { Grid, IconButton, Button } from '@material-ui/core';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import HistoryIcon from '@material-ui/icons/History';
import DefaultInput from './DefaultInput';
import InputInfo from '../../../../components/InputInfo';
import TabPanel from '../../../../components/TabPanel';
import { InputContent } from '../styles';

function POAndHistoricalData({
  data,
  selectedTab,
  tabIndex,
  onChange,
  onChangeDate,
}) {
  const { id } = useParams();
  const equipmentLoading = useSelector(
    (state) => state.ui.movimentationLoading
  );
  const chargeCodes = useSelector(
    (state) => state.originalCharges?.docs || state.originalCharges || []
  );

  const fields = [
    { label: 'Customer PO Number', key: 'customer_po_no' },
    { label: 'PO Line Item Number', key: 'PO_line_item_no' },
    { label: 'Supplier SO Number', key: 'supplier_so_no' },
    {
      label: 'Estimated Delivery Date',
      key: 'estimated_delivery_date',
      date: true,
    },
    { label: 'Actual Delivery Date', key: 'actual_delivery_date', date: true },
    { label: 'Original Batch Number', key: 'original_batch_no' },
    {
      label: 'Original Charge Code',
      key: 'original_charge',
      options: chargeCodes.map(({ _id, original_charge_code }) => ({
        value: _id,
        label: original_charge_code,
      })),
    },
    { label: 'Legacy Tag ID', key: 'legacy_tag_id' },
    {
      label: 'Mother Tube ID',
      key: 'mother_id',
      disabled: true,
      InputProps: {
        endAdornment: (
          <InputInfo
            title={
              <span>
                Refers to equipment used to this equipment fabrication.
                <br />
                Click to visit the mother tube details
              </span>
            }
            CustomComponent={
              <Link
                component={IconButton}
                color="primary"
                to={
                  data.mother_id?._id
                    ? `/equipment/${data.mother_id._id}`
                    : null
                }
              >
                <InfoOutlined />
              </Link>
            }
          />
        ),
      },
    },
    {
      label: 'Date Placed in Inventory',
      key: 'date_placed_in_inventory',
      date: true,
    },
    { label: 'Manufacture Date', key: 'manufacture_date', date: true },
    { label: 'Date Last Returned', key: 'date_last_returned', date: true },
    { label: 'Date Last Shipped', key: 'date_last_shipped', date: true },
    { label: 'Last Reconcile Date', key: 'last_reconcile_date', date: true },
    { label: 'Date Last Offshore', key: 'last_date_offshore', date: true },
    { label: 'Consumed By', key: 'consumed_by', disabled: true },
    {
      label: 'Previous Charge Code',
      key: 'previous_customer_charge_code',
      options: chargeCodes.map(({ _id, original_charge_code }) => ({
        value: _id,
        label: original_charge_code,
      })),
    },
  ];

  return (
    <TabPanel value={selectedTab} index={tabIndex} dir="ltr">
      <Grid container style={{ opacity: equipmentLoading ? 0.3 : 1 }}>
        {fields.map(({ label, key, ...extraProps }) => (
          <DefaultInput
            key={key}
            label={label}
            name={key}
            value={data[key]}
            onChange={onChange}
            onChangeDate={onChangeDate}
            {...extraProps}
          />
        ))}
        <Grid item lg={4} md={6} sm={12} xs={12}>
          <InputContent>
            <Link
              to={`/history/${id}`}
              component={Button}
              variant="outlined"
              color="primary"
              disabled={id === 'new'}
              startIcon={<HistoryIcon />}
              fullWidth
            >
              See History
            </Link>
          </InputContent>
        </Grid>
      </Grid>
    </TabPanel>
  );
}

export default POAndHistoricalData;
