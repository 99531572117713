import {
  UPDATE_JOB_SUCCESS,
  UPDATE_JOB_ERROR,
  FETCH_JOB_SUCCESS,
  FETCH_JOB_ERROR,
  GET_JOB,
  CREATE_JOB,
  UPDATE_JOB,
  updateObjectJob,
  showSpinner,
  hideSpinner,
  hideModal,
} from '../actions/job';
import { getJobs } from '../actions/jobs';
import Toastify from '../../utils/Toastify/index';
import { apiRequest } from '../actions/api';

const URL = 'jobs';

export const createJobFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === CREATE_JOB) {
    dispatch(
      apiRequest(
        'POST',
        URL,
        action.payload,
        UPDATE_JOB_SUCCESS,
        UPDATE_JOB_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const updateJobFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === UPDATE_JOB) {
    dispatch(
      apiRequest(
        'PUT',
        URL,
        action.payload,
        UPDATE_JOB_SUCCESS,
        UPDATE_JOB_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const getJobFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_JOB) {
    const { _id } = action.payload;
    dispatch(
      apiRequest('GET-id', URL, { _id }, FETCH_JOB_SUCCESS, FETCH_JOB_ERROR),
    );
    dispatch(showSpinner());
  }
};

export const processJobCollection = ({ dispatch, getState }) => (next) => (
  action,
) => {
  next(action);
  if (action.type === FETCH_JOB_SUCCESS) {
    dispatch((action.payload));
    dispatch(hideSpinner());
  } else if (action.type === UPDATE_JOB_SUCCESS) {
    dispatch(updateObjectJob(action.payload));
    dispatch(hideModal());
    dispatch(hideSpinner());
    // To reload the page
    const { page, limit } = getState().jobs;
    dispatch(getJobs({ page, limit }));
    Toastify.addSuccess('Job saved.');
  } else if (action.type === UPDATE_JOB_ERROR) {
    dispatch(hideSpinner());
    Toastify.addError(action.payload || 'Error to save the job');
  }
};

export const jobMdl = [
  createJobFlow,
  updateJobFlow,
  getJobFlow,
  processJobCollection,
];
