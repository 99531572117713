module.exports = [
  { value: 'mm_number', label: 'Customer MM No', type: 'String' },
  {
    value: 'short_description',
    label: 'Short Description',
    type: 'String',
  },
  {
    value: 'long_description',
    label: 'Long Description',
    type: 'String',
  },
  { value: 'equipment_type', label: 'Equipment Type', type: 'String' },
  { value: 'modifier', label: 'Modifier', type: 'String' },
  { value: 'size', label: 'Max OD Size', type: 'String' },
  { value: 'weight', label: 'Tube Weight', type: 'String' },
  { value: 'grade', label: 'Material Grade', type: 'String' },
  { value: 'connection_1', label: 'Connection 1', type: 'String' },
  { value: 'connection_1_size', label: 'Connection 1 (SIZE)', type: 'String' },
  {
    value: 'connection_1_weight',
    label: 'Connection 1 (WEIGHT)',
    type: 'String',
  },
  {
    value: 'connection_1_grade',
    label: 'Connection 1 (GRADE)',
    type: 'String',
  },
  { value: 'connection_2', label: 'Connection 2', type: 'String' },
  { value: 'connection_2_size', label: 'Connection 2 (SIZE)', type: 'String' },
  {
    value: 'connection_2_weight',
    label: 'Connection 2 (WEIGHT)',
    type: 'String',
  },
  {
    value: 'connection_2_grade',
    label: 'Connection 2 (GRADE)',
    type: 'String',
  },
  {
    value: 'connection_configuration',
    label: 'Connection Configuration',
    type: 'String',
  },
  { value: 'nominal_wall', label: 'Nominal Wall', type: 'String' },
  { value: 'drift_diameter', label: 'Drift Diameter', type: 'String' },
  { value: 'range', label: 'Range', type: 'String' },
  { value: 'pipe_type', label: 'Pipe Type', type: 'String' },
  { value: 'manufacturer', label: 'Manufacturer', type: 'String' },
  {
    value: 'manufacturer_pn',
    label: 'Manufacturer Part Number',
    type: 'String',
  },
  {
    value: 'inspection_criteria',
    label: 'Inspection Criteria',
    type: 'String',
  },
  {
    value: 'pressure_rating',
    label: 'Pressure Rating (PSI)',
    type: 'String',
  },
  {
    value: 'primary_uom',
    label: 'Primary UoM',
    type: 'String',
  },
  {
    value: 'secondary_uom',
    label: 'Secondary UoM',
    type: 'String',
  },
  {
    value: 'action_type',
    label: 'Action Type',
    type: 'String',
  },
  { value: 'MRP_min_qty', label: 'MRP Minimum Quantity', type: 'Number' },
  { value: 'MRP_max_qty', label: 'MRP Maximum Quantity', type: 'Number' },
  { value: 'MRP_min_ftg', label: 'MRP Minimum Footage', type: 'Number' },
  { value: 'MRP_max_ftg', label: 'MRP Maximum Footage', type: 'Number' },
  { value: 'hazmat', label: 'Hazardous Material (Y/N)', type: 'Boolean' },
];
