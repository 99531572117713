import styled from 'styled-components';

export const Container = styled.div`
  padding: 1rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
`;

export const InputContainer = styled.div`
  padding: 5px 10px 10px 10px;
`;

export const ButtonContainer = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

export const ModalWrapper = styled.div`
  margin: 1rem;
  width: 95vw;
  padding: 0.5rem;
  background-color: #ccc;
  border-radius: 4px;
  display: flex;
  align-self: flex-start;
`;
