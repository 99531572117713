import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { ArrowBack } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import EventDocumentsSection from '../../../components/EventDocumentsSection';
import FabricationRow from '../../../components/FabricationRow';
import Spinner from '../../../components/Spinner';
import TagsField from '../../../components/TagsField';
import { CUSTOMER_KEY } from '../../../config/consts';
import {
  createFabrication,
  getFabrication as getFabricationEvent,
  updateFabrication,
} from '../../../redux/actions/fabrication';
import { downloadReport } from '../../../services/Download';
import { tableStyles } from '../../../styles/tableStyles';
import EquipmentForm from '../Equipment';
import { ButtonContainer, Container, InputContainer } from './styles';
import handlePost from '../../../utils/Event/handlePost';

const Fabrication = ({
  dispatch,
  fabrication,
  movimentationLoading,
  loggedUser,
}) => {
  const { customer, id } = useParams();
  const history = useHistory();
  const { state } = useLocation();
  const classes = tableStyles();
  const [openModal, setOpenModal] = useState(false);
  const [motherTube, setMotherTube] = useState('');
  const [data, setData] = React.useState({
    individualData: [],
    newAdded: [],
    tags: [],
    files: [],
    newFiles: [],
  });
  const [indexConsumed, setIndexConsumed] = useState(null);
  const [indexEdited, setIndexEdited] = useState(null);

  const {
    individualData,
    files,
    newAdded,
    tags,
    facilityReferenceID,
    newFiles,
    fileNotes,
  } = data;

  React.useEffect(() => {
    if (!id || id === 'new') return;
    dispatch(getFabricationEvent(id));
  }, [id]);

  React.useEffect(() => {
    if (id === 'new' || !fabrication) return;
    setData({
      ...fabrication,
      individualData: fabrication.individualData?.map(
        ({ movimentation, fabricated }) => ({
          movimentation,
          fabricated,
        })
      ),
    });
  }, [fabrication]);

  React.useEffect(() => {
    if (state?.movimentations) {
      setData((currentData) => ({
        ...currentData,
        individualData: state.movimentations.map((eqp) => ({
          movimentation: eqp,
          fabricated: [],
        })),
      }));
    }
  }, [state]);

  useEffect(() => {
    if (!localStorage.getItem(CUSTOMER_KEY))
      history.replace('/equipments', { origin: 'mm_number' });
  }, []);

  const handleBack = () => {
    history.goBack();
  };

  function handleOpenModal(consumed, edited = null) {
    return function () {
      setIndexConsumed(consumed);
      setIndexEdited(edited);
      setMotherTube(individualData[consumed].movimentation.individual_item_id);
      setOpenModal(true);
    };
  }
  function handleCloseModal() {
    setIndexConsumed(null);
    setIndexEdited(null);
    setOpenModal(false);
  }

  function handleDownload() {
    downloadReport(id);
  }
  function handleEdit(e) {
    const { name, value } = e.target;
    setData((currentData) => ({ ...currentData, [name]: value }));
  }

  function handleSave(data) {
    if (!data || indexConsumed === null || !individualData[indexConsumed])
      return;
    setData((currentData) => {
      const individualCopy = [...currentData.individualData];
      if (indexEdited === null)
        individualCopy[indexConsumed].fabricated = [
          ...individualCopy[indexConsumed].fabricated,
          data,
        ];
      else {
        individualCopy[indexConsumed].fabricated[indexEdited] = data;
      }
      return { ...currentData, individualData: individualCopy };
    });
    handleCloseModal();
  }

  function handleRemove(consumedIndex, editedIndex) {
    return function () {
      setData((currentData) => {
        const individualCopy = [...currentData.individualData];
        const fabricatedCopy = [...individualCopy[consumedIndex].fabricated];
        fabricatedCopy.splice(editedIndex, 1);
        individualCopy[consumedIndex].fabricated = fabricatedCopy;
        return { ...currentData, individualData: individualCopy };
      });
    };
  }

  function handleFiles({ files, notes }) {
    setData((currentData) => ({
      ...currentData,
      newFiles: files,
      fileNotes: notes,
    }));
  }

  const disabled = id !== 'new';

  return (
    <Container>
      <div className={classes.root} style={{ overflow: 'visible' }}>
        <Paper className={classes.paper} style={{ minHeight: '90%' }}>
          <Toolbar className={classes.toolBar}>
            <span>
              <IconButton onClick={handleBack}>
                <ArrowBack />
              </IconButton>
              <Typography
                className={classes.title}
                variant="h6"
                id="tableTitle"
                component="span"
              >
                {id === 'new' ? 'New' : 'Edit'} Fabrication
              </Typography>
            </span>
          </Toolbar>
          <TableContainer className={classes.table}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell style={{ maxWidth: 300 }}>
                    RINA Description
                  </TableCell>
                  <TableCell>RAMP Unique ID</TableCell>
                  <TableCell>Customer MM No</TableCell>
                  <TableCell>Total Qty. EA</TableCell>
                  <TableCell>Total Tally (FT)</TableCell>
                  <TableCell>COPAS Class</TableCell>
                  <TableCell>Supplier MM No</TableCell>
                  <TableCell>Supplier Batch No</TableCell>
                  <TableCell>Heat/Lot</TableCell>
                  <TableCell>Run/Jt No</TableCell>
                  <TableCell>RBW</TableCell>
                  <TableCell>Facility Reference ID</TableCell>
                  <TableCell>Assigned Project</TableCell>
                  <TableCell>Assigned Well</TableCell>
                  <TableCell>Assigned Charge Code</TableCell>
                  <TableCell>RAMP Tag No</TableCell>
                  <TableCell>RINA Work Order No</TableCell>
                  <TableCell>SLOC</TableCell>
                  <TableCell>Material Layout</TableCell>
                  <TableCell>Associated RAMP Tag</TableCell>
                  <TableCell>Allocated</TableCell>
                  <TableCell>Remove From Parent</TableCell>
                  <TableCell>Supplier</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {movimentationLoading ? (
                  <TableRow>
                    <TableCell colSpan={26}>
                      <Spinner parent="Table" />
                    </TableCell>
                  </TableRow>
                ) : (
                  individualData.map(({ movimentation, fabricated }, index) => (
                    <FabricationRow
                      key={movimentation._id}
                      movimentation={movimentation}
                      root
                      openModal={handleOpenModal(index)}
                      enableRemoval={id === 'new'}
                      consumedIndex={index}
                      fabricated={fabricated}
                      onRemove={handleRemove}
                    />
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Paper className={classes.paper} style={{ overflow: 'visible' }}>
          <Grid container style={{ padding: '16px 8px 8px 10px' }}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <InputContainer>
                <TextField
                  value={facilityReferenceID}
                  name="facilityReferenceID"
                  onChange={handleEdit}
                  label="Facility Reference ID"
                  variant="outlined"
                  fullWidth
                  disabled={disabled}
                />
              </InputContainer>
            </Grid>
          </Grid>
        </Paper>
        <Paper className={classes.paper}>
          <EventDocumentsSection
            files={files}
            id={id}
            newFiles={newFiles}
            fileNotes={fileNotes}
            onChangeFiles={handleFiles}
          />
          <Grid container style={{ padding: '0px 10px 10px 10px' }}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <InputContainer>
                <TagsField
                  value={tags}
                  name="tags"
                  onChange={handleEdit}
                  label="Tags"
                  disabled={disabled}
                />
              </InputContainer>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <ButtonContainer>
                <Button
                  color="primary"
                  variant={id === 'new' ? 'contained' : 'outlined'}
                  style={{ marginRight: 5 }}
                  onClick={handlePost(
                    id,
                    data,
                    loggedUser,
                    createFabrication,
                    updateFabrication,
                    dispatch
                  )}
                >
                  {id === 'new' ? 'Post' : 'Update'} Transaction
                </Button>
                <Button
                  disabled={id === 'new'}
                  color="primary"
                  variant="contained"
                  style={{ marginRight: 5 }}
                  onClick={handleDownload}
                >
                  Create Fabrication Report
                </Button>
              </ButtonContainer>
            </Grid>
          </Grid>
        </Paper>
      </div>
      <CreationModal
        open={openModal}
        onClose={handleCloseModal}
        motherTube={motherTube}
        onSave={handleSave}
      />
    </Container>
  );
};

function CreationModal({ open, onClose, onSave, motherTube }) {
  return (
    <Modal open={open} onClose={onClose} disableScrollLock>
      <EquipmentForm
        fabrication
        onSave={onSave}
        onClose={onClose}
        motherTube={motherTube}
      />
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  projects: state.projects,
  chargeCodes: state.originalCharges,
  materialMasters: state.customerMMs,
  SLOCs: state.slocs,
  fabrication: state.fabrication,
  parents: state.BOMParents,
  movimentationLoading: state.ui.movimentationLoading,
  loggedUser: state.loggedUser,
});

export default connect(mapStateToProps)(Fabrication);
