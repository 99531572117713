export const LIST_RESULT_LAYOUT = '[RESULT LAYOUT] LIST';
export const SUCCESS_LIST_RESULT_LAYOUT = '[RESULT LAYOUT] SUCCESS_LIST';
export const ERROR_LIST_RESULT_LAYOUT = '[RESULT LAYOUT] ERROR_LIST';
export const UPDATE_RESULT_LAYOUT_LIST = '[RESULT LAYOUT] UPDATE LIST';

export function listResultLayout(payload) {
  return { type: LIST_RESULT_LAYOUT, payload };
}
export function updateResultLayoutList(payload) {
  return { type: UPDATE_RESULT_LAYOUT_LIST, payload };
}
