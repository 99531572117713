import { GET_WORK_ORDERS, UPDATE_WORK_ORDERS } from '../actions/workOrders';

const initState = {
  docs: [],
  page: 1,
  limit: 10,
  totalDocs: 0,
};

export function workOrdersReducer(workOrders = initState, action) {
  switch (action.type) {
    case GET_WORK_ORDERS:
      return action.payload;
    case UPDATE_WORK_ORDERS:
      return action.payload;

    default:
      return workOrders;
  }
}
