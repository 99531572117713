import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import Alert from '../../../components/Alert/index';
import Spinner from '../../../components/Spinner/index';
import { Container, InputContent, ModalTitle, ButtonContainer } from './styles';
import { getWells } from '../../../redux/actions/wells';
import { CUSTOMER_KEY } from '../../../config/consts';

import {
  getOriginalCharge,
  createOriginalCharge,
  updateOriginalCharge,
} from '../../../redux/actions/originalCharge';

const styles = {
  formButton: {
    margin: 2,
  },
};

const OriginalChargeModal = ({
  id,
  dispatch,
  originalCharge,
  originalChargeModal,
  handleClose,
  originalChargeSpinner,
  wells,
}) => {
  const [codeError, setCodeError] = useState(false);
  const [data, setData] = useState({});
  const { original_charge_code, well } = data;

  useEffect(() => {
    dispatch(
      getWells({
        filter: { listAll: true, customer: localStorage.getItem(CUSTOMER_KEY) },
      })
    );
  }, []);

  useEffect(() => {
    if (id !== 'new') {
      dispatch(getOriginalCharge(id));
    }
  }, [id]);

  useEffect(() => {
    if (originalCharge && id !== 'new') {
      setData(originalCharge);
    }
  }, [originalCharge]);

  useEffect(() => {
    if (!originalChargeModal) {
      handleClose();
    }
  }, [originalChargeModal]);

  const handleSave = async () => {
    let err = false;
    setCodeError(!original_charge_code);
    err = !original_charge_code;

    if (!err) {
      const response = await Alert();
      if (response && response.confirm) {
        if (id === 'new') {
          dispatch(createOriginalCharge(data));
        } else {
          dispatch(updateOriginalCharge(data));
        }
      }
    }
  };

  const handleEdit = (e) => {
    const { name, value } = e.target;
    setData((currentData) => ({ ...currentData, [name]: value }));
  };

  return (
    <>
      {originalChargeSpinner && <Spinner overlay />}

      <Container style={{ opacity: originalChargeSpinner ? 0.5 : 1 }}>
        <ModalTitle>{id === 'new' ? 'New' : 'Edit'} Charge Code</ModalTitle>
        <InputContent>
          <TextField
            id="outlined-password-input"
            label="Code"
            required
            type="text"
            size="small"
            error={codeError}
            helperText={codeError && 'Code is required'}
            variant="outlined"
            value={original_charge_code || ''}
            name="original_charge_code"
            onChange={handleEdit}
            fullWidth
          />
        </InputContent>
        <InputContent>
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel id="select-label">Assigned Well</InputLabel>
            <Select
              size="small"
              labelId="select-label"
              id="select-label-component"
              value={well || ''}
              variant="outlined"
              onChange={handleEdit}
              name="well"
              fullWidth
              disabled={wells.length === 0}
            >
              {wells &&
                wells.map((well) => (
                  <MenuItem value={well._id}>{well.name}</MenuItem>
                ))}
            </Select>
          </FormControl>
        </InputContent>

        <ButtonContainer>
          <Button
            color="secondary"
            onClick={handleClose}
            variant="contained"
            style={styles.formButton}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={handleSave}
            variant="contained"
            style={styles.formButton}
          >
            Save
          </Button>
        </ButtonContainer>
      </Container>
    </>
  );
};

export default connect((state) => ({
  originalCharge: state.originalCharge,
  originalChargeModal: state.ui.originalChargeModal,
  originalChargeSpinner: state.ui.originalChargeLoading,
  wells: state.wells?.docs || state.wells,
}))(OriginalChargeModal);
