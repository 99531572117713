import { CUSTOMER_KEY } from '../../config/consts';

export const GET_VPASS = '[VPASs] GET';
export const FETCH_VPASS_SUCCESS = '[VPASs] Fetch success';
export const FETCH_VPASS_ERROR = '[VPASs] Fetch Error';
export const UPDATE_VPASS = '[VPASs] Update';
export const SHOW_VPASS_SPINNER = '[VPASs - ui] show spinner';
export const HIDE_VPASS_SPINNER = '[VPASs - ui] hide spinner';

export const getVPASs = (filter) => ({
  type: GET_VPASS,
  payload: filter,
});

export const updateVPASs = (data) => ({
  type: UPDATE_VPASS,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_VPASS_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_VPASS_SPINNER,
});
