export const GET_LOGGED_USER = '[logged-user] GET';
export const UPDATE_LOGGED_USER = '[logged-user] UPDATE';
export const LOGIN = '[logged-user] LOGIN';
export const LOGOUT = '[logged-user] LOGOUT';

export const FETCH_LOGIN_SUCCESS = '[logged-user] Fetch success';
export const FETCH_LOGIN_ERROR = '[logged-user] Fetch Error';
export const SHOW_LOGIN_SPINNER = '[logged-user - ui] show spinner';
export const HIDE_LOGIN_SPINNER = '[logged-user - ui] hide spinner';

export const getLoggedUser = () => ({
  type: GET_LOGGED_USER,
  payload: {},
});

export const updateLoggedUser = (data) => ({
  type: UPDATE_LOGGED_USER,
  payload: data,
});

export const login = (data) => ({
  type: LOGIN,
  payload: data,
});

export const logout = (data) => ({
  type: LOGOUT,
  payload: data,
});

export const showLoginSpinner = () => ({
  type: SHOW_LOGIN_SPINNER,
});

export const hideLoginSpinner = () => ({
  type: HIDE_LOGIN_SPINNER,
});
