/* eslint-disable object-curly-newline */
module.exports = [
  { value: 'manufacturer', label: 'Manufacturer', type: 'String' }, // String
  { value: 'parent_assembly', label: 'Parent Assembly', type: 'String' }, // String
  { value: 'assembly_id', label: 'Assembly ID', type: 'String' }, // String
  { value: 'material_layout', label: 'Material Layout', type: 'String' }, // String
  // { value: '_view.department', label: 'Department', type: 'String' }, // String
  {
    value: 'PO_line_item_no',
    label: 'PO Line Item Number',
    type: 'String',
  }, // String
  {
    value: 'manufacturer_no',
    label: 'Manufacturer Number',
    type: 'String',
  }, // String
  {
    value: 'divestment_number',
    label: 'Divestment Number',
    type: 'String',
  }, // String
  { value: 'classification', label: 'COPAS Class', type: 'String' }, // String
  { value: 'footage', label: 'Footage', type: 'String' }, // String
  { value: 'qty', label: 'Quantity', type: 'Number' }, // Number
  { value: 'tally_id', label: 'Tally ID', type: 'String' }, // String
  { value: 'qty_prime', label: 'Quantity Prime', type: 'Number' }, // Number
  { value: 'qty_damage', label: 'Quantity Damage', type: 'Number' }, // Number
  { value: 'batch_no', label: 'Supplier Batch No', type: 'String' }, // String
  {
    value: 'customer_po_no',
    label: 'Customer PO Number',
    type: 'String',
  }, // String
  { value: 'RBW', label: 'RBW', type: 'String' }, // String
  { value: 'heat', label: 'HEAT', type: 'String' }, // String
  { value: 'joint_no', label: 'Joint/Run No', type: 'String' }, // String
  {
    value: 'facility_reference_id',
    label: 'Facility Reference ID',
    type: 'String',
  }, // String
  { value: 'bin_location.name', label: 'Bin Location', type: 'String' }, // String
  { value: 'rack_location.name', label: 'Rack Location', type: 'String' }, // String
  {
    value: 'warehouse_location.name',
    label: 'Warehouse Location',
    type: 'String',
  }, // String
  {
    value: 'price_per_foot_cost',
    label: 'Price Per Foot Cost',
    type: 'String',
  }, // String
  { value: 'unit_cost', label: 'Unit (EA) Cost', type: 'String' }, // String
  { value: 'container_id', label: 'Container ID', type: 'String' }, // String
  {
    value: 'date_last_returned',
    label: 'Date Last Returned',
    type: 'Date',
  }, // Date
  {
    value: 'date_last_shipped',
    label: 'Date Last Shipped',
    type: 'Date',
  }, // Date
  {
    value: 'rina_work_order_no',
    label: 'Equality Work Order Number',
    type: 'String',
  }, // String
  {
    value: 'rina_return_work_order_no',
    label: 'Equality Return Work Order Number',
    type: 'String',
  }, // String
  {
    value: 'reclass_damage_description',
    label: 'Reclass Damage Description',
    type: 'String',
  }, // String
  {
    value: 'date_placed_in_inventory',
    label: 'Date Placed in Inventory',
    type: 'Date',
  }, // Date
  {
    value: 'individual_item_id',
    label: 'RAMP Unique ID',
    type: 'String',
  }, // String
  {
    value: 'legacy_tag_id',
    label: 'Legacy Tag ID',
    type: 'String',
  }, // String
  {
    value: 'plant_code',
    label: 'Assigned Plant Code',
    type: 'String',
  }, // String
  {
    value: 'allocated_plant_code',
    label: 'Allocated Plant Code',
    type: 'String',
  }, // String
  {
    value: 'last_reconcile_date',
    label: 'Last Reconcile Date',
    type: 'Date',
  }, // Date
  {
    value: 'last_date_offshore',
    label: 'Last Date Offshore',
    type: 'Date',
  }, // Date
  {
    value: 'parent_container',
    label: 'Parent Container',
    type: 'String',
  }, // String
  {
    value: 'maintenance_routine_interval_days',
    label: 'Maintenance Routine Interval Days',
    type: 'String',
  }, // String
  {
    value: 'maintenance_routine_target_date',
    label: 'Maintenance Routine Target Date',
    type: 'Date',
  }, // Date
  {
    value: 'rotation_routine_interval',
    label: 'Rotation Routine Interval',
    type: 'String',
  }, // String
  {
    value: 'rotation_routine_date',
    label: 'Rotation Routine Date',
    type: 'Date',
  }, // Date
  {
    value: 'testing_routine_interval',
    label: 'Testing Routine Interval',
    type: 'String',
  }, // String
  {
    value: 'testing_routine_date',
    label: 'Testing Routine Date',
    type: 'Date',
  }, // Date
  // { value: 'allocated_release_number', label: 'Allocated Release Number', type: 'String' }, // String
  {
    value: 'allocated_batch_number',
    label: 'Allocated Batch Number',
    type: 'String',
  }, // String
  {
    value: 'allocated_work_order_number',
    label: 'Allocated Work Order Number',
    type: 'String',
  }, // String
  { value: 'note', label: 'Note', type: 'String' }, // String
  { value: 'supplier', label: 'Supplier', type: 'String' }, // String
  { value: 'supplier_serial_no', label: 'Supplier Serial No', type: 'String' }, // String
  { value: 'supplier_so_no', label: 'Supplier SO No', type: 'String' }, // String
  {
    value: 'supplier_part_no',
    label: 'Supplier Part No',
    type: 'String',
  }, // String
  { value: 'status', label: 'Status', type: 'String' }, // String
  {
    value: 'inspection_criteria',
    label: 'Inspection Criteria',
    type: 'String',
  }, // String
  // { value: 'unique_id', label: 'Unique ID', type: 'String' }, // String
  { value: 'tag_number', label: 'Tag Number', type: 'String' }, // String
  { value: 'department', label: 'Item Department', type: 'String' }, // String
  // Original Charges Objects
  {
    value: '_view.assigned_customer_charge_code.original_charge_code',
    label: 'Assigned Charge Code',
    type: 'ObjectId',
    ref: 'originalCharge',
  },
  {
    value: '_view.assigned_customer_charge_code.well.project.name',
    label: 'Assigned Project',
    type: 'ObjectId',
    ref: 'originalCharge',
  },
  {
    value: '_view.original_charge.original_charge_code',
    label: 'Original Charge Code',
    type: 'ObjectId',
    ref: 'originalCharge',
  },
  {
    value: '_view.previous_customer_charge_code.original_charge_code',
    label: 'Previous Charge Code',
    type: 'ObjectId',
    ref: 'originalCharge',
  },
  // Customer MM Objects
  {
    value: '_view.customer_MM.short_description',
    label: 'Equipment Name',
  }, // String
  {
    value: '_view.customer_MM.mm_number',
    label: 'Material Master Number',
  }, // String
  // { value: '_view.customer_MM.short_description', type: 'ObjectId', label: 'Customer MM', ref: 'materialMasters' },
  { value: '_view.customer_MM.size', type: 'Function', label: 'Max OD Size' },
  { value: '_view.customer_MM.weight', type: 'Function', label: 'Tube Weight' },
  { value: '_view.customer_MM.grade', type: 'String', label: 'Material Grade' },
  {
    value: '_view.customer_MM.connection_1',
    type: 'String',
    label: 'Connection 1',
  },
  {
    value: '_view.customer_MM.connection_1_size',
    type: 'Function',
    label: 'Connection 1 (SIZE)',
  },
  {
    value: '_view.customer_MM.connection_1_weight',
    type: 'Function',
    label: 'Connection 1 (WEIGHT)',
  },
  {
    value: '_view.customer_MM.connection_1_grade',
    type: 'String',
    label: 'Connection 1 (GRADE)',
  },
  {
    value: '_view.customer_MM.connection_2',
    type: 'String',
    label: 'Connection 2',
  },
  {
    value: '_view.customer_MM.connection_2_size',
    type: 'Function',
    label: 'Connection 2 (SIZE)',
  },
  {
    value: '_view.customer_MM.connection_2_weight',
    type: 'Function',
    label: 'Connection 2 (WEIGHT)',
  },
  {
    value: '_view.customer_MM.connection_2_grade',
    type: 'String',
    label: 'Connection 2 (GRADE)',
  },
  {
    value: '_view.customer_MM.connection_configuration',
    type: 'String',
    label: 'Connection Configuration',
  },
  {
    value: '_view.customer_MM.nominal_wall',
    type: 'String',
    label: 'Nominal Wall',
  },
  { value: '_view.customer_MM.range', type: 'String', label: 'Range' },
  { value: '_view.customer_MM.pipe_type', type: 'String', label: 'Pipe Type' },
  {
    value: '_view.customer_MM.equipment_type',
    type: 'String',
    label: 'Equipment Type',
  },
  {
    value: '_view.customer_MM.modifier',
    type: 'String',
    label: 'Modifier',
  },
  {
    value: 'SLOC.sloc_name',
    type: 'String',
    label: 'Storage Location (SLOC)',
  },
  {
    value: 'SLOC.sloc_id',
    type: 'String',
    label: 'SLOC ID',
  },
  {
    value: 'rina_description',
    type: 'Function',
    label: 'RINA Description',
  },
  {
    value: 'mother_id',
    type: 'Function',
    label: 'Mother Tube ID',
  },
  { label: 'Purchase Price', value: 'purchase_price', type: 'Currency' },
  { label: 'Fabrication Costs', value: 'fabrication_costs', type: 'Currency' },
  { label: 'Service Cost', value: 'service_cost', type: 'Currency' },
  {
    label: 'Transportation Cost',
    value: 'transportation_cost',
    type: 'Currency',
  },
  { label: 'Handling Cost', value: 'handling_cost', type: 'Currency' },
  { label: 'Inspection Cost', value: 'inspection_cost', type: 'Currency' },
  {
    label: 'Material Value Including Services',
    value: 'material_value_plus_services',
    type: 'Currency',
  },
  { label: 'Price per Foot', value: 'price_per_foot', type: 'Currency' },
  { label: 'Price per Each', value: 'price_per_each', type: 'Currency' },
  {
    label: 'Current Material Value',
    value: 'current_material_value',
    type: 'Currency',
  },
];
