import { GET_BINS, UPDATE_BINS } from '../actions/bins';

const initState = {
  docs: [],
  page: 1,
  limit: 10,
  totalDocs: 0,
};

export function binsReducer(bins = initState, action) {
  switch (action.type) {
    case UPDATE_BINS:
      return action.payload;

    default:
      return bins;
  }
}
