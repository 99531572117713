import {
  LIST_RESULT_LAYOUT,
  SUCCESS_LIST_RESULT_LAYOUT,
  ERROR_LIST_RESULT_LAYOUT,
  updateResultLayoutList,
} from '../actions/resultLayouts';
import { apiRequest } from '../actions/api';

const URL = 'result_layouts';

function listResultLayoutFlow({ dispatch, getState }) {
  return function (next) {
    return function (action) {
      next(action);
      if (action.type === LIST_RESULT_LAYOUT) {
        const { filter } = action.payload || {};
        dispatch(
          apiRequest(
            'GET',
            URL,
            {
              filter: { user: getState().loggedUser._id, ...filter },
            },
            SUCCESS_LIST_RESULT_LAYOUT,
            ERROR_LIST_RESULT_LAYOUT,
            ['user'],
          ),
        );
      }
    };
  };
}
function processResultLayoutCollection({ dispatch, getState }) {
  return function (next) {
    return function (action) {
      next(action);
      if (action.type === SUCCESS_LIST_RESULT_LAYOUT) {
        dispatch(updateResultLayoutList(action.payload));
      }
    };
  };
}

export const resultLayoutsMdl = [
  listResultLayoutFlow,
  processResultLayoutCollection,
];
