import styled from 'styled-components';

export const Container = styled.div`
    min-height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: center;
    justify-content: center;
    color: white;
    font-size: 26px;
`;
