import React from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import DefaultInput from './DefaultInput';
import TabPanel from '../../../../components/TabPanel';

function RINAAndQAQCData({
  data,
  selectedTab,
  tabIndex,
  onChange,
  onChangeDate,
}) {
  const equipmentLoading = useSelector(
    (state) => state.ui.movimentationLoading
  );

  const fields = [
    {
      label: 'RINA Work Order Number (most recent)',
      key: 'rina_work_order_no',
      disabled: true,
    },
    {
      label: 'RINA Return Work Order Number',
      key: 'rina_return_work_order_no',
      disabled: true,
    },
    {
      label: 'Allocation Work Order Number',
      key: 'allocated_work_order_number',
      disabled: true,
    },
    { label: 'Expiration Date', key: 'expiration_date', date: true },
    { label: 'In Service Date', key: 'in_service_date', date: true },
    { label: 'Tally ID', key: 'tally_id' },
    { label: 'Reclass Damage Description', key: 'reclass_damage_description' },
    {
      label: 'Rotation Routine Interval (Days)',
      key: 'rotation_routine_interval',
    },
    {
      label: 'Rotation Routine Target Date',
      key: 'date_last_rotated',
      date: true,
    },
    {
      label: 'Maintenance Routine Interval (Days)',
      key: 'maintenance_routine_interval_days',
    },
    {
      label: 'Maintenance Routine Target Date',
      key: 'maintenance_routine_target_date',
      date: true,
    },
    {
      label: 'Testing Routine Interval (Days)',
      key: 'testing_routine_interval',
    },
    {
      label: 'Testing Routine Target Date',
      key: 'date_last_tested',
      date: true,
    },
    { label: 'Inspection Criteria', key: 'inspection_criteria' },
  ];

  return (
    <TabPanel value={selectedTab} index={tabIndex} dir="ltr">
      <Grid container style={{ opacity: equipmentLoading ? 0.3 : 1 }}>
        {fields.map(({ key, ...props }) => (
          <DefaultInput
            key={key}
            name={key}
            onChange={onChange}
            onChangeDate={onChangeDate}
            value={data[key]}
            {...props}
          />
        ))}
      </Grid>
    </TabPanel>
  );
}

export default RINAAndQAQCData;
