import {
  FETCH_ROLES_SUCCESS, FETCH_ROLES_ERROR, GET_ROLES, updateRoles, showSpinner, hideSpinner,
} from '../actions/roles';
import { apiRequest } from '../actions/api';

const URL = 'roles';

export const getRolesFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_ROLES) {
    dispatch(apiRequest('GET', URL, action.payload, FETCH_ROLES_SUCCESS, FETCH_ROLES_ERROR));
    dispatch(showSpinner());
  }
};

export const processRolesCollection = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_ROLES_SUCCESS) {
    dispatch(updateRoles(action.payload));
    dispatch(hideSpinner());
  }
};

export const rolesMdl = [getRolesFlow, processRolesCollection];
