import Service from '../../services/Service';
import { API_REQUEST } from '../actions/api';

export const api = ({ dispatch }) => (next) => (action) => {
  if (action.type === API_REQUEST) {
    const {
      method, url, onSuccess, onError, params,
    } = action.meta;
    const { payload } = action;

    switch (method) {
      case 'POST': {
        const apiService = new Service({
          route: payload && payload._id ? `${url}/${payload._id}` : url,
        });
        apiService.store(payload).then((response) => {
          if (response.ok) {
            dispatch({ type: onSuccess, payload: response.data });
          } else {
            dispatch({ type: onError, payload: response.message });
          }
        });
        break;
      }
      case 'PUT': {
        const apiService = new Service({ route: url });
        const id = payload.get ? payload.get('_id') : payload._id;
        apiService.put(id, payload).then((response) => {
          if (response.ok) {
            dispatch({ type: onSuccess, payload: response.data });
          } else {
            dispatch({ type: onError, payload: response.message });
          }
        });
        break;
      }
      case 'PUT-file': {
        const apiService = new Service({ route: url });
        apiService.put('', payload).then((response) => {
          if (response.ok) {
            dispatch({ type: onSuccess, payload: response.data });
          } else {
            dispatch({ type: onError, payload: response.message });
          }
        });
        break;
      }
      case 'GET-id': {
        const apiService = new Service({ route: `${url}/${payload._id}` });
        apiService
          .getById()
          .then((response) => dispatch({
            type: onSuccess,
            payload: payload.forwardId
              ? { _id: payload._id, data: response.data }
              : response.data,
          }))
          .catch((error) => dispatch({ type: onError, payload: error }));
        break;
      }
      case 'GET-cart': {
        const apiService = new Service({
          route: `${url}/${payload.userId}/${payload.availability}`,
        });
        apiService
          .get(payload, params)
          .then((response) => dispatch({
            type: onSuccess,
            payload: {
              ...response.data,
              availability: payload.availability,
            },
          }))
          .catch((error) => dispatch({ type: onError, payload: error }));
        break;
      }
      case 'POST-cart': {
        const apiService = new Service({
          route: payload && payload.userId ? `${url}/${payload.userId}` : url,
        });
        apiService.store(payload).then((response) => {
          if (response.ok) {
            dispatch({ type: onSuccess, payload: payload.userId });
          } else {
            dispatch({ type: onError, payload: response.message });
          }
        });
        break;
      }
      case 'DELETE-cart': {
        const apiService = new Service({
          route: url,
        });
        apiService
          .put(payload.userId, {
            movimentations: payload.equipmentIds,
          })
          .then((response) => {
            if (response.ok) {
              dispatch({ type: onSuccess, payload: payload.userId });
            } else {
              dispatch({ type: onError, payload: response.message });
            }
          });
        break;
      }
      case 'DELETE': {
        const apiService = new Service({
          route: url,
        });
        apiService.delete(payload).then((response) => {
          if (response.ok) {
            dispatch({ type: onSuccess, payload: payload.userId });
          } else {
            dispatch({ type: onError, payload: response.message });
          }
        });
        break;
      }
      default: {
        const apiService = new Service({ route: url });
        apiService
          .get(payload, params)
          .then((response) => dispatch({ type: onSuccess, payload: response.data }))
          .catch((error) => dispatch({ type: onError, payload: error }));
        break;
      }
    }
  }
  return Promise.resolve(next(action));
};
