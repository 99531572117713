import React, { useState } from 'react';
import { Typography, Button, Grid, Box, TextField } from '@material-ui/core';
import { ModalContainer, DropZone } from '../../../../../components';
import { Container } from './styles';

function RejectionModal({ open, onClose, name, equipment, onConfirm }) {
  const [note, setNote] = useState('');
  const [file, setFile] = useState(null);

  const handleNote = (e) => setNote(e.target.value);

  const handleFile = (newFile) => setFile(newFile);

  const handleClose = () => {
    setNote('');
    setFile(null);
    onClose();
  };

  const handleConfirm = () => {
    setNote('');
    setFile(null);
    onConfirm(note, file)();
  };

  return (
    <ModalContainer open={open} handleClose={handleClose}>
      <Container>
        <Typography variant="h6" gutterBottom>
          Reject this item
        </Typography>
        <Typography variant="body">
          Write down the reasons to reject the service/maintenance "{name}" to
          equipment "{equipment}":
        </Typography>
        <Box margin="1rem 0">
          <TextField
            fullWidth
            value={note}
            onChange={handleNote}
            variant="outlined"
            label="Notes"
            multiline
          />
        </Box>
        <Box>
          <DropZone
            allowedExtensions={[
              'jpeg',
              'jpg',
              'png',
              'svg',
              'gif',
              'webp',
              'bmp',
              'ico',
              'tiff',
              'tif',
            ]}
            file={file}
            onChangeFile={handleFile}
          />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box display="flex" flex={1} justifyContent="flex-end">
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" color="primary" onClick={handleConfirm}>
              Reject
            </Button>
          </Grid>
        </Grid>
      </Container>
    </ModalContainer>
  );
}

export default RejectionModal;
