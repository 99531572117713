import Service from '../Service';

export default function uploadFile(file, data) {
  const api = new Service({ route: '/inspections/file' });
  const formData = new FormData();
  formData.append('file', file);

  Object.entries(data).forEach(([key, value]) => {
    formData.append(key, value);
  });

  return api
    .post(formData)
    .then((response) => response)
    .catch((e) => e);
}
