import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';

export const Container = styled.div`
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 20px;
  .MuiExpansionPanelSummary-root.Mui-expanded {
    height: 25px !important;
  }
  .MuiAccordionSummary-root.Mui-expanded {
    /* height: 25px !important; */
    border-bottom: 1px solid #ddd;
    min-height: 10px !important;
  }
  .MuiExpansionPanelDetails-root {
    padding: 0px;
  }
  .MuiList-padding {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .muiexpansionpanelsummary-root:hover: {
    cursor: default !important;
  }
  .MuiListItem-root.Mui-disabled {
    opacity: 1;
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0px !important;
  }
`;

export const PaperStyled = styled(Paper)`
  padding: 15px;
  width: auto;
`;

export const Title = styled.div`
  font-size: 1.4rem;
  font-weight: bold;
  padding: 15px;
`;

export const TableTitle = styled.div`
  font-size: 1rem;
  font-weight: bold;
  padding-top: 20px;
`;

export const ExcelTable = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  border: 1px solid #878787;
  border-radius: 3px;
  margin: 0px 0px 15px 0px;
  padding: 10px;
`;

export const Column = styled.div`
  padding: 5px;
`;

export const ColumnHeader = styled.div`
  text-align: center;
  font-size: 1.1rem;
`;

export const ColumnContent = styled.div`
  min-width: 200px;
`;

export const ButtonContainer = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;
export const TitleContent = styled.div`
  margin: 0;
  width: 85%;
  min-height: 25px;
`;

export const TemplateItem = styled.div``;
export const TemplateFieldItem = styled.div``;
