import React from 'react';

import RemoveIcon from '@material-ui/icons/Delete';
import { ModalContainer } from '..';
import { Container, RemoveButton, ExpandedImg } from './styles';

function Thumbnail({ src, alt, expandable, onRemove }) {
  const [open, setOpen] = React.useState(false);

  function closeModal() {
    setOpen(false);
  }
  function openModal() {
    setOpen(true);
  }

  function handleRemove(e) {
    e.stopPropagation();
    onRemove();
  }

  return (
    <>
      {expandable && (
        <ModalContainer open={open} handleClose={closeModal}>
          <ExpandedImg src={src} alt={alt} />
        </ModalContainer>
      )}
      <Container
        expandable={expandable}
        onClick={expandable ? openModal : () => {}}
      >
        {onRemove && (
          <RemoveButton onClick={handleRemove} size="small" color="secondary">
            <RemoveIcon />
          </RemoveButton>
        )}
        <img src={src} alt={alt} />
      </Container>
    </>
  );
}

export default Thumbnail;
