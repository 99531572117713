import { CUSTOMER_KEY } from '../../config/consts';

export const GET_JOBS = '[jobs] GET';
export const FETCH_JOBS_SUCCESS = '[jobs] Fetch success';
export const FETCH_JOBS_ERROR = '[jobs] Fetch Error';
export const UPDATE_JOBS = '[jobs] Update';
export const SHOW_JOBS_SPINNER = '[jobs - ui] show spinner';
export const HIDE_JOBS_SPINNER = '[jobs - ui] hide spinner';
export const SHOW_DOCUMENTATION_MODAL = '[jobs - ui] show doc modal';
export const HIDE_DOCUMENTATION_MODAL = '[jobs - ui] hide doc modal';
export const SHOW_JOB_VPAS_MODAL = '[jobs - ui] show vpas modal';
export const HIDE_JOB_VPAS_MODAL = '[jobs - ui] hide vpas modal';

export const getJobs = (filter) => ({
  type: GET_JOBS,
  payload: {
    ...filter,
    filter: { customer: localStorage.getItem(CUSTOMER_KEY) },
  },
});

export const updateJobs = (data) => ({
  type: UPDATE_JOBS,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_JOBS_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_JOBS_SPINNER,
});

export const showDocumentationModal = () => ({
  type: SHOW_DOCUMENTATION_MODAL,
});

export const hideDocumentationModal = () => ({
  type: HIDE_DOCUMENTATION_MODAL,
});

export const showVPASModal = () => ({
  type: SHOW_JOB_VPAS_MODAL,
});

export const hideVPASModal = () => ({
  type: HIDE_JOB_VPAS_MODAL,
});
