export const GET_MOVIMENTATIONS = '[movimentations] GET';
export const SEARCH_MOVIMENTATIONS = '[movimentations] SEARCH';
export const FULL_SEARCH_MOVIMENTATIONS = '[movimentations] FULL SEARCH';
export const FETCH_MOVIMENTATIONS_SUCCESS = '[movimentations] Fetch success';
export const FETCH_MOVIMENTATIONS_ERROR = '[movimentations] Fetch Error';
export const SEARCH_MOVIMENTATIONS_SUCCESS = '[movimentations] SEARCH success';
export const SEARCH_MOVIMENTATIONS_ERROR = '[movimentations] SEARCH Error';
export const UPDATE_MOVIMENTATIONS = '[movimentations] Update';
export const UPDATE_MOVIMENTATIONS_SEARCH = '[movimentations] Update Search';
export const SHOW_MOVIMENTATIONS_SPINNER = '[movimentations - ui] show spinner';
export const HIDE_MOVIMENTATIONS_SPINNER = '[movimentations - ui] hide spinner';
export const SHOW_MOVIMENTATIONS_SEARCH_SPINNER = '[movimentations - ui] show SEARCH_spinner';
export const HIDE_MOVIMENTATIONS_SEARCH_SPINNER = '[movimentations - ui] hide SEARCH_spinner';

export const getMovimentations = (filter) => ({
  type: GET_MOVIMENTATIONS,
  payload: filter,
});

export const searchMovimentations = (filter) => ({
  type: SEARCH_MOVIMENTATIONS,
  payload: filter,
});
export const fullSearchMovimentations = (filter) => ({
  type: FULL_SEARCH_MOVIMENTATIONS,
  payload: filter,
});

export const updateMovimentations = (data) => ({
  type: UPDATE_MOVIMENTATIONS,
  payload: data,
});
export const updateMovimentationsSearch = (data) => ({
  type: UPDATE_MOVIMENTATIONS_SEARCH,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_MOVIMENTATIONS_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_MOVIMENTATIONS_SPINNER,
});

export const showSearchSpinner = () => ({
  type: SHOW_MOVIMENTATIONS_SEARCH_SPINNER,
});

export const hideSearchSpinner = () => ({
  type: HIDE_MOVIMENTATIONS_SEARCH_SPINNER,
});
