import { SET_MOVIMENTATIONS_FILTER } from '../actions/movimentationsFilter';

export function movimentationsFilterReducer(movimentationsFilter = {}, action) {
  switch (action.type) {
    case SET_MOVIMENTATIONS_FILTER:
      return action.payload;

    default:
      return movimentationsFilter;
  }
}
