import axios from 'axios';
import FileSaver from 'file-saver';
import { apiUrl, CUSTOMER_KEY } from '../config/consts';
import toast from '../utils/Toastify';
import { store } from '../redux/store';

function getFileName(result) {
  return result.headers['content-disposition']
    .match(/filename=(.+)/)[1]
    .replaceAll('"', '');
}

export function download(route, params) {
  toast.addInfo('Download should start momentarily');
  return axios
    .get(`${apiUrl}${route}`, {
      params,
      responseType: 'blob',
    })
    .then((result) => {
      FileSaver.saveAs(result.data, getFileName(result));
    })
    .catch((error) => {
      if (!error?.response?.data) return toast.addError(error?.message);
      const reader = new FileReader();
      reader.onload = function () {
        const result = JSON.parse(reader.result);
        toast.addError(result?.error || result?.message || error?.message);
      };
      reader.onerror = function () {
        toast.addError(error?.message);
      };
      return reader.readAsText(error?.response?.data);
    });
}

export function downloadReport(id) {
  const { loggedUser } = store.getState();
  return download(`events/${id}/export`, {
    format: 'pdf',
    preparedBy: loggedUser._id,
  });
}
export function exportHistory(equipmentID, filterByInventory) {
  const { historyFilter } = store.getState();
  download('events/export', {
    ...historyFilter,
    movimentation: equipmentID,
    ...(filterByInventory
      ? { inventory: localStorage.getItem(CUSTOMER_KEY) }
      : {}),
  });
}
