export function getSpecName(id, specLibrary) {
  return specLibrary.find(({ _id }) => _id === id)?.name;
}

export function getMaintenance(maintenance, maintenanceCategories) {
  let result = null;
  maintenanceCategories.some((category) => category.maintenances.some((mnt) => {
    if (mnt._id === maintenance) {
      result = mnt;
      return true;
    }
    return false;
  }));
  return result;
}
