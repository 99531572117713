import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import {
  Collapse, ListItemText, makeStyles, Tooltip, useMediaQuery, useTheme,
} from '@material-ui/core';
import { ImOnedrive as VaultIcon } from 'react-icons/im';
import {
  Menu,
  Home as HomeIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  DashboardRounded as InventoryIcon,
  ErrorOutlineRounded,
  Settings,
  GroupRounded,
  GroupWorkOutlined,
  VerifiedUser,
  CloudUpload,
  NotificationsNone,
  ExitToApp,
  AccountCircle,
  LocationOn,
  MenuBook,
  AccountBalanceWallet,
  Assessment,
  Build,
  History,
  Assignment,
  DeviceHub as HubIcon,
  LocalDrink as WellIcon,
  Ballot,
  BusinessCenter as BusinessUnitIcon,
} from '@material-ui/icons';
import { FaHandshake } from 'react-icons/fa';
import { RiFileDamageFill } from 'react-icons/ri';
import { IoIosPeople } from 'react-icons/io';

import { logout } from '../../redux/actions/loggedUser';
import { BlankSpace, ListIcon } from './styles';

function CompanyCodeIcon() {
  return <span style={{ fontWeight: 700 }}>CC</span>;
}
function MaterialOwnerIcon() {
  return <span style={{ fontWeight: 700 }}>MO</span>;
}

const drawerWidth = 240;

const styles = {
  iconStyle: {
    marginLeft: 25,
    cursor: 'pointer',
    color: '#717478',
  },
  dropdownIconStyle: {
    color: '#fff',
    position: 'absolute',
    top: 20,
    left: 20,
    cursor: 'pointer',
  },
  drawerIconStyle: {
    color: '#fff',
  },
  logoutIconStyle: {
    marginLeft: 10,
    height: 25,
    width: 25,
  },
  linkFont: {
    color: '#fff',
    textDecoration: 'none',
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    backgroundColor: '#2c2c32',
    color: 'white',
    width: theme.spacing(9) + 1,
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      overflowX: 'auto',
    },
  },
  drawerClose: {
    backgroundColor: '#2c2c32',
    color: 'white',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  toolbarIcon: {
    color: '#fff',
  },
  contentOpen: {
    width: `calc(100% - ${theme.spacing(9) + 1}px)`,
    height: 'calc(100vh - 98px)',
    overflow: 'auto',
    marginTop: 65,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  contentClose: {
    width: '100%',
    height: 'calc(100vh - 98px)',
    overflow: 'auto',
    marginTop: 65,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${theme.spacing(9) + 1}px)`,
    },
  },
  tooltip: {
    fontSize: 12,
  },
  scrollableMenu: {
    scrollbarColor: '#555 #aaa',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: 6,
    },
    '&::-webkit-scrollbar-track': {
      background: '#aaa',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#555',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#444',
    },
  },
}));

const menuOptions = [
  {
    path: '/equipments',
    label: 'Inventory',
    icon: <HomeIcon />,
  },
  {
    path: '/jobs',
    label: 'Jobs',
    icon: <Assignment />,
  },
  {
    path: '/maintenances',
    label: 'Maintenances',
    icon: <Build />,
  },
  {
    path: '/history',
    label: 'History',
    icon: <History />,
  },
  {
    path: '/vpas',
    label: 'V-PAS',
    icon: <ErrorOutlineRounded />,
  },
  {
    path: '/vault',
    label: 'Vault',
    icon: <VaultIcon className="react-icons" />,
  },
  {
    path: '/variance_report',
    label: 'Variance Report',
    icon: <Assessment />,
  },
  {
    path: '/equipment_import',
    label: 'Upload Inventory',
    icon: <CloudUpload />,
  },
  // {
  //   path: {
  //     pathname: '/equipments',
  //     state: { clear: true },
  //   },
  //   label: 'Select Inventory',
  //   icon: <Cached />,
  // },
];

const settingsOptions = [
  {
    path: '/users',
    label: 'Users',
    icon: <GroupRounded />,
  },
  {
    path: '/customers',
    label: 'Customers',
    icon: <IoIosPeople className="react-icons" />,
  },
  {
    path: '/roles',
    label: 'Roles',
    icon: <VerifiedUser />,
  },
  {
    path: '/projects',
    label: 'Projects',
    icon: <GroupWorkOutlined />,
  },
  {
    path: '/wells',
    label: 'Wells',
    icon: <WellIcon />,
  },
  {
    path: '/vendors',
    label: 'Vendors',
    icon: <FaHandshake className="react-icons" />,
  },
  {
    path: '/sloc',
    label: 'SLOCS',
    icon: <LocationOn />,
  },
  {
    path: '/original_charge_code',
    label: 'Charge Codes',
    icon: <AccountBalanceWallet />,
  },
  {
    path: '/material_master',
    label: 'Material Master',
    icon: <MenuBook />,
  },
  {
    path: '/damage_descriptions',
    label: 'Damage Description',
    icon: <RiFileDamageFill className="react-icons" />,
  },
  {
    path: '/service_categories',
    label: 'Service Categories',
    icon: <Ballot />,
  },
  {
    path: '/maintenance_categories',
    label: 'Maintenance Categories',
    icon: <Build />,
  },
  {
    path: '/business_units',
    label: 'Business Units',
    icon: <BusinessUnitIcon />,
  },
  {
    path: '/company_codes',
    label: 'Company Codes',
    icon: <CompanyCodeIcon />,
  },
  {
    path: '/owners',
    label: 'Material Owners',
    icon: <MaterialOwnerIcon />,
  },
];

function MenuOld({ dispatch }) {
  const [open, setOpen] = React.useState(false);
  const [openSettings, setOpenSettings] = React.useState(false);
  const classes = useStyles();
  const theme = useTheme();

  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const md = useMediaQuery(theme.breakpoints.down('md'));

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
        paperAnchorLeft: classes.scrollableMenu,
      }}
    >
      <div className={classes.toolbar}>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'rtl' ? (
            <ChevronRightIcon className={classes.toolbarIcon} />
          ) : (
            <ChevronLeftIcon className={classes.toolbarIcon} />
          )}
        </IconButton>
      </div>
      <List>
        {menuOptions.map((opt) => (
          <Link key={opt.label} to={opt.path} style={styles.linkFont}>
            <Tooltip
              title={opt.label}
              placement="right"
              arrow
              classes={{ tooltip: classes.tooltip }}
            >
              <ListItem button style={{ width: '100%' }}>
                <ListIcon style={styles.drawerIconStyle}>
                  {opt.icon}
                </ListIcon>
                <ListItemText primary={opt.label} />
              </ListItem>
            </Tooltip>
          </Link>
        ))}
      </List>
      <Divider />
      <List>
        <Tooltip
          title="Settings"
          placement="right"
          arrow
          classes={{ tooltip: classes.tooltip }}
        >
          <ListItem button onClick={() => setOpenSettings(!openSettings)}>
            <ListIcon style={styles.drawerIconStyle}>
              <Settings
                style={{ opacity: openSettings ? '30%' : '100%' }}
              />
            </ListIcon>
            <ListItemText
              primary="Settings"
              style={{ opacity: openSettings ? '30%' : '100%' }}
            />
          </ListItem>
        </Tooltip>
        <Collapse in={openSettings}>
          {settingsOptions.map((opt) => (
            <Link key={opt.label} to={opt.path} style={styles.linkFont}>
              <Tooltip
                title={opt.label}
                placement="right"
                arrow
                classes={{ tooltip: classes.tooltip }}
              >
                <ListItem button style={{ width: '100%' }}>
                  <ListIcon style={styles.drawerIconStyle}>
                    {opt.icon}
                  </ListIcon>
                  <ListItemText primary={opt.label} />
                </ListItem>
              </Tooltip>
            </Link>
          ))}
        </Collapse>
      </List>
      {md && (
        <>
          <BlankSpace />
          <List>
            <Link to="/" style={styles.linkFont}>
              <Tooltip
                title="Hub"
                placement="right"
                arrow
                classes={{ tooltip: classes.tooltip }}
              >
                <ListItem onClick={handleDrawerClose}>
                  <ListIcon style={styles.drawerIconStyle}>
                    <HubIcon />
                  </ListIcon>
                  <ListItemText primary="Hub" />
                </ListItem>
              </Tooltip>
            </Link>
            <Tooltip
              title="Logout"
              placement="right"
              arrow
              classes={{ tooltip: classes.tooltip }}
            >
              <ListItem onClick={handleLogout}>
                <ListIcon style={styles.drawerIconStyle}>
                  <ExitToApp />
                </ListIcon>
                <ListItemText primary="Logout" />
              </ListItem>
            </Tooltip>
          </List>
        </>
      )}
    </Drawer>
  );
}

export default MenuOld;
