export const GET_MAINTENANCES = '[maintenances] GET';
export const SEARCH_MAINTENANCES = '[maintenances] SEARCH';
export const FULL_SEARCH_MAINTENANCES = '[maintenances] FULL SEARCH';
export const FETCH_MAINTENANCES_SUCCESS = '[maintenances] Fetch success';
export const FETCH_MAINTENANCES_ERROR = '[maintenances] Fetch Error';
export const SEARCH_MAINTENANCES_SUCCESS = '[maintenances] SEARCH success';
export const SEARCH_MAINTENANCES_ERROR = '[maintenances] SEARCH Error';
export const UPDATE_MAINTENANCES = '[maintenances] Update';
export const UPDATE_MAINTENANCES_SEARCH = '[maintenances] Update Search';
export const SHOW_MAINTENANCES_SPINNER = '[maintenances - ui] show spinner';
export const HIDE_MAINTENANCES_SPINNER = '[maintenances - ui] hide spinner';
export const SHOW_MAINTENANCES_SEARCH_SPINNER = '[maintenances - ui] show SEARCH_spinner';
export const HIDE_MAINTENANCES_SEARCH_SPINNER = '[maintenances - ui] hide SEARCH_spinner';
export const DISABLE_MAINTENANCES_NOTIFICATION = '[maintenances] disable notifications';
export const ENABLE_MAINTENANCES_NOTIFICATION = '[maintenances] enable notifications';
export const SUCCESS_DISABLE_MAINTENANCES_NOTIFICATION = '[maintenances] SUCCESS disable notifications';
export const SUCCESS_ENABLE_MAINTENANCES_NOTIFICATION = '[maintenances] SUCCESS enable notifications';
export const ERROR_DISABLE_MAINTENANCES_NOTIFICATION = '[maintenances] ERROR disable notifications';
export const ERROR_ENABLE_MAINTENANCES_NOTIFICATION = '[maintenances] ERROR enable notifications';

export const getMaintenances = (filter) => ({
  type: GET_MAINTENANCES,
  payload: filter,
});

export const searchMaintenances = (filter) => ({
  type: SEARCH_MAINTENANCES,
  payload: filter,
});
export const fullSearchMaintenances = (filter) => ({
  type: FULL_SEARCH_MAINTENANCES,
  payload: filter,
});

export const updateMaintenances = (data) => ({
  type: UPDATE_MAINTENANCES,
  payload: data,
});
export const updateMaintenancesSearch = (data) => ({
  type: UPDATE_MAINTENANCES_SEARCH,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_MAINTENANCES_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_MAINTENANCES_SPINNER,
});

export const showSearchSpinner = () => ({
  type: SHOW_MAINTENANCES_SEARCH_SPINNER,
});

export const hideSearchSpinner = () => ({
  type: HIDE_MAINTENANCES_SEARCH_SPINNER,
});

export const enableNotifications = (payload) => ({
  type: ENABLE_MAINTENANCES_NOTIFICATION,
  payload,
});
export const disableNotifications = (payload) => ({
  type: DISABLE_MAINTENANCES_NOTIFICATION,
  payload,
});
