import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import {
  TextField,
  Button,
  IconButton,
  Grid,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import Alert from '../../../components/Alert';
import Spinner from '../../../components/Spinner';
import DropZone from '../../../components/DropZone';
import ModalContainer from '../../../components/ModalContainer';
import {
  Container,
  InputContent,
  ModalTitle,
  ButtonContainer,
  PicturesContainer,
  Picture,
} from './styles';
import { apiUrl } from '../../../config/consts';
import Toastify from '../../../utils/Toastify';
import {
  getCustomerMM,
  createCustomerMM,
  updateCustomerMM,
} from '../../../redux/actions/customerMM';

const styles = {
  formButton: {
    margin: 2,
  },
};

const CustomerMMModal = ({
  id,
  dispatch,
  customerMM,
  customerMMModal,
  handleClose,
  customerMMSpinner,
}) => {
  const [editPictures, setEditPictures] = useState(false);
  const [files, setFiles] = useState([]);
  const [data, setData] = useState({});
  const {
    rina_mm_number,
    mm_number,
    short_description,
    long_description,
    equipment_type,
    modifier,
    MRP_min_qty,
    MRP_max_qty,
    MRP_min_ftg,
    MRP_max_ftg,
    size,
    weight,
    grade,
    connection_1,
    connection_1_size,
    connection_1_weight,
    connection_1_grade,
    connection_2,
    connection_2_size,
    connection_2_weight,
    connection_2_grade,
    connection_configuration,
    nominal_wall,
    drift_diameter,
    range,
    pipe_type,
    manufacturer,
    manufacturer_pn,
    inspection_criteria,
    pressure_rating,
    primary_uom,
    secondary_uom,
    action_type,
    photos = [],
    hazmat,
  } = data;

  useEffect(() => {
    if (id !== 'new') {
      dispatch(getCustomerMM(id));
    }
  }, [id]);

  useEffect(() => {
    if (customerMM && id !== 'new') {
      setData(customerMM);
    }
  }, [customerMM]);

  useEffect(() => {
    if (!customerMMModal) {
      handleClose();
    }
  }, [customerMMModal]);

  const handleSave = async () => {
    const response = await Alert();
    if (response && response.confirm) {
      if (id === 'new') {
        dispatch(createCustomerMM(data));
      } else {
        dispatch(updateCustomerMM(data));
      }
    }
  };

  const handleEdit = (e) => {
    const { name, value } = e.target;
    setData((currentData) => ({ ...currentData, [name]: value }));
  };
  const handleEditSwitch = (e) => {
    const { name, checked } = e.target;
    setData((currentData) => ({ ...currentData, [name]: checked }));
  };

  function handleCloseEditPictureModal() {
    setEditPictures(false);
  }

  function handleEditPictures() {
    setEditPictures(true);
  }

  function handleChangeFiles(files) {
    setFiles(files);
  }

  function handleUpload() {
    Alert('Are you sure?', 'Do you really want to upload this picture?').then(
      ({ confirm }) => {
        if (!confirm) return;
        const formData = new FormData();
        Array.from(files).forEach((file) => {
          formData.append(`files`, file);
        });
        axios
          .put(`${apiUrl}material_masters/${id}/upload`, formData)
          .then((response) => {
            setFiles([]);
            dispatch(getCustomerMM(id));
            Toastify.addInfo('Uploaded successfully.');
          })
          .catch((error) => {
            Toastify.addError(
              error?.response?.data?.error || "Error - couldn't upload file(s)"
            );
          });
      }
    );
  }
  function handleDeletePicture(filename) {
    return function () {
      Alert(
        'Are you sure?',
        "You won't be able to revert this!",
        false,
        '',
        'Yes, delete it.'
      ).then(({ confirm }) => {
        if (!confirm) return;
        axios
          .delete(`${apiUrl}material_masters/${id}/remove/${filename}`)
          .then((response) => {
            dispatch(getCustomerMM(id));
            Toastify.addInfo('Removed successfully.');
          })
          .catch((error) => {
            Toastify.addError(
              error?.response?.data?.error || "Error - couldn't remove picture"
            );
          });
      });
    };
  }

  return (
    <>
      {customerMMSpinner && <Spinner overlay />}
      <ModalContainer
        open={editPictures}
        handleClose={handleCloseEditPictureModal}
      >
        <Container>
          <ModalTitle>Edit Pictures</ModalTitle>
          <PicturesContainer container spacing={2}>
            {photos?.map((photo) => (
              <Grid xs={12} sm={12} md={6} lg={3} xl={3} item>
                <Picture key={photo}>
                  <img src={`${apiUrl}file/image/${photo}`} />
                  <IconButton
                    size="small"
                    className="delete-button"
                    color="secondary"
                    onClick={handleDeletePicture(photo)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Picture>
              </Grid>
            ))}
          </PicturesContainer>
          <DropZone
            file={files}
            onChangeFile={handleChangeFiles}
            multiple
            limit={4}
            allowedExtensions={[
              'bmp',
              'gif',
              'ico',
              'jpeg',
              'jpg',
              'png',
              'svg',
              'tiff',
              'tif',
              'webp',
            ]}
          />
          <ButtonContainer>
            <Button onClick={handleCloseEditPictureModal} color="secondary">
              Close
            </Button>
            <Button onClick={handleUpload} variant="contained" color="primary">
              Upload Pictures
            </Button>
          </ButtonContainer>
        </Container>
      </ModalContainer>
      <Container style={{ opacity: customerMMSpinner ? 0.5 : 1 }}>
        <ModalTitle>
          {id === 'new' ? 'New' : 'Edit'} Customer Material Master
        </ModalTitle>
        <InputContent>
          <TextField
            label="RINA MM No"
            type="text"
            variant="outlined"
            value={rina_mm_number || ''}
            name="rina_mm_number"
            disabled
            fullWidth
          />
        </InputContent>
        <InputContent>
          <TextField
            label="CUSTOMER MM No"
            type="text"
            variant="outlined"
            value={mm_number || ''}
            name="mm_number"
            onChange={handleEdit}
            fullWidth
          />
        </InputContent>

        <InputContent>
          <TextField
            label="Short Description"
            type="text"
            variant="outlined"
            value={short_description || ''}
            name="short_description"
            onChange={handleEdit}
            fullWidth
          />
        </InputContent>
        <InputContent>
          <TextField
            label="Long Description"
            required
            type="text"
            variant="outlined"
            value={long_description || ''}
            name="long_description"
            onChange={handleEdit}
            fullWidth
          />
        </InputContent>
        <InputContent>
          <TextField
            label="Equipment Type"
            type="text"
            variant="outlined"
            value={equipment_type || ''}
            name="equipment_type"
            onChange={handleEdit}
            fullWidth
          />
        </InputContent>
        <InputContent>
          <TextField
            label="Modifier"
            type="text"
            variant="outlined"
            value={modifier || ''}
            name="modifier"
            onChange={handleEdit}
            fullWidth
          />
        </InputContent>
        <InputContent>
          <TextField
            label="MRP Minimum Quantity"
            type="text"
            variant="outlined"
            value={MRP_min_qty || ''}
            name="MRP_min_qty"
            onChange={handleEdit}
            fullWidth
          />
        </InputContent>
        <InputContent>
          <TextField
            label="MRP Maximum Quantity"
            type="text"
            variant="outlined"
            value={MRP_max_qty || ''}
            name="MRP_max_qty"
            onChange={handleEdit}
            fullWidth
          />
        </InputContent>
        <InputContent>
          <TextField
            label="MRP Minimum Footage"
            type="text"
            variant="outlined"
            value={MRP_min_ftg || ''}
            name="MRP_min_ftg"
            onChange={handleEdit}
            fullWidth
          />
        </InputContent>
        <InputContent>
          <TextField
            label="MRP Maximum Footage"
            type="text"
            variant="outlined"
            value={MRP_max_ftg || ''}
            name="MRP_max_ftg"
            onChange={handleEdit}
            fullWidth
          />
        </InputContent>
        <InputContent>
          <TextField
            label="Max OD Size"
            type="text"
            variant="outlined"
            value={size || ''}
            name="size"
            onChange={handleEdit}
            fullWidth
          />
        </InputContent>
        <InputContent>
          <TextField
            label="Tube Weight"
            type="text"
            variant="outlined"
            value={weight || ''}
            name="weight"
            onChange={handleEdit}
            fullWidth
          />
        </InputContent>
        <InputContent>
          <TextField
            label="Material Grade"
            type="text"
            variant="outlined"
            value={grade || ''}
            name="grade"
            onChange={handleEdit}
            fullWidth
          />
        </InputContent>
        <InputContent>
          <TextField
            label="Connection 1"
            type="text"
            variant="outlined"
            value={connection_1 || ''}
            name="connection_1"
            onChange={handleEdit}
            fullWidth
          />
        </InputContent>
        <InputContent>
          <TextField
            label="Connection 1 (SIZE)"
            type="text"
            variant="outlined"
            value={connection_1_size || ''}
            name="connection_1_size"
            onChange={handleEdit}
            fullWidth
          />
        </InputContent>
        <InputContent>
          <TextField
            label="Connection 1 (WEIGHT)"
            type="text"
            variant="outlined"
            value={connection_1_weight || ''}
            name="connection_1_weight"
            onChange={handleEdit}
            fullWidth
          />
        </InputContent>
        <InputContent>
          <TextField
            label="Connection 1 (GRADE)"
            type="text"
            variant="outlined"
            value={connection_1_grade || ''}
            name="connection_1_grade"
            onChange={handleEdit}
            fullWidth
          />
        </InputContent>
        <InputContent>
          <TextField
            label="Connection 2"
            type="text"
            variant="outlined"
            value={connection_2 || ''}
            name="connection_2"
            onChange={handleEdit}
            fullWidth
          />
        </InputContent>
        <InputContent>
          <TextField
            label="Connection 2 (SIZE)"
            type="text"
            variant="outlined"
            value={connection_2_size || ''}
            name="connection_2_size"
            onChange={handleEdit}
            fullWidth
          />
        </InputContent>
        <InputContent>
          <TextField
            label="Connection 2 (WEIGHT)"
            type="text"
            variant="outlined"
            value={connection_2_weight || ''}
            name="connection_2_weight"
            onChange={handleEdit}
            fullWidth
          />
        </InputContent>
        <InputContent>
          <TextField
            label="Connection 2 (GRADE)"
            type="text"
            variant="outlined"
            value={connection_2_grade || ''}
            name="connection_2_grade"
            onChange={handleEdit}
            fullWidth
          />
        </InputContent>
        <InputContent>
          <TextField
            label="Connection Configuration"
            type="text"
            variant="outlined"
            value={connection_configuration || ''}
            name="connection_configuration"
            onChange={handleEdit}
            fullWidth
          />
        </InputContent>
        <InputContent>
          <TextField
            label="Nominal Wall"
            type="text"
            variant="outlined"
            value={nominal_wall || ''}
            name="nominal_wall"
            onChange={handleEdit}
            fullWidth
          />
        </InputContent>
        <InputContent>
          <TextField
            label="Drift Diameter"
            type="text"
            variant="outlined"
            value={drift_diameter || ''}
            name="drift_diameter"
            onChange={handleEdit}
            fullWidth
          />
        </InputContent>
        <InputContent>
          <TextField
            label="Range"
            type="text"
            variant="outlined"
            value={range || ''}
            name="range"
            onChange={handleEdit}
            fullWidth
          />
        </InputContent>
        <InputContent>
          <TextField
            label="Pipe Type"
            type="text"
            variant="outlined"
            value={pipe_type || ''}
            name="pipe_type"
            onChange={handleEdit}
            fullWidth
          />
        </InputContent>
        <InputContent>
          <TextField
            label="Manufacturer"
            type="text"
            variant="outlined"
            value={manufacturer || ''}
            name="manufacturer"
            onChange={handleEdit}
            fullWidth
          />
        </InputContent>
        <InputContent>
          <TextField
            label="Manufacturer Part Number"
            type="text"
            variant="outlined"
            value={manufacturer_pn || ''}
            name="manufacturer_pn"
            onChange={handleEdit}
            fullWidth
          />
        </InputContent>
        <InputContent>
          <TextField
            label="Inspection Criteria"
            type="text"
            variant="outlined"
            value={inspection_criteria || ''}
            name="inspection_criteria"
            onChange={handleEdit}
            fullWidth
          />
        </InputContent>
        <InputContent>
          <TextField
            label="Pressure Rating (PSI)"
            type="text"
            variant="outlined"
            value={pressure_rating || ''}
            name="pressure_rating"
            onChange={handleEdit}
            fullWidth
          />
        </InputContent>
        <InputContent>
          <TextField
            label="Action Type"
            type="text"
            variant="outlined"
            value={action_type || ''}
            name="action_type"
            onChange={handleEdit}
            fullWidth
          />
        </InputContent>
        <InputContent>
          <TextField
            label="Primary UoM"
            type="text"
            variant="outlined"
            value={primary_uom || ''}
            name="primary_uom"
            onChange={handleEdit}
            fullWidth
          />
        </InputContent>
        <InputContent>
          <TextField
            label="Secondary UoM"
            type="text"
            variant="outlined"
            value={secondary_uom || ''}
            name="secondary_uom"
            onChange={handleEdit}
            fullWidth
          />
        </InputContent>
        <InputContent>
          <FormControlLabel
            control={
              <Switch
                checked={hazmat || false}
                onChange={handleEditSwitch}
                InputProps={{ name: 'hazmat' }}
                color="primary"
              />
            }
            label="Hazardous Material"
          />
        </InputContent>
        <PicturesContainer container spacing={2}>
          {photos?.map((photo) => (
            <Grid xs={6} sm={6} md={3} lg={3} xl={3} item>
              <Picture key={photo}>
                <img src={`${apiUrl}file/image/${photo}`} />
              </Picture>
            </Grid>
          ))}
        </PicturesContainer>
        <ButtonContainer>
          <Button
            color="secondary"
            onClick={handleClose}
            variant="contained"
            style={styles.formButton}
          >
            Cancel
          </Button>
          {id !== 'new' && (
            <Button
              color="default"
              onClick={handleEditPictures}
              variant="contained"
              style={styles.formButton}
            >
              Edit Pictures
            </Button>
          )}
          <Button
            color="primary"
            onClick={handleSave}
            variant="contained"
            style={styles.formButton}
          >
            Save
          </Button>
        </ButtonContainer>
      </Container>
    </>
  );
};

export default connect((state) => ({
  customerMM: state.customerMM,
  customerMMModal: state.ui.customerMMModal,
  customerMMSpinner: state.ui.customerMMLoading,
}))(CustomerMMModal);
