export const GET_COMPANY_CODE = '[company_code] GET';
export const UPDATE_COMPANY_CODE = '[company_code] UPDATE';
export const CREATE_COMPANY_CODE = '[company_code] CREATE';
export const DELETE_COMPANY_CODE = '[company_code] DELETE';
export const UPDATE_OBJECT_COMPANY_CODE = '[company_code] UPDATE OBJECT COMPANY_CODE';
// UI
export const SHOW_COMPANY_CODE_MODAL = '[company_code] SHOW MODAL';
export const HIDE_COMPANY_CODE_MODAL = '[company_code] HIDE MODAL';
// Spinner
export const UPDATE_COMPANY_CODE_SUCCESS = '[company_code] Update success';
export const UPDATE_COMPANY_CODE_ERROR = '[company_code] Update Error';
export const FETCH_COMPANY_CODE_SUCCESS = '[company_code] Fetch success';
export const FETCH_COMPANY_CODE_ERROR = '[company_code] Fetch Error';
export const SHOW_COMPANY_CODE_SPINNER = '[company_code - ui] show spinner';
export const HIDE_COMPANY_CODE_SPINNER = '[company_code - ui] hide spinner';

export const getCompanyCode = (id) => ({
  type: GET_COMPANY_CODE,
  payload: { _id: id },
});

export const createCompanyCode = (data) => ({
  type: CREATE_COMPANY_CODE,
  payload: data,
});

export const updateCompanyCode = (data) => ({
  type: UPDATE_COMPANY_CODE,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_COMPANY_CODE_MODAL,
});

export const hideModal = () => ({
  type: HIDE_COMPANY_CODE_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_COMPANY_CODE_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_COMPANY_CODE_SPINNER,
});

export const updateObjectCompanyCode = (data) => ({
  type: UPDATE_OBJECT_COMPANY_CODE,
  payload: data,
});
