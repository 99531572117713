import styled from 'styled-components';

export const ModalTitle = styled.div`
  padding-left: 16px;
  padding-bottom: 16px;
  font-size: 1.3rem;
`;

export const ModalContent = styled.div`
  min-width: 60vw;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ModalButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;
