import React from 'react';

import { Container } from './styles';

function ButtonSpinner({ parent, overlay, size = 'small' }) {
  return (
    <Container parent={parent} overlay={overlay}>
      <div className={`lds-ellipsis ${size}`}>
        <div />
        <div />
        <div />
        <div />
      </div>
    </Container>
  );
}

export default ButtonSpinner;
