import {
  GET_MAINTENANCES,
  UPDATE_MAINTENANCES,
  UPDATE_MAINTENANCES_SEARCH,
} from '../actions/maintenances';

const initState = {
  docs: [],
  page: 1,
  limit: 50,
  totalDocs: 0,
  query: '',
  filter: {},
  searchResults: [],
  totalSearch: 0,
};

export function maintenancesReducer(maintenances = initState, action) {
  switch (action.type) {
    case GET_MAINTENANCES:
      return { ...maintenances, ...action.payload };
    case UPDATE_MAINTENANCES:
      return {
        ...maintenances,
        ...(action.payload.docs ? action.payload : { docs: action.payload }),
      };
    case UPDATE_MAINTENANCES_SEARCH:
      return {
        ...maintenances,
        totalSearch: action.payload.totalDocs,
        searchResults: action.payload.docs,
      };
    default:
      return maintenances;
  }
}
