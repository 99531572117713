import {
  GET_WORK_ORDER,
  UPDATE_WORK_ORDER,
  UPDATE_OBJECT_WORK_ORDER,
  CREATE_WORK_ORDER,
  DELETE_WORK_ORDER,
} from '../actions/workOrder';

export function workOrderReducer(workOrder = {}, action) {
  switch (action.type) {
    case UPDATE_OBJECT_WORK_ORDER:
      return action.payload;
    case GET_WORK_ORDER:
    case UPDATE_WORK_ORDER:
    case CREATE_WORK_ORDER:
    case DELETE_WORK_ORDER:
    default:
      return workOrder;
  }
}
