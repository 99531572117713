import {
  GET_WELL,
  UPDATE_WELL,
  UPDATE_OBJECT_WELL,
  CREATE_WELL,
  DELETE_WELL,
} from '../actions/well';

export function wellReducer(well = {}, action) {
  switch (action.type) {
    case GET_WELL:
      return action.payload;
    case UPDATE_WELL:
      return action.payload;
    case CREATE_WELL:
      return action.payload;
    case DELETE_WELL:
      return action.payload;
    case UPDATE_OBJECT_WELL:
      return action.payload;

    default:
      return well;
  }
}
