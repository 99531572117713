import { GET_RECLASS_DAMAGES, UPDATE_RECLASS_DAMAGES } from '../actions/reclassDamages';

const initState = {
  docs: [],
  page: 1,
  limit: 10,
  totalDocs: 0,
};

export function reclassDamagesReducer(reclassDamages = initState, action) {
  switch (action.type) {
    case GET_RECLASS_DAMAGES:
      return action.payload;
    case UPDATE_RECLASS_DAMAGES:
      return action.payload;

    default:
      return reclassDamages;
  }
}
