import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';

export function Lottie({ animationData, width, height }) {
  const element = useRef(null);
  const lottieInstance = useRef();

  useEffect(() => {
    if (element.current) {
      lottieInstance.current = lottie.loadAnimation({
        animationData,
        container: element.current,
      });
    }
    return () => {
      lottieInstance.current?.destroy();
    };
  }, [animationData]);

  return <div style={{ width, height }} ref={element} />;
}
