import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  padding: 0.5rem 2rem;
`;

export const Form = styled.form`
  position: relative;
  padding: 1rem;
  border-radius: 5px;
  background-color: white;

  .headerContainer {
    margin-bottom: 16px;
  }
`;

export const Title = styled.div`
  font-size: 1.3rem;
  padding-left: 10px;
  font-weight: bold;
`;

export const InputContent = styled.form`
  width: 100%;
  padding: 7px 2rem 10px 2rem;
`;

export const ButtonContainer = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

export const ButtonsContainer = styled.span`
    display: flex;
`;
