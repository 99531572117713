import {
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  FETCH_USER_SUCCESS,
  FETCH_USER_ERROR,
  GET_USER,
  CREATE_USER,
  UPDATE_USER,
  updateObjectUser,
  showSpinner,
  hideSpinner,
  hideModal,
} from '../actions/user';
import { getUsers } from '../actions/users';
import Toastify from '../../utils/Toastify/index';
import { apiRequest } from '../actions/api';

const URL = 'users';

export const createUserFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === CREATE_USER) {
    dispatch(
      apiRequest(
        'POST',
        URL,
        action.payload,
        UPDATE_USER_SUCCESS,
        UPDATE_USER_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const updateUserFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === UPDATE_USER) {
    dispatch(
      apiRequest(
        'PUT',
        URL,
        action.payload,
        UPDATE_USER_SUCCESS,
        UPDATE_USER_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const getUserFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_USER) {
    const { _id } = action.payload;
    dispatch(
      apiRequest('GET-id', URL, { _id }, FETCH_USER_SUCCESS, FETCH_USER_ERROR),
    );
    dispatch(showSpinner());
  }
};

export const processUserCollection = ({ dispatch, getState }) => (next) => (
  action,
) => {
  next(action);
  if (action.type === FETCH_USER_SUCCESS) {
    dispatch(updateObjectUser(action.payload));
    dispatch(hideSpinner());
  } else if (action.type === UPDATE_USER_SUCCESS) {
    dispatch(updateObjectUser(action.payload));
    dispatch(hideModal());
    dispatch(hideSpinner());
    // To reload the page
    const { page, limit } = getState().users;
    dispatch(getUsers({ page, limit }));
    Toastify.addSuccess('User saved.');
  } else if (action.type === UPDATE_USER_ERROR) {
    dispatch(hideSpinner());
    Toastify.addError(action.payload || 'Error to save the user');
  }
};

export const userMdl = [
  createUserFlow,
  updateUserFlow,
  getUserFlow,
  processUserCollection,
];
