import {
  UPDATE_OBJECT_RECONCILE,
  CREATE_RECONCILE_FROM_COUNTING,
} from '../actions/reconcile';

export function reconcileReducer(reconcile = {}, action) {
  switch (action.type) {
    case CREATE_RECONCILE_FROM_COUNTING: {
      const individualData = JSON.parse(action.payload.get('individualData'));
      const equipment = individualData[0].movimentation;
      return { ...reconcile, request: { equipment } };
    }
    case UPDATE_OBJECT_RECONCILE:
      return action.payload;

    default:
      return reconcile;
  }
}
