export const GET_ACTIVITY = '[activity] GET';
export const UPDATE_ACTIVITY = '[activity] UPDATE';
export const CREATE_ACTIVITY = '[activity] CREATE';
export const DELETE_ACTIVITY = '[activity] DELETE';
export const UPDATE_OBJECT_ACTIVITY = '[activity] UPDATE OBJECT ACTIVITY';
// UI
export const SHOW_ACTIVITY_MODAL = '[activity] SHOW MODAL';
export const HIDE_ACTIVITY_MODAL = '[activity] HIDE MODAL';
// Spinner
export const UPDATE_ACTIVITY_SUCCESS = '[activity] Update success';
export const UPDATE_ACTIVITY_ERROR = '[activity] Update Error';
export const FETCH_ACTIVITY_SUCCESS = '[activity] Fetch success';
export const FETCH_ACTIVITY_ERROR = '[activity] Fetch Error';
export const SHOW_ACTIVITY_SPINNER = '[activity - ui] show spinner';
export const HIDE_ACTIVITY_SPINNER = '[activity - ui] hide spinner';

export const getActivity = (id) => ({
  type: GET_ACTIVITY,
  payload: { _id: id },
});

export const createActivity = (data) => ({
  type: CREATE_ACTIVITY,
  payload: data,
});

export const updateActivity = (data) => ({
  type: UPDATE_ACTIVITY,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_ACTIVITY_MODAL,
});

export const hideModal = () => ({
  type: HIDE_ACTIVITY_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_ACTIVITY_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_ACTIVITY_SPINNER,
});

export const updateObjectActivity = (data) => ({
  type: UPDATE_OBJECT_ACTIVITY,
  payload: data,
});
