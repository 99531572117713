import React from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { InputContent } from '../styles';

function DefaultInput({
  options,
  date,
  currency,
  lg = 4,
  md = 6,
  sm = 12,
  xs = 12,
  onChange,
  onChangeDate,
  ...props
}) {
  let Component = TextField;
  if (date) Component = KeyboardDatePicker;
  if (currency) Component = CurrencyTextField;
  return (
    <Grid lg={lg} md={md} sm={sm} xs={xs}>
      <InputContent>
        <Component
          size="small"
          variant="outlined"
          inputVariant="outlined"
          format="MM-dd-yyyy"
          fullWidth
          select={!!options}
          onChange={date ? onChangeDate(props.name) : onChange}
          {...props}
        >
          {!!options &&
            options
              .sort((a, b) => String(a.label).localeCompare(b.label))
              .map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
        </Component>
      </InputContent>
    </Grid>
  );
}

export default DefaultInput;
