import React from 'react';
import {
  Table,
  TableRow,
  TableCell,
  TableContainer,
  Box,
} from '@material-ui/core';
import InspectionRow from '../InspectionRow';
import ServiceTable from '../ResultServiceTable';
import MaintenanceTable from '../ResultMaintenanceTable';
import { TableHead, TableBody } from './styles';

function ApprovedTable({ data: { equipment_service: equipmentService = [] } }) {
  const hasRejection = (eqpId) => {
    return equipmentService.some(
      ({ status, equipment }) =>
        equipment._id === eqpId && status === 'Rejected'
    );
  };

  const approvedItems = equipmentService.filter(
    ({ status, equipment }) =>
      status === 'Approved' && !hasRejection(equipment._id)
  );

  const equipmentMap = approvedItems.reduce((map, item) => {
    if (!map[item.equipment._id]) {
      map[item.equipment._id] = {
        equipment: item.equipment,
        maintenances: [],
        services: [],
      };
    }
    if (item.service)
      map[item.equipment._id].services.push({ service: item.service });
    if (item.maintenance)
      map[item.equipment._id].maintenances.push({
        maintenance: item.maintenance,
      });
    return map;
  }, {});

  return (
    <Box marginTop="2rem">
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={13} align="center">
                Approved Items
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell />
              <TableCell>Unique ID</TableCell>
              <TableCell>RINA Description</TableCell>
              <TableCell>Customer MM No</TableCell>
              <TableCell>Total Qty. (EA)</TableCell>
              <TableCell>Footage</TableCell>
              <TableCell>COPAS Class</TableCell>
              <TableCell>Supplier MM No</TableCell>
              <TableCell>Supplier Batch No</TableCell>
              <TableCell>Heat/Lot</TableCell>
              <TableCell>RBW</TableCell>
              <TableCell>Facility Reference ID</TableCell>
              <TableCell>Manufacture</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!approvedItems.length && (
              <TableRow>
                <TableCell colSpan={13} align="center">
                  No Approved Items
                </TableCell>
              </TableRow>
            )}
            {Object.keys(equipmentMap).map((item) => (
              <InspectionRow
                equipment={equipmentMap[item].equipment}
                maintenances={equipmentMap[item].maintenances}
                services={equipmentMap[item].services}
                key={item}
                MaintenanceTableComponent={MaintenanceTable}
                ServiceTableComponent={ServiceTable}
                equipmentService={equipmentService}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default ApprovedTable;
