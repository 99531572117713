import {
  FETCH_VPASS_SUCCESS,
  FETCH_VPASS_ERROR,
  GET_VPASS,
  updateVPASs,
  showSpinner,
  hideSpinner,
} from '../actions/vpass';
import { apiRequest } from '../actions/api';

const URL = 'vpas';

export const getVPAsFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_VPASS) {
    dispatch(
      apiRequest(
        'GET',
        URL,
        action.payload,
        FETCH_VPASS_SUCCESS,
        FETCH_VPASS_ERROR,
        ['work_order'],
      ),
    );
    dispatch(showSpinner());
  }
};

export const processVPAsCollection = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_VPASS_SUCCESS) {
    dispatch(updateVPASs(action.payload));
    dispatch(hideSpinner());
  }
};

export const vpassMdl = [getVPAsFlow, processVPAsCollection];
