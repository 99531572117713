import {
  GET_ROLE, UPDATE_ROLE, UPDATE_OBJECT_ROLE, CREATE_ROLE, DELETE_ROLE,
} from '../actions/role';

export function roleReducer(role = {}, action) {
  switch (action.type) {
    case GET_ROLE:
      return action.payload;
    case UPDATE_ROLE:
      return action.payload;
    case CREATE_ROLE:
      return action.payload;
    case DELETE_ROLE:
      return action.payload;
    case UPDATE_OBJECT_ROLE:
      return action.payload;

    default:
      return role;
  }
}
