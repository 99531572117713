import {
  UPDATE_CHECK_OUT_SUCCESS,
  UPDATE_CHECK_OUT_ERROR,
  FETCH_CHECK_OUT_SUCCESS,
  FETCH_CHECK_OUT_ERROR,
  GET_CHECK_OUT,
  CREATE_CHECK_OUT,
  UPDATE_CHECK_OUT,
  updateObjectCheckOut,
  showSpinner,
  hideSpinner,
  hideModal,
} from '../actions/checkOut';
import Toastify from '../../utils/Toastify/index';
import { apiRequest } from '../actions/api';
import history from '../../services/history';
import { CUSTOMER_KEY } from '../../config/consts';

const URL = 'check-out';

export const createCheckOutFlow = ({ dispatch, getState }) => (next) => (
  action,
) => {
  next(action);
  if (action.type === CREATE_CHECK_OUT) {
    dispatch(
      apiRequest(
        'POST',
        URL,
        {
          ...action.payload,
          inventory: localStorage.getItem(CUSTOMER_KEY),
          user: getState().loggedUser._id,
        },
        UPDATE_CHECK_OUT_SUCCESS,
        UPDATE_CHECK_OUT_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const updateCheckOutFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === UPDATE_CHECK_OUT) {
    dispatch(
      apiRequest(
        'PUT',
        URL,
        action.payload,
        UPDATE_CHECK_OUT_SUCCESS,
        UPDATE_CHECK_OUT_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const getCheckOutFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_CHECK_OUT) {
    const { _id } = action.payload;
    dispatch(
      apiRequest(
        'GET-id',
        URL,
        { _id },
        FETCH_CHECK_OUT_SUCCESS,
        FETCH_CHECK_OUT_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const processCheckOutCollection = ({ dispatch, getState }) => (next) => (
  action,
) => {
  next(action);
  if (action.type === FETCH_CHECK_OUT_SUCCESS) {
    dispatch(updateObjectCheckOut(action.payload));
    dispatch(hideSpinner());
  } else if (action.type === UPDATE_CHECK_OUT_SUCCESS) {
    dispatch(updateObjectCheckOut(action.payload));
    dispatch(hideModal());
    dispatch(hideSpinner());
    history.replace(`/check_out/${action.payload._id}`);
    Toastify.addSuccess('Check-Out posted successfully.');
  } else if (action.type === UPDATE_CHECK_OUT_ERROR) {
    dispatch(hideSpinner());
    Toastify.addError(action.payload || 'Error posting Check-Out');
  }
};

export const checkOutMdl = [
  createCheckOutFlow,
  updateCheckOutFlow,
  getCheckOutFlow,
  processCheckOutCollection,
];
