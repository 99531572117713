import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TextField from '@material-ui/core/TextField';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import {
  AddRounded,
  ArrowBack,
  CloudUploadOutlined,
  EditRounded,
  Search,
} from '@material-ui/icons';
import { Button } from '@material-ui/core';
import { getVendors } from '../../redux/actions/vendors';
import { showModal, hideModal } from '../../redux/actions/vendor';
import Spinner from '../../components/Spinner/index';
import { Container } from './styles';
import ModalContainer from '../../components/ModalContainer/index';
import EditModal from '../Modals/Vendor';
import { CUSTOMER_KEY } from '../../config/consts';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  title: {
    fontSize: 20,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  row: {
    padding: 0,
  },
  cell: {
    fontSize: 12,
    padding: '0px 10px 0px 15px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  buttons: {
    color: 'blue',
  },
  checkButton: {
    color: 'green',
  },
  closeButton: {
    color: 'red',
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 0,
  },
}));

function Vendor({
  dispatch, vendors, vendorsLoading, vendorModal,
}) {
  const history = useHistory();
  const classes = useStyles();
  const [vendorsFormated, setVendorsFormated] = useState([]);
  const [modalContentId, setModalContentId] = useState('new');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(50);
  const [total, setTotal] = useState(0);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    dispatch(getVendors({ page: page + 1, limit }));
  }, [page, limit]);

  useEffect(() => {
    if (vendors.docs) {
      setVendorsFormated(vendors.docs);
      setTotal(vendors.totalDocs);
    }
  }, [vendors]);

  const handleBack = () => {
    history.replace('/equipments');
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleOpenModal = () => {
    dispatch(showModal());
  };
  const handleClick = (id) => {
    setModalContentId(id);
    handleOpenModal();
  };

  const handleCloseModal = () => {
    dispatch(hideModal());
  };

  const handleGoToImport = () => {
    history.push('/vendor/import');
  };

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(getVendors({ page: 1, limit, searchText }));
  };

  return (
    <Container>
      <ModalContainer open={vendorModal} handleClose={handleCloseModal}>
        <EditModal id={modalContentId} handleClose={handleCloseModal} />
      </ModalContainer>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Toolbar className={classes.toolBar}>
            <span
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <IconButton onClick={handleBack}>
                <ArrowBack />
              </IconButton>
              <Typography
                className={classes.title}
                variant="h6"
                id="tableTitle"
                component="span"
              >
                Vendors
              </Typography>
            </span>

            <span>
              <Button
                className={classes.buttons}
                onClick={() => handleClick('new')}
                startIcon={<AddRounded />}
              >
                New
              </Button>
              <Button
                color="secondary"
                onClick={handleGoToImport}
                startIcon={<CloudUploadOutlined />}
              >
                Import List
              </Button>
            </span>
          </Toolbar>
          <div
            style={{
              paddingTop: 10,
              width: 'fullWidth',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginLeft: '2rem',
              marginRight: '2rem',
            }}
          >
            <TextField
              label="Search"
              type="text"
              variant="standard"
              value={searchText}
              size="small"
              InputProps={{
                endAdornment: (
                  <IconButton onClick={handleSearch} size="small">
                    <Search />
                  </IconButton>
                ),
              }}
              name="vendor_name"
              onChange={(e) => setSearchText(e.target.value)}
              onKeyPress={(e) => (e.key === 'Enter' ? handleSearch(e) : null)}
              style={{ width: 300 }}
            />
          </div>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Vendor Name</TableCell>
                  <TableCell>Vendor Code</TableCell>
                  <TableCell>Vendor Type</TableCell>
                  <TableCell>Vendor SLOC Code</TableCell>
                  <TableCell>Vendor Address</TableCell>
                  <TableCell>Contact Name</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {!localStorage.getItem(CUSTOMER_KEY) ? (
                  <TableRow className={classes.row}>
                    <TableCell
                        colSpan={8}
                        className={classes.cell}
                        align="center"
                      >
                        <Typography variant="h6">
                          Select an inventory
                        </Typography>
                      </TableCell>
                  </TableRow>
                ) : (
                  <>
                      {vendorsLoading && (
                        <TableRow className={classes.row}>
                          <TableCell colSpan={8} className={classes.cell}>
                            <Spinner parent="Table" />
                          </TableCell>
                        </TableRow>
                      )}
                      {!vendorsLoading
                        && vendorsFormated.map((vendor) => (
                          <TableRow className={classes.row}>
                            <TableCell className={classes.cell}>
                              {vendor.vendor_name}
                            </TableCell>
                            <TableCell className={classes.cell}>
                              <b>{vendor.vendor_code}</b>
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {vendor.vendor_type}
                            </TableCell>
                            <TableCell className={classes.cell}>
                              <b>{vendor.vendor_sloc_code}</b>
                            </TableCell>
                            <TableCell className={classes.cell}>
                              <b>
                                {vendor.vendor_address.number}
                                ,
                                {' '}
                                {vendor.vendor_address.address}
                                ,
                                {' '}
                                {vendor.vendor_address.city}
                                ,
                                {' '}
                                {vendor.vendor_address.country}
                              </b>
                            </TableCell>

                            <TableCell className={classes.cell}>
                              {vendor.contact_name}
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {vendor.phone_number}
                            </TableCell>
                            <TableCell className={classes.cell}>
                              <IconButton
                                onClick={() => handleClick(vendor._id)}
                              >
                                <EditRounded />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                    </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[50, 100, 200, 500, 1000]}
            component="div"
            count={total}
            rowsPerPage={limit}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  vendorsLoading: state.ui.vendorsLoading,
  vendors: state.vendors,
  vendorModal: state.ui.vendorModal,
});

export default connect(mapStateToProps)(Vendor);
