import { UPDATE_COMPANY_CODES } from '../actions/companyCodes';

const initState = {
  docs: [],
  page: 1,
  limit: 10,
  totalDocs: 0,
};

export function companyCodesReducer(companyCodes = initState, action) {
  switch (action.type) {
    case UPDATE_COMPANY_CODES:
      return action.payload;

    default:
      return companyCodes;
  }
}
