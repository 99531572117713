export const GET_MAINTENANCE_CATEGORY = '[maintenanceCategory] GET';
export const UPDATE_MAINTENANCE_CATEGORY = '[maintenanceCategory] UPDATE';
export const CREATE_MAINTENANCE_CATEGORY = '[maintenanceCategory] CREATE';
export const DELETE_MAINTENANCE_CATEGORY = '[maintenanceCategory] DELETE';
export const UPDATE_OBJECT_MAINTENANCE_CATEGORY = '[maintenanceCategory] UPDATE OBJECT CATEGORY';
// UI
export const SHOW_MAINTENANCE_CATEGORY_MODAL = '[maintenanceCategory] SHOW MODAL';
export const HIDE_MAINTENANCE_CATEGORY_MODAL = '[maintenanceCategory] HIDE MODAL';
// Spinner
export const UPDATE_MAINTENANCE_CATEGORY_SUCCESS = '[maintenanceCategory] Update success';
export const UPDATE_MAINTENANCE_CATEGORY_ERROR = '[maintenanceCategory] Update Error';
export const FETCH_MAINTENANCE_CATEGORY_SUCCESS = '[maintenanceCategory] Fetch success';
export const FETCH_MAINTENANCE_CATEGORY_ERROR = '[maintenanceCategory] Fetch Error';
export const SHOW_MAINTENANCE_CATEGORY_SPINNER = '[maintenanceCategory - ui] show spinner';
export const HIDE_MAINTENANCE_CATEGORY_SPINNER = '[maintenanceCategory - ui] hide spinner';

export const getMaintenanceCategory = (id) => ({
  type: GET_MAINTENANCE_CATEGORY,
  payload: { _id: id },
});

export const createMaintenanceCategory = (data) => ({
  type: CREATE_MAINTENANCE_CATEGORY,
  payload: data,
});

export const updateMaintenanceCategory = (data) => ({
  type: UPDATE_MAINTENANCE_CATEGORY,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_MAINTENANCE_CATEGORY_MODAL,
});

export const hideModal = () => ({
  type: HIDE_MAINTENANCE_CATEGORY_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_MAINTENANCE_CATEGORY_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_MAINTENANCE_CATEGORY_SPINNER,
});

export const updateObjectMaintenanceCategory = (data) => ({
  type: UPDATE_OBJECT_MAINTENANCE_CATEGORY,
  payload: data,
});
