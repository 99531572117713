import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  FormGroup,
  MenuItem,
  Button,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';
import Alert from '../../../components/Alert/index';
import Spinner from '../../../components/Spinner/index';
import {
  Container,
  InputContent,
  ModalTitle,
  ButtonContainer,
  CheckboxContent,
} from './styles';

import { getUser, createUser, updateUser } from '../../../redux/actions/user';
import { getRoles } from '../../../redux/actions/roles';

const styles = {
  formButton: {
    margin: 2,
  },
};

function UserModal({
  id,
  dispatch,
  user,
  userModal,
  handleClose,
  userSpinner,
  allRoles,
}) {
  const [active, setActive] = useState(true);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [roles, setRoles] = useState('');

  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [rolesError, setRolesError] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    if (id !== 'new') {
      dispatch(getUser(id));
    }
    dispatch(getRoles({ page: 1, limit: 100 }));
  }, [id]);

  useEffect(() => {
    if (user && id !== 'new') {
      setActive(user.active);
      setName(user.name);
      setEmail(user.email);
      setRoles(user.roles);
      setPhoneNumber(user.phone_number);
    }
  }, [user]);

  useEffect(() => {
    if (!userModal) {
      handleClose();
    }
  }, [userModal]);

  const handleSave = async () => {
    let err = false;
    setNameError(!name);
    setEmailError(!email);
    setRolesError(!roles);
    setPasswordError(id === 'new' && !password);
    err = !name || !email || !roles || (id === 'new' && !password);

    if (!err) {
      const response = await Alert();
      if (response && response.confirm) {
        if (id === 'new') {
          dispatch(
            createUser({
              active,
              name,
              email,
              password,
              roles,
              phone_number: phoneNumber,
            }),
          );
        } else {
          dispatch(
            updateUser({
              _id: id,
              active,
              name,
              email,
              roles,
              phone_number: phoneNumber,
            }),
          );
        }
      }
    }
  };

  return (
    <>
      {userSpinner && <Spinner overlay />}
      <Container style={{ opacity: userSpinner ? 0.5 : 1 }}>
        <ModalTitle>
          {id === 'new' ? 'New' : 'Edit'}
          {' '}
          User
        </ModalTitle>
        <FormGroup>
          <CheckboxContent>
            <FormControlLabel
              control={(
                <Switch
                  checked={active}
                  onChange={(e) => setActive(e.target.checked)}
                />
              )}
              label="Active"
              labelPlacement="start"
            />
          </CheckboxContent>
          <InputContent>
            <TextField
              id="outlined-password-input"
              label="Name*"
              type="text"
              error={nameError}
              helperText={nameError && 'Name is required'}
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
            />
          </InputContent>
          <InputContent>
            <TextField
              id="outlined-password-input"
              label="E-mail*"
              type="text"
              error={emailError}
              helperText={emailError && 'Email is required'}
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
            />
          </InputContent>
          <InputContent>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="select-label">Role*</InputLabel>
              <Select
                labelId="select-label"
                id="select-label-component"
                value={roles}
                error={rolesError}
                helperText={rolesError && 'Role is required'}
                variant="outlined"
                onChange={(e) => setRoles(e.target.value)}
                fullWidth
              >
                {allRoles
                  && allRoles.map((r) => (
                    <MenuItem value={r._id}>{r.name}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </InputContent>
          <InputContent>
            <FormControl fullWidth>
              <PhoneInput
                fullWidth
                country="us"
                specialLabel="Phone Number"
                containerStyle={{ width: '100%' }}
                inputStyle={{ width: '100%' }}
                value={phoneNumber}
                onChange={(_rawValue, _data, _event, value) => setPhoneNumber(value)}
              />
              {/* {phoneNumberError && (
                <FormHelperText>
                  Phone number is required
                </FormHelperText>
              )} */}
            </FormControl>
          </InputContent>
          <InputContent>
            {id === 'new' && (
              <TextField
                id="outlined-password-input"
                label="Password*"
                type="password"
                helperText={passwordError && 'Password is required'}
                error={passwordError}
                variant="outlined"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
              />
            )}
          </InputContent>
          <ButtonContainer>
            <Button
              color="secondary"
              onClick={handleClose}
              variant="contained"
              style={styles.formButton}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={handleSave}
              variant="contained"
              style={styles.formButton}
            >
              Save
            </Button>
          </ButtonContainer>
        </FormGroup>
      </Container>
    </>
  );
}

export default connect((state) => ({
  user: state.user,
  userModal: state.ui.userModal,
  userSpinner: state.ui.userLoading,
  allRoles: state.roles.docs,
}))(UserModal);
