import React, { useState } from 'react';
import { Animated } from 'react-animated-css';
import { Grid } from '@material-ui/core';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import Spinner from '../../components/ButtonSpinner/index';
import {
  Container,
  BlurPage,
  TransparentColorPage,
  Form,
  Logo,
  Input,
  LoginButton,
  ForgotPasswordButton,
} from './styles';
import logoImage from '../../assets/images/logo.png';

import api from '../../services/Api';
import Toastify from '../../utils/Toastify';

function ResetPassword() {
  const history = useHistory();
  const location = useLocation();
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [loading, setLoading] = useState(false);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const data = { password, passwordConfirmation };
      const schema = Yup.object().shape({
        password: Yup.string().required('Password is required'),
        passwordConfirmation: Yup.string().oneOf(
          [Yup.ref('password'), null],
          'Passwords do not match',
        ),
      });

      await schema.validate(data);

      const token = location.search.replace('?token=', '');

      if (!token) {
        throw new Error();
      }

      await api.post('/reset-password', {
        password,
        passwordConfirmation,
        token,
      });

      Toastify.addSuccess('Done! Password change performed successfully');
      history.push('/login');
    } catch (error) {
      Toastify.addError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Container>

        <Animated animationIn="fadeInUp" animationOut="fadeOut" animationInDuration={1000} animationOutDuration={1000} style={{ display: 'flex' }}>
          <Form>
            <Grid container style={{ maxWidth: 440 }} spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                <Logo src={logoImage} />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                <Input placeholder="Password" value={password} type="password" onChange={(e) => setPassword(e.target.value)} />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                <Input placeholder="Password confirmation" value={passwordConfirmation} type="password" onChange={(e) => setPasswordConfirmation(e.target.value)} />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                <LoginButton onClick={handleResetPassword}>
                  {loading ? <Spinner color="#fff" /> : 'Reset password'}
                </LoginButton>
              </Grid>
            </Grid>
          </Form>
        </Animated>
      </Container>
      <BlurPage>
        <TransparentColorPage />
      </BlurPage>
    </>
  );
}

export default ResetPassword;
