export const SET_MOVIMENTATIONS_FILTER = '[movimentations] Set Filter';
export const SET_FILTER_LAYOUT = '[movimentations] Set Filter Layout';

export const setMovimentationsFilter = (filter) => ({
  type: SET_MOVIMENTATIONS_FILTER,
  payload: filter,
});

export const setFilterLayout = (layout) => ({
  type: SET_FILTER_LAYOUT,
  payload: layout,
});
