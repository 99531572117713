import { UPDATE_RESULT_LAYOUT_OBJECT } from '../actions/resultLayout';

export function resultLayoutReducer(resultLayout = {}, action) {
  switch (action.type) {
    case UPDATE_RESULT_LAYOUT_OBJECT:
      return action.payload;
    default:
      return resultLayout;
  }
}
