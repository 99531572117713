import {
  FETCH_LOGIN_SUCCESS,
  FETCH_LOGIN_ERROR,
  LOGIN,
  updateLoggedUser,
  showLoginSpinner,
  hideLoginSpinner,
} from '../actions/loggedUser';
import { getCompleteCart } from '../actions/inventory_cart';
import Toastify from '../../utils/Toastify/index';
import { apiRequest } from '../actions/api';

const URL = 'login';

export const login = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === LOGIN) {
    dispatch(
      apiRequest(
        'POST',
        URL,
        action.payload,
        FETCH_LOGIN_SUCCESS,
        FETCH_LOGIN_ERROR,
      ),
    );
    dispatch(showLoginSpinner());
  }
};

export const processLoginCollection = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_LOGIN_SUCCESS) {
    dispatch(updateLoggedUser({ ...action.payload, authenticated: true }));
    dispatch(getCompleteCart(action.payload._id));
    Toastify.addSuccess('Welcome to RINA RAMP');
    dispatch(hideLoginSpinner());
  } else if (action.type === FETCH_LOGIN_ERROR) {
    if (action.payload === 'Please, check your email inbox and copy the code.') {
      Toastify.addWarning(action.payload);
      dispatch(hideLoginSpinner());
    } else {
      Toastify.addError(action.payload);
      dispatch(hideLoginSpinner());
    }
  }
};

export const loggedUserMdl = [login, processLoginCollection];
