import { CUSTOMER_KEY } from '../../config/consts';

export const GET_VPAS = '[VPAS] GET';
export const UPDATE_VPAS = '[VPAS] UPDATE';
export const CREATE_VPAS = '[VPAS] CREATE';
export const DELETE_VPAS = '[VPAS] DELETE';
export const UPDATE_OBJECT_VPAS = '[VPAS] UPDATE OBJECT VPAS';
// UI
export const SHOW_VPAS_MODAL = '[VPAS] SHOW MODAL';
export const HIDE_VPAS_MODAL = '[VPAS] HIDE MODAL';
// Spinner
export const UPDATE_VPAS_SUCCESS = '[VPAS] Update success';
export const UPDATE_VPAS_ERROR = '[VPAS] Update Error';
export const FETCH_VPAS_SUCCESS = '[VPAS] Fetch success';
export const FETCH_VPAS_ERROR = '[VPAS] Fetch Error';
export const SHOW_VPAS_SPINNER = '[VPAS - ui] show spinner';
export const HIDE_VPAS_SPINNER = '[VPAS - ui] hide spinner';

export const getVPAS = (id) => ({
  type: GET_VPAS,
  payload: { _id: id },
});

export const createVPAS = (data) => ({
  type: CREATE_VPAS,
  payload: data,
});

export const updateVPAS = (data) => ({
  type: UPDATE_VPAS,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_VPAS_MODAL,
});

export const hideModal = () => ({
  type: HIDE_VPAS_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_VPAS_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_VPAS_SPINNER,
});

export const updateObjectVPAS = (data) => ({
  type: UPDATE_OBJECT_VPAS,
  payload: data,
});
