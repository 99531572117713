import { SET_MAINTENANCE_FILTER } from '../actions/maintenanceFilter';

export const maintenanceFilterInitialState = {
  maintenance: '',
  customer_MM: '',
  individualItemId: '',
  inspectionCriteria: '',
  effectedDate: '',
  interval: '',
};

export function maintenanceFilterReducer(
  maintenanceFilter = maintenanceFilterInitialState,
  action,
) {
  switch (action.type) {
    case SET_MAINTENANCE_FILTER:
      return action.payload;

    default:
      return maintenanceFilter;
  }
}
