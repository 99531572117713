import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import {
  Container,
  TitleContent,
  InputContainer,
  ButtonContainer,
} from './styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 600,
    position: 'relative',
    width: '100%',
  },
  button: {
    fontSize: 16,
    color: '#737373',
  },
  listItem: {
    width: '100%',
  },
  listAccordion: {
    padding: '0px 0px 0px 25px',
    height: 39,
    marginTop: -25,
  },
  questionLabel: {
    paddingTop: 2,
    paddingBottom: 2,
    fontSize: '0.8rem',
  },
  listItemQuestion: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderTop: '1px solid #ccc',
  },
  listItemPadding: {
    paddingTop: 4,
    paddingBottom: 4,
  },
  expansionHeader: {},
  questionLabelEmpty: {
    color: '#808080',
    fontSize: '0.8rem',
  },
}));

const EquipmentInformation = ({ movimentation }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);

  return (
    <Container>
      <Accordion expanded={expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          classes={{ content: classes.expansionHeader }}
          onClick={() => setExpanded(!expanded)}
        >
          <div className={classes.heading}>
            <TitleContent>Equipment Information</TitleContent>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container style={{ padding: '0px 10px 10px 10px' }}>
            <Grid item lg={3} md={6} sm={12} xs={12}>
              <InputContainer>
                <TextField
                  disabled
                  size="small"
                  label="Customer MM No."
                  variant="outlined"
                  value={movimentation?.customer_MM?.mm_number || ''}
                  fullWidth
                />
              </InputContainer>
            </Grid>
            <Grid item lg={3} md={6} sm={12} xs={12}>
              <InputContainer>
                <TextField
                  disabled
                  size="small"
                  label="Supplier Batch No."
                  variant="outlined"
                  value={movimentation?.batch_no || ''}
                  fullWidth
                />
              </InputContainer>
            </Grid>
            <Grid item lg={3} md={6} sm={12} xs={12}>
              <InputContainer>
                <TextField
                  disabled
                  size="small"
                  label="Equipment Type"
                  type="text"
                  variant="outlined"
                  value={movimentation?.customer_MM?.equipment_type || ''}
                  fullWidth
                />
              </InputContainer>
            </Grid>
            <Grid item lg={3} md={6} sm={12} xs={12}>
              <InputContainer>
                <TextField
                  disabled
                  size="small"
                  label="Modifier"
                  type="text"
                  variant="outlined"
                  value={movimentation?.customer_MM?.modifier || ''}
                  fullWidth
                />
              </InputContainer>
            </Grid>
            <Grid item lg={3} md={6} sm={12} xs={12}>
              <InputContainer>
                <TextField
                  disabled
                  size="small"
                  label="Max OD Size"
                  type="text"
                  variant="outlined"
                  value={movimentation?.customer_MM?.size || ''}
                  fullWidth
                />
              </InputContainer>
            </Grid>
            <Grid item lg={3} md={6} sm={12} xs={12}>
              <InputContainer>
                <TextField
                  disabled
                  size="small"
                  label="Tube Weight"
                  variant="outlined"
                  value={movimentation?.customer_MM?.weight || ''}
                  fullWidth
                />
              </InputContainer>
            </Grid>
            <Grid item lg={3} md={6} sm={12} xs={12}>
              <InputContainer>
                <TextField
                  disabled
                  size="small"
                  label="Material Grade"
                  type="text"
                  variant="outlined"
                  value={movimentation?.customer_MM?.grade || ''}
                  fullWidth
                />
              </InputContainer>
            </Grid>
            <Grid item lg={3} md={6} sm={12} xs={12}>
              <InputContainer>
                <TextField
                  disabled
                  size="small"
                  label="Connection 1"
                  type="text"
                  variant="outlined"
                  value={movimentation?.customer_MM?.connection_1 || ''}
                  fullWidth
                />
              </InputContainer>
            </Grid>
            <Grid item lg={3} md={6} sm={12} xs={12}>
              <InputContainer>
                <TextField
                  disabled
                  size="small"
                  label="Connection 1 (SIZE)"
                  type="text"
                  variant="outlined"
                  value={movimentation?.customer_MM?.connection_1_size || ''}
                  fullWidth
                />
              </InputContainer>
            </Grid>
            <Grid item lg={3} md={6} sm={12} xs={12}>
              <InputContainer>
                <TextField
                  disabled
                  size="small"
                  label="Connection 1 (WEIGHT)"
                  type="text"
                  variant="outlined"
                  value={movimentation?.customer_MM?.connection_1_weight || ''}
                  fullWidth
                />
              </InputContainer>
            </Grid>
            <Grid item lg={3} md={6} sm={12} xs={12}>
              <InputContainer>
                <TextField
                  disabled
                  size="small"
                  label="Connection 1 (GRADE)"
                  type="text"
                  variant="outlined"
                  value={movimentation?.customer_MM?.connection_1_grade || ''}
                  fullWidth
                />
              </InputContainer>
            </Grid>
            <Grid item lg={3} md={6} sm={12} xs={12}>
              <InputContainer>
                <TextField
                  disabled
                  size="small"
                  label="Connection 2"
                  type="text"
                  variant="outlined"
                  value={movimentation?.customer_MM?.connection_2 || ''}
                  fullWidth
                />
              </InputContainer>
            </Grid>
            <Grid item lg={3} md={6} sm={12} xs={12}>
              <InputContainer>
                <TextField
                  disabled
                  size="small"
                  label="Connection 2 (SIZE)"
                  type="text"
                  variant="outlined"
                  value={movimentation?.customer_MM?.connection_2_size || ''}
                  fullWidth
                />
              </InputContainer>
            </Grid>
            <Grid item lg={3} md={6} sm={12} xs={12}>
              <InputContainer>
                <TextField
                  disabled
                  size="small"
                  label="Connection 2 (WEIGHT)"
                  type="text"
                  variant="outlined"
                  value={movimentation?.customer_MM?.connection_2_weight || ''}
                  fullWidth
                />
              </InputContainer>
            </Grid>
            <Grid item lg={3} md={6} sm={12} xs={12}>
              <InputContainer>
                <TextField
                  disabled
                  size="small"
                  label="Connection 2 (GRADE)"
                  type="text"
                  variant="outlined"
                  value={movimentation?.customer_MM?.connection_2_grade || ''}
                  fullWidth
                />
              </InputContainer>
            </Grid>
            <Grid item lg={3} md={6} sm={12} xs={12}>
              <InputContainer>
                <TextField
                  disabled
                  size="small"
                  label="Connection Configuration"
                  type="text"
                  variant="outlined"
                  value={
                    movimentation?.customer_MM?.connection_configuration || ''
                  }
                  fullWidth
                />
              </InputContainer>
            </Grid>
            <Grid item lg={3} md={6} sm={12} xs={12}>
              <InputContainer>
                <TextField
                  disabled
                  size="small"
                  label="Material Layout"
                  type="text"
                  variant="outlined"
                  value={movimentation?.material_layout || ''}
                  fullWidth
                />
              </InputContainer>
            </Grid>
            <Grid item lg={3} md={6} sm={12} xs={12}>
              <InputContainer>
                <TextField
                  disabled
                  size="small"
                  label="SLOC"
                  type="text"
                  variant="outlined"
                  value={movimentation?.SLOC?.sloc_name || ''}
                  fullWidth
                />
              </InputContainer>
            </Grid>
            <Grid item lg={3} md={6} sm={12} xs={12}>
              <InputContainer>
                <TextField
                  disabled
                  size="small"
                  label="Assigned Project"
                  type="text"
                  variant="outlined"
                  value={
                    movimentation?.assigned_customer_charge_code?.well?.project
                      ?.name || ''
                  }
                  fullWidth
                />
              </InputContainer>
            </Grid>
            <Grid item lg={3} md={6} sm={12} xs={12}>
              <InputContainer>
                <TextField
                  disabled
                  size="small"
                  label="Assigned Charge Code"
                  type="text"
                  variant="outlined"
                  value={
                    movimentation?.assigned_customer_charge_code
                      ?.original_charge_code || ''
                  }
                  fullWidth
                />
              </InputContainer>
            </Grid>
            <Grid item lg={3} md={6} sm={12} xs={12}>
              <InputContainer>
                <TextField
                  disabled
                  size="small"
                  label="Department"
                  type="text"
                  variant="outlined"
                  value={movimentation?.department || ''}
                  fullWidth
                />
              </InputContainer>
            </Grid>
            <Grid item lg={3} md={6} sm={12} xs={12}>
              <InputContainer>
                <TextField
                  disabled
                  size="small"
                  label="Nominal Wall"
                  type="text"
                  variant="outlined"
                  value={movimentation?.customer_MM?.nominal_wall || ''}
                  fullWidth
                />
              </InputContainer>
            </Grid>
            <Grid item lg={3} md={6} sm={12} xs={12}>
              <InputContainer>
                <TextField
                  disabled
                  size="small"
                  label="Pipe Type"
                  type="text"
                  variant="outlined"
                  value={movimentation?.customer_MM?.pipe_type || ''}
                  fullWidth
                />
              </InputContainer>
            </Grid>
            <Grid item lg={3} md={6} sm={12} xs={12}>
              <InputContainer>
                <TextField
                  disabled
                  size="small"
                  label="Supplier Part No."
                  type="text"
                  variant="outlined"
                  value={movimentation?.supplier_part_no || ''}
                  fullWidth
                />
              </InputContainer>
            </Grid>
            <Grid item lg={3} md={6} sm={12} xs={12}>
              <InputContainer>
                <TextField
                  disabled
                  size="small"
                  label="Drift Diameter"
                  type="text"
                  variant="outlined"
                  value={movimentation?.customer_MM?.drift_diameter || ''}
                  fullWidth
                />
              </InputContainer>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

export default EquipmentInformation;
