import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { TextField, Button } from '@material-ui/core';
import Spinner from '../../../components/Spinner';
import {
  Container, InputContent, ModalTitle, ButtonContainer,
} from './styles';

const styles = {
  formButton: {
    margin: 2,
  },
};

function InspectionResultsModal({
  handleClose,
  documentSpinner,
  onSave,
  editData,
  onDelete,
}) {
  const [nameError, setNameError] = useState(false);
  const [data, setData] = useState({
    name: '',
  });
  useEffect(() => {
    if (editData) setData(editData);
    else {
      setData({
        name: '',
      });
    }
  }, [editData]);
  const { name } = data;

  const handleSave = async () => {
    let err = false;
    setNameError(!name);
    err = !name;

    if (!err) {
      onSave(data, !!editData);
    }
  };

  const handleEdit = (e) => {
    const { name, value } = e.target;
    setData((currentData) => ({ ...currentData, [name]: value }));
  };
  function handleFile(file) {
    setData((currentData) => ({ ...currentData, file }));
  }
  return (
    <>
      {documentSpinner && <Spinner overlay />}

      <Container style={{ opacity: documentSpinner ? 0.5 : 1 }}>
        <ModalTitle>
          {editData ? 'Edit a' : 'New'}
          {' '}
          Inspection
        </ModalTitle>
        <InputContent>
          <TextField
            label="Name"
            required
            type="text"
            error={nameError}
            helperText={nameError && 'Name is required'}
            variant="outlined"
            value={name || ''}
            name="name"
            onChange={handleEdit}
            fullWidth
          />
        </InputContent>

        <ButtonContainer>
          {editData && (
            <Button
              color="secondary"
              onClick={onDelete}
              variant="outlined"
              style={styles.formButton}
            >
              Delete
            </Button>
          )}
          <Button
            color="secondary"
            onClick={handleClose}
            variant="contained"
            style={styles.formButton}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={handleSave}
            variant="contained"
            style={styles.formButton}
          >
            Save
          </Button>
        </ButtonContainer>
      </Container>
    </>
  );
}

export default connect((state) => ({
  documentSpinner: state.ui.documentLoading,
}))(InspectionResultsModal);
