import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
import DefaultInput from './DefaultInput';
import { getSLOCS } from '../../../../redux/actions/slocs';
import TabPanel from '../../../../components/TabPanel';

function StorageAndAssemblyData({
  data,
  selectedTab,
  tabIndex,
  onChange,
  setData,
  errors = [],
}) {
  const dispatch = useDispatch();
  const equipmentLoading = useSelector(
    (state) => state.ui.movimentationLoading
  );
  const SLOCs = useSelector((state) => state.slocs?.docs || state.slocs || []);

  useEffect(() => {
    dispatch(getSLOCS({ filter: { customer: data.customer }, listAll: true }));
  }, [data.customer]);

  const getParentValues = (type, field) => {
    return data.parent?.material_layout === type ? data.parent?.[field] : '';
  };

  const SLOCOptions = SLOCs.map(({ _id, sloc_id, sloc_name }) => ({
    value: _id,
    label: `${sloc_id} - ${sloc_name}`,
  }));

  const warehouseOptions = SLOCs.find(
    ({ _id }) => _id === data.SLOC
  )?.warehouses?.map(({ name, _id }) => ({ value: _id, label: name }));

  const rackOptions = SLOCs.find(({ _id }) => _id === data.SLOC)
    ?.warehouses.find(({ _id }) => _id === data.warehouse_location)
    ?.racks?.map(({ name, _id }) => ({ value: _id, label: name }));

  const binOptions = SLOCs.find(({ _id }) => _id === data.SLOC)
    ?.warehouses.find(({ _id }) => _id === data.warehouse_location)
    ?.racks?.find(({ _id }) => _id === data.rack_location)
    ?.bins?.map(({ name, _id }) => ({ value: _id, label: name }));

  const onChangeLocation = (field) => (e) => {
    onChange(e);
    const fieldsMap = {
      rack: { bin_location: '' },
      warehouse: { bin_location: '', rack_location: '' },
      SLOC: { bin_location: '', rack_location: '', warehouse_location: '' },
    };
    setData(fieldsMap[field]);
  };

  const fields = [
    {
      label: 'SLOC',
      key: 'SLOC',
      options: SLOCOptions,
      onChange: onChangeLocation('SLOC'),
      required: true,
    },
    {
      label: 'Warehouse Location',
      key: 'warehouse_location',
      options: warehouseOptions,
      disabled: !data.SLOC,
      onChange: onChangeLocation('warehouse'),
    },
    {
      label: 'Rack Location',
      key: 'rack_location',
      options: rackOptions,
      disabled: !data.warehouse_location,
      onChange: onChangeLocation('rack'),
    },
    {
      label: 'Bin Location',
      key: 'bin_location',
      options: binOptions,
      disabled: !data.rack_location,
    },
    { label: 'Facility Reference ID', key: 'facility_reference_id' },
    {
      label: '(Name) Parent Assembly ID',
      key: 'assembly_name',
      disabled: true,
      value: getParentValues('B.O.M', 'individual_item_id'),
    },
    {
      label: '(Tag) Parent Assembly ID',
      key: 'assembly_tag',
      disabled: true,
      value: getParentValues('B.O.M', 'tag_number'),
    },
    {
      label: '(Name) Parent Container ID',
      key: 'container_name',
      disabled: true,
      value: getParentValues('Container', 'individual_item_id'),
    },
    {
      label: '(Tag) Parent Container ID',
      key: 'container_tag',
      disabled: true,
      value: getParentValues('Container', 'tag_number'),
    },
  ];

  return (
    <TabPanel value={selectedTab} index={tabIndex} dir="ltr">
      <Grid container style={{ opacity: equipmentLoading ? 0.3 : 1 }}>
        {fields.map(({ key, label, ...props }) => (
          <DefaultInput
            key={key}
            name={key}
            onChange={onChange}
            value={data[key]}
            label={label}
            error={errors.includes(key)}
            helperText={errors.includes(key) && `${label} is required`}
            {...props}
          />
        ))}
      </Grid>
    </TabPanel>
  );
}

export default StorageAndAssemblyData;
