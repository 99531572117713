import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Stepper,
  Step,
  StepButton,
  StepContent,
  IconButton,
  Button,
  Icon,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { createCycleCount } from '../../../redux/actions/cycleCount';
import { getSLOCS } from '../../../redux/actions/slocs';
import { getBins } from '../../../redux/actions/bins';
import Alert from '../../../components/Alert';
import { Container, Paper, Head, Title, Body } from './styles';
import { SelectSLOC, SelectBin, GeneralInfo } from './steps';

function GenerateCycleCounting() {
  const [step, setStep] = useState(0);
  const [data, setData] = useState({ sloc: '', bin: '', date: new Date() });

  const history = useHistory();

  const dispatch = useDispatch();
  const slocs = useSelector((state) => state.slocs?.docs || state.slocs || []);
  const bins = useSelector((state) => state.bins?.docs || state.bins || []);

  useEffect(() => {
    dispatch(getSLOCS({ filter: { listAll: true } }));
  }, []);

  useEffect(() => {
    if (!data.sloc) return;
    dispatch(getBins({ sloc: data.sloc, filter: { listAll: true } }));
    setStep(1);
  }, [data.sloc]);

  useEffect(() => {
    if (!data.bin) return;
    setStep(2);
  }, [data.bin]);

  const selectedSLOC = slocs.find(({ _id }) => _id.toString() === data.sloc);
  const selectedBin = bins.find(({ _id }) => _id.toString() === data.bin);

  const steps = [
    {
      Component: SelectSLOC,
      label: selectedSLOC
        ? `${selectedSLOC.sloc_id} - ${selectedSLOC.sloc_name}`
        : 'Select SLOC',
    },
    { Component: SelectBin, label: selectedBin?.name || 'Select Bin' },
    { Component: GeneralInfo, label: 'General Info' },
  ];

  const handleEdit = (e) => {
    const { name, value } = e.target;
    setData((currentState) => ({ ...currentState, [name]: value }));
  };

  const handleGoBack = () => {
    history.goBack();
  };

  const handleGenerate = async () => {
    const confirm = await Alert();
    if (!confirm.confirm) return;

    dispatch(createCycleCount(data));
  };

  const handleChangeStep = (target) => () => setStep(target);

  const handleNextStep = () => {
    setStep((currentStep) => currentStep + 1);
  };
  const handlePrevStep = () => {
    setStep((currentStep) => currentStep - 1);
  };

  return (
    <Container>
      <Paper>
        <Head>
          <Title>
            <IconButton onClick={handleGoBack}>
              <Icon>arrow_back</Icon>
            </IconButton>
            Generate Cycle Counting
          </Title>
        </Head>
        <Body>
          <Stepper orientation="vertical" activeStep={step}>
            {steps.map(({ Component, label }, index) => (
              <Step key={label}>
                <StepButton onClick={handleChangeStep(index)}>
                  {label}
                </StepButton>
                <StepContent>
                  <Component
                    data={data}
                    onEdit={handleEdit}
                    onNextStep={handleNextStep}
                    onPrevStep={handlePrevStep}
                  />
                </StepContent>
              </Step>
            ))}
          </Stepper>
          {step === steps.length - 1 && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleGenerate}
            >
              Generate
            </Button>
          )}
        </Body>
      </Paper>
    </Container>
  );
}

export default GenerateCycleCounting;
