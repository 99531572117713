import { GET_SLOCS, UPDATE_SLOCS } from '../actions/slocs';

const initState = {
  docs: [],
  page: 1,
  limit: 10,
  totalDocs: 0,
};

export function slocsReducer(slocs = initState, action) {
  switch (action.type) {
    case UPDATE_SLOCS:
      return action.payload;
    case GET_SLOCS:
    default:
      return slocs;
  }
}
