import React from 'react';
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Tooltip,
  Button,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import Check from '@material-ui/icons/Check';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { connect } from 'react-redux';
import { getWells } from '../../../redux/actions/wells';
import { getActivities } from '../../../redux/actions/activities';
import { getOriginalCharge } from '../../../redux/actions/originalCharges';
import { getSLOCS } from '../../../redux/actions/slocs';
import { CUSTOMER_KEY } from '../../../config/consts';
import Toastify from '../../../utils/Toastify';
import { getVendorsByType } from '../../../services/Vendors';
import { InputContent, Divider } from './styles';

function GeneralInfo({
  dispatch,
  projects,
  originalCharges,
  vendors,
  handleEdit,
  handleEditRevision,
  handleDate,
  data,
  setData,
  users,
  wells,
  slocs,
  activities,
  loadingActivities,
}) {
  const [contact, setContact] = React.useState('');
  const [selectedProject, setSelectedProject] = React.useState('');
  const [selectedWell, setSelectedWell] = React.useState('');
  const [transportationVendors, setTransportationVendors] = React.useState([]);

  const {
    rina_job_number,
    job_status,
    rina_work_order_no,
    revision: {
      cmef_form,
      activity,
      gombu_service_order,
      batch_no,
      alt_bill_Code,
      date_needed,
      vendor_reference_number,
      revision,
      customer_contact,
      coordinator,
      date_modified,
      start_date,
      end_date,
      charge_code,
      SLOC,
      vendor,
      point_of_contact,
      updated_fields,
      SLOC_to,
      transportation_provider,
      truck_number,
    },
  } = data;

  const selectedVendor = vendors?.docs?.find(
    ({ _id }) => _id === vendor || _id === vendor?._id
  );

  React.useEffect(() => {
    dispatch(
      getSLOCS({
        filter: { listAll: true, customer: localStorage.getItem(CUSTOMER_KEY) },
      })
    );
    dispatch(
      getActivities({
        filter: { listAll: true },
      })
    );
    getVendorsByType('Transportation').then((response) => {
      if (!response.ok) return;
      setTransportationVendors(response.data);
    });
  }, []);

  React.useEffect(() => {
    dispatch(getWells({ filter: { listAll: true, project: selectedProject } }));
  }, [selectedProject]);

  React.useEffect(() => {
    if (selectedWell) {
      dispatch(
        getOriginalCharge({
          page: 1,
          limit: 9999,
          filter: { listAll: true, well: selectedWell },
        })
      );
    }
  }, [selectedWell]);

  React.useEffect(() => {
    const selectedChargeCode = originalCharges
      ? originalCharges.find(
        ({ _id }) => _id === (charge_code?._id || charge_code)
      )
      : null;
    if (!selectedChargeCode) return;
    const well = wells.find(
      ({ _id }) =>
        _id === selectedChargeCode.well || _id === selectedChargeCode.well?._id
    );
    if (!well) return;
    setSelectedProject(well.project._id || well.project);
    setSelectedWell(well._id);
  }, [charge_code, originalCharges, wells]);

  React.useEffect(() => {
    if (!selectedVendor || !point_of_contact) return;
    const { point_of_contact: poc, phone, email, position } = point_of_contact;
    const selectedContact = selectedVendor?.vendor_contacts?.findIndex(
      ({ contact_name, contact_phone, contact_email, contact_position }) =>
        contact_name === poc &&
        contact_phone === phone &&
        contact_email === email &&
        contact_position === position
    );
    if (selectedContact < 0) return;
    setContact(selectedContact);
  }, [point_of_contact, selectedVendor]);

  function handleContact(e) {
    const value = e.target.value;
    setContact(value);
    setData((currentData) => ({
      ...currentData,
      revision: {
        ...currentData.revision,
        point_of_contact: {
          vendor,
          point_of_contact:
            selectedVendor?.vendor_contacts[value]?.contact_name,
          phone: selectedVendor?.vendor_contacts[value]?.contact_phone,
          email: selectedVendor?.vendor_contacts[value]?.contact_email,
          position: selectedVendor?.vendor_contacts[value]?.contact_position,
        },
      },
    }));
  };

  function handleCoordinator(e) {
    const value = e.target.value;

    const user = users.find((currentUser) => currentUser._id === value);

    setData((currentData) => ({
      ...currentData,
      revision: {
        ...currentData.revision,
        coordinator: value,
        contacts: [
          ...currentData.revision.contacts,
          {
            point_of_contact: user?.name,
            phone: user?.phone_number,
            email: user?.email,
            position: 'Coordinator',
          },
        ],
      },
    }));
  };

  function addContact() {
    setData((currentData) => ({
      ...currentData,
      revision: {
        ...currentData.revision,
        contacts: [
          ...currentData.revision.contacts,
          currentData.revision.point_of_contact,
        ],
      },
    }));
    Toastify.addSuccess('Added to contact list');
  };

  function isUpdated(field) {
    if (!updated_fields) return '';
    if (!Array.isArray(field))
      return updated_fields.includes(field) ? 'updated-field' : '';

    return field.some((currentField) => updated_fields.includes(currentField))
      ? 'updated-field'
      : '';
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h6">General Information</Typography>
        <Divider />
      </Grid>
      <Grid lg={4} md={6} sm={12} xs={12}>
        <InputContent className={isUpdated('start_date')}>
          <KeyboardDatePicker
            label="Start Date"
            size="small"
            variant="outlined"
            inputVariant="outlined"
            name="start_date"
            value={start_date}
            onChange={handleDate('start_date')}
            format="MM-dd-yyyy"
            fullWidth
          />
        </InputContent>
      </Grid>
      <Grid lg={4} md={6} sm={12} xs={12}>
        <InputContent>
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel id="select-label-project">Project</InputLabel>
            <Select
              size="small"
              labelId="select-label-project"
              name="project"
              value={selectedProject}
              variant="outlined"
              fullWidth
              onChange={(e) => {
                setSelectedProject(e.target.value);
                setSelectedWell(undefined);
                handleEditRevision({
                  target: { value: null, name: 'charge_code' },
                });
              }}
            >
              {projects &&
                projects.docs &&
                projects.docs.map((project) => (
                  <MenuItem value={project._id}>{project.name}</MenuItem>
                ))}
            </Select>
          </FormControl>
        </InputContent>
      </Grid>
      <Grid lg={4} md={6} sm={12} xs={12}>
        <InputContent>
          <TextField
            size="small"
            label="Revision"
            type="text"
            variant="outlined"
            name="revision"
            value={revision || ''}
            onChange={handleEdit}
            fullWidth
            disabled
            InputProps={{
              endAdornment: (
                <Tooltip title="Current Rev number of this work order.">
                  <IconButton color="primary" size="small">
                    <InfoOutlined />
                  </IconButton>
                </Tooltip>
              ),
            }}
          />
        </InputContent>
      </Grid>
      <Grid lg={4} md={6} sm={12} xs={12}>
        <InputContent className={isUpdated('end_date')}>
          <KeyboardDatePicker
            label="End Date"
            size="small"
            variant="outlined"
            inputVariant="outlined"
            name="end_date"
            value={end_date}
            onChange={handleDate('end_date')}
            format="MM-dd-yyyy"
            fullWidth
          />
        </InputContent>
      </Grid>
      <Grid lg={4} md={6} sm={12} xs={12}>
        <InputContent>
          <TextField
            disabled={!selectedProject}
            size="small"
            label="Well"
            type="text"
            variant="outlined"
            name="well"
            value={selectedWell}
            onChange={(e) => {
              setSelectedWell(e.target.value);
              handleEditRevision({
                target: { value: null, name: 'charge_code' },
              });
            }}
            fullWidth
            select
          >
            {wells.map((well) => (
              <MenuItem value={well._id}>{well.name}</MenuItem>
            ))}
          </TextField>
        </InputContent>
      </Grid>
      <Grid lg={4} md={6} sm={12} xs={12}>
        <InputContent>
          <KeyboardDatePicker
            label="Last Date Modified"
            size="small"
            variant="outlined"
            inputVariant="outlined"
            value={date_modified}
            name="date_modified"
            onChange={handleDate('date_modified')}
            format="MM-dd-yyyy"
            fullWidth
            disabled
          />
        </InputContent>
      </Grid>
      <Grid lg={4} md={6} sm={12} xs={12}>
        <InputContent className={isUpdated('cmef_form')}>
          <TextField
            size="small"
            label="CMEF Form"
            type="text"
            variant="outlined"
            name="cmef_form"
            value={cmef_form}
            onChange={handleEditRevision}
            fullWidth
          />
        </InputContent>
      </Grid>
      <Grid lg={4} md={6} sm={12} xs={12}>
        <InputContent className={isUpdated('charge_code')}>
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel id="select-label-charge_code">
              WBS Charge Code
            </InputLabel>
            <Select
              disabled={!selectedWell}
              size="small"
              labelId="select-label-charge_code"
              name="charge_code"
              value={charge_code?._id || charge_code || ''}
              variant="outlined"
              fullWidth
              onChange={handleEditRevision}
            >
              {originalCharges &&
                originalCharges.map((charge_code) => (
                  <MenuItem value={charge_code._id}>
                    {charge_code.original_charge_code}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </InputContent>
      </Grid>
      <Grid lg={4} md={6} sm={12} xs={12}>
        <InputContent className={isUpdated('charge_code')}>
          <FormControl required variant="outlined" size="small" fullWidth>
            <InputLabel id="select-label-SLOC">SLOC</InputLabel>
            <Select
              size="small"
              labelId="select-label-SLOC"
              name="SLOC"
              value={SLOC?._id || SLOC || ''}
              variant="outlined"
              fullWidth
              onChange={handleEditRevision}
            >
              {slocs &&
                slocs.map((SLOC) => (
                  <MenuItem value={SLOC._id} key={SLOC._id}>
                    {SLOC.sloc_id} - {SLOC.sloc_name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </InputContent>
      </Grid>
      <Grid lg={4} md={6} sm={12} xs={12}>
        <InputContent>
          <TextField
            size="small"
            label="RINA Work Order No"
            type="text"
            variant="outlined"
            name="rina_work_order_no"
            value={
              rina_work_order_no
                ? `${rina_work_order_no}-${revision ? revision.toString().padStart(2, '0') : '00'
                }`
                : ''
            }
            fullWidth
            disabled
            InputProps={{
              endAdornment: (
                <Tooltip title="This field is automatically filled when Work Order is posted.">
                  <IconButton color="primary" size="small">
                    <InfoOutlined />
                  </IconButton>
                </Tooltip>
              ),
            }}
          />
        </InputContent>
      </Grid>
      <Grid lg={4} md={6} sm={12} xs={12}>
        <InputContent>
          <TextField
            size="small"
            label="RINA Job No"
            type="text"
            variant="outlined"
            name="rina_job_number"
            value={rina_job_number || ''}
            onChange={handleEdit}
            fullWidth
            disabled
            InputProps={{
              endAdornment: (
                <Tooltip title="This field is automatically filled when Work Order is posted.">
                  <IconButton color="primary" size="small">
                    <InfoOutlined />
                  </IconButton>
                </Tooltip>
              ),
            }}
          />
        </InputContent>
      </Grid>
      <Grid lg={4} md={6} sm={12} xs={12}>
        <InputContent className={isUpdated('activity')}>
          <TextField
            size="small"
            label="Activity"
            type="text"
            variant="outlined"
            name="activity"
            value={activity}
            onChange={handleEditRevision}
            fullWidth
            select
            InputProps={{
              endAdornment: loadingActivities && (
                <CircularProgress
                  color="primary"
                  size={20}
                  style={{ marginRight: 16 }}
                />
              ),
            }}
          >
            {activities.map(({ _id, title }) => (
              <MenuItem key={_id} value={_id}>
                {title}
              </MenuItem>
            ))}
          </TextField>
        </InputContent>
      </Grid>
      <Grid lg={4} md={6} sm={12} xs={12}>
        <InputContent className={isUpdated('customer_contact')}>
          <TextField
            size="small"
            label="Customer Contact"
            type="text"
            variant="outlined"
            name="customer_contact"
            value={customer_contact || ''}
            onChange={handleEditRevision}
            fullWidth
          />
        </InputContent>
      </Grid>
      <Grid lg={4} md={6} sm={12} xs={12}>
        <InputContent>
          <TextField
            size="small"
            label="Job Status"
            type="text"
            variant="outlined"
            name="job_status"
            value={job_status}
            onChange={handleEdit}
            fullWidth
            select
          >
            <MenuItem value={'open'}>Open</MenuItem>
            <MenuItem value={'cancelled'}>Cancelled</MenuItem>
            <MenuItem value={'closed'}>Closed</MenuItem>
          </TextField>
        </InputContent>
      </Grid>
      <Grid lg={4} md={6} sm={12} xs={12}>
        <InputContent className={isUpdated('gombu_service_order')}>
          <TextField
            size="small"
            label="GOMBU Service Order"
            type="text"
            variant="outlined"
            name="gombu_service_order"
            value={gombu_service_order}
            onChange={handleEditRevision}
            fullWidth
          />
        </InputContent>
      </Grid>
      <Grid lg={4} md={6} sm={12} xs={12}>
        <InputContent className={isUpdated('coordinator')}>
          <TextField
            size="small"
            label="Coordinator"
            type="text"
            variant="outlined"
            name="coordinator"
            value={coordinator}
            onChange={handleCoordinator}
            fullWidth
            select
          >
            {users &&
              users.map((user) => (
                <MenuItem value={user._id}>{user.name}</MenuItem>
              ))}
          </TextField>
        </InputContent>
      </Grid>
      <Grid lg={4} md={6} sm={12} xs={12}>
        <InputContent className={isUpdated('batch_no')}>
          <TextField
            size="small"
            label="Batch No."
            type="text"
            variant="outlined"
            value={batch_no}
            name="batch_no"
            onChange={handleEditRevision}
            fullWidth
          />
        </InputContent>
      </Grid>
      <Grid lg={4} md={6} sm={12} xs={12}>
        <InputContent className={isUpdated('alt_bill_Code')}>
          <TextField
            size="small"
            label="Alt Bill Code"
            type="text"
            variant="outlined"
            name="alt_bill_Code"
            value={alt_bill_Code}
            onChange={handleEditRevision}
            fullWidth
          />
        </InputContent>
      </Grid>
      <Grid lg={4} md={6} sm={12} xs={12}>
        <InputContent className={isUpdated('date_needed')}>
          <KeyboardDatePicker
            label="Date Needed"
            size="small"
            variant="outlined"
            inputVariant="outlined"
            value={date_needed}
            name="date_needed"
            onChange={handleDate('date_needed')}
            format="MM-dd-yyyy"
            fullWidth
          />
        </InputContent>
      </Grid>
      <Grid lg={4} md={6} sm={12} xs={12}>
        <InputContent className={isUpdated('vendor_reference_number')}>
          <TextField
            size="small"
            label="Vendor Reference Number"
            type="text"
            variant="outlined"
            name="vendor_reference_number"
            value={vendor_reference_number}
            onChange={handleEditRevision}
            fullWidth
          />
        </InputContent>
      </Grid>
      <Grid lg={4} md={6} sm={12} xs={12}>
        <InputContent className={isUpdated('SLOC_to')}>
          <TextField
            size="small"
            label="SLOC to"
            type="text"
            variant="outlined"
            name="SLOC_to"
            value={SLOC_to || ''}
            onChange={handleEditRevision}
            fullWidth
            select
          >
            {slocs &&
              slocs.map((SLOC) => (
                <MenuItem value={SLOC._id} key={SLOC._id}>
                  {SLOC.sloc_id} - {SLOC.sloc_name}
                </MenuItem>
              ))}
          </TextField>
        </InputContent>
      </Grid>
      <Grid lg={4} md={6} sm={12} xs={12}>
        <InputContent className={isUpdated('transportation_provider')}>
          <TextField
            size="small"
            label="Transportation Provider"
            type="text"
            variant="outlined"
            name="transportation_provider"
            value={transportation_provider || ''}
            onChange={handleEditRevision}
            fullWidth
            select
          >
            {transportationVendors.map((vendor) => (
              <MenuItem value={vendor._id} key={vendor._id}>
                {vendor.vendor_name}
              </MenuItem>
            ))}
          </TextField>
        </InputContent>
      </Grid>
      <Grid lg={4} md={6} sm={12} xs={12}>
        <InputContent className={isUpdated('truck_number')}>
          <TextField
            size="small"
            label="Truck Number"
            type="text"
            variant="outlined"
            name="truck_number"
            value={truck_number || ''}
            onChange={handleEditRevision}
            fullWidth
          />
        </InputContent>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Service Site Information</Typography>
        <Divider />
      </Grid>
      <Grid lg={4} md={6} sm={12} xs={12}>
        <InputContent className={isUpdated('vendor')}>
          <FormControl variant="outlined" size="small" required fullWidth>
            <InputLabel id="select-label-vendor">Vendor</InputLabel>
            <Select
              size="small"
              labelId="select-label-vendor"
              name="vendor"
              value={vendor?._id || vendor || ''}
              variant="outlined"
              onChange={(e) => {
                handleEditRevision(e);
                setContact('');
                setData((currentData) => ({
                  ...currentData,
                  revision: { ...currentData.revision, point_of_contact: null },
                }));
              }}
              fullWidth
            >
              {vendors &&
                vendors.docs &&
                vendors.docs.map((vendor) => (
                  <MenuItem value={vendor._id}>{vendor.vendor_name}</MenuItem>
                ))}
            </Select>
          </FormControl>
        </InputContent>
      </Grid>
      {vendor && (
        <Grid lg={4} md={6} sm={12} xs={12}>
          <InputContent
            className={isUpdated([
              'point_of_contact.point_of_contact',
              'point_of_contact.phone',
              'point_of_contact.email',
              'point_of_contact.position',
            ])}
          >
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel id="select-label">Point of Contact</InputLabel>
              <Select
                size="small"
                labelId="select-label"
                name="contact"
                value={contact !== undefined ? contact : ''}
                variant="outlined"
                onChange={handleContact}
                fullWidth
              >
                {selectedVendor?.vendor_contacts.map((contact, index) => (
                  <MenuItem value={index}>{contact.contact_name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </InputContent>
        </Grid>
      )}
      {vendor && (
        <Grid item xs={12} container>
          <Grid lg={4} md={6} sm={12} xs={12}>
            <InputContent>
              <Typography variant="subtitle2">Address:</Typography>
              {selectedVendor?.vendor_address?.number},{' '}
              {selectedVendor?.vendor_address?.address},{' '}
              {selectedVendor?.vendor_address?.postal_code},{' '}
              {selectedVendor?.vendor_address?.city},{' '}
              {selectedVendor?.vendor_address?.state},{' '}
              {selectedVendor?.vendor_address?.country}
            </InputContent>
          </Grid>
          {contact !== '' && (
            <>
              <Grid lg={4} md={6} sm={12} xs={12}>
                <InputContent>
                  <Typography variant="subtitle2">Point of Contact:</Typography>{' '}
                  {selectedVendor?.vendor_contacts[contact]?.contact_name}
                </InputContent>
              </Grid>
              <Grid lg={4} md={6} sm={12} xs={12}>
                <InputContent>
                  <Typography variant="subtitle2">Phone:</Typography>{' '}
                  {selectedVendor?.vendor_contacts[contact]?.contact_phone}
                </InputContent>
              </Grid>
              <Grid lg={4} md={6} sm={12} xs={12}>
                <InputContent>
                  <Typography variant="subtitle2">Email:</Typography>{' '}
                  {selectedVendor?.vendor_contacts[contact]?.contact_email}
                </InputContent>
              </Grid>
              <Grid lg={4} md={6} sm={12} xs={12}>
                <InputContent>
                  <Typography variant="subtitle2">Position:</Typography>{' '}
                  {selectedVendor?.vendor_contacts[contact]?.contact_position}
                </InputContent>
              </Grid>
              <Grid xs={12}>
                <Button onClick={addContact} color="primary">
                  <Check /> Confirm Point of Contact
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      )}
    </Grid>
  );
}

export default connect((state) => ({
  originalCharges: state.originalCharges?.docs || state.originalCharges,
  vendors: state.vendors,
  projects: state.projects,
  users: state.users?.docs,
  wells: state.wells?.docs || state.wells,
  slocs: state.slocs?.docs || state.slocs,
  activities: state.activities?.docs || state.activities,
  loadingActivities: state.ui.activitiesLoading,
}))(GeneralInfo);
