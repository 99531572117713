export const GET_RECLASS = '[reclass] GET';
export const UPDATE_RECLASS = '[reclass] UPDATE';
export const CREATE_RECLASS = '[reclass] CREATE';
export const DELETE_RECLASS = '[reclass] DELETE';
export const UPDATE_OBJECT_RECLASS = '[reclass] UPDATE OBJECT RECLASS';
// UI
export const SHOW_RECLASS_MODAL = '[reclass] SHOW MODAL';
export const HIDE_RECLASS_MODAL = '[reclass] HIDE MODAL';
// Spinner
export const UPDATE_RECLASS_SUCCESS = '[reclass] Update success';
export const UPDATE_RECLASS_ERROR = '[reclass] Update Error';
export const FETCH_RECLASS_SUCCESS = '[reclass] Fetch success';
export const FETCH_RECLASS_ERROR = '[reclass] Fetch Error';
export const SHOW_RECLASS_SPINNER = '[reclass - ui] show spinner';
export const HIDE_RECLASS_SPINNER = '[reclass - ui] hide spinner';

export const getReclass = (id) => ({
  type: GET_RECLASS,
  payload: { _id: id },
});

export const createReclass = (data) => ({
  type: CREATE_RECLASS,
  payload: data,
});

export const updateReclass = (data) => ({
  type: UPDATE_RECLASS,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_RECLASS_MODAL,
});

export const hideModal = () => ({
  type: HIDE_RECLASS_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_RECLASS_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_RECLASS_SPINNER,
});

export const updateObjectReclass = (data) => ({
  type: UPDATE_OBJECT_RECLASS,
  payload: data,
});
