import { UPDATE_OBJECT_VPAS } from '../actions/vpas';

export function vpasReducer(vpas = {}, action) {
  switch (action.type) {
    case UPDATE_OBJECT_VPAS:
      return action.payload;

    default:
      return vpas;
  }
}
