import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { InputContainer } from '../../styles';
import { tableStyles } from '../../../../../styles/tableStyles';

function StatusField({
  value,
  onChange,
  name = 'status',
  label = 'Equipment Status',
  disabled,
}) {
  const classes = tableStyles();

  return (
    <InputContainer>
      <FormControl
        variant="outlined"
        size="small"
        className={classes.formControl}
        fullWidth
        disabled={disabled}
      >
        <InputLabel id="status_label">{label}</InputLabel>
        <Select
          size="small"
          labelId="status_label"
          fullWidth
          value={value}
          onChange={onChange}
          name={name}
        >
          <MenuItem value="Available">Available</MenuItem>
          <MenuItem value="Allocated">Allocated</MenuItem>
          <MenuItem value="Consumed">Consumed</MenuItem>
          <MenuItem value="Consignment">Consignment</MenuItem>
          <MenuItem value="Divested">Divested</MenuItem>
          <MenuItem value="Flagged for Divestment">
            Flagged for Divestment
          </MenuItem>
          <MenuItem value="On Order">On Order</MenuItem>
        </Select>
      </FormControl>
    </InputContainer>
  );
}

export default StatusField;
