import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {
  KeyboardArrowDown as ExpandedIcon,
  KeyboardArrowRight as CollapsedIcon,
} from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { connect } from 'react-redux';
import ModalContainer from '../../../components/ModalContainer';
import { getMaintenanceCategories } from '../../../redux/actions/maintenanceCategories';
import { getSpecLibrary } from '../../../redux/actions/vault';
import { getMaintenance } from '../../../utils/Maintenance';
import { ButtonContainer, Container, InputContent, ModalTitle } from './styles';
import { createMaintenance } from '../../../redux/actions/maintenance';

const styles = {
  formButton: {
    margin: 2,
  },
};

const headers = [
  'Unique ID',
  'Customer MM No',
  'Total Qty. (EA)',
  'Footage',
  'COPAS Class',
  'Supplier',
  'Batch No',
  'Heat/Lot',
  'RBW',
  'Facility Reference ID',
  'Manufacturer',
];

const effectedDateList = [
  { label: 'Date of Manufacture', value: 'manufacture_date' },
  { label: 'Expiration Date', value: 'expiration_date' },
  { label: 'In Service Date', value: 'in_service_date' },
  { label: 'Date Last Reconciled', value: 'last_reconcile_date' },
  { label: 'Date Last Tested', value: 'date_last_tested' },
  { label: 'Date Last Rotated', value: 'date_last_rotated' },
];

function CreateMaintenanceToWorkOrder({
  open,
  onClose,
  dispatch,
  maintenanceCategories,
  specLibrary,
  maintenanceLoading,
  equipment = [],
  loggedUser,
}) {
  const [data, setData] = useState({
    maintenance: null,
    inspectionCriteria: '',
    effectedDate: '',
    interval: '',
    notificationTrigger: '',
    notificationFrequency: '',
  });
  const [selectedEquipment, setSelectedEquipment] = useState([]);
  const [eqpCollapsed, setEqpCollapsed] = useState(true);

  useEffect(() => {
    if (!equipment || !equipment.length) return;
    setSelectedEquipment(equipment.map(({ _id }) => _id));
  }, [equipment]);

  useEffect(() => {
    dispatch(getMaintenanceCategories({ filter: { listAll: true } }));
  }, []);
  useEffect(() => {
    dispatch(getSpecLibrary());
  }, []);
  const {
    maintenance,
    inspectionCriteria,
    effectedDate,
    interval,
    notificationTrigger,
    notificationFrequency,
  } = data;
  const [selectedCategory, setSelectedCategory] = useState(null);

  const setDataField = (field, value) => {
    setData((currentData) => ({ ...currentData, [field]: value }));
  };

  function getAllMaintenances() {
    return (
      maintenanceCategories.reduce(
        (mnts, category) => [...mnts, ...category.maintenances],
        []
      ) || []
    );
  }

  const specFiles = Array.isArray(specLibrary)
    ? specLibrary.filter(({ isDirectory, hidden }) => !isDirectory && !hidden)
    : [];

  const getSpec = () =>
    specFiles.find(({ _id }) => _id === inspectionCriteria) || null;

  const handleEdit = (e) => {
    const { name, value } = e.target;
    setDataField(name, value);
  };

  function handleEditMaintenance(_, value) {
    if (!value?._id) return setDataField('maintenance', null);
    const {
      _id,
      defaultInspectionCriteria,
      defaultEffectedDate,
      defaultInterval,
      defaultNotificationTrigger,
      defaultNotificationFrequency,
    } = value;
    setData((currentData) => ({
      ...currentData,
      maintenance: _id,
      inspectionCriteria: defaultInspectionCriteria,
      effectedDate: defaultEffectedDate,
      interval: defaultInterval,
      notificationTrigger: defaultNotificationTrigger,
      notificationFrequency: defaultNotificationFrequency,
    }));
  }

  function getCompleteSelection() {
    if (!selectedEquipment.length) return -1;
    const allSelected = equipment.every(({ _id }) =>
      selectedEquipment.includes(_id)
    );
    return allSelected ? 1 : 0;
  }

  function getEquipmentSelection(equipmentId) {
    return selectedEquipment.includes(equipmentId);
  }

  const areAllSelected = getCompleteSelection();

  function toggleSelectAll() {
    if (selectedEquipment.length) return setSelectedEquipment([]);
    setSelectedEquipment(equipment.map(({ _id }) => _id));
  }

  function toggleSelectEquipment(id) {
    return function () {
      const index = selectedEquipment.indexOf(id);
      if (index < 0)
        return setSelectedEquipment((currentSelection) => [
          ...currentSelection,
          id,
        ]);
      setSelectedEquipment((currentSelection) => {
        const copy = [...currentSelection];
        copy.splice(index, 1);
        return copy;
      });
    };
  }

  function handleCollapse() {
    setEqpCollapsed((currentStatus) => !currentStatus);
  }

  function handleSave() {
    dispatch(
      createMaintenance({
        maintenance: { ...data, user: loggedUser },
        equipment: selectedEquipment,
        request: {
          filter: {
            equipment: equipment.map(({ _id }) => _id),
            listAll: true,
            group: true,
          },
        },
      })
    );
  }

  return (
    <ModalContainer open={open} handleClose={onClose}>
      <Container>
        <ModalTitle>Tracked Maintenance</ModalTitle>
        <Grid container>
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <InputContent>
              <Autocomplete
                options={maintenanceCategories}
                getOptionLabel={({ name }) => name}
                onChange={(_, value) => {
                  setSelectedCategory(value);
                  setDataField('maintenance', '');
                }}
                value={selectedCategory}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    label="Maintenance Category"
                    type="text"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </InputContent>
          </Grid>
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <InputContent>
              <Autocomplete
                options={selectedCategory?.maintenances || getAllMaintenances()}
                getOptionLabel={({ name }) => name}
                onChange={handleEditMaintenance}
                value={getMaintenance(maintenance, maintenanceCategories)}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    label="Maintenance"
                    type="text"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </InputContent>
          </Grid>
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <InputContent>
              <Autocomplete
                getOptionSelected={({ _id }, value) => {
                  return _id === value?._id;
                }}
                value={getSpec()}
                onChange={(_, value) =>
                  setDataField('inspectionCriteria', value?._id)
                }
                fullWidth
                options={specFiles}
                autoHighlight
                getOptionLabel={({ name }) => name}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    label="Inspection Criteria"
                    required
                    variant="outlined"
                  />
                )}
              />
            </InputContent>
          </Grid>
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <InputContent>
              <TextField
                label="Effected Date"
                required
                type="text"
                variant="outlined"
                value={effectedDate}
                name="effectedDate"
                onChange={handleEdit}
                fullWidth
                select
              >
                {effectedDateList.map(({ label, value }) => (
                  <MenuItem value={value}>{label}</MenuItem>
                ))}
              </TextField>
            </InputContent>
          </Grid>
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <InputContent>
              <TextField
                label="Maintenance Interval (by days)"
                type="number"
                variant="outlined"
                value={interval}
                name="interval"
                onChange={handleEdit}
                fullWidth
              />
            </InputContent>
          </Grid>
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <InputContent>
              <TextField
                label="Notification Trigger (days left)"
                type="number"
                variant="outlined"
                value={notificationTrigger}
                name="notificationTrigger"
                onChange={handleEdit}
                fullWidth
              />
            </InputContent>
          </Grid>
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <InputContent>
              <TextField
                label="Maintenance Frequency (each X days)"
                type="number"
                variant="outlined"
                value={notificationFrequency}
                name="notificationFrequency"
                onChange={handleEdit}
                fullWidth
              />
            </InputContent>
          </Grid>
        </Grid>
        <Box marginTop="1rem">
          <Typography variant="h6">
            <Checkbox
              onClick={toggleSelectAll}
              color="primary"
              checked={areAllSelected === 1}
              indeterminate={!areAllSelected}
            />
            <IconButton onClick={handleCollapse}>
              {eqpCollapsed ? <CollapsedIcon /> : <ExpandedIcon />}
            </IconButton>
            Equipment
          </Typography>
          <Collapse in={!eqpCollapsed} timeout="auto" unmountOnExit>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  {headers.map((header) => (
                    <TableCell key={header}>{header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {equipment.map((eqp) => (
                  <TableRow key={eqp._id}>
                    <TableCell>
                      <Checkbox
                        color="primary"
                        onClick={toggleSelectEquipment(eqp._id)}
                        checked={getEquipmentSelection(eqp._id)}
                      />
                    </TableCell>
                    <TableCell>{eqp.individual_item_id}</TableCell>
                    <TableCell>{eqp.customer_MM?.mm_number}</TableCell>
                    <TableCell>{eqp.qty}</TableCell>
                    <TableCell>{eqp.footage}</TableCell>
                    <TableCell>{eqp.classification}</TableCell>
                    <TableCell>{eqp.supplier}</TableCell>
                    <TableCell>{eqp.batch_no}</TableCell>
                    <TableCell>{eqp.heat}</TableCell>
                    <TableCell>{eqp.RBW}</TableCell>
                    <TableCell>{eqp.facility_reference_id}</TableCell>
                    <TableCell>{eqp.manufacturer}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </Box>
        <ButtonContainer>
          <Button
            color="secondary"
            onClick={onClose}
            variant="contained"
            style={styles.formButton}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={handleSave}
            variant="contained"
            style={styles.formButton}
          >
            Save
          </Button>
        </ButtonContainer>
      </Container>
    </ModalContainer>
  );
}

function mapStateToProps(state) {
  return {
    maintenanceSpinner: state.ui.maintenanceLoading,
    specLibrary: state.specLibrary,
    maintenanceCategories:
      state.maintenanceCategories?.docs || state.maintenanceCategories || [],
    loggedUser: state.loggedUser?._id,
  };
}

export default connect(mapStateToProps)(CreateMaintenanceToWorkOrder);
