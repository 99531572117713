import {
  CREATE_CYCLE_COUNT_SUCCESS,
  UPDATE_CYCLE_COUNT_SUCCESS,
  UPDATE_CYCLE_COUNT_ERROR,
  FETCH_CYCLE_COUNT_SUCCESS,
  FETCH_CYCLE_COUNT_ERROR,
  GET_CYCLE_COUNT,
  CREATE_CYCLE_COUNT,
  UPDATE_CYCLE_COUNT,
  updateObjectCycleCount,
  showSpinner,
  hideSpinner,
  hideModal,
} from '../actions/cycleCount';
import { getCycleCounts } from '../actions/cycleCounts';
import history from '../../services/history';
import Toastify from '../../utils/Toastify';
import { apiRequest } from '../actions/api';

const URL = 'cycle_counts';

export const createCycleCountFlow =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === CREATE_CYCLE_COUNT) {
      dispatch(
        apiRequest(
          'POST',
          URL,
          action.payload,
          CREATE_CYCLE_COUNT_SUCCESS,
          UPDATE_CYCLE_COUNT_ERROR
        )
      );
      dispatch(showSpinner());
    }
  };

export const updateCycleCountFlow =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === UPDATE_CYCLE_COUNT) {
      dispatch(
        apiRequest(
          'PUT',
          URL,
          action.payload,
          UPDATE_CYCLE_COUNT_SUCCESS,
          UPDATE_CYCLE_COUNT_ERROR
        )
      );
      dispatch(showSpinner());
    }
  };

export const getCycleCountFlow =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === GET_CYCLE_COUNT) {
      const { _id } = action.payload;
      dispatch(
        apiRequest(
          'GET-id',
          URL,
          { _id },
          FETCH_CYCLE_COUNT_SUCCESS,
          FETCH_CYCLE_COUNT_ERROR
        )
      );
      dispatch(showSpinner());
    }
  };

export const processCycleCountCollection =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (action.type === FETCH_CYCLE_COUNT_SUCCESS) {
      dispatch(updateObjectCycleCount(action.payload));
      dispatch(hideSpinner());
    } else if (action.type === CREATE_CYCLE_COUNT_SUCCESS) {
      dispatch(updateObjectCycleCount(action.payload));
      dispatch(hideModal());
      dispatch(hideSpinner());
      // To reload the page
      history.goBack();
      Toastify.addSuccess('CycleCount saved.');
    } else if (action.type === UPDATE_CYCLE_COUNT_SUCCESS) {
      if (action.payload?.status === 'Complete') {
        const { page, limit } = getState().cycleCounts;
        dispatch(getCycleCounts({ page, limit }));
        history.push('/cycle_counting/');
      } else {
        dispatch(updateObjectCycleCount(action.payload));
        dispatch(hideModal());
        dispatch(hideSpinner());
      }
    } else if (action.type === UPDATE_CYCLE_COUNT_ERROR) {
      dispatch(hideSpinner());
      Toastify.addError(action.payload || 'Error to save the cycleCount');
    }
  };

export const cycleCountMdl = [
  createCycleCountFlow,
  updateCycleCountFlow,
  getCycleCountFlow,
  processCycleCountCollection,
];
