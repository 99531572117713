import { UPDATE_SPEC_LIBRARY_OBJECT } from '../actions/vault';

export function specLibraryReducer(specLibrary = [], action) {
  switch (action.type) {
    case UPDATE_SPEC_LIBRARY_OBJECT:
      return action.payload;

    default:
      return specLibrary;
  }
}
