export const GET_WELLS = '[wells] GET';
export const FETCH_WELLS_SUCCESS = '[wells] Fetch success';
export const FETCH_WELLS_ERROR = '[wells] Fetch Error';
export const UPDATE_WELLS = '[wells] Update';
export const SHOW_WELLS_SPINNER = '[wells - ui] show spinner';
export const HIDE_WELLS_SPINNER = '[wells - ui] hide spinner';

export const getWells = (filter) => ({
  type: GET_WELLS,
  payload: filter,
});

export const updateWells = (data) => ({
  type: UPDATE_WELLS,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_WELLS_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_WELLS_SPINNER,
});
