import React, { useState } from "react";

import {
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  ListItemSecondaryAction,
  Divider,
  TextField,
  Typography,
  Button,
  MenuItem,
  IconButton,
  Tooltip,
  Chip,
  Box,
} from "@material-ui/core";
import { Add, Delete } from "@material-ui/icons";
import { useSelector } from "react-redux";

const WorkInstructionBox = ({
  contact,
  contactIndex,
  setData,
  serviceCategories,
}) => {
  const vendors = useSelector((state) => state.vendors?.docs || []);
  const [category, setCategory] = useState("");
  const [service, setService] = useState([]);
  const handleCategory = (e) => setCategory(e.target.value);
  const handleService = (e) => {
    setService(e.target.value);
  };

  const categoryMap = serviceCategories.reduce(
    (obj, cat) => ({ ...obj, [cat._id]: cat.services }),
    {}
  );

  const onAdd = () => {
    setData((currentData) => {
      const newContacts = [...currentData.revision.contacts];

      newContacts[contactIndex].services = [
        ...(newContacts[contactIndex].services || []),
        ...service,
      ];
      return {
        ...currentData,
        revision: { ...currentData.revision, contacts: newContacts },
      };
    });
    setCategory("");
    setService([]);
  };

  const onRemove = (id) => () => {
    setData((currentData) => {
      const newContacts = [...currentData.revision.contacts];

      const index = newContacts[contactIndex].services.findIndex(
        (svc) => svc._id === id
      );
      newContacts[contactIndex].services.splice(index, 1);
      return {
        ...currentData,
        revision: { ...currentData.revision, contacts: newContacts },
      };
    });
  };

  return (
    <List
      subheader={
        <ListSubheader align="center">
          {contact?.vendor?.vendor_name ||
            vendors?.find(({ _id }) => _id === contact.vendor)?.vendor_name}
        </ListSubheader>
      }
    >
      <Divider />
      {contact.services?.map((service) => (
        <ListItem>
          <ListItemText>
            <Tooltip title="Remove">
              <IconButton
                size="small"
                color="secondary"
                onClick={onRemove(service._id)}
              >
                <Delete />
              </IconButton>
            </Tooltip>
            {service.name}
          </ListItemText>
        </ListItem>
      ))}
      <ListItem>
        <ListItemText>
          <Typography variant="subtitle2" style={{ marginBottom: 8 }}>
            Add service
          </Typography>
          <TextField
            size="small"
            label="Service Category"
            type="text"
            variant="outlined"
            name="category"
            value={category || ""}
            onChange={handleCategory}
            fullWidth
            select
          >
            {serviceCategories.map((cat) => (
              <MenuItem value={cat._id}>{cat.name}</MenuItem>
            ))}
          </TextField>
        </ListItemText>
      </ListItem>
      {category && (
        <ListItem>
          <ListItemText>
            <TextField
              size="small"
              label="Service"
              type="text"
              variant="outlined"
              name="service"
              value={service || []}
              onChange={handleService}
              fullWidth
              select
              disabled={!category}
              multiline
              SelectProps={{
                multiple: true,
                renderValue: (selected) => (
                  <Box display="flex" style={{ gap: 8 }} flexWrap="wrap">
                    {selected.map((item) => (
                      <Chip label={item.name} />
                    ))}
                  </Box>
                ),
                onClose: onAdd,
              }}
            >
              {categoryMap[category]?.map((svc) => (
                <MenuItem value={svc}>{svc.name}</MenuItem>
              ))}
            </TextField>
          </ListItemText>
        </ListItem>
      )}
    </List>
  );
};

export default WorkInstructionBox;
