import {
  GET_CUSTOMER,
  UPDATE_CUSTOMER,
  UPDATE_OBJECT_CUSTOMER,
  CREATE_CUSTOMER,
  DELETE_CUSTOMER,
} from '../actions/customer';

export function customerReducer(customer = {}, action) {
  switch (action.type) {
    case GET_CUSTOMER:
      return action.payload;
    case UPDATE_CUSTOMER:
      return action.payload;
    case CREATE_CUSTOMER:
      return action.payload;
    case DELETE_CUSTOMER:
      return action.payload;
    case UPDATE_OBJECT_CUSTOMER:
      return action.payload;

    default:
      return customer;
  }
}
