import { makeStyles } from '@material-ui/core/styles';

export const tableStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flex: 1,
    overflow: 'hidden',
    flexDirection: 'column',
    minHeight: 400,
  },
  title: {
    fontSize: 20,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflow: 'hidden',
  },
  row: { padding: 0 },
  cell: {
    fontSize: 12,
    padding: '10px 10px 10px 15px',
    '&.ellipsis': {
      maxWidth: 300,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  buttons: {
    color: 'blue',
  },
  checkButton: {
    color: 'green',
  },
  closeButton: {
    color: 'red',
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 0,
  },
  tableContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  table: {
    display: 'flex',
    flex: 5,
    flexDirection: 'column',
    scrollbarColor: '#555 #aaa',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: 6,
      height: 10,
    },
    '&::-webkit-scrollbar-track': {
      background: '#aaa',
      borderRadius: 3,
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#555',
      borderRadius: 3,
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#444',
    },
  },
}));
