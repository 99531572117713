import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Alert from '../../../components/Alert';
import Thumbnail from '../../../components/Thumbnail';
import DropZone from '../../../components/DropZone';
import Spinner from '../../../components/Spinner';
import {
  Container,
  InputContent,
  ModalTitle,
  ButtonContainer,
  FilesContainer,
  DropZoneContainer,
} from './styles';
import { getVendors } from '../../../redux/actions/vendors';
import { apiUrl } from '../../../config/consts';

import { getVPAS, createVPAS, updateVPAS } from '../../../redux/actions/vpas';

const styles = {
  formButton: {
    margin: 2,
  },
};

const VPASModal = ({
  id,
  dispatch,
  vpas,
  vpasModal,
  handleClose,
  vpasSpinner,
  vendors,
  workOrderId,
  loggedUser,
}) => {
  const [data, setData] = useState({
    employee: '',
    date: new Date(),
    vendor: null,
    coordinator: '',
    activity: '',
    down_time: '',
    down_time_hr: '',
    stop_work: '',
    near_miss: '',
    ncr: '',
    category_of_concern: '',
    descrepancy: '',
    action: '',
    newFiles: [],
    files: [],
  });
  const {
    employee,
    date,
    vendor,
    coordinator,
    activity,
    down_time,
    down_time_hr,
    stop_work,
    near_miss,
    ncr,
    category_of_concern,
    descrepancy,
    action,
    newFiles,
    files,
    work_order,
  } = data;

  useEffect(() => {
    if (workOrderId)
      handleEdit({ target: { name: 'work_order', value: workOrderId } });
  }, [workOrderId]);

  useEffect(() => {
    dispatch(getVendors({ page: 1, limit: 999 }));
  }, []);

  useEffect(() => {
    if (id !== 'new') {
      dispatch(getVPAS(id));
    }
  }, [id]);

  useEffect(() => {
    if (vpas && id !== 'new') {
      setData({ newFiles: [], ...vpas });
      console.log('vpas', vpas);
    }
  }, [vpas]);

  useEffect(() => {
    if (!vpasModal) {
      handleClose();
    }
  }, [vpasModal]);

  const handleSave = async () => {
    let err = false;

    if (!err) {
      const response = await Alert();
      if (response && response.confirm) {
        const jsonData = { ...data };
        delete jsonData.newFiles;
        delete jsonData._id;
        delete jsonData.work_order;
        const formData = new FormData();
        Object.keys(jsonData).forEach((field) =>
          formData.append(field, JSON.stringify(jsonData[field]))
        );

        Array.from(data.newFiles).forEach((file) =>
          formData.append('newFiles', file)
        );

        formData.append('user', loggedUser._id);
        if (work_order) formData.append('work_order', work_order);
        if (id === 'new') {
          dispatch(createVPAS(formData));
        } else {
          formData.append('_id', id);
          dispatch(updateVPAS(formData));
        }
      }
    }
  };

  const handleEdit = (e) => {
    const { name, value } = e.target;
    setData((currentData) => ({ ...currentData, [name]: value }));
  };

  function handleDate(value) {
    setData((currentData) => ({ ...currentData, date: value }));
  }
  function handleFiles(files) {
    handleEdit({ target: { name: 'newFiles', value: files } });
  }

  function handleRemovePicture(index) {
    return function () {
      const filesCopy = [...files];
      filesCopy.splice(index, 1);
      handleEdit({ target: { name: 'files', value: filesCopy } });
    };
  }

  return (
    <>
      {vpasSpinner && <Spinner overlay />}
      <Container style={{ opacity: vpasSpinner ? 0.5 : 1 }}>
        <ModalTitle>{id === 'new' ? 'New' : 'Edit'} V-PAS</ModalTitle>
        <InputContent>
          <TextField
            value={employee}
            name="employee"
            variant="outlined"
            onChange={handleEdit}
            fullWidth
            label="Employee"
          />
        </InputContent>
        <InputContent>
          <KeyboardDatePicker
            value={date || null}
            name="date"
            inputVariant="outlined"
            onChange={handleDate}
            fullWidth
            label="Date"
            format="MM-dd-yyyy"
          />
        </InputContent>
        <InputContent>
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel id="select-label">VPAS Vendor</InputLabel>
            <Select
              size="small"
              labelId="select-label"
              id="select-label-component"
              value={vendor || ''}
              variant="outlined"
              onChange={handleEdit}
              name="vendor"
              fullWidth
            >
              {vendors.map((vendor) => (
                <MenuItem value={vendor._id}>{vendor.vendor_name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </InputContent>
        <InputContent>
          <TextField
            value={coordinator}
            name="coordinator"
            variant="outlined"
            onChange={handleEdit}
            fullWidth
            label="Coordinator"
          />
        </InputContent>
        <InputContent>
          <TextField
            value={activity}
            name="activity"
            variant="outlined"
            onChange={handleEdit}
            fullWidth
            label="Activity"
          />
        </InputContent>
        <InputContent>
          <TextField
            value={down_time}
            name="down_time"
            variant="outlined"
            onChange={handleEdit}
            fullWidth
            label="Down Time"
          />
        </InputContent>
        <InputContent>
          <TextField
            value={down_time_hr}
            name="down_time_hr"
            variant="outlined"
            onChange={handleEdit}
            fullWidth
            label="Down Time (HR)"
          />
        </InputContent>
        <InputContent>
          <TextField
            value={stop_work}
            name="stop_work"
            variant="outlined"
            onChange={handleEdit}
            fullWidth
            label="Stop Work"
          />
        </InputContent>
        <InputContent>
          <TextField
            value={near_miss}
            name="near_miss"
            variant="outlined"
            onChange={handleEdit}
            fullWidth
            label="Near Miss"
          />
        </InputContent>
        <InputContent>
          <TextField
            value={ncr}
            name="ncr"
            variant="outlined"
            onChange={handleEdit}
            fullWidth
            label="NCR"
          />
        </InputContent>
        <InputContent>
          <TextField
            value={category_of_concern}
            name="category_of_concern"
            variant="outlined"
            onChange={handleEdit}
            fullWidth
            label="Category of Concern"
          />
        </InputContent>
        <InputContent>
          <TextField
            value={descrepancy}
            name="descrepancy"
            variant="outlined"
            onChange={handleEdit}
            fullWidth
            multiline
            label="Descrepancy"
          />
        </InputContent>
        <InputContent>
          <TextField
            value={action}
            name="action"
            variant="outlined"
            onChange={handleEdit}
            fullWidth
            multiline
            label="Action"
          />
        </InputContent>
        <FilesContainer>
          {files &&
            files.map(({ file, name }, index) => (
              <Thumbnail
                src={`${apiUrl}vault/download/${file?.filename}`}
                alt={name}
                onRemove={handleRemovePicture(index)}
                expandable
              />
            ))}
        </FilesContainer>
        <DropZoneContainer>
          <DropZone
            allowedExtensions={['png', 'jpg', 'jpeg', 'gif', 'svg', 'bmp']}
            file={newFiles}
            onChangeFile={handleFiles}
            multiple
          />
        </DropZoneContainer>
        <ButtonContainer>
          <Button
            color="secondary"
            onClick={handleClose}
            variant="contained"
            style={styles.formButton}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={handleSave}
            variant="contained"
            style={styles.formButton}
          >
            Save
          </Button>
        </ButtonContainer>
      </Container>
    </>
  );
};

export default connect((state) => ({
  vpas: state.vpas,
  vpasModal: state.ui.vpasModal,
  vpasSpinner: state.ui.vpasLoading,
  vendors: state?.vendors?.docs || [],
  loggedUser: state.loggedUser,
}))(VPASModal);
