export const GET_TRANSFER_OF_OWNERSHIP = '[transfer_of_ownership] GET';
export const UPDATE_TRANSFER_OF_OWNERSHIP = '[transfer_of_ownership] UPDATE';
export const CREATE_TRANSFER_OF_OWNERSHIP = '[transfer_of_ownership] CREATE';
export const DELETE_TRANSFER_OF_OWNERSHIP = '[transfer_of_ownership] DELETE';
export const UPDATE_OBJECT_TRANSFER_OF_OWNERSHIP = '[transfer_of_ownership] UPDATE OBJECT TRANSFER OF OWNERSHIP';
// UI
export const SHOW_TRANSFER_OF_OWNERSHIP_MODAL = '[transfer_of_ownership] SHOW MODAL';
export const HIDE_TRANSFER_OF_OWNERSHIP_MODAL = '[transfer_of_ownership] HIDE MODAL';
// Spinner
export const UPDATE_TRANSFER_OF_OWNERSHIP_SUCCESS = '[transfer_of_ownership] Update success';
export const UPDATE_TRANSFER_OF_OWNERSHIP_ERROR = '[transfer_of_ownership] Update Error';
export const FETCH_TRANSFER_OF_OWNERSHIP_SUCCESS = '[transfer_of_ownership] Fetch success';
export const FETCH_TRANSFER_OF_OWNERSHIP_ERROR = '[transfer_of_ownership] Fetch Error';
export const SHOW_TRANSFER_OF_OWNERSHIP_SPINNER = '[transfer_of_ownership - ui] show spinner';
export const HIDE_TRANSFER_OF_OWNERSHIP_SPINNER = '[transfer_of_ownership - ui] hide spinner';

export const getTransferOfOwnership = (id) => ({
  type: GET_TRANSFER_OF_OWNERSHIP,
  payload: { _id: id },
});

export const createTransferOfOwnership = (data) => ({
  type: CREATE_TRANSFER_OF_OWNERSHIP,
  payload: data,
});

export const updateTransferOfOwnership = (data) => ({
  type: UPDATE_TRANSFER_OF_OWNERSHIP,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_TRANSFER_OF_OWNERSHIP_MODAL,
});

export const hideModal = () => ({
  type: HIDE_TRANSFER_OF_OWNERSHIP_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_TRANSFER_OF_OWNERSHIP_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_TRANSFER_OF_OWNERSHIP_SPINNER,
});

export const updateObjectTransferOfOwnership = (data) => ({
  type: UPDATE_OBJECT_TRANSFER_OF_OWNERSHIP,
  payload: data,
});
