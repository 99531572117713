export const GET_DIRECTORY_INFO = '[VAULT] GET DIRECTORY INFO';
export const GET_ENTRY_INFO = '[VAULT] GET ENTRY INFO';
export const GET_DIRECTORY_CONTENT = '[VAULT] GET DIRECTORY CONTENT';
export const GET_DIRECTORY_STRUCTURE = '[VAULT] GET DIRECTORY STRUCTURE';
export const GET_COMPLETE_DIRECTORY_STRUCTURE = '[VAULT] GET COMPLETE DIRECTORY STRUCTURE';
export const GET_ROOT = '[VAULT] GET ROOT';
export const SUCCESS_GET_DIRECTORY_CONTENT = '[VAULT] SUCCESS GET DIRECTORY CONTENT';
export const SUCCESS_GET_ROOT = '[VAULT] SUCCESS GET ROOT';
export const SUCCESS_GET_DIRECTORY_INFO = '[VAULT] SUCCESS GET DIRECTORY INFO';
export const SUCCESS_GET_DIRECTORY_STRUCTURE = '[VAULT] SUCCESS GET DIRECTORY STRUCTURE';
export const SUCCESS_GET_ENTRY_INFO = '[VAULT] SUCCESS GET ENTRY INFO';
export const ERROR_GET_DIRECTORY_INFO = '[VAULT] ERROR GET DIRECTORY INFO';
export const ERROR_GET_ENTRY_INFO = '[VAULT] ERROR GET ENTRY INFO';
export const ERROR_GET_DIRECTORY_CONTENT = '[VAULT] ERROR GET DIRECTORY CONTENT';
export const ERROR_GET_DIRECTORY_STRUCTURE = '[VAULT] ERROR GET DIRECTORY STRUCTURE';
export const ERROR_GET_ROOT = '[VAULT] ERROR GET ROOT';
export const UPDATE_DIRECTORY_STRUCTURE = '[VAULT] UPDATE DIRECTORY STRUCTURE';
export const UPDATE_DIRECTORY_INFO = '[VAULT] UPDATE DIRECTORY INFO';
export const UPDATE_DIRECTORY_CONTENT = '[VAULT] UPDATE DIRECTORY CONTENT';
export const UPDATE_ENTRY_INFO = '[VAULT] UPDATE ENTRY INFO';
export const SHOW_STRUCTURE_SPINNER = '[VAULT] SHOW STRUCTURE SPINNER';
export const HIDE_STRUCTURE_SPINNER = '[VAULT] HIDE STRUCTURE SPINNER';
export const CREATE_FOLDER = '[VAULT] CREATE FOLDER';
export const UPLOAD_FILES = '[VAULT] UPLOAD FILES';
export const SUCCESS_CREATE_FOLDER = '[VAULT] SUCCESS CREATE FOLDER';
export const ERROR_CREATE_FOLDER = '[VAULT] ERROR CREATE FOLDER';
export const SUCCESS_UPLOAD_FILES = '[VAULT] SUCCESS UPLOAD FILES';
export const ERROR_UPLOAD_FILES = '[VAULT] ERROR UPLOAD FILES';
export const DELETE_ENTRY = '[VAULT] DELETE ENTRY';
export const DELETE_ENTRIES = '[VAULT] DELETE ENTRIES';
export const SUCCESS_DELETE_ENTRY = '[VAULT] SUCCESS_DELETE ENTRY';
export const SUCCESS_DELETE_ENTRIES = '[VAULT] SUCCESS_DELETE ENTRIES';
export const ERROR_DELETE_ENTRY = '[VAULT] ERROR_DELETE ENTRY';
export const GET_SPEC_LIBRARY = '[VAULT] GET SPEC LIBRARY';
export const ERROR_GET_SPEC_LIBRARY = '[VAULT] ERROR GET SPEC LIBRARY';
export const SUCCESS_GET_SPEC_LIBRARY = '[VAULT] SUCCESS GET SPEC LIBRARY';
export const UPDATE_SPEC_LIBRARY_OBJECT = '[VAULT] UPDATE SPEC LIBRARY OBJECT';

export function getDirectoryInfo(directory) {
  return { type: GET_DIRECTORY_INFO, payload: directory };
}
export function getRoot() {
  return { type: GET_ROOT };
}
export function getSpecLibrary() {
  return { type: GET_SPEC_LIBRARY };
}
export function getEntryInfo(entry) {
  return { type: GET_ENTRY_INFO, payload: entry };
}
export function getDirectoryContent(directory) {
  return { type: GET_DIRECTORY_CONTENT, payload: directory };
}
export function getDirectoryStructure(directory) {
  return { type: GET_DIRECTORY_STRUCTURE, payload: directory };
}
export function getCompleteDirectoryStructure(directory) {
  return { type: GET_COMPLETE_DIRECTORY_STRUCTURE, payload: directory };
}

export function updateDirectoryStructure(payload) {
  return { type: UPDATE_DIRECTORY_STRUCTURE, payload };
}
export function updateDirectoryInfo(payload) {
  return { type: UPDATE_DIRECTORY_INFO, payload };
}
export function updateSpecLibraryObject(payload) {
  return { type: UPDATE_SPEC_LIBRARY_OBJECT, payload };
}
export function updateDirectoryContent(payload) {
  return { type: UPDATE_DIRECTORY_CONTENT, payload };
}
export function updateEntryInfo(payload) {
  return { type: UPDATE_ENTRY_INFO, payload };
}
export function showStructureSpinner(entryId) {
  return { type: SHOW_STRUCTURE_SPINNER, payload: entryId };
}
export function hideStructureSpinner(entryId) {
  return { type: HIDE_STRUCTURE_SPINNER, payload: entryId };
}

export function createFolder(parent, name) {
  return { type: CREATE_FOLDER, payload: { parent, name } };
}

export function uploadFiles(parent, files) {
  return { type: UPLOAD_FILES, payload: { parent, files } };
}

export function deleteEntry(id) {
  return { type: DELETE_ENTRY, payload: id };
}
export function deleteEntries(id_list) {
  return { type: DELETE_ENTRIES, payload: { id_list } };
}
