import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  IconButton,
  Icon,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  Typography,
  Button,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import getRINADescription from '../../../utils/Equipment/getRINADescription';
import Alert from '../../../components/Alert';
import Spinner from '../../../components/Spinner';
import ReconcileForm from '../Reconcile';
import ModalContainer from '../../../components/ModalContainer';
import {
  getCycleCount,
  updateCycleCount,
} from '../../../redux/actions/cycleCount';
import {
  showReconcileModal,
  hideReconcileModal,
} from '../../../redux/actions/reconcile';
import {
  Container,
  Paper,
  Head,
  Title,
  Body,
  SectionTitle,
  Section,
  ButtonArea,
} from './styles';

function ReconcileCycleCounting() {
  const history = useHistory();
  const { id } = useParams();
  const [reconciledEquipment, setReconciledEquipment] = useState(null);
  const dispatch = useDispatch();
  const cycleCounting = useSelector((state) => state.cycleCount);
  const loading = useSelector((state) => state.ui.cycleCountLoading);
  const reconcileModalOpen = useSelector((state) => state.ui.reconcileModal);
  useEffect(() => {
    if (!id || id === 'new ') return;

    dispatch(getCycleCount(id));
  }, [id]);

  const { flagged_for_reconciliation = [], complete = [] } = cycleCounting;

  const pending = flagged_for_reconciliation.filter(
    ({ equipment: { _id: pendingId } }) =>
      complete.every(
        ({ equipment: { _id: completeId } }) =>
          completeId.toString() !== pendingId.toString()
      )
  );

  const handleGoBack = () => {
    history.goBack();
  };

  const handleReScan = (equipment) => async () => {
    const response = await Alert();
    if (!response?.confirm) return;

    dispatch(updateCycleCount({ _id: `${id}/Re-Scan`, equipment }));
  };

  const handleReconcileClick = (eqp) => () => {
    setReconciledEquipment(eqp);
    dispatch(showReconcileModal());
  };

  const handleCloseModal = () => {
    setReconciledEquipment(null);
    dispatch(hideReconcileModal());
  };

  const handleComplete = async () => {
    const response = await Alert();
    if (!response?.confirm) return;

    dispatch(updateCycleCount({ _id: `${id}/complete/reconcile` }));
  };

  return (
    <>
      <ModalContainer open={reconcileModalOpen} handleClose={handleCloseModal}>
        <ReconcileForm
          isModal
          equipment={[reconciledEquipment]}
          onCloseModal={handleCloseModal}
        />
      </ModalContainer>
      <Container>
        <Paper>
          <Head>
            <Title>
              <IconButton onClick={handleGoBack}>
                <Icon>arrow_back</Icon>
              </IconButton>
              Reconcile Cycle Counting
            </Title>
          </Head>
          <Body>
            <Section pending>
              <SectionTitle>Material Flagged for Reconciliation</SectionTitle>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Unique ID</TableCell>
                    <TableCell>RINA Description</TableCell>
                    <TableCell>COPA Class</TableCell>
                    <TableCell>Heat Number</TableCell>
                    <TableCell>RBW</TableCell>
                    <TableCell>Notes</TableCell>
                    <TableCell style={{ minWidth: 150 }}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!pending.length || loading ? (
                    <TableRow>
                      <TableCell colSpan={7}>
                        <Typography
                          variant="body"
                          align="center"
                          component="div"
                        >
                          {loading ? (
                            <Spinner parent="Table" />
                          ) : (
                            'There is no pending reconciliation at this count'
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    pending.map(({ equipment: eqp, note }) => (
                      <TableRow key={eqp._id}>
                        <TableCell>{eqp.individual_item_id || '--'}</TableCell>
                        <TableCell>
                          {getRINADescription(eqp.customer_MM) || '--'}
                        </TableCell>
                        <TableCell>{eqp.classification || '--'}</TableCell>
                        <TableCell>{eqp.heat || '--'}</TableCell>
                        <TableCell>{eqp.RBW || '--'}</TableCell>
                        <TableCell>{note || '--'}</TableCell>
                        <TableCell>
                          <Tooltip title="Reconcile">
                            <IconButton onClick={handleReconcileClick(eqp)}>
                              <Icon>cached</Icon>
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Re-scan">
                            <IconButton onClick={handleReScan(eqp._id)}>
                              <Icon>qr_code_scanner</Icon>
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </Section>
            <Section done>
              <SectionTitle>Material Complete</SectionTitle>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Action Taken</TableCell>
                    <TableCell>Unique ID</TableCell>
                    <TableCell>RINA Description</TableCell>
                    <TableCell>COPA Class</TableCell>
                    <TableCell>Heat Number</TableCell>
                    <TableCell>RBW</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!complete.length || loading ? (
                    <TableRow>
                      <TableCell colSpan={6}>
                        <Typography
                          variant="body"
                          align="center"
                          component="div"
                        >
                          {loading ? (
                            <Spinner parent="Table" />
                          ) : (
                            'There is no complete material at this count'
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    complete.map(({ action_taken, equipment: eqp }) => (
                      <TableRow key={eqp._id}>
                        <TableCell>{action_taken}</TableCell>
                        <TableCell>{eqp.individual_item_id || '--'}</TableCell>
                        <TableCell>
                          {getRINADescription(eqp.customer_MM) || '--'}
                        </TableCell>
                        <TableCell>{eqp.classification || '--'}</TableCell>
                        <TableCell>{eqp.heat || '--'}</TableCell>
                        <TableCell>{eqp.RBW || '--'}</TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </Section>
            <ButtonArea>
              <Button
                variant="contained"
                color="primary"
                disabled={pending.length}
                onClick={handleComplete}
              >
                Complete Reconcile
              </Button>
            </ButtonArea>
          </Body>
        </Paper>
      </Container>
    </>
  );
}

export default ReconcileCycleCounting;
