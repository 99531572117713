import React, { useState, useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { useLocation } from 'react-router-dom';
import {
  Container,
  TitleContent,
  InputContainer,
  ButtonContainer,
} from './styles';
import { getMaintenance } from '../../utils/Maintenance';
import { setMaintenanceFilter } from '../../redux/actions/maintenanceFilter';
import { getMaintenances } from '../../redux/actions/maintenances';
import { getMaintenanceCategories } from '../../redux/actions/maintenanceCategories';
import { getSpecLibrary } from '../../redux/actions/vault';
import { getCustomerMMs } from '../../redux/actions/customerMMs';
import { CUSTOMER_KEY } from '../../config/consts';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 600,
    position: 'relative',
    width: '100%',
  },
  button: {
    fontSize: 16,
    color: '#737373',
  },
  listItem: {
    width: '100%',
  },
  listAccordion: {
    padding: '0px 0px 0px 25px',
    height: 39,
    marginTop: -25,
  },
  questionLabel: {
    paddingTop: 2,
    paddingBottom: 2,
    fontSize: '0.8rem',
  },
  listItemQuestion: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderTop: '1px solid #ccc',
  },
  listItemPadding: {
    paddingTop: 4,
    paddingBottom: 4,
  },
  expansionHeader: {},
  questionLabelEmpty: {
    color: '#808080',
    fontSize: '0.8rem',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    fontSize: 10,
    height: 20,
    '& > span': { paddingLeft: 5, paddingRight: 5 },
  },
  formControl: {
    width: '100%',
    padding: 10,
    paddingTop: 0,
    '& > label': { paddingLeft: 10 },
  },
}));

const effectedDateList = [
  { label: 'Date of Manufacture', value: 'manufacture_date' },
  { label: 'Expiration Date', value: 'expiration_date' },
  { label: 'In Service Date', value: 'in_service_date' },
  { label: 'Date Last Reconciled', value: 'last_reconcile_date' },
  { label: 'Date Last Tested', value: 'date_last_tested' },
  { label: 'Date Last Rotated', value: 'date_last_rotated' },
];

const initialState = {
  maintenance: '',
  individualItemId: '',
  customer_MM: '',
  inspectionCriteria: '',
  effectedDate: '',
  interval: '',
};

const MaintenanceFilter = ({
  dispatch,
  maintenances,
  maintenanceFilter,
  maintenanceCategories,
  customerMMs,
  specLibrary,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const { state } = useLocation();


  const handleSearch = () => {
    dispatch(
      getMaintenances({
        page: Number.parseInt(maintenances.page, 10) || 1,
        limit: Number.parseInt(maintenances.limit, 10) || 50,
        ...(state?.movimentations
          ? {
              filter: {
                equipment: state.movimentations.map((eqp) => eqp?._id || eqp),
              },
            }
          : {}),
      })
    );
    setExpanded(false);
  };

  const clearFilter = () => {
    dispatch(setMaintenanceFilter({ ...maintenanceFilter, ...initialState }));
    dispatch(
      getMaintenances({
        page: 1,
        limit: 50,
      })
    );
    setExpanded(false);
  };

  useEffect(() => {
    dispatch(getSpecLibrary());
  }, []);

  useEffect(() => {
    dispatch(getMaintenanceCategories({ filter: { listAll: true } }));
  }, []);

  useEffect(() => {
    if (!localStorage.getItem(CUSTOMER_KEY)) return;
    dispatch(
      getCustomerMMs({
        customer: localStorage.getItem(CUSTOMER_KEY),
      })
    );
  }, []);

  const setDataField = (field, value) => {
    dispatch(setMaintenanceFilter({ ...maintenanceFilter, [field]: value }));
  };

  function handleEdit(e) {
    const { name, value } = e.target;
    dispatch(setMaintenanceFilter({ ...maintenanceFilter, [name]: value }));
  }

  function getAllMaintenances() {
    return (
      maintenanceCategories.reduce(
        (mnts, category) => [
          ...mnts,
          ...category.maintenances.map((mnt) => ({
            category: category.name,
            ...mnt,
          })),
        ],
        []
      ) || []
    );
  }

  const getAllCustomerMMs = useMemo(() => {
    return (customerMMs.docs || [])
  }, [customerMMs]);

  const customerMMValue = useMemo(() => {
    return getAllCustomerMMs.find(cmm => cmm._id === maintenanceFilter.customerMM) || ''
  }, [maintenanceFilter.customerMM, getAllCustomerMMs])

  const specFiles = Array.isArray(specLibrary)
    ? specLibrary.filter(({ isDirectory, hidden }) => !isDirectory && !hidden)
    : [];

  const getSpec = () =>
    specFiles.find(({ _id }) => _id === maintenanceFilter.inspectionCriteria) ||
    null;

  return (
    <Container>
      <Accordion expanded={expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          classes={{ content: classes.expansionHeader }}
          onClick={() => setExpanded(!expanded)}
        >
          <div className={classes.heading}>
            <TitleContent>Maintenance Filter</TitleContent>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container style={{ padding: '0px 10px 10px 10px' }}>
            <Grid item lg={3} md={6} sm={12} xs={12}>
                <InputContainer>
                  <Autocomplete
                    options={getAllCustomerMMs}
                    getOptionLabel={({ mm_number = undefined }) => {
                      return mm_number || ''
                    }}
                    onChange={(_, value) => {
                      setDataField('customer_MM', value?._id)
                    }}
                    value={customerMMValue}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        label="Material Master"
                        type="text"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </InputContainer>
              </Grid>
              <Grid item lg={3} md={6} sm={12} xs={12}>
                <InputContainer>
                  <TextField
                    label="RINA Unique ID"
                    type="text"
                    variant="outlined"
                    fullWidth
                    value={maintenanceFilter.individualItemId}
                    onChange={(event) => {
                      setDataField('individualItemId', event.target.value)
                    }}
                  />
                </InputContainer>
              </Grid>
              <Grid item lg={3} md={6} sm={12} xs={12}>
                <InputContainer>
                  <Autocomplete
                    options={getAllMaintenances()}
                    getOptionLabel={({ name }) => name}
                    groupBy={({ category }) => category}
                    onChange={(_, value) =>
                      setDataField('maintenance', value?._id)
                    }
                    value={getMaintenance(
                      maintenanceFilter.maintenance,
                      maintenanceCategories
                    )}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        label="Maintenance"
                        type="text"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </InputContainer>
              </Grid>
              <Grid item lg={3} md={6} sm={12} xs={12}>
                <InputContainer>
                  <Autocomplete
                    getOptionSelected={({ _id }, value) => {
                      return _id === value?._id;
                    }}
                    value={getSpec()}
                    onChange={(_, value) =>
                      setDataField('inspectionCriteria', value?._id)
                    }
                    fullWidth
                    options={specFiles}
                    autoHighlight
                    getOptionLabel={({ name }) => name}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        label="Inspection Criteria"
                        required
                        variant="outlined"
                      />
                    )}
                  />
                </InputContainer>
              </Grid>
              <Grid item lg={3} md={6} sm={12} xs={12}>
                <InputContainer>
                  <TextField
                    label="Effected Date"
                    required
                    type="text"
                    variant="outlined"
                    value={maintenanceFilter.effectedDate}
                    name="effectedDate"
                    onChange={handleEdit}
                    fullWidth
                    select
                  >
                    {effectedDateList.map(({ label, value }) => (
                      <MenuItem value={value}>{label}</MenuItem>
                    ))}
                  </TextField>
                </InputContainer>
              </Grid>
              <Grid item lg={3} md={6} sm={12} xs={12}>
                <InputContainer>
                  <TextField
                    label="Maintenance Interval (by days)"
                    type="number"
                    variant="outlined"
                    value={maintenanceFilter.interval}
                    name="interval"
                    onChange={handleEdit}
                    fullWidth
                  />
                </InputContainer>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} align="right">
                <ButtonContainer>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSearch}
                    style={{ marginRight: 10, cursor: 'pointer' }}
                  >
                    Filter
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={clearFilter}
                    style={{ cursor: 'pointer' }}
                  >
                    Clear
                  </Button>
                </ButtonContainer>
              </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  customerMMs: state.customerMMs,
  specLibrary: state.specLibrary,
  maintenanceCategories:
    state.maintenanceCategories?.docs || state.maintenanceCategories || [],
  maintenanceFilter: state.maintenanceFilter,
  maintenances: state.maintenances,
});

export default connect(mapStateToProps)(MaintenanceFilter);
