import styled from 'styled-components';
import {
  Paper as MuiPaper,
  Divider as MaterialDivider,
} from '@material-ui/core';

export const Container = styled.div`
  padding: 1rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
`;

export const Paper = styled(MuiPaper)`
  width: 100%;
  margin-bottom: 2rem;
  padding: 1rem;
`;

export const Title = styled.span`
  font-size: 20px;
  display: flex;
  align-items: center;
`;

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Body = styled.div``;

export const InputContent = styled.div`
  width: 100%;
  padding: 7px 2rem 10px 2rem;
  &.updated-field fieldset {
    border-color: #4caf50;
  }
  &.updated-field .MuiOutlinedInput-root:hover fieldset {
    border-color: #388e3c;
  }
  &.updated-field .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: #388e3c;
  }
  &.updated-field label {
    color: #4caf50;
  }
  &.updated-field .MuiTypography-subtitle2 {
    color: #388e3c;
  }
`;

export const Divider = styled(MaterialDivider)`
  margin-bottom: 1rem;
`;
