import {
  GET_CHECK_IN,
  UPDATE_CHECK_IN,
  UPDATE_OBJECT_CHECK_IN,
  CREATE_CHECK_IN,
  DELETE_CHECK_IN,
} from '../actions/checkIn';

export function checkInReducer(checkIn = {}, action) {
  switch (action.type) {
    case GET_CHECK_IN:
      return action.payload;
    case UPDATE_CHECK_IN:
      return action.payload;
    case CREATE_CHECK_IN:
      return action.payload;
    case DELETE_CHECK_IN:
      return action.payload;
    case UPDATE_OBJECT_CHECK_IN:
      return action.payload;

    default:
      return checkIn;
  }
}
