import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Grid, IconButton, Button } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import { ArrowBack, Close } from '@material-ui/icons';
import { getMovimentation } from '../../../redux/actions/movimentation';
import Spinner from '../../../components/Spinner/index';
import AddChildrenList from '../AddChildren/index';
import { tableStyles } from '../../../styles/tableStyles';
import { apiUrl } from '../../../config/consts';
import toast from '../../../utils/Toastify';
import Alert from '../../../components/Alert';
import { Container, Form, Title } from './styles';

const HierarchyForm = ({ dispatch, movimentation, movimentationSpinner }) => {
  const history = useHistory();
  const { id } = useParams();
  const classes = tableStyles();
  const [addChildrenPage, setAddChildrenPage] = useState(false);
  const [childrenFormatted, setChildrenFormatted] = useState([]);

  useEffect(() => {
    dispatch(getMovimentation(id));
  }, [id]);

  useEffect(() => {
    if (movimentation && movimentation.children) {
      setChildrenFormatted(movimentation.children);
    }
  }, [movimentation]);

  const handleBack = () => {
    history.goBack();
  };

  const styles = {
    headerContent: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      paddingBottom: 15,
      position: 'relative',
    },
    hierarchyMenu: {
      position: 'absolute',
      right: 30,
      top: 5,
    },
    addChildrenButton: {
      marginTop: 15,
    },
  };

  function handleRemove(child) {
    function handler() {
      Alert().then(({ confirm }) => {
        if (!confirm) return;
        axios
          .delete(`${apiUrl}build-of-material/remove/${child}`)
          .then(() => {
            toast.addSuccess('Equipment removed from hierarchy successfully.');
            dispatch(getMovimentation(id));
          })
          .catch((error) => {
            toast.addError(
              error?.response?.data?.error ||
                error?.response?.data?.message ||
                error?.message
            );
          });
      });
    }
    return handler;
  }

  return (
    <>
      <Container>
        <Form>
          {!addChildrenPage && (
            <Grid
              container
              className="headerContainer"
              style={{ opacity: movimentationSpinner ? 0.3 : 1 }}
            >
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={styles.headerContent}
              >
                <IconButton onClick={handleBack}>
                  <ArrowBack />
                </IconButton>
                <Title>
                  {movimentation &&
                    movimentation?.customer_MM?.short_description}
                </Title>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} align="left">
                <Title style={{ marginTop: '2rem' }}>
                  Selected Mother Item
                </Title>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Status</TableCell>
                        <TableCell>Material Layout</TableCell>
                        <TableCell colSpan={3}>Equipment Name</TableCell>
                        <TableCell>Customer MM no</TableCell>
                        <TableCell>Total Qty. (EA)</TableCell>
                        <TableCell>Footage</TableCell>
                        <TableCell>Supplier Batch No</TableCell>
                        <TableCell>SLOC</TableCell>
                        <TableCell>Assigned Project</TableCell>
                        <TableCell>Supplier</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {movimentationSpinner && (
                        <TableRow className={classes.row}>
                          <TableCell colSpan="18" className={classes.cell}>
                            <Spinner parent="Table" />
                          </TableCell>
                        </TableRow>
                      )}
                      {movimentation && movimentation.parent && (
                        <TableRow className={classes.row}>
                          <TableCell
                            className={classes.cell}
                            style={{
                              color:
                                movimentation.parent &&
                                movimentation.parent.status === 'Available'
                                  ? 'green'
                                  : 'black',
                            }}
                          >
                            <b>
                              {movimentation.parent &&
                                movimentation.parent.status}
                            </b>
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {movimentation.parent &&
                              movimentation.parent.material_layout}
                          </TableCell>
                          <TableCell colSpan={3} className={classes.cell}>
                            <b>
                              {movimentation.parent &&
                                movimentation.parent?.customer_MM
                                  ?.short_description}
                            </b>
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {movimentation.parent &&
                              movimentation.parent.customer_MM &&
                              movimentation.parent.customer_MM.mm_number}
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {movimentation.parent && movimentation.parent.qty}
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {movimentation.parent &&
                              movimentation.parent.footage}
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {movimentation.parent &&
                              movimentation.parent.batch_no}
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {movimentation.parent &&
                              movimentation.parent.SLOC &&
                              movimentation.parent.SLOC.sloc_name}
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {movimentation.parent &&
                              movimentation.parent.original_charge &&
                              movimentation.parent.original_charge.well &&
                              movimentation.parent.original_charge.well
                                .project &&
                              movimentation.parent.original_charge.well.project
                                .name}
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {movimentation.parent &&
                              movimentation.parent.supplier}
                          </TableCell>
                        </TableRow>
                      )}
                      {!movimentationSpinner && !movimentation.parent && (
                        <TableRow className={classes.row}>
                          <TableCell colSpan="12" style={{ fontSize: '1rem' }}>
                            <div style={{ width: '100%', textAlign: 'center' }}>
                              This item doest not have parent
                            </div>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12} align="left">
                <Title style={{ marginTop: '4rem' }}>Hierarchy Breakdown</Title>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell>Status</TableCell>
                        <TableCell>Material Layout</TableCell>
                        <TableCell colSpan={3}>Equipment Name</TableCell>
                        <TableCell>Customer MM no</TableCell>
                        <TableCell>Total Qty. (EA)</TableCell>
                        <TableCell>Footage</TableCell>
                        <TableCell>Supplier Batch No</TableCell>
                        <TableCell>SLOC</TableCell>
                        <TableCell>Assigned Project</TableCell>
                        <TableCell>Supplier</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {movimentationSpinner && (
                        <TableRow className={classes.row}>
                          <TableCell colSpan="18" className={classes.cell}>
                            <Spinner parent="Table" />
                          </TableCell>
                        </TableRow>
                      )}
                      {!movimentationSpinner &&
                        childrenFormatted.map((rr) => (
                          <TableRow className={classes.row} key={rr._id}>
                            <TableCell className={classes.cell}>
                              <Tooltip title="Remove from hierarchy">
                                <IconButton
                                  onClick={handleRemove(rr._id)}
                                  color="secondary"
                                >
                                  <Close />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                            <TableCell
                              className={classes.cell}
                              style={{
                                color:
                                  rr.status === 'Available' ? 'green' : 'black',
                              }}
                            >
                              <b>{rr.status}</b>
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {rr.material_layout}
                            </TableCell>
                            <TableCell colSpan={3} className={classes.cell}>
                              <b>{rr?.customer_MM?.short_description}</b>
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {rr.customer_MM && rr.customer_MM.mm_number}
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {rr.qty}
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {rr.footage}
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {rr.batch_no}
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {rr.SLOC && rr.SLOC.sloc_name}
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {rr.original_charge &&
                                rr.original_charge.well &&
                                rr.original_charge.well.project &&
                                rr.original_charge.well.project.name}
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {rr.supplier}
                            </TableCell>
                          </TableRow>
                        ))}
                      {!movimentationSpinner && childrenFormatted.length === 0 && (
                        <TableRow className={classes.row}>
                          <TableCell colSpan="12" style={{ fontSize: '1rem' }}>
                            <div style={{ width: '100%', textAlign: 'center' }}>
                              This item does not have children
                            </div>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                <Button
                  onClick={() => setAddChildrenPage(true)}
                  variant="contained"
                  color="primary"
                  style={styles.addChildrenButton}
                >
                  Add Item
                </Button>
              </Grid>
            </Grid>
          )}
          {addChildrenPage && (
            <AddChildrenList
              id={id}
              handleBack={() => {
                dispatch(getMovimentation(id));
                setAddChildrenPage(false);
              }}
            />
          )}
        </Form>
      </Container>
    </>
  );
};

export default connect((state) => ({
  movimentation: state.movimentation,
  movimentationSpinner: state.ui.movimentationLoading,
}))(HierarchyForm);
