import React from 'react';
import {
  Container,
} from './styles';

function NoPermissionPage() {
  return (
    <Container>
      <h1>You don't have permission</h1>
    </Container>
  );
}

export default NoPermissionPage;
