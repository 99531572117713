import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import axios from 'axios';
import React from 'react';
import { useParams } from 'react-router-dom';
import Alert from '../../components/Alert';
import { apiUrl } from '../../config/consts';
import getRINADescription from '../../utils/Equipment/getRINADescription';
import toast from '../../utils/Toastify';

function handleColor(movimentation, fromCart = [], newAdded = []) {
  if (fromCart.includes(movimentation)) return '#ffe082';
  return newAdded.includes(movimentation) ? '#fff9c4' : undefined;
}

const CollapsableRow = ({
  movimentation,
  root,
  fromCart = [],
  openModal,
  onReload,
  newAdded = [],
  onChange,
  innerFacilityReferenceID = {},
}) => {
  const { id } = useParams();
  const [open, setOpen] = React.useState(root);

  function handleRemove() {
    Alert().then(({ confirm }) => {
      if (!confirm) return;
      axios
        .delete(`${apiUrl}build-of-material/remove/${movimentation._id}`)
        .then(() => {
          toast.addSuccess('Equipment removed from hierarchy successfully.');
          onReload();
        })
        .catch((error) => {
          toast.addError(
            error?.response?.data?.error ||
              error?.response?.data?.message ||
              error?.message
          );
        });
    });
  }

  return (
    <>
      <TableRow
        style={{
          backgroundColor: handleColor(movimentation._id, fromCart, newAdded),
        }}
      >
        <TableCell>
          <Tooltip title="Add to Hierarchy">
            <IconButton
              size="small"
              color="primary"
              onClick={() => openModal(movimentation._id)}
              disabled={id !== 'new'}
            >
              <AddCircleOutline />
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell>
          {!root && (
            <Tooltip title="Remove from Hierarchy">
              <IconButton
                size="small"
                color="secondary"
                onClick={handleRemove}
                disabled={id !== 'new'}
              >
                <HighlightOffIcon />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell title="RAMP Unique ID">
          {movimentation?.individual_item_id}
        </TableCell>
        <TableCell title="RINA Description" style={{ minWidth: 250 }}>
          {getRINADescription(movimentation?.customer_MM)}
        </TableCell>
        <TableCell title="RINA MM No">
          {movimentation?.customer_MM?.rina_mm_number}
        </TableCell>
        <TableCell title="Total Qty. EA">{movimentation.qty}</TableCell>
        <TableCell title="Footage">{movimentation.footage}</TableCell>
        <TableCell title="COPAS Class">
          {movimentation.classification}
        </TableCell>
        <TableCell
          title="Facility Reference ID"
          style={{ backgroundColor: '#e3f2fd', minWidth: 200 }}
        >
          <TextField
            value={
              innerFacilityReferenceID[movimentation._id] ||
              movimentation.facility_reference_id
            }
            variant="outlined"
            fullWidth
            onChange={onChange(movimentation._id)}
          />
        </TableCell>
        <TableCell title="Supplier MM No"></TableCell>
        <TableCell title="Supplier Batch No"></TableCell>
        <TableCell title="Heat/Lot">{movimentation.heat}</TableCell>
        <TableCell title="Joint/Run No">{movimentation.joint_no}</TableCell>
        <TableCell title="RBW">{movimentation.RBW}</TableCell>

        <TableCell title="Assigned Project">
          {movimentation?.assigned_customer_charge_code?.well?.project?.name}
        </TableCell>
        <TableCell title="Assigned Well">
          {movimentation?.assigned_customer_charge_code?.well?.name}
        </TableCell>
        <TableCell title="Assigned Charge Code">
          {movimentation?.assigned_customer_charge_code?.original_charge_code}
        </TableCell>
        <TableCell title="RAMP Tag No"></TableCell>
        <TableCell title="RINA Work Order No">
          {movimentation.rina_work_order_no}
        </TableCell>
        <TableCell title="SLOC">{movimentation?.SLOC?.sloc_name}</TableCell>
        <TableCell title="Material Layout">
          {movimentation.material_layout}
        </TableCell>
        <TableCell title="Associated RAMP Tag"></TableCell>
        <TableCell title="Allocated">
          {movimentation.allocated_release_number ||
          movimentation.allocated_batch_number ||
          movimentation.allocated_work_order_number ? (
            <span>YES</span>
          ) : (
            <span>NO</span>
          )}
        </TableCell>
        <TableCell title="Remove From Parent"></TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={24}>
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            style={{
              borderLeft: '2px solid rgba(224, 224, 224, 1)',
            }}
          >
            <Box margin={1} marginBottom={0}>
              {root && (
                <Typography variant="h6" gutterBottom component="div">
                  Bill of Material Breakdown
                </Typography>
              )}
              <Table size="small" aria-label="purchases">
                <TableBody>
                  {movimentation?.children?.map((child) => (
                    <CollapsableRow
                      key={child._id}
                      movimentation={child}
                      fromCart={fromCart}
                      openModal={openModal}
                      onReload={onReload}
                      newAdded={newAdded}
                      innerFacilityReferenceID={innerFacilityReferenceID}
                      onChange={onChange}
                    />
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
export default CollapsableRow;
