import { UPDATE_OBJECT_CYCLE_COUNT } from '../actions/cycleCount';

export function cycleCountReducer(
  cycleCount = {
    complete: [],
    flagged_for_reconciliation: [],
  },
  action,
) {
  switch (action.type) {
    case UPDATE_OBJECT_CYCLE_COUNT:
      return action.payload;

    default:
      return cycleCount;
  }
}
