import { CUSTOMER_KEY } from '../../config/consts';

export const GET_CUSTOMER_MM = '[customer_mm] GET';
export const UPDATE_CUSTOMER_MM = '[customer_mm] UPDATE';
export const CREATE_CUSTOMER_MM = '[customer_mm] CREATE';
export const DELETE_CUSTOMER_MM = '[customer_mm] DELETE';
export const UPDATE_OBJECT_CUSTOMER_MM = '[customer_mm] UPDATE OBJECT CUSTOMER_MM';
// UI
export const SHOW_CUSTOMER_MM_MODAL = '[customer_mm] SHOW MODAL';
export const HIDE_CUSTOMER_MM_MODAL = '[customer_mm] HIDE MODAL';
// Spinner
export const UPDATE_CUSTOMER_MM_SUCCESS = '[customer_mm] Update success';
export const UPDATE_CUSTOMER_MM_ERROR = '[customer_mm] Update Error';
export const FETCH_CUSTOMER_MM_SUCCESS = '[customer_mm] Fetch success';
export const FETCH_CUSTOMER_MM_ERROR = '[customer_mm] Fetch Error';
export const SHOW_CUSTOMER_MM_SPINNER = '[customer_mm - ui] show spinner';
export const HIDE_CUSTOMER_MM_SPINNER = '[customer_mm - ui] hide spinner';

export const getCustomerMM = (id) => ({
  type: GET_CUSTOMER_MM,
  payload: { _id: id },
});

export const createCustomerMM = (data) => ({
  type: CREATE_CUSTOMER_MM,
  payload: { ...data, customer: localStorage.getItem(CUSTOMER_KEY) },
});

export const updateCustomerMM = (data) => ({
  type: UPDATE_CUSTOMER_MM,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_CUSTOMER_MM_MODAL,
});

export const hideModal = () => ({
  type: HIDE_CUSTOMER_MM_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_CUSTOMER_MM_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_CUSTOMER_MM_SPINNER,
});

export const updateObjectCustomerMM = (data) => ({
  type: UPDATE_OBJECT_CUSTOMER_MM,
  payload: data,
});
