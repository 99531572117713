import { UPDATE_CYCLE_COUNTS } from '../actions/cycleCounts';

const initState = {
  docs: [],
  page: 1,
  limit: 10,
  totalDocs: 0,
};

export function cycleCountsReducer(cycleCounts = initState, action) {
  switch (action.type) {
    case UPDATE_CYCLE_COUNTS:
      return action.payload;

    default:
      return cycleCounts;
  }
}
