import {
  UPDATE_RECLASS_SUCCESS,
  UPDATE_RECLASS_ERROR,
  FETCH_RECLASS_SUCCESS,
  FETCH_RECLASS_ERROR,
  GET_RECLASS,
  CREATE_RECLASS,
  UPDATE_RECLASS,
  updateObjectReclass,
  showSpinner,
  hideSpinner,
  hideModal,
} from '../actions/reclass';
import Toastify from '../../utils/Toastify/index';
import history from '../../services/history';
import { apiRequest } from '../actions/api';
import { CUSTOMER_KEY } from '../../config/consts';

const URL = 'reclass';

export const createReclassFlow = ({ dispatch, getState }) => (next) => (action) => {
  next(action);
  if (action.type === CREATE_RECLASS) {
    action.payload.append('inventory', localStorage.getItem(CUSTOMER_KEY));
    action.payload.append('user', getState().loggedUser._id);
    dispatch(
      apiRequest(
        'POST',
        URL,
        action.payload,
        UPDATE_RECLASS_SUCCESS,
        UPDATE_RECLASS_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const updateReclassFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === UPDATE_RECLASS) {
    dispatch(
      apiRequest(
        'PUT',
        URL,
        action.payload,
        UPDATE_RECLASS_SUCCESS,
        UPDATE_RECLASS_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const getReclassFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_RECLASS) {
    const { _id } = action.payload;
    dispatch(
      apiRequest(
        'GET-id',
        URL,
        { _id },
        FETCH_RECLASS_SUCCESS,
        FETCH_RECLASS_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const processReclassCollection = ({ dispatch, getState }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_RECLASS_SUCCESS) {
    dispatch(updateObjectReclass(action.payload));
    dispatch(hideSpinner());
  } else if (action.type === UPDATE_RECLASS_SUCCESS) {
    dispatch(updateObjectReclass(action.payload));
    dispatch(hideModal());
    dispatch(hideSpinner());
    history.replace(`/reclass/${action.payload._id}`);
    Toastify.addSuccess('Reclass saved.');
  } else if (action.type === UPDATE_RECLASS_ERROR) {
    dispatch(hideSpinner());
    Toastify.addError(action.payload || 'Error to save the reclass');
  }
};

export const reclassMdl = [
  createReclassFlow,
  updateReclassFlow,
  getReclassFlow,
  processReclassCollection,
];
