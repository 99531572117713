import React from 'react';
import format from 'date-fns/format';
import {
  TableRow,
  TableCell,
  Tooltip,
  Icon,
  IconButton,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

function formatDate(date) {
  if (!date) return '--';
  return format(new Date(date), 'MM-dd-yyyy HH:mm');
}

function Row({ data }) {
  const location = {};
  data.sloc.warehouses.some((item) => {
    const rack = item.racks.findIndex(({ bins }) =>
      bins.includes(data.bin._id.toString())
    );
    if (rack < 0) return false;

    location.rack = item.racks[rack];
    location.warehouse = item;
    return true;
  });

  return (
    <TableRow key={data._id}>
      <TableCell>{data.count_number}</TableCell>
      <TableCell>
        {data.sloc.sloc_id} &gt; {location.warehouse.name} &gt;{' '}
        {location.rack.name} &gt; {data.bin.name}
      </TableCell>
      <TableCell>{formatDate(data.date)}</TableCell>
      <TableCell>{formatDate(data.perform_date)}</TableCell>
      <TableCell>{formatDate(data.reconcile_date)}</TableCell>
      <TableCell>{data.status}</TableCell>
      <TableCell>
        <Tooltip title="Reconcile Cycle Count">
          <Link
            disabled={data.status !== 'Pending'}
            component={IconButton}
            to={`/cycle_counting/reconcile/${data._id}`}
          >
            <Icon>cached</Icon>
          </Link>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
}

export default Row;
