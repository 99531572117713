import {
  UPDATE_INVENTORY_CART,
  UPDATE_CART_COUNT,
} from '../actions/inventory_cart';

const initState = {
  all: [],
  cart: {
    docs: [],
    page: 1,
    limit: 10,
    totalDocs: 0,
  },
};

export function inventoryCartReducer(inventoryCart = initState, action) {
  switch (action.type) {
    case UPDATE_INVENTORY_CART:
      return {
        ...inventoryCart,
        cart: action.payload,
      };
    case UPDATE_CART_COUNT:
      return {
        ...inventoryCart,
        all: action.payload.filter((item) => item) || [],
      };
    default:
      return inventoryCart;
  }
}
