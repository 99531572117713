import { GET_VENDORS, UPDATE_VENDORS } from '../actions/vendors';

const initState = {
  docs: [],
  page: 1,
  limit: 10,
  totalDocs: 0,
  searchText: '',
};

export function vendorsReducer(vendors = initState, action) {
  switch (action.type) {
    case GET_VENDORS:
      return action.payload;
    case UPDATE_VENDORS:
      return action.payload;

    default:
      return vendors;
  }
}
