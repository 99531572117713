import React, { useState, useEffect } from 'react';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Typography from '@material-ui/core/Typography';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import {
  Add,
  KeyboardArrowRight as CollapsedIcon,
  KeyboardArrowDown as ExpandedIcon,
} from '@material-ui/icons';
import Collapse from '@material-ui/core/Collapse';
import { AddButton } from './styles';
import AddMaintenanceToWorkOrderModal from '../../Modals/AddMaintenanceToWorkOrder';

const effectedDateMap = {
  manufacture_date: 'Date of Manufacture',
  expiration_date: 'Expiration Date',
  in_service_date: 'In Service Date',
  last_reconcile_date: 'Date Last Reconciled',
  date_last_tested: 'Date Last Tested',
  date_last_rotated: 'Date Last Rotated',
};

function Row({ maintenance, equipment = [] }) {
  const [collapsed, setCollapsed] = useState(true);

  function toggleCollapsed() {
    setCollapsed((currentStatus) => !currentStatus);
  }

  function getEquipment(id) {
    return equipment.find(({ _id }) => _id.toString() === id.toString());
  }

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            size="small"
            color={collapsed ? 'default' : 'primary'}
            onClick={toggleCollapsed}
          >
            {collapsed ? <CollapsedIcon /> : <ExpandedIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{maintenance?.maintenance?.maintenance?.name}</TableCell>
        <TableCell>
          {maintenance?.maintenance?.inspectionCriteria?.name}
        </TableCell>
        <TableCell>
          {effectedDateMap[maintenance?.maintenance?.effectedDate]}
        </TableCell>
        <TableCell>{maintenance?.maintenance?.interval}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={!collapsed} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Equipment Selected
              </Typography>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Unique ID</TableCell>
                    <TableCell>Customer MM No</TableCell>
                    <TableCell>Total Qty. (EA)</TableCell>
                    <TableCell>Footage</TableCell>
                    <TableCell>COPAS Class</TableCell>
                    <TableCell>Supplier</TableCell>
                    <TableCell>Batch No</TableCell>
                    <TableCell>Heat/Lot</TableCell>
                    <TableCell>RBW</TableCell>
                    <TableCell>Facility Reference ID</TableCell>
                    <TableCell>Manufacture</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {maintenance.equipment?.map((eqp) => (
                    <TableRow key={eqp}>
                      <TableCell>
                        {getEquipment(eqp).individual_item_id}
                      </TableCell>
                      <TableCell>
                        {getEquipment(eqp).customer_MM?.mm_number}
                      </TableCell>
                      <TableCell>{getEquipment(eqp).qty}</TableCell>
                      <TableCell>{getEquipment(eqp).footage}</TableCell>
                      <TableCell>{getEquipment(eqp).classification}</TableCell>
                      <TableCell>{getEquipment(eqp).supplier}</TableCell>
                      <TableCell>{getEquipment(eqp).batch_no}</TableCell>
                      <TableCell>{getEquipment(eqp).heat}</TableCell>
                      <TableCell>{getEquipment(eqp).RBW}</TableCell>
                      <TableCell>
                        {getEquipment(eqp).facility_reference_id}
                      </TableCell>
                      <TableCell>{getEquipment(eqp).manufacturer}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function Maintenance({ data, handleEditRevision }) {
  const {
    revision: { maintenances, equipment = [] },
  } = data;
  const [modalOpen, setModalOpen] = useState(false);

  function openModal() {
    setModalOpen(true);
  }
  function closeModal() {
    setModalOpen(false);
  }

  return (
    <>
      <AddMaintenanceToWorkOrderModal
        equipment={equipment}
        open={modalOpen}
        onClose={closeModal}
        handleEditRevision={handleEditRevision}
        maintenances={maintenances}
      />
      <AddButton
        variant="contained"
        color="primary"
        size="small"
        onClick={openModal}
      >
        <Add />
        Add Maintenance
      </AddButton>
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Maintenance Name</TableCell>
              <TableCell>Inspection Criteria</TableCell>
              <TableCell>Effected Date</TableCell>
              <TableCell>Interval</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!maintenances.length && (
              <TableRow>
                <TableCell colSpan={5}>
                  <Typography variant="subtitle1" component="center">
                    No maintenance selected
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {maintenances.map((maintenance) => (
              <Row
                maintenance={maintenance}
                key={maintenance._id}
                equipment={equipment}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default Maintenance;
