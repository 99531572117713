/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  FormGroup,
  Grid,
  makeStyles,
  Paper,
  Step,
  StepButton,
  StepContent,
  Stepper,
  TextField,
  Tooltip,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import LabelIcon from '@material-ui/icons/Label';
import { isEmpty } from 'lodash';
import { AiFillFileExcel, AiFillFilePdf } from 'react-icons/ai';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { apiUrl, CUSTOMER_KEY } from '../../config/consts';
import { getMovimentations } from '../../redux/actions/movimentations';
import {
  createReportField,
  updateReportField,
} from '../../redux/actions/reportField';
import { getReportFields } from '../../redux/actions/reportFields';
import equipmentInputs from '../../utils/StaticData/EquipmentInputs/export_data';
import Toastify from '../../utils/Toastify/index';
import {
  ButtonContainer,
  Column,
  ColumnContent,
  ColumnHeader,
  Container,
  ExcelTable,
  TemplateFieldItem,
  TemplateItem,
  Title,
} from './styles';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 600,
    position: 'relative',
    width: '100%',
  },
}));

const alphabeticalOrder = (a, b) => {
  if (a.label < b.label) {
    return -1;
  }
  if (a.label > b.label) {
    return 1;
  }
  return 0;
};

const ExportReport = ({
  dispatch,
  movimentations,
  movimentationsFilter,
  available,
  loggedUser,
  reportFields,
  reportFieldsLoading,
}) => {
  const classes = useStyles();
  const [loadingFull, setLoadingFull] = useState(false);
  const [loadingShort, setLoadingShort] = useState(false);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const location = useLocation();
  const [fields, setFields] = useState([]);
  const [filled, setFilled] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [templateName, setTemplateName] = useState('');
  const [templateNameError, setTemplateNameError] = useState(false);
  const [equipmentInputsSorted] = useState(
    equipmentInputs.sort(alphabeticalOrder)
  ); // Sorting the inputs labels

  useEffect(() => {
    if (isEmpty(movimentationsFilter)) {
      const customer = localStorage.getItem(CUSTOMER_KEY);
      dispatch(
        getMovimentations({
          page: 1,
          limit: 0,
          filter: {
            customer,
            in_inventory: true,
            consolidated: true,
          },
        })
      );
    }
    dispatch(
      getReportFields({ page: 1, limit: 10, filter: { user: loggedUser._id } })
    );
  }, []);

  const saveFields = () => {
    if (templateName === '') {
      setTemplateNameError(true);
      return;
    }
    if (selectedTemplate === null)
      dispatch(
        createReportField({ name: templateName, fields, _user: loggedUser._id })
      );
    else
      dispatch(
        updateReportField({
          name: templateName,
          fields,
          _user: loggedUser._id,
          _id: reportFields[selectedTemplate]._id,
        })
      );
  };

  const imported = (label) => {
    const result = fields.some((item) => item.label === label);
    return result;
  };

  const notImported = (label) => {
    const result = fields.some((item) => {
      if (item.label === label) {
        return false;
      }
    });
    return result;
  };

  const handleImport = async (name) => {
    let newField = imported(name);
    if (!newField) {
      equipmentInputsSorted.map((item) => {
        if (item.label === name) {
          setFields([...fields, item]);
        }
      });
    }
  };

  const handleRemove = async (name) => {
    let newField = imported(name);
    if (newField) {
      equipmentInputsSorted.map((item) => {
        if (item.label === name) {
          const newFields = fields.filter((field) => {
            if (field.label !== name) return field;
          });
          setFields(newFields);
        }
      });
    }
  };

  const exportAll = async () => {
    const allFields = equipmentInputsSorted;
    setFields(allFields);
    setFilled(true);
  };

  const removeAll = async () => {
    setFields([]);
    setFilled(false);
  };

  const handleQuery = () => {
    Object.keys(movimentationsFilter).forEach((item) => {
      if (
        typeof movimentationsFilter[item] === 'undefined' ||
        movimentationsFilter[item] === null
      )
        delete movimentationsFilter[item];
    });
    let query = Object.keys(movimentationsFilter)
      .map(
        (k) =>
          encodeURIComponent(k) +
          '=' +
          encodeURIComponent(movimentationsFilter[k])
      )
      .join('&');

    if (location.pathname == '/export_cart') {
      return '';
    }

    return query;
  };

  const cart =
    location.pathname == '/export_cart'
      ? available.map(({ _id }) => _id)
      : undefined;

  const pdfReport = async () => {
    const body = {
      fields,
      cart,
    };
    const query = handleQuery();
    setLoadingFull(true);
    fetch(`${apiUrl}equipments-pdf?${query}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        setLoadingFull(false);
        if (response.status === 200 || response.status === 204) {
          return response.blob();
        }
        Toastify.addError('Error to download file!');
        throw response;
      })
      .then((myBlob) => {
        const objectURL = URL.createObjectURL(myBlob);
        window.open(objectURL, '_blanc');
      });
  };

  const pdfReportShort = async () => {
    const query = handleQuery();
    const body = {
      fields,
      cart,
    };
    setLoadingShort(true);
    fetch(`${apiUrl}equipments-pdf?short_template=true&${query}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        setLoadingShort(false);
        if (response.status === 200 || response.status === 204) {
          return response.blob();
        }
        Toastify.addError('Error to download file!');
      })
      .then((myBlob) => {
        const objectURL = URL.createObjectURL(myBlob);
        window.open(objectURL, '_blank');
      });
  };

  const excelReport = async () => {
    const query = handleQuery();
    const body = {
      fields,
      cart,
    };
    setLoadingExcel(true);
    fetch(`${apiUrl}equipments-excel?${query}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        setLoadingExcel(false);
        if (response.status === 200 || response.status === 204) {
          return response.blob();
        }
        Toastify.addError('Error to download file!');
      })
      .then((myBlob) => {
        const objectURL = URL.createObjectURL(myBlob);
        const fileLink = document.createElement('a');
        fileLink.href = objectURL;
        const today = new Date().toISOString().slice(0, 10);
        fileLink.download = `inventory-export-${today}.xlsx`;
        fileLink.click();
      });
  };

  function handleDownloadReport(reportHandler) {
    function handler() {
      if (fields.length === 0) {
        Toastify.addError('Please Select fields before download...');
        handleStep(1)();
        return;
      }
      reportHandler();
    }
    return handler;
  }

  const printItemsToExport = () => {
    if (location.pathname == '/export_cart') {
      if (available && available.docs) {
        return `(${available.docs.length} items to export)`;
      }
    }
    if (location.pathname == '/export_inventory') {
      if (movimentations && movimentations.totalDocs) {
        return `(${movimentations.totalDocs} items to export)`;
      }
      return '(Export all the inventory)';
    }
  };

  function handleStep(step) {
    function handler() {
      setCurrentStep((currentStep) => (currentStep === step ? null : step));
    }
    return handler;
  }

  function handleTemplateSelection(template) {
    function handler() {
      setSelectedTemplate((currentSelected) => {
        if (currentSelected === template || template === null) {
          setTemplateName('');
          setFields([]);
          return null;
        }
        setTemplateName(reportFields[template].name);
        const fixedFields = reportFields[template].fields.map((field) => {
          const localField = equipmentInputs.find(
            ({ label }) => field.label === label
          );
          if (!localField) return field;
          return localField;
        });
        setFields(fixedFields);
        return template;
      });
    }
    return handler;
  }

  function handleSkipTemplate() {
    handleTemplateSelection(null)();
    handleStep(1)();
  }

  function handleTemplateName(e) {
    setTemplateName(e.target.value);
    setTemplateNameError(false);
  }

  return (
    <Container>
      <Paper>
        <Title>Inventory Export {printItemsToExport()}</Title>
        <Stepper activeStep={currentStep} nonLinear orientation="vertical">
          <Step>
            <StepButton onClick={handleStep(0)}>Select a Template</StepButton>
            <StepContent>
              {reportFieldsLoading ? (
                <CircularProgress />
              ) : (
                <>
                  {reportFields.map((template, index) => (
                    <TemplateItem key={template._id}>
                      <Tooltip
                        arrow
                        placement="right"
                        title={template.fields.map((field) => (
                          <TemplateFieldItem>{field.label}</TemplateFieldItem>
                        ))}
                      >
                        <Button
                          variant={
                            selectedTemplate === index ? 'outlined' : 'text'
                          }
                          onClick={handleTemplateSelection(index)}
                          startIcon={<LabelIcon />}
                        >
                          {template.name}
                        </Button>
                      </Tooltip>
                    </TemplateItem>
                  ))}
                  {reportFields.length < 10 && (
                    <TemplateItem>
                      <Button
                        onClick={handleSkipTemplate}
                        color="primary"
                        startIcon={<AddIcon />}
                      >
                        New Template
                      </Button>
                    </TemplateItem>
                  )}{' '}
                </>
              )}
              <ButtonContainer style={{ justifyContent: 'flex-start' }}>
                <Button
                  onClick={handleSkipTemplate}
                  style={{ marginRight: 10 }}
                >
                  Skip
                </Button>
                <Button
                  onClick={handleStep(1)}
                  style={{ marginRight: 10 }}
                  variant="outlined"
                  color="primary"
                >
                  Change Fields
                </Button>
                <Button
                  onClick={handleStep(2)}
                  style={{ marginRight: 10 }}
                  variant="contained"
                  color="primary"
                >
                  Select Download Option
                </Button>
              </ButtonContainer>
            </StepContent>
          </Step>
          <Step>
            <StepButton onClick={handleStep(1)}>Confirm Fields</StepButton>
            <StepContent>
              <Grid
                container
                style={{
                  padding: '0px 10px 10px 10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <ExcelTable>
                  <Column>
                    <ColumnHeader>Available Data</ColumnHeader>
                    <ColumnContent>
                      <FormGroup colum>
                        {equipmentInputsSorted?.map(
                          (obj, i) =>
                            !imported(obj.label) && (
                              <div
                                onClick={() => handleImport(obj.label)}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  cursor: 'pointer',
                                }}
                              >
                                <AddIcon
                                  key={i}
                                  style={{ margin: 9 }}
                                  name={obj.label}
                                  checked={imported(obj.label)}
                                  color="primary"
                                />
                                {obj.label}
                              </div>
                            )
                        )}
                      </FormGroup>
                    </ColumnContent>
                  </Column>
                  <Column>
                    <ColumnContent>
                      <FormGroup colum>
                        <div
                          style={{
                            margin: 0,
                            marginTop: '15rem',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              fontWeight: 'bold',
                              marginLeft: '56px',
                            }}
                          >
                            <Button
                              onClick={() => exportAll()}
                              variant="outlined"
                              title="Add All"
                              size="small"
                              color="primary"
                              className={classes.margin}
                              disabled={filled}
                            >
                              {'>>'}
                            </Button>
                          </div>

                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              fontWeight: 'bold',
                              marginTop: '1rem',
                              marginLeft: '56px',
                            }}
                          >
                            <Button
                              onClick={() => removeAll()}
                              variant="outlined"
                              title="Remove All"
                              size="small"
                              color="secondary"
                              className={classes.margin}
                              disabled={fields.length === 0 && !filled}
                            >
                              {'<<'}
                            </Button>
                          </div>
                        </div>
                      </FormGroup>
                    </ColumnContent>
                  </Column>
                  <Column>
                    <ColumnHeader>
                      {selectedTemplate === null &&
                      reportFields.length >= 10 ? (
                        'Temporary Template'
                      ) : (
                        <TextField
                          label="Template Name"
                          variant="outlined"
                          value={templateName}
                          onChange={handleTemplateName}
                          fullWidth
                          size="small"
                          required
                          helperText={
                            templateNameError &&
                            'Please, set a name to this template.'
                          }
                          error={templateNameError}
                        />
                      )}
                    </ColumnHeader>
                    <ColumnContent>
                      <FormGroup colum>
                        {fields?.map((obj, i) => (
                          <div
                            onClick={() => handleRemove(obj.label)}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              cursor: 'pointer',
                            }}
                          >
                            <CancelIcon
                              key={i}
                              style={{ color: 'red', margin: 9 }}
                              name={obj.label}
                              checked={notImported(obj.label)}
                              color="primary"
                            />
                            {obj.label}
                          </div>
                        ))}
                      </FormGroup>
                      <FormControl
                        variant="outlined"
                        size="small"
                        fullWidth
                        style={{ marginTop: 10 }}
                      >
                        <Button
                          onClick={saveFields}
                          variant="contained"
                          color="primary"
                          startIcon={
                            reportFieldsLoading && (
                              <CircularProgress
                                size={24}
                                style={{ color: '#fff' }}
                              />
                            )
                          }
                        >
                          Save Template
                        </Button>
                      </FormControl>
                    </ColumnContent>
                  </Column>
                </ExcelTable>
              </Grid>
              <ButtonContainer style={{ justifyContent: 'flex-start' }}>
                <Button onClick={handleStep(0)} style={{ marginRight: 10 }}>
                  Back
                </Button>
                <Button
                  onClick={handleStep(2)}
                  style={{ marginRight: 10 }}
                  variant="contained"
                  color="primary"
                >
                  Select Download Option
                </Button>
              </ButtonContainer>
            </StepContent>
          </Step>
          <Step>
            <StepButton onClick={handleStep(2)}>
              Select Download Option
            </StepButton>
            <StepContent>
              <ButtonContainer>
                <Button
                  onClick={handleDownloadReport(pdfReport)}
                  variant="contained"
                  style={{
                    marginleft: 5,
                    marginRight: 5,
                    backgroundColor: '#f44336',
                    color: '#FFF',
                  }}
                  disabled={loadingFull}
                  startIcon={
                    loadingFull ? (
                      <CircularProgress
                        size={24}
                        style={{ marginRight: 10, color: '#FFF' }}
                      />
                    ) : (
                      <AiFillFilePdf />
                    )
                  }
                >
                  Download Full PDF Version
                </Button>
                <Button
                  onClick={handleDownloadReport(pdfReportShort)}
                  variant="contained"
                  style={{
                    marginleft: 5,
                    marginRight: 5,
                    backgroundColor: '#FFF',
                    color: '#f44336',
                    borderColor: '#f44336',
                    borderWidth: 0.5,
                    borderStyle: 'solid',
                  }}
                  disabled={loadingShort}
                  startIcon={
                    loadingShort ? (
                      <CircularProgress
                        size={24}
                        style={{ marginRight: 10, color: '#f44336' }}
                      />
                    ) : (
                      <AiFillFilePdf />
                    )
                  }
                >
                  Download Short PDF Version
                </Button>
                <Button
                  onClick={handleDownloadReport(excelReport)}
                  variant="contained"
                  style={{
                    marginleft: 5,
                    marginRight: 5,
                    backgroundColor: '#4caf50',
                    color: '#FFF',
                  }}
                  disabled={loadingExcel}
                  startIcon={
                    loadingExcel ? (
                      <CircularProgress
                        size={24}
                        style={{ marginRight: 10, color: '#FFF' }}
                      />
                    ) : (
                      <AiFillFileExcel />
                    )
                  }
                >
                  Download Excel Version
                </Button>
              </ButtonContainer>
            </StepContent>
          </Step>
        </Stepper>
      </Paper>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  movimentationsFilter: state.movimentationsFilter,
  movimentationsLoading: state.ui.movimentationsLoading,
  movimentations: state.movimentations,
  loggedUser: state.loggedUser,
  reportFields: state?.reportFields || [],
  available: state.inventory_cart.all,
  reportFieldsLoading: state.ui.reportFieldsLoading,
});

export default connect(mapStateToProps)(ExportReport);
