import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Paper, Box, Button } from '@material-ui/core';
import {
  getDirectoryContent,
  getCompleteDirectoryStructure,
  getDirectoryInfo,
  getRoot,
  createFolder,
  uploadFiles,
} from '../../redux/actions/vault';
import {
  Toolbar,
  FilesContainer,
  TreeItem,
  Prompt,
  DropZone,
  ModalContainer,
} from '../../components';
import { Container, ModalTitle, ButtonContainer } from './styles';

const Vault = ({ dispatch, directoryInfo, directoryStructure, entryInfo }) => {
  const [listView, setListView] = React.useState(true);
  const [showSidebar, setShowSidebar] = React.useState(true);
  const [fileUploadModal, setFileUploadModal] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const [selectedEntries, setSelectedEntries] = React.useState([]);
  const { directoryId } = useParams();

  React.useEffect(() => {
    if (!directoryId) return dispatch(getRoot());
    dispatch(getDirectoryInfo(directoryId));
    dispatch(getDirectoryContent(directoryId));
    setSelectedEntries([]);
  }, [directoryId]);

  React.useEffect(() => {
    if (!directoryInfo?._id) return;
    dispatch(getCompleteDirectoryStructure(directoryInfo?._id));
  }, [directoryInfo]);

  function handleToggleListView() {
    setListView((currentState) => !currentState);
  }
  function handleToggleSidebar() {
    setShowSidebar((currentState) => !currentState);
  }

  function handleNewFolder() {
    Prompt({
      title: 'New Folder',
      inputPlaceholder: 'New Folder',
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Create',
      icon: 'info',
      text: 'What is the new folder name?',
      inputValidator: function (value) {
        if (!value) return 'Please, inform a name to the new folder';
      },
    }).then(function ({ isConfirmed, value }) {
      if (isConfirmed) dispatch(createFolder(directoryInfo?._id, value));
    });
  }

  function handleUploadClick() {
    setFileUploadModal(true);
  }

  function handleCloseModal() {
    setFileUploadModal(false);
    setFiles([]);
  }

  function handleUpload() {
    dispatch(uploadFiles(directoryInfo?._id, files));
    handleCloseModal();
  }

  return (
    <Container>
      <ModalContainer open={fileUploadModal} handleClose={handleCloseModal}>
        <Paper component={Box} padding={2}>
          <ModalTitle>Upload Files</ModalTitle>
          <DropZone
            multiple
            file={files}
            onChangeFile={setFiles}
            modal
            allowedExtensions="any"
          />
          <ButtonContainer>
            <Button
              color="secondary"
              onClick={handleCloseModal}
              variant="contained"
            >
              Cancel
            </Button>
            <Button color="primary" onClick={handleUpload} variant="contained">
              Upload
            </Button>
          </ButtonContainer>
        </Paper>
      </ModalContainer>
      <Paper component={Box} height="100%">
        <Toolbar
          path={directoryInfo?.path}
          toggleListView={handleToggleListView}
          listView={listView}
          toggleSidebar={handleToggleSidebar}
          showSidebar={showSidebar}
          directoryName={directoryInfo?.name}
          onCreateFolder={handleNewFolder}
          onUploadClick={handleUploadClick}
          selectedEntries={selectedEntries}
        />
        <Box
          height="calc(100% - 72px)"
          display="flex"
          flexDirection="row"
          flex="1"
        >
          <Box
            height="100%"
            overflow="auto"
            borderRight={'1px solid #999'}
            width={showSidebar ? '25%' : 0}
            style={{ transition: 'width 500ms' }}
            display={{
              xs: 'none',
              sm: 'none',
              md: 'block',
              lg: 'block',
              xl: 'block',
            }}
          >
            {directoryStructure.content?.map((child) => (
              <TreeItem entry={child} path={directoryInfo?.path} />
            ))}
          </Box>
          <Box height="100%" overflow="auto" width="100%">
            <FilesContainer
              onChangeSelection={setSelectedEntries}
              showSidebar={showSidebar}
              listView={listView}
              selectedEntries={selectedEntries}
            />
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

function mapStateToProps(state) {
  return {
    directoryInfo: state.vault.directoryInfo,
    directoryStructure: state.vault.directoryStructure,
    entryInfo: state.vault.entryInfo,
  };
}

export default connect(mapStateToProps)(Vault);
