import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';

export const Container = styled.div`
  min-width: 60vw;
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ModalTitle = styled.div`
  padding-left: 15px;
  padding-bottom: 20px;
  font-size: 1.3rem;
`;

export const InputContent = styled.form`
  width: 100%;
  padding: 20px 10px 10px 10px;
`;

export const CheckboxContent = styled.form`
  width: 100%;
  padding: 12px 10px 15px 0px;
`;

export const ButtonContainer = styled.div`
  margin-top: 25px;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: flex-end;
  flex-direction: row;
  flex: 1;
`;

export const AddButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 32px;
`;

export function AddButton(props) {
  return (
    <AddButtonContainer>
      <Button {...props} />
    </AddButtonContainer>
  );
}
