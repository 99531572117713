export const SET_HISTORY_FILTER = '[history] Set Filter';
export const CLEAR_HISTORY_FILTER = '[history] Clear Filter';

export const setHistoryFilter = (filter) => ({
  type: SET_HISTORY_FILTER,
  payload: filter,
});
export const clearHistoryFilter = () => ({
  type: CLEAR_HISTORY_FILTER,
});
