import { Tooltip } from '@material-ui/core';
import React from 'react';
import {
  Container,
  DropContainer,
  DropMessage,
  FileDisplayContainer,
  FileErrorMessage,
  FileName,
  FileRemove,
  FileSize,
  FileStatusBar,
  FileType,
  FileTypeLogo,
} from './styles';

function ConditionalTooltip({ show, children }) {
  return show ? (
    <Tooltip
      placement="top"
      title="You can only upload files to an already created job."
    >
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  );
}

function DropZone({
  file,
  onChangeFile,
  multiple,
  allowedExtensions,
  modal,
  limit,
  disabled,
}) {
  const [errorMessage, setErrorMessage] = React.useState('');

  const fileInputRef = React.useRef();

  const dragOver = (e) => {
    e.preventDefault();
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };

  const validateFile = (fileToValidate) => {
    const extension = fileToValidate.name.split('.').pop();
    if (allowedExtensions) {
      if (allowedExtensions === 'any') return true;
      return allowedExtensions.some((allowedExtension) => extension.includes(allowedExtension));
    }
    if (!extension.includes('xls')) {
      return false;
    }
    return true;
  };

  const handleFile = (files) => {
    if (!multiple) {
      const selectedFile = files[0];
      if (!validateFile(selectedFile)) {
        selectedFile.invalid = true;
        setErrorMessage('File type not permitted');
      }
      onChangeFile(selectedFile);
    } else {
      Array.from(files).forEach((file, index) => {
        if (!validateFile(file)) {
          files[index].invalid = true;
        }
      });
      if (Array.from(files).some((file) => file.invalid)) setErrorMessage('File type not permitted');
      onChangeFile([
        ...file,
        ...(limit ? Array.from(files).splice(0, limit) : files),
      ]);
      return files;
    }
  };

  const fileDrop = (e) => {
    e.preventDefault();
    const { files } = e.dataTransfer;
    if (files.length) {
      handleFile(files);
    }
  };

  const fileSize = (size) => {
    if (size === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return `${parseFloat((size / k ** i).toFixed(2))} ${sizes[i]}`;
  };

  const fileType = (fileName) => fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length)
    || fileName;

  const handleRemove = () => onChangeFile(null);
  const handleRemoveMulti = (index) => () => {
    const filesCopy = [...file];
    filesCopy.splice(index, 1);
    onChangeFile(filesCopy);
  };

  const fileInputClicked = () => {
    fileInputRef.current.click();
  };

  const filesSelected = () => {
    if (fileInputRef.current.files.length) {
      handleFile(fileInputRef.current.files);
    }
  };

  return (
    <Container modal={modal}>
      <ConditionalTooltip show={disabled}>
        <DropContainer
          onDragOver={!disabled && dragOver}
          onDragEnter={!disabled && dragEnter}
          onDragLeave={!disabled && dragLeave}
          onDrop={!disabled && fileDrop}
          onClick={!disabled && fileInputClicked}
          disabled={disabled}
        >
          <DropMessage>
            <figure>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="17"
                viewBox="0 0 20 17"
              >
                <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" />
              </svg>
            </figure>
            Drag & Drop a file here or click to upload
          </DropMessage>
          <input
            ref={fileInputRef}
            type="file"
            multiple
            onChange={filesSelected}
          />
        </DropContainer>
      </ConditionalTooltip>
      <FileDisplayContainer>
        {file
          && (multiple ? (
            Array.from(file).map((currentFile, index) => (
              <FileStatusBar key={index}>
                <div>
                  <FileTypeLogo>
                    <svg
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      title="fontSize large"
                    >
                      <path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zM6 20V4h7v5h5v11H6z" />
                    </svg>
                  </FileTypeLogo>
                  <FileType>{fileType(currentFile.name)}</FileType>
                  <FileName invalid={currentFile.invalid}>
                    {currentFile.name}
                  </FileName>
                  <FileSize>
                    (
                    {fileSize(currentFile.size)}
                    )
                  </FileSize>
                  {currentFile.invalid && (
                    <FileErrorMessage>
                      (
                      {errorMessage}
                      )
                    </FileErrorMessage>
                  )}
                </div>

                <FileRemove onClick={handleRemoveMulti(index)}>X</FileRemove>
              </FileStatusBar>
            ))
          ) : (
            <FileStatusBar>
              <div>
                {file.type.toLowerCase().includes('image') ? (
                  <FileTypeLogo>
                    <img src={URL.createObjectURL(file)} />
                  </FileTypeLogo>
                ) : (
                  <>
                    <FileTypeLogo>
                      <svg
                        focusable="false"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        title="fontSize large"
                      >
                        <path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zM6 20V4h7v5h5v11H6z" />
                      </svg>
                    </FileTypeLogo>
                    <FileType>{fileType(file.name)}</FileType>
                  </>
                )}
                <FileName invalid={file.invalid}>{file.name}</FileName>
                <FileSize>
                  (
                  {fileSize(file.size)}
                  )
                </FileSize>
                {file.invalid && (
                  <FileErrorMessage>
                    (
                    {errorMessage}
                    )
                  </FileErrorMessage>
                )}
              </div>

              <FileRemove onClick={handleRemove}>X</FileRemove>
            </FileStatusBar>
          ))}
      </FileDisplayContainer>
    </Container>
  );
}

export default DropZone;
