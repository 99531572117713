import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  TextField,
  FormGroup,
  Button,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import Alert from '../../../components/Alert';
import Spinner from '../../../components/Spinner';
import {
  Container,
  InputContent,
  ModalTitle,
  ButtonContainer,
  CheckboxContent,
} from './styles';

import {
  getCompanyCode,
  createCompanyCode,
  updateCompanyCode,
} from '../../../redux/actions/companyCode';

const styles = {
  formButton: {
    margin: 2,
  },
};

function CompanyCodeModal({
  id,
  dispatch,
  companyCode,
  companyCodeModal,
  handleClose,
  companyCodeSpinner,
}) {
  const [active, setActive] = useState(true);
  const [code, setCode] = useState('');

  const [codeError, setCodeError] = useState(false);

  useEffect(() => {
    if (id !== 'new') {
      dispatch(getCompanyCode(id));
    }
  }, [id]);

  useEffect(() => {
    if (companyCode && id !== 'new') {
      setActive(companyCode.active);
      setCode(companyCode.code);
    }
  }, [companyCode]);

  useEffect(() => {
    if (!companyCodeModal) {
      handleClose();
    }
  }, [companyCodeModal]);

  const handleSave = async () => {
    let err = false;
    setCodeError(!code);
    err = !code;

    if (!err) {
      const response = await Alert();
      if (response && response.confirm) {
        if (id === 'new') {
          dispatch(
            createCompanyCode({
              active,
              code,
            }),
          );
        } else {
          dispatch(
            updateCompanyCode({
              _id: id,
              active,
              code,
            }),
          );
        }
      }
    }
  };

  return (
    <>
      {companyCodeSpinner && <Spinner overlay />}
      <Container style={{ opacity: companyCodeSpinner ? 0.5 : 1 }}>
        <ModalTitle>
          {id === 'new' ? 'New' : 'Edit'}
          {' '}
          Company Code
        </ModalTitle>
        <FormGroup>
          <CheckboxContent>
            <FormControlLabel
              control={(
                <Switch
                  checked={active}
                  onChange={(e) => setActive(e.target.checked)}
                />
              )}
              label="Active"
              labelPlacement="start"
            />
          </CheckboxContent>
          <InputContent>
            <TextField
              label="Code*"
              error={codeError}
              helperText={codeError && 'Code is required'}
              variant="outlined"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              fullWidth
            />
          </InputContent>

          <ButtonContainer>
            <Button
              color="secondary"
              onClick={handleClose}
              variant="contained"
              style={styles.formButton}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={handleSave}
              variant="contained"
              style={styles.formButton}
            >
              Save
            </Button>
          </ButtonContainer>
        </FormGroup>
      </Container>
    </>
  );
}

export default connect((state) => ({
  companyCode: state.companyCode,
  companyCodeModal: state.ui.companyCodeModal,
  companyCodeSpinner: state.ui.companyCodeLoading,
}))(CompanyCodeModal);
