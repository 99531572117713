import {
  GET_CUSTOMER_MM,
  UPDATE_CUSTOMER_MM,
  UPDATE_OBJECT_CUSTOMER_MM,
  CREATE_CUSTOMER_MM,
  DELETE_CUSTOMER_MM,
} from '../actions/customerMM';

export function customerMMReducer(customerMM = {}, action) {
  switch (action.type) {
    case GET_CUSTOMER_MM:
      return action.payload;
    case UPDATE_CUSTOMER_MM:
      return action.payload;
    case CREATE_CUSTOMER_MM:
      return action.payload;
    case DELETE_CUSTOMER_MM:
      return action.payload;
    case UPDATE_OBJECT_CUSTOMER_MM:
      return action.payload;

    default:
      return customerMM;
  }
}
