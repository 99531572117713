import { CUSTOMER_KEY } from '../../config/consts';

export const GET_JOB = '[job] GET';
export const UPDATE_JOB = '[job] UPDATE';
export const CREATE_JOB = '[job] CREATE';
export const DELETE_JOB = '[job] DELETE';
export const UPDATE_OBJECT_JOB = '[job] UPDATE OBJECT JOB';
// UI
export const SHOW_JOB_MODAL = '[job] SHOW MODAL';
export const HIDE_JOB_MODAL = '[job] HIDE MODAL';
// Spinner
export const UPDATE_JOB_SUCCESS = '[job] Update success';
export const UPDATE_JOB_ERROR = '[job] Update Error';
export const FETCH_JOB_SUCCESS = '[job] Fetch success';
export const FETCH_JOB_ERROR = '[job] Fetch Error';
export const SHOW_JOB_SPINNER = '[job - ui] show spinner';
export const HIDE_JOB_SPINNER = '[job - ui] hide spinner';

export const getJob = (id) => ({
  type: GET_JOB,
  payload: { _id: id },
});

export const createJob = (data) => ({
  type: CREATE_JOB,
  payload: { ...data, customer: localStorage.getItem(CUSTOMER_KEY) },
});

export const updateJob = (data) => ({
  type: UPDATE_JOB,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_JOB_MODAL,
});

export const hideModal = () => ({
  type: HIDE_JOB_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_JOB_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_JOB_SPINNER,
});

export const updateObjectJob = (data) => ({
  type: UPDATE_OBJECT_JOB,
  payload: data,
});
