import { GET_RACKS, UPDATE_RACKS } from '../actions/racks';

const initState = {
  docs: [],
  page: 1,
  limit: 10,
  totalDocs: 0,
};

export function racksReducer(racks = initState, action) {
  switch (action.type) {
    case UPDATE_RACKS:
      return action.payload;
    case GET_RACKS:
    default:
      return racks;
  }
}
