import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { TextField, Button } from '@material-ui/core';
import Alert from '../../../components/Alert/index';
import Spinner from '../../../components/Spinner/index';
import {
  Container, InputContent, ModalTitle, ButtonContainer,
} from './styles';

import {
  getWarehouse,
  createWarehouse,
  updateWarehouse,
} from '../../../redux/actions/warehouse';

const styles = {
  formButton: {
    margin: 2,
  },
};

function WarehouseModal({
  id,
  dispatch,
  warehouse,
  warehouseModal,
  handleClose,
  warehouseSpinner,
  onSave,
  slocId,
}) {
  const [nameError, setNameError] = useState(false);
  const [data, setData] = useState({ name: '', max_weight: '' });
  const { name, max_weight } = data;
  useEffect(() => {
    if (id !== 'new') {
      dispatch(getWarehouse(id));
    }
  }, [id]);

  useEffect(() => {
    if (warehouse && id !== 'new') {
      setData(warehouse);
    }
  }, [warehouse]);

  useEffect(() => {
    if (!warehouseModal) {
      handleClose();
    }
  }, [warehouseModal]);

  const handleSave = async () => {
    let err = false;
    setNameError(!name);
    err = !name;

    if (!err) {
      if (onSave) return onSave(data);
      const response = await Alert();
      if (response && response.confirm) {
        if (id === 'new') {
          dispatch(createWarehouse({ ...data, sloc: slocId }));
        } else {
          dispatch(updateWarehouse(data));
        }
      }
    }
  };

  const handleEdit = (e) => {
    const { name, value } = e.target;
    setData((currentData) => ({ ...currentData, [name]: value }));
  };

  return (
    <>
      {warehouseSpinner && <Spinner overlay />}

      <Container style={{ opacity: warehouseSpinner ? 0.5 : 1 }}>
        <ModalTitle>
          {id === 'new' ? 'New' : 'Edit'}
          {' '}
          Warehouse
        </ModalTitle>
        <InputContent>
          <TextField
            label="Warehouse"
            required
            type="text"
            error={nameError}
            helperText={nameError && 'Warehouse is required'}
            variant="outlined"
            value={name || ''}
            name="name"
            onChange={handleEdit}
            fullWidth
          />
        </InputContent>
        <InputContent>
          <TextField
            label="Maximum Weight"
            required
            type="number"
            variant="outlined"
            value={max_weight || ''}
            name="max_weight"
            onChange={handleEdit}
            fullWidth
          />
        </InputContent>

        <ButtonContainer>
          <Button
            color="secondary"
            onClick={handleClose}
            variant="contained"
            style={styles.formButton}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={handleSave}
            variant="contained"
            style={styles.formButton}
          >
            Save
          </Button>
        </ButtonContainer>
      </Container>
    </>
  );
}

export default connect((state) => ({
  warehouse: state.warehouse,
  warehouseModal: state.ui.warehouseModal,
  warehouseSpinner: state.ui.warehouseLoading,
}))(WarehouseModal);
