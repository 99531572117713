export const SET_CUSTOMER_MM_FILTER = '[customer MM] Set Filter';
export const CLEAR_CUSTOMER_MM_FILTER = '[customer MM] Clear Filter';

export const setCustomerMMFilter = (filter) => ({
  type: SET_CUSTOMER_MM_FILTER,
  payload: filter,
});
export const clearCustomerMMFilter = () => ({
  type: CLEAR_CUSTOMER_MM_FILTER,
});
