import {
  UPDATE_ORIGINAL_CHARGE_SUCCESS,
  UPDATE_ORIGINAL_CHARGE_ERROR,
  FETCH_ORIGINAL_CHARGE_SUCCESS,
  FETCH_ORIGINAL_CHARGE_ERROR,
  GET_ORIGINAL_CHARGE,
  CREATE_ORIGINAL_CHARGE,
  UPDATE_ORIGINAL_CHARGE,
  updateObjectOriginalCharge,
  showSpinner,
  hideSpinner,
  hideModal,
} from '../actions/originalCharge';
import { getOriginalCharge } from '../actions/originalCharges';
import Toastify from '../../utils/Toastify/index';
import { apiRequest } from '../actions/api';

const URL = 'original_charges';

export const createOriginalChargeFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === CREATE_ORIGINAL_CHARGE) {
    dispatch(
      apiRequest(
        'POST',
        URL,
        action.payload,
        UPDATE_ORIGINAL_CHARGE_SUCCESS,
        UPDATE_ORIGINAL_CHARGE_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const updateOriginalChargeFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === UPDATE_ORIGINAL_CHARGE) {
    dispatch(
      apiRequest(
        'PUT',
        URL,
        action.payload,
        UPDATE_ORIGINAL_CHARGE_SUCCESS,
        UPDATE_ORIGINAL_CHARGE_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const getOriginalChargeFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_ORIGINAL_CHARGE) {
    const { _id } = action.payload;
    dispatch(
      apiRequest(
        'GET-id',
        URL,
        { _id },
        FETCH_ORIGINAL_CHARGE_SUCCESS,
        FETCH_ORIGINAL_CHARGE_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const processOriginalChargeCollection = ({ dispatch, getState }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_ORIGINAL_CHARGE_SUCCESS) {
    dispatch(updateObjectOriginalCharge(action.payload));
    dispatch(hideSpinner());
  } else if (action.type === UPDATE_ORIGINAL_CHARGE_SUCCESS) {
    dispatch(updateObjectOriginalCharge(action.payload));
    dispatch(hideModal());
    dispatch(hideSpinner());
    // To reload the page
    const { page, limit } = getState().originalCharges;
    dispatch(getOriginalCharge({ page, limit }));
    Toastify.addSuccess('Charge Code saved.');
  } else if (action.type === UPDATE_ORIGINAL_CHARGE_ERROR) {
    dispatch(hideSpinner());
    Toastify.addError(action.payload || 'Error to save the charge code');
  }
};

export const original_chargeMdl = [
  createOriginalChargeFlow,
  updateOriginalChargeFlow,
  getOriginalChargeFlow,
  processOriginalChargeCollection,
];
