import React from 'react';
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from '@material-ui/core';
import Thumbnail from '../../../../../components/Thumbnail';

function MaintenanceTable({ maintenances, hasNote }) {
  return (
    <Box>
      <Typography variant="h6">Maintenance</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Maintenance</TableCell>
            {hasNote && (
              <>
                <TableCell>Notes</TableCell>
                <TableCell>Photo Record</TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {!maintenances.length && (
            <TableRow>
              <TableCell colSpan={hasNote ? 2 : 1} align="center">
                No pending maintenance
              </TableCell>
            </TableRow>
          )}
          {maintenances.map(({ maintenance: mnt, note, photo }) => (
            <TableRow key={mnt._id}>
              <TableCell>{mnt.maintenance.name}</TableCell>
              {hasNote && (
                <>
                  <TableCell>{note}</TableCell>
                  <TableCell>
                    {photo && <Thumbnail src={URL.createObjectURL(photo)} expandable />}
                  </TableCell>
                </>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}

export default MaintenanceTable;
