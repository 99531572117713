const apiUrlMap = {
  development: 'http://localhost:8080/', // '',
  remote_development: 'https://api.rinaramp.com/',
  production: 'https://api.rinaramp.com/', // 'https://rampusa.app/api/',
};

export const apiUrl = apiUrlMap[process.env.REACT_APP_NODE_ENV || 'production'];
export const sessionStorageKey = 'user@rina_ramp';
export const CUSTOMER_KEY = 'ramp@selected_customer';
export const FILTER_LAYOUT_KEY = 'ramp@filter_layout';
export const CART_SELECTION_KEY = 'ramp@selected_cart_items';
export const RESULT_LAYOUT_KEY = 'ramp@result_layout';
export const RESULT_LAYOUT_VERSION_KEY = 'ramp@result_layout_version';
export const RESULT_LAYOUT_VERSION = '1.0';

export { default as DEFAULT_RESULT_LAYOUT } from '../utils/StaticData/EquipmentInputs/result_data';
