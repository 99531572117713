import { isBefore, addDays } from 'date-fns';
import getRINADescription, {
  numberValues,
} from '../../Equipment/getRINADescription';

export default [
  {
    label: 'Customer MM No',
    field: 'customer_MM.mm_number',
    type: 'String',
  },
  {
    label: 'RINA Description',
    type: 'Function',
    function_id: 'GET RINA DESCRIPTION',
  },
  {
    label: 'Equipment Type',
    field: 'customer_MM.equipment_type',
    type: 'String',
  },
  {
    label: 'Modifier',
    field: 'customer_MM.modifier',
    type: 'String',
  },
  {
    label: 'Size',
    field: 'customer_MM.size',
    type: 'Function',
    function_id: 'GET TUBE SIZE',
  },
  {
    label: 'Weight',
    field: 'customer_MM.weight',
    type: 'Function',
    function_id: 'GET TUBE WEIGHT',
  },
  {
    label: 'Grade',
    field: 'customer_MM.grade',
    type: 'String',
  },
  {
    label: 'Connection 1',
    field: 'customer_MM.connection_1',
    type: 'String',
  },
  {
    label: 'Long Description',
    field: 'customer_MM.long_description',
    type: 'String',
  },
  {
    label: 'Connection 2',
    field: 'customer_MM.connection_2',
    type: 'String',
  },
  {
    label: 'Connection Configuration',
    field: 'customer_MM.connection_configuration',
    type: 'String',
  },
  {
    label: 'Manufacturer Part No',
    field: 'customer_MM.manufacturer_pn',
    type: 'String',
  },
  { label: 'Supplier Batch No', field: 'batch_no', type: 'String' },
  { label: 'Total Qty. (EA)', field: 'qty', type: 'String' },
  { label: 'Footage', field: 'footage', type: 'String' },
  { label: 'RAMP Unique ID', field: 'individual_item_id', type: 'String' },
  { label: 'Storage Location (SLOC)', field: 'SLOC.sloc_name', type: 'String' },
  { label: 'SLOC ID', field: 'SLOC.sloc_id', type: 'String' },
  { label: 'Bin Location', field: 'bin_location.name', type: 'String' },
  { label: 'Rack Location', field: 'rack_location.name', type: 'String' },
  {
    label: 'Warehouse Location',
    field: 'warehouse_location.name',
    type: 'String',
  },
  { label: 'COPAS Class', field: 'classification', type: 'String' },
  { label: 'Damage Description', field: 'damage.description', type: 'String' },
  {
    label: 'Assigned Project',
    field: 'assigned_customer_charge_code.well.project.name',
    type: 'String',
  },
  {
    label: 'Assigned Charge Code',
    field: 'assigned_customer_charge_code.original_charge_code',
    type: 'String',
  },
  {
    label: 'Assigned Well',
    field: 'assigned_customer_charge_code.well.name',
    type: 'String',
  },
  {
    label: 'Assigned Plant Code',
    field: 'plant_code',
    type: 'String',
  },
  {
    label: 'Facility Reference ID',
    field: 'facility_reference_id',
    type: 'String',
  },
  {
    label: 'Allocated Project',
    field: 'allocated_charge_code.well.project.name',
    type: 'String',
  },
  {
    label: 'Allocated Charge Code',
    field: 'allocated_charge_code.original_charge_code',
    type: 'String',
  },
  {
    label: 'Allocated Well',
    field: 'allocated_charge_code.well.name',
    type: 'String',
  },
  {
    label: 'Allocated Plant Code',
    field: 'allocated_plant_code',
    type: 'String',
  },
  {
    label: 'Allocated Batch No',
    field: 'allocated_batch_number',
    type: 'String',
  },
  { label: 'RBW', field: 'RBW', type: 'String' },
  {
    label: 'B.O.M ID',
    type: 'Function',
    function_id: 'GET BOM ID',
  },
  {
    label: 'Container ID',
    type: 'Function',
    function_id: 'GET CONTAINER ID',
  },
  { label: 'Supplier Part No', field: 'supplier_part_no', type: 'String' },
  { label: 'Supplier Serial No', field: 'supplier_serial_no', type: 'String' },
  { label: 'Status', field: 'status', type: 'String' },
  {
    label: 'Requires Maintenance',
    type: 'Function',
    function_id: 'DOES IT REQUIRE MAINTENANCE',
  },
  { label: 'Last Reconciled Date', field: 'last_reconcile_date', type: 'Date' },
  { label: 'Legacy Tag ID', field: 'legacy_tag_id', type: 'String' },
  { label: 'Material Layout', field: 'material_layout', type: 'String' },
  {
    label: 'Mother Tube ID',
    field: 'mother_id',
    type: 'Function',
    function_id: 'GET MOTHER TUBE ID',
    route: 'equipment',
    param: {
      field: 'mother_id',
      type: 'Function',
      function_id: 'GET MOTHER TUBE _ID',
    },
  },
  { label: 'Purchase Price', field: 'purchase_price', type: 'Currency' },
  { label: 'Fabrication Costs', field: 'fabrication_costs', type: 'Currency' },
  { label: 'Service Cost', field: 'service_cost', type: 'Currency' },
  {
    label: 'Transportation Cost',
    field: 'transportation_cost',
    type: 'Currency',
  },
  { label: 'Handling Cost', field: 'handling_cost', type: 'Currency' },
  { label: 'Inspection Cost', field: 'inspection_cost', type: 'Currency' },
  {
    label: 'Material Value Including Services',
    field: 'material_value_plus_services',
    type: 'Currency',
  },
  { label: 'Price per Foot', field: 'price_per_foot', type: 'Currency' },
  { label: 'Price per Each', field: 'price_per_each', type: 'Currency' },
  {
    label: 'Current Material Value',
    field: 'current_material_value',
    type: 'Currency',
  },
  { label: 'RINA Work Order No', field: 'rina_work_order_no', type: 'String' },
];

export const dataExtractors = {
  'GET RINA DESCRIPTION': (item) => getRINADescription(item?.customer_MM),
  'DOES IT REQUIRE MAINTENANCE': ({ maintenance }) => {
    const today = new Date();
    const requiresMaintenance = Array.isArray(maintenance)
      ? maintenance.some(
        ({ date_of_last_inspection, maintenance_interval_days }) => {
          if (!date_of_last_inspection || !maintenance_interval_days) return false;
          const expectedDate = addDays(
            new Date(date_of_last_inspection),
            maintenance_interval_days,
          );
          const isItOverdue = isBefore(expectedDate, today);
          return isItOverdue;
        },
      )
      : false;
    return requiresMaintenance ? 'YES' : 'NO';
  },
  'GET BOM ID': ({ parent }) => {
    if (!parent || parent.material_layout !== 'B.O.M') return '--';
    return parent.individual_item_id || '--';
  },
  'GET CONTAINER ID': ({ parent }) => {
    if (!parent || parent.material_layout === 'B.O.M') return '--';
    return parent.individual_item_id || '--';
  },
  'GET TUBE SIZE': ({ customer_MM }) => numberValues(customer_MM, 'size'),
  'GET TUBE WEIGHT': ({ customer_MM }) => numberValues(customer_MM, 'weight'),
  'GET MOTHER TUBE ID': ({ mother_id }) => mother_id?.individual_item_id,
  'GET MOTHER TUBE _ID': ({ mother_id }) => mother_id?._id,
};
