import React from 'react';
import { Dialog } from '@material-ui/core';

function ModalContainer({
  open, handleClose, children, maxWidth = 'xl',
}) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      closeAfterTransition
      maxWidth={maxWidth}
      scroll="body"
    >
      {children}
    </Dialog>
  );
}

export default ModalContainer;
