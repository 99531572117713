import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import format from 'date-fns/format';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import Button from '@material-ui/core/Button';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionActions';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import getRINADescription from '../../utils/Equipment/getRINADescription.js';
import {
  AttachFile,
  InsertChart,
  EditRounded,
  AssignmentOutlined as InspectIcon,
  ArrowBack,
} from '@material-ui/icons';
import { getWorkOrders } from '../../redux/actions/workOrders';
import { getProjects } from '../../redux/actions/projects';
import { getOriginalCharge } from '../../redux/actions/originalCharges';
import Spinner from '../../components/Spinner/index';
import { Container, ButtonContainer, ButtonsContainer } from './styles';
import { getActivities } from '../../redux/actions/activities.js';
import ModalContainer from '../../components/ModalContainer/index';
import { Link } from 'react-router-dom';
import {
  hideDocumentationModal,
  showDocumentationModal,
  hideVPASModal,
  showVPASModal,
} from '../../redux/actions/jobs';
import JobVPASModal from '../Modals/JobVPAS';
import JobsDocumentationModal from '../Modals/JobsDocumentationModal';
import { TitleContent } from './styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 600,
    position: 'relative',
    width: '100%',
  },
  title: {
    fontSize: 20,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    '& .MuiAccordionSummary-root.Mui-expanded ': {
      borderBottom: '1px solid #ddd',
      minHeight: '10px !important',
    },
    '& .MuiAccordionSummary-content.Mui-expanded ': {
      margin: 0,
    },
  },
  row: {
    padding: 0,
  },
  cell: {
    fontSize: 12,
    padding: '0px 10px 0px 15px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  buttons: {
    color: 'blue',
  },
  checkButton: {
    color: 'green',
  },
  closeButton: {
    color: 'red',
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 0,
  },
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 420,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const initialFilterState = {
  project: '',
  charge_code: '',
  rina_work_order_no: '',
  rina_job_number: '',
  well: '',
  activity: '',
  supplier_release: '',
  job_status: '',
  from_date: null,
  to_date: null,
};

const Job = ({
  dispatch,
  workOrders,
  workOrdersLoading,
  jobModal,
  projects,
  originalCharges,
  vpasModal,
  activities,
  documentsModal,
}) => {
  const { customer } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const [jobsFormated, setJobsFormated] = useState([]);
  const [modalContentId, setModalContentId] = useState(null);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(50);
  const [total, setTotal] = useState(0);
  const [filterExpanded, setFilterExpanded] = useState(false);
  const [filter, setFilter] = useState(initialFilterState);
  const [autoCompleteFieldsInput, setAutoCompleteFieldsInput] =
    useState(initialFilterState);

  useEffect(() => {
    dispatch(getProjects({ page: 1, limit: 999 }));
    dispatch(getOriginalCharge({ page: 1, limit: 999 }));
  }, []);

  React.useEffect(() => {
    dispatch(
      getActivities({
        filter: { listAll: true },
      })
    );
  }, []);

  useEffect(() => {
    dispatch(getWorkOrders({ page: page + 1, limit, filter: { customer } }));
  }, [page, limit]);

  useEffect(() => {
    if (workOrders.docs) {
      setJobsFormated(workOrders.docs);
      setTotal(workOrders.totalDocs);
    }
  }, [workOrders]);

  const handleBack = () => {
    history.replace('/equipments');
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };
  // const handleOpenModal = () => {
  //   dispatch(showModal());
  // };

  // const handleCloseModal = () => {
  //   dispatch(hideModal());
  // };

  const handleCloseVPASModal = () => {
    dispatch(hideVPASModal());
  };

  const handleCloseDocumentationModal = () => {
    dispatch(hideDocumentationModal());
  };

  function toggleFilter() {
    setFilterExpanded((currentState) => !currentState);
  }

  function handleChangeFilter(e) {
    const { name, value } = e.target;
    setFilter((currentState) => ({ ...currentState, [name]: value }));
  }

  function handleAutoComplete(name) {
    function handler(e, value) {
      setFilter((currentState) => ({
        ...currentState,
        [name]: value && value._id ? value._id : '',
      }));
    }
    return handler;
  }

  function handleFilter(e) {
    e.preventDefault();
    let to_date = null;
    if (filter.to_date) {
      to_date = new Date(filter.to_date);
      to_date.setHours(23, 59, 59);
    }
    dispatch(
      getWorkOrders({
        page: 1,
        limit,
        filter: { ...filter, customer, to_date },
      })
    );
  }

  function handleAutoCompleteInput(name) {
    function handler(e, value) {
      setAutoCompleteFieldsInput((currentState) => ({
        ...currentState,
        [name]: value,
      }));
    }
    return handler;
  }

  function handleClear() {
    setFilter(initialFilterState);
    setAutoCompleteFieldsInput(initialFilterState);
    dispatch(
      getWorkOrders({
        page: 1,
        limit,
        filter: { customer },
      })
    );
  }

  function handleDate(name) {
    function handler(value) {
      setFilter((currentState) => ({ ...currentState, [name]: value }));
    }
    return handler;
  }

  function handleOpenVPASModal(id) {
    function handler() {
      setModalContentId(id);
      dispatch(showVPASModal());
    }
    return handler;
  }

  function handleOpenDocumentationModal(id) {
    function handler() {
      setModalContentId(id);
      dispatch(showDocumentationModal());
    }
    return handler;
  }

  function renderRevisionEquipment(equipment) {
    return (
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Equipment</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {equipment.map((movimentation, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {movimentation?.customer_MM &&
                    getRINADescription(movimentation.customer_MM)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return (
    <>
      <ModalContainer open={vpasModal} handleClose={handleCloseVPASModal}>
        <JobVPASModal id={modalContentId} handleClose={handleCloseVPASModal} />
      </ModalContainer>
      <ModalContainer
        open={documentsModal}
        handleClose={handleCloseDocumentationModal}
      >
        <JobsDocumentationModal
          id={modalContentId}
          handleClose={handleCloseDocumentationModal}
        />
      </ModalContainer>
      <Container>
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <Accordion expanded={filterExpanded}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                onClick={toggleFilter}
              >
                <div className={classes.heading}>
                  <TitleContent>Jobs Filter</TitleContent>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <form onSubmit={handleFilter}>
                  <Grid
                    container
                    spacing={3}
                    style={{ padding: '0px 10px 10px 10px' }}
                  >
                    <Grid item lg={2} md={3} sm={12} xs={12}>
                      <TextField
                        size="small"
                        label="RINA Work Order N°"
                        value={filter.rina_work_order_no}
                        name="rina_work_order_no"
                        onChange={handleChangeFilter}
                        fullWidth
                      />
                    </Grid>
                    <Grid item lg={2} md={3} sm={12} xs={12}>
                      <TextField
                        size="small"
                        label="eQuality Job N°"
                        value={filter.rina_job_number}
                        name="rina_job_number"
                        onChange={handleChangeFilter}
                        fullWidth
                      />
                    </Grid>
                    <Grid item lg={2} md={3} sm={12} xs={12}>
                      <Autocomplete
                        size="small"
                        className={classes.customAutoComplete}
                        options={projects && projects.docs ? projects.docs : []}
                        getOptionLabel={(option) => option.name || ''}
                        value={
                          (projects &&
                            projects.docs &&
                            projects.docs.find(
                              ({ _id }) => _id === filter.project
                            )) ||
                          ''
                        }
                        onInputChange={handleAutoCompleteInput('project')}
                        inputValue={autoCompleteFieldsInput.project || ''}
                        onChange={handleAutoComplete('project')}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            variant="standard"
                            label="Project"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item lg={2} md={3} sm={12} xs={12}>
                      <TextField
                        size="small"
                        label="Well"
                        value={filter.well}
                        name="well"
                        onChange={handleChangeFilter}
                        fullWidth
                      />
                    </Grid>
                    <Grid item lg={2} md={3} sm={12} xs={12}>
                      <Autocomplete
                        size="small"
                        className={classes.customAutoComplete}
                        options={
                          (originalCharges && originalCharges.docs) || []
                        }
                        getOptionLabel={(option) =>
                          option.original_charge_code || ''
                        }
                        value={
                          (originalCharges &&
                            originalCharges.docs &&
                            originalCharges.docs.find(
                              ({ _id }) => _id === filter.charge_code
                            )) ||
                          ''
                        }
                        onInputChange={handleAutoCompleteInput('charge_code')}
                        inputValue={autoCompleteFieldsInput.charge_code || ''}
                        onChange={handleAutoComplete('charge_code')}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            variant="standard"
                            label="Charge Code"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item lg={2} md={3} sm={12} xs={12}>
                      <TextField
                        size="small"
                        label="Activity"
                        value={filter.activity}
                        name="activity"
                        onChange={handleChangeFilter}
                        fullWidth
                        select
                      >
                        {activities.map(({ _id, title }) => (
                          <MenuItem key={_id} value={_id}>
                            {title}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item lg={2} md={3} sm={12} xs={12}>
                      <TextField
                        size="small"
                        label="Supplier Release"
                        value={filter.supplier_release}
                        name="supplier_release"
                        onChange={handleChangeFilter}
                        fullWidth
                      />
                    </Grid>
                    <Grid item lg={2} md={3} sm={12} xs={12}>
                      <TextField
                        size="small"
                        label="Job Status"
                        value={filter.job_status}
                        name="job_status"
                        onChange={handleChangeFilter}
                        fullWidth
                      />
                    </Grid>
                    <Grid item lg={2} md={3} sm={12} xs={12}>
                      <KeyboardDatePicker
                        label="From"
                        size="small"
                        variant="outlined"
                        name="from_date"
                        value={filter.from_date}
                        onChange={handleDate('from_date')}
                        format="MM-dd-yyyy"
                        fullWidth
                      />
                    </Grid>
                    <Grid item lg={2} md={3} sm={12} xs={12}>
                      <KeyboardDatePicker
                        label="To"
                        size="small"
                        variant="outlined"
                        name="to_date"
                        value={filter.to_date}
                        onChange={handleDate('to_date')}
                        format="MM-dd-yyyy"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs justify="center">
                      <ButtonsContainer style={{ justifyContent: 'flex-end' }}>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ margin: 10 }}
                          type="submit"
                        >
                          Filter
                        </Button>
                        <Button
                          variant="contained"
                          type="clear"
                          color="secondary"
                          style={{ margin: 10 }}
                          onClick={handleClear}
                        >
                          Clear
                        </Button>
                      </ButtonsContainer>
                    </Grid>
                  </Grid>
                </form>
              </AccordionDetails>
            </Accordion>
          </Paper>
          <Paper className={classes.paper}>
            <Toolbar className={classes.toolBar}>
              <span
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <IconButton onClick={handleBack}>
                  <ArrowBack />
                </IconButton>
                <Typography
                  className={classes.title}
                  variant="h6"
                  id="tableTitle"
                  component="span"
                >
                  Jobs
                </Typography>
              </span>
            </Toolbar>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Transaction ID</TableCell>
                    <TableCell>Start Date</TableCell>
                    <TableCell>Project</TableCell>
                    <TableCell>Well</TableCell>
                    <TableCell>Charge Code</TableCell>
                    <TableCell>Vendor Location</TableCell>
                    <TableCell>RINA Work Order No</TableCell>
                    <TableCell>eQuality Job No</TableCell>
                    <TableCell>Activity</TableCell>
                    <TableCell>Supplier Release</TableCell>
                    <TableCell>Job Status</TableCell>
                    <TableCell colSpan={2} style={{ minWidth: 170 }} />
                    {/* <TableCell colSpan={2} style={{ minWidth: 170 }} /> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {workOrdersLoading && (
                    <TableRow className={classes.row}>
                      <TableCell colSpan="14" className={classes.cell}>
                        <Spinner parent="Table" />
                      </TableCell>
                    </TableRow>
                  )}
                  {!workOrdersLoading &&
                    jobsFormated.map((job) => (
                      <TableRow className={classes.row}>
                        <TableCell className={classes.cell}>
                          {job?.transactionId}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {job.revision?.start_date &&
                            format(
                              new Date(job.revision.start_date),
                              'MM-dd-yyyy'
                            )}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {job?.revision?.charge_code?.well?.project?.name}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {job?.revision?.charge_code?.well?.name}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {job.revision?.charge_code?.original_charge_code}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {job.revision?.vendor?.vendor_address?.city}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <HtmlTooltip
                            title={
                              job.revision?.equipment?.length
                                ? renderRevisionEquipment(
                                    job.revision.equipment
                                  )
                                : 'No equipment'
                            }
                          >
                            <Link to={`/equalityworkorder/${job._id}`}>
                              {job.rina_work_order_no}-
                              {job.revision?.revision
                                ? job.revision.revision
                                    .toString()
                                    .padStart(2, '0')
                                : '00'}
                            </Link>
                          </HtmlTooltip>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {job.rina_job_number}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {job.revision?.activity?.title}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {job.revision?.batch_no}
                        </TableCell>
                        <TableCell className={classes.cell} style={{textTransform: 'capitalize'}}>
                          {job.job_status}
                        </TableCell>
                        <TableCell colSpan={2} className={classes.cell}>
                          <Tooltip title="Inspect">
                            <Link to={`/inspections/${job._id}`}>
                              <IconButton>
                                <InspectIcon />
                              </IconButton>
                            </Link>
                          </Tooltip>
                          <Tooltip
                            title={`V-PAS${
                              job?.VPAS?.length > 0
                                ? ` (${job?.VPAS?.length})`
                                : ''
                            }`}
                          >
                            <IconButton
                              color={
                                job?.VPAS?.length > 0 ? 'secondary' : 'default'
                              }
                              onClick={handleOpenVPASModal(job._id)}
                            >
                              <ErrorOutline />
                            </IconButton>
                          </Tooltip>
                          {/* <Tooltip title="Inspections">
                            <IconButton>
                              <InsertChart />
                            </IconButton>
                          </Tooltip> */}
                          <Tooltip title="Documentation">
                            <IconButton
                              onClick={handleOpenDocumentationModal(job._id)}
                            >
                              <AttachFile />
                            </IconButton>
                          </Tooltip>
                        </TableCell>

                        {/* <TableCell colSpan={2} className={classes.cell}>
                          <Tooltip title="Edit">
                            <Link to={`/equalityworkorder/${job._id}`}>
                              <IconButton>
                                <EditRounded />
                              </IconButton>
                            </Link>
                          </Tooltip>
                        </TableCell> */}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[50, 100, 200, 500, 1000]}
              component="div"
              count={total}
              rowsPerPage={limit}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  workOrdersLoading: state.ui.workOrdersLoading,
  workOrders: state.workOrders,
  projects: state.projects,
  originalCharges: state.originalCharges,
  vpasModal: state.ui.jobVpasModal,
  documentsModal: state.ui.documentsModal,
  activities: state.activities?.docs || state.activities,
});

export default connect(mapStateToProps)(Job);
