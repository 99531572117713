import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  AddCircleOutline,
  HighlightOff as HighlightOffIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@material-ui/icons';
import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import getRINADescription from '../../utils/Equipment/getRINADescription';

const FabricationRow = ({
  movimentation,
  root,
  openModal,
  enableRemoval = true,
  consumedIndex,
  editedIndex,
  fabricated = [],
  onRemove,
}) => {
  const { id } = useParams();
  const [open, setOpen] = React.useState(root);

  return (
    <>
      <TableRow>
        <TableCell>
          {root && (
            <Tooltip title="Fabricate from this equipment">
              <IconButton
                size="small"
                color="primary"
                onClick={() => openModal(movimentation._id)}
                disabled={id !== 'new'}
              >
                <AddCircleOutline />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
        <TableCell>
          {!root && enableRemoval && (
            <Tooltip title="Remove from Hierarchy">
              <IconButton
                size="small"
                color="secondary"
                onClick={onRemove(consumedIndex, editedIndex)}
                disabled={id !== 'new'}
              >
                <HighlightOffIcon />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
        <TableCell>
          {fabricated.length > 0 && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell
          title="RINA Description"
          style={{ minWidth: 250, maxWidth: 300 }}
        >
          {getRINADescription(movimentation.customer_MM)}
        </TableCell>
        <TableCell title="RAMP Unique ID">
          {movimentation?.individual_item_id}
        </TableCell>
        <TableCell title="Customer MM No">
          {movimentation?.customer_MM?.mm_number}
        </TableCell>
        <TableCell title="Total Qty. EA">{movimentation.qty}</TableCell>
        <TableCell title="Footage">{movimentation.footage}</TableCell>
        <TableCell title="COPAS Class">
          {movimentation.classification}
        </TableCell>
        <TableCell title="Supplier MM No"></TableCell>
        <TableCell title="Supplier Batch No"></TableCell>
        <TableCell title="Heat/Lot">{movimentation.heat}</TableCell>
        <TableCell title="Joint/Run No">{movimentation.joint_no}</TableCell>
        <TableCell title="RBW">{movimentation.RBW}</TableCell>
        <TableCell title="Facility Reference ID">
          {movimentation.facility_reference_id}
        </TableCell>
        <TableCell title="Assigned Project">
          {movimentation?.assigned_customer_charge_code?.well?.project?.name}
        </TableCell>
        <TableCell title="Assigned Well">
          {movimentation?.assigned_customer_charge_code?.well?.name}
        </TableCell>
        <TableCell title="Assigned Charge Code">
          {movimentation?.assigned_customer_charge_code?.original_charge_code}
        </TableCell>
        <TableCell title="RAMP Tag No"></TableCell>
        <TableCell title="RINA Work Order No">
          {movimentation.rina_work_order_no}
        </TableCell>
        <TableCell title="SLOC">{movimentation?.SLOC?.sloc_name}</TableCell>
        <TableCell title="Material Layout">
          {movimentation.material_layout}
        </TableCell>
        <TableCell title="Associated RAMP Tag"></TableCell>
        <TableCell title="Allocated">
          {movimentation.allocated_release_number ||
          movimentation.allocated_batch_number ||
          movimentation.allocated_work_order_number ? (
            <span>YES</span>
          ) : (
            <span>NO</span>
          )}
        </TableCell>
        <TableCell title="Remove From Parent"></TableCell>
        <TableCell title="Supplier">{movimentation.supplier}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={24}>
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            style={{
              borderLeft: '2px solid rgba(224, 224, 224, 1)',
            }}
          >
            <Box margin={1} marginBottom={0}>
              {root && (
                <Typography variant="h6" gutterBottom component="div">
                  Fabricated Equipment
                </Typography>
              )}
              <Table size="small" aria-label="purchases">
                <TableBody>
                  {fabricated?.map((child, index) => (
                    <FabricationRow
                      key={child._id || index}
                      movimentation={child}
                      openModal={openModal}
                      enableRemoval={enableRemoval}
                      consumedIndex={consumedIndex}
                      editedIndex={index}
                      onRemove={onRemove}
                    />
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const mapStateToProps = (state) => ({
  chargeCodes: state.originalCharges || [],
  materialMasters: state.customerMMs || [],
  SLOCs: state.slocs || [],
});

export default connect(mapStateToProps)(FabricationRow);
