import {
  UPDATE_OWNER_SUCCESS,
  UPDATE_OWNER_ERROR,
  FETCH_OWNER_SUCCESS,
  FETCH_OWNER_ERROR,
  GET_OWNER,
  CREATE_OWNER,
  UPDATE_OWNER,
  updateObjectOwner,
  showSpinner,
  hideSpinner,
  hideModal,
} from '../actions/owner';
import { getOwners } from '../actions/owners';
import Toastify from '../../utils/Toastify/index';
import { apiRequest } from '../actions/api';

const URL = 'owners';

export const createOwnerFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === CREATE_OWNER) {
    dispatch(
      apiRequest(
        'POST',
        URL,
        action.payload,
        UPDATE_OWNER_SUCCESS,
        UPDATE_OWNER_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const updateOwnerFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === UPDATE_OWNER) {
    dispatch(
      apiRequest(
        'PUT',
        URL,
        action.payload,
        UPDATE_OWNER_SUCCESS,
        UPDATE_OWNER_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const getOwnerFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_OWNER) {
    const { _id } = action.payload;
    dispatch(
      apiRequest(
        'GET-id',
        URL,
        { _id },
        FETCH_OWNER_SUCCESS,
        FETCH_OWNER_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const processOwnerCollection = ({ dispatch, getState }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_OWNER_SUCCESS) {
    dispatch(updateObjectOwner(action.payload));
    dispatch(hideSpinner());
  } else if (action.type === UPDATE_OWNER_SUCCESS) {
    dispatch(updateObjectOwner(action.payload));
    dispatch(hideModal());
    dispatch(hideSpinner());
    // To reload the page
    const { page, limit } = getState().owners;
    dispatch(getOwners({ page, limit }));
    Toastify.addSuccess('Owner saved.');
  } else if (action.type === UPDATE_OWNER_ERROR) {
    dispatch(hideSpinner());
    Toastify.addError(action.payload || 'Error to save the owner');
  }
};

export const ownerMdl = [
  createOwnerFlow,
  updateOwnerFlow,
  getOwnerFlow,
  processOwnerCollection,
];
