export const GET_PROJECTS = '[projects] GET';
export const FETCH_PROJECTS_SUCCESS = '[projects] Fetch success';
export const FETCH_PROJECTS_ERROR = '[projects] Fetch Error';
export const UPDATE_PROJECTS = '[projects] Update';
export const SHOW_PROJECTS_SPINNER = '[projects - ui] show spinner';
export const HIDE_PROJECTS_SPINNER = '[projects - ui] hide spinner';

export const getProjects = (filter) => ({
  type: GET_PROJECTS,
  payload: filter,
});

export const updateProjects = (data) => ({
  type: UPDATE_PROJECTS,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_PROJECTS_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_PROJECTS_SPINNER,
});
