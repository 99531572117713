export const GET_BUSINESS_UNIT = '[business_unit] GET';
export const UPDATE_BUSINESS_UNIT = '[business_unit] UPDATE';
export const CREATE_BUSINESS_UNIT = '[business_unit] CREATE';
export const DELETE_BUSINESS_UNIT = '[business_unit] DELETE';
export const UPDATE_OBJECT_BUSINESS_UNIT = '[business_unit] UPDATE OBJECT BUSINESS_UNIT';
// UI
export const SHOW_BUSINESS_UNIT_MODAL = '[business_unit] SHOW MODAL';
export const HIDE_BUSINESS_UNIT_MODAL = '[business_unit] HIDE MODAL';
// Spinner
export const UPDATE_BUSINESS_UNIT_SUCCESS = '[business_unit] Update success';
export const UPDATE_BUSINESS_UNIT_ERROR = '[business_unit] Update Error';
export const FETCH_BUSINESS_UNIT_SUCCESS = '[business_unit] Fetch success';
export const FETCH_BUSINESS_UNIT_ERROR = '[business_unit] Fetch Error';
export const SHOW_BUSINESS_UNIT_SPINNER = '[business_unit - ui] show spinner';
export const HIDE_BUSINESS_UNIT_SPINNER = '[business_unit - ui] hide spinner';

export const getBusinessUnit = (id) => ({
  type: GET_BUSINESS_UNIT,
  payload: { _id: id },
});

export const createBusinessUnit = (data) => ({
  type: CREATE_BUSINESS_UNIT,
  payload: data,
});

export const updateBusinessUnit = (data) => ({
  type: UPDATE_BUSINESS_UNIT,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_BUSINESS_UNIT_MODAL,
});

export const hideModal = () => ({
  type: HIDE_BUSINESS_UNIT_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_BUSINESS_UNIT_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_BUSINESS_UNIT_SPINNER,
});

export const updateObjectBusinessUnit = (data) => ({
  type: UPDATE_OBJECT_BUSINESS_UNIT,
  payload: data,
});
