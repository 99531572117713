export const GET_RECLASS_DAMAGE = '[reclassDamage] GET';
export const UPDATE_RECLASS_DAMAGE = '[reclassDamage] UPDATE';
export const CREATE_RECLASS_DAMAGE = '[reclassDamage] CREATE';
export const DELETE_RECLASS_DAMAGE = '[reclassDamage] DELETE';
export const UPDATE_OBJECT_RECLASS_DAMAGE = '[reclassDamage] UPDATE OBJECT RECLASS_DAMAGE';
// UI
export const SHOW_RECLASS_DAMAGE_MODAL = '[reclassDamage] SHOW MODAL';
export const HIDE_RECLASS_DAMAGE_MODAL = '[reclassDamage] HIDE MODAL';
// Spinner
export const UPDATE_RECLASS_DAMAGE_SUCCESS = '[reclassDamage] Update success';
export const UPDATE_RECLASS_DAMAGE_ERROR = '[reclassDamage] Update Error';
export const FETCH_RECLASS_DAMAGE_SUCCESS = '[reclassDamage] Fetch success';
export const FETCH_RECLASS_DAMAGE_ERROR = '[reclassDamage] Fetch Error';
export const SHOW_RECLASS_DAMAGE_SPINNER = '[reclassDamage - ui] show spinner';
export const HIDE_RECLASS_DAMAGE_SPINNER = '[reclassDamage - ui] hide spinner';

export const getReclassDamage = (id) => ({
  type: GET_RECLASS_DAMAGE,
  payload: { _id: id },
});

export const createReclassDamage = (data) => ({
  type: CREATE_RECLASS_DAMAGE,
  payload: data,
});

export const updateReclassDamage = (data) => ({
  type: UPDATE_RECLASS_DAMAGE,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_RECLASS_DAMAGE_MODAL,
});

export const hideModal = () => ({
  type: HIDE_RECLASS_DAMAGE_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_RECLASS_DAMAGE_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_RECLASS_DAMAGE_SPINNER,
});

export const updateObjectReclassDamage = (data) => ({
  type: UPDATE_OBJECT_RECLASS_DAMAGE,
  payload: data,
});
