import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

export const Container = styled(Grid).attrs({ container: true })`
  padding: 16px;
  height: 80vh;
  place-items: center;
`;

export const Item = styled(Grid).attrs({ item: true })`
  padding: 16px;
`;
