import React from 'react';
import { Button, TextField } from '@material-ui/core';
import ModalContainer from '../../../components/ModalContainer';
import DropZone from '../../../components/DropZone';
import { ModalTitle, ModalContent, ModalButtons } from './styles';

function UploadModal({ open, onClose, onAddFile }) {
  const [file, setFile] = React.useState(null);
  const [notes, setNotes] = React.useState('');

  function handleChangeFile(file) {
    setFile(file);
  }

  function handleChangeNotes(e) {
    const { value } = e.target;
    setNotes(value);
  }

  function handleCancel() {
    setFile(null);
    setNotes('');
    onClose();
  }

  function handleAdd() {
    onAddFile({ file, notes });
    onClose();
  }

  return (
    <ModalContainer open={open} handleClose={onClose}>
      <ModalContent>
        <ModalTitle>File Upload</ModalTitle>
        <TextField
          label="Notes"
          value={notes}
          onChange={handleChangeNotes}
          variant="outlined"
          fullWidth
          multiline
        />
        <DropZone
          file={file}
          onChangeFile={handleChangeFile}
          allowedExtensions="any"
        />
        <ModalButtons>
          <Button variant="contained" color="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleAdd}>
            Add
          </Button>
        </ModalButtons>
      </ModalContent>
    </ModalContainer>
  );
}

export default UploadModal;
