import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Animated } from 'react-animated-css';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Spinner from '../../components/ButtonSpinner/index';
import {
  Container,
  BlurPage,
  TransparentColorPage,
  Form,
  Logo,
  Input,
  LoginButton,
  ForgotPasswordButton,
  VerificationCodeText,
} from './styles';
import logoImage from '../../assets/images/logo.png';
import { login, updateLoggedUser } from '../../redux/actions/loggedUser';

import api from '../../services/Api';
import Service from '../../services/Service';
import Toastify from '../../utils/Toastify';

function Login({ dispatch, authenticated, loginSpinner }) {
  const history = useHistory();
  const [emailForm, setEmailForm] = useState('');
  const [passwordForm, setPasswordForm] = useState('');
  const [codeForm, setCodeForm] = useState('');
  const [loginForm, setLoginForm] = useState(true);
  const [verificationForm, setVerificationForm] = useState(false);
  const [resetPasswordForm, setResetPasswordForm] = useState(false);
  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    if (!authenticated) return;
    history.replace('/');
  }, [authenticated]);

  const handleForm = (selectedForm) => {
    switch (selectedForm) {
      case '#LoginForm': {
        setLoginForm(true);
        setVerificationForm(false);
        setResetPasswordForm(false);
        break;
      }
      case '#VerificationForm': {
        setLoginForm(false);
        setVerificationForm(true);
        setResetPasswordForm(false);
        break;
      }
      case '#ResetPasswordForm': {
        setLoginForm(false);
        setVerificationForm(false);
        setResetPasswordForm(true);
        break;
      }
      default: {
        setLoginForm(true);
        setVerificationForm(false);
        setResetPasswordForm(false);
        break;
      }
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await api.post('/forgot-password', { email: emailForm });
      if (response.status === 200) {
        Toastify.addSuccess('Email sent! Password reset instructions have been sent to your email');
      }
    } catch (error) {
      Toastify.addError('Error! There was a problem sending an email with instructions to reset your password');
    } finally {
      setLoading(false);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    const apiService = new Service({ route: '/login' });
    apiService.store({ email: emailForm, password: passwordForm }).then((response) => {
      if (response.ok) {
        dispatch(updateLoggedUser({ ...response.data, authenticated: true }));
        api.defaults.headers.common.Authorization = `Bearer ${response.data.token}`;
        Toastify.addSuccess('Welcome to RINA RAMP');
      } else if (response.message === 'Please, check your email inbox and copy the code.') {
        setLoading(false);
        handleForm('#VerificationForm');
      } else {
        Toastify.addError(response.message);
        setLoading(false);
      }
    });
  };

  const handleVerificationCode = async (e) => {
    if (emailForm && passwordForm && codeForm) {
      e.preventDefault();
      setLoading(true);
      const apiService = new Service({ route: '/validate' });
      apiService.store({ email: emailForm, password: passwordForm, code: codeForm }).then((response) => {
        if (response.ok) {
          dispatch(updateLoggedUser({ ...response.data, authenticated: true }));
          api.defaults.headers.common.Authorization = `Bearer ${response.data.token}`;
          Toastify.addSuccess('Welcome to RINA RAMP');
          setLoading(false);
        } else {
          Toastify.addError(response.message);
          setLoading(false);
        }
      });
    }
  };

  return (
    <>
      <Container>
        {loginForm && (
        <Animated isVisible={loginForm} animationIn="fadeInDown" animationOut="fadeOut" animationInDuration={2000} animationOutDuration={1000} style={{ display: 'flex' }}>
          <Form onSubmit={handleLogin}>

            <Grid container style={{ maxWidth: 440 }} spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                <Logo src={logoImage} />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                <Input placeholder="E-mail" onChange={(e) => setEmailForm(e.target.value)} />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                <Input type="password" placeholder="Password" onChange={(e) => setPasswordForm(e.target.value)} />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                <LoginButton disabled={loginSpinner || loading} type="submit">
                  {loginSpinner || loading ? <Spinner /> : 'Sign In'}
                </LoginButton>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                <ForgotPasswordButton
                  type="butoon"
                  onClick={() => handleForm('#ResetPasswordForm')}
                >
                  Forgot password
                </ForgotPasswordButton>
              </Grid>
            </Grid>
          </Form>
        </Animated>
        )}
        {verificationForm && (
        <Animated isVisible={verificationForm} animationIn="fadeInDown" animationOut="fadeOut" animationInDuration={2000} animationOutDuration={1000} style={{ display: 'flex' }}>
          <Form onSubmit={handleVerificationCode}>
            <Grid container style={{ maxWidth: 440 }} spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                <Logo src={logoImage} />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                <VerificationCodeText>
                  An access code was sent to your registered email.
                  {' '}
                  <br />
                  To go ahead, please use this code to validate your session below.
                </VerificationCodeText>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                <Input type="text" placeholder="Verification Code" onChange={(e) => setCodeForm(e.target.value)} />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                <LoginButton disabled={loginSpinner || loading} type="submit">
                  {loginSpinner || loading ? <Spinner /> : 'Confirm the access code'}
                </LoginButton>
              </Grid>
              {/* <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                <ForgotPasswordButton
                  type="butoon"
                  onClick={() => handleForm('#LoginForm')}
                >
                  Back to Login
                </ForgotPasswordButton>
              </Grid> */}
            </Grid>
          </Form>
        </Animated>
        )}
        {resetPasswordForm && (
        <Animated animationIn="fadeInUp" animationOut="fadeOut" animationInDuration={1000} animationOutDuration={1000} style={{ display: 'flex' }}>
          <Form>
            <Grid container style={{ maxWidth: 440 }} spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                <Logo src={logoImage} />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                <Input placeholder="E-mail" type="email" onChange={(e) => setEmailForm(e.target.value)} />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                <LoginButton onClick={handleResetPassword}>
                  {loading ? <Spinner color="#fff" /> : 'Reset password'}
                </LoginButton>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                <ForgotPasswordButton
                  type="butoon"
                  onClick={() => handleForm('#LoginForm')}
                >
                  Login page
                </ForgotPasswordButton>
              </Grid>
            </Grid>
          </Form>
        </Animated>
        )}
      </Container>
      <BlurPage>
        <TransparentColorPage />
      </BlurPage>
    </>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
    authenticated: state.loggedUser.authenticated,
    loginSpinner: state.ui.loginLoading,
  };
}

export default connect(mapStateToProps)(Login);
