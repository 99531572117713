import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, CircularProgress } from '@material-ui/core';
import DropZone from '../../../components/DropZone';
import Spinner from '../../../components/Spinner';
import {
  Container,
  InputContent,
  ModalTitle,
  ButtonContainer,
  ImageContainer,
} from './styles';

const styles = {
  formButton: {
    margin: 2,
  },
};

function PhotoModal({
  dispatch,
  handleClose,
  photoSpinner,
  onSave,
  editData,
  onDelete,
}) {
  const [descriptionError, setPhotoError] = useState(false);
  const [data, setData] = useState({
    description: '',
    file: null,
  });
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (editData) setData(editData);
    else {
      setData({
        description: '',
      });
    }
  }, [editData]);
  const { description, file } = data;

  const handleSave = async () => {
    let err = false;
    setPhotoError(!description);
    err = !description;

    if (!err) {
      setLoading(true);
      onSave(data, () => setLoading(false));
    }
  };

  const handleEdit = (e) => {
    const { name, value } = e.target;
    setData((currentData) => ({ ...currentData, [name]: value }));
  };

  function handleFile(file) {
    setData((currentData) => ({ ...currentData, file }));
  }

  return (
    <>
      {photoSpinner && <Spinner overlay />}
      <Container style={{ opacity: photoSpinner ? 0.5 : 1 }}>
        <ModalTitle>
          {editData ? 'Edit' : 'New'}
          {' '}
          Picture
        </ModalTitle>
        <InputContent>
          <TextField
            label="Description"
            required
            multiline
            type="text"
            error={descriptionError}
            helperText={descriptionError && 'Description is required'}
            variant="outlined"
            value={description || ''}
            name="description"
            onChange={handleEdit}
            fullWidth
          />
        </InputContent>
        {editData ? (
          <ImageContainer>
            <img alt={description} src={editData.url} />
          </ImageContainer>
        ) : (
          <DropZone
            allowedExtensions={[
              'jpeg',
              'jpg',
              'png',
              'svg',
              'gif',
              'webp',
              'bmp',
              'ico',
              'tiff',
              'tif',
            ]}
            file={file}
            onChangeFile={handleFile}
          />
        )}

        <ButtonContainer>
          {editData && (
            <Button
              color="secondary"
              onClick={onDelete}
              variant="outlined"
              style={styles.formButton}
            >
              Delete
            </Button>
          )}
          <Button
            color="secondary"
            onClick={handleClose}
            variant="contained"
            style={styles.formButton}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={handleSave}
            variant="contained"
            style={styles.formButton}
            disabled={loading}
            startIcon={loading && <CircularProgress size={12} />}
          >
            Save
          </Button>
        </ButtonContainer>
      </Container>
    </>
  );
}

export default connect((state) => ({
  photoSpinner: state.ui.photoLoading,
}))(PhotoModal);
