export const GET_WAREHOUSE = '[warehouse] GET';
export const UPDATE_WAREHOUSE = '[warehouse] UPDATE';
export const CREATE_WAREHOUSE = '[warehouse] CREATE';
export const DELETE_WAREHOUSE = '[warehouse] DELETE';
export const UPDATE_OBJECT_WAREHOUSE = '[warehouse] UPDATE OBJECT WAREHOUSE';
// UI
export const SHOW_WAREHOUSE_MODAL = '[warehouse] SHOW MODAL';
export const HIDE_WAREHOUSE_MODAL = '[warehouse] HIDE MODAL';
// Spinner
export const UPDATE_WAREHOUSE_SUCCESS = '[warehouse] Update success';
export const UPDATE_WAREHOUSE_ERROR = '[warehouse] Update Error';
export const FETCH_WAREHOUSE_SUCCESS = '[warehouse] Fetch success';
export const FETCH_WAREHOUSE_ERROR = '[warehouse] Fetch Error';
export const SHOW_WAREHOUSE_SPINNER = '[warehouse - ui] show spinner';
export const HIDE_WAREHOUSE_SPINNER = '[warehouse - ui] hide spinner';

export const getWarehouse = (id) => ({
  type: GET_WAREHOUSE,
  payload: { _id: id },
});

export const createWarehouse = (data) => ({
  type: CREATE_WAREHOUSE,
  payload: data,
});

export const updateWarehouse = (data) => ({
  type: UPDATE_WAREHOUSE,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_WAREHOUSE_MODAL,
});

export const hideModal = () => ({
  type: HIDE_WAREHOUSE_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_WAREHOUSE_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_WAREHOUSE_SPINNER,
});

export const updateObjectWarehouse = (data) => ({
  type: UPDATE_OBJECT_WAREHOUSE,
  payload: data,
});
