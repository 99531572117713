import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import {
  AccessTime,
  ArrowBack,
} from '@material-ui/icons';
import { getNotifications } from '../../redux/actions/notifications';
import Spinner from '../../components/Spinner/index';
import { Container, RinaDescriptionTag } from './styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  title: {
    fontSize: 20,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  row: {
    padding: 0,
  },
  cell: {
    fontSize: 12,
    padding: '0px 10px 0px 15px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  buttons: {
    color: 'blue',
  },
  checkButton: {
    color: 'green',
  },
  closeButton: {
    color: 'red',
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 0,
  },
}));

const Notifications = ({ dispatch, notifications, notificationsLoading }) => {
  const history = useHistory();
  const classes = useStyles();
  const [notificationsFormated, setNotificationsFormated] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    dispatch(getNotifications({ page: page + 1, limit }));
  }, [page, limit]);

  useEffect(() => {
    if (notifications.docs) {
      setNotificationsFormated(notifications.docs);
      setTotal(notifications.totalDocs);
    }
  }, [notifications]);

  const handleBack = () => {
    history.replace('/equipments');
  };

  const secondsToDays = (time) => {
    return (time / (60 * 60 * 24));
  }

  const handlePostpone = (itemId) => {
    alert('This function will be implemented soon.', itemId);
  }

  const printMovimentations = (movimentations) => {
    let html = '';

    return html;
  }

  return (
    <>
      <Container>
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <Toolbar className={classes.toolBar}>
              <span style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
                <IconButton onClick={handleBack}>
                  <ArrowBack />
                </IconButton>
                <Typography
                  className={classes.title}
                  variant="h6"
                  id="tableTitle"
                  component="span"
                >
                  Notifications
                </Typography>
              </span>
            </Toolbar>
            <TableContainer className={classes.table}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={1}>Next Date to Send</TableCell>
                    <TableCell colSpan={1}>Inventory</TableCell>
                    <TableCell colSpan={1}>Equipments</TableCell>
                    <TableCell colSpan={1}>Users</TableCell>
                    <TableCell colSpan={1}>Period</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {notificationsLoading && (
                    <TableRow className={classes.row}>
                      <TableCell colSpan="6" className={classes.cell}>
                        <Spinner parent="Table" />
                      </TableCell>
                    </TableRow>
                  )}
                  {!notificationsLoading &&
                    notificationsFormated.map((notification) => (
                      <TableRow key={notification._id} className={classes.row}>
                        <TableCell colSpan={1} className={classes.cell}>
                          {format(new Date(notification.date_to_send), 'MM/dd/yyyy HH:mm')}
                        </TableCell>
                        <TableCell colSpan={1} className={classes.cell}>
                          {notification.customer?.name}
                        </TableCell>
                        <TableCell colSpan={1} className={classes.cell}>
                          <div>
                            {notification.movimentations.map((item) => {
                              return <p onClick={() => history.replace(`/equipment/${item.equipment._id}`)} style={{ padding: '6px 0px' }}>
                                <RinaDescriptionTag>
                                  - {item.rinaDescription} ({item.status})
                                </RinaDescriptionTag>
                              </p>
                            })}
                          </div>
                        </TableCell>
                        <TableCell colSpan={1} className={classes.cell}>
                        <div>
                            {notification.recipes.map((item) => {
                              return <p>
                                - {item.user?.name} ({item.user?.email})
                              </p>
                            })}
                          </div>
                        </TableCell>
                        <TableCell colSpan={1} className={classes.cell}>
                          {secondsToDays(notification.default_period_in_seconds)}{' '}days
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <IconButton title="Postpone" onClick={() => handlePostpone(notification._id)}>
                            <AccessTime />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  notificationsLoading: state.ui.notificationsLoading,
  notifications: state.notifications,
});

export default connect(mapStateToProps)(Notifications);
