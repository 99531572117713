import React from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';

function InputInfo({
  title,
  color = 'primary',
  icon = <InfoIcon />,
  CustomComponent,
  ...props
}) {
  return (
    <Tooltip title={title} {...props}>
      {CustomComponent ? (
        CustomComponent
      ) : (
        <IconButton color={color}>{icon}</IconButton>
      )}
    </Tooltip>
  );
}

export default InputInfo;
