import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ModalContainer from '../../../components/ModalContainer';
import InspectionResultsModal from '../../Modals/InspectionResults';
import { useSelector, useDispatch } from 'react-redux';
import {
  showInspectionResultsModal,
  hideInspectionResultsModal,
} from '../../../redux/actions/workOrders';
import { Check } from '@material-ui/icons';
import { AddButton } from './styles';
import RejectionModal from './components/RejectionModal';
import InspectionRow from './components/InspectionRow';
import { Alert } from '../../../components';
import ApprovedTable from './components/ApprovedTable';
import RejectedTable from './components/RejectedTable';
import uploadFile from '../../../services/Inspection/uploadFile';

function Results({ data, onChangeEquipmentService }) {
  const dispatch = useDispatch();
  const [editing, setEditing] = React.useState(null);
  const [rejectionModalOpen, setRejectionModalOpen] = React.useState(false);
  const [rejectionData, setRejectionData] = React.useState(null);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const equipment = useSelector(
    (state) => state.workOrder?.revision?.equipment || []
  );
  const woInspectionResultsModal = useSelector(
    (state) => state.woInspectionResultsModal
  );
  const { workOrderId } = useParams();
  const loggedUser = useSelector((state) => state.loggedUser?._id);

  const services = useSelector((state) =>
    Array.from(
      (state.workOrder?.revision?.contacts || []).reduce(
        (serviceList, contact) => {
          return new Set([
            ...serviceList,
            ...contact.services.map(({ name }) => name),
          ]);
        },
        []
      )
    ).sort((a, b) => a.localeCompare(b))
  );

  const maintenances = useSelector(
    (state) => state.workOrder?.revision?.maintenances || []
  );

  function handleCloseInspectionResultsModal() {
    dispatch(hideInspectionResultsModal());
  }

  const handleOpenRejectionModal = (equipment, service, maintenance) => () => {
    setRejectionModalOpen(true);
    setRejectionData({ equipment, service, maintenance });
  };
  const handleCloseRejectionModal = () => {
    setRejectionModalOpen(false);
    setRejectionData(null);
  };

  const handleChangeEquipmentService = (commonData, status, note, photo) => {
    onChangeEquipmentService({
      equipment: commonData.equipment,
      maintenance: commonData.maintenance,
      service: commonData.service,
      note,
      status,
      photo,
    });
  };

  const handleRejectCall = (note, photo) => {
    handleChangeEquipmentService(rejectionData, 'Rejected', note, photo);
    handleCloseRejectionModal();
  };

  const handleRejection = (note, photo) => () => {
    const data = {
      docType: 'photos',
      workOrderId,
      user: loggedUser,
    };

    if (photo) {
      uploadFile(photo, data).then(({ data: responseData, ok }) => {
        if (!ok) return;
        handleRejectCall(note, responseData);
      });
    } else {
      handleRejectCall(note);
    }
  };

  const handleApproval = (equipment, service, maintenance) => async () => {
    const response = await Alert(
      'Approve this item?',
      `Confirm approval of the service/maintenance "${
        service || maintenance?.maintenance?.name
      }" to equipment "${equipment.individual_item_id}"`
    );
    if (!response?.confirm) return;

    handleChangeEquipmentService(
      { equipment, service, maintenance },
      'Approved'
    );
  };

  const handleBulkApproval = async () => {
    const response = await Alert(
      'Approve these item?',
      `Confirm approval of the selected services/maintenances`
    );
    if (!response?.confirm) return;

    selectedItems.forEach(({ equipment, service, maintenance }) =>
      handleChangeEquipmentService(
        { equipment, service, maintenance },
        'Approved'
      )
    );
    setSelectedItems([]);
  };

  const pendingItemsToRender = equipment.filter((item) => {
    const isThereServicePending = services.some(
      (service) =>
        !data.equipment_service.some(
          ({ service: svc, equipment: eqp }) =>
            svc === service && item._id === eqp._id
        )
    );

    const isThereMaintenancePending = maintenances.some(
      (maintenance) =>
        !data.equipment_service.some(
          ({ maintenance: mnt, equipment: eqp }) =>
            mnt?.maintenance?._id === maintenance.maintenance._id &&
            item._id === eqp._id
        )
    );

    const shouldItRender = isThereServicePending || isThereMaintenancePending;
    return shouldItRender;
  });

  const getSelectionIndex = (
    serviceOrMaintenance,
    equipment,
    list = selectedItems
  ) =>
    list.findIndex(
      ({ maintenance, service, equipment: eqp }) =>
        eqp._id === equipment &&
        (maintenance?.maintenance?._id === serviceOrMaintenance ||
          service === serviceOrMaintenance)
    );

  const isSelected = ({ service, maintenance, equipment }) =>
    getSelectionIndex(
      service || maintenance?.maintenance?._id,
      equipment?._id
    ) > -1;

  const handleSelect =
    ({ service, maintenance, equipment }) =>
    () => {
      return setSelectedItems((currentSelection) => {
        console.log(service || maintenance?.maintenance?._id);
        const selectionIndex = getSelectionIndex(
          service || maintenance?.maintenance?._id,
          equipment?._id,
          currentSelection
        );

        if (selectionIndex === -1)
          return [...currentSelection, { service, maintenance, equipment }];
        const copy = [...currentSelection];
        copy.splice(selectionIndex, 1);
        return copy;
      });
    };

  const handlerSelectedAll = useCallback(() => {
    pendingItemsToRender.forEach((eqp) => {
      services.forEach((service) => {
        handleSelect({ equipment: eqp, service, maintenance: null })();
      });
      maintenances.forEach((maintenance) => {
        handleSelect({ equipment: eqp, maintenance, service: null })();
      });
    });
  }, [pendingItemsToRender, services, maintenances]);

  return (
    <>
      <RejectionModal
        open={rejectionModalOpen}
        onClose={handleCloseRejectionModal}
        equipment={rejectionData?.equipment?.individual_item_id}
        name={
          rejectionData?.service ||
          rejectionData?.maintenance?.maintenance?.name
        }
        onConfirm={handleRejection}
      />
      <AddButton
        variant="contained"
        color="primary"
        size="small"
        onClick={handleBulkApproval}
        disabled={selectedItems.length < 1}
      >
        <Check />
        Approve
      </AddButton>
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={100} align="center">
                Pending Items
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Checkbox
                  onChange={(_, checked) => handlerSelectedAll(checked)}
                />
              </TableCell>
              <TableCell></TableCell>
              <TableCell>Unique ID</TableCell>
              <TableCell>RINA Description</TableCell>
              <TableCell>Customer MM No</TableCell>
              <TableCell>Total Qty. (EA)</TableCell>
              <TableCell>Footage</TableCell>
              <TableCell>COPAS Class</TableCell>
              <TableCell>Supplier MM No</TableCell>
              <TableCell>Supplier Batch No</TableCell>
              <TableCell>Heat/Lot</TableCell>
              <TableCell>RBW</TableCell>
              <TableCell>Facility Reference ID</TableCell>
              <TableCell>Manufacture</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!pendingItemsToRender.length && (
              <TableRow>
                <TableCell colSpan={100}>No Pending Items</TableCell>
              </TableRow>
            )}
            {pendingItemsToRender.map((eqp) => (
              <InspectionRow
                key={eqp._id}
                equipment={eqp}
                onOpenModalRejection={handleOpenRejectionModal}
                onApprove={handleApproval}
                equipmentService={data.equipment_service}
                services={services}
                maintenances={maintenances}
                isSelected={isSelected}
                onSelectChange={handleSelect}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ApprovedTable data={data} />
      <RejectedTable data={data} />
    </>
  );
}

export default Results;
