export const GET_BUILD_OF_MATERIAL = '[build_of_material] GET';
export const UPDATE_BUILD_OF_MATERIAL = '[build_of_material] UPDATE';
export const CREATE_BUILD_OF_MATERIAL = '[build_of_material] CREATE';
export const DELETE_BUILD_OF_MATERIAL = '[build_of_material] DELETE';
export const UPDATE_OBJECT_BUILD_OF_MATERIAL = '[build_of_material] UPDATE OBJECT BUILD OF MATERIAL';
// UI
export const SHOW_BUILD_OF_MATERIAL_MODAL = '[build_of_material] SHOW MODAL';
export const HIDE_BUILD_OF_MATERIAL_MODAL = '[build_of_material] HIDE MODAL';
// Spinner
export const UPDATE_BUILD_OF_MATERIAL_SUCCESS = '[build_of_material] Update success';
export const UPDATE_BUILD_OF_MATERIAL_ERROR = '[build_of_material] Update Error';
export const FETCH_BUILD_OF_MATERIAL_SUCCESS = '[build_of_material] Fetch success';
export const FETCH_BUILD_OF_MATERIAL_ERROR = '[build_of_material] Fetch Error';
export const SHOW_BUILD_OF_MATERIAL_SPINNER = '[build_of_material - ui] show spinner';
export const HIDE_BUILD_OF_MATERIAL_SPINNER = '[build_of_material - ui] hide spinner';

export const getBuildOfMaterial = (id) => ({
  type: GET_BUILD_OF_MATERIAL,
  payload: { _id: id },
});

export const createBuildOfMaterial = (data) => ({
  type: CREATE_BUILD_OF_MATERIAL,
  payload: data,
});

export const updateBuildOfMaterial = (data) => ({
  type: UPDATE_BUILD_OF_MATERIAL,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_BUILD_OF_MATERIAL_MODAL,
});

export const hideModal = () => ({
  type: HIDE_BUILD_OF_MATERIAL_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_BUILD_OF_MATERIAL_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_BUILD_OF_MATERIAL_SPINNER,
});

export const updateObjectBuildOfMaterial = (data) => ({
  type: UPDATE_OBJECT_BUILD_OF_MATERIAL,
  payload: data,
});
