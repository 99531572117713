import React from 'react';
import { Box, Button } from '@material-ui/core';
import { DropZone, ModalContainer } from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { getRoot } from '../../../redux/actions/vault';
import Toastify from '../../../utils/Toastify';
import api from '../../../services/Api';

const UploadSpecification = ({ open, onClose, callback }) => {
  const [file, setFile] = React.useState(null);
  const dispatch = useDispatch();
  const vault = useSelector((state) => state.vault);
  const loggedUser = useSelector((state) => state.loggedUser._id);

  const specLibraryId = React.useMemo(
    () =>
      vault?.directoryContent?.find((item) => item.name === 'Spec Library')
        ?._id,
    [vault]
  );

  React.useEffect(() => {
    dispatch(getRoot());
  }, []);

  const handleSubmit = async () => {
    if (!specLibraryId) {
      return Toastify.addError(
        'Error - if this error persist, please, contact support'
      );
    }

    try {
      const form = new FormData();

      form.append('files', file);
      form.append('creator', loggedUser);

      const response = await api.post(`/vault/${specLibraryId}/upload`, form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data?.ok) {
        onClose();
        callback(response.data.files?.[0]?._id);
      } else {
        Toastify.addError(
          'Error - an error ocurred trying to complete request'
        );
      }
    } catch (e) {
      Toastify.addError('Error - an error ocurred trying to complete request');
    }
  };

  return (
    <ModalContainer open={open} handleClose={onClose}>
      <Box padding={2}>
        <DropZone
          file={file}
          onChangeFile={setFile}
          modal
          allowedExtensions="any"
        />
        <Box
          marginTop={2}
          display="flex"
          flex={1}
          justifyContent="center"
          style={{ gap: 8 }}
        >
          <Button variant="contained" color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Upload
          </Button>
        </Box>
      </Box>
    </ModalContainer>
  );
};

export default UploadSpecification;
