import {
  UPDATE_DIRECTORY_CONTENT,
  UPDATE_ENTRY_INFO,
  UPDATE_DIRECTORY_STRUCTURE,
  UPDATE_DIRECTORY_INFO,
} from '../actions/vault';

const initialState = {
  directoryInfo: {},
  directoryContent: [],
  directoryStructure: {},
  entryInfo: {},
};

function rebuildTree(tree, content) {
  if (String(tree._id) === String(content._id) || !tree._id) return content;
  return {
    ...tree,
    content: tree?.content?.map((child) => rebuildTree(child, content)) || [],
  };
}

export function vaultReducer(vault = initialState, action) {
  switch (action.type) {
    case UPDATE_DIRECTORY_CONTENT:
      return { ...vault, directoryContent: action.payload };
    case UPDATE_ENTRY_INFO:
      return { ...vault, entryInfo: action.payload };
    // TODO update structure
    case UPDATE_DIRECTORY_STRUCTURE: {
      const directoryStructure = rebuildTree(
        vault.directoryStructure,
        action.payload,
      );
      return { ...vault, directoryStructure };
    }
    case UPDATE_DIRECTORY_INFO:
      return { ...vault, directoryInfo: action.payload };
    default:
      return vault;
  }
}
