import React from 'react';
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Icon,
  IconButton,
  Tooltip,
  Checkbox,
} from '@material-ui/core';

function MaintenanceTable({
  equipment,
  onOpenModalRejection,
  onApprove,
  maintenances,
  equipmentService = [],
  isSelected,
  onSelectChange,
}) {
  const equipmentMaintenances = maintenances
    .filter(({ equipment: eqp }) => eqp.includes(equipment._id))
    .map(({ maintenance }) => maintenance);

  const maintenancesToRender = equipmentMaintenances.filter(
    (maintenance) =>
      !equipmentService.some(
        ({ maintenance: mnt, equipment: eqp }) =>
          mnt?.maintenance?._id === maintenance.maintenance._id &&
          equipment._id === eqp._id
      )
  );

  const allSelected = () =>
    maintenancesToRender.length &&
    maintenancesToRender.every((maintenance) =>
      isSelected({ maintenance, equipment })
    );
  const selected = () =>
    maintenancesToRender.filter((maintenance) =>
      isSelected({ maintenance, equipment })
    );

  const handleSelectAll = () => {
    const selectedItems = selected();
    if (!selectedItems.length)
      maintenancesToRender.forEach((maintenance) =>
        onSelectChange({ maintenance, equipment })()
      );
    selectedItems.forEach((maintenance) =>
      onSelectChange({ maintenance, equipment })()
    );
  };

  return (
    <Box>
      <Typography variant="h6">Maintenance</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Checkbox checked={allSelected()} onChange={handleSelectAll} />
            </TableCell>
            <TableCell>Maintenance</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!maintenancesToRender.length && (
            <TableRow>
              <TableCell colSpan={3} align="center">
                No pending maintenance
              </TableCell>
            </TableRow>
          )}
          {maintenancesToRender.map((mnt) => (
            <TableRow key={mnt._id}>
              <TableCell>
                <Checkbox
                  checked={isSelected({ maintenance: mnt, equipment })}
                  onChange={onSelectChange({ maintenance: mnt, equipment })}
                />
              </TableCell>
              <TableCell>{mnt.maintenance.name}</TableCell>
              <TableCell>
                <Tooltip title="Approve">
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={onApprove(equipment, null, mnt)}
                  >
                    <Icon>check</Icon>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Reject">
                  <IconButton
                    size="small"
                    color="secondary"
                    onClick={onOpenModalRejection(equipment, null, mnt)}
                  >
                    <Icon>close</Icon>
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}

export default MaintenanceTable;
