import axios from 'axios';
import { apiUrl, sessionStorageKey } from '../config/consts';

const user = localStorage.getItem(sessionStorageKey);
const token = user ? JSON.parse(user).token : null;

const api = axios.create({
  baseURL: apiUrl,
});

api.defaults.headers.common.Authorization = `Bearer ${token}`;

export default api;
