import {
  FETCH_JOBS_SUCCESS,
  FETCH_JOBS_ERROR,
  GET_JOBS,
  updateJobs,
  showSpinner,
  hideSpinner,
} from '../actions/jobs';
import { apiRequest } from '../actions/api';

const URL = 'jobs';

export const getJobsFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_JOBS) {
    dispatch(
      apiRequest(
        'GET',
        URL,
        action.payload,
        FETCH_JOBS_SUCCESS,
        FETCH_JOBS_ERROR,
        ['customer'],
      ),
    );
    dispatch(showSpinner());
  }
};

export const processJobsCollection = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_JOBS_SUCCESS) {
    dispatch(updateJobs(action.payload));
    dispatch(hideSpinner());
  }
};

export const jobsMdl = [getJobsFlow, processJobsCollection];
