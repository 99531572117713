import {
  FETCH_ORIGINAL_CHARGES_SUCCESS,
  FETCH_ORIGINAL_CHARGES_ERROR,
  GET_ORIGINAL_CHARGES,
  updateOriginalCharge,
  showSpinner,
  hideSpinner,
} from '../actions/originalCharges';
import { apiRequest } from '../actions/api';

const URL = 'original_charges';

export const getOriginalChargesFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_ORIGINAL_CHARGES) {
    dispatch(
      apiRequest(
        'GET',
        URL,
        action.payload,
        FETCH_ORIGINAL_CHARGES_SUCCESS,
        FETCH_ORIGINAL_CHARGES_ERROR,
        ['customer', 'listAll', 'well'],
      ),
    );
    dispatch(showSpinner());
  }
};

export const processOriginalChargesCollection = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_ORIGINAL_CHARGES_SUCCESS) {
    dispatch(updateOriginalCharge(action.payload));
    dispatch(hideSpinner());
  }
};

export const original_chargesMdl = [
  getOriginalChargesFlow,
  processOriginalChargesCollection,
];
