import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Paper,
  TextField,
  FormGroup,
  Button,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
} from '@material-ui/core';
import Alert from '../../../components/Alert/index';
import Spinner from '../../../components/Spinner/index';
import {
  Container,
  InputContent,
  ModalTitle,
  ButtonContainer,
  CheckboxContent,
} from './styles';

import { getRole, createRole, updateRole } from '../../../redux/actions/role';

const styles = {
  formButton: {
    margin: 2,
  },
};

const RoleModal = ({
  id,
  dispatch,
  role,
  roleModal,
  handleClose,
  roleSpinner,
}) => {
  const [active, setActive] = useState(true);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [permissions, setPermissions] = useState({
    users: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    roles: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
  });

  useEffect(() => {
    if (id !== 'new') {
      dispatch(getRole(id));
    }
  }, [id]);

  useEffect(() => {
    if (role && id !== 'new') {
      setActive(role.active);
      setName(role.name);
      setPermissions(role.permissions);
    }
  }, [role]);

  useEffect(() => {
    // Close the modal after update the roles list
    if (!roleModal) {
      handleClose();
    }
  }, [roleModal]);

  const handleChangePermission = (value, path, field) => {
    let clone = JSON.parse(JSON.stringify(permissions));
    clone[path][field] = value;
    setPermissions(clone);
  };

  const handleSave = async () => {
    let err = false;
    setNameError(!name);
    err = !name;

    if (!err) {
      const response = await Alert();
      if (response.confirm) {
        if (id === 'new') {
          dispatch(
            createRole({
              active,
              name,
              permissions,
            })
          );
        } else {
          dispatch(
            updateRole({
              _id: id,
              active,
              name,
              permissions,
            })
          );
        }
      }
    }
  };

  return (
    <>
      {roleSpinner && <Spinner overlay />}
      <Container style={{ opacity: roleSpinner ? 0.5 : 1 }}>
        <ModalTitle>{id === 'new' ? 'New' : 'Edit'} Role</ModalTitle>
        <FormGroup>
          <CheckboxContent>
            <FormControlLabel
              control={
                <Switch
                  checked={active}
                  onChange={(e) => setActive(e.target.checked)}
                />
              }
              label="Active"
              labelPlacement="start"
            />
          </CheckboxContent>
          <InputContent>
            <TextField
              id="outlined-password-input"
              label="Name"
              type="text"
              error={nameError}
              helperText={nameError && 'Name is required'}
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
            />
          </InputContent>
          <InputContent>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Module
                    </TableCell>
                    <TableCell align="center">Create</TableCell>
                    <TableCell align="center">Read</TableCell>
                    <TableCell align="center">Update</TableCell>
                    <TableCell align="center">Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Users
                    </TableCell>
                    <TableCell align="center">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={permissions?.users?.create}
                            onChange={(e) =>
                              handleChangePermission(
                                e.target.checked,
                                'users',
                                'create'
                              )
                            }
                          />
                        }
                      />
                    </TableCell>
                    <TableCell align="center">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={permissions?.users?.read}
                            onChange={(e) =>
                              handleChangePermission(
                                e.target.checked,
                                'users',
                                'read'
                              )
                            }
                          />
                        }
                      />
                    </TableCell>
                    <TableCell align="center">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={permissions?.users?.update}
                            onChange={(e) =>
                              handleChangePermission(
                                e.target.checked,
                                'users',
                                'update'
                              )
                            }
                          />
                        }
                      />
                    </TableCell>
                    <TableCell align="center">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={permissions?.users?.delete}
                            onChange={(e) =>
                              handleChangePermission(
                                e.target.checked,
                                'users',
                                'delete'
                              )
                            }
                          />
                        }
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Roles
                    </TableCell>
                    <TableCell align="center">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={permissions?.roles?.create}
                            onChange={(e) =>
                              handleChangePermission(
                                e.target.checked,
                                'roles',
                                'create'
                              )
                            }
                          />
                        }
                      />
                    </TableCell>
                    <TableCell align="center">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={permissions?.roles?.read}
                            onChange={(e) =>
                              handleChangePermission(
                                e.target.checked,
                                'roles',
                                'read'
                              )
                            }
                          />
                        }
                      />
                    </TableCell>
                    <TableCell align="center">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={permissions?.roles?.update}
                            onChange={(e) =>
                              handleChangePermission(
                                e.target.checked,
                                'roles',
                                'update'
                              )
                            }
                          />
                        }
                      />
                    </TableCell>
                    <TableCell align="center">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={permissions?.roles?.delete}
                            onChange={(e) =>
                              handleChangePermission(
                                e.target.checked,
                                'roles',
                                'delete'
                              )
                            }
                          />
                        }
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </InputContent>
          <ButtonContainer>
            <Button
              color="secondary"
              onClick={handleClose}
              variant="contained"
              style={styles.formButton}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={handleSave}
              variant="contained"
              style={styles.formButton}
            >
              Save
            </Button>
          </ButtonContainer>
        </FormGroup>
      </Container>
    </>
  );
};

export default connect((state) => ({
  role: state.role,
  roleModal: state.ui.roleModal,
  roleSpinner: state.ui.roleLoading,
}))(RoleModal);
