import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button,
  Icon,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  TableFooter,
} from '@material-ui/core';
import { getCycleCounts } from '../../redux/actions/cycleCounts';
import Spinner from '../../components/Spinner';
import Row from './components/Row';
import { Container, Paper, Head, Title, Body } from './styles';

function CycleCounting() {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const dispatch = useDispatch();
  const cycleCounts = useSelector((state) => state.cycleCounts?.docs || []);
  const total = useSelector((state) => state.cycleCounts?.totalDocs || 0);
  const loading = useSelector((state) => state.ui.cycleCountsLoading);

  useEffect(() => {
    dispatch(getCycleCounts({ page, limit }));
  }, [page, limit]);

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(1);
  };

  return (
    <Container>
      <Paper>
        <Head>
          <Title>Cycle Counting</Title>
          <Link
            component={Button}
            to="/cycle_counting/generate"
            color="primary"
            startIcon={<Icon>add</Icon>}
          >
            Generate Cycle Counting
          </Link>
        </Head>
        <Body>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Counting Number</TableCell>
                <TableCell>Bin Location</TableCell>
                <TableCell>Generate Date</TableCell>
                <TableCell>Perform Date</TableCell>
                <TableCell>Reconcile Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cycleCounts.length && !loading ? (
                cycleCounts.map((count) => <Row key={count._id} data={count} />)
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    {loading ? (
                      <Spinner parent="Table" />
                    ) : (
                      'No Cycle Counts found'
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={7}
                  count={total}
                  page={page - 1}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  onChangeRowsPerPage={handleChangeLimit}
                  onChangePage={handleChangePage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </Body>
      </Paper>
    </Container>
  );
}

export default CycleCounting;
