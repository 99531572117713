import React from 'react';
import { Grid, Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import { Entry } from './styles';

function GridView({ children, showSidebar }) {
  const theme = useTheme();

  function renderChild(child) {
    return (
      <Entry
        item
        xs={6}
        sm={6}
        md={showSidebar ? 4 : 3}
        lg={showSidebar ? 3 : 2}
        xl={showSidebar ? 2 : 1}
        theme={theme}
      >
        {child}
      </Entry>
    );
  }

  return (
    <Grid container component={Box} padding="10px">
      {React.Children.map(children, renderChild)}
    </Grid>
  );
}

export default GridView;
