import {
  GET_SLOC,
  UPDATE_SLOC,
  UPDATE_OBJECT_SLOC,
  CREATE_SLOC,
  DELETE_SLOC,
} from '../actions/sloc';

export function slocReducer(sloc = {}, action) {
  switch (action.type) {
    case GET_SLOC:
      return action.payload;
    case UPDATE_SLOC:
      return action.payload;
    case CREATE_SLOC:
      return action.payload;
    case DELETE_SLOC:
      return action.payload;
    case UPDATE_OBJECT_SLOC:
      return action.payload;

    default:
      return sloc;
  }
}
