import React from 'react';
import { useSelector } from 'react-redux';
import { TextField, MenuItem } from '@material-ui/core';
import { InputContainer } from '../../styles';

function MaterialMasterField({
  name = 'materialMaster',
  label = 'Customer MM No',
  onChange,
  value,
  disabled,
}) {
  const materialMasters = useSelector(
    (state) => state.customerMMs?.docs || state.customerMMs || []
  );

  return (
    <InputContainer>
      <TextField
        onChange={onChange}
        size="small"
        name={name}
        label={label}
        variant="outlined"
        value={value}
        fullWidth
        select
        disabled={disabled}
      >
        {materialMasters?.map(({ _id, mm_number }) => (
          <MenuItem value={_id}>{mm_number}</MenuItem>
        ))}
      </TextField>
    </InputContainer>
  );
}

export default MaterialMasterField;
