import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import { ArrowBack } from '@material-ui/icons';
import { getBuildOfMaterial } from '../../../redux/actions/movimentation';
import { getProjects } from '../../../redux/actions/projects';
import { getOriginalCharge } from '../../../redux/actions/originalCharges';
import Spinner from '../../../components/Spinner';
import { CUSTOMER_KEY } from '../../../config/consts';
import { tableStyles } from '../../../styles/tableStyles';
import Grid from '@material-ui/core/Grid';
import { Container, ButtonContainer, InputContainer } from './styles';
import { Button } from '@material-ui/core';
import {
  createBuildOfMaterial,
  updateBuildOfMaterial,
  getBuildOfMaterial as getBuildOfMaterialEvent,
} from '../../../redux/actions/buildOfMaterial';
import CollapsableRow from '../../../components/CollapsableRow';
import AddChildren from '../AddChildren';
import { downloadReport } from '../../../services/Download';
import TagsField from '../../../components/TagsField';
import EventDocumentsSection from '../../../components/EventDocumentsSection';
import handlePost from '../../../utils/Event/handlePost';

const BuildOfMaterial = ({
  dispatch,
  buildOfMaterial,
  parents,
  movimentationLoading,
  loggedUser,
}) => {
  const { customer, id } = useParams();
  const history = useHistory();
  const { state } = useLocation();
  const classes = tableStyles();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(50);
  const [total, setTotal] = useState(0);
  const [addChildrenPage, setAddChildrenPage] = useState(false);
  const [data, setData] = React.useState({
    movimentations: [],
    parents: [],
    newAdded: [],
    tags: [],
    files: [],
    innerFacilityReferenceID: {},
    newFiles: [],
  });
  const [idToAdd, setIdToAdd] = useState(null);

  const {
    movimentations,
    newAdded,
    tags,
    files,
    facilityReferenceID,
    newFiles,
    fileNotes,
  } = data;

  React.useEffect(() => {
    if (!id || id === 'new') return;
    dispatch(getBuildOfMaterialEvent(id));
  }, [id]);

  React.useEffect(() => {
    if (id === 'new' || !buildOfMaterial) return;
    setData({
      ...buildOfMaterial,
      movimentations: buildOfMaterial.individualData?.map(
        ({ movimentation }) => movimentation._id || movimentation
      ),
    });
    dispatch(getBuildOfMaterial(buildOfMaterial.parents));
  }, [buildOfMaterial]);

  React.useEffect(() => {
    if (state?.movimentations) {
      setData((currentData) => ({
        ...currentData,
        movimentations: state.movimentations,
      }));
    }
  }, [state]);

  React.useEffect(() => {
    if (!state || !state.parents) return;
    setData((currentData) => ({ ...currentData, parents: state.parents }));
    dispatch(getBuildOfMaterial(state.parents));
  }, [state]);

  useEffect(() => {
    if (!localStorage.getItem(CUSTOMER_KEY))
      history.replace('/equipments', { origin: 'mm_number' });
  }, []);

  useEffect(() => {
    dispatch(getProjects({ page: 1, limit: 999, filter: { customer } }));
  }, []);

  useEffect(() => {
    dispatch(getOriginalCharge({ page: 1, limit: 999, filter: { customer } }));
  }, []);

  const handleBack = () => {
    history.goBack();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  function handleConcatNewAdded(toAdd) {
    setData((currentData) => ({
      ...currentData,
      newAdded: [...currentData.newAdded, ...toAdd],
    }));
  }

  function handleReload() {
    dispatch(getBuildOfMaterial(state.parents));
  }

  function handleOpenModal(id) {
    setIdToAdd(id);
    setAddChildrenPage(true);
  }

  function handleDownload() {
    downloadReport(id);
  }
  function handleEdit(e) {
    const { name, value } = e.target;
    setData((currentData) => ({ ...currentData, [name]: value }));
  }

  function handleInnerEdit(id) {
    return function (e) {
      const { value } = e.target;
      setData((currentData) => {
        const innerFacilityReferenceIDCopy = {
          ...currentData.innerFacilityReferenceID,
        };
        innerFacilityReferenceIDCopy[id] = value;
        return {
          ...currentData,
          innerFacilityReferenceID: innerFacilityReferenceIDCopy,
        };
      });
    };
  }

  function handleFiles({ files, notes }) {
    setData((currentData) => ({
      ...currentData,
      newFiles: files,
      fileNotes: notes,
    }));
  }

  const disabled = id !== 'new';

  return (
    <>
      {addChildrenPage ? (
        <Paper style={{ margin: '3rem', paddingBottom: '1rem' }}>
          <AddChildren
            id={idToAdd}
            handleBack={() => {
              handleReload();
              setAddChildrenPage(false);
            }}
            handleNewAdded={handleConcatNewAdded}
          />
        </Paper>
      ) : (
        <Container>
          <div className={classes.root} style={{ overflow: 'visible' }}>
            <Paper className={classes.paper} style={{ minHeight: '90%' }}>
              <Toolbar className={classes.toolBar}>
                <span>
                  <IconButton onClick={handleBack}>
                    <ArrowBack />
                  </IconButton>
                  <Typography
                    className={classes.title}
                    variant="h6"
                    id="tableTitle"
                    component="span"
                  >
                    {id === 'new' ? 'New' : 'Edit'} Bill of Material
                  </Typography>
                </span>
              </Toolbar>
              <TableContainer className={classes.table}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell>RAMP Unique ID</TableCell>
                      <TableCell>RINA Description</TableCell>
                      <TableCell>RINA MM No</TableCell>
                      <TableCell>Total Qty. EA</TableCell>
                      <TableCell>Total Tally (FT)</TableCell>
                      <TableCell>COPAS Class</TableCell>
                      <TableCell style={{ backgroundColor: '#bbdefb' }}>
                        Facility Reference ID
                      </TableCell>
                      <TableCell>Supplier MM No</TableCell>
                      <TableCell>Supplier Batch No</TableCell>
                      <TableCell>Heat/Lot</TableCell>
                      <TableCell>Run/Jt No</TableCell>
                      <TableCell>RBW</TableCell>
                      <TableCell>Assigned Project</TableCell>
                      <TableCell>Assigned Well</TableCell>
                      <TableCell>Assigned Charge Code</TableCell>
                      <TableCell>RAMP Tag No</TableCell>
                      <TableCell>RINA Work Order No</TableCell>
                      <TableCell>SLOC</TableCell>
                      <TableCell>Material Layout</TableCell>
                      <TableCell>Associated RAMP Tag</TableCell>
                      <TableCell>Allocated</TableCell>
                      <TableCell>Remove From Parent</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {movimentationLoading ? (
                      <TableRow>
                        <TableCell colSpan={26}>
                          <Spinner parent="Table" />
                        </TableCell>
                      </TableRow>
                    ) : (
                      parents.map((parent) => (
                        <CollapsableRow
                          key={parent._id}
                          movimentation={parent}
                          root
                          fromCart={movimentations}
                          newAdded={newAdded}
                          openModal={handleOpenModal}
                          onReload={handleReload}
                          onChange={handleInnerEdit}
                          innerFacilityReferenceID={
                            data.innerFacilityReferenceID
                          }
                        />
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <Paper className={classes.paper} style={{ overflow: 'visible' }}>
              <Grid container style={{ padding: '16px 8px 8px 10px' }}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <InputContainer>
                    <TextField
                      value={facilityReferenceID}
                      name="facilityReferenceID"
                      onChange={handleEdit}
                      label="Facility Reference ID"
                      variant="outlined"
                      fullWidth
                    />
                  </InputContainer>
                </Grid>
              </Grid>
            </Paper>
            <Paper className={classes.paper}>
              <EventDocumentsSection
                files={files}
                id={id}
                newFiles={newFiles}
                fileNotes={fileNotes}
                onChangeFiles={handleFiles}
              />
              <Grid container style={{ padding: '0px 10px 10px 10px' }}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <InputContainer>
                    <TagsField
                      value={tags}
                      name="tags"
                      onChange={handleEdit}
                      label="Tags"
                      disabled={disabled}
                    />
                  </InputContainer>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <ButtonContainer>
                    <Button
                      color="primary"
                      variant={id === 'new' ? 'contained' : 'outlined'}
                      style={{ marginRight: 5 }}
                      onClick={handlePost(
                        id,
                        data,
                        loggedUser,
                        createBuildOfMaterial,
                        updateBuildOfMaterial,
                        dispatch
                      )}
                    >
                      {id === 'new' ? 'Post' : 'Update'} Transaction
                    </Button>
                    <Button
                      disabled={id === 'new'}
                      color="primary"
                      variant="contained"
                      style={{ marginRight: 5 }}
                      onClick={handleDownload}
                    >
                      Create B.O.M. Report
                    </Button>
                  </ButtonContainer>
                </Grid>
              </Grid>
            </Paper>
          </div>
        </Container>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  projects: state.projects,
  originalCharges: state.originalCharges,
  buildOfMaterial: state.buildOfMaterial,
  parents: state.BOMParents,
  movimentationLoading: state.ui.movimentationLoading,
  loggedUser: state.loggedUser,
});

export default connect(mapStateToProps)(BuildOfMaterial);
