import { UPDATE_MAINTENANCE_CATEGORIES } from '../actions/maintenanceCategories';

const initState = {
  docs: [],
  page: 1,
  limit: 10,
  totalDocs: 0,
};

export function maintenanceCategoriesReducer(
  maintenance_categories = initState,
  action,
) {
  switch (action.type) {
    case UPDATE_MAINTENANCE_CATEGORIES:
      return action.payload;

    default:
      return maintenance_categories;
  }
}
