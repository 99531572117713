import styled from 'styled-components';

export const Thumbnail = styled.div`
  width: 100px;
  height: 100px;
  background-color: #e3e3e3;
  border: 1px solid #a0a0a0;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  & > img {
    max-width: 100%;
    max-height: 100%;
  }
`;
