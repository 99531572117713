import { apiRequest } from '../actions/api';
import {
  FETCH_SERVICE_CATEGORIES_SUCCESS,
  FETCH_SERVICE_CATEGORIES_ERROR,
  GET_SERVICE_CATEGORIES,
  updateServiceCategories,
  showSpinner,
  hideSpinner,
} from '../actions/serviceCategories';

const URL = 'service_categories';

export const getServiceCategoriesFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_SERVICE_CATEGORIES) {
    dispatch(
      apiRequest(
        'GET',
        URL,
        action.payload,
        FETCH_SERVICE_CATEGORIES_SUCCESS,
        FETCH_SERVICE_CATEGORIES_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const processServiceCategoriesCollection = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_SERVICE_CATEGORIES_SUCCESS) {
    dispatch(updateServiceCategories(action.payload));
    dispatch(hideSpinner());
  }
};

export const serviceCategoriesMdl = [
  getServiceCategoriesFlow, processServiceCategoriesCollection,
];
