export const GET_CHECK_OUT = '[check-out] GET';
export const UPDATE_CHECK_OUT = '[check-out] UPDATE';
export const CREATE_CHECK_OUT = '[check-out] CREATE';
export const DELETE_CHECK_OUT = '[check-out] DELETE';
export const UPDATE_OBJECT_CHECK_OUT = '[check-out] UPDATE OBJECT CHECK_OUT';
// UI
export const SHOW_CHECK_OUT_MODAL = '[check-out] SHOW MODAL';
export const HIDE_CHECK_OUT_MODAL = '[check-out] HIDE MODAL';
// Spinner
export const UPDATE_CHECK_OUT_SUCCESS = '[check-out] Update success';
export const UPDATE_CHECK_OUT_ERROR = '[check-out] Update Error';
export const FETCH_CHECK_OUT_SUCCESS = '[check-out] Fetch success';
export const FETCH_CHECK_OUT_ERROR = '[check-out] Fetch Error';
export const SHOW_CHECK_OUT_SPINNER = '[check-out - ui] show spinner';
export const HIDE_CHECK_OUT_SPINNER = '[check-out - ui] hide spinner';

export const getCheckOut = (id) => ({
  type: GET_CHECK_OUT,
  payload: { _id: id },
});

export const createCheckOut = (data) => ({
  type: CREATE_CHECK_OUT,
  payload: data,
});

export const updateCheckOut = (data) => ({
  type: UPDATE_CHECK_OUT,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_CHECK_OUT_MODAL,
});

export const hideModal = () => ({
  type: HIDE_CHECK_OUT_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_CHECK_OUT_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_CHECK_OUT_SPINNER,
});

export const updateObjectCheckOut = (data) => ({
  type: UPDATE_OBJECT_CHECK_OUT,
  payload: data,
});
