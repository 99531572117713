import styled from 'styled-components';
import { darken } from 'polished';

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 20px rgba(0,0,0, 0.6);
  margin: 0 16px;
  border-radius: 8px 8px 0 0;
`;

export const CardImage = styled.img`
  display: block;
  margin: 0 auto;
  height: 200px;
  width: 60%;
  object-fit: contain;
  padding: 8px 16px;
  border-radius: 8px 8px 0 0;
`;

export const CardButton = styled.button`
  width: 100%;
  background: ${(props) => (props.color ? props.color : '#00A6CA')};
  color: #fff;
  padding: 1.2em;
  margin: 0;
  border: 0;
  outline: 0;
  text-transform: uppercase;
  transition: all 0.4s ease-in-out;
  border-radius: 0 0 8px 8px;
  cursor: pointer;

  &:hover {
    background: ${(props) => (props.color ? darken(0.05, props.color) : '#00A6CA')};
  }
`;

export const ExternalLink = styled.a`
  text-decoration: none;
`;
