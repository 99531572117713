import { UPDATE_OBJECT_FABRICATION } from '../actions/fabrication';

export function fabricationReducer(
  fabrication = { individualData: [] },
  action,
) {
  switch (action.type) {
    case UPDATE_OBJECT_FABRICATION:
      return action.payload;

    default:
      return fabrication;
  }
}
