import React from 'react';
import { connect } from 'react-redux';

import { GridView, VaultEntry, ListView } from '../';
import { Container } from './styles';

function FilesContainer({
  dispatch,
  listView,
  directoryContent = [],
  showSidebar,
  onChangeSelection,
  selectedEntries = [],
}) {
  const View = { true: ListView, false: GridView };
  const ViewComponent = View[(listView || false).toString()];

  function handleToggleSelection(entryId) {
    onChangeSelection((currentSelection = []) => {
      const index = currentSelection.indexOf(entryId);
      if (index < 0) return [...currentSelection, entryId];
      const copy = [...currentSelection];
      copy.splice(index, 1);
      return copy;
    });
  }

  return (
    <Container allowOverflowX={listView}>
      <ViewComponent showSidebar={showSidebar}>
        {directoryContent?.map((entry) => (
          <VaultEntry
            onToggleSelection={handleToggleSelection}
            selected={selectedEntries.includes(entry._id)}
            key={entry._id}
            entry={entry}
          />
        ))}
      </ViewComponent>
    </Container>
  );
}

function mapStateToProps(state) {
  return { directoryContent: state.vault.directoryContent };
}

export default connect(mapStateToProps)(FilesContainer);
