import {
  GET_MRP_NOTIFICATION_SETUP,
  UPDATE_MRP_NOTIFICATION_SETUP,
  UPDATE_OBJECT_MRP_NOTIFICATION_SETUP,
} from '../actions/MRPNotificationSetup';

export function mrpNotificationSetupReducer(mrpNotificationSetup = {}, action) {
  switch (action.type) {
    case GET_MRP_NOTIFICATION_SETUP:
      return action.payload;
    case UPDATE_MRP_NOTIFICATION_SETUP:
      return action.payload;
    case UPDATE_OBJECT_MRP_NOTIFICATION_SETUP:
      return action.payload;

    default:
      return mrpNotificationSetup;
  }
}
