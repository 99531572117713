import React from 'react';
import {
  Button,
  InputLabel,
  TablePagination,
  Box,
  Divider as MaterialDivider,
  TextField,
} from '@material-ui/core';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  & .MuiPaper-root {
    border-radius: 8px;
    width: 100%;
  }
`;
export const ModalContainer = styled.div`
  min-width: 60vw;
  padding: 20px;
  max-width: 90vw;
  overflow: auto;
  max-height: 95vh;
`;
export const ModalTitle = styled.div`
  padding-left: 15px;
  padding-bottom: 20px;
  font-size: 1.3rem;
`;
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  border-radius: 5px;
  background-color: white;
  width: 95%;
  margin: 1rem 0;
  .headerContainer {
    margin-bottom: 16px;
  }
`;

export const Title = styled.div`
  font-size: 1.3rem;
  padding-left: 10px;
`;

export const InputContent = styled.form`
  width: 100%;
  padding: 7px 2rem 10px 2rem;
`;

export const ButtonContainer = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

export const TabSheet = styled.div`
  .MuiTabs-flexContainer {
    background-color: white;
    width: 100%;
    height: 100%;
  }
  .MuiTab-textColorInherit {
    color: #2c2c32;
  }
  .Mui-selected {
    color: #000;
  }
  .MuiTabs-indicator {
    background-color: #000;
  }
`;

export const TabContainer = styled.div`
  display: flex;
`;

export const AddButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 32px;
`;

export const StyledTablePagination = styled(TablePagination)`
  float: left;
  margin-top: 3rem;
  margin-left: 1rem;
`;

export function AddButton(props) {
  return (
    <AddButtonContainer>
      <Button {...props} />
    </AddButtonContainer>
  );
}

export const StyledLabel = styled(InputLabel)`
  margin: 2rem;
  color: #000;
`;

export const CollapsableBox = styled(Box)`
  transition: max-height 500ms ease-in-out;
  max-height: 500vh;
  transform-origin: top left;
  overflow: hidden;
  ${({ closed }) => closed
    && css`
      max-height: 0px;
    `}
`;

export const Divider = styled(MaterialDivider)`
  margin-bottom: 1rem;
`;

export const Thumbnail = styled.div`
  width: 100px;
  height: 100px;
  background-color: #e3e3e3;
  border: 1px solid #a0a0a0;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  & > img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const StatusSelector = styled(TextField).attrs({
  select: true,
  variant: 'outlined',
  size: 'small',
  fullWidth: true,
  InputLabelProps: { shrink: false },
})`
  /* width: 180px;
  height: 35px;
  border-radius: 5px;
  font-size: 13px;
  border: none; */
  background-color: ${(props) => (props.status === 'approved' && '#dcedc8')
    || (props.status === 'disapproved' && '#ffcdd2')
    || '#e3e3e3'};
`;
