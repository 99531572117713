import styled from 'styled-components';

export const Container = styled.div`
  min-width: 60vw;
  padding: 20px;
`;

export const ModalTitle = styled.div`
  padding-left: 15px;
  padding-bottom: 20px;
  font-size: 1.3rem;
`;

export const InputContent = styled.form`
  width: 100%;
  padding: 20px 10px 10px 10px;
`;

export const CheckboxContent = styled.form`
  width: 100%;
  padding: 12px 10px 15px 0px;
`;

export const ButtonContainer = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

export const ImageContainer = styled.div`
  width: 400px;
  height: 400px;
  max-height: 90vw;
  max-width: 90vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  & > img {
    max-width: 100%;
    max-height: 100%;
  }
`;
