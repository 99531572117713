import { SET_FILTER_LAYOUT } from '../actions/movimentationsFilter';
import { FILTER_LAYOUT_KEY } from '../../config/consts';
import filterData from '../../utils/StaticData/EquipmentInputs/filter_data';

const storedState = JSON.parse(localStorage.getItem(FILTER_LAYOUT_KEY)) || filterData;

export function filterLayoutReducer(filterLayout = storedState, action) {
  switch (action.type) {
    case SET_FILTER_LAYOUT:
      const layoutString = JSON.stringify(action.payload);
      localStorage.setItem(FILTER_LAYOUT_KEY, layoutString);
      return action.payload;

    default:
      return filterLayout;
  }
}
