import React from 'react';
import { useSelector } from 'react-redux';
import { TextField, MenuItem } from '@material-ui/core';
import { InputContainer } from '../../styles';

function SLOCField({
  name = 'SLOC',
  label = 'Storage Location',
  onChange,
  value,
  disabled,
}) {
  const SLOCs = useSelector((state) => state.slocs?.docs || state.slocs || []);

  return (
    <InputContainer>
      <TextField
        onChange={onChange}
        size="small"
        name={name}
        label={label}
        variant="outlined"
        value={value}
        fullWidth
        select
        disabled={disabled}
      >
        {SLOCs?.map(({ _id, sloc_id }) => (
          <MenuItem value={_id}>{sloc_id}</MenuItem>
        ))}
      </TextField>
    </InputContainer>
  );
}

export default SLOCField;
