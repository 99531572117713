import React from 'react';
import UnderConstruction from '../../components/UnderConstruction';

import { Container } from './styles';

function VarianceReport() {
  return (
    <UnderConstruction />
  );
}

export default VarianceReport;
