import { GET_WAREHOUSES, UPDATE_WAREHOUSES } from '../actions/warehouses';

const initState = {
  docs: [],
  page: 1,
  limit: 10,
  totalDocs: 0,
};

export function warehousesReducer(warehouses = initState, action) {
  switch (action.type) {
    case UPDATE_WAREHOUSES:
      return action.payload;
    case GET_WAREHOUSES:
    default:
      return warehouses;
  }
}
