/* eslint-disable no-case-declarations */
import {
  GET_LOGGED_USER,
  UPDATE_LOGGED_USER,
  LOGIN,
  LOGOUT,
} from '../actions/loggedUser';
import { sessionStorageKey } from '../../config/consts';

const sessionStorageUser = JSON.parse(
  localStorage.getItem(sessionStorageKey),
);
const initLoggedUser = () => ({
  _id: sessionStorageUser ? sessionStorageUser._id : '',
  name: sessionStorageUser ? sessionStorageUser.name : '',
  email: sessionStorageUser ? sessionStorageUser.email : '',
  roles: sessionStorageUser ? sessionStorageUser.roles : '',
  token: sessionStorageUser ? sessionStorageUser.token : '',
  authenticated: sessionStorageUser ? sessionStorageUser.authenticated : false,
  codeRequired: false,
});

export function loggedUserReducer(user = initLoggedUser(), action) {
  switch (action.type) {
    case GET_LOGGED_USER:
      return action.payload;
    case UPDATE_LOGGED_USER:
      localStorage.setItem(sessionStorageKey, JSON.stringify(action.payload));
      return action.payload;
    case LOGOUT:
      localStorage.removeItem(sessionStorageKey);
      window.location.reload();
      return initLoggedUser();
    case LOGIN:
      return action.payload;

    default:
      return user;
  }
}
