export const GET_ALLOCATE = '[allocate] GET';
export const UPDATE_ALLOCATE = '[allocate] UPDATE';
export const CREATE_ALLOCATE = '[allocate] CREATE';
export const DELETE_ALLOCATE = '[allocate] DELETE';
export const UPDATE_OBJECT_ALLOCATE = '[allocate] UPDATE OBJECT ALLOCATE';
// UI
export const SHOW_ALLOCATE_MODAL = '[allocate] SHOW MODAL';
export const HIDE_ALLOCATE_MODAL = '[allocate] HIDE MODAL';
// Spinner
export const UPDATE_ALLOCATE_SUCCESS = '[allocate] Update success';
export const UPDATE_ALLOCATE_ERROR = '[allocate] Update Error';
export const FETCH_ALLOCATE_SUCCESS = '[allocate] Fetch success';
export const FETCH_ALLOCATE_ERROR = '[allocate] Fetch Error';
export const SHOW_ALLOCATE_SPINNER = '[allocate - ui] show spinner';
export const HIDE_ALLOCATE_SPINNER = '[allocate - ui] hide spinner';

export const getAllocate = (id) => ({
  type: GET_ALLOCATE,
  payload: { _id: id },
});

export const createAllocate = (data) => ({
  type: CREATE_ALLOCATE,
  payload: data,
});

export const updateAllocate = (data) => ({
  type: UPDATE_ALLOCATE,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_ALLOCATE_MODAL,
});

export const hideModal = () => ({
  type: HIDE_ALLOCATE_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_ALLOCATE_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_ALLOCATE_SPINNER,
});

export const updateObjectAllocate = (data) => ({
  type: UPDATE_OBJECT_ALLOCATE,
  payload: data,
});
