import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import { getSLOCS } from '../../../redux/actions/slocs';
import { ArrowBack } from '@material-ui/icons';
import SweetAlert from 'sweetalert2';
import { CUSTOMER_KEY } from '../../../config/consts';
import { tableStyles } from '../../../styles/tableStyles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { InputContainer, Container, ButtonContainer } from './styles';
import EventDocumentsSection from '../../../components/EventDocumentsSection';
import { Button } from '@material-ui/core';
import { createCheckIn, getCheckIn } from '../../../redux/actions/checkIn';
import getRINADescription from '../../../utils/Equipment/getRINADescription';
import { downloadReport } from '../../../services/Download';
import TagsField from '../../../components/TagsField';

const swalWithBootstrapButtons = SweetAlert.mixin({
  customClass: {
    confirmButton: 'btn btn-success',
    denyButton: 'btn btn-warpning',
    cancelButton: 'btn btn-danger',
  },
  buttonsStyling: true,
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  title: {
    fontSize: 20,
  },
  paper: {
    width: '100%',
    marginBottom: 16,
  },
  row: {
    padding: 0,
  },
  cell: {
    fontSize: 12,
    padding: '0px 10px 0px 15px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  buttons: {
    color: 'blue',
  },
  checkButton: {
    color: 'green',
  },
  closeButton: {
    color: 'red',
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  styledSpan: {
    alignSelf: 'center',
    fontWeight: '700',
  },
}));

const CheckIn = ({ dispatch, SLOCs, checkIn }) => {
  const { customer, id } = useParams();
  const history = useHistory();
  const { state } = useLocation();
  const classes = tableStyles();
  const localClasses = useStyles();
  const [data, setData] = React.useState({
    individualData: [],
    SLOC: '',
    SLOC_POC: '',
    POCEmail: '',
    note: '',
    files: [],
  });

  const {
    individualData,
    conditionClassification,
    SLOC,
    SLOC_POC,
    POCEmail,
    note,
    files,
  } = data;

  React.useEffect(() => {
    if (state?.movimentations) {
      setData((currentData) => ({
        ...currentData,
        individualData: state.movimentations.map((movimentation) => ({
          movimentation,
          tags: [],
        })),
      }));
    }
  }, [state]);

  React.useEffect(() => {
    if (!id || id === 'new') return;
    dispatch(getCheckIn(id));
  }, [id]);

  React.useEffect(() => {
    if (id === 'new' || !checkIn) return;
    setData(checkIn);
  }, [checkIn]);

  useEffect(() => {
    if (!localStorage.getItem(CUSTOMER_KEY))
      history.replace('/equipments', { origin: 'mm_number' });
  }, []);

  useEffect(() => {
    dispatch(getSLOCS({ page: 1, limit: 99999, filter: { customer } }));
  }, []);

  const handleBack = () => {
    history.goBack();
  };

  function handleEdit(e) {
    const { name, value } = e.target;
    setData((currentData) => ({ ...currentData, [name]: value }));
  }

  function handleSave() {
    if (
      individualData.some(({ movimentation }) => !movimentation.in_inventory)
    ) {
      swalWithBootstrapButtons
        .fire({
          title: 'Return items to inventory?',
          text: 'The items highlighted in red are currently out of inventory, do you want to return it?',
          icon: 'warning',
          showCancelButton: true,
          showDenyButton: true,
          confirmButtonText: 'Yes, return it.',
          denyButtonText: 'No, keep it out.',
          cancelButtonText: 'Cancel.',
          reverseButtons: true,
          width: 600,
        })
        .then((result) => {
          if (result.isConfirmed) {
            const returnedEquipmentArray = individualData.filter(
              ({ movimentation }) => !movimentation.in_inventory
            );
            const returnEqpDescArray = returnedEquipmentArray.map((item) =>
              getRINADescription(item.movimentation.customer_MM)
            );
            const listOfEqpFormatted = returnEqpDescArray.join('\n');
            const note = `The following items were returned to the inventory:\n${listOfEqpFormatted}`;
            dispatch(
              createCheckIn({
                ...data,
                returnToInventory: true,
                note,
              })
            );
          }
          if (result.isDenied) {
            dispatch(
              createCheckIn({
                ...data,
                returnToInventory: false,
              })
            );
          }
        });
    } else {
      dispatch(
        createCheckIn({
          ...data,
          individualData: individualData.map((curData) => ({
            ...curData,
            movimentation: curData.movimentation._id || curData.movimentation,
          })),
        })
      );
    }
  }

  function handleDownload() {
    downloadReport(id).then(() => dispatch(getCheckIn(id)));
  }

  function handleEditOnTable(index) {
    function handler(e) {
      const { name, value } = e.target;
      setData((currentData) => {
        const newIndividualData = [...currentData.individualData];
        newIndividualData[index] = {
          ...newIndividualData[index],
          [name]: value,
        };
        return { ...currentData, individualData: newIndividualData };
      });
    }
    return handler;
  }

  return (
    <>
      <Container>
        <div className={classes.root} style={{ overflow: 'visible' }}>
          <Paper className={classes.paper} style={{ minHeight: '75%' }}>
            <Toolbar className={classes.toolBar}>
              <span>
                <IconButton onClick={handleBack}>
                  <ArrowBack />
                </IconButton>
                <Typography
                  className={classes.title}
                  variant="h6"
                  id="tableTitle"
                  component="span"
                >
                  Check-In
                </Typography>
              </span>
            </Toolbar>
            <TableContainer className={classes.table}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Equipment Name</TableCell>
                    <TableCell>RAMP Unique ID</TableCell>
                    <TableCell>Customer MM No</TableCell>
                    <TableCell>Total Qty. EA</TableCell>
                    <TableCell>Footage</TableCell>
                    <TableCell
                      style={{
                        backgroundColor: '#9370db66',
                        textAlign: 'center',
                      }}
                    >
                      Tags
                    </TableCell>
                    <TableCell>Supplier Batch No</TableCell>
                    <TableCell>COPAS Class</TableCell>
                    <TableCell>Heat/Lot</TableCell>
                    <TableCell>RBW</TableCell>
                    <TableCell>Facility Reference ID</TableCell>
                    <TableCell>Assigned Project</TableCell>
                    <TableCell>Assigned Well</TableCell>
                    <TableCell>Assigned Charge Code</TableCell>
                    <TableCell>RAMP Tag No</TableCell>
                    <TableCell>RINA Work Order No</TableCell>
                    <TableCell>SLOC</TableCell>
                    <TableCell>Material Layout</TableCell>
                    <TableCell>Allocated</TableCell>
                    <TableCell>Supplier</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {individualData?.map(({ movimentation, tags }, index) => (
                    <TableRow
                      style={
                        !movimentation.in_inventory
                          ? {
                              backgroundColor: '#f19699',
                            }
                          : {}
                      }
                    >
                      <TableCell>
                        {movimentation?.customer_MM?.short_description}
                      </TableCell>
                      <TableCell>{movimentation?.individual_item_id}</TableCell>
                      <TableCell>
                        {movimentation?.customer_MM?.mm_number}
                      </TableCell>
                      <TableCell>{movimentation.qty}</TableCell>
                      <TableCell>{movimentation.footage}</TableCell>
                      <TableCell
                        style={{ backgroundColor: '#9370db33', minWidth: 200 }}
                      >
                        <InputContainer>
                          <TagsField
                            value={tags}
                            name="tags"
                            onChange={handleEditOnTable(index)}
                          />
                        </InputContainer>
                      </TableCell>
                      <TableCell>{movimentation?.batch_no}</TableCell>
                      <TableCell>{movimentation.classification}</TableCell>
                      <TableCell>{movimentation.heat}</TableCell>
                      <TableCell>{movimentation.RBW}</TableCell>
                      <TableCell>
                        {movimentation.facility_reference_id}
                      </TableCell>
                      <TableCell>
                        {
                          movimentation?.assigned_customer_charge_code?.project
                            ?.name
                        }
                      </TableCell>
                      <TableCell>
                        {
                          movimentation?.assigned_customer_charge_code?.well
                            ?.name
                        }
                      </TableCell>
                      <TableCell>
                        {
                          movimentation?.assigned_customer_charge_code
                            ?.original_charge_code
                        }
                      </TableCell>
                      <TableCell>{movimentation.tag_number}</TableCell>
                      <TableCell>{movimentation.rina_work_order_no}</TableCell>
                      <TableCell>{movimentation?.SLOC?.sloc_name}</TableCell>
                      <TableCell>{movimentation.material_layout}</TableCell>
                      <TableCell>
                        {movimentation.allocated_release_number ||
                        movimentation.allocated_batch_number ||
                        movimentation.allocated_work_order_number ? (
                          <span>YES</span>
                        ) : (
                          <span>NO</span>
                        )}
                      </TableCell>
                      <TableCell>{movimentation.supplier}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Paper className={localClasses.paper}>
            <Toolbar className={classes.toolBar}>
              <span>
                <Typography
                  className={classes.title}
                  variant="h6"
                  id="tableTitle"
                  component="span"
                  style={{ marginLeft: 15 }}
                >
                  Logistic Details
                </Typography>
              </span>
            </Toolbar>
            <Grid container style={{ padding: '0px 10px 10px 10px' }}>
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <InputContainer>
                  <TextField
                    onChange={handleEdit}
                    size="small"
                    name="SLOC"
                    label="Returning SLOC"
                    type="text"
                    variant="outlined"
                    value={SLOC?._id || SLOC || ''}
                    fullWidth
                    select
                  >
                    {SLOCs?.docs?.map(({ sloc_id, _id }) => (
                      <MenuItem value={_id}>{sloc_id}</MenuItem>
                    ))}
                  </TextField>
                </InputContainer>
              </Grid>
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <InputContainer>
                  <TextField
                    onChange={handleEdit}
                    size="small"
                    name="SLOC_POC"
                    label="Returning SLOC POC"
                    type="text"
                    variant="outlined"
                    value={SLOC_POC}
                    fullWidth
                  />
                </InputContainer>
              </Grid>
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <InputContainer>
                  <TextField
                    onChange={handleEdit}
                    size="small"
                    name="POCEmail"
                    label="POC E-mail"
                    type="text"
                    variant="outlined"
                    value={POCEmail}
                    fullWidth
                  />
                </InputContainer>
              </Grid>
            </Grid>
          </Paper>
          <Paper className={localClasses.paper}>
            <Toolbar className={classes.toolBar}>
              <span>
                <Typography
                  className={classes.title}
                  variant="h6"
                  id="tableTitle"
                  component="span"
                  style={{ marginLeft: 15 }}
                >
                  Condition Assestment
                </Typography>
              </span>
            </Toolbar>
            <Grid container style={{ padding: '0px 10px 10px 10px' }}>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <InputContainer>
                  <TextField
                    onChange={handleEdit}
                    size="small"
                    name="conditionClassification"
                    label="COPAS Class"
                    type="text"
                    variant="outlined"
                    value={conditionClassification || ''}
                    fullWidth
                  />
                </InputContainer>
              </Grid>
            </Grid>
          </Paper>
          <Paper className={classes.paper} style={{ minHeight: 600 }}>
            <EventDocumentsSection files={files} />
            <Grid container style={{ padding: '0px 10px 10px 10px' }}>
              {id !== 'new' && (
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <InputContainer>
                    <TextField
                      disabled
                      onChange={handleEdit}
                      size="small"
                      label="Notes"
                      multiline
                      type="text"
                      name="note"
                      variant="outlined"
                      value={note}
                      fullWidth
                    />
                  </InputContainer>
                </Grid>
              )}
              <Grid lg={12} md={12} sm={12} xs={12}>
                <ButtonContainer>
                  <Button
                    color="primary"
                    variant="contained"
                    style={{ marginRight: 5 }}
                    onClick={handleSave}
                    disabled={id !== 'new'}
                  >
                    Post Transaction
                  </Button>
                  <Button
                    disabled={id === 'new'}
                    color="primary"
                    variant="contained"
                    style={{ marginRight: 5 }}
                    onClick={handleDownload}
                  >
                    Create Return ELP Report
                  </Button>
                </ButtonContainer>
              </Grid>
            </Grid>
          </Paper>
        </div>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  SLOCs: state.slocs,
  checkIn: state.checkIn,
});

export default connect(mapStateToProps)(CheckIn);
