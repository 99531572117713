import { GET_WELLS, UPDATE_WELLS } from '../actions/wells';

const initState = {
  docs: [],
  page: 1,
  limit: 10,
  totalDocs: 0,
};

export function wellsReducer(wells = initState, action) {
  switch (action.type) {
    case UPDATE_WELLS:
      return action.payload;
    case GET_WELLS:
    default:
      return wells;
  }
}
