import {
  GET_REPORT_FIELD,
  UPDATE_REPORT_FIELD,
  UPDATE_OBJECT_REPORT_FIELD,
  CREATE_REPORT_FIELD,
  DELETE_REPORT_FIELD,
} from '../actions/reportField';

export function reportFieldReducer(reportField = {}, action) {
  switch (action.type) {
    case GET_REPORT_FIELD:
      return action.payload;
    case UPDATE_REPORT_FIELD:
      return action.payload;
    case CREATE_REPORT_FIELD:
      return action.payload;
    case DELETE_REPORT_FIELD:
      return action.payload;
    case UPDATE_OBJECT_REPORT_FIELD:
      return action.payload;

    default:
      return reportField;
  }
}
