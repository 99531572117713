import React, { useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { useHistory, useParams, Link, useLocation } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Collapse from '@material-ui/core/Collapse';
import { Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import toast from '../../utils/Toastify';
import {
  AddShoppingCart,
  BarChart,
  History,
  Edit,
  Check,
  SubdirectoryArrowRight,
} from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';
import axios from 'axios';
import Spinner from '../Spinner/index';
import { ButtonsContainer, Title } from './styles';
import { tableStyles } from '../../styles/tableStyles';
import { apiUrl, CUSTOMER_KEY } from '../../config/consts';
import getColorFromClass from '../../utils/Equipment/getColorFromClass';
import Alert from '../Alert';
import getRINADescription from '../../utils/Equipment/getRINADescription';

const BuildOfMaterialRow = (props) => {
  const { rr, styles, handleAddToCart, inventoryCart } = props;
  const { state } = useLocation();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [formattedChildren, setFormattedChildren] = useState([]);
  const [formattedParent, setFormattedParent] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [childrenHasMore, setChildrenHasMore] = useState('');
  const classes = tableStyles();
  const history = useHistory();
  const store = useStore();

  async function getMovimentation(id) {
    setLoading(true);

    const response = await axios.get(`${apiUrl}movimentations/${id}`);

    if (response.status === 200) {
      setFormattedChildren(
        filterChildrenByFirstTenElements(response.data.children)
      );
      setFormattedParent(response.data.parent);
      setLoading(false);
      setLoaded(true);
    }
  }

  const handleSelect = (id) => async () => {
    setOpen(open);
    const response = await Alert(
      undefined,
      'Do you want to set this equipment as the parent?',
      undefined,
      undefined,
      'Yes'
    );
    if (response && response.confirm) {
      axios
        .put(`${apiUrl}build-of-material/change-parent`, {
          movimentations: state.movimentations.map((mov) => mov._id || mov),
          parent: id,
          inventory: localStorage.getItem(CUSTOMER_KEY),
          user: store.getState().loggedUser._id,
        })
        .then(() => {
          toast.addSuccess('Equipment added to hierarchy successfully.');
          history.replace('/build_of_material/new', {
            parents: [id],
            movimentations: state.movimentations?.map((mov) =>
              mov._id ? mov._id : mov
            ),
          });
        })
        .catch((error) => {
          toast.addError(
            error?.response?.data?.error ||
              error?.response?.data?.message ||
              error?.message
          );
        });
    }
  };

  function filterChildrenByFirstTenElements(array) {
    if (array && array.length > 5) {
      const filteredArray = array.filter((element, index) => index < 5);
      const remainingItems = array.length - 5;
      setChildrenHasMore(
        `There ${remainingItems > 1 ? 'are' : 'is'} ${remainingItems} more ${
          remainingItems > 1 ? 'items' : 'item'
        }...`
      );
      return filteredArray;
    }
    return array;
  }

  function handleClick() {
    if (!open && !loaded) {
      getMovimentation(rr._id);
    }
    setOpen(!open);
  }

  let backgroundColor = undefined;
  if (inventoryCart.findIndex((item) => item._id === rr._id) > -1)
    backgroundColor = '#eee';
  else if (open) backgroundColor = '#ddd';
  function getChildColor(child) {
    let color = undefined;
    const classificationColor = getColorFromClass(child.classification);
    if (!classificationColor) {
      if (inventoryCart.findIndex((item) => item._id === child._id) > -1)
        color = '#eee';
    } else color = classificationColor;
    return color;
  }

  return (
    <>
      <TableRow
        className={classes.row}
        style={{
          backgroundColor,
          cursor: 'pointer',
        }}
      >
        <TableCell component="th" scope="row">
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleSelect(rr._id)}
          >
            Select
          </Button>
        </TableCell>
        <TableCell className={classes.cell} onClick={handleClick}>
          {rr.individual_item_id}
        </TableCell>
        <TableCell
          className={classes.cell}
          style={{
            color: rr.status === 'Available' ? 'green' : 'black',
          }}
          component="th"
          scope="row"
          onClick={handleClick}
        >
          <b>{rr.status}</b>
        </TableCell>
        <TableCell className={classes.cell} onClick={handleClick}>
          {rr.material_layout}
        </TableCell>
        <TableCell colSpan={3} className={classes.cell} onClick={handleClick}>
          <b>{getRINADescription(rr.customer_MM)}</b>
        </TableCell>
        <TableCell className={classes.cell} onClick={handleClick}>
          {rr.parent ? rr.parent?.individual_item_id : '--'}
        </TableCell>
        <TableCell className={classes.cell} onClick={handleClick}>
          {rr.children.length}
        </TableCell>
        <TableCell className={classes.cell} onClick={handleClick}>
          {rr.customer_MM?.rina_mm_number}
        </TableCell>
        <TableCell className={classes.cell} onClick={handleClick}>
          {!rr.count || rr.count === 1 ? rr.qty : rr.count}
        </TableCell>
        <TableCell className={classes.cell} onClick={handleClick}>
          {rr.footage}
        </TableCell>
        <TableCell className={classes.cell} onClick={handleClick}>
          {rr.batch_no}
        </TableCell>
        <TableCell className={classes.cell} onClick={handleClick}>
          {rr.SLOC?.sloc_name}
        </TableCell>
        <TableCell className={classes.cell} onClick={handleClick}>
          {rr.assigned_customer_charge_code &&
            rr.assigned_customer_charge_code.well &&
            rr.assigned_customer_charge_code.well.project &&
            rr.assigned_customer_charge_code.well.project.name}
        </TableCell>
        <TableCell className={classes.cell} onClick={handleClick}>
          {rr.assigned_customer_charge_code &&
            rr.assigned_customer_charge_code.original_charge_code}
        </TableCell>
        <TableCell className={classes.cell} onClick={handleClick}>
          {rr.assigned_customer_charge_code &&
            rr.assigned_customer_charge_code.well &&
            rr.assigned_customer_charge_code.well.project && (
              <Check color="primary" />
            )}
        </TableCell>
        <TableCell className={classes.cell} onClick={handleClick}>
          {rr.allocated_batch_number}
        </TableCell>
        <TableCell className={classes.cell} onClick={handleClick}>
          {rr.allocated_work_order_number}
        </TableCell>
      </TableRow>
      <TableRow style={{ backgroundColor: '#fefefe' }}>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            border: 'none',
            paddingLeft: '27px',
            backgroundColor: '#FCFEFE',
          }}
          colSpan="20"
        >
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            style={{
              borderLeft: '2px solid rgba(224, 224, 224, 1)',
              borderBottom: '2px solid rgba(224, 224, 224, 1)',
              paddingLeft: '8px',
              position: 'relative',
            }}
          >
            <SubdirectoryArrowRight
              style={{
                marginTop: '17px',
                marginLeft: '-14px',
                position: 'absolute',
                color: 'rgba(224, 224, 224, 0.8)',
              }}
            />
            <Grid item lg={12} md={12} sm={12} xs={12} align="left">
              <Title style={{ marginTop: '1rem' }}>Mother Data</Title>
            </Grid>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    {!formattedParent && (
                      <>
                        <TableCell />
                        <TableCell />
                        <TableCell />
                      </>
                    )}
                    <TableCell>RINA Unique ID</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Material Layout</TableCell>
                    <TableCell colSpan={3}>RINA Description</TableCell>
                    <TableCell>Resides in</TableCell>
                    <TableCell>Children No.</TableCell>
                    <TableCell>RINA MM No.</TableCell>
                    <TableCell>Total Qty. (EA)</TableCell>
                    <TableCell>Footage</TableCell>
                    <TableCell>Batch No.</TableCell>
                    <TableCell>SLOC</TableCell>
                    <TableCell>Assigned Project</TableCell>
                    <TableCell>Assigned Charge Code</TableCell>
                    <TableCell>Allocated</TableCell>
                    <TableCell>Allocated Batch No.</TableCell>
                    <TableCell>Allocated WO No.</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading && (
                    <TableRow className={classes.row}>
                      <TableCell colSpan="20" className={classes.cell}>
                        <Spinner parent="Table" />
                      </TableCell>
                    </TableRow>
                  )}
                  {!loading && rr && formattedParent && (
                    <TableRow
                      className={classes.row}
                      style={{
                        backgroundColor:
                          inventoryCart.findIndex(
                            (item) => item._id === formattedParent._id
                          ) > -1
                            ? '#eee'
                            : undefined,
                      }}
                    >
                      <TableCell className={classes.cell}>
                        <ButtonsContainer>
                          <Tooltip
                            title="History"
                            arrow
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <IconButton
                              size="small"
                              style={{
                                ...styles.editButton,
                                backgroundColor: '#878787',
                                color: 'white',
                              }}
                            >
                              <History fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            title="Add to Cart"
                            arrow
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <IconButton
                              size="small"
                              onClick={handleAddToCart([formattedParent._id])}
                              style={{
                                ...styles.editButton,
                                backgroundColor: 'cornflowerblue',
                                color: 'white',
                              }}
                            >
                              <AddShoppingCart fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            title="Edit Data"
                            arrow
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <IconButton
                              size="small"
                              style={{
                                ...styles.editButton,
                                backgroundColor: 'mediumPurple',
                                color: 'white',
                              }}
                              onClick={() =>
                                history.push(
                                  `/equipment/${formattedParent._id}`
                                )
                              }
                            >
                              <Edit fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </ButtonsContainer>
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {formattedParent.individual_item_id}
                      </TableCell>
                      <TableCell
                        className={classes.cell}
                        style={{
                          color:
                            formattedParent.status === 'Available'
                              ? 'green'
                              : 'black',
                        }}
                        component="th"
                        scope="row"
                      >
                        <b>{formattedParent.status}</b>
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {formattedParent.material_layout}
                      </TableCell>
                      <TableCell colSpan={3} className={classes.cell}>
                        <b>{getRINADescription(formattedParent.customer_MM)}</b>
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {formattedParent.parent
                          ? formattedParent.parent.material_layout
                          : '---'}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {formattedParent.children.length}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {formattedParent.customer_MM?.rina_mm_number}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {formattedParent.qty}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {formattedParent.footage}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {formattedParent.batch_no}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {formattedParent.SLOC?.sloc_name}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {formattedParent.assigned_customer_charge_code &&
                          formattedParent.assigned_customer_charge_code
                            .project &&
                          formattedParent.assigned_customer_charge_code.project
                            .name}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {formattedParent.assigned_customer_charge_code &&
                          formattedParent.assigned_customer_charge_code
                            .original_charge_code}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {formattedParent.assigned_customer_charge_code &&
                          formattedParent.assigned_customer_charge_code
                            .project && <Check color="primary" />}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {formattedParent.allocated_batch_number}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {formattedParent.allocated_work_order_number}
                      </TableCell>
                    </TableRow>
                  )}
                  {!loading && !formattedParent && (
                    <TableRow className={classes.row}>
                      <TableCell colSpan="20" style={{ fontSize: '1rem' }}>
                        <div style={{ width: '100%', textAlign: 'center' }}>
                          This item does not have parent
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <SubdirectoryArrowRight
              style={{
                marginTop: '17px',
                marginLeft: '-14px',
                position: 'absolute',
                color: 'rgba(224, 224, 224, 0.8)',
              }}
            />
            <Grid item lg={12} md={12} sm={12} xs={12} align="left">
              <Title style={{ marginTop: '1rem' }}>Children Data</Title>
            </Grid>
            <TableContainer style={{ marginBottom: '2rem' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    {formattedChildren.length === 0 && (
                      <>
                        <TableCell />
                        <TableCell />
                        <TableCell />
                      </>
                    )}
                    <TableCell>RINA Unique ID</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Material Layout</TableCell>
                    <TableCell colSpan={3}>RINA Description</TableCell>
                    <TableCell>Children No.</TableCell>
                    <TableCell>RINA MM No.</TableCell>
                    <TableCell>Total Qty. (EA)</TableCell>
                    <TableCell>Footage</TableCell>
                    <TableCell>Batch No.</TableCell>
                    <TableCell>COPAS Class</TableCell>
                    <TableCell>SLOC</TableCell>
                    <TableCell>Assigned Project</TableCell>
                    <TableCell>Assigned Charge Code</TableCell>
                    <TableCell>Allocated</TableCell>
                    <TableCell>Allocated Batch No.</TableCell>
                    <TableCell>Allocated WO No.</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading && (
                    <TableRow className={classes.row}>
                      <TableCell colSpan="20" className={classes.cell}>
                        <Spinner parent="Table" />
                      </TableCell>
                    </TableRow>
                  )}
                  {!loading &&
                    formattedChildren.map((children) => (
                      <TableRow
                        className={classes.row}
                        style={{
                          backgroundColor: getChildColor(children),
                        }}
                      >
                        <TableCell className={classes.cell}>
                          <ButtonsContainer>
                            <Tooltip
                              title="History"
                              arrow
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <IconButton
                                size="small"
                                style={{
                                  ...styles.editButton,
                                  backgroundColor: '#878787',
                                  color: 'white',
                                }}
                              >
                                <History fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              title="Add to Cart"
                              arrow
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <IconButton
                                size="small"
                                onClick={handleAddToCart([children._id])}
                                style={{
                                  ...styles.editButton,
                                  backgroundColor: 'cornflowerblue',
                                  color: 'white',
                                }}
                              >
                                <AddShoppingCart fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              title="Edit Data"
                              arrow
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <IconButton
                                size="small"
                                style={{
                                  ...styles.editButton,
                                  backgroundColor: 'mediumPurple',
                                  color: 'white',
                                }}
                                onClick={() =>
                                  history.push(`/equipment/${children._id}`)
                                }
                              >
                                <Edit fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </ButtonsContainer>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {children.individual_item_id}
                        </TableCell>
                        <TableCell
                          className={classes.cell}
                          style={{
                            color:
                              children.status === 'Available'
                                ? 'green'
                                : 'black',
                          }}
                          component="th"
                          scope="row"
                        >
                          <b>{children.status}</b>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {children.material_layout}
                        </TableCell>
                        <TableCell colSpan={3} className={classes.cell}>
                          <b>{getRINADescription(children.customer_MM)}</b>
                        </TableCell>
                        <TableCell
                          className={classes.cell}
                          onClick={handleClick}
                        >
                          {children.children.length}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {children.customer_MM?.rina_mm_number}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {children.qty}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {children.footage}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {children.batch_no}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {children.classification}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {children.SLOC?.sloc_name}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {children.assigned_customer_charge_code &&
                            children.assigned_customer_charge_code.well &&
                            children.assigned_customer_charge_code.well
                              .project &&
                            children.assigned_customer_charge_code.well.project
                              .name}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {children.assigned_customer_charge_code &&
                            children.assigned_customer_charge_code
                              .original_charge_code}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {children.assigned_customer_charge_code &&
                            children.assigned_customer_charge_code.project && (
                              <Check color="primary" />
                            )}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {children.allocated_batch_number}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {children.allocated_work_order_number}
                        </TableCell>
                      </TableRow>
                    ))}
                  {!loading && formattedChildren.length === 0 && (
                    <TableRow className={classes.row}>
                      <TableCell colSpan="24" style={{ fontSize: '1rem' }}>
                        <div style={{ width: '100%', textAlign: 'center' }}>
                          This item does not have children
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                  {!loading && childrenHasMore !== '' && (
                    <TableRow className={classes.row}>
                      <TableCell
                        colSpan="20"
                        style={{
                          fontSize: '1rem',
                          borderBottom: 'none',
                          paddingBottom: 0,
                        }}
                      >
                        <div style={{ width: '100%', textAlign: 'center' }}>
                          {childrenHasMore}
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {rr.count > 1 && rr.count !== rr.qty ? (
              <>
                <SubdirectoryArrowRight
                  style={{
                    marginTop: '17px',
                    marginLeft: '-14px',
                    position: 'absolute',
                    color: 'rgba(224, 224, 224, 0.8)',
                  }}
                />
                <Grid item lg={12} md={12} sm={12} xs={12} align="left">
                  <Title style={{ marginTop: '3rem' }}>
                    {rr.material_layout !== 'BATCH'
                      ? 'Individual Items'
                      : 'Batches'}
                  </Title>
                </Grid>
                <TableContainer style={{ marginBottom: '2rem' }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        {rr.individual_items?.length === 0 && (
                          <>
                            <TableCell />
                            <TableCell />
                            <TableCell />
                          </>
                        )}
                        <TableCell>RINA Unique ID</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Material Layout</TableCell>
                        <TableCell colSpan={3}>RINA Description</TableCell>
                        <TableCell>Resides in</TableCell>
                        <TableCell>Children No.</TableCell>
                        <TableCell>RINA MM No.</TableCell>
                        <TableCell>Total Qty. (EA)</TableCell>
                        <TableCell>Footage</TableCell>
                        <TableCell>Batch No.</TableCell>
                        <TableCell>COPAS Class</TableCell>
                        <TableCell>SLOC</TableCell>
                        <TableCell>Assigned Project</TableCell>
                        <TableCell>Assigned Charge Code</TableCell>
                        <TableCell>Allocated</TableCell>
                        <TableCell>Allocated Batch No.</TableCell>
                        <TableCell>Allocated WO No.</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {loading && (
                        <TableRow className={classes.row}>
                          <TableCell colSpan="20" className={classes.cell}>
                            <Spinner parent="Table" />
                          </TableCell>
                        </TableRow>
                      )}
                      {!loading &&
                        rr.individual_items.map((item) => (
                          <TableRow
                            className={classes.row}
                            style={{
                              backgroundColor: getChildColor(item),
                            }}
                          >
                            <TableCell className={classes.cell}>
                              <ButtonsContainer>
                                <Tooltip
                                  title="History"
                                  arrow
                                  classes={{ tooltip: classes.tooltip }}
                                >
                                  <IconButton
                                    size="small"
                                    style={{
                                      ...styles.editButton,
                                      backgroundColor: '#878787',
                                      color: 'white',
                                    }}
                                  >
                                    <History fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip
                                  title="Add to Cart"
                                  arrow
                                  classes={{ tooltip: classes.tooltip }}
                                >
                                  <IconButton
                                    size="small"
                                    onClick={handleAddToCart([item._id])}
                                    style={{
                                      ...styles.editButton,
                                      backgroundColor: 'cornflowerblue',
                                      color: 'white',
                                    }}
                                  >
                                    <AddShoppingCart fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip
                                  title="Edit Data"
                                  arrow
                                  classes={{ tooltip: classes.tooltip }}
                                >
                                  <IconButton
                                    size="small"
                                    style={{
                                      ...styles.editButton,
                                      backgroundColor: 'mediumPurple',
                                      color: 'white',
                                    }}
                                    onClick={() =>
                                      history.push(`/equipment/${item._id}`)
                                    }
                                  >
                                    <Edit fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </ButtonsContainer>
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {item.individual_item_id}
                            </TableCell>
                            <TableCell
                              className={classes.cell}
                              style={{
                                color:
                                  item.status === 'Available'
                                    ? 'green'
                                    : 'black',
                              }}
                              component="th"
                              scope="row"
                            >
                              <b>{item.status}</b>
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {item.material_layout}
                            </TableCell>
                            <TableCell colSpan={3} className={classes.cell}>
                              <b>{getRINADescription(item.customer_MM)}</b>
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {item.parent
                                ? item.parent.material_layout
                                : '---'}
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {item.children.length}
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {item.customer_MM?.rina_mm_number}
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {item.qty}
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {item.footage}
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {item.batch_no}
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {item.classification}
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {item.SLOC?.sloc_name}
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {item.assigned_customer_charge_code &&
                                item.assigned_customer_charge_code.well &&
                                item.assigned_customer_charge_code.well
                                  .project &&
                                item.assigned_customer_charge_code.well.project
                                  .name}
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {item.assigned_customer_charge_code &&
                                item.assigned_customer_charge_code
                                  .original_charge_code}
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {item.assigned_customer_charge_code &&
                                item.assigned_customer_charge_code.project && (
                                  <Check color="primary" />
                                )}
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {item.allocated_batch_number}
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {item.allocated_work_order_number}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ) : (
              <div style={{ marginBottom: '2rem' }}></div>
            )}
            {!loading && (
              <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                <Button
                  onClick={() => history.push(`/breakoutdata/${rr._id}`)}
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ marginBottom: '2rem' }}
                >
                  Full Breakout Data
                </Button>
              </Grid>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default BuildOfMaterialRow;
