import React from 'react';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckIcon from '@material-ui/icons/Check';
import { InputContainer } from '../../styles';
import useDebounce from '../../../../../hooks/useDebounce';
import api from '../../../../../services/Api';

function ConsumptionField({
  name,
  label,
  onChange,
  error: emptyError,
  disabled,
  value,
  defaultValue,
}) {
  const [innerValue, setInnerValue] = React.useState('');
  const debouncedInnerValue = useDebounce(innerValue, 800);
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (!defaultValue?._id) return;
    onChange({ target: { name, value: defaultValue._id } });
    setInnerValue(defaultValue.individual_item_id);
  }, [defaultValue]);

  React.useEffect(() => {
    if (!debouncedInnerValue) return;
    setLoading(true);
    api
      .get(`/movimentations/unique_id/${debouncedInnerValue}`)
      .then(({ data }) => {
        onChange({ target: { name, value: data._id } });
        setError(false);
      })
      .catch((e) => {
        console.log('🚀 ~ file: index.jsx ~ line 22 ~ api.get ~ e', e);
        onChange({ target: { name, value: '' } });
        setError(true);
      })
      .finally(() => setLoading(false));
  }, [debouncedInnerValue]);

  function handleChange(e) {
    const { value } = e.target;
    setInnerValue(value);
  }

  return (
    <InputContainer>
      <TextField
        onChange={handleChange}
        size="small"
        label={label}
        type="text"
        variant="outlined"
        value={innerValue}
        fullWidth
        disabled={disabled}
        error={error || emptyError}
        helperText={(error || emptyError) && 'Please, insert a valid ID'}
        InputProps={{
          endAdornment:
            (loading && <CircularProgress size="1rem" color="primary" />) ||
            (value && <CheckIcon color="primary" />),
        }}
        required
      />
    </InputContainer>
  );
}

export default ConsumptionField;
