import { CUSTOMER_KEY } from '../../config/consts';

export const GET_RECONCILE = '[reconcile] GET';
export const UPDATE_RECONCILE = '[reconcile] UPDATE';
export const CREATE_RECONCILE = '[reconcile] CREATE';
export const CREATE_RECONCILE_FROM_COUNTING = '[reconcile] CREATE FROM COUNTING';
export const DELETE_RECONCILE = '[reconcile] DELETE';
export const UPDATE_OBJECT_RECONCILE = '[reconcile] UPDATE OBJECT RECONCILE';
// UI
export const SHOW_RECONCILE_MAINTENANCE_MODAL = '[reconcile] SHOW MAINTENANCE MODAL';
export const HIDE_RECONCILE_MAINTENANCE_MODAL = '[reconcile] HIDE MAINTENANCE MODAL';
// Spinner
export const UPDATE_RECONCILE_SUCCESS = '[reconcile] Update success';
export const UPDATE_RECONCILE_FROM_COUNTING_SUCCESS = '[reconcile] Update from counting success';
export const UPDATE_RECONCILE_ERROR = '[reconcile] Update Error';
export const FETCH_RECONCILE_SUCCESS = '[reconcile] Fetch success';
export const FETCH_RECONCILE_ERROR = '[reconcile] Fetch Error';
export const SHOW_RECONCILE_SPINNER = '[reconcile - ui] show spinner';
export const HIDE_RECONCILE_SPINNER = '[reconcile - ui] hide spinner';
export const SHOW_RECONCILE_MODAL = '[reconcile - ui] show modal';
export const HIDE_RECONCILE_MODAL = '[reconcile - ui] hide modal';

export const getReconcile = (id) => ({
  type: GET_RECONCILE,
  payload: { _id: id },
});

export const createReconcile = (data) => ({
  type: CREATE_RECONCILE,
  payload: data,
});

export const createReconcileFromCounting = (data) => ({
  type: CREATE_RECONCILE_FROM_COUNTING,
  payload: data,
});

export const updateReconcile = (data) => ({
  type: UPDATE_RECONCILE,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_RECONCILE_MAINTENANCE_MODAL,
});

export const hideModal = () => ({
  type: HIDE_RECONCILE_MAINTENANCE_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_RECONCILE_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_RECONCILE_SPINNER,
});
export const showReconcileModal = () => ({
  type: SHOW_RECONCILE_MODAL,
});

export const hideReconcileModal = () => ({
  type: HIDE_RECONCILE_MODAL,
});

export const updateObjectReconcile = (data) => ({
  type: UPDATE_OBJECT_RECONCILE,
  payload: data,
});
