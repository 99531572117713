import {
  UPDATE_BUILD_OF_MATERIAL_SUCCESS,
  UPDATE_BUILD_OF_MATERIAL_ERROR,
  FETCH_BUILD_OF_MATERIAL_SUCCESS,
  FETCH_BUILD_OF_MATERIAL_ERROR,
  GET_BUILD_OF_MATERIAL,
  CREATE_BUILD_OF_MATERIAL,
  UPDATE_BUILD_OF_MATERIAL,
  updateObjectBuildOfMaterial,
  showSpinner,
  hideSpinner,
  hideModal,
} from '../actions/buildOfMaterial';
import Toastify from '../../utils/Toastify/index';
import history from '../../services/history';
import { apiRequest } from '../actions/api';
import { CUSTOMER_KEY } from '../../config/consts';

const URL = 'build-of-material';

export const createBuildOfMaterialFlow = ({ dispatch, getState }) => (next) => (action) => {
  next(action);
  if (action.type === CREATE_BUILD_OF_MATERIAL) {
    action.payload.append('inventory', localStorage.getItem(CUSTOMER_KEY));
    action.payload.append('user', getState().loggedUser._id);
    dispatch(
      apiRequest(
        'POST',
        URL,
        action.payload,
        UPDATE_BUILD_OF_MATERIAL_SUCCESS,
        UPDATE_BUILD_OF_MATERIAL_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const updateBuildOfMaterialFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === UPDATE_BUILD_OF_MATERIAL) {
    dispatch(
      apiRequest(
        'PUT',
        URL,
        action.payload,
        UPDATE_BUILD_OF_MATERIAL_SUCCESS,
        UPDATE_BUILD_OF_MATERIAL_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const getBuildOfMaterialFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_BUILD_OF_MATERIAL) {
    const { _id } = action.payload;
    dispatch(
      apiRequest(
        'GET-id',
        URL,
        { _id },
        FETCH_BUILD_OF_MATERIAL_SUCCESS,
        FETCH_BUILD_OF_MATERIAL_ERROR,
      ),
    );
    dispatch(showSpinner());
  }
};

export const processBuildOfMaterialCollection = ({ dispatch, getState }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_BUILD_OF_MATERIAL_SUCCESS) {
    dispatch(updateObjectBuildOfMaterial(action.payload));
    dispatch(hideSpinner());
  } else if (action.type === UPDATE_BUILD_OF_MATERIAL_SUCCESS) {
    Toastify.addSuccess('Build Of Material posted successfully!');
    dispatch(updateObjectBuildOfMaterial(action.payload));
    dispatch(hideModal());
    dispatch(hideSpinner());
    history.replace(`/build_of_material/${action.payload._id}`);
  } else if (action.type === UPDATE_BUILD_OF_MATERIAL_ERROR) {
    dispatch(hideSpinner());
    Toastify.addError(action.payload || 'Error to save Bill of Material');
  }
};

export const buildOfMaterialMdl = [
  createBuildOfMaterialFlow,
  updateBuildOfMaterialFlow,
  getBuildOfMaterialFlow,
  processBuildOfMaterialCollection,
];
