import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Typography,
  IconButton,
  Button,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Delete';
import { format } from 'date-fns';
import { showModal, hideModal } from '../../../../../redux/actions/bin';
import EditModal from '../../../Bin';
import ModalContainer from '../../../../../components/ModalContainer';

function formatDate(date) {
  if (!date) return '--';
  return format(new Date(date), 'MM-dd-yyyy HH:mm');
}

function BinTable({
  data = [],
  onNewBin,
  onRemoveBin,
  warehouseIndex,
  rackIndex,
}) {
  const dispatch = useDispatch();

  const modalOpen = useSelector((state) => state.ui.binModal);

  const handleOpenModal = () => {
    dispatch(showModal());
  };
  const handleCloseModal = () => {
    dispatch(hideModal());
  };

  const handleRemove = (index) => () => {
    onRemoveBin(warehouseIndex, rackIndex, index);
  };

  const handleSave = (data) => {
    onNewBin(warehouseIndex, rackIndex, data);
    handleCloseModal();
  };

  return (
    <>
      <Box>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h6" gutterBottom component="span">
            Bins
          </Typography>
          <Button
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleOpenModal}
          >
            New
          </Button>
        </Box>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Maximum Weight</TableCell>
              <TableCell>Date of Last Count</TableCell>
              <TableCell>Date of Last Reconciliation</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length ? (
              data.map((bin, index) => (
                <TableRow key={bin._id}>
                  <TableCell>
                    <b>{bin.name}</b>
                  </TableCell>
                  <TableCell>{`${bin.max_weight} lb` || '--'}</TableCell>
                  <TableCell>{formatDate(bin.last_count_date)}</TableCell>
                  <TableCell>{formatDate(bin.last_reconcile_date)}</TableCell>
                  <TableCell>
                    <IconButton onClick={handleRemove(index)}>
                      <RemoveIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  There is no bins at this rack
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>
      <ModalContainer open={modalOpen} handleClose={handleCloseModal}>
        <EditModal
          id="new"
          handleClose={handleCloseModal}
          onSave={handleSave}
        />
      </ModalContainer>
    </>
  );
}

export default BinTable;
