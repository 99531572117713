import React from 'react';

import {
  Container,
} from './styles';

function Spinner({ parent, overlay }) {
  return (
    <Container parent={parent} overlay={overlay}>
      <div className="lds-ellipsis">
        <div />
        <div />
        <div />
        <div />
      </div>
    </Container>
  );
}

export default Spinner;
