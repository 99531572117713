import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
import { getOwners } from '../../../../redux/actions/owners';
import { getProjects } from '../../../../redux/actions/projects';
import { getOriginalCharge } from '../../../../redux/actions/originalCharges';
import { getCustomers } from '../../../../redux/actions/customers';
import { getWells } from '../../../../redux/actions/wells';
import TabPanel from '../../../../components/TabPanel';
import DefaultInput from './DefaultInput';

const segments = ['Drilling', 'Completions', 'Subsea Ops', 'Production'];

function OwnershipAndAllocationData({
  data,
  selectedTab,
  tabIndex,
  onChange,
  setData,
  errors = [],
}) {
  const [assignedProject, setAssignedProject] = useState('');
  const [assignedWell, setAssignedWell] = useState('');
  const [allocatedProject, setAllocatedProject] = useState('');
  const [allocatedWell, setAllocatedWell] = useState('');

  const dispatch = useDispatch();
  const equipmentLoading = useSelector(
    (state) => state.ui.movimentationLoading
  );
  const owners = useSelector((state) => state.owners?.docs || state.owners);
  const customers = useSelector(
    (state) => state.customers?.docs || state.customers
  );
  const chargeCodes = useSelector(
    (state) => state.originalCharges?.docs || state.originalCharges
  );
  const projects = useSelector(
    (state) => state.projects?.docs || state.projects
  );
  const wells = useSelector((state) => state.wells?.docs || state.wells);

  useEffect(() => {
    dispatch(getOwners({ filter: { listAll: true } }));
    dispatch(getWells({ filter: { listAll: true } }));
    dispatch(getCustomers({ listAll: true, active: true }));
    dispatch(getProjects({ filter: { listAll: true } }));
  }, []);

  useEffect(() => {
    dispatch(
      getOriginalCharge({ filter: { customer: data.customer }, listAll: true })
    );
  }, [data.customer]);

  useEffect(() => {
    if (!data.assigned_customer_charge_code) return;
    const chargeCode = chargeCodes.find(
      ({ _id }) => _id === data.assigned_customer_charge_code
    );
    setAssignedProject(chargeCode?.well?.project?._id);
    setAssignedWell(chargeCode?.well?._id);
  }, [data.assigned_customer_charge_code, chargeCodes]);

  useEffect(() => {
    if (!data.allocated_charge_code) return;

    const chargeCode = chargeCodes.find(
      ({ _id }) => _id === data.allocated_charge_code
    );
    setAllocatedProject(chargeCode?.well?.project?._id);
    setAllocatedWell(chargeCode?.well?._id);
  }, [data.allocated_charge_code, chargeCodes]);

  const handleProject = (type) => (e) => {
    const { value } = e.target;
    const setter = {
      assigned: {
        project: setAssignedProject,
        well: setAssignedWell,
        chargeCode: 'assigned_customer_charge_code',
      },
      allocated: {
        project: setAllocatedProject,
        well: setAllocatedWell,
        chargeCode: 'allocated_charge_code',
      },
    };
    setter[type].project(value);
    setter[type].well('');
    setData({ [setter[type].chargeCode]: '' });
  };

  const handleWell = (type) => (e) => {
    const { value } = e.target;
    const setter = {
      assigned: {
        well: setAssignedWell,
        chargeCode: 'assigned_customer_charge_code',
      },
      allocated: {
        well: setAllocatedWell,
        chargeCode: 'allocated_charge_code',
      },
    };
    setter[type].well(value);
    setData({ [setter[type].chargeCode]: '' });
  };

  const wellOptions = (type) => {
    const project = {
      assigned: assignedProject,
      allocated: allocatedProject,
    };

    const filtered = project[type]
      ? wells.filter((well) => well.project?._id === project[type])
      : wells;

    return filtered.map(({ name, _id }) => ({ value: _id, label: name }));
  };

  const chargeCodeOptions = (type) => {
    const well = {
      assigned: assignedWell,
      allocated: allocatedWell,
    };

    const filtered = well[type]
      ? chargeCodes.filter((chargeCode) => chargeCode?.well?._id === well[type])
      : chargeCodes;

    return filtered.map(({ original_charge_code, _id }) => ({
      value: _id,
      label: original_charge_code,
    }));
  };

  const handleChangeCustomer = (e) => {
    onChange(e);
    setData({
      SLOC: '',
      warehouse_location: '',
      rack_location: '',
      bin_location: '',
      customer_MM: '',
      assigned_customer_charge_code: '',
      allocated_charge_code: '',
    });
  };

  const handleChangeBatchNo = (e) => {
    const toUpdate = { batch_no: e.target.value };
    if (!data.original_batch_no || data.original_batch_no === data.batch_no)
      toUpdate.original_batch_no = e.target.value;
    setData(toUpdate);
  };

  const fields = [
    {
      label: 'Material Owner',
      key: 'material_owner',
      options: owners.map(({ name, _id }) => ({ value: _id, label: name })),
    },
    {
      label: 'Customer',
      key: 'customer',
      options: customers.map(({ name, _id }) => ({ value: _id, label: name })),
      required: true,
      onChange: handleChangeCustomer,
    },
    {
      label: 'Segment',
      key: 'segment',
      options: segments.map((segment) => ({ label: segment, value: segment })),
    },
    { label: 'Department', key: 'department' },
    { label: 'Customer Equipment Contact', key: 'contact' },
    { label: 'Associated Equipment ID', key: 'associated_equipment_id' },
    {
      label: 'Assigned Batch Number',
      key: 'batch_no',
      onChange: handleChangeBatchNo,
    },
    {
      label: 'Assigned Project',
      key: 'assigned_project',
      options: projects.map(({ name, _id }) => ({ value: _id, label: name })),
      onChange: handleProject('assigned'),
      value: assignedProject,
    },
    {
      label: 'Assigned Well',
      key: 'assigned_well',
      options: wellOptions('assigned'),
      onChange: handleWell('assigned'),
      value: assignedWell,
    },
    {
      label: 'Assigned Charge Code',
      key: 'assigned_customer_charge_code',
      options: chargeCodeOptions('assigned'),
    },
    { label: 'Allocated Batch Number', key: 'allocated_batch_number' },
    {
      label: 'Allocated Project',
      key: 'allocated_project',
      options: projects.map(({ name, _id }) => ({ value: _id, label: name })),
      onChange: handleProject('allocated'),
      value: allocatedProject,
    },
    {
      label: 'Allocated Well',
      key: 'allocated_well',
      options: wellOptions('allocated'),
      onChange: handleWell('allocated'),
      value: allocatedWell,
    },
    {
      label: 'Allocated Charge Code',
      key: 'allocated_charge_code',
      options: chargeCodeOptions('allocated'),
    },
    { label: 'Divestment Number', key: 'divestment_number' },
  ];

  return (
    <TabPanel value={selectedTab} index={tabIndex} dir="ltr">
      <Grid container style={{ opacity: equipmentLoading ? 0.3 : 1 }}>
        {fields.map(({ key, label, ...props }) => (
          <DefaultInput
            key={key}
            name={key}
            onChange={onChange}
            value={data[key]}
            label={label}
            error={errors.includes(key)}
            helperText={errors.includes(key) && `${label} is required`}
            {...props}
          />
        ))}
      </Grid>
    </TabPanel>
  );
}

export default OwnershipAndAllocationData;
