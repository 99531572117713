/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  TextField,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { InputContainer } from '../../components/InventoryFilter/styles';
import { makeStyles } from '@material-ui/core/styles';
import { AddRounded } from '@material-ui/icons';
import { getCustomers } from '../../redux/actions/customers';
import Toastify from '../../utils/Toastify/index';
import Alert from '../../components/Alert/index';
import {
  Container,
  PaperStyled,
  Title,
  TableTitle,
  ExcelTable,
  Column,
  ColumnHeader,
  ColumnContent,
  ButtonContainer,
  AddButton,
  AddMoreButtonContainer,
} from './styles';
import staticFields from '../../utils/StaticData/MaterialMasterInputs/import_data';
import excelColumns from '../../utils/StaticData/ExcelColumns/data';
import { apiUrl } from '../../config/consts';
import DropZone from '../../components/DropZone';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  title: {
    fontSize: 20,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  row: { padding: 0 },
  cell: {
    fontSize: 12,
    padding: '0px 10px 0px 15px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  buttons: {
    color: 'blue',
  },
  checkButton: {
    color: 'green',
  },
  closeButton: {
    color: 'red',
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const ImportMenu = ({ dispatch, customers = [] }) => {
  const history = useHistory();
  const classes = useStyles();
  const [customer, setCustomer] = useState('');
  const [customerError, setCustomerError] = useState(false);
  const [file, setFile] = useState(null);
  const [fieldsLen, setFieldsLen] = useState(staticFields.length);
  const [fields, setFields] = useState([]);
  const [inputs, setInputs] = useState([]);
  const [skipHeader, setSkipHeader] = useState(false);

  const fieldsOrder = () => {
    setInputs(
      staticFields.sort(function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      })
    );
  };

  useEffect(() => {
    fieldsOrder();
    dispatch(getCustomers({ filter: { listAll: true, active: true } }));
  }, []);

  useEffect(() => {
    if (fieldsLen <= fields) return;
    setFields(getNewFieldsState(fieldsLen));

    function getNewFieldsState(newFieldsLength) {
      return function (currentFieldsState) {
        const emptyArray = createEmptyFieldsArray(
          currentFieldsState,
          newFieldsLength
        );
        return concatArrays(currentFieldsState, emptyArray);
      };
    }

    function createEmptyFieldsArray(currentList, newLength) {
      const length = getQtyToAdd(newLength, currentList.length);
      return [...Array(length).keys()].map((_, index) => {
        return {
          label: getExcelColumn(index, currentList.length),
          value: '',
        };
      });
    }

    function getQtyToAdd(newFieldsLength, currentFieldsLength) {
      return newFieldsLength - currentFieldsLength;
    }

    function getExcelColumn(index, length) {
      return excelColumns[length + index];
    }

    function concatArrays(head, tail) {
      return [...head, ...tail];
    }
  }, [fieldsLen]);

  const setValueOfField = (column, newValue) => {
    const newItems = JSON.parse(JSON.stringify(fields));
    const index = newItems.findIndex((item) => item.label === column);
    newItems[index].value = newValue;
    setFields(newItems);
  };

  const handleImport = async () => {
    let err = false;
    console.table(fields);

    if (!customer) {
      err = true;
      setCustomerError(true);
    }

    if (!file) {
      err = true;
      Toastify.addError('File not found. Please select a valid file.');
      return;
    }

    if (file.invalid) {
      err = true;
      Toastify.addError(
        'The file type is not supported. Please select an excel file.'
      );
    }

    let savedDocuments = 0;
    fields.forEach((field) => {
      if (field.value) {
        savedDocuments += 1;
      }
    });

    if (savedDocuments === 0) {
      err = true;
      Toastify.addError('You must select the excel columns.');
    }

    if (!err) {
      const form = new FormData();
      form.append('file', file);
      form.append('skipHeader', skipHeader);
      if (customer && customer !== '') {
        form.append('customer', customer);
      }

      fields.forEach((field, index) => {
        if (field.value) {
          form.append(field.value, index + 1);
        }
      });

      const confirmResponse = await Alert();
      if (confirmResponse && confirmResponse.confirm) {
        fetch(`${apiUrl}import_file/material_master`, {
          method: 'POST',
          body: form,
        })
          .then((response) => {
            if (response.status === 200 || response.status === 204) {
              Toastify.addSuccess('The file was imported successfully.');
              history.push(`/material_master/`);
            } else {
              Toastify.addError('Error to import the file.');
            }
          })
          .catch(() => {
            Toastify.addError('Error to import the file.');
          });
      }
    }
  };

  return (
    <Container>
      <PaperStyled elevation={1}>
        <Grid container>
          <Grid item lg={6} md={6} xs={6} sm={6}>
            <Title>Import Material Master Form</Title>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} align="center">
            <DropZone file={file} onChangeFile={setFile} />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <FormControl
              variant="outlined"
              size="small"
              error={customerError}
              helperText="Required field"
              fullWidth
            >
              <InputLabel id="select-label">Select the customer*</InputLabel>
              <Select
                size="small"
                labelId="select-label"
                variant="outlined"
                id="select-label-component"
                value={customer}
                onChange={(e) => {
                  setCustomer(e.target.value);
                  setCustomerError(false);
                }}
                fullWidth
              >
                {customers &&
                  customers.map((r) => (
                    <MenuItem value={r._id}>{r.name}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={skipHeader}
                  color="primary"
                  onChange={(event) => setSkipHeader(event.target.checked)}
                />
              }
              label="Skip first row of each column?"
            />
          </Grid>
        </Grid>
        <TableTitle>Excel Columns</TableTitle>
        <ExcelTable>
          <AddMoreButtonContainer>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setFieldsLen(fieldsLen + 1)}
            >
              Add +1 Column
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: 5 }}
              onClick={() => setFieldsLen(fieldsLen + 5)}
            >
              Add +5 Column
            </Button>
          </AddMoreButtonContainer>
          {fields &&
            fields.map(
              (column, count) =>
                count < fieldsLen && (
                  <Column>
                    <ColumnHeader>{column.label}</ColumnHeader>
                    <ColumnContent>
                      <InputContainer>
                        <Autocomplete
                          freeSolo
                          autoHighlight
                          size="small"
                          options={inputs.filter((input) =>
                            fields.every((field) => field.value !== input.value)
                          )}
                          getOptionLabel={(option) => option?.label}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setValueOfField(column.label, newValue.value);
                            } else {
                              setValueOfField(column.label, null);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label={params.label}
                              placeholder={params.label}
                            />
                          )}
                        />
                      </InputContainer>
                    </ColumnContent>
                  </Column>
                )
            )}
        </ExcelTable>
        <ButtonContainer>
          <Button
            color="secondary"
            onClick={() => history.push('/material_master')}
            variant="contained"
            style={{ marginRight: 5 }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={handleImport}
            variant="contained"
            style={{ marginleft: 5 }}
          >
            Import
          </Button>
        </ButtonContainer>
      </PaperStyled>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  customers: state.customers?.docs || state.customers,
});

export default connect(mapStateToProps)(ImportMenu);
