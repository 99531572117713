import {
  GET_VENDOR,
  UPDATE_VENDOR,
  UPDATE_OBJECT_VENDOR,
  CREATE_VENDOR,
  DELETE_VENDOR,
} from '../actions/vendor';

export function vendorReducer(vendor = {}, action) {
  switch (action.type) {
    case GET_VENDOR:
      return action.payload;
    case UPDATE_VENDOR:
      return action.payload;
    case CREATE_VENDOR:
      return action.payload;
    case DELETE_VENDOR:
      return action.payload;
    case UPDATE_OBJECT_VENDOR:
      return action.payload;

    default:
      return vendor;
  }
}
