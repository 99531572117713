import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TextField from '@material-ui/core/TextField';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import {
  CheckCircle,
  CancelRounded,
  EditRounded,
  AddRounded,
  ArrowBack,
  Search,
} from '@material-ui/icons';
import { Button } from '@material-ui/core';
import { getServiceCategories } from '../../redux/actions/serviceCategories';
import { showModal, hideModal } from '../../redux/actions/serviceCategory';
import ModalContainer from '../../components/ModalContainer/index';
import EditModal from '../Modals/ServiceCategories/index';
import Spinner from '../../components/Spinner/index';
import { Container } from './styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  title: {
    fontSize: 20,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  row: {
    padding: 0,
  },
  cell: {
    fontSize: 12,
    padding: '0px 10px 0px 15px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  buttons: {
    color: 'blue',
  },
  checkButton: {
    color: 'green',
  },
  closeButton: {
    color: 'red',
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 0,
  },
}));

function ServiceCategories({
  dispatch,
  serviceCategories,
  serviceCategoryModal,
  serviceCategoriesLoading,
}) {
  const history = useHistory();
  const classes = useStyles();
  const [serviceCategoriesFormatted, setServiceCategoriesFormatted] = useState([]);
  const [modalContentId, setModalContentId] = useState('new');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    dispatch(getServiceCategories({ page: page + 1, limit }));
  }, [page, limit]);

  useEffect(() => {
    if (serviceCategories.docs) {
      setServiceCategoriesFormatted(serviceCategories.docs);
      setTotal(serviceCategories.totalDocs);
    }
  }, [serviceCategories]);

  const handleOpenModal = () => {
    dispatch(showModal());
  };

  const handleCloseModal = () => {
    dispatch(hideModal());
  };

  const handleClick = (id) => {
    setModalContentId(id);
    handleOpenModal();
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleBack = () => {
    history.replace('/equipments');
  };

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(getServiceCategories({ page: 1, limit, searchText }));
  };

  return (
    <>
      <ModalContainer open={serviceCategoryModal} handleClose={handleCloseModal}>
        <EditModal id={modalContentId} handleClose={handleCloseModal} />
      </ModalContainer>
      <Container>
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <Toolbar className={classes.toolBar}>
              <span style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              >
                <IconButton onClick={handleBack}>
                  <ArrowBack />
                </IconButton>
                <Typography
                  className={classes.title}
                  variant="h6"
                  id="tableTitle"
                  component="span"
                >
                  Service Categories
                </Typography>
              </span>
              <span>
                <Button
                  className={classes.buttons}
                  onClick={() => handleClick('new')}
                  startIcon={<AddRounded />}
                >
                  New
                </Button>
              </span>
            </Toolbar>
            <div style={{
              paddingTop: 10,
              width: 'fullWidth',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginLeft: '2rem',
              marginRight: '2rem',
            }}
            >
              <TextField
                label="Search"
                type="text"
                variant="standard"
                value={searchText}
                size="small"
                InputProps={{
                  endAdornment:
  <IconButton
    onClick={handleSearch}
    size="small"
  >
    <Search />
  </IconButton>,
                }}
                name="vendor_name"
                onChange={(e) => setSearchText(e.target.value)}
                onKeyPress={(e) => (e.key === 'Enter' ? handleSearch(e) : null)}
                style={{ width: 300 }}
              />
            </div>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Active</TableCell>
                    <TableCell colSpan={2}>Name</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {serviceCategoriesLoading && (
                  <TableRow className={classes.row}>
                    <TableCell colSpan="5" className={classes.cell}>
                      <Spinner parent="Table" />
                    </TableCell>
                  </TableRow>
                  )}
                  {!serviceCategoriesLoading
                      && serviceCategoriesFormatted.map((serviceCategory) => (
                        <TableRow className={classes.row}>
                          <TableCell className={classes.cell}>
                            {serviceCategory.active ? (
                              <CheckCircle className={classes.checkButton} />
                            ) : (
                              <CancelRounded className={classes.closeButton} />
                            )}
                          </TableCell>
                          <TableCell colSpan="2" className={classes.cell}>
                            <b>{serviceCategory.name}</b>
                          </TableCell>
                          <TableCell className={classes.cell}>
                            <IconButton onClick={() => handleClick(serviceCategory._id)}>
                              <EditRounded />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[2, 5, 10, 25]}
              component="div"
              count={total}
              rowsPerPage={limit}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({
  serviceCategoriesLoading: state.ui.serviceCategoriesLoading,
  serviceCategories: state.serviceCategories,
  serviceCategoryModal: state.ui.serviceCategoryModal,
});

export default connect(mapStateToProps)(ServiceCategories);
