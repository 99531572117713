import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
import { countries } from 'countries-list';
import DefaultInput from './DefaultInput';
import { getBusinessUnits } from '../../../../redux/actions/businessUnits';
import { getCompanyCodes } from '../../../../redux/actions/companyCodes';
import TabPanel from '../../../../components/TabPanel';

const countryOptions = Object.keys(countries).map((code) => ({
  value: code,
  label: `${code} - ${countries[code].name}`,
}));
const stockIndicators = [{ label: '', value: '' }];

const buildOption =
  (labelField = 'name', valueField = '_id') =>
  (item) => ({ label: item[labelField], value: item[valueField] });

function CustomerSpecificData({
  data,
  selectedTab,
  tabIndex,
  onChange,
  errors = [],
}) {
  const dispatch = useDispatch();

  const equipmentLoading = useSelector(
    (state) => state.ui.movimentationLoading
  );

  const businessUnits = useSelector(
    (state) => state.businessUnits?.docs || state.businessUnits || []
  );
  const customerMMs = useSelector(
    (state) => state.customerMMs?.docs || state.customerMMs || []
  );
  const companyCodes = useSelector(
    (state) => state.companyCodes?.docs || state.companyCodes || []
  );

  useEffect(() => {
    dispatch(getBusinessUnits({ filter: { listAll: true } }));
    dispatch(getCompanyCodes({ filter: { listAll: true } }));
  }, []);

  //TODO ask about Customer SLOC ID

  const fields = [
    {
      label: 'Customer MM Number',
      key: 'customer_MM',
      options: customerMMs.map(buildOption('mm_number')),
      required: true,
      disabled: true,
    },
    { label: 'Customer Batch Number', key: 'customer_batch_no' },
    { label: 'Assigned Plant Code', key: 'plant_code' },
    {
      label: 'Special Stock Indicator',
      key: 'special_stock_indicator',
      options: stockIndicators,
    },
    {
      label: 'Business Unit',
      key: 'business_unit',
      options: businessUnits.map(buildOption('unit')),
    },
    {
      label: 'Company Code',
      key: 'company_code',
      options: companyCodes.map(buildOption('code')),
    },
    { label: 'Country', key: 'country', options: countryOptions },
  ];

  return (
    <TabPanel value={selectedTab} index={tabIndex} dir="ltr">
      <Grid container style={{ opacity: equipmentLoading ? 0.3 : 1 }}>
        {fields.map(({ key, label, ...props }) => (
          <DefaultInput
            key={key}
            name={key}
            onChange={onChange}
            label={label}
            value={data[key] || ''}
            error={errors.includes(key)}
            helperText={errors.includes(key) && `${label} is required`}
            {...props}
          />
        ))}
      </Grid>
    </TabPanel>
  );
}

export default CustomerSpecificData;
