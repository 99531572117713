export const GET_REPORT_FIELD = '[report field] GET';
export const UPDATE_REPORT_FIELD = '[report field] UPDATE';
export const CREATE_REPORT_FIELD = '[report field] CREATE';
export const DELETE_REPORT_FIELD = '[report field] DELETE';
export const UPDATE_OBJECT_REPORT_FIELD = '[report field] UPDATE OBJECT REPORT_FIELD';
// UI
export const SHOW_REPORT_FIELD_MODAL = '[report field] SHOW MODAL';
export const HIDE_REPORT_FIELD_MODAL = '[report field] HIDE MODAL';
// Spinner
export const UPDATE_REPORT_FIELD_SUCCESS = '[report field] Update success';
export const UPDATE_REPORT_FIELD_ERROR = '[report field] Update Error';
export const FETCH_REPORT_FIELD_SUCCESS = '[report field] Fetch success';
export const FETCH_REPORT_FIELD_ERROR = '[report field] Fetch Error';
export const SHOW_REPORT_FIELD_SPINNER = '[report field - ui] show spinner';
export const HIDE_REPORT_FIELD_SPINNER = '[report field - ui] hide spinner';

export const getReportField = (id) => ({
  type: GET_REPORT_FIELD,
  payload: { _id: id },
});

export const createReportField = (data) => ({
  type: CREATE_REPORT_FIELD,
  payload: data,
});

export const updateReportField = (data) => ({
  type: UPDATE_REPORT_FIELD,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_REPORT_FIELD_MODAL,
});

export const hideModal = () => ({
  type: HIDE_REPORT_FIELD_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_REPORT_FIELD_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_REPORT_FIELD_SPINNER,
});

export const updateObjectReportField = (data) => ({
  type: UPDATE_OBJECT_REPORT_FIELD,
  payload: data,
});
