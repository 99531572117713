import {
  GET_ORIGINAL_CHARGE,
  UPDATE_ORIGINAL_CHARGE,
  UPDATE_OBJECT_ORIGINAL_CHARGE,
  CREATE_ORIGINAL_CHARGE,
  DELETE_ORIGINAL_CHARGE,
} from '../actions/originalCharge';

export function originalChargeReducer(originalCharge = {}, action) {
  switch (action.type) {
    case GET_ORIGINAL_CHARGE:
      return action.payload;
    case UPDATE_ORIGINAL_CHARGE:
      return action.payload;
    case CREATE_ORIGINAL_CHARGE:
      return action.payload;
    case DELETE_ORIGINAL_CHARGE:
      return action.payload;
    case UPDATE_OBJECT_ORIGINAL_CHARGE:
      return action.payload;
    default:
      return originalCharge;
  }
}
