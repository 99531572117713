export const GET_VENDORS = '[vendors] GET';
export const FETCH_VENDORS_SUCCESS = '[vendors] Fetch success';
export const FETCH_VENDORS_ERROR = '[vendors] Fetch Error';
export const UPDATE_VENDORS = '[vendors] Update';
export const SHOW_VENDORS_SPINNER = '[vendors - ui] show spinner';
export const HIDE_VENDORS_SPINNER = '[vendors - ui] hide spinner';

export const getVendors = (filter) => ({
  type: GET_VENDORS,
  payload: {
    ...filter,
  },
});

export const updateVendors = (data) => ({
  type: UPDATE_VENDORS,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_VENDORS_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_VENDORS_SPINNER,
});
