import { CUSTOMER_KEY } from '../../config/consts';

export const GET_ORIGINAL_CHARGES = '[original_charges] GET';
export const FETCH_ORIGINAL_CHARGES_SUCCESS = '[original_charges] Fetch success';
export const FETCH_ORIGINAL_CHARGES_ERROR = '[original_charges] Fetch Error';
export const UPDATE_ORIGINAL_CHARGES = '[original_charges] Update';
export const SHOW_ORIGINAL_CHARGES_SPINNER = '[original_charges - ui] show spinner';
export const HIDE_ORIGINAL_CHARGES_SPINNER = '[original_charges - ui] hide spinner';

export const getOriginalCharge = (filter) => ({
  type: GET_ORIGINAL_CHARGES,
  payload: {
    ...filter,
    filter: { customer: localStorage.getItem(CUSTOMER_KEY), ...filter.filter },
  },
});

export const updateOriginalCharge = (data) => ({
  type: UPDATE_ORIGINAL_CHARGES,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_ORIGINAL_CHARGES_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_ORIGINAL_CHARGES_SPINNER,
});
