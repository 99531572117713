import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import {
  AddRounded,
  ArrowBack,
  EditRounded,
  CloudUploadOutlined,
  GetApp as ExportIcon,
} from '@material-ui/icons';
import { getCustomerMMs } from '../../redux/actions/customerMMs';
import { showModal, hideModal } from '../../redux/actions/customerMM';
import Spinner from '../../components/Spinner/index';
import { Container } from './styles';
import ModalContainer from '../../components/ModalContainer/index';
import EditModal from '../Modals/CustomerMM';
import { CUSTOMER_KEY } from '../../config/consts';
import { tableStyles } from '../../styles/tableStyles';
import getRINADescription from '../../utils/Equipment/getRINADescription';
import CustomerMMFilter from '../../components/CustomerMMFilter';
import { download } from '../../services/Download';

function Home({
  dispatch,
  customerMMs,
  customerMMsLoading,
  customerMMModal,
  customerMMFilter,
}) {
  const { customer } = useParams();
  const history = useHistory();
  const classes = tableStyles();
  const [customerMMsFormated, setCustomerMMsFormated] = useState([]);
  const [modalContentId, setModalContentId] = useState('new');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(50);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (!localStorage.getItem(CUSTOMER_KEY)) return;
    dispatch(
      getCustomerMMs({
        page: page + 1,
        limit,
        customer: localStorage.getItem(CUSTOMER_KEY),
      }),
    );
  }, [page, limit]);

  useEffect(() => {
    if (customerMMs.docs) {
      setCustomerMMsFormated(customerMMs.docs);
      setTotal(customerMMs.totalDocs);
    }
  }, [customerMMs]);

  const handleBack = () => {
    history.replace('/equipments');
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleOpenModal = () => {
    dispatch(showModal());
  };
  const handleClick = (id) => {
    setModalContentId(id);
    handleOpenModal();
  };

  const handleCloseModal = () => {
    dispatch(hideModal());
  };

  const handleGoToImport = () => {
    history.push('/material_master/import');
  };

  const handleExport = () => {
    download('export/material_masters', {
      ...customerMMFilter,
      customer: localStorage.getItem(CUSTOMER_KEY),
    });
  };

  return (
    <Container>
      <ModalContainer open={customerMMModal} handleClose={handleCloseModal}>
        <EditModal id={modalContentId} handleClose={handleCloseModal} />
      </ModalContainer>
      <CustomerMMFilter />
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Toolbar className={classes.toolBar}>
            <span>
              <IconButton onClick={handleBack}>
                <ArrowBack />
              </IconButton>
              <Typography
                className={classes.title}
                variant="h6"
                id="tableTitle"
                component="span"
              >
                Customer Material Master
              </Typography>
            </span>
            <span>
              <Button
                className={classes.buttons}
                onClick={() => handleClick('new')}
                startIcon={<AddRounded />}
              >
                New
              </Button>
              <Button
                color="secondary"
                onClick={handleGoToImport}
                startIcon={<CloudUploadOutlined />}
              >
                Import List
              </Button>
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={handleExport}
                startIcon={<ExportIcon />}
              >
                Export List
              </Button>
            </span>
          </Toolbar>
          <TableContainer className={classes.table}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>RINA MM No</TableCell>
                  <TableCell>Customer MM No</TableCell>
                  <TableCell>RINA Description</TableCell>
                  <TableCell>Short Description</TableCell>
                  <TableCell>Long Description</TableCell>
                  <TableCell>Equipment Type</TableCell>
                  <TableCell>Hazardous Material</TableCell>
                  <TableCell>Modifier</TableCell>
                  <TableCell>Max OD Size</TableCell>
                  <TableCell>Tube Weight</TableCell>
                  <TableCell>Material Grade</TableCell>
                  <TableCell>Connection 1</TableCell>
                  <TableCell>Conn 1 (SIZE)</TableCell>
                  <TableCell>Conn 1 (WEIGHT)</TableCell>
                  <TableCell>Conn 1 (GRADE)</TableCell>
                  <TableCell>Connection 2</TableCell>
                  <TableCell>Conn 2 (SIZE)</TableCell>
                  <TableCell>Conn 2 (WEIGHT)</TableCell>
                  <TableCell>Conn 2 (GRADE)</TableCell>
                  <TableCell>Conn Config</TableCell>
                  <TableCell>Nominal Wall</TableCell>
                  <TableCell>Drift Diameter</TableCell>
                  <TableCell>Range</TableCell>
                  <TableCell>Pipe Type</TableCell>
                  <TableCell>Manufacturer</TableCell>
                  <TableCell>Manufacture PN</TableCell>
                  <TableCell>Inspection Criteria</TableCell>
                  <TableCell>Pressure Rating (PSI)</TableCell>
                  <TableCell>Action Type</TableCell>
                  <TableCell>Primary UoM</TableCell>
                  <TableCell>Secondary UoM</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {!localStorage.getItem(CUSTOMER_KEY) ? (
                  <TableRow className={classes.row}>
                    <TableCell
                      colSpan="18"
                      className={classes.cell}
                      align="center"
                    >
                      <Typography variant="h6">
                        Select an inventory
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {customerMMsLoading && (
                      <TableRow className={classes.row}>
                        <TableCell colSpan="18" className={classes.cell}>
                          <Spinner parent="Table" />
                        </TableCell>
                      </TableRow>
                    )}
                    {!customerMMsLoading
                      && customerMMsFormated.map((customerMM) => (
                        <TableRow className={classes.row}>
                          <TableCell className={classes.cell}>
                            <b>{customerMM.rina_mm_number || '--'}</b>
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {customerMM.mm_number || '--'}
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {getRINADescription(customerMM) || '--'}
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {customerMM.short_description || '--'}
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {customerMM.long_description || '--'}
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {customerMM.equipment_type || '--'}
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {customerMM.hazmat ? 'YES' : 'NO'}
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {customerMM.modifier || '--'}
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {customerMM.size || '--'}
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {customerMM.weight || '--'}
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {customerMM.grade || '--'}
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {customerMM.connection_1 || '--'}
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {customerMM.connection_1_size || '--'}
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {customerMM.connection_1_weight || '--'}
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {customerMM.connection_1_grade || '--'}
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {customerMM.connection_2 || '--'}
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {customerMM.connection_2_size || '--'}
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {customerMM.connection_2_weight || '--'}
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {customerMM.connection_2_grade || '--'}
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {customerMM.connection_configuration || '--'}
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {customerMM.nominal_wall || '--'}
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {customerMM.drift_diameter || '--'}
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {customerMM.range || '--'}
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {customerMM.pipe_type || '--'}
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {customerMM.manufacturer || '--'}
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {customerMM.manufacturer_pn || '--'}
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {customerMM.inspection_criteria || '--'}
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {customerMM.pressure_rating || '--'}
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {customerMM.action_type || '--'}
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {customerMM.primary_uom || '--'}
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {customerMM.secondary_uom || '--'}
                          </TableCell>
                          <TableCell className={classes.cell}>
                            <IconButton
                              onClick={() => handleClick(customerMM._id)}
                            >
                              <EditRounded />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    {' '}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[50, 100, 200, 500, 1000]}
            component="div"
            count={total}
            rowsPerPage={limit}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  customerMMsLoading: state.ui.customerMMsLoading,
  customerMMs: state.customerMMs,
  customerMMModal: state.ui.customerMMModal,
  customerMMFilter: state.customerMMFilter,
});

export default connect(mapStateToProps)(Home);
