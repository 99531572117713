import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Typography,
  IconButton,
  Button,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { format } from 'date-fns';
import { showModal, hideModal } from '../../../../redux/actions/bin';
import EditModal from '../../../Modals/Bin';
import ModalContainer from '../../../../components/ModalContainer';

function formatDate(date) {
  if (!date) return '--';
  return format(new Date(date), 'MM-dd-yyyy HH:mm');
}

function BinTable({ data = [], parentId }) {
  const dispatch = useDispatch();
  const [modalContentId, setModalContentId] = useState();

  const modalOpen = useSelector((state) => state.ui.binModal);

  const handleOpenModal = () => {
    dispatch(showModal());
  };
  const handleCloseModal = () => {
    dispatch(hideModal());
  };
  const handleClick = (id) => {
    setModalContentId(id);
    handleOpenModal();
  };

  return (
    <>
      <Box>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h6" gutterBottom component="span">
            Bins
          </Typography>
          <Button
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => handleClick('new')}
          >
            New
          </Button>
        </Box>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Maximum Weight</TableCell>
              <TableCell>Date of Last Count</TableCell>
              <TableCell>Date of Last Reconciliation</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length ? (
              data.map((bin) => (
                <TableRow key={bin._id}>
                  <TableCell>
                    <b>{bin.name}</b>
                  </TableCell>
                  <TableCell>{`${bin.max_weight} lb` || '--'}</TableCell>
                  <TableCell>{formatDate(bin.last_count_date)}</TableCell>
                  <TableCell>{formatDate(bin.last_reconcile_date)}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleClick(bin._id)}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  There is no bins at this rack
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>
      <ModalContainer open={modalOpen} handleClose={handleCloseModal}>
        <EditModal
          id={modalContentId}
          rackId={parentId}
          handleClose={handleCloseModal}
        />
      </ModalContainer>
    </>
  );
}

export default BinTable;
