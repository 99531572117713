export const GET_WAREHOUSES = '[warehouses] GET';
export const FETCH_WAREHOUSES_SUCCESS = '[warehouses] Fetch success';
export const FETCH_WAREHOUSES_ERROR = '[warehouses] Fetch Error';
export const UPDATE_WAREHOUSES = '[warehouses] Update';
export const SHOW_WAREHOUSES_SPINNER = '[warehouses - ui] show spinner';
export const HIDE_WAREHOUSES_SPINNER = '[warehouses - ui] hide spinner';

export const getWarehouses = (filter) => ({
  type: GET_WAREHOUSES,
  payload: filter,
});

export const updateWarehouses = (data) => ({
  type: UPDATE_WAREHOUSES,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_WAREHOUSES_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_WAREHOUSES_SPINNER,
});
