export const GET_INSPECTIONS = '[inspections] GET';
export const FETCH_INSPECTIONS_SUCCESS = '[inspections] Fetch success';
export const FETCH_INSPECTIONS_ERROR = '[inspections] Fetch Error';
export const UPDATE_INSPECTIONS = '[inspections] Update';
export const SHOW_INSPECTIONS_SPINNER = '[inspections - ui] show spinner';
export const HIDE_INSPECTIONS_SPINNER = '[inspections - ui] hide spinner';

export const getInspections = (filter) => ({
  type: GET_INSPECTIONS,
  payload: filter,
});

export const updateInspections = (data) => ({
  type: UPDATE_INSPECTIONS,
  payload: data,
});

export const showSpinner = () => ({
  type: SHOW_INSPECTIONS_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_INSPECTIONS_SPINNER,
});
