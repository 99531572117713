import {
  GET_RACK,
  UPDATE_RACK,
  UPDATE_OBJECT_RACK,
  CREATE_RACK,
  DELETE_RACK,
} from '../actions/rack';

export function rackReducer(rack = {}, action) {
  switch (action.type) {
    case UPDATE_OBJECT_RACK:
      return action.payload;
    case GET_RACK:
    case UPDATE_RACK:
    case CREATE_RACK:
    case DELETE_RACK:
    default:
      return rack;
  }
}
