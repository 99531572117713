export const GET_CUSTOMER = '[customer] GET';
export const UPDATE_CUSTOMER = '[customer] UPDATE';
export const CREATE_CUSTOMER = '[customer] CREATE';
export const DELETE_CUSTOMER = '[customer] DELETE';
export const UPDATE_OBJECT_CUSTOMER = '[customer] UPDATE OBJECT CUSTOMER';
// UI
export const SHOW_CUSTOMER_MODAL = '[customer] SHOW MODAL';
export const HIDE_CUSTOMER_MODAL = '[customer] HIDE MODAL';
// Spinner
export const UPDATE_CUSTOMER_SUCCESS = '[customer] Update success';
export const UPDATE_CUSTOMER_ERROR = '[customer] Update Error';
export const FETCH_CUSTOMER_SUCCESS = '[customer] Fetch success';
export const FETCH_CUSTOMER_ERROR = '[customer] Fetch Error';
export const SHOW_CUSTOMER_SPINNER = '[customer - ui] show spinner';
export const HIDE_CUSTOMER_SPINNER = '[customer - ui] hide spinner';

export const getCustomer = (id) => ({
  type: GET_CUSTOMER,
  payload: { _id: id },
});

export const createCustomer = (data) => ({
  type: CREATE_CUSTOMER,
  payload: data,
});

export const updateCustomer = (data) => ({
  type: UPDATE_CUSTOMER,
  payload: data,
});

export const showModal = () => ({
  type: SHOW_CUSTOMER_MODAL,
});

export const hideModal = () => ({
  type: HIDE_CUSTOMER_MODAL,
});

export const showSpinner = () => ({
  type: SHOW_CUSTOMER_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_CUSTOMER_SPINNER,
});

export const updateObjectCustomer = (data) => ({
  type: UPDATE_OBJECT_CUSTOMER,
  payload: data,
});
