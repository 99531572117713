import React from 'react';

import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useHistory, withRouter } from 'react-router-dom';

import { CUSTOMER_KEY } from '../../config/consts';
import ButtonSpinner from '../ButtonSpinner';
import { getMovimentations } from '../../redux/actions/movimentations';
import {
  Container,
  SearchItem,
  Title,
  Label,
  Value,
  NoResults,
  SeeAll,
  Results,
  SpinnerContainer,
} from './styles';

function ResultBox({
  searchResults = [],
  totalSearch,
  loading,
  allowVisible,
  dispatch,
  query,
}) {
  const history = useHistory();

  function handleSeeAll() {
    dispatch(
      getMovimentations({
        page: 1,
        limit: 50,
        filter: { query, in_inventory: true, consolidated: true },
      })
    );
    history.push({
      pathname: `/equipments`,
      state: { fromSearch: true },
    });
  }

  function handleSelectInventory() {
    history.push('/equipments');
  }

  return (
    <Container open={allowVisible}>
      <NoResults open={!localStorage.getItem(CUSTOMER_KEY)}>
        <Button onClick={handleSelectInventory} variant="text">
          Select an inventory
        </Button>
      </NoResults>
      <SpinnerContainer open={loading}>
        <ButtonSpinner size="large" />
      </SpinnerContainer>
      <Results open={searchResults.length > 0}>
        {searchResults.map((item) => (
          <SearchItem key={item._id}>
            <Grid container>
              <Grid item xs={12}>
                <Title to={`/breakoutdata/${item._id}`}>
                  {item?.individual_item_id || 'Unnamed'}
                </Title>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <Label>Status</Label>
                <Value>item.status</Value>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <Label>Material Layout</Label>
                <Value>{item?.material_layout || 'None'}</Value>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <Label>Customer MM No</Label>
                <Value>{item.customer_MM?.mm_number || 'None'}</Value>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <Label>Total Qty. (EA)</Label>
                <Value>{item.qty || 0}</Value>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <Label>Supplier Batch No</Label>
                <Value>{item.batch_no || 'None'}</Value>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <Label>SLOC</Label>
                <Value>{item?.SLOC?.sloc_name || 'None'}</Value>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <Label>Assigned Project</Label>
                <Value>
                  {item?.assigned_customer_charge_code?.well?.project?.name ||
                    'None'}
                </Value>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <Label>Assigned Charge Code</Label>
                <Value>
                  {item?.assigned_customer_charge_code?.original_charge_code ||
                    'None'}
                </Value>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <Label>Allocated Batch No</Label>
                <Value>{item?.allocated_batch_number || 'None'}</Value>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <Label>Allocated WO No</Label>
                <Value>{item?.allocated_work_order_number || 'None'}</Value>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <Label>Supplier</Label>
                <Value>{item?.supplier || 'None'}</Value>
              </Grid>
            </Grid>
          </SearchItem>
        ))}
      </Results>
      <SeeAll open={searchResults.length > 0}>
        <Button onClick={handleSeeAll} color="primary" variant="text">
          See all {totalSearch} results...
        </Button>
      </SeeAll>

      <NoResults open={searchResults.length === 0 && !loading}>
        No results found
      </NoResults>
    </Container>
  );
}

function mapStateToProps(state) {
  return {
    searchResults: state.movimentations.searchResults,
    totalSearch: state.movimentations.totalSearch,
    loading: state.ui.movimentationsSearchLoading,
  };
}

export default withRouter(connect(mapStateToProps)(ResultBox));
