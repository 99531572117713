import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepContent from '@material-ui/core/StepContent';
import { Button } from '@material-ui/core';
import { connect } from 'react-redux';
import Alert from '../../../components/Alert/index';
import { Container } from './styles';
import GeneralInfo from './GeneralInfo';
import ContactInfo from './ContactInfo';
import Specifications from './Specifications';
import AdditionalNotes from './AdditionalNotes';
import EquipmentDetails from './EquipmentDetails';
import WorkInstructions from './WorkInstructions';
import Maintenance from './Maintenance';
import { getVendors } from '../../../redux/actions/vendors';
import { getProjects } from '../../../redux/actions/projects';
import { getOriginalCharge } from '../../../redux/actions/originalCharges';
import { getUsers } from '../../../redux/actions/users';
import {
  createWorkOrder,
  updateWorkOrder,
  getWorkOrder,
} from '../../../redux/actions/workOrder';

const steps = [
  { label: 'General Info', Component: GeneralInfo },
  { label: 'Service Provider', Component: ContactInfo },
  { label: 'Specifications', Component: Specifications },
  { label: 'Additional Notes', Component: AdditionalNotes },
  { label: 'Equipment Detail', Component: EquipmentDetails },
  { label: 'Work Instructions', Component: WorkInstructions },
  { label: 'Required Maintenance', Component: Maintenance },
];

function WorkOrder({ dispatch, workOrder, loggedUser }) {
  const { id } = useParams();
  const { goBack } = useHistory();
  const { state } = useLocation();
  const [isValid, setIsValid] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const [currentStep, setCurrentStep] = useState(null);
  const [data, setData] = useState({
    job_status: 'open',
    revision: {
      contacts: [],
      charge_code: null,
      specifications: [],
      additional_notes: [],
      equipment: [],
      special_comments: [],
      documents: [],
      photos: [],
      maintenances: [],
    },
  });

  useEffect(() => {
    dispatch(getVendors({ page: 1, limit: 999 }));
    dispatch(getUsers({ page: 1, limit: 999 }));
    dispatch(getProjects({ page: 1, limit: 999 }));
    dispatch(getOriginalCharge({ page: 1, limit: 999 }));
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if (id === 'new') return;
    dispatch(getWorkOrder(id));
  }, [id, isLoaded]);

  useEffect(() => {
    if (!currentStep)
      setTimeout(() => {
        setCurrentStep(0);
      }, 2500);
  }, [workOrder]);

  useEffect(() => {
    if (!workOrder || id === 'new') return;

    setData((currentData) => ({
      ...currentData,
      ...workOrder,
      revision: { ...currentData.revision, ...workOrder.revision },
    }));
  }, [workOrder]);

  useEffect(() => {
    if (state?.movimentations) {
      setData((currentData) => ({
        ...currentData,
        revision: { ...currentData.revision, equipment: state.movimentations },
      }));
    }
  }, [state]);

  function handleEdit(e) {
    const { name, value } = e.target;
    setData((currentData) => ({ ...currentData, [name]: value }));
  }

  function handleEditRevision(e) {
    const { name, value } = e.target;
    setData((currentData) => ({
      ...currentData,
      revision: { ...currentData.revision, [name]: value },
    }));
  }

  function handleDate(name) {
    function handler(value) {
      setData((currentData) => ({
        ...currentData,
        revision: { ...currentData.revision, [name]: value },
      }));
    }
    return handler;
  }

  function handleNext() {
    setCurrentStep((currentValue) => currentValue + 1);
  }
  function handlePrevious() {
    setCurrentStep((currentValue) => currentValue - 1);
  }
  function handleStep(step) {
    function handler() {
      setCurrentStep((currentValue) => (currentValue === step ? null : step));
    }
    return handler;
  }
  const handleSave = async () => {
    const response = await Alert();

    if (response && response.confirm) {
      if (id === 'new') {
        dispatch(
          createWorkOrder({
            ...data,
            user: loggedUser._id,
            revision: {
              ...data.revision,
              charge_code:
                data.revision.charge_code?._id || data.revision.charge_code,
              vendor: data.revision.vendor?._id || data.revision.vendor,
              equipment: data.revision.equipment.map((eqp) => eqp?._id || eqp),
            },
          })
        );
      } else {
        dispatch(
          updateWorkOrder({
            ...data,
            revision: {
              ...data.revision,
              charge_code:
                data.revision.charge_code?._id || data.revision.charge_code,
              vendor: data.revision.vendor?._id || data.revision.vendor,
              equipment: data.revision.equipment.map((eqp) => eqp?._id || eqp),
            },
          })
        );
      }
    }
  };

  useEffect(() => {
    setIsValid(() => {
      if (!data) return false;
      if (!data.revision) return false;
      if (!data.revision.activity) return false;
      if (!data.revision.coordinator) return false;
      if (!data.revision.charge_code) return false;
      return true;
    });
    console.log(data);
  }, [data]);

  return (
    <Container>
      <Stepper activeStep={currentStep} nonLinear orientation="vertical">
        {steps.map(({ label, Component }, index) => (
          <Step key={index}>
            <StepButton onClick={handleStep(index)}>{label}</StepButton>
            <StepContent>
              <Component
                handleDate={handleDate}
                handleEdit={handleEdit}
                handleEditRevision={handleEditRevision}
                data={data}
                setData={setData}
                workOrder={workOrder}
              />
              <Button
                variant="text"
                disabled={index === 0}
                onClick={handlePrevious}
                style={{ marginRight: 20, marginLeft: 30, marginTop: 20 }}
              >
                Back
              </Button>
              <Button
                variant="contained"
                color={index === steps.length - 1 ? 'primary' : 'secondary'}
                onClick={index === steps.length - 1 ? handleSave : handleNext}
                style={{ marginTop: 20 }}
              >
                {index === steps.length - 1 ? 'Save' : 'Next'}
              </Button>
            </StepContent>
          </Step>
        ))}
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="outlined"
            onClick={goBack}
            style={{ marginRight: 20, marginLeft: 30, marginTop: 20 }}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            disabled={!isValid}
            variant="contained"
            color="primary"
            onClick={handleSave}
            style={{ marginTop: 20 }}
          >
            Save
          </Button>
        </div>
      </Stepper>
    </Container>
  );
}

export default connect((state) => {
  return {
    workOrder: state.workOrder,
    loggedUser: state.loggedUser,
  };
})(WorkOrder);
