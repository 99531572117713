import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import { ArrowBack } from '@material-ui/icons';
import { getSLOCS } from '../../../redux/actions/slocs';
import { CUSTOMER_KEY } from '../../../config/consts';
import { tableStyles } from '../../../styles/tableStyles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Divider from '@material-ui/core/Divider';
import { InputContainer, Container, ButtonContainer } from './styles';
import EventDocumentsSection from '../../../components/EventDocumentsSection';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import SweetAlert from 'sweetalert2';
import axios from 'axios';
import { apiUrl } from '../../../config/consts';
import toast from '../../../utils/Toastify';
import { createCheckOut, getCheckOut } from '../../../redux/actions/checkOut';
import { downloadReport } from '../../../services/Download';
import TagsField from '../../../components/TagsField';

const swalWithBootstrapButtons = SweetAlert.mixin({
  customClass: {
    confirmButton: 'btn btn-success',
    cancelButton: 'btn btn-danger',
  },
  buttonsStyling: true,
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  title: {
    fontSize: 20,
  },
  paper: {
    width: '100%',
    marginBottom: 16,
  },
  row: {
    padding: 0,
  },
  cell: {
    fontSize: 12,
    padding: '0px 10px 0px 15px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  buttons: {
    color: 'blue',
  },
  checkButton: {
    color: 'green',
  },
  closeButton: {
    color: 'red',
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  styledSpan: {
    alignSelf: 'center',
    fontWeight: '700',
  },
}));

const CheckOut = ({ dispatch, loggedUser, SLOCs, checkOut }) => {
  const { customer, id } = useParams();
  const history = useHistory();
  const { state } = useLocation();
  const classes = tableStyles();
  const localClasses = useStyles();
  const [data, setData] = React.useState({
    individualData: [],
    supplierName: '',
    supplierPhone: '',
    supplierEmail: '',
    SLOC: null,
    facilityReferenceID: '',
    SLOC_POC: '',
    removeFromInventory: false,
    files: [],
  });

  const {
    individualData,
    supplierName,
    supplierPhone,
    supplierEmail,
    SLOC,
    facilityReferenceID,
    SLOC_POC,
    removeFromInventory,
    files,
  } = data;

  React.useEffect(() => {
    if (!id || id === 'new') return;
    dispatch(getCheckOut(id));
  }, [id]);

  React.useEffect(() => {
    if (id === 'new' || !checkOut) return;
    setData(checkOut);
  }, [checkOut]);

  React.useEffect(() => {
    if (state?.movimentations) {
      setData((currentData) => ({
        ...currentData,
        individualData: state.movimentations.map((movimentation) => ({
          movimentation,
          tags: [],
        })),
      }));
    }
  }, [state]);

  useEffect(() => {
    if (!localStorage.getItem(CUSTOMER_KEY))
      history.replace('/equipments', { origin: 'mm_number' });
  }, []);

  useEffect(() => {
    dispatch(getSLOCS({ page: 1, limit: 99999, filter: { customer } }));
  }, []);

  const handleRemoveFromInventory = async () =>
    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, save it.',
        cancelButtonText: 'No, cancel.',
        reverseButtons: true,
        input: 'password',
        inputLabel: 'Password',
        inputPlaceholder: 'Enter your password',
        inputAttributes: {
          maxlength: 30,
          autocapitalize: 'off',
          autocorrect: 'off',
        },
      })
      .then((result) => {
        if (result.isConfirmed) {
          axios
            .put(`${apiUrl}remove_from_inventory`, {
              movimentations: individualData.map(
                ({ movimentation }) => movimentation
              ),
              password: result.value,
              user: loggedUser?._id,
            })
            .then((result) => {
              toast.addSuccess(
                'Equipment removed from inventory successfully.'
              );
              dispatch(createCheckOut(data));
            })
            .catch((error) => {
              toast.addError(
                error?.response?.data?.error ||
                  error?.response?.data?.message ||
                  error?.message
              );
            });
        }
        if (result.dismiss === SweetAlert.DismissReason.cancel) {
          return;
        }
      });

  const handleBack = () => {
    history.goBack();
  };

  function handleEdit(e) {
    const { name, value } = e.target;
    setData((currentData) => ({ ...currentData, [name]: value }));
  }

  function handlePostCheckOut() {
    if (removeFromInventory) return handleRemoveFromInventory();
    dispatch(
      createCheckOut({
        ...data,
        individualData: individualData.map((curData) => ({
          ...curData,
          movimentation: curData.movimentation._id || curData.movimentation,
        })),
      })
    );
  }

  function handleDownload() {
    downloadReport(id).then(() => dispatch(getCheckOut(id)));
  }

  function handleEditOnTable(index) {
    function handler(e) {
      const { name, value } = e.target;
      setData((currentData) => {
        const newIndividualData = [...currentData.individualData];
        newIndividualData[index] = {
          ...newIndividualData[index],
          [name]: value,
        };
        return { ...currentData, individualData: newIndividualData };
      });
    }
    return handler;
  }

  return (
    <>
      <Container>
        <div className={classes.root} style={{ overflow: 'visible' }}>
          <Paper className={classes.paper} style={{ minHeight: '75%' }}>
            <Toolbar className={classes.toolBar}>
              <span>
                <IconButton onClick={handleBack}>
                  <ArrowBack />
                </IconButton>
                <Typography
                  className={classes.title}
                  variant="h6"
                  id="tableTitle"
                  component="span"
                >
                  Check-Out
                </Typography>
              </span>
            </Toolbar>
            <TableContainer className={classes.table}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Equipment Name</TableCell>
                    <TableCell>RAMP Unique ID</TableCell>
                    <TableCell>Customer MM No</TableCell>
                    <TableCell>Total Qty. EA</TableCell>
                    <TableCell>Footage</TableCell>
                    <TableCell
                      style={{
                        backgroundColor: '#9370db66',
                        textAlign: 'center',
                      }}
                    >
                      Tags
                    </TableCell>
                    <TableCell>Supplier Batch No</TableCell>
                    <TableCell>COPAS Class</TableCell>
                    <TableCell>Heat/Lot</TableCell>
                    <TableCell>RBW</TableCell>
                    <TableCell>Facility Reference ID</TableCell>
                    <TableCell>Assigned Project</TableCell>
                    <TableCell>Assigned Well</TableCell>
                    <TableCell>Assigned Charge Code</TableCell>
                    <TableCell>RAMP Tag No</TableCell>
                    <TableCell>RINA Work Order No</TableCell>
                    <TableCell>SLOC</TableCell>
                    <TableCell>Material Layout</TableCell>
                    <TableCell>Allocated</TableCell>
                    <TableCell>Supplier</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {individualData?.map(({ movimentation, tags }, index) => (
                    <TableRow>
                      <TableCell>
                        {movimentation?.customer_MM?.short_description}
                      </TableCell>
                      <TableCell>{movimentation?.individual_item_id}</TableCell>
                      <TableCell>
                        {movimentation?.customer_MM?.mm_number}
                      </TableCell>
                      <TableCell>{movimentation.qty}</TableCell>
                      <TableCell>{movimentation.footage}</TableCell>
                      <TableCell
                        style={{ backgroundColor: '#9370db33', minWidth: 200 }}
                      >
                        <InputContainer>
                          <TagsField
                            value={tags}
                            name="tags"
                            onChange={handleEditOnTable(index)}
                          />
                        </InputContainer>
                      </TableCell>
                      <TableCell>{movimentation?.batch_no}</TableCell>
                      <TableCell>{movimentation.classification}</TableCell>
                      <TableCell>{movimentation.heat}</TableCell>
                      <TableCell>{movimentation.RBW}</TableCell>
                      <TableCell>
                        {movimentation.facility_reference_id}
                      </TableCell>
                      <TableCell>
                        {
                          movimentation?.assigned_customer_charge_code?.project
                            ?.name
                        }
                      </TableCell>
                      <TableCell>
                        {
                          movimentation?.assigned_customer_charge_code?.well
                            ?.name
                        }
                      </TableCell>
                      <TableCell>
                        {
                          movimentation?.assigned_customer_charge_code
                            ?.original_charge_code
                        }
                      </TableCell>
                      <TableCell>{movimentation.tag_number}</TableCell>
                      <TableCell>{movimentation.rina_work_order_no}</TableCell>
                      <TableCell>{movimentation?.SLOC?.sloc_name}</TableCell>
                      <TableCell>{movimentation.material_layout}</TableCell>
                      <TableCell>
                        {movimentation.allocated_release_number ||
                        movimentation.allocated_batch_number ||
                        movimentation.allocated_work_order_number ? (
                          <span>YES</span>
                        ) : (
                          <span>NO</span>
                        )}
                      </TableCell>
                      <TableCell>{movimentation.supplier}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Paper className={localClasses.paper}>
            <Toolbar className={classes.toolBar}>
              <span>
                <Typography
                  className={classes.title}
                  variant="h6"
                  id="tableTitle"
                  component="span"
                  style={{ marginLeft: 15 }}
                >
                  Requesting Supplier Details
                </Typography>
              </span>
            </Toolbar>
            <Grid container style={{ padding: '0px 10px 10px 10px' }}>
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <InputContainer>
                  <TextField
                    onChange={handleEdit}
                    size="small"
                    name="supplierName"
                    label="Supplier Name"
                    type="text"
                    variant="outlined"
                    value={supplierName}
                    fullWidth
                  />
                </InputContainer>
              </Grid>
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <InputContainer>
                  <TextField
                    onChange={handleEdit}
                    size="small"
                    name="supplierPhone"
                    label="Supplier Phone"
                    type="text"
                    variant="outlined"
                    value={supplierPhone}
                    fullWidth
                  />
                </InputContainer>
              </Grid>
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <InputContainer>
                  <TextField
                    onChange={handleEdit}
                    size="small"
                    name="supplierEmail"
                    label="Supplier E-mail"
                    type="text"
                    variant="outlined"
                    value={supplierEmail}
                    fullWidth
                  />
                </InputContainer>
              </Grid>
            </Grid>
          </Paper>

          <Paper className={localClasses.paper}>
            <Toolbar className={classes.toolBar}>
              <span>
                <Typography
                  className={classes.title}
                  variant="h6"
                  id="tableTitle"
                  component="span"
                  style={{ marginLeft: 15 }}
                >
                  Logistic Details
                </Typography>
              </span>
            </Toolbar>
            <Grid container style={{ padding: '0px 10px 10px 10px' }}>
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <InputContainer>
                  <TextField
                    select
                    onChange={handleEdit}
                    size="small"
                    name="SLOC"
                    label="Destination SLOC"
                    type="text"
                    variant="outlined"
                    value={SLOC?._id || SLOC || ''}
                    fullWidth
                  >
                    {SLOCs?.docs?.map((SLOC) => (
                      <MenuItem value={SLOC._id}>{SLOC.sloc_id}</MenuItem>
                    ))}
                  </TextField>
                </InputContainer>
              </Grid>
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <InputContainer>
                  <TextField
                    onChange={handleEdit}
                    size="small"
                    name="SLOC_POC"
                    label="Destination SLOC POC"
                    type="text"
                    variant="outlined"
                    value={SLOC_POC}
                    fullWidth
                  />
                </InputContainer>
              </Grid>
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <InputContainer>
                  <TextField
                    onChange={handleEdit}
                    size="small"
                    name="facilityReferenceID"
                    label="Facility Reference ID"
                    type="text"
                    variant="outlined"
                    value={facilityReferenceID}
                    fullWidth
                  />
                </InputContainer>
              </Grid>
            </Grid>
          </Paper>
          <Paper className={classes.paper} style={{ minHeight: 600 }}>
            <EventDocumentsSection files={files} />
            <Divider />
            <Grid container style={{ padding: '0px 10px 10px 10px' }}>
              <Grid lg={12} md={12} sm={12} xs={12} justify="center" container>
                <FormControlLabel
                  control={
                    <Switch
                      checked={removeFromInventory}
                      color="secondary"
                      onChange={(event) =>
                        setData((currentState) => ({
                          ...currentState,
                          removeFromInventory: event.target.checked,
                        }))
                      }
                    />
                  }
                  label="Remove Equipment from Inventory"
                />
              </Grid>
              <Grid lg={12} md={12} sm={12} xs={12}>
                <ButtonContainer>
                  <Button
                    disabled={id !== 'new'}
                    color="primary"
                    variant="contained"
                    style={{ marginRight: 5 }}
                    onClick={handlePostCheckOut}
                  >
                    Post Transaction
                  </Button>
                  <Button
                    disabled={id === 'new'}
                    color="primary"
                    variant="contained"
                    style={{ marginRight: 5 }}
                    onClick={handleDownload}
                  >
                    Create ELP Form
                  </Button>
                </ButtonContainer>
              </Grid>
            </Grid>
          </Paper>
        </div>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  reconcilesLoading: state.ui.reconcilesLoading,
  reconciles: state.reconciles,
  loggedUser: state.loggedUser,
  SLOCs: state.slocs,
  checkOut: state.checkOut,
});

export default connect(mapStateToProps)(CheckOut);
