import {
  FETCH_VENDORS_SUCCESS,
  FETCH_VENDORS_ERROR,
  GET_VENDORS,
  updateVendors,
  showSpinner,
  hideSpinner,
} from '../actions/vendors';
import { apiRequest } from '../actions/api';

const URL = 'vendors';

export const getVendorsFlow = ({ dispatch, state }) => (next) => (action) => {
  next(action);
  if (action.type === GET_VENDORS) {
    dispatch(
      apiRequest(
        'GET',
        URL,
        action.payload,
        FETCH_VENDORS_SUCCESS,
        FETCH_VENDORS_ERROR,
        ['customer'],
      ),
    );
    dispatch(showSpinner());
  }
};

export const processVendorsCollection = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_VENDORS_SUCCESS) {
    dispatch(updateVendors(action.payload));
    dispatch(hideSpinner());
  }
};

export const vendorsMdl = [getVendorsFlow, processVendorsCollection];
