import {
  GET_MOVIMENTATIONS,
  UPDATE_MOVIMENTATIONS,
  UPDATE_MOVIMENTATIONS_SEARCH,
} from '../actions/movimentations';

const initState = {
  docs: [],
  page: 1,
  limit: 50,
  totalDocs: 0,
  query: '',
  filter: {},
  searchResults: [],
  totalSearch: 0,
};

export function movimentationsReducer(movimentations = initState, action) {
  switch (action.type) {
    case GET_MOVIMENTATIONS:
      return { ...movimentations, ...action.payload };
    case UPDATE_MOVIMENTATIONS:
      return { ...movimentations, ...action.payload };
    case UPDATE_MOVIMENTATIONS_SEARCH:
      return {
        ...movimentations,
        totalSearch: action.payload.totalDocs,
        searchResults: action.payload.docs,
      };
    default:
      return movimentations;
  }
}
