import styled, { css } from 'styled-components';
import logoImage from '../../assets/images/login-image.jpg';

export const BlurPage = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    filter: blur(3px);
    background-image: url(${logoImage});
    background-repeat: no-repeat;
    background-size: cover;
`;

export const TransparentColorPage = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: black;
    opacity: 0.8;
    z-index: 500;
`;

export const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Form = styled.form`
    margin: 0 auto !important;
    width: 90%;
    zoom: 1.1;
`;

export const Logo = styled.img`
    max-height: 15vh;
    max-width: 15vw;
`;

export const Input = styled.input` 
    font-size: 0.8rem !important;
    width: 100%;
    height: 35px;
    border: 1px solid white;
    box-shadow: none;
    border-radius: 20px;
    padding: 5px 10px;
`;

export const LoginButton = styled.button`
    background-color: ${({ disabled }) => (disabled ? '#FFF5' : 'white')};
    color: black;
    font-weight: bold;
    border-radius: 20px;
    height: 35px;
    width: 100%;
    border: 2px solid ${({ disabled }) => (disabled ? 'transparent' : 'white')};
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    transition: background 0.3s;
    ${({ disabled }) => (disabled ? null : css`
    &:hover {
        background-color: rgba(0, 0, 0, 0);
        color: white;
    }`)};
    
`;

export const RegisterButton = styled.button`
    background-color: rgba(0, 0, 0, 0);
    color: white;
    font-weight: bold;
    border-radius: 20px;
    height: 35px;
    width: 100%;
    border: 2px solid white;
    cursor: pointer;
    transition: background 0.3s;
    &:hover {
        background-color: white;
        color: black;
    }
`;

export const ForgotPasswordButton = styled.button`
  background: transparent;
  border: none;
  color: #ffffff;
  cursor:pointer;
`;

export const VerificationCodeText = styled.span`
    font-size: 16px;
    color: white;
    font-weight: 400;
`;
