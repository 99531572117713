import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Spinner from '../../../components/Spinner/index';
import { ButtonContainer, Container, InputContent, ModalTitle } from './styles';

const styles = {
  formButton: {
    margin: 2,
  },
};

const ContactDataModal = ({
  dispatch,
  handleClose,
  contractorDataSpinner,
  onSave,
  editData,
  onDelete,
  vendors,
}) => {
  const [active, setActive] = useState(true);
  const [vendorError, setVendorError] = useState(false);
  const [pointOfContactError, setPointOfContactError] = useState(false);
  const [proneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [positionError, setPositionError] = useState(false);
  const [contact, setContact] = useState(null);
  const [data, setData] = useState({
    vendor: '',
    point_of_contact: '',
    phone: '',
    email: '',
    position: '',
  });
  useEffect(() => {
    if (editData) setData(editData);
    else
      setData({
        vendor: '',
        point_of_contact: '',
        phone: '',
        email: '',
        position: '',
      });
  }, [editData]);
  const { vendor, point_of_contact, phone, email, position } = data;

  const handleSave = async () => {
    let err = false;
    // setVendorError(!vendor);
    // setPointOfContactError(!point_of_contact);
    // setPhoneError(!phone);
    // setEmailError(!email);
    // setPositionError(!position);
    // err = !vendor || !point_of_contact || !phone || !email || !position;

    if (!err) {
      onSave(data);
    }
  };

  const handleEdit = (e) => {
    const { name, value } = e.target;
    setData((currentData) => ({ ...currentData, [name]: value }));
  };

  const selectedVendor = vendors?.find(({ _id }) => _id === vendor);

  function handleContact(e) {
    const value = e.target.value;
    setContact(value);
    setData({
      vendor,
      point_of_contact: selectedVendor?.vendor_contacts[value]?.contact_name,
      phone: selectedVendor?.vendor_contacts[value]?.contact_phone,
      email: selectedVendor?.vendor_contacts[value]?.contact_email,
      position: selectedVendor?.vendor_contacts[value]?.contact_position,
    });
  }

  return (
    <>
      {contractorDataSpinner && <Spinner overlay />}
      <Container style={{ opacity: contractorDataSpinner ? 0.5 : 1 }}>
        <ModalTitle>New Contact</ModalTitle>
        <InputContent>
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel id="select-label">Vendor</InputLabel>
            <Select
              size="small"
              labelId="select-label"
              name="vendor"
              id="select-label-component"
              value={vendor?._id || vendor || ''}
              variant="outlined"
              onChange={(e) => {
                handleEdit(e);
                setContact(null);
              }}
              fullWidth
            >
              {vendors?.map((vendor) => (
                <MenuItem value={vendor._id}>{vendor.vendor_name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </InputContent>
        {vendor && (
          <InputContent>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel id="select-label">Point of Contact</InputLabel>
              <Select
                size="small"
                labelId="select-label"
                name="contact"
                id="select-label-component"
                value={contact}
                variant="outlined"
                onChange={handleContact}
                fullWidth
              >
                {selectedVendor?.vendor_contacts.map((contact, index) => (
                  <MenuItem value={index}>{contact.contact_name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </InputContent>
        )}
        {vendor && (
          <Grid container style={{ maxWidth: '90vw' }}>
            <Grid lg={4} md={6} sm={12} xs={12}>
              <InputContent>
                <Typography variant="subtitle2">Address:</Typography>
                {selectedVendor?.vendor_address?.number},{' '}
                {selectedVendor?.vendor_address?.address},{' '}
                {selectedVendor?.vendor_address?.postal_code},{' '}
                {selectedVendor?.vendor_address?.city},{' '}
                {selectedVendor?.vendor_address?.state},{' '}
                {selectedVendor?.vendor_address?.country}
              </InputContent>
            </Grid>
            {contact !== null && (
              <>
                <Grid lg={4} md={6} sm={12} xs={12}>
                  <InputContent>
                    <Typography variant="subtitle2">
                      Point of Contact:
                    </Typography>{' '}
                    {selectedVendor?.vendor_contacts[contact]?.contact_name}
                  </InputContent>
                </Grid>
                <Grid lg={4} md={6} sm={12} xs={12}>
                  <InputContent>
                    <Typography variant="subtitle2">Phone:</Typography>{' '}
                    {selectedVendor?.vendor_contacts[contact]?.contact_phone}
                  </InputContent>
                </Grid>
                <Grid lg={4} md={6} sm={12} xs={12}>
                  <InputContent>
                    <Typography variant="subtitle2">E-mail:</Typography>{' '}
                    {selectedVendor?.vendor_contacts[contact]?.contact_email}
                  </InputContent>
                </Grid>
                <Grid lg={4} md={6} sm={12} xs={12}>
                  <InputContent>
                    <Typography variant="subtitle2">Position:</Typography>{' '}
                    {selectedVendor?.vendor_contacts[contact]?.contact_position}
                  </InputContent>
                </Grid>
              </>
            )}
          </Grid>
        )}
        {/* <InputContent>
            <TextField
              id="outlined-password-input"
              label="Vendor"
              required
              type="text"
              error={vendorError}
              helperText={vendorError && 'Vendor is required'}
              variant="outlined"
              value={vendor || ''}
              name="vendor"
              onChange={handleEdit}
              fullWidth
            />
          </InputContent>
          <InputContent>
            <TextField
              id="outlined-password-input"
              label="Point of Contact"
              required
              type="text"
              error={pointOfContactError}
              helperText={pointOfContactError && 'Point of Contact is required'}
              variant="outlined"
              value={point_of_contact || ''}
              name="point_of_contact"
              onChange={handleEdit}
              fullWidth
            />
          </InputContent>
          <InputContent>
            <TextField
              id="outlined-password-input"
              label="Phone"
              required
              type="text"
              error={proneError}
              helperText={proneError && 'Phone is required'}
              variant="outlined"
              value={phone || ''}
              name="phone"
              onChange={handleEdit}
              fullWidth
            />
          </InputContent>
          <InputContent>
            <TextField
              id="outlined-password-input"
              label="E-mail"
              required
              error={emailError}
              type="text"
              helperText={emailError && 'E-mail is required'}
              variant="outlined"
              value={email || ''}
              name="email"
              onChange={handleEdit}
              fullWidth
            />
          </InputContent>
          <InputContent>
            <TextField
              id="outlined-password-input"
              label="Position"
              required
              error={positionError}
              type="text"
              helperText={positionError && 'Position is required'}
              variant="outlined"
              value={position || ''}
              name="position"
              onChange={handleEdit}
              fullWidth
            />
          </InputContent> */}
        <ButtonContainer>
          {editData && (
            <Button
              color="secondary"
              onClick={onDelete}
              variant="outlined"
              style={styles.formButton}
            >
              Delete
            </Button>
          )}
          <Button
            color="secondary"
            onClick={handleClose}
            variant="contained"
            style={styles.formButton}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={handleSave}
            variant="contained"
            style={styles.formButton}
          >
            Save
          </Button>
        </ButtonContainer>
      </Container>
    </>
  );
};

export default connect((state) => ({
  contractorData: state.contractorData,
  contractorDataModal: state.ui.contractorDataModal,
  contractorDataSpinner: state.ui.contractorDataLoading,
  vendors: state.vendors?.docs || [],
}))(ContactDataModal);
